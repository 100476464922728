import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EActions } from "../../usecase/Permissions/Permissions";
import { NewRemarksFormViewModel } from "../RemarksFormViewModel/NewRemarksFormViewModel";
import { RemarksFormViewModel } from "../RemarksFormViewModel/RemarksFormViewModel";
import { RemarksListViewModel } from "../RemarksListViewModel/RemarksListViewModel";
import { ERemarksPageContent, IRemarksPageViewModel } from "./interface";

export class RemarksPageViewModel implements IRemarksPageViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get editButtonViewModel() {
    if (
      (this.content !== ERemarksPageContent.form) ||
      this.props.layers.usecases.viewState.props.editRemarkMode ||
      this.props.layers.usecases.viewState.props.addRemarkMode
    ) return

    if (
      this.props.layers.usecases.permissions.someActionsIsAllowed([
        EActions.editRemark,
        EActions.deleteRemark,
        EActions.setRemarkStatusOpen,
        EActions.setRemarkStatusClose,
        EActions.setRemarkStatusPending,
        EActions.addNewRemarkFiles,
      ])) return {
        onClick: () => {
          this.props.layers.usecases.viewState.setEditRemarkMode(true)
        }
      }
  }

  @computed
  get selectedRemark() {
    return this.props.layers.usecases.remarks.selectedRemarks[0]
  }

  @computed
  get content() {
    if (
      this.props.layers.usecases.viewState.props.addRemarkMode ||
      this.props.layers.usecases.remarks.selectedRemark
    ) return ERemarksPageContent.form

    return ERemarksPageContent.list
  }

  @computed
  get remarksListViewModel() {
    return new RemarksListViewModel(this.props)
  }

  @computed
  get remarksFormViewModel() {
    if (this.props.layers.usecases.remarks.selectedRemark) {
      return new RemarksFormViewModel({
        ...this.props,
        remark: this.props.layers.usecases.remarks.selectedRemark,
      })
    }
    return new NewRemarksFormViewModel(this.props)
  }

  onBack() {
    if (this.content === ERemarksPageContent.form) {
      this.props.layers.usecases.remarks.dropSelectedRemark()
      this.props.layers.usecases.remarks.dropAddRemarkMode()
      this.props.layers.usecases.viewState.setEditRemarkMode(false)
    } else {
      this.props.layers.usecases.viewState.setPanelOpened(false)
    }
  }

  @computed
  get onAdd() {
    if (this.content !== ERemarksPageContent.list) return
    if (this.props.layers.usecases.permissions.actionIsBlocked(EActions.addRemark)) return

    return () => this.props.layers.usecases.viewState.setAddRemarkMode(true)
  }
}