import React, { useEffect, useMemo, useRef } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IPlanFactChartViewModel } from "../../view-model/PlanFactChartViewModel/interface"
import Chart from "chart.js/auto"
import "chartjs-adapter-date-fns"
import { ru } from 'date-fns/esm/locale'

const useClasses = makeStyles(theme => ({
  root: {},
}))

interface IPlanFactChartProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPlanFactChartViewModel
  width: number
  height: number
  className?: string
}

export const PlanFactChart = observer((props: IPlanFactChartProps) => {
  const ref = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (ref.current) {
      const ctx = ref.current.getContext('2d');
      if (ctx) {
        const chart = new Chart(ctx, {
          type: 'line',
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: props.viewModel.name
              }
            },
            scales: {
              x: {
                type: "time",
                time: {
                  unit: "month",
                  displayFormats: {
                    month: "LLLL yyyy"
                  }
                },
                adapters: {
                  date: {
                    locale: ru
                  }
                },
              },
              y: {
                min: 0,
                ticks: {
                  callback: (_, value: number | string) => `${value} ${props.viewModel.unit}`
                }
              },
            },
          },
          data: {
            labels: props.viewModel.labels,
            datasets: props.viewModel.datasets.map(d => ({
              label: d.label,
              data: d.data,
              borderColor: d.color,
              backgroundColor: d.color
            }))
          },
        });

        return () => {
          chart.destroy();
        }
      }
    }
  }, [
    ref.current,
    props.viewModel.labels,
    props.viewModel.datasets,
    props.viewModel.name
  ])

  const canvasProps = useMemo(() => ({
    width: props.width,
    height: props.height,
  }), [props.width, props.height])

  return (
    <canvas
      ref={ref}
      className={props.className}
      {...canvasProps}
    ></canvas>
  )
})