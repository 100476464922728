import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EPages } from "../../models/Pages";
import { Project } from "../../models/Project";
import { EActions } from "../../usecase/Permissions/Permissions";
import { IProjectCardViewModel, IProjectsListViewModel } from "./interface";

export class ProjectsListViewModel implements IProjectsListViewModel {
    constructor(private props: { layers: ILayers }) {
        this.props = props

        makeObservable(this)
    }

    @computed
    private get searchText() {
        return this.props.layers.usecases.viewState.props.mainPageSearch.toLowerCase()
    }

    @computed
    get projects() {
        if (!this.searchText) return this.allProjects

        return this.allProjects.filter(project => project.name.toLowerCase().includes(this.searchText))
    }

    @computed
    get allProjects() {
        const projects = this.props.layers.repositories.projectsRepository.getAll()
        return projects.map(project => new ProjectCardViewModel({ layers: this.props.layers, project }))
    }
}


class ProjectCardViewModel implements IProjectCardViewModel {
    constructor(private props: { project: Project, layers: ILayers }) {
        this.props = props

        makeObservable(this)
    }

    @computed
    get name() {
        return this.props.project.name
    }

    @computed
    get key() {
        return this.props.project.id
    }

    @computed
    get image() {
        return this.props.project.image
    }

    @computed
    get sections() {
        return {
            name: `Корпус${getAnEnding(this.targetsCount)}`,
            count: this.targetsCount
        }
    }

    @computed
    get files() {
        return {
            name: `Файл${getAnEnding(this.bimsCount)}`,
            count: this.bimsCount
        }
    }

    @computed
    get allSectionsButton() {
        if (this.props.layers.usecases.permissions.actionIsBlocked(EActions.showModels)) return

        return {
            disabled: this.bimsCount === 0,
            onClick: () => this.onOpenAllModels()
        }
    }

    private async onOpenAllModels() {
        const bims = this.props.layers.usecases.bims.getBimsByProjectId(this.props.project.id)
        this.props.layers.usecases.viewState.setSelectedBims(bims.map(bim => bim.id))
        this.props.layers.usecases.viewState.setSelectedPage(EPages.viewer)
    }

    @computed
    get targetsCount() {
        return this.props.layers.repositories.targetsRepository.getByProjectId(this.props.project.id).length
    }

    @computed
    get bimsCount() {
        return this.props.layers.usecases.bims.getBimsByProjectId(this.props.project.id).length
    }

    onOpen() {
        this.props.layers.usecases.viewState.setSelectedProjectId(this.props.project.id)
    }
}

const getAnEnding = (count) => {
    if (count === 11) {
        return 'ов';
    }
    if (count % 10 === 1) {
        return "";
    }
    if (count % 10 >= 2 && count % 10 <= 4) {
        return "a";
    }
    return 'ов'
}