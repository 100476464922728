import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Dialog
} from '@material-ui/core';

const StyleDialog = withStyles(theme => ({
  paper: {
    borderRadius: 14
  }
}))(Dialog);

export const DialogWindow = props => {
  const { onClose, open, children } = props;

  return (
    <StyleDialog
      open={open}
      onClose={onClose}
    >
      {children}
    </StyleDialog>
  )
}