import { action, computed, makeObservable } from "mobx";
import { ISimpleDataRepository } from "../../repositories/SimpleDataRepository/interface";
import { IFloatingWindowViewModel, TPosition, TSize } from "./interface";

export class BaseFloatingWindowViewModel implements IFloatingWindowViewModel {
  constructor(private props: { floatingWindowDataRepositoy: ISimpleDataRepository<{ position?: TPosition, size?: TSize }> }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get position() {
    return this.props.floatingWindowDataRepositoy.get()?.position
  }

  @computed
  get size() {
    return this.props.floatingWindowDataRepositoy.get()?.size
  }

  enableResizable = true

  @action
  onResizeStop = (...args) => {
    const position: TPosition = args[4]
    const deltaSize: TSize = args[3]

    const size: TSize = this.size ? {
      width: this.size.width + deltaSize.width,
      height: this.size.height + deltaSize.height,
    } : {
      width: deltaSize.width,
      height: deltaSize.height
    }

    this.props.floatingWindowDataRepositoy.patch({
      position,
      size
    })
  }

  @action
  onDragStop = (e, data: TPosition & any) => {
    this.props.floatingWindowDataRepositoy.patch({
      position: { x: data.x, y: data.y },
      size: this.size
    })
  }
}