import React, { useMemo } from "react";
import { CodeRounded, AssignmentLate, AssignmentTurnedIn, History, InsertChart, Layers, PlaylistAddCheck, Settings as SettingsIcon } from "@material-ui/icons";
import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import packageJSON from "../../../package.json";
import { DialogWindow } from '../../components/DialogWindow/DialogWindow'
import SimpleButton from "../../components/Buttons/SimpleButton";
import { EBimPanelContent, IBimPanelViewModel } from "../../view-model/BimPanelViewModel/interface";
import { StatusPage } from "../StatusPage/StatusPage";
import { observer } from "mobx-react-lite";

// import HistoryPage from "../../components/HistoryPage/HistoryPage";
import { RemarksPage } from "../RemarksPage/RemarksPage";
import { LevelsFilter } from "../LevelsFilter/LevelsFilter";
import { TermsAndVolumes } from "../TermsAndVolumes/TermsAndVolumes";
import { PrescriptionsPage } from "../PrescriptionsPage/PrescriptionsPage";
import { StatisticsPage } from "../StatisticsPage/StatisticsPage";
import { SettingsPage } from "../SettingsPage/SettingsPage";
import { HistoryPage } from "../HistoryPage/HistoryPage";

const MenuIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="2" stroke="black" strokeLinejoin="round" strokeLinecap="round">
    <path d="M20.3995 18.669L4.39947 18.669" />
    <path d="M20.3995 12.669L4.39947 12.669" />
    <path d="M20.3995 6.66901L4.39947 6.669" />
  </svg>
)

const CloseIconNew = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path
      d="M19.2713 21.1388H10.571C10.0782 21.1388 9.67042 20.731 9.67042 20.2382C9.67042 19.7454 10.0782 19.3376 10.571 19.3376H18.3707V11.0791L13.0859 6.81397L7.80122 11.0791V20.2382C7.80122 20.731 7.3934 21.1388 6.90061 21.1388C6.40782 21.1388 6 20.731 6 20.2382V11.0451C6 10.5354 6.2379 10.0426 6.62873 9.71971L12.0154 5.36959C12.6271 4.8768 13.5278 4.8768 14.1395 5.36959L19.5432 9.71971C19.951 10.0426 20.1719 10.5184 20.1719 11.0451V20.2552C20.1719 20.731 19.7641 21.1388 19.2713 21.1388Z"
      fill="#EE2E2E" />
  </svg>
)

const useClassesBottomAdditionalContentShort = makeStyles(theme => ({
  root: {
    padding: 20,
    transition: "padding-left 0.2s linear"
  },
  rootLong: {
    paddingLeft: 20
  },
  button: {
    display: "flex",
    padding: 10,
    borderRadius: 8,
    //@ts-ignore
    backgroundColor: theme.palette.baseBackgroundColor,
    marginRight: 40,

    "&:first-child": {
      marginBottom: 10
    }
  },
  version: {
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10px",
    color: "#0002",
    opacity: 0,
    transition: "opacity 0.1s linear"
  },
  versionLong: {
    opacity: 1,
    transition: "opacity 0.6s linear"
  },
  versionAndExitContainer: {
    display: 'flex',
  }
}))

function BottomAdditionalContentShort({ onShow, onClose, open }) {
  const classes = useClassesBottomAdditionalContentShort();

  return (
    <div className={clsx(classes.root, { [classes.rootLong]: open })}>
      <IconButton className={classes.button} onClick={onShow}>
        <MenuIcon />
      </IconButton>
      <div className={classes.versionAndExitContainer}>
        <IconButton className={classes.button} onClick={onClose}>
          <CloseIconNew />
        </IconButton>
        <div className={clsx(classes.version, { [classes.versionLong]: open })}>
          {
            `Версия ${packageJSON.version}`
          }
        </div>
      </div>
    </div>
  );
}

const useClassesContentDialogWindow = makeStyles(theme => ({
  content: {
    width: 314,
    padding: 20
  },
  text: {
    fontSize: 22,
    fontWeight: 'bold',
    //@ts-ignore
    color: theme.palette.textColor,
    textAlign: 'center',
    letterSpacing: -0.24
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: "space-between",
    marginTop: 20,
  },
  button: {
    width: 127,
    height: 44,
    //@ts-ignore
    backgroundColor: theme.palette.baseBackgroundColor,
    opacity: 0.5,
    //@ts-ignore
    color: theme.palette.textColor,
    fontSize: 12,
    fontWeight: 'bold',

    '&:hover': {
      //@ts-ignore
      backgroundColor: theme.palette.primary1Color,
      opacity: 1
    }
  }
}))

const ContentDialogWindow = ({ onClose, onExit }) => {
  const classes = useClassesContentDialogWindow()

  return (
    <div className={classes.content}>
      <Typography className={classes.text}>
        Вы уверены, что хотите закрыть модель?
      </Typography>
      <div className={classes.buttonsContainer}>
        <SimpleButton className={classes.button} onClick={onExit}>
          Да
        </SimpleButton>
        <SimpleButton className={classes.button} onClick={onClose}>
          Нет
        </SimpleButton>
      </div>
    </div>
  )
}


const DRAWER_OPEN_WIDTH = 280;
const CONTENT_WIDTH = 360;
const DRAWER_CLOSE_WIDTH = 84;

const useClasses = makeStyles<any, { isMobile: boolean, panelWidth: number, short: boolean, classes: any }>(theme => ({
  root: {
    height: "100%",
    display: "flex",
  },
  header: {
    fontSize: "12px",
    padding: "20px 30px",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    width: ({ panelWidth }) => panelWidth,
    opacity: 0.5
  },
  panel: {
    position: "relative",
    zIndex: 2,
    height: "100%",
    transition: "width 0.5s ease",
    width: ({ panelWidth }) => panelWidth,
    overflow: "hidden",
  },
  contentWrapper: {
    height: "100%",
    position: "relative"
  },
  content: {
    display: "flex",
    backgroundColor: "#FFF",
    boxShadow: "inset 10px 0 20px -10px rgba(0, 0, 0, 0.05)",
    position: "absolute",
    height: "100%",
    minWidth: CONTENT_WIDTH,
    transition: "transform 0.5s ease",
    transform: "translateX(-100%)",
    width: CONTENT_WIDTH,

    "& > *": {
      flex: 1,
      overflow: "auto"
    }
  },
  contentOpen: {
    transform: "none",
  },
  bottomAdditionalContent: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0
  },
  sections: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: ({ isMobile }) => isMobile ? 20 : 0,
    justifyContent: ({ isMobile }) => isMobile ? "start" : "center",
    backgroundColor: "white",

    "& > *:not(:last-child)": {
      borderBottom: ({ short }) => short ? "1px solid #0000" : "1px solid #0001",
    },
  },
  section: {
    width: 280,
    // width: "100%",
    padding: "15px 40px 15px 30px",
    boxSizing: "border-box",
    textTransform: "none",
    justifyContent: "start",
    borderRadius: 0,

    "& svg": {
      fill: theme.palette.sectionIconColor
    },
  },
  selectedSection: {
    "& svg": {
      fill: theme.palette.primary.main
    },
  },
  description: {
    opacity: ({ short }) => short ? 0 : 1,
    marginLeft: 15,
    flex: 1,
    textAlign: "left",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#000",
    transition: "opacity 0.2s linear"
  },
  selectedDescription: {
    fontWeight: "bold",
    color: "#000"
  },
  additionalContent: {
    fontSize: "12px",
    fontWeight: "bold"
  },
  red: {
    color: "#EE2E2E"
  }
}));

const sectionsDic = new Map([
  [EBimPanelContent.storeys, {
    Icon: Layers
  }],
  [EBimPanelContent.status, {
    Icon: AssignmentTurnedIn
  }],
  [EBimPanelContent.remarks, {
    Icon: AssignmentLate
  }],
  [EBimPanelContent.prescriptions, {
    Icon: PlaylistAddCheck
  }],
  [EBimPanelContent.planfact, {
    Icon: InsertChart
  }],
  [EBimPanelContent.terms_and_volumes, {
    Icon: CodeRounded
  }],
  [EBimPanelContent.history, {
    Icon: History
  }],
  [EBimPanelContent.settings, {
    Icon: SettingsIcon
  }],
]);

interface IBimPanelProps {
  classes?: any
  viewModel: IBimPanelViewModel
}

export const BimPanel = observer((props: IBimPanelProps) => {
  const short = props.viewModel.shortModeActive

  const panelWidth = short ? DRAWER_CLOSE_WIDTH : DRAWER_OPEN_WIDTH;

  const classes = useClasses({
    classes: props.classes,
    short,
    panelWidth,
    isMobile: props.viewModel.isMobile
  });

  const content = useMemo(() => {
    switch (props.viewModel.content) {
      case EBimPanelContent.storeys:
        return <LevelsFilter viewModel={props.viewModel.levelsFilterViewModel} />
      case EBimPanelContent.status:
        return <StatusPage viewModel={props.viewModel.statusPageViewModel} />
      case EBimPanelContent.remarks:
        return <RemarksPage viewModel={props.viewModel.remarksPageViewModel} />
      case EBimPanelContent.prescriptions:
        return <PrescriptionsPage viewModel={props.viewModel.prescriptionsPageViewModel} />
      case EBimPanelContent.planfact:
        return <StatisticsPage viewModel={props.viewModel.statisticsPageViewModel} />
      case EBimPanelContent.history:
        return <HistoryPage viewModel={props.viewModel.historyPageViewModel} />
      case EBimPanelContent.settings:
        return <SettingsPage viewModel={props.viewModel.settingsPageViewModel} />
      case EBimPanelContent.terms_and_volumes:
        return <TermsAndVolumes viewModel={props.viewModel.termsAndVolumesViewModel} />
      default:
        return null
    }
  }, [props.viewModel.content])

  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <div className={classes.sections}>
          {
            props.viewModel.sections.map((section, index) => {
              const Icon = sectionsDic.get(section.code)?.Icon
              return (
                <Button
                  className={clsx(classes.section, {
                    [classes.selectedSection]: section.selected
                  })}
                  key={index}
                  onClick={section.onClick}
                >
                  {Icon ? <Icon /> : null}
                  <div className={clsx(classes.description, {
                    [classes.selectedDescription]: section.selected
                  })}>
                    {section.name}
                  </div>
                  {
                    section.count ? (
                      <div className={classes.additionalContent}>
                        <span className={classes.red}>{section.count[0]}</span>/{section.count[1]}
                      </div>
                    ) : null
                  }
                </Button>
              )
            }

            )
          }
        </div>
        <div className={classes.bottomAdditionalContent}>
          <BottomAdditionalContentShort
            onShow={() => props.viewModel.switchShortModeActive()}
            onClose={() => props.viewModel.onExit()}
            open={!props.viewModel.shortModeActive}
          />
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <div
          className={clsx(classes.content, {
            [classes.contentOpen]: props.viewModel.contentOpen
          })}
        >
          {content}
        </div>
      </div>
      <DialogWindow
        open={props.viewModel.closeDialogOpened}
        onClose={() => props.viewModel.onCloseDialog()}
      >
        <ContentDialogWindow onClose={() => props.viewModel.onCloseDialog()} onExit={() => props.viewModel.exit()} />
      </DialogWindow>
    </div>
  );
})