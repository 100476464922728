import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Collection, TNewCollectionProps } from "../../models/Collection";
import { PanelSelectorMultiViewModel } from "../PanelSelectorViewModel/PanelSelectorMultiViewModel";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { ICollectionFormViewModel } from "./interface";

export class CollectionFormEditViewModel implements ICollectionFormViewModel {
  constructor(private props: {
    layers: ILayers,
    collection: Collection,
    onClose?(): void
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get header() {
    return this.props.collection?.name || "?"
  }

  @computed
  get deleteButtonViewModel() {
    return {
      onClick: async () => {
        try {
          this.statusWrapperViewModel.load()
          await this.props.layers.repositories.collectionsRepository.delete(this.props.collection.id)
          this.statusWrapperViewModel.success()
        } catch (error) {
          this.statusWrapperViewModel.fail("Ошибка")
        }

      }
    }
  }

  @computed
  get bimsPanelSelectorViewModel() {
    return new PanelSelectorMultiViewModel({
      defaultValue: this.props.collection.bims,
      options: this.bimsOptions,
      label: ""
    })
  }

  @computed
  private get bimsOptions() {
    return this.projectBims.map(bim => {
      if (bim.modelWorkType === this.props.collection.modelWorkType) return {
        value: bim.id,
        label: bim.name || bim.fileName
      }

      const bimModelWorkType = this.props.layers.repositories.modelWorkTypeRepository.getById(bim.modelWorkType)

      return {
        value: bim.id,
        label: bimModelWorkType ? `${bim.name || bim.fileName} (${bimModelWorkType.name})` : bim.name || bim.fileName
      }
    })
  }

  @computed
  get namePanelInputViewModel() {
    return new SimpleViewModel({
      value: this.props.collection.name
    })
  }

  @computed
  get linkPanelInputViewModel() {
    return new SimpleViewModel({
      value: this.props.collection.link
    })
  }

  @computed
  get saveButtonViewModel() {
    return {
      label: "Сохранить",
      disabled: !this.isValid,
      onClick: this.onSave
    }
  }

  statusWrapperViewModel = new StatusWrapperViewModel()

  @computed
  private get selectedProjectId() {
    return this.props.layers.usecases.viewState.props.selectedProjectId
  }

  @computed
  private get projectBims() {
    if (!this.selectedProjectId) return []
    return this.props.layers.usecases.bims.getBimsByProjectId(this.selectedProjectId)
  }

  @computed
  private get isValid() {
    return this.props.layers.usecases.collections.getCollectionIsValid(this.collectionProps)
  }

  @computed
  private get collectionProps(): TNewCollectionProps {
    return {
      ...this.props.collection.props,
      name: this.namePanelInputViewModel.value,
      link: this.linkPanelInputViewModel.value,
      bims: this.bimsPanelSelectorViewModel.values,
    }
  }

  private onSave = async () => {
    try {
      this.statusWrapperViewModel.load()

      await this.props.layers.repositories.collectionsRepository.edit(this.props.collection.id, this.collectionProps)
      this.props.onClose?.()

      this.statusWrapperViewModel.success()
    } catch (error) {
      this.statusWrapperViewModel.fail("Ошибка")
    }
  }
}