import React from "react"
import { Button, makeStyles, withStyles } from "@material-ui/core"

export const TableButton = withStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: 10,
    padding: 0,
    lineHeight: "44px",
    backgroundColor: (props: { mobile?: boolean }) => props.mobile ? theme.palette.primary.main : "rgb(245, 245, 245)",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,

      "& .MuiButton-label": {
        opacity: 1
      }
    }
  },
  label: {
    fontWeight: "bold",
    fontSize: 12,
    color: "#000",
    textTransform: 'none',
    opacity: (props: { mobile?: boolean }) => props.mobile ? 1 : 0.5,
  }
}))(Button)