import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { IModelsLoaderViewModel } from "./interface";

export class ModelsLoaderViewModel implements IModelsLoaderViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get urns() {
    return this.bims.map(bim => bim.urn)
  }

  @computed
  private get bims() {
    return this.props.layers.usecases.bims.selectedAllBims
  }
}