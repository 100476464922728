import React from "react";
import { withStyles } from "@material-ui/core";
import clsx from "clsx";

function SimpleButtonPure({className, children, classes, onClick, disabled }) {
    return (
        <div 
            className={clsx(classes.root, className, {
                [classes.disabled]: disabled
            })} 
            onClick={disabled ? null: onClick}
        >
            {children}
        </div>
    );
}

const styles = {
    root: {
        height: 44,
        textAlign: "center",
        boxSizing: "border-box",

        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "44px",

        borderRadius: 8,

        cursor: "pointer",
        whiteSpace: "nowrap",
        padding: "0 10px",
        userSelect: "none"
    },
    disabled: {
        backgroundColor: "#ddd !important",
        cursor: "initial",
        opacity: 0.5,
    }
};

const SimpleButton = withStyles(styles)(SimpleButtonPure);

export default SimpleButton;