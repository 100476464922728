import { IAuthRepository } from "../AuthRepository/interface";
import { loadWithReTry } from "../SmartRequest/SmartRequest";
import { IPDMAuthRepository } from "./interface";

export class PDMAuthRepository implements IPDMAuthRepository {
  constructor(private props: { authRepository: IAuthRepository, href: string }) {
    this.props = props

    // makeObservable(this)
  }

  private token?: string
  private tokenRequest?: Promise<string>
  private offline: boolean = false

  private getHeaders() {
    if (this.offline) return {}

    return {
      'Authorization': `Bearer ${this.token}`
    }
  }

  async getAuthHeadersAsync() {
    if (!this.tokenRequest) {
      this.tokenRequest = this.loadToken()
    }

    await this.tokenRequest

    return this.getHeaders()
  }

  private async loadToken(): Promise<string> {
    const response = await loadWithReTry(this.props.href, {
      headers: this.props.authRepository.getAuthHeaders()
    }, { maxTry: 3 })

    if (!response || response.status === 0) {
      this.offline = true
      this.tokenRequest = undefined

      return ""
    } else {
      const responseData = await response.json()
      const token = responseData.access_token
      this.offline = false
      this.token = token

      return token
    }
  }
}