import { computed, makeObservable, action, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { IFilterPanelViewModel } from "./interface";
import { ERemarkStatus } from "../../models/Remark";
import { PanelSelectorMultiAllViewModel } from "../PanelSelectorViewModel/PanelSelectorMultiAllViewModel";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { SetViewModel } from "../SimpleViewModel/SetViewModel";
import { User } from "../../models/User";
import { RemarkBase } from "../../models/RemarkBase";
export class FilterPanelViewModel implements IFilterPanelViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get statusesSetViewModel() {
    return new SetViewModel(this.props.layers.usecases.filterPanel.selectedStatuses.array)
  }

  @computed
  get contractorsSetViewModel() {
    return new SetViewModel(this.props.layers.usecases.filterPanel.selectedContractors.array)
  }

  @computed
  get ownersSetViewModel() {
    return new SetViewModel(this.props.layers.usecases.filterPanel.selectedOwners.array)
  }

  @computed
  get deadlineStatusSetViewModel() {
    return new SetViewModel(this.props.layers.usecases.filterPanel.selectedDeadlineStatus.array)
  }

  @computed
  get selectedRemarksBaseSetViewModel() {
    return new SetViewModel(this.props.layers.usecases.filterPanel.selectedRemarksBase.array)
  }

  @computed
  get isOpen() {
    return this.props.layers.usecases.viewState.props.isOpenFilterPanel
  }

  @computed
  get statusesPanelSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      setViewModel: this.statusesSetViewModel,
      label: "Статусы",
      options: [
        {
          value: ERemarkStatus.opened,
          label: "Открыто"
        },
        {
          value: ERemarkStatus.pending,
          label: "Ожидает"
        },
        {
          value: ERemarkStatus.closed,
          label: "Закрыто"
        }
      ]
    })
  }

  @computed
  get contractorPanelSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      setViewModel: this.contractorsSetViewModel,
      label: "Подрядчик",
      options: this.props.layers.repositories.companiesRepository.getContractors().map(company => ({
        value: company.id,
        label: company.name,
      }))
    })
  }

  @computed
  get ownersPanelSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      setViewModel: this.ownersSetViewModel,
      label: "Инженер СК",
      options: this.ownersByRemarks.map(user => ({
        value: user.id,
        label: user.fullName,
      }))
    })
  }

  @computed
  get remarkBasePanelSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      setViewModel: this.selectedRemarksBaseSetViewModel,
      label: "Тип замечания",
      options: this.remarkBaseByRemarks.map(remarkBase => ({
        value: remarkBase.id,
        label: remarkBase.title,
      }))
    })
  }

  @computed
  get deadlineStatusPanelSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      setViewModel: this.deadlineStatusSetViewModel,
      label: "Срок",
      options: [
        {
          value: "completed",
          label: "Выполнено"
        },
        {
          value: "expired",
          label: "Просрочено"
        },
        {
          value: "in process",
          label: "В процессе"
        }
      ]
    })
  }

  @computed
  get remarks() {
    return this.targets.reduce((acc, target) => [...acc, ...this.props.layers.usecases.remarks.getRemarksByTargetId(target.id)], [])
  }

  @computed
  get deadlinePanelDateSelectorViewModel() {
    return new SimpleViewModel({
      value: this.props.layers.usecases.filterPanel.selectedDeadline.value
    })
  }

  @computed
  get createdAtPanelDateSelectorViewModel() {
    return new SimpleViewModel({
      value: this.props.layers.usecases.filterPanel.selectedCreatedAt.value
    })
  }

  @computed
  get targets() {
    if (!this.props.layers.usecases.viewState.props.selectedProjectId) return []

    return this.props.layers.repositories.targetsRepository.getByProjectId(this.props.layers.usecases.viewState.props.selectedProjectId)
  }

  @computed
  get ownersByRemarks() {
    const owners = new Set<User>()

    this.remarks.forEach((remark) => {
      const owner = this.props.layers.repositories.usersRepository.getById(remark.owner)
      if (owner) owners.add(owner)
    })

    return Array.from(owners.values())
  }

  @computed
  get remarkBaseByRemarks() {
    const remarkBaseSet = new Set<RemarkBase>()

    this.remarks.forEach((remark) => {
      const remarkBase = this.props.layers.repositories.remarksBaseRepository.getById(remark.remarkBase)
      if (remarkBase) remarkBaseSet.add(remarkBase)
    })

    return Array.from(remarkBaseSet.values())
  }

  @action
  onClose = () => {
    this.props.layers.usecases.viewState.switchFilterPanel();
  }

  @action
  onApply = () => {
    this.props.layers.usecases.filterPanel.selectedStatuses.replace(this.statusesSetViewModel.array)
    this.props.layers.usecases.filterPanel.selectedContractors.replace(this.contractorsSetViewModel.array)
    this.props.layers.usecases.filterPanel.selectedOwners.replace(this.ownersSetViewModel.array)
    this.props.layers.usecases.filterPanel.selectedDeadlineStatus.replace(this.deadlineStatusSetViewModel.array)
    this.props.layers.usecases.filterPanel.selectedRemarksBase.replace(this.selectedRemarksBaseSetViewModel.array)

    this.props.layers.usecases.filterPanel.selectedDeadline.onChange(this.deadlinePanelDateSelectorViewModel.value)
    this.props.layers.usecases.filterPanel.selectedCreatedAt.onChange(this.createdAtPanelDateSelectorViewModel.value)

    this.props.layers.usecases.viewState.switchFilterPanel();
  }

  @action
  onClear = () => {
    this.statusesSetViewModel.clear()
    this.contractorsSetViewModel.clear()
    this.ownersSetViewModel.clear()
    this.deadlineStatusSetViewModel.clear()
    this.selectedRemarksBaseSetViewModel.clear()

    this.deadlinePanelDateSelectorViewModel.onChange("")
    this.createdAtPanelDateSelectorViewModel.onChange("")
  }
}