import React from "react"
import { IconButton, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { EPropertyType, IPropertiesPanelViewModel } from "../../view-model/PropertiesPanelViewModel/interface"
import { Close } from "@material-ui/icons"
import clsx from "clsx"
import { StatusWrapper } from "../StatusWrapper/StatusWrapper"

const useClasses = makeStyles(theme => ({
  root: {
    boxShadow: "1px 3px 10px 0 rgb(0 0 0 / 40%)",

    backgroundColor: "#fff",
    opacity: 0.95,

    height: "100%",
    width: "100%",

    borderRadius: 4,

    overflow: "hidden",

    display: "flex",
    flexDirection: "column"
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    boxShadow: "0 3px 5px 0 rgb(0 0 0 / 10%)",
    paddingLeft: 22,
  },
  header: {
    fontWeight: 600
  },

  cancel: {},

  listContainer: {
    overflow: "auto",
    paddingBottom: 10,

    fontSize: "12px"
  },

  list: {

  },
  row: {
    padding: "0 30px 0 30px",
    display: "flex",
    justifyContent: "space-between",

    lineHeight: "35px"
  },
  name: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginRight: 10
  },
  label: {
    color: "#939ca5",

    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  group: {

  },

  groupHead: {
    padding: "0 10px",
    backgroundColor: "#0002",
    cursor: "pointer",
    userSelect: "none",

    display: "flex",
    alignItems: "center",
    lineHeight: "35px"
  },

  groupIcon: {

  },
  groupIconExpanded: {
    transform: "rotate(90deg)"
  },
  groupLabel: {
    paddingLeft: 10,
    fontWeight: 600
  },
}))

interface IPropertiesPanelProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPropertiesPanelViewModel
}

export const PropertiesPanel = observer((props: IPropertiesPanelProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div className={classes.header}>{props.viewModel.header}</div>
        <IconButton onClick={props.viewModel.onClose} className={classes.cancel}>
          <Close />
        </IconButton>
      </div>
      <StatusWrapper className={clsx(classes.listContainer, classes.cancel)} viewModel={props.viewModel.statusWrapperViewModel}>
        <PropertiesList classes={classes} properties={props.viewModel.properties} />
      </StatusWrapper>
    </div>
  )
})

const ArrowRightIcon = <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill="#000" d="M5.47374 5.53492L1.21756 9.7911C0.939028 10.0696 0.487439 10.0696 0.208907 9.7911C-0.0696249 9.51257 -0.0696248 9.06098 0.208907 8.78245L3.99136 5L0.208907 1.21755C-0.0696241 0.939019 -0.0696241 0.48743 0.208908 0.208898C0.48744 -0.0696339 0.939029 -0.0696339 1.21756 0.208898L5.47374 4.46508C5.76917 4.76051 5.76917 5.23949 5.47374 5.53492Z" />
</svg>


const PropertiesList = observer((props: { classes: ReturnType<typeof useClasses>, properties: IPropertiesPanelProps["viewModel"]["properties"] }) => {
  const { classes } = props

  return (
    <div className={classes.list}>
      {props.properties.map(property => {
        if (property.type === EPropertyType.string) {
          return (
            <div key={property.key} className={classes.row}>
              <div className={classes.name} title={property.name}>{property.name}</div>
              <div className={classes.label} title={property.label}>{property.label}</div>
            </div>
          )
        }

        if (property.type === EPropertyType.object) {
          return (
            <div key={property.key} className={classes.group}>
              <div className={classes.groupHead} onClick={property.onClick}>
                <div className={clsx(classes.groupIcon, {
                  [classes.groupIconExpanded]: property.expanded
                })}>{ArrowRightIcon}</div>
                <div className={classes.groupLabel}>{property.name}</div>
              </div>
              {property.expanded ? <PropertiesList classes={classes} properties={property.properties} /> : null}
            </div>
          )
        }

        return null
      })}
    </div>
  )
})