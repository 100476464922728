import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { IElementsPropertyShortPanelViewModel } from "./interface";

export class ElementsPropertyShortPanelViewModel implements IElementsPropertyShortPanelViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get rows() {

    return [
      {
        name: "Объем",
        value: `${this.volume} м³`,
        key: "1"
      },
      {
        name: "Площадь",
        value: `${this.square} м²`,
        key: "2"
      }
    ]
  }

  @computed
  get volume() {
    const forgeElements = this.props.layers.usecases.selectedElements.selectedForgeElements

    return forgeElements.reduce((acc, forgeElement) => {
      return acc + forgeElement.volumeNumber
    }, 0).toFixed(2)
  }

  @computed
  get square() {
    const forgeElements = this.props.layers.usecases.selectedElements.selectedForgeElements

    return forgeElements.reduce((acc, forgeElement) => {
      return acc + forgeElement.squareNumber
    }, 0).toFixed(2)
  }
}