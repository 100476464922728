import { action, computed, makeObservable, observable } from "mobx"

import { TCompanyProps } from "../../models/Company";
import { SimpleViewModel } from "../../view-model/SimpleViewModel/SimpleViewModel";
import { ERemarkStatus, Remark } from "../../models/Remark";
import { SetViewModel } from "../../view-model/SimpleViewModel/SetViewModel";
import { isAfter, isSameDay } from "date-fns";
import { RemarkBase } from "../../models/RemarkBase";
export class FilterPanel {
  constructor(private props: {
  }) {
    this.props = props

    makeObservable(this)
  }

  selectedStatuses = new SetViewModel<ERemarkStatus>()
  selectedContractors = new SetViewModel<TCompanyProps["id"]>()
  selectedOwners = new SetViewModel<TCompanyProps["id"]>()
  selectedDeadlineStatus = new SetViewModel<"completed" | "expired" | "in process">()
  selectedRemarksBase = new SetViewModel<RemarkBase["id"]>()

  selectedDeadline = new SimpleViewModel({ value: "" })
  selectedCreatedAt = new SimpleViewModel({ value: "" })

  filterRemarks(remarks: Remark[]): Remark[] {
    return this.filterRemarksByStatus(
      this.filterRemarksByContractors(
        this.filterRemarksByOwners(
          this.filterRemarksByDeadline(
            this.filterRemarksByDeadlineStatus(
              this.filterRemarksByRemarkBase(
                this.filterRemarksByCreatedAt(
                  remarks
                )
              )
            )
          )
        )
      )
    )
  }

  filterRemarksByStatus(remarks: Remark[]) {
    if (this.selectedStatuses.size === 0) return remarks

    return remarks.filter(remark => this.selectedStatuses.has(remark.status))
  }

  filterRemarksByContractors(remarks: Remark[]) {
    if (this.selectedContractors.size === 0) return remarks

    return remarks.filter(remark => this.selectedContractors.has(remark.contractor))
  }

  filterRemarksByOwners(remarks: Remark[]) {
    if (this.selectedOwners.size === 0) return remarks

    return remarks.filter(remark => this.selectedOwners.has(remark.owner))
  }

  filterRemarksByDeadline(remarks: Remark[]) {
    const date = this.selectedDeadlineDate
    if (!date) return remarks

    return remarks.filter(remark => isAfter(date, remark.deadline))
  }

  filterRemarksByDeadlineStatus(remarks: Remark[]) {
    if (this.selectedDeadlineStatus.size === 0) return remarks

    const completed = this.selectedDeadlineStatus.has("completed")
    const expired = this.selectedDeadlineStatus.has("expired")
    const inProcess = this.selectedDeadlineStatus.has("in process")

    return remarks.filter(remark =>
      (expired && remark.expired) ||
      (completed && remark.status === ERemarkStatus.closed) ||
      (inProcess && (remark.status === ERemarkStatus.opened || remark.status === ERemarkStatus.pending)) ||
      false
    )
  }

  filterRemarksByRemarkBase(remarks: Remark[]) {
    if (this.selectedRemarksBase.size === 0) return remarks

    return remarks.filter(remark => this.selectedRemarksBase.has(remark.remarkBase))
  }

  filterRemarksByCreatedAt(remarks: Remark[]) {
    const date = this.selectedCreatedAtDate
    if (!date) return remarks

    return remarks.filter(remark => isSameDay(date, remark.createdAt))
  }

  @computed
  get selectedDeadlineDate() {
    return this.selectedDeadline.value ? new Date(this.selectedDeadline.value) : undefined
  }

  @computed
  get selectedCreatedAtDate() {
    return this.selectedCreatedAt.value ? new Date(this.selectedCreatedAt.value) : undefined
  }

  @computed
  get filterCount() {
    let count =
      this.selectedStatuses.size +
      this.selectedContractors.size +
      this.selectedOwners.size +
      this.selectedDeadlineStatus.size +
      this.selectedRemarksBase.size +
      0

    if (this.selectedCreatedAt.value) count++
    if (this.selectedDeadline.value) count++

    return count
  }

  @action
  сlearFilters() {
    this.selectedStatuses.clear()
    this.selectedContractors.clear()
    this.selectedOwners.clear()
    this.selectedDeadlineStatus.clear()
    this.selectedRemarksBase.clear()

    this.selectedDeadline.onChange("")
    this.selectedCreatedAt.onChange("")
  }
}