import { action, computed, makeObservable, observable } from "mobx";
import { computedFn } from "mobx-utils";
import { v4 } from "uuid";
import { ILayers } from "../../core/Layers/interface";
import { Bim, EBimType } from "../../models/Bim";
import { EBundleFileKeys } from "../../models/BundleFile";
import { ECamera } from "../../models/Camera";
import { EMeasurementType, IMeasurement, TMeasurementPoint } from "../../models/Measurement/interface";
import { IModelElement } from "../../models/ModelElement/interface";
import { EWindowSelectStage } from "../../usecase/SelectedElements/SelectedElements";
import { ISarexViewerViewModel, TModel } from "./interface";

export class SarexViewerViewModel implements ISarexViewerViewModel {
  constructor(private props: { layers: ILayers }) {
    makeObservable(this)
  }

  private shiftMode = false
  private preventNodeSelected = false
  private contextShowTimeout?: NodeJS.Timeout

  @computed
  private get bims() {
    return this.props.layers.usecases.bims.selectedAllBims.filter(bim => bim.type === EBimType.bundle)
  }

  // все модели
  @computed
  private get modelsArray() {
    return this.bims.map(bim => this.getModel(bim))
  }

  // модели с загруженными свойствами
  @computed
  get models() {
    return new Map(this.modelsArray.filter(model => model.isPropsLoaded).map(model => ([model.modelURL, model])))
  }

  // загруженные во вьювере модели
  @computed
  private get loadedModels() {
    return this.modelsArray.filter(model => model.isModelLoaded)
  }

  private getModel = computedFn((bim: Bim) => {
    return new ModelViewModel({ layers: this.props.layers, bim })
  })

  @computed
  get modelsNodesColors() {
    return this.loadedModels.map((model) => ({ model: model.modelURL, colorGroups: model.colorGroups }))
  }

  @computed
  get modelsNodesHide() {
    return this.loadedModels.map((model) => ({ model: model.modelURL, nodes: model.hidedNodes }))
  }

  @computed
  get camera() {
    return this.props.layers.usecases.ui.viewerDataRepository.get()?.camera || ECamera.default
  }

  @computed
  get editMeasurement() {
    return this.props.layers.usecases.ui.temporaryMeasurementsDataRepository.addMode
  }

  @computed
  get showMeasurement(): boolean {
    return this.props.layers.usecases.ui.measurementsDataRepository.get()?.toolActive || false
  }

  @computed
  get selectionBoxActive() {
    return (this.props.layers.usecases.selectedElements.windowStage === EWindowSelectStage.select) || (this.props.layers.usecases.selectedElements.windowStage === EWindowSelectStage.deselect)
  }

  // находим родительские элементы по дочерним геометрическим
  private async getModelElementsFromUrlAndModelIDAsync(props: [string, number[]][]): Promise<Set<IModelElement>> {
    const set = new Set<IModelElement>()

    for (const [url, modelIDs] of props) {
      const bim = this.modelsArray.find(model => model.modelURL === url)?.bim

      if (bim) {
        for (const modelID of modelIDs) {
          const modelElement = await this.props.layers.repositories.modelElementsRepository.getByGeometryModelIDAsync(bim, modelID)

          if (modelElement) {
            set.add(modelElement)
          }
        }
      }
    }

    return set
  }

  @action
  private async switchNode(model: string | null, node: number | null) {
    if (!model || !node) {
      return this.props.layers.usecases.forgeElements.onClear()
    }

    const modelElements = await this.getModelElementsFromUrlAndModelIDAsync([[model, [node]]])

    this.switchModelElements(modelElements)
  }

  get isMobile() {
    return this.props.layers.usecases.device.isMobile
  }

  getHeaders = () => {
    return this.props.layers.repositories.pdmAuthRepository.getAuthHeadersAsync()
  }


  onKeyDown(props: { key: string }) {
    if (props.key === "Shift" || props.key === "Control") {
      this.shiftMode = true
    }
  }

  onKeyUp(props: { key: string }) {
    if (props.key === "Shift" || props.key === "Control") {
      this.shiftMode = false
    }
  }

  onClick() {
    this.closeContextMenu()
  }

  @action
  onNodeSelected(props: { selectedNodeID: number, modelID: string }) {
    if (this.preventNodeSelected) {
      this.preventNodeSelected = false

      return
    }

    if (this.shiftMode) {
      this.switchNode(props.modelID, props.selectedNodeID)
    } else {
      this.selectNode(props.modelID, props.selectedNodeID)
    }
  }

  @action
  private async selectNode(model: string | null, node: number | null) {
    if (this.isMobile) return this.switchNode(model, node)

    if (!model || !node) {
      return this.props.layers.usecases.forgeElements.onClear()
    }

    const modelElements = await this.getModelElementsFromUrlAndModelIDAsync([[model, [node]]])
    this.selectModelElements(modelElements)
  }

  @action
  async selectNodes(props: [string, number[]][]) {
    const modelElements = await this.getModelElementsFromUrlAndModelIDAsync(props)
    this.selectModelElements(modelElements)
  }

  private selectModelElements(modelElements: Set<IModelElement>) {
    this.props.layers.usecases.forgeElements.onSelect(
      modelElements
    )
  }

  private switchModelElements(modelElements: Set<IModelElement>) {
    this.props.layers.usecases.forgeElements.onSwitch(
      modelElements
    )
  }

  @action
  onMeasurementCreated = (props: { points: TMeasurementPoint[], distance: number }) => {
    const measurement: IMeasurement = {
      id: v4(),
      points: props.points,
      type: EMeasurementType.polyline,
      name: `измерение ${props.distance.toFixed(2)}м`
    }
    this.props.layers.usecases.ui.temporaryMeasurementsDataRepository.addMode = false

    this.props.layers.usecases.ui.measurementsDataRepository.patch({
      measurements: {
        ...this.props.layers.usecases.ui.measurementsDataRepository.get()?.measurements,
        [measurement.id]: measurement,
      }
    })
  }

  @computed
  get measurements(): IMeasurement[] {
    if (!this.showMeasurement) return []
    return Object.values(this.props.layers.usecases.ui.measurementsDataRepository.get()?.measurements || [])
  }

  // getCameraInitialDataAsync() {
  //   return this.props.layers.usecases.bims.modelDataRepository?.get()?.cameraData
  // }

  // onCameraDataChanged(data: any) {
  //   this.props.layers.usecases.bims.modelDataRepository?.patch({
  //     cameraData: data
  //   })
  // }

  @action
  onContextMenu(props: { x: number, y: number }) {
    this.preventNodeSelected = true

    this.contextShowTimeout = setTimeout(() => {
      this.contextShowTimeout = undefined
      this.openContextMenu(props)
    }, 1000);
  }

  onMouseMove() {
    if (this.contextShowTimeout) {
      clearTimeout(this.contextShowTimeout)
      this.contextShowTimeout = undefined
    }
  }

  @action
  private openContextMenu(props: { x: number, y: number }) {
    this.props.layers.usecases.ui.temporaryViewerDataRepository = {
      ...this.props.layers.usecases.ui.temporaryViewerDataRepository,
      contextMenu: {
        x: props.x,
        y: props.y
      }
    }
  }

  @action
  private closeContextMenu() {
    this.props.layers.usecases.ui.temporaryViewerDataRepository.contextMenu = undefined
  }

  private showContextMenuTimeout?: NodeJS.Timeout

  onTouchStart(props: { x: number, y: number }) {
    this.closeContextMenu()
    this.clearShowContextMenuTimeout()

    this.showContextMenuTimeout = setTimeout(() => {
      this.showContextMenuTimeout = undefined
      this.openContextMenu(props)
    }, 500);
  }

  onTouchMove() {
    this.clearShowContextMenuTimeout()
  }

  onTouchEnd() {
    this.clearShowContextMenuTimeout()
  }

  private clearShowContextMenuTimeout() {
    if (this.showContextMenuTimeout) {
      clearTimeout(this.showContextMenuTimeout)
      this.showContextMenuTimeout = undefined
    }
  }

  setGetScreenShotCanvasMethod(method) {
    this.props.layers.usecases.ui.getViewerCanvas = method
  }
}

class ModelViewModel implements TModel {
  constructor(private props: { layers: ILayers, bim: Bim }) {
    this.props = props

    makeObservable(this)

    this.load()
  }

  get bim() {
    return this.props.bim
  }

  @observable
  isPropsLoaded = false

  @observable
  isModelLoaded = false

  @observable
  modelURL = ""

  modelFormat = "pop"

  @observable
  metadataURL = ""

  @observable
  staticMetadataURL = ""

  @computed
  get rangeRequestsUsingQuery() {
    return this.props.layers.repositories.bundleRepository.rangeRequestsUsingQuery
  }

  private selectedColor = "rgb(0, 160, 255)"

  @computed
  get colorGroups() {
    if (this.props.layers.usecases.bims.selectedModelWorkTypeId && (this.bim.modelWorkType !== this.props.layers.usecases.bims.selectedModelWorkTypeId)) return []

    return this.getModelColorGroups(this.bim)
  }

  @computed
  get hidedNodes() {
    return this.getModelHideNodes(this.bim)
  }

  async getFilterObjectsAsync() {
    return this.hidedNodes
  }

  async getColorGroupsAsync() {
    return this.colorGroups
  }

  private getModelHideNodes(bim: Bim): number[] {
    const modelElements = this.props.layers.repositories.modelElementsRepository.getByBim(bim).filter(modelElement =>
      this.props.layers.usecases.hidedElements.getElementHided(bim, modelElement)
    )
    return this.getGeometricsModelIDForModelElements(modelElements)
  }

  private getGeometricsModelIDForModelElements(modelElements: IModelElement[]): IModelElement["modelID"][] {
    const geometricsSet = new Set<IModelElement["modelID"]>()

    for (const modelElement of modelElements) {
      for (const modelID of modelElement.geometryElementsModelID) {
        geometricsSet.add(modelID)
      }
    }

    return Array.from(geometricsSet)
  }

  private getModelColorGroups(bim: Bim): { color: string, nodes: number[] }[] {
    const colorsMap = new Map<string, Array<number>>()

    for (const modelElement of this.props.layers.repositories.modelElementsRepository.getByBim(bim)) {
      const color = this.props.layers.usecases.selectedElements.getForgeElementSelected(modelElement)
        ? this.selectedColor
        : this.props.layers.usecases.paintElements.getElementColor(modelElement)

      if (color) {
        let array = colorsMap.get(color)
        if (!array) {
          array = new Array()
          colorsMap.set(color, array)
        }
        for (const modelID of modelElement.geometryElementsModelID) {
          array.push(modelID)
        }
      }
    }

    return Array.from(colorsMap.entries()).map(([color, nodes]) => ({ color, nodes }))
  }

  private async load() {
    const bundle = await this.props.layers.repositories.bundleRepository.getById(this.bim.bundle)
    if (!bundle) return

    const file = bundle.getFileByKey(EBundleFileKeys.bim_optimized)
    if (!file) return

    this.modelURL = file.url
    this.metadataURL = bundle.getFileByKey(EBundleFileKeys.bim_metadata)?.url || ""
    this.staticMetadataURL = bundle.getFileByKey(EBundleFileKeys.bim_metadata_static)?.url || ""

    // load elements
    await this.props.layers.repositories.modelElementsRepository.getByBimAsync(this.bim)

    this.isPropsLoaded = true
  }

  @action
  onModelLoaded() {
    this.isModelLoaded = true
  }
}