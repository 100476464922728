import React, { memo, useEffect, useRef } from "react";

CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    this.beginPath();
    this.moveTo(x + radius, y);
    this.arcTo(x + width, y, x + width, y + height, radius);
    this.arcTo(x + width, y + height, x, y + height, radius);
    this.arcTo(x, y + height, x, y, radius);
    this.arcTo(x, y, x + width, y, radius);
    this.closePath();
    return this;
}

function BarChart({ data, height, width = "100%", totalValue }) {
    const canvasRef = useRef();

    useEffect(() => {
        const canvas = canvasRef.current;
        if (data && canvas) {
            const ctx = canvas.getContext("2d");
            const width = canvas.width;
            const height = canvas.height;
            const barWidth = width / data.length;
            const barMargin = 32;

            const total = totalValue !== undefined ? totalValue : data.reduce((acc, { value }) => acc + value, 0);

            ctx.font = "10px serif";
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            data.forEach(({ color, value, valueLabel }, index) => {
                const heightStart = (1 - value / total) * (height - barMargin);

                ctx.fillStyle = color;
                ctx.beginPath();
                ctx.roundRect(index * barWidth, heightStart + barMargin, barWidth, height, 4);
                ctx.fill();

                ctx.fillStyle = "#000";
                const label = valueLabel !== undefined ? String(valueLabel) : String(value);
                const labels = label.split("\n");

                labels.forEach((label, labelIndex) => {
                    if (label) {
                        ctx.fillText(label, (index + 0.5) * barWidth, heightStart + barMargin - 6 - (labels.length - 1 - labelIndex) * 12);
                    }
                })
            })

            return () => {
                ctx.clearRect(0, 0, width, height);
            }
        }
    }, [data, totalValue]);

    return (
        <canvas ref={canvasRef} height={height} width={width} />
    )
}

export default memo(BarChart)