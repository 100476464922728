import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { ICheckboxWithLabelViewModel } from "../../view-model/CheckboxViewModel/interface"
import { SliderCheckbox } from "./SliderCheckbox"
import clsx from "clsx"

const useClasses = makeStyles(theme => ({
  root: {
    padding: "7px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  label: {
    fontSize: "12px"
  }
}))

interface ISliderCheckboxWithLabelProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: ICheckboxWithLabelViewModel
  className?: string
}

export const SliderCheckboxWithLabel = observer((props: ISliderCheckboxWithLabelProps) => {
  const classes = useClasses(props)

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={classes.label}>{props.viewModel.label}</div>
      <SliderCheckbox viewModel={props.viewModel} />
    </div>
  )
})