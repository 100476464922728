import { computed, makeObservable } from "mobx";
import { fromPromise } from "mobx-utils";
import { ILayers } from "../../core/Layers/interface";
import { EHomeSectionCodes } from "../../models/HomeSectionCodes";
import { Target, TTargetProps } from "../../models/Target";
import { EVariants } from "../../usecase/Notifications/Notifications";
import { ITargetsListViewModel, TTargetItemViewModel } from "./interface";

export class TargetsListViewModel implements ITargetsListViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get targets(): ITargetsListViewModel["targets"] {
    return this.targetsPromise.state === "fulfilled" ? this.targetsPromise.value : []
  }

  @computed
  get targetsPromise() {
    return fromPromise((async () => {
      const projectId = this.props.layers.usecases.viewState.props.selectedProjectId
      const targets = projectId ? await this.props.layers.repositories.targetsRepository.getByProjectId(projectId) : []

      return targets.map(target => ({
        key: target.id,
        viewModel: new TargetItemViewModel({ layers: this.props.layers, target })
      }))
    })())
  }
}

class TargetItemViewModel implements TTargetItemViewModel {
  constructor(private props: { target: Target, layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  get id() {
    return this.props.target.id
  }

  get name() {
    return this.props.target.name
  }

  @computed
  get allowAdd() {
    return this.props.layers.usecases.viewState.props.mainPageSection === EHomeSectionCodes.files
  }

  @computed
  get visibleUploadButton() {
    return false
  }

  @computed
  get additionalContent() {
    return this.additionalContentPromise.state === "fulfilled" ? this.additionalContentPromise.value : "?"
  }

  @computed
  get additionalContentPromise() {
    return fromPromise(this.getAdditionalContent(this.props.target))
  }

  async getRemarksLabel(id: TTargetProps["id"]) {
    const remarks = await this.props.layers.usecases.filtredRemarksTable.getFiltredRemarksByTargetId(id)
    return `Замечаний: ${remarks.length}`
  }

  async getPrescriptionsLabel(id: TTargetProps["id"]) {
    const prescriptions = await this.props.layers.usecases.filtredPrescriptionsTable.getFiltredPrescriptionsByTargetId(id)
    return `Предписаний: ${prescriptions.length}`
  }

  async getAdditionalContent(target: Target): Promise<string> {
    const mainPageSection = this.props.layers.usecases.viewState.props.mainPageSection
    switch (mainPageSection) {
      case EHomeSectionCodes.remarks:
        return this.getRemarksLabel(target.id)
      case EHomeSectionCodes.prescriptions:
        return this.getPrescriptionsLabel(target.id)
    }

    return ""
  }

  // async onAdd(file: File) {
  //   // const notificationKey = await this.props.layers.usecases.notifications.wait(`загрузка файла ${file.name}`)

  //   // try {
  //   //   await this.props.layers.repositories.bimsRepository.add(this.props.target.id, file)
  //   //   this.props.layers.usecases.notifications.notify("загрузка завершена", {
  //   //     variant: EVariants.success
  //   //   })
  //   // } catch (error) {
  //   //   this.props.layers.usecases.notifications.wait("ошибка", {
  //   //     variant: EVariants.error
  //   //   })
  //   // }
  //   // this.props.layers.usecases.notifications.close(notificationKey)
  // }
}