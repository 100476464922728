import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IDatePickerViewModel } from "../../view-model/DatePickerViewModel/interface"
import DateFnsUtils from '@date-io/date-fns';

import {
  DatePicker as DatePickerMaterial,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  IconButton
} from '@material-ui/core';
import clsx from "clsx";
import {
  format,
  getDayOfYear
} from "date-fns";

const useClasses = makeStyles(theme => ({
  dayContainer: {
    margin: 8
  },
  dayButton: {
    width: 30,
    height: 30,
    fontWeight: "normal",
    fontSize: 12,
    color: "#000",
    padding: 0,
    '&:hover': {
      backgroundColor: "#fdc50a",
      fontWeight: "bold",
    }
  },
  selectedDay: {
    backgroundColor: "#fdc50a",
    color: '#000',
    fontWeight: "bold",
  },
  hidedDay: {
    opacity: 0,
  },
  hidedDayButton: {
    cursor: 'initial'
  },
  dialog: {
    flex: "inherit",
    "& .MuiPickersBasePicker-containerLandscape ": {
      flexDirection: "column"
    },
    "& .MuiToolbar-root .MuiTypography-root": {
      color: "white"
    },
    "& .MuiPickersBasePicker-pickerView": {
      margin: 0,
      maxWidth: 'inherit',
      minWidth: 'inherit',
      minHeight: 'inherit',
      "& .MuiPickersCalendarHeader-switchHeader": {
        margin: 0,
        padding: "0 20px",
        paddingTop: 20,
        marginBottom: 12,
        "& .MuiIconButton-root": {
          borderRadius: 8,
          backgroundColor: '#F5F5F5 !important',
          padding: "13px 17px",
          "& .MuiSvgIcon-root": {
            fill: "#000",
            fontSize: 32
          }
        },
        "& .MuiPickersCalendarHeader-transitionContainer": {
          height: 44,
        }
      },
      "& .MuiPickersCalendarHeader-daysHeader": {
        display: 'none'
      },
      "& .MuiPickersCalendar-transitionContainer": {
        minHeight: 240,
        margin: 0,
      },
      "& .MuiTypography-root": {
        height: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: "capitalize",
        fontWeight: 'bold',
        fontSize: 24,

      },
    },
  },
  dialogRoot: {
    borderRadius: 14,
    minHeight: 408,
    minWidth: 360,
    '& .MuiDialogActions-root': {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      padding: 20,
      '& .MuiButton-root': {
        minWidth: 150,
        color: "#000",
        textTransform: 'none',
        fontSize: 12,
        fontWeight: 'bold',
        backgroundColor: "#F5F5F5",
        borderRadius: 8,
        "&:hover": {
          backgroundColor: "#fdc50a",
          "& .MuiButton-label": {
            opacity: 1
          }
        },
        "& .MuiButton-label": {
          opacity: 0.5,
        }
      },
      "& .MuiButton-text": {
        padding: "11.5px 8px"
      }
    },
  },
}))

interface IDatePickerProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IDatePickerViewModel
}

const LeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
      <path
        d="M0.469309 8.06581L7.34581 1.18931C7.79582 0.739304 8.52543 0.739304 8.97544 1.18931C9.42545 1.63932 9.42545 2.36893 8.97544 2.81894L2.86433 8.93005L8.97544 15.0412C9.42545 15.4912 9.42545 16.2208 8.97544 16.6708C8.52543 17.1208 7.79582 17.1208 7.34581 16.6708L0.469309 9.7943C-0.00799876 9.31699 -0.00799876 8.54312 0.469309 8.06581Z"
        fill="black" />
    </svg>
  )
}

const RightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
    <path
      d="M9.53069 9.93419L2.65419 16.8107C2.20418 17.2607 1.47457 17.2607 1.02456 16.8107C0.574552 16.3607 0.574552 15.6311 1.02456 15.1811L7.13567 9.06995L1.02456 2.95883C0.574551 2.50882 0.574551 1.77921 1.02456 1.3292C1.47457 0.879195 2.20418 0.879195 2.65419 1.3292L9.53069 8.2057C10.008 8.68301 10.008 9.45688 9.53069 9.93419Z"
      fill="black" />
  </svg>
)

function EmptyComponent() {
  return null;
}

export const DatePicker = observer((props: IDatePickerProps) => {
  const classes = useClasses(props)

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
    // locale="ru"
    >
      <DatePickerMaterial
        disableToolbar
        open={props.viewModel.open}
        onClose={props.viewModel.onClose}
        TextFieldComponent={EmptyComponent}
        value={props.viewModel.value}
        onChange={props.viewModel.onChange}
        DialogProps={{
          //@ts-ignore
          classes: {
            dialogRoot: classes.dialogRoot,
            dialog: classes.dialog,
          }
        }}
        renderDay={(date, selectedDate, dayInCurrentMonth) => {
          return RenderDay({
            date,
            //@ts-ignore
            selected: date && dayInCurrentMonth && (getDayOfYear(date) === getDayOfYear(selectedDate)),
            hided: !dayInCurrentMonth,
            classes: classes
          })
        }}
        okLabel={props.viewModel.applyButtonLabel}
        cancelLabel="Отмена"
        leftArrowIcon={<LeftIcon />}
        rightArrowIcon={<RightIcon />}
      />
    </MuiPickersUtilsProvider>
  )
})

const RenderDay = ({
  date,
  selected,
  hided,
  classes
}) => {

  const wrapperClassName = clsx(classes.dayContainer, {
    [classes.hidedDay]: hided,
  });

  const dayClassName = clsx(classes.dayButton, {
    [classes.selectedDay]: selected,
    [classes.hidedDayButton]: hided,
  });

  return (
    <div className={wrapperClassName}>
      <IconButton className={dayClassName}>
        <span>{format(date, "dd")}</span>
      </IconButton>
    </div>
  )
}