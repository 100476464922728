import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { TTargetProps } from "../../models/Target";
import { IStatusWrapperViewModel } from "../StatusWrapperViewModel/interface";
import { StatusWrapperForPromiseViewModel } from "../StatusWrapperViewModel/StatusWrapperForPromiseViewModel";
import { IRemarksTableViewModel, TRemarkTable } from "./interface";

export class RemarksTableViewModel implements IRemarksTableViewModel {
  constructor(private props: { layers: ILayers, targetId: TTargetProps["id"] }) {
    this.props = props

    this.statusWrapperViewModel = new StatusWrapperForPromiseViewModel({
      promise: this.props.layers.usecases.filtredRemarksTable.getFiltredRemarksByTargetIdPromise(this.props.targetId)
    })

    makeObservable(this)
  }

  statusWrapperViewModel: IStatusWrapperViewModel


  @computed
  get remarks(): TRemarkTable[] {
    return this.props.layers.usecases.filtredRemarksTable.getFiltredRemarksByTargetId(this.props.targetId)
  }
}