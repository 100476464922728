export enum EUnits {
  thing = "шт",
  volume = "м3",
  square = "м2",
  length = "м",
}

export enum EUnitRepresentation {
  absolute,
  percent,
}

export const unitLabel = {
  [EUnits.thing]: "шт",
  [EUnits.volume]: "м3",
  [EUnits.square]: "м2",
  [EUnits.length]: "м",
}