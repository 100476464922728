import { action, computed, makeObservable, ObservableSet } from "mobx";
import { Bim } from "../../models/Bim";
import { IModelElementsRepository } from "../../repositories/ModelElementsRepository/interface";
import { Bims } from "../Bims/Bims";
export class SelectedStoreys {
  constructor(private props: {
    bims: Bims
    modelElementsRepository: IModelElementsRepository
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get selectedStoreysArray() {
    return this.props.bims.modelDataRepository?.get()?.selectedStoreys || []
  }

  @computed
  private get selectedStoreysSet() {
    return new Set(this.selectedStoreysArray)
  }

  @computed
  get bims() {
    return this.props.bims.selectedBims
  }

  @computed
  get selectedStoreysSize() {
    return this.selectedStoreysSet.size
  }

  // @computed
  // get selectedStoreysArray() {
  //   return Array.from(this.selectedStoreys)
  // }

  @computed
  get storeys() {
    const storeys = new Map<string, { id: string, name: string, number: number, bim: Set<Bim> }>()

    for (const bim of this.bims) {
      for (const forgeElement of this.props.modelElementsRepository.getByBim(bim)) {
        const storey = forgeElement.storey
        if (storey) {
          if (storeys.has(storey)) {
            storeys.get(storey)?.bim.add(bim)
          } else {
            storeys.set(storey, {
              name: storey,
              number: Number(forgeElement.storey),
              bim: new Set([bim]),
              id: storey
            })
          }
        }
      }
    }
    const array = Array.from(storeys.values())

    array.sort((a, b) => a.number - b.number)

    return array
  }

  getStoreySelected(storey: string) {
    return this.selectedStoreysSet.has(storey)
  }

  @action
  resetSelectedStoreys() {
    this.props.bims.modelDataRepository?.patch({
      selectedStoreys: []
    })
  }

  @action
  switchSelectedStorey(storey: string) {
    this.selectedStoreysSet.has(storey) ? this.selectedStoreysSet.delete(storey) : this.selectedStoreysSet.add(storey)

    this.props.bims.modelDataRepository?.patch({
      selectedStoreys: Array.from(this.selectedStoreysSet)
    })
  }

  @action
  setSelectedStorey(values: string[]) {
    this.props.bims.modelDataRepository?.patch({
      selectedStoreys: values
    })
  }
}