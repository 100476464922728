import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IPanelSelectorViewModel } from "../../view-model/PanelSelectorViewModel/interface"
import clsx from "clsx"
import { PanelDropdownMenu } from "../PanelDropdownMenu/PanelDropdownMenu"
import { EOrientations } from "../../view-model/PanelDropdownMenuViewModel/interface"

const useClasses = makeStyles<
  any,
  {
    classes?: any
    orientation: EOrientations
  }>(theme => ({
    root: {
      display: "flex",
      flexDirection: ({ orientation }) => orientation === EOrientations.right || orientation === EOrientations.left ? "row" : "column"
    },
    hidden: {
      display: "none"
    },
    selector: {
      flex: 1,
      maxWidth: "100%",
      display: "flex",
      lineHeight: "44px",
      cursor: "pointer",
      userSelect: "none",

      "&:hover": {
        backgroundColor: "#f5f5f5"
      }
    },
    disabledSelector: {
      cursor: "initial",

      "&:hover": {
        backgroundColor: "inherit"
      }
    },
    padding: {
      padding: "0 15px",
    },
    label: {
      fontWeight: "normal",
      fontSize: "12px",
      flex: 1
    },
    value: {
      textAlign: "right",
      fontWeight: "bold",
      fontSize: "12px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "50%",
      marginRight: 15,
      flex: 1
    },
  }))


interface IPanelSelectorProps {
  className?: string
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPanelSelectorViewModel
}

const ArrowRightIcon = <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.47374 5.53492L1.21756 9.7911C0.939028 10.0696 0.487439 10.0696 0.208907 9.7911C-0.0696249 9.51257 -0.0696248 9.06098 0.208907 8.78245L3.99136 5L0.208907 1.21755C-0.0696241 0.939019 -0.0696241 0.48743 0.208908 0.208898C0.48744 -0.0696339 0.939029 -0.0696339 1.21756 0.208898L5.47374 4.46508C5.76917 4.76051 5.76917 5.23949 5.47374 5.53492Z" fill="#AAAAA9" />
</svg>

export const PanelSelector = observer((props: IPanelSelectorProps) => {
  // const {
  //   orientation = "auto",
  //   disabled = false,
  // } = props.viewModel;

  // const rootRef = useRef<HTMLDivElement>(null);
  // const [menuRender, setMenuRender] = useState(false);
  // const [menuOpen, setMenuOpen] = useReducer((state, action) => {
  //   if (disabled) return false

  //   if (action) {
  //     setMenuRender(true);
  //   }
  //   return action;
  // }, false);

  // const [calculatedOrientation, setCalculatedOrientation] = useState<IPanelSelectorViewModel["orientation"]>();

  // // pre orientation
  // const preparedOrientation = useMemo(() => {
  //   if (orientation === "auto") {
  //     if (rootRef.current) {
  //       const allowedWidth = window.innerWidth - rootRef.current.getBoundingClientRect().right;
  //       if (allowedWidth < 100) {
  //         return "bottom"
  //       } else {
  //         return "right"
  //       }
  //     }
  //     return "right"
  //   } else {
  //     return orientation;
  //   }
  // }, [menuOpen]);

  // // post orientation
  // useEffect(() => {
  //   if (menuOpen && orientation === "auto" && rootRef.current) {
  //     const allowedWidth = window.innerWidth - rootRef.current.getBoundingClientRect().right;
  //     if (allowedWidth < 100) {
  //       setCalculatedOrientation("bottom")
  //     } else {
  //       setCalculatedOrientation("right")
  //     }
  //   }
  // }, []);

  const classes = useClasses({
    classes: props.classes,
    orientation: props.viewModel.orientation
    // orientation: calculatedOrientation || preparedOrientation
  });

  // const switchMenu = useCallback(() => {
  //   setMenuOpen(!menuOpen)
  // }, [menuOpen]);

  // useEffect(() => {
  //   if (menuOpen) {
  //     function closeMenuHandler() {
  //       setMenuOpen(false);
  //     }
  //     window.addEventListener("click", closeMenuHandler);

  //     return () => {
  //       window.removeEventListener("click", closeMenuHandler);
  //     }
  //   }
  // }, [menuOpen]);

  return (
    <div className={clsx(classes.root, props.className)}
    // ref={rootRef}
    >
      <div className={clsx(classes.selector, classes.padding, {
        [classes.disabledSelector]: props.viewModel.disabled
      })} onClick={props.viewModel.onClick}>
        <div className={classes.label} title={props.viewModel.label}>{props.viewModel.label}</div>
        <div className={classes.value} title={props.viewModel.valueLabel}>{props.viewModel.valueLabel}</div>
        <div className={clsx(classes.icon, {
          [classes.hidden]: props.viewModel.disabled
        })}>{ArrowRightIcon}</div>
      </div>
      <PanelDropdownMenu viewModel={props.viewModel.panelDropdownMenuViewModel} />
      {/* PanelDropdownMenu */}
    </div>
  )
})