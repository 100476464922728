import React, { useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IProjectStatisticsViewModel } from "../../view-model/ProjectStatisticsViewModel/interface"
import BarChart from "../../components/Charts/BarChart";
import { StatisticsTable } from "../StatisticsTable/StatisticsTable";
import { PieChartWithLegend } from "../PieChartWithLegend/PieChartWithLegend";
import clsx from "clsx";
import { StatusWrapper } from "../StatusWrapper/StatusWrapper";
import { PlanFactChart } from "../PlanFactChart/PlanFactChart";

const margin = 40;

const useClasses = makeStyles(theme => ({
  root: {},
  header: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: 500
  },
  projectName: {
    fontSize: "30px",
    textAlign: "center",
    fontWeight: 500,
  },
  center: {
    textAlign: "center"
  },
  margin: {
    marginTop: margin
  },
  marginChart: {
    marginTop: margin / 2
  },
  barChartsContainer: {
    display: "flex",
    backgroundColor: "#0001",
    justifyContent: "center",
  },
  bar: {
    "& >:first-child": {
      margin: "0 5px"
    }
  },
  barLegend: {
    fontSize: "10px",
    textAlign: "center",
  },
  pieChart: {
    marginTop: margin / 2,

    textAlign: "initial"
  },
  bold: {
    fontWeight: 500
  },
  header3: {
    fontSize: 18
  },
  pieChartInList: {
    marginLeft: 20
  },
  minBlock: {
    minHeight: 100
  }
}))

interface IProjectStatisticsProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IProjectStatisticsViewModel
  className?: string
}

const pieChartProps = {
  width: 200,
  height: 200
}

const pieChartProps2 = {
  width: 400,
  height: 400
}

export const ProjectStatistics = observer((props: IProjectStatisticsProps) => {
  const classes = useClasses(props)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) props.viewModel.setNode(ref.current)
  }, [ref.current])

  return (
    <div ref={ref} className={clsx(classes.root, props.className)}>
      <div className={classes.header}>{props.viewModel.header}</div>
      <div className={clsx(classes.projectName, classes.margin)}>{props.viewModel.projectName}</div>

      {
        props.viewModel.factForProjectViewModels ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.factForProjectViewModels.header}</div>
            <StatusWrapper className={classes.minBlock} viewModel={props.viewModel.factForProjectViewModels.statusWrapperViewModel}>
              <>
                {
                  props.viewModel.factForProjectViewModels.viewModels.map(viewModel => (
                    <div className={classes.margin} key={viewModel.key}>
                      <div className={clsx(classes.bold, classes.header3)}>{viewModel.header}</div>
                      <div className={clsx(classes.margin, classes.barChartsContainer)}>
                        {
                          viewModel.barChartViewModels.map(({ key, viewModel }) => (
                            <div className={classes.bar} key={key}>
                              <BarChart data={viewModel.data} totalValue={viewModel.value} height={120} width={60 * viewModel.data.length} />
                              <div className={classes.barLegend}>{viewModel.label}</div>
                            </div>
                          ))
                        }
                      </div>
                      {/* <StatisticsTable className={classes.margin} viewModel={viewModel.statisticsTableViewModel} /> */}
                    </div>
                  ))
                }
              </>
            </StatusWrapper>
          </div>
          : null
      }

      {
        props.viewModel.workStatusViewModels ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.workStatusViewModels.header}</div>
            <StatusWrapper className={classes.minBlock} viewModel={props.viewModel.workStatusViewModels.statusWrapperViewModel}>
              <>
                {
                  props.viewModel.workStatusViewModels.viewModels.map(workStatusViewModel => (
                    <div key={workStatusViewModel.key} className={classes.margin}>
                      <div className={clsx(classes.bold, classes.header3)}>{workStatusViewModel.header}</div>
                      <PieChartWithLegend className={classes.pieChart} viewModel={workStatusViewModel.projectPieChartViewModel} pieChartProps={pieChartProps} />
                      <div>
                        {
                          workStatusViewModel.collectionsPieChartViewModels.map(collectionsPieChartViewModel => (
                            <PieChartWithLegend key={collectionsPieChartViewModel.key} className={clsx(classes.pieChart, classes.pieChartInList)} viewModel={collectionsPieChartViewModel.viewModel} pieChartProps={pieChartProps} />
                          ))
                        }
                      </div>

                    </div>
                  ))
                }
              </>
            </StatusWrapper>
          </div>

          : null
      }

      {
        props.viewModel.remarksStatusForProjectViewModel ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.remarksStatusForProjectViewModel.header}</div>
            <StatusWrapper viewModel={props.viewModel.remarksStatusForProjectViewModel.statusWrapperViewModel}>
              <PieChartWithLegend className={classes.pieChart} viewModel={props.viewModel.remarksStatusForProjectViewModel.pieChartViewModel} pieChartProps={pieChartProps} />
            </StatusWrapper>
          </div>
          : null
      }

      {
        props.viewModel.remarksStatusForCollectionsViewModel ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.remarksStatusForCollectionsViewModel.header}</div>
            <StatusWrapper viewModel={props.viewModel.remarksStatusForCollectionsViewModel.statusWrapperViewModel}>
              <>
                {
                  props.viewModel.remarksStatusForCollectionsViewModel.pieChartViewModels.map(({ key, viewModel }) =>
                    <PieChartWithLegend className={clsx(classes.pieChart, classes.pieChartInList)} key={key} viewModel={viewModel} pieChartProps={pieChartProps} />
                  )
                }
              </>
            </StatusWrapper>

          </div>
          : null
      }

      {
        props.viewModel.deadlineForTheProject ?
          <>
            <div className={clsx(classes.margin, classes.center, classes.header, classes.bold)}>Соблюдение срока исправления замечаний подрядчиком</div>
            <div className={clsx(classes.margin, classes.center)}>
              <div className={classes.header}>{props.viewModel.deadlineForTheProject.header}</div>
              <StatusWrapper viewModel={props.viewModel.deadlineForTheProject.statusWrapperViewModel}>
                <PieChartWithLegend className={classes.pieChart} viewModel={props.viewModel.deadlineForTheProject.pieChartViewModel} pieChartProps={pieChartProps} />
              </StatusWrapper>
            </div>
          </>
          : null
      }

      {
        props.viewModel.deadlineForTheCollections ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.deadlineForTheCollections.header}</div>
            <StatusWrapper viewModel={props.viewModel.deadlineForTheCollections.statusWrapperViewModel}>
              <>
                {
                  props.viewModel.deadlineForTheCollections.pieChartViewModels.map(({ key, viewModel }) =>
                    <PieChartWithLegend className={clsx(classes.pieChart, classes.pieChartInList)} key={key} viewModel={viewModel} pieChartProps={pieChartProps} />
                  )
                }
              </>
            </StatusWrapper>
          </div>
          : null
      }

      {
        props.viewModel.typeOfRemarksForTheProject ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.typeOfRemarksForTheProject.header}</div>
            <StatusWrapper viewModel={props.viewModel.typeOfRemarksForTheProject.statusWrapperViewModel}>
              <PieChartWithLegend className={classes.pieChart} viewModel={props.viewModel.typeOfRemarksForTheProject.pieChartViewModel} pieChartProps={pieChartProps} />
            </StatusWrapper>
          </div>
          : null
      }

      {
        props.viewModel.typeOfRemarksForTheCollections ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.typeOfRemarksForTheCollections.header}</div>
            <StatusWrapper viewModel={props.viewModel.typeOfRemarksForTheCollections.statusWrapperViewModel}>
              <>
                {
                  props.viewModel.typeOfRemarksForTheCollections.pieChartViewModels.map(({ key, viewModel }) =>
                    <PieChartWithLegend className={clsx(classes.pieChart, classes.pieChartInList)} key={key} viewModel={viewModel} pieChartProps={pieChartProps} />
                  )
                }
              </>
            </StatusWrapper>
          </div>
          : null
      }

      {
        props.viewModel.workStatusRemarksOnProject ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.workStatusRemarksOnProject.header}</div>
            <StatusWrapper viewModel={props.viewModel.workStatusRemarksOnProject.statusWrapperViewModel}>
              <PieChartWithLegend className={classes.pieChart} viewModel={props.viewModel.workStatusRemarksOnProject.pieChartViewModel} pieChartProps={pieChartProps2} />
            </StatusWrapper>
          </div>
          : null
      }

      {
        props.viewModel.criticalRemarksForTheProject ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.criticalRemarksForTheProject.header}</div>
            <StatusWrapper viewModel={props.viewModel.criticalRemarksForTheProject.statusWrapperViewModel}>
              <PieChartWithLegend className={classes.pieChart} viewModel={props.viewModel.criticalRemarksForTheProject.pieChartViewModel} pieChartProps={pieChartProps} />
            </StatusWrapper>
          </div>
          : null
      }

      {
        props.viewModel.criticalRemarksForTheCollections ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.criticalRemarksForTheCollections.header}</div>
            <StatusWrapper viewModel={props.viewModel.criticalRemarksForTheCollections.statusWrapperViewModel}>
              <>
                {
                  props.viewModel.criticalRemarksForTheCollections.pieChartViewModels.map(({ key, viewModel }) =>
                    <PieChartWithLegend className={clsx(classes.pieChart, classes.pieChartInList)} key={key} viewModel={viewModel} pieChartProps={pieChartProps} />
                  )
                }
              </>
            </StatusWrapper>
          </div>
          : null
      }

      {
        props.viewModel.collectionRemarksForTheProject ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.collectionRemarksForTheProject.header}</div>
            <StatusWrapper viewModel={props.viewModel.collectionRemarksForTheProject.statusWrapperViewModel}>
              <PieChartWithLegend className={classes.pieChart} viewModel={props.viewModel.collectionRemarksForTheProject.pieChartViewModel} pieChartProps={pieChartProps2} />
            </StatusWrapper>
          </div>
          : null
      }

      {
        props.viewModel.planFactViewModels ?
          <div className={clsx(classes.margin, classes.center)}>
            <div className={classes.header}>{props.viewModel.planFactViewModels.header}</div>
            <StatusWrapper viewModel={props.viewModel.planFactViewModels.statusWrapperViewModel}>
              <>
                {
                  props.viewModel.planFactViewModels.viewModels.map(viewModel => (
                    <PlanFactChart key={viewModel.key} className={clsx(classes.marginChart, classes.pieChart)} viewModel={viewModel.planFactChartViewModel} {...pieChartProps2} />
                  ))
                }
              </>
            </StatusWrapper>
          </div>
          : null
      }
    </div>
  )
})