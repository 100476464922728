import { action, computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { IModelElement } from "../../models/ModelElement/interface";
import { IItem, IViewerContextMenuViewModel } from "./interface";

export class ViewerContextMenuViewModel implements IViewerContextMenuViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get items(): IItem[] {
    const items: IItem[] = []

    if (this.nodesSelected) {
      items.push(this.isolateItem)
      items.push(this.hideSelectedItem)
    }

    items.push(this.showAllNodesItem)

    if (this.nodesSelected) {
      items.push(this.cancelSelectItem)
    }

    return items
  }

  @computed
  get nodesHided() {
    return Boolean(this.props.layers.usecases.bims.modelDataRepository?.get()?.hideNodes)
  }

  @computed
  get nodesSelected() {
    return this.props.layers.usecases.selectedElements.selectedModelElementsSet.size > 0
  }

  private get isolateItem(): IItem {
    return {
      key: "isolateItem",
      label: "Изолировать",
      onClick: this.isolate
    }
  }

  private get hideSelectedItem(): IItem {
    return {
      key: "hideSelectedItem",
      label: "Скрыть выбранные",
      onClick: this.hideSelected
    }
  }

  private get showAllNodesItem(): IItem {
    return {
      key: "showAllNodesItem",
      label: "Показать все элементы",
      onClick: this.showAll
    }
  }

  private get cancelSelectItem(): IItem {
    return {
      key: "cancelSelectItem",
      label: "Отменить выбор",
      onClick: this.cancelSelect
    }
  }


  @action
  private hideContextMenu() {
    this.props.layers.usecases.ui.temporaryViewerDataRepository.contextMenu = undefined
  }

  @action
  private cancelSelect = () => {
    this.hideContextMenu()
    this.props.layers.usecases.forgeElements.onClear()
  }

  @action
  private showAll = () => {
    this.hideContextMenu()

    this.props.layers.usecases.bims.modelDataRepository?.patch({
      hideNodes: undefined
    })
  }

  @action
  private hideSelected = () => {
    this.hideContextMenu()

    const selectedNodes = this.props.layers.usecases.selectedElements.selectedModelElements

    let hideNodes: {
      [key: string]: IModelElement["id"][]
      [key: number]: IModelElement["id"][]
    } = this.props.layers.usecases.bims.modelDataRepository?.get()?.hideNodes || {}

    for (const modelElement of selectedNodes) {
      if (modelElement.bim in hideNodes) {
        hideNodes[modelElement.bim].push(modelElement.id)
      } else {
        hideNodes[modelElement.bim] = [modelElement.id]
      }
    }

    this.props.layers.usecases.bims.modelDataRepository?.patch({
      hideNodes
    })

    this.props.layers.usecases.forgeElements.onClear()
  }

  @action
  private isolate = () => {
    this.hideContextMenu()

    const modelElements = this.props.layers.usecases.elements.forgeElements

    let hideNodes: {
      [key: string]: IModelElement["id"][]
      [key: number]: IModelElement["id"][]
    } = {}

    for (const modelElement of modelElements) {
      if (!this.props.layers.usecases.selectedElements.selectedModelElementsSet.has(modelElement)) {
        if (modelElement.bim in hideNodes) {
          hideNodes[modelElement.bim].push(modelElement.id)
        } else {
          hideNodes[modelElement.bim] = [modelElement.id]
        }
      }
    }

    this.props.layers.usecases.bims.modelDataRepository?.patch({
      hideNodes
    })

    this.props.layers.usecases.forgeElements.onClear()
  }
}