import { action, computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { numberFormat } from "../../heplpers/formats";
import { Bim } from "../../models/Bim";
import { Company, TCompanyProps } from "../../models/Company";
import { Prescription } from "../../models/Prescription";
import { Remark } from "../../models/Remark";
import { EVariants } from "../../usecase/Notifications/Notifications";
import { EActions } from "../../usecase/Permissions/Permissions";
import { UploadButtonViewModelV2 } from "../Buttons/UploadButtonViewModelV2";
import { InputViewModel } from "../InputViewModel/InputViewModel";
import { EOrientations } from "../PanelDropdownMenuViewModel/interface";
import { PanelInputViewModel } from "../PanelInputViewModel/PanelInputViewModel";
import { PanelSelectorSingleViewModel } from "../PanelSelectorViewModel/PanelSelectorSingleViewModel";
import { PrescriptionDocumentViewModel } from "../PrescriptionDocumentViewModel/PrescriptionDocumentViewModel";
import { ISimpleViewModel } from "../SimpleViewModel/interface";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { UploadFilesListViewModelV2 } from "../UploadFilesListViewModel/UploadFilesListViewModelV2";
import { IPrescriptionFormViewModel } from "./interface";
import { DialogViewModel } from "../DialogViewModel/DialogViewModel"
import { SetViewModel } from "../SimpleViewModel/SetViewModel";
import { PanelSelectorMultiViewModel } from "../PanelSelectorViewModel/PanelSelectorMultiViewModel";
import { EActionTarget } from "../../models/Action";
export class PrescriptionFormViewModel implements IPrescriptionFormViewModel {
  constructor(private props: {
    layers: ILayers,
    prescription: Prescription,
    previewViewModel?: ISimpleViewModel<boolean>
  }) {
    this.props = props

    this.mount(props.prescription)
    makeObservable(this)
  }

  private mount(prescription: Prescription) {
    this.props.layers.repositories.actionsRepository.silentSendEntityAction({
      entityId: prescription.id,
      entityType: EActionTarget.prescription
    })

    this.selectedRemarksSet.replace(prescription.remarks)
    this.applyContractorForRemarks()
  }

  @observable
  statusWrapperViewModel = new StatusWrapperViewModel()

  private selectedRemarksSet = new SetViewModel<Remark["id"]>()
  private selectedContractor = new SimpleViewModel<TCompanyProps["id"] | undefined>({ value: undefined })

  @computed
  private get contractors() {
    return this.props.layers.repositories.companiesRepository.getAll().filter(company => company.isContractor)
  }

  private getRemarkLabel(remark: Remark) {
    const base = this.props.layers.repositories.remarksBaseRepository.getById(remark.remarkBase)
    return `${this.props.layers.usecases.remarks.getRemarkCode(remark)} - ${base?.title || "?"} ${remark.normativeDocument}`
  }

  @computed
  private get selectedRemarks() {
    const remarks: Remark[] = []

    for (const id of this.selectedRemarksSet.array) {
      const remark = this.props.layers.repositories.remarksRepository.getById(id)
      if (remark) {
        remarks.push(remark)
      }
    }

    return remarks
  }

  @computed
  private get selectedBimId(): number | string | undefined {
    return this.selectedRemarks[0]?.bim
  }

  @computed
  private get selectedBim(): Bim | undefined {
    return this.selectedBimId ? this.props.layers.repositories.bimsRepository.getById(this.selectedBimId) : undefined
  }

  @computed
  private get levelMarks(): string[] {
    const marks = new Set<string>()
    marks.add("отсутствует")
    marks.add(this.props.prescription.documentLevelMark)

    if (this.selectedBim) {
      const marksFromBim = this.props.layers.usecases.storeyNotes.getLevelMarksByBim(this.selectedBim)
      for (const mark of marksFromBim) {
        marks.add(mark)
      }
    }

    return Array.from(marks.values()).sort((a, b) => {
      if (a === "отсутствует") return -1
      if (b === "отсутствует") return 1

      return parseFloat(a) - parseFloat(b)
    })
  }

  @computed
  private get allowedRemarks() {
    const contractor = this.contractorSelectorViewModel.value

    return this.props.layers.usecases.prescriptions.getFreeRemarksForPrescription(this.props.prescription)
      .filter(remark => !contractor || (remark.contractor === contractor))
  }

  @computed
  get remarksSelectorViewModel() {
    return new PanelSelectorMultiViewModel<Remark["id"]>({
      orientation: EOrientations.right,
      getOptions: () => this.allowedRemarks
        .map(remark => ({
          value: remark.id,
          label: this.getRemarkLabel(remark),
        })),
      label: "Замечание",
      disabled: !this.allowedEditPrescription,
      setViewModel: this.selectedRemarksSet,
      onChange: () => this.applyContractorForRemarks()
    })
  }

  @computed
  get contractorSelectorViewModel() {
    return new PanelSelectorSingleViewModel<Company["id"] | undefined>({
      orientation: EOrientations.right,
      options: this.contractors.map(contractor => ({
        value: contractor.id,
        label: contractor.name
      })),
      valueViewModel: this.selectedContractor,
      label: "Подрядчик",
      disabled: !this.allowedEditPrescription,
      onChange: () => this.applyRemarksForContractor()
    })
  }

  @action
  private applyContractorForRemarks() {
    let contractor: TCompanyProps["id"] | undefined = undefined
    for (const id of this.selectedRemarksSet.array) {
      const remark = this.props.layers.repositories.remarksRepository.getById(id)
      if (remark) {
        contractor = remark.contractor
        break
      }
    }

    this.selectedContractor.onChange(contractor)
  }

  @action
  private applyRemarksForContractor() {
    this.selectedRemarksSet.clear()
  }

  @computed
  get levelMarkSelectorViewModel() {
    return new PanelInputViewModel({
      label: "Отметка",
      placeholder: "...",
      disabled: !this.allowedEditPrescription,
      hintOrientation: EOrientations.right,
      value: this.props.prescription.documentLevelMark,
      hints: this.levelMarks,
    })
  }

  // @computed
  // get levelMarkSelectorViewModel() {
  //   return new PanelSelectorViewModelOld<string, false>({
  //     options: this.levelMarks.map(levelMark => ({
  //       value: levelMark,
  //       label: levelMark || "отсутствует"
  //     })),
  //     disabled: !this.allowedEditPrescription,
  //     value: this.props.prescription.documentLevelMark,
  //   })
  // }

  @computed
  get notesInputViewModel() {
    return new SimpleViewModel({
      value: this.props.prescription.notes,
      disabled: !this.allowedEditPrescription,
    })
  }

  @computed
  get workDescriptionInputViewModel() {
    return new InputViewModel({
      value: this.props.prescription.documentWorkDescription,
      disabled: !this.allowedEditPrescription,
    })
  }

  @computed
  get createdAtViewModel() {
    return new SimpleViewModel({
      value: this.props.prescription.props.deadlineAt,
      disabled: !this.allowedEditPrescription
    })
  }

  @computed
  get customCodeInputViewModel() {
    return new InputViewModel({
      value: this.props.prescription.customCode,
      disabled: !this.allowedEditPrescription,
      mask: /\d+/g,
      onMaskPrevented: () => {
        this.props.layers.usecases.notifications.notify("Дополнительный номер может состоять только из цифр", {
          variant: EVariants.info
        })
      }
    })
  }

  @computed
  get receiverInputViewModel() {
    return new SimpleViewModel({
      value: this.props.prescription.receiver,
      disabled: !this.allowedEditPrescription,
    })
  }

  @computed
  get specialConditionsInputViewModel() {
    return new SimpleViewModel({
      value: this.props.prescription.specialConditions,
      disabled: !this.allowedEditPrescription,
    })
  }

  @computed
  get customTargetInputViewModel() {
    if (!this.allowedEditCustomPrescriptionFields) return

    return new SimpleViewModel({
      value: this.props.prescription.targetText,
    })
  }

  // @computed
  // get customReceiverCompanyInputViewModel() {
  //   if (!this.allowedEditCustomPrescriptionFields) return

  //   return new SimpleViewModel({
  //     value: this.props.prescription.recipientText
  //   })
  // }

  @computed
  get project() {
    return this.props.layers.usecases.bims.selectedProjects[0]
  }

  @computed
  get issuedByViewModel() {
    return new SimpleViewModel({ value: this.props.prescription.issuedBy })
  }

  @computed
  get issuedBySelectorViewModel() {
    return new PanelSelectorSingleViewModel({
      valueViewModel: this.issuedByViewModel,
      label: "Предписание выдал",
      orientation: EOrientations.right,
      disabled: !this.allowedEditPrescription,
      options: this.project ? this.props.layers.repositories.companiesRepository.getForProject(this.project.id).filter(company => company.isControlCompany).map(company => ({
        value: company.id,
        label: company.name
      })) : []
    })
  }

  @computed
  get allowedEditPrescription() {
    return this.props.layers.usecases.permissions.actionIsAllowed(EActions.editPrescription)
  }

  @computed
  get allowedEditCustomPrescriptionFields() {
    return this.props.layers.usecases.permissions.actionIsAllowed(EActions.editCustomPrescriptionsFields)
  }

  @computed
  get applyButtonViewModel() {
    const prescription = this.newPrescription
    if (!this.allowedEditPrescription && !this.allowedEditCustomPrescriptionFields) return

    return {
      disabled: this.selectedRemarksSet.size === 0 || !prescription,
      onClick: async () => {
        if (!prescription) return
        this.statusWrapperViewModel.load()
        await this.props.layers.repositories.prescriptionsRepository.edit(this.props.prescription.id, prescription.props)
        this.props.layers.usecases.viewState.setSelectedPrescriptions([])
        this.statusWrapperViewModel.success()
      },
      label: "Сохранить"
    }
  }

  @computed
  private get newPrescription() {
    if (!this.selectedBimId) return
    if (!this.issuedBySelectorViewModel.value) return

    return new Prescription({
      ...this.props.prescription.props,
      remarks: this.selectedRemarksSet.array,
      receiver: this.receiverInputViewModel.value,
      notes: this.notesInputViewModel.value,
      bim: this.selectedBimId,
      specialConditions: this.specialConditionsInputViewModel.value,
      targetText: this.customTargetInputViewModel?.value || this.props.prescription.targetText,
      // recipientText: this.customReceiverCompanyInputViewModel?.value || this.props.prescription.recipientText,
      issuedBy: this.issuedBySelectorViewModel.value,
      deadlineAt: this.createdAtViewModel.value,
      attributes: {
        documentWorkDescription: this.workDescriptionInputViewModel.value,
        documentLevelMark: this.levelMarkSelectorViewModel.value || "",
        customCode: this.customCodeInputViewModel.value || ""
      },
      attachments: this.uploadButtonViewModelSetViewModel.array
    })
  }

  @computed
  get preview() {
    if (this.props.previewViewModel?.value) return {
      prescriptionDocumentViewModel: this.prescriptionDocumentViewModel
    }
  }

  @computed
  get prescriptionDocumentViewModel() {
    if (!this.newPrescription) return

    return new PrescriptionDocumentViewModel({
      ...this.props,
      prescription: this.newPrescription
    })
  }

  @computed
  get downloadButtonViewModel() {
    return {
      disabled: this.selectedRemarksSet.size === 0 || !this.prescriptionDocumentViewModel,
      onClick: async () => {
        if (!this.prescriptionDocumentViewModel) return
        this.statusWrapperViewModel.load()

        await this.prescriptionDocumentViewModel.download()

        this.statusWrapperViewModel.success()
      }
    }
  }

  @computed
  get deleteButtonViewModel() {
    if (this.props.layers.usecases.permissions.actionIsBlocked(EActions.deletePrescription)) return

    return {
      onClick: async () => {
        this.statusWrapperViewModel.load()
        await this.props.layers.repositories.prescriptionsRepository.delete(this.props.prescription.id)
        this.props.layers.usecases.viewState.setSelectedPrescriptions([])
        this.statusWrapperViewModel.success()
      }
    }
  }


  // attachments
  @computed
  get uploadButtonViewModel() {
    if (this.props.layers.usecases.permissions.actionIsBlocked(EActions.editPrescription)) return

    return new UploadButtonViewModelV2({
      ...this.props,
      files: this.props.prescription.attachments
    })
  }

  @computed
  private get uploadButtonViewModelSetViewModel() {
    return this.uploadButtonViewModel?.files || new SetViewModel(this.props.prescription.attachments)
  }

  @computed
  get uploadFilesListViewModel() {
    if (!this.showFilesDialogViewModel.open) return

    const viewModel = new UploadFilesListViewModelV2({
      ...this.props,
      files: this.uploadButtonViewModelSetViewModel,
      onClose: () => this.showFilesDialogViewModel.onClose()
    })

    return viewModel
  }

  @computed
  get emptyAttachments() {
    return this.uploadButtonViewModelSetViewModel.size === 0
  }

  @computed
  get showFilesDialogViewModel() {
    return new DialogViewModel({
      open: false,
      onOpen: this.emptyAttachments ? () => { } : () => this.showFilesDialogViewModel.open = true,
    })
  }

  @computed
  get showFilesViewModel() {
    return {
      label: `Посмотреть ${this.uploadButtonViewModelSetViewModel.size} ${numberFormat(this.uploadButtonViewModelSetViewModel.size, ["файл", "файла", "файлов"])}`,
      onClick: this.showFilesDialogViewModel.onOpen
    }
  }
}