import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import SimpleButton from '../Buttons/SimpleButton';

const useClasses = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.redColor,
    width: 44,
    height: 44,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
}));

function NewRemarkButtonPure(props) {
  const classes = useClasses(props);
  const {
    disabled,
    onClick
  } = props;

  return (
    <SimpleButton className={classes.root} onClick={onClick} disabled={disabled}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 21C13.78 21 15.5201 20.4722 17.0001 19.4832C18.4802 18.4943 19.6337 17.0887 20.3149 15.4442C20.9961 13.7996 21.1743 11.99 20.8271 10.2442C20.4798 8.49836 19.6226 6.89472 18.364 5.63604C17.1053 4.37737 15.5016 3.5202 13.7558 3.17294C12.01 2.82567 10.2004 3.0039 8.55585 3.68509C6.91131 4.36628 5.50571 5.51983 4.51677 6.99987C3.52784 8.47991 3 10.22 3 12C3 14.387 3.94821 16.6761 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21ZM11.1 7.5C11.1 7.26131 11.1948 7.03239 11.3636 6.86361C11.5324 6.69482 11.7613 6.6 12 6.6C12.2387 6.6 12.4676 6.69482 12.6364 6.86361C12.8052 7.03239 12.9 7.26131 12.9 7.5V13.8C12.9 14.0387 12.8052 14.2676 12.6364 14.4364C12.4676 14.6052 12.2387 14.7 12 14.7C11.7613 14.7 11.5324 14.6052 11.3636 14.4364C11.1948 14.2676 11.1 14.0387 11.1 13.8V7.5ZM12 15.6C12.178 15.6 12.352 15.6528 12.5 15.7517C12.648 15.8506 12.7634 15.9911 12.8315 16.1556C12.8996 16.32 12.9174 16.501 12.8827 16.6756C12.848 16.8502 12.7623 17.0105 12.6364 17.1364C12.5105 17.2623 12.3502 17.348 12.1756 17.3827C12.001 17.4174 11.82 17.3996 11.6556 17.3315C11.4911 17.2634 11.3506 17.148 11.2517 17C11.1528 16.852 11.1 16.678 11.1 16.5C11.1 16.2613 11.1948 16.0324 11.3636 15.8636C11.5324 15.6948 11.7613 15.6 12 15.6Z" fill="white" />
      </svg>
    </SimpleButton>
  );
}

export default memo(NewRemarkButtonPure);