import React, { useMemo } from "react";
import { IconButton, makeStyles, Typography, Button } from "@material-ui/core";
import PanelDateSelector from "../../components/Selectors/PanelDateSelector";
import { IFilterPanelViewModel } from "../../view-model/FilterPanel/interface";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import PanelSelectorOld from "../../components/Selectors/PanelSelector";
import { PanelSelector } from "../PanelSelector/PanelSelector";

interface IFilterPanelProps {
  viewModel: IFilterPanelViewModel
}

const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const useClasses = makeStyles(theme => ({
  filter: {
    display: "flex"
  },
  filterOpen: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
  },
  filterPanel: {
    width: 360,
    height: "100%",
    position: "fixed",
    left: 0,
    top: 0,
    transform: "translateX(-100%)",
    transition: "transform 0.5s ease",
    background: "#fff",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
    zIndex: 10,
  },
  filterPanelOpen: {
    transform: "none"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "25px",
    paddingRight: "10px",
    borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)"
  },
  filterList: {
    padding: "20px 0",
    cursor: "pointer"
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: "flex",
    padding: "0 25px"
  },
  button: {
    width: "100%",
    marginTop: 18,
    fontWeight: "bold",
    height: 44,
    fontSize: 14,
    color: "#000",
    textTransform: 'none',
    borderColor: "#FEC60F",
    backgroundColor: "#FEC60F",
    "&:hover": {
      borderColor: "#FEC60F",
      backgroundColor: "#FEC60F",
      // boxShadow: "none",
    },
  },
  buttonClear: {
    backgroundColor: "#F5F5F5",
    marginLeft: "20px",
  },
  selector: {
    width: "100%",
    padding: "0 10px",
  },
  padding: {
    padding: "0 25px",
  },
  overlay: {
    backgroundColor: "#0001",
    zIndex: 1,
    flex: 1
  }
}));

const FilterPanel = observer((props: IFilterPanelProps) => {
  const classes = useClasses();

  const paddingClasses = useMemo(() => ({
    padding: classes.padding
  }), []);

  const isOpen = props.viewModel.isOpen

  return (
    <div className={clsx(classes.filter, { [classes.filterOpen]: isOpen })}>
      <div className={clsx(classes.filterPanel, { [classes.filterPanelOpen]: isOpen })}>
        <div className={classes.header}>
          <Typography variant="h6" component="h6">
            Фильтр
          </Typography>
          <IconButton className={classes.iconButton} onClick={props.viewModel.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.filterList}>
          {
            props.viewModel.statusesPanelSelectorViewModel ?
              <PanelSelector
                classes={paddingClasses}
                viewModel={props.viewModel.statusesPanelSelectorViewModel}
              />
              : null
          }
          {
            props.viewModel.contractorPanelSelectorViewModel ?
              <PanelSelector
                classes={paddingClasses}
                viewModel={props.viewModel.contractorPanelSelectorViewModel}
              />
              : null
          }

          {
            props.viewModel.ownersPanelSelectorViewModel ?
              <PanelSelector
                classes={paddingClasses}
                viewModel={props.viewModel.ownersPanelSelectorViewModel}
              />
              : null
          }
          {
            props.viewModel.deadlineStatusPanelSelectorViewModel ?
              <PanelSelector
                classes={paddingClasses}
                viewModel={props.viewModel.deadlineStatusPanelSelectorViewModel}
              />
              : null
          }
          {
            props.viewModel.remarkBasePanelSelectorViewModel ?
              <PanelSelector
                classes={paddingClasses}
                viewModel={props.viewModel.remarkBasePanelSelectorViewModel}
              />
              : null
          }
          {
            props.viewModel.createdAtPanelDateSelectorViewModel ?
              <PanelDateSelector
                classes={paddingClasses}
                label="Дата создрания"
                value={props.viewModel.createdAtPanelDateSelectorViewModel.value}
                placeholder="..."
                onChange={props.viewModel.createdAtPanelDateSelectorViewModel.onChange}
              />
              : null
          }
          {
            props.viewModel.deadlinePanelDateSelectorViewModel ?
              <PanelDateSelector
                classes={paddingClasses}
                label="Устранить до"
                value={props.viewModel.deadlinePanelDateSelectorViewModel.value}
                placeholder="..."
                onChange={props.viewModel.deadlinePanelDateSelectorViewModel.onChange}
              />
              : null
          }
          <div className={classes.actions}>
            <Button onClick={props.viewModel.onApply} className={classes.button}>
              Применить
            </Button>

            <Button onClick={props.viewModel.onClear} className={clsx(classes.button, classes.buttonClear)}>
              Очистить
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.overlay} onClick={props.viewModel.onClose}> </div>
    </div>
  );
})

export default FilterPanel;