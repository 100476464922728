import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EBimType } from "../../models/Bim";
import { BimPanelViewModel } from "../BimPanelViewModel/BimPanelViewModel";
import { BimViewerV2ViewModel } from "../BimViewerV2ViewModel/BimViewerV2ViewModel";
import { BimViewerViewModel } from "../BimViewerViewModel/BimViewerViewModel";
import { IBimPageViewModel } from "./interface";

export class BimPageViewModel implements IBimPageViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get bimPanelViewModel() {
    return new BimPanelViewModel(this.props)
  }

  @computed
  get bimViewerViewModel() {
    return new BimViewerViewModel(this.props)
  }

  @computed
  get bimViewerV2ViewModel() {
    return new BimViewerV2ViewModel(this.props)
  }

  @computed
  get viewerVersion(): 1 | 2 | undefined {
    switch (this.props.layers.usecases.bims.selectedBimsType) {
      case EBimType.forge:
        return 1
      case EBimType.bundle:
        return 2
    }
  }
}