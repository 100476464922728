import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IMoreButtonsPanelViewModel } from "../../view-model/MoreButtonsPanelViewModel/interface"
import SimpleButton from "../../components/Buttons/SimpleButton";
import { StatusWrapper } from "../StatusWrapper/StatusWrapper";

const useClasses = makeStyles(theme => ({
  root: {},
  content: {
    "& >*:not(:last-child)": {
      marginBottom: 10
    }
  },

  button: {
    backgroundColor: "#F5F5F5",
    color: "#0005"
  },

  closeButton: {
    color: "white",
    //@ts-ignore
    backgroundColor: theme.palette.redColor,
  }
}))

interface IMoreButtonsPanelProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IMoreButtonsPanelViewModel
  viewer?: Autodesk.Viewing.Viewer3D
}

export const MoreButtonsPanel = observer((props: IMoreButtonsPanelProps) => {
  const {
    viewer
  } = props
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
        <div className={classes.content}>
          {
            props.viewModel.paintButtonViewModel ?
              <SimpleButton className={classes.button} {...props.viewModel.paintButtonViewModel}>
                {props.viewModel.paintButtonViewModel.label}
              </SimpleButton>
              : null
          }
          {
            props.viewModel.updateScreenButtonViewModel ?
              <SimpleButton
                className={classes.button}
                onClick={() => props.viewModel.updateScreenButtonViewModel?.onClick()}
              >
                Обновить заставку
              </SimpleButton>
              : null
          }
          {
            props.viewModel.downloadPDFButtonViewModel ?
              <SimpleButton
                className={classes.button}
                onClick={() => props.viewModel.downloadPDFButtonViewModel?.onClick()}
              >
                Скачать PDF
              </SimpleButton>
              : null
          }
          {
            props.viewModel.downloadImageButtonViewModel ?
              <SimpleButton
                className={classes.button}
                onClick={() => props.viewModel.downloadImageButtonViewModel?.onClick()}
              >
                Скачать JPG
              </SimpleButton>
              : null
          }
          <SimpleButton className={classes.closeButton} onClick={() => props.viewModel.onClose()}>Закрыть</SimpleButton>
        </div>
      </StatusWrapper>
    </div>
  );
})