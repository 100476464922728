import { IDBPDatabase } from "idb";
import { computed, makeObservable } from "mobx";
import { Bim, TBimProps } from "../../models/Bim";
import { BimElement } from "../../models/BimElement";
import { TCompanyProps } from "../../models/Company";
import { ERemarkStatus, NewRemark, Remark, TNewRemarkProps, TRemarkProps } from "../../models/Remark";
import { TRemarkBaseProps } from "../../models/RemarkBase";
import { TUploadFileProps } from "../../models/UploadFile";
import { EUserRoles, TUserProps } from "../../models/User";
import { IAuthRepository } from "../AuthRepository/interface";
import { IndexedDBPatchesRepository } from "../PatchesRepository/IndexedDBPatchesRepository";
import { RestOfflineRepository } from "../Repository/RestOfflineRepository";
import { IRemarksRepository } from "./interface";

type TRestProps = {
  id: number
  bim: number
  elements: (number | string)[]
  owner: {
    id: number
    company: number
    email: string
    first_name: string
    last_name: string
    role: EUserRoles
  } | number
  remark_base: number
  supervisor: {
    id: number
    company: number
    email: string
    first_name: string
    last_name: string
    role: EUserRoles
  } | number
  body: string
  contractor: number
  location: string
  deadline: string
  created_at: string
  updated_at: string
  status: "closed" | "open" | "pending"
  title: string
  attachments: number[]
  project_number: number
  normative_document?: string
  is_critical: boolean
}


type TNewRestProps = {
  bim: TBimProps["id"]
  elements: (number | string)[]
  remark_base: TRemarkBaseProps["id"]
  supervisor: TUserProps["id"]
  body: string
  contractor: TCompanyProps["id"]
  location: string
  deadline: string
  status: ERemarkStatus
  title: string
  attachments: TUploadFileProps["id"][]
  normative_document: string
  is_critical: boolean
}
export class RestRemarksRepository implements IRemarksRepository {
  constructor(private props: {
    host: string
    authRepository: IAuthRepository
    db: IDBPDatabase
    patchesTableName: string
  }) {
    this.props = props

    this.offlineRepository = new RestOfflineRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: Remark,
      NewModel: NewRemark,
      serialyze: this.serialyze,
      serialyzeAdd: this.serialyzeAdd,
      patchesRepository: new IndexedDBPatchesRepository({
        db: props.db,
        name: props.patchesTableName,
        Model: Remark
      })
    })
  }

  offlineRepository: RestOfflineRepository<Remark, TRestProps, TNewRestProps, TNewRemarkProps>

  @computed
  get path() {
    return `${this.props.host}api/main/bim-remark/`
  }

  getById(id) {
    return this.offlineRepository.getById(id)
  }

  getByIdAsync(id) {
    return this.offlineRepository.getByIdAsync(id)
  }

  delete(id) {
    return this.offlineRepository.delete(id)
  }

  edit(id, props) {
    return this.offlineRepository.edit(id, props)
  }

  add(props) {
    return this.offlineRepository.add(props)
  }

  getByBimId(bim: TBimProps["id"]) {
    return this.offlineRepository.getByParams({ bim })
  }

  async getByBimIdAsync(bim: Bim["id"]) {
    return this.offlineRepository.getByParamsAsync({ bim })
  }

  async getByBimElementAsync(el: BimElement) {
    const remarks = await this.getByBimIdAsync(el.bim)
    return remarks.filter(remark => remark.elements.includes(el.id))
  }

  private getRemarkStatus(status: TRestProps["status"]): ERemarkStatus {
    switch (status) {
      case "closed":
        return ERemarkStatus.closed
      case "open":
        return ERemarkStatus.opened
      case "pending":
        return ERemarkStatus.pending
    }
  }


  serialyzeAdd(props: TNewRemarkProps): TNewRestProps {
    return {
      bim: props.bim,
      elements: props.elements,
      remark_base: props.remarkBase,
      supervisor: props.supervisor,
      body: props.body,
      contractor: props.contractor,
      location: props.location,
      deadline: props.deadline,
      status: props.status,
      title: props.title,
      attachments: props.attachments,
      normative_document: props.normativeDocument,
      is_critical: props.isCritical
    }
  }

  serialyze = (p: TRestProps): TRemarkProps => {
    const ownerId = typeof p.owner === "object" ? p.owner.id : p.owner
    const supervisorId = typeof p.supervisor === "object" ? p.supervisor.id : p.supervisor

    return {
      id: p.id,
      bim: p.bim,
      elements: p.elements,
      owner: ownerId,
      remarkBase: p.remark_base,
      supervisor: supervisorId,
      body: p.body,
      contractor: p.contractor,
      location: p.location,
      deadline: p.deadline,
      createdAt: p.created_at,
      updatedAt: p.updated_at,
      status: this.getRemarkStatus(p.status),
      title: p.title,
      attachments: p.attachments,
      projectNumber: p.project_number,
      normativeDocument: p.normative_document || "",
      isCritical: p.is_critical || false
    }
  }
}