import { computed, makeObservable } from "mobx";
import { TBaseModel } from "../BaseModel";
import { Bim } from "../Bim";
import { FrozenEntity } from "../Entity/FrozenEntity";
import { IModelElement } from "./interface";

export type TModelElementProps = {
  modelID: number;
  bim: Bim["id"];
  properties: {
    [key: string]: {
      [key: string]: any;
    };
  };
} & TBaseModel;

export class ModelElement
  extends FrozenEntity<TModelElementProps>
  implements IModelElement
{
  constructor(props: TModelElementProps) {
    super(props);

    makeObservable(this);
  }

  @computed
  get id() {
    if (this.isConsolidatedModelElement && this.objectID) return this.objectID;

    return this.modelID;
  }

  get modelID() {
    return this.props.modelID;
  }

  get bim() {
    return this.props.bim;
  }

  @computed
  get objectID(): string | undefined {
    return this.props.properties["ID объекта"]?.["Значение"];
  }

  parent?: ModelElement;
  children = new Set<ModelElement>();

  setParent(element: ModelElement) {
    this._setParent(element);
    element._addChild(this);
  }

  addChild(element: ModelElement) {
    this._addChild(element);
    element._setParent(this);
  }

  private _setParent(element: ModelElement) {
    this.parent = element;
  }

  private _addChild(element: ModelElement) {
    this.children.add(element);
  }

  @computed
  get geometryElementsModelID(): Set<ModelElement["modelID"]> {
    if (this.isGeometryElement) return new Set([this.modelID]);

    const geometryElements = new Set<ModelElement["modelID"]>();

    for (const child of this.children) {
      for (const modelID of child.geometryElementsModelID) {
        geometryElements.add(modelID);
      }
    }

    return geometryElements;
  }

  @computed
  get isGeometryElement() {
    return this.children.size === 0 && Boolean(this.parent);
  }

  @computed
  get consolidatedModelElement(): ModelElement | undefined {
    return this.isConsolidatedModelElement
      ? this
      : this.parent?.consolidatedModelElement;
  }

  @computed
  get isConsolidatedModelElement(): boolean {
    return Boolean(this.objectID) && Boolean(this.objectProps);
  }

  @computed
  private get objectProps() {
    return this.props.properties["Объект"];
  }

  @computed
  get constructionGroup() {
    return this.objectProps?.["INGD_Группа конструкции"];
  }

  @computed
  get section() {
    return this.objectProps?.["INGD_Секция"];
  }

  @computed
  get storey() {
    return this.objectProps?.["INGD_Номер этажа"];
  }

  @computed
  get material() {
    return this.objectProps?.["INGD_Категория материала"];
  }

  @computed
  get materials() {
    return this.material?.split("_") || [];
  }

  @computed
  get width() {
    return this.objectProps?.["INGD_Ширина"];
  }

  @computed
  get length() {
    return this.objectProps?.["INGD_Длина"];
  }

  @computed
  get height() {
    return this.objectProps?.["INGD_Высота"];
  }

  @computed
  get value() {
    return this.objectProps?.["Объем"];
  }

  @computed
  get volumeNumber() {
    return parseFloat(this.value || "0");
  }

  @computed
  get squareNumber() {
    return parseFloat(this.square || "0");
  }

  @computed
  get square() {
    return this.objectProps?.["Площадь"];
  }

  @computed
  get squareValue() {
    return this.getValueFromString(this.square);
  }

  @computed
  get lengthValue() {
    return this.getValueFromString(this.length);
  }

  @computed
  get volumeValue(): number {
    return this.getValueFromString(this.value);
  }

  private getValueFromString(string: string | undefined): number {
    const value = string ? parseFloat(string) : 0;
    return isNaN(value) ? 0 : value;
  }
}
