import { v4 } from "uuid";
import { BaseModel } from "./BaseModel";
import { Entity } from "./Entity";

export type TTargetNote = TNewTargetNoteProps & BaseModel

export type TNewTargetNoteProps = {
  target: number
  attributes: {
    [ETargetNoteAttributesStoreyKeys.storeys]?: { [s: string]: string }
    [ETargetNoteAttributesStoreyKeys.storeysOther]?: { [s: string]: string }
  }
}

export enum ETargetNoteAttributesStoreyKeys {
  storeys = "storeys",
  storeysOther = "storeysOther"
}
export class TargetNote extends Entity<TTargetNote> {
  get target() {
    return this.props.target
  }

  get attributes() {
    return this.props.attributes
  }
}

export class NewTargetNote extends TargetNote {
  constructor(props: TNewTargetNoteProps & Partial<BaseModel>) {
    super({
      id: props.id || v4(),
      ...props
    })
  }
}