import { action, computed, makeObservable } from "mobx"
import { IPanelSelectorViewModel } from "./interface"
import { PanelSelectorMultiViewModel, TPanelSelectorMultiViewModelProps } from "./PanelSelectorMultiViewModel"

export class PanelSelectorMultiAllViewModel<T> implements IPanelSelectorViewModel {
  constructor(private props: TPanelSelectorMultiViewModelProps<T>) {
    this.props = props
    this.panelSelectorMultiViewModel = new PanelSelectorMultiViewModel(props)

    makeObservable(this)
  }

  private panelSelectorMultiViewModel: PanelSelectorMultiViewModel<T>

  @computed
  private get notDisabledOptions() {
    return this.panelSelectorMultiViewModel.options.filter(option => !option.disabled)
  }

  @computed
  private get notDisabledPureOptions() {
    return this.panelSelectorMultiViewModel.allOptions.filter(option => !option.disabled)
  }

  @computed
  get allSelected() {
    return this.notDisabledOptions.every(option => option.selected) && this.panelSelectorMultiViewModel.groups.every(group => group.selected)
  }

  @action
  private switchAllSelected = () => {
    if (this.allSelected) {
      this.panelSelectorMultiViewModel.setViewModel.clear()
    } else {
      this.panelSelectorMultiViewModel.setViewModel.addBatch(this.notDisabledPureOptions.map(option => option.value))
    }

    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  @computed
  get options() {
    return [
      {
        key: "all",
        label: "все",
        selected: this.allSelected,
        onClick: this.switchAllSelected,
      },
      ...this.panelSelectorMultiViewModel.options
    ]
  }

  @computed
  get valueLabel() {
    if (this.allSelected) return "все"
    return this.panelSelectorMultiViewModel.valueLabel
  }

  ///// inherit
  @computed
  get orientation() {
    return this.panelSelectorMultiViewModel.orientation
  }

  @computed
  get notSelected() {
    return this.panelSelectorMultiViewModel.notSelected
  }

  has(value: T) {
    return this.panelSelectorMultiViewModel.setViewModel.has(value)
  }

  onClick = (e) => {
    this.panelSelectorMultiViewModel.onClick(e)
  }

  @computed
  get panelDropdownMenuViewModel() {
    return this.panelSelectorMultiViewModel.panelDropdownMenuViewModel
  }

  @computed
  get groups() {
    return this.panelSelectorMultiViewModel.groups
  }

  @computed
  get label() {
    return this.panelSelectorMultiViewModel.label
  }

  @computed
  get disabled() {
    return this.panelSelectorMultiViewModel.disabled
  }

  @computed
  public get values() {
    return this.panelSelectorMultiViewModel.values
  }
}