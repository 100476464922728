import { IHistoryPageViewModel } from "../HistoryPageViewModel/interface";
import { ILevelsFilterViewModel } from "../LevelsFilterViewModel/interface";
import { IPrescriptionsPageViewModel } from "../PrescriptionsPageViewModel/interface";
import { IRemarksPageViewModel } from "../RemarksPageViewModel/interface";
import { ISettingsPageViewModel } from "../SettingsPageViewModel/interface";
import { IStatisticsPageViewModel } from "../StatisticsPageViewModel/interface";
import { IStatusPageViewModel } from "../StatusPageViewModel/interface";
import { ITermsAndVolumesViewModel } from "../TermsAndVolumesViewModel/interface";

export enum EBimPanelContent {
  storeys = "storeys",
  status = "status",
  remarks = "remarks",
  prescriptions = "prescriptions",
  planfact = "planfact",
  terms_and_volumes = "terms and volumes",
  history = "history",
  settings = "settings",
}
export interface IBimPanelViewModel {
  sections: {
    name: string
    code: EBimPanelContent
    selected: boolean
    onClick(): void
    count?: string[]
  }[]

  isMobile: boolean
  content: EBimPanelContent
  contentOpen: boolean
  shortModeActive: boolean
  closeDialogOpened: boolean

  statusPageViewModel: IStatusPageViewModel
  remarksPageViewModel: IRemarksPageViewModel
  levelsFilterViewModel: ILevelsFilterViewModel
  termsAndVolumesViewModel: ITermsAndVolumesViewModel
  prescriptionsPageViewModel: IPrescriptionsPageViewModel
  statisticsPageViewModel: IStatisticsPageViewModel
  settingsPageViewModel: ISettingsPageViewModel
  historyPageViewModel: IHistoryPageViewModel

  switchShortModeActive(): void
  onCloseDialog(): void
  setContent(e: EBimPanelContent): void
  exit: () => void
  onExit: () => void
}