import React, { useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IPanelInputViewModel } from "../../view-model/PanelInputViewModel/interface"
import clsx from "clsx"
import { PanelDropdownMenu } from "../PanelDropdownMenu/PanelDropdownMenu"

const useClasses = makeStyles(theme => ({
  root: {
    display: "flex",
    lineHeight: "44px",
    cursor: "pointer",
  },
  inputContainer: {
    display: "flex",
    flex: 1
  },
  disabled: {
    cursor: "default !important",
  },
  padding: {
    padding: "0 15px",
  },
  label: {
    flex: 1,
    whiteSpace: "nowrap",
    userSelect: "none",
    fontWeight: "normal",
    fontSize: "12px",
  },
  value: {
    background: "inherit",
    border: "none",

    flex: 1,

    textAlign: "right",
    fontWeight: "bold",
    fontSize: "12px",
    cursor: "text",

    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "50%",
    lineHeight: "43px",
    borderBottom: "1px solid #0000",

    "&:focus": {
      borderBottom: "1px solid #000",
      textOverflow: "initial",
      outline: "none"
    },

    "&:empty:before": {
      content: "attr(placeholder)",
      color: "#AAAAA9"
    }
  },
}))

interface IPanelInputProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPanelInputViewModel
}

export const PanelInput = observer((props: IPanelInputProps) => {
  const classes = useClasses(props);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      props.viewModel.onInputMount(inputRef.current)
    }
  }, [])

  return (
    <div className={clsx(classes.root, {
      [classes.disabled]: props.viewModel.disabled
    })}
      onClick={props.viewModel.onClick}
      style={props.viewModel.rootStyle}
    >
      <div className={clsx(classes.inputContainer, classes.padding)}>
        <div className={classes.label}>{props.viewModel.label}</div>
        <input
          ref={inputRef}
          className={clsx(classes.value, {
            [classes.disabled]: props.viewModel.disabled
          })}
          disabled={props.viewModel.disabled}
          value={props.viewModel.value}
          onChange={props.viewModel.onChange}
          placeholder={props.viewModel.placeholder}
        />
      </div>
      {props.viewModel.panelDropdownMenuViewModel ? <PanelDropdownMenu viewModel={props.viewModel.panelDropdownMenuViewModel} /> : null}
    </div>
  )
})