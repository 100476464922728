import { computed, makeObservable, observable, ObservableSet } from "mobx"
import { EUnits } from "../../models/Units"
import { WorkType } from "../../models/WorkType"
import { WorkTypeGroup } from "../../models/WorkTypeGroup"
import { IModelElementsRepository } from "../../repositories/ModelElementsRepository/interface"
import { Bims } from "../Bims/Bims"
import { Elements } from "../Elements/Elements"

export class WorkTypes {
  constructor(private props: {
    bims: Bims
    modelElementsRepository: IModelElementsRepository
    elements: Elements
  }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  selectedWorkTypes = new ObservableSet<WorkType["id"]>()

  setSelectedWorkTypes(values: WorkType["id"][]) {
    this.selectedWorkTypes.replace(values)
  }

  @computed
  get workTypes() {
    const workTypesSet = new Set<WorkType>()

    for (const bim of this.props.bims.selectedBims) {
      const forgeElements = this.props.modelElementsRepository.getByBim(bim)
      const workTypes = this.props.elements.getWorkTypesByForgeElements(forgeElements)

      for (const workType of workTypes) {
        if (workType) workTypesSet.add(workType)
      }
    }

    return Array.from(workTypesSet.values())
  }

  @computed
  get workTypeGroups() {
    const workTypesSet = new Set<WorkTypeGroup>()

    for (const bim of this.props.bims.selectedBims) {
      const forgeElements = this.props.modelElementsRepository.getByBim(bim)
      const workTypeGroups = this.props.elements.getWorkTypeGroupsByForgeElements(forgeElements)

      for (const workTypeGroup of workTypeGroups) {
        workTypesSet.add(workTypeGroup || this.workTypeOther)
      }
    }

    const result = Array.from(workTypesSet.values())
    result.sort((a, b) => a.id === undefined || (a.name > b.name) ? 1 : -1)

    return result
  }

  @observable
  workTypeOther = new WorkTypeGroup({
    id: 0,
    name: "другое",
    unit: EUnits.thing,
    other: true
  })
}