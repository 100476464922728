import { IDBPDatabase } from "idb";
import { NewUploadFile, TNewUploadFileProps, UploadFile } from "../../models/UploadFile";
import { IAuthRepository } from "../AuthRepository/interface";
import { IndexedDBPatchesRepository } from "../PatchesRepository/IndexedDBPatchesRepository";
import { RestOfflineRepository } from "../Repository/RestOfflineRepository";
import { IUploadFilesRepository } from "./interface";

type TResponseProps = {
  id: number
  file: string
  name: string
  user: number
  uploaded_at: string
}

type TNewRestProps = {
  file: string
  name: string
  user: number
}

export class RestUploadFilesRepository implements IUploadFilesRepository {
  constructor(private props: {
    path: string
    authRepository: IAuthRepository
    db: IDBPDatabase
    patchesTableName: string
  }) {
    this.props = props

    this.offlineRepository = new RestOfflineRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: UploadFile,
      NewModel: NewUploadFile,
      serialyze: this.serialyze,
      patchesRepository: new IndexedDBPatchesRepository({
        db: props.db,
        name: props.patchesTableName,
        Model: UploadFile
      })
    })
  }

  offlineRepository: RestOfflineRepository<UploadFile, TResponseProps, TNewRestProps, TNewUploadFileProps>

  get path() {
    return this.props.path
  }

  serialyze(props: TResponseProps): UploadFile["props"] {
    return {
      ...props,
      uploadedAt: props.uploaded_at
    }
  }

  getById(id) {
    return this.offlineRepository.getById(id)
  }

  getByIdAsync(id) {
    return this.offlineRepository.getByIdAsync(id)
  }

  add(props) {
    return this.offlineRepository.add(props)
  }

  delete(id) {
    return this.offlineRepository.delete(id)
  }
}