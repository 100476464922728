import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, IconButton, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IBimViewerV2ViewModel } from "../../view-model/BimViewerV2ViewModel/interface"
import { SarexViewer } from "../SarexViewer/SarexViewer"
import { RightViewerPanel } from "../RightViewerPanel/RightViewerPanel"
import { FloatingWindow } from "../FloatingWindow/FloatingWindow"
import { PropertiesPanel } from "../PropertiesPanel/PropertiesPanel"
import clsx from "clsx"
import { MeasurementsPanel } from "../MeasurementsPanel/MeasurementsPanel"
import { ViewerContextMenu } from "../ViewerContextMenu/ViewerContextMenu"

const useClasses = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    position: "relative",
    overflow: 'hidden',
  },
  viewer: {
    width: "100%",
    height: "100%",
    background: "linear-gradient(0, #D2F0FF, #6B87C4)"
    // backgroundColor: "#e8edff"
  },
  controls: {
    position: "absolute",
    bottom: 20,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    pointerEvents: "none",

    "& >*:not(:first-child)": {
      marginLeft: 10
    },
  },
  control: {
    padding: 0,
    width: 44,
    height: 44,
    minWidth: "initial",
    backgroundColor: "white",
    pointerEvents: "initial",

    "& path": {
      fill: "#3C4051"
    }
  },
  controlActive: {
    "& path": {
      fill: theme.palette.primary.main
    }
  },
  statusPanel: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: "#fff",
    opacity: 0.95,
    position: "absolute",
    top: 10,
    right: 10,
    fontSize: "12px"
  }
}))

const useClassesPanel = makeStyles(theme => ({
  root: {
    cursor: "default"
  },
  cancel: {
    cursor: "default"
  }
}))

interface IBimViewerProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IBimViewerV2ViewModel
}

export const BimViewerV2 = observer((props: IBimViewerProps) => {
  const classes = useClasses(props)
  const classesPanel = useClassesPanel()
  const viewerContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (viewerContainerRef.current) {
      new SarexViewer({
        viewModel: props.viewModel.sarexViewerViewModel,
        container: viewerContainerRef.current
      })
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.viewer} ref={viewerContainerRef} onContextMenu={props.viewModel.onContextMenu}></div>
      <div className={classes.controls}>
        {
          props.viewModel.propertiesButtonViewModel ? (
            <Button
              variant="outlined"
              className={clsx(classes.control, {
                [classes.controlActive]: props.viewModel.propertiesButtonViewModel.active
              })}
              onClick={props.viewModel.propertiesButtonViewModel.onClick}
            >
              <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.23 3.816L9 5.382l2.77-1.566L9 2.25 6.23 3.816zM12.573 4.27l-2.77 1.566 2.77 1.566 2.769-1.566-2.77-1.566zM12.177 8.087L9.396 6.514v7.663l2.781 1.573V8.087zM5.427 8.556l-2.769 1.566 2.77 1.566 2.769-1.566-2.77-1.566zM8.604 9.444V6.067L5.823 4.494v3.377l2.781 1.573zM12.968 8.088v7.662l2.782-1.573V6.514l-2.782 1.574zM5.823 12.373v3.377l2.781-1.573V10.8l-2.781 1.573zM5.032 12.373L2.25 10.8v3.377l2.782 1.573v-3.377z"></path></svg>
            </Button>
          ) : null
        }
        {
          props.viewModel.cameraButtonViewModel ? (
            <Button
              variant="outlined"
              className={clsx(classes.control, {
                [classes.controlActive]: props.viewModel.cameraButtonViewModel.active
              })}
              onClick={props.viewModel.cameraButtonViewModel.onClick}
            >
              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.333 8.842v8.421c0 .372-.14.73-.39.993a1.3 1.3 0 01-.943.41H4.667A3.19 3.19 0 013.39 18.4a3.327 3.327 0 01-1.081-.761 3.605 3.605 0 01-.977-2.481V6.737c0-.372.14-.73.39-.993a1.3 1.3 0 01.944-.41H12c.884 0 1.732.369 2.357 1.027.625.658.976 1.55.976 2.481zM21 7.184a.617.617 0 00-.667 0l-3.333 2a.686.686 0 00-.246.26.732.732 0 00-.087.354v4.404a.732.732 0 00.087.354.686.686 0 00.246.26l3.333 2a.666.666 0 00.334.096.642.642 0 00.333-.096.668.668 0 00.246-.255.713.713 0 00.087-.35V7.788a.713.713 0 00-.087-.35.668.668 0 00-.246-.255z"></path></svg>
            </Button>
          ) : null
        }
        {
          props.viewModel.measurementButtonViewModel ? (
            <Button
              variant="outlined"
              className={clsx(classes.control, {
                [classes.controlActive]: props.viewModel.measurementButtonViewModel.active
              })}
              onClick={props.viewModel.measurementButtonViewModel.onClick}
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.21742 2.25049H3.99888C3.76589 2.25049 3.57703 2.43935 3.57703 2.67234V15.328C3.57703 15.561 3.76589 15.7498 3.99888 15.7498H8.21742C8.45042 15.7498 8.63928 15.561 8.63928 15.328V2.67234C8.63928 2.43935 8.45039 2.25049 8.21742 2.25049ZM7.79557 14.9061H4.42074V13.6406H6.53001V12.7968H4.42074V11.5313H5.6863V10.6876H4.42074V9.42201H6.53001V8.5783H4.42074V7.31274H5.6863V6.46903H4.42074V5.20347H6.53001V4.35976H4.42074V3.0942H7.79557V14.9061Z" />
                <path d="M13.828 12.9233L12.4359 14.3112V3.68887L13.8238 5.07677L14.4186 4.48195L12.3094 2.37268C12.1448 2.20911 11.8791 2.20911 11.7146 2.37268L9.60529 4.48195L10.2001 5.07677L11.5922 3.68887V14.3112L10.2043 12.9233L9.6095 13.5181L11.7188 15.6273C11.8833 15.7909 12.149 15.7909 12.3136 15.6273L14.4229 13.5181L13.828 12.9233Z" />
              </svg>
            </Button>
          ) : null
        }
      </div>
      {
        props.viewModel.loadingStatusPanel ? (
          <div className={classes.statusPanel}>{props.viewModel.loadingStatusPanel.status}</div>
        ) : null
      }
      {
        props.viewModel.rightViewerPanelViewModel ?
          <RightViewerPanel viewModel={props.viewModel.rightViewerPanelViewModel} />
          : null
      }
      {
        props.viewModel.propertiesPanel ? (
          <FloatingWindow cancel={`.${classesPanel.cancel}`} viewModel={props.viewModel.propertiesPanel.floatingWindowViewModel}>
            <PropertiesPanel classes={classesPanel} viewModel={props.viewModel.propertiesPanel.propertiesPanelViewModel} />
          </FloatingWindow>
        ) : null
      }
      {
        props.viewModel.measurementsPanel ? (
          <FloatingWindow cancel={`.${classesPanel.cancel}`} viewModel={props.viewModel.measurementsPanel.floatingWindowViewModel}>
            <MeasurementsPanel classes={classesPanel} viewModel={props.viewModel.measurementsPanel.measurementsPanelViewModel} />
          </FloatingWindow>
        ) : null
      }
      {
        props.viewModel.viewerContextMenu ? (
          <FloatingWindow cancel={`.${classesPanel.cancel}`} viewModel={props.viewModel.viewerContextMenu.floatingWindowViewModel}>
            <ViewerContextMenu classes={classesPanel} viewModel={props.viewModel.viewerContextMenu.viewerContextMenuViewModel} />
          </FloatingWindow>
        ) : null
      }
    </div>
  )
})