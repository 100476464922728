import { IDBPDatabase } from "idb";
import { computed, makeObservable } from "mobx";
import { TBimProps } from "../../models/Bim";
import { TCompanyProps } from "../../models/Company";
import { NewPrescription, Prescription, TPrescriptionProps } from "../../models/Prescription";
import { Remark, TRemarkProps } from "../../models/Remark";
import { TUploadFileProps } from "../../models/UploadFile";
import { EUserRoles } from "../../models/User";
import { IAuthRepository } from "../AuthRepository/interface";
import { IndexedDBPatchesRepository } from "../PatchesRepository/IndexedDBPatchesRepository";
import { RestOfflineRepository } from "../Repository/RestOfflineRepository";
import { IPrescriptionRepository, TNewPrescriptionProps } from "./interface";

type TRestProps = {
  id: number
  bim: number
  created_at: string
  updated_at: string
  remarks: number[]
  receiver: string
  project_number: number
  special_conditions: string
  target_text: string
  owner: {
    id: number
    company: number
    email: string
    first_name: string
    last_name: string
    role: EUserRoles
  }
  attributes: any
  notes: string
  issued_by: TCompanyProps["id"]
  deadline_at: string
  attachments: TUploadFileProps["id"][]
}

type TNewRestProps = {
  bim: TBimProps["id"]
  receiver: string
  notes: string
  remarks: TRemarkProps["id"][]
  special_conditions: string
  target_text: string
  issued_by: TCompanyProps["id"]
  attributes: any
  deadline_at: string
  attachments: TUploadFileProps["id"][]
}


export class RestPrescriptionRepository implements IPrescriptionRepository {
  constructor(private props: {
    host: string
    authRepository: IAuthRepository
    db: IDBPDatabase
    patchesTableName: string
  }) {
    this.props = props

    this.offlineRepository = new RestOfflineRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: Prescription,
      NewModel: NewPrescription,
      serialyze: this.serialyze,
      serialyzeAdd: this.serialyzeAdd,
      patchesRepository: new IndexedDBPatchesRepository({
        db: props.db,
        name: props.patchesTableName,
        Model: Prescription
      })
    })

    makeObservable(this)
  }

  offlineRepository: RestOfflineRepository<Prescription, TRestProps, TNewRestProps, TNewPrescriptionProps>

  @computed
  get path() {
    return `${this.props.host}api/main/bim-prescription/`
  }

  getById(id) {
    return this.offlineRepository.getById(id)
  }

  add(props) {
    return this.offlineRepository.add(props)
  }

  edit(id, props) {
    return this.offlineRepository.edit(id, props)
  }

  delete(id) {
    return this.offlineRepository.delete(id)
  }

  getByBimId(bim: TBimProps["id"]) {
    return this.offlineRepository.getByParams({ bim })
  }

  getByBimIdAsync(bim: TBimProps["id"]) {
    return this.offlineRepository.getByParamsAsync({ bim })
  }

  getByRemark(remark: Remark) {
    const prescriptions = this.offlineRepository.getByParams({
      bim: remark.bim
    })

    return prescriptions.filter(prescription => prescription.remarks.includes(remark.id))
  }

  async getByRemarkAsync(remark: Remark) {
    const prescriptions = await this.offlineRepository.getByParamsAsync({
      bim: remark.bim
    })

    return prescriptions.filter(prescription => prescription.remarks.includes(remark.id))
  }

  serialyzeAdd(props: TNewPrescriptionProps): TNewRestProps {
    return {
      bim: props.bim,
      receiver: props.receiver,
      notes: props.notes,
      remarks: props.remarks,
      special_conditions: props.specialConditions,
      target_text: props.targetText,
      issued_by: props.issuedBy,
      attributes: props.attributes,
      deadline_at: props.deadlineAt,
      attachments: props.attachments
    }
  }

  getByIdAsync(id) {
    return this.offlineRepository.getByIdAsync(id)
  }

  serialyze(p: TRestProps): TPrescriptionProps {
    const ownerId = typeof p.owner === "object" ? p.owner.id : p.owner

    return {
      id: p.id,
      projectNumber: p.project_number,
      owner: ownerId,
      bim: p.bim,
      receiver: p.receiver,
      notes: p.notes,
      remarks: p.remarks,
      createdAt: p.created_at,
      specialConditions: p.special_conditions,
      updatedAt: p.updated_at,
      targetText: p.target_text,
      issuedBy: p.issued_by,
      attributes: p.attributes,
      deadlineAt: p.deadline_at,
      attachments: p.attachments
    }
  }
}