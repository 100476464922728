import axios from "axios"
import { BaseModel } from "../../models/BaseModel"
import { Entity } from "../../models/Entity"
import { IAuthRepository } from "../AuthRepository/interface"
import { IRepository } from "./interface"
import { ReadOnlyRestRepository } from "./ReadOnlyRestRepository"

export class RestRepository<Model extends Entity<BaseModel>, ResponseProps, NewModelProps> implements IRepository<Model, NewModelProps> {
  constructor(private props: {
    Model: new (props: Model["props"]) => Model

    authRepository?: IAuthRepository
    path: string
    expirationTime?: number

    serialyze?(props: ResponseProps): Model["props"]
    serialyzeAdd?(props: NewModelProps): Partial<ResponseProps>
  }) {
    this.props = props

    this.readOnlyRepository = new ReadOnlyRestRepository(props)
  }

  readOnlyRepository: ReadOnlyRestRepository<Model, ResponseProps>

  getAuthHeadersAsync() {
    return this.props.authRepository ? this.props.authRepository.getAuthHeadersAsync() : {}
  }

  get serialyze() {
    return this.readOnlyRepository.serialyze
  }

  getById(props) {
    return this.readOnlyRepository.getById(props)
  }

  getByIdAsync(props) {
    return this.readOnlyRepository.getByIdAsync(props)
  }

  getAll() {
    return this.readOnlyRepository.getAll()
  }

  getAllAsync() {
    return this.readOnlyRepository.getAllAsync()
  }

  getByParams(filterProps, loadProps) {
    return this.readOnlyRepository.getByParams(filterProps, loadProps)
  }

  getByParamsAsync(filterProps, loadProps) {
    return this.readOnlyRepository.getByParamsAsync(filterProps, loadProps)
  }

  getOneByParams(filterProps, uploadProps?) {
    return this.readOnlyRepository.getOneByParams(filterProps, uploadProps)
  }
  async getOneByParamsAsync(filterProps, uploadProps?) {
    return this.readOnlyRepository.getOneByParamsAsync(filterProps, uploadProps)
  }

  async add(props: NewModelProps): Promise<Model> {
    const response = await axios.post<ResponseProps>(`${this.props.path}`, this.props.serialyzeAdd ? this.props.serialyzeAdd(props) : props, {
      headers: await this.readOnlyRepository.getAuthHeadersAsync(),
    })

    const modelProps = this.readOnlyRepository.serialyze(response.data)

    return this.readOnlyRepository.updateOrCreateModel(modelProps)
  }

  async addBatch(newModelsProps: NewModelProps[]): Promise<Model[]> {
    const modelsProps: Model["props"][] = []

    for (const props of newModelsProps) {
      const response = await axios.post<ResponseProps>(`${this.props.path}`, this.props.serialyzeAdd ? this.props.serialyzeAdd(props) : props, {
        headers: await this.readOnlyRepository.getAuthHeadersAsync(),
      })

      modelsProps.push(this.readOnlyRepository.serialyze(response.data))
    }

    return this.readOnlyRepository.updateOrCreateModels(modelsProps)
  }

  async edit(id: Model["id"], props: NewModelProps): Promise<Model> {
    const response = await axios.patch<ResponseProps>(`${this.props.path}${id}/`, this.props.serialyzeAdd ? this.props.serialyzeAdd(props) : props, {
      headers: await this.readOnlyRepository.getAuthHeadersAsync(),
    })

    const modelProps = this.readOnlyRepository.serialyze(response.data)

    return this.readOnlyRepository.updateOrCreateModel(modelProps)
  }

  async delete(id: Model["id"]) {
    const response = await axios.delete(`${this.props.path}${id}/`, {
      headers: await this.readOnlyRepository.getAuthHeadersAsync(),
    })

    this.readOnlyRepository.models.delete(id)
  }

  addToCache(props: Model["props"]) {
    return this.readOnlyRepository.updateOrCreateModel(props)
  }
}