import { action, computed, makeObservable, observable, ObservableSet, override, reaction, toJS } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Bim, TBimProps } from "../../models/Bim";
import { TBimElementProps } from "../../models/BimElement";
import { IModelElement } from "../../models/ModelElement/interface";
import { EWindowSelectStage } from "../../usecase/SelectedElements/SelectedElements";
import { EOrientations } from "../PanelDropdownMenuViewModel/interface";
import { PanelSelectorMultiAllViewModel } from "../PanelSelectorViewModel/PanelSelectorMultiAllViewModel";
import { ISelectionFilterViewModel } from "./interface";

export class SelectionFilterViewModel implements ISelectionFilterViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  selectedModelElements = new ObservableSet<IModelElement>()

  mount() {
    this.selectedModelElements.replace(this.props.layers.usecases.selectedElements.selectedModelElementsSet)

    this.reactions.push(
      reaction(() => this.filteredGuidsMap, (map) => this.applySelected(map))
    )
  }

  unmount = () => {
    this.reactions.forEach(reaction => reaction())
  }

  reactions: Function[] = []

  @computed
  get filteredGuidsMap() {
    const result = new Set<IModelElement>()

    for (const modelElement of this.selectedModelElements) {
      if (
        this.getBimFilterBySelectedBims(modelElement.id) &&
        this.getForgeElementFilterByConstructionGroup(modelElement) &&
        this.getForgeElementFilterBySections(modelElement) &&
        this.getForgeElementFilterByStatus(modelElement) &&
        this.getForgeElementFilterByWorkType(modelElement) &&
        true
      ) {
        result.add(modelElement)
      }
    }

    return result
  }

  get disabledSaveButton() {
    return false
  }

  @computed
  get bims() {
    const set = new Set<Bim>()
    for (const modelElement of this.selectedModelElements) {
      const bim = this.props.layers.repositories.bimsRepository.getById(modelElement.bim)

      if (bim) {
        set.add(bim)
      }
    }
    return set
  }

  @computed
  get selectedForgeElements() {
    return Array.from(this.selectedModelElements)
  }

  @computed
  get selectedConstructionGroups() {
    return this.props.layers.usecases.selectedElements.getConstructionsGroups(this.selectedForgeElements)
  }

  @computed
  get modelPanelSelectorViewModel() {
    if (this.bims.size <= 1) return

    return new PanelSelectorMultiAllViewModel({
      label: "Модель",
      options: Array.from(this.bims.values()).map(bim => ({ value: bim.id, label: bim.name || bim.fileName })),
      orientation: EOrientations.bottom,
      defaultAllSelected: true
    })
  }

  @computed
  get typePanelSelectorViewModel() {
    if (this.selectedConstructionGroups.length <= 1) return

    return new PanelSelectorMultiAllViewModel({
      label: "Группа конструкции",
      options: this.selectedConstructionGroups.map(constructionsGroup => ({
        value: constructionsGroup || "",
        label: constructionsGroup || "Отсутствует"
      })),
      defaultAllSelected: true,
      orientation: EOrientations.bottom
    })
  }

  @computed
  get sectionPanelSelectorViewModel() {
    const sections = this.props.layers.usecases.selectedElements.getSections(this.selectedForgeElements)
    if (sections.length <= 1) return

    return new PanelSelectorMultiAllViewModel({
      label: "Секция",
      options: sections.map(section => ({
        value: section || "",
        label: section || "Отсутствует"
      })),
      orientation: EOrientations.bottom,
      defaultAllSelected: true
    })
  }

  @computed
  get workTypePanelSelectorViewModel() {
    const workTypes = this.props.layers.usecases.elements.getWorkTypesByForgeElements(this.selectedForgeElements)
    if (workTypes.length <= 1) return

    return new PanelSelectorMultiAllViewModel({
      label: "Вид работ",
      options: workTypes.map(workType => ({
        value: workType?.id || "",
        label: workType?.fullName || "Отсутствует"
      })),
      orientation: EOrientations.bottom,
      defaultAllSelected: true
    })
  }

  @computed
  get statusPanelSelectorViewModel() {
    const bimElements = this.props.layers.usecases.selectedElements.getBimElements(this.selectedForgeElements)
    const statuses = this.props.layers.usecases.statuses.getStatusDictionaryForBimElements(bimElements)
    if (statuses.length <= 1) return

    return new PanelSelectorMultiAllViewModel({
      label: "Статус",
      options: statuses.map(status => status ? {
        value: status.id,
        label: status.name
      } : {
        value: 0,
        label: "Не назначен"
      }),
      orientation: EOrientations.bottom,
      defaultAllSelected: true
    })
  }

  getBimFilterBySelectedBims(id: Bim["id"]) {
    if (
      !this.modelPanelSelectorViewModel ||
      this.modelPanelSelectorViewModel.has(id)
    ) return true

    return false
  }

  getForgeElementFilterByConstructionGroup(forgeElement: IModelElement) {
    if (
      !this.typePanelSelectorViewModel ||
      this.typePanelSelectorViewModel.has(forgeElement.constructionGroup || "")
    ) return true

    return false
  }

  getForgeElementFilterBySections(forgeElement: IModelElement) {
    if (
      !this.sectionPanelSelectorViewModel ||
      this.sectionPanelSelectorViewModel.has(forgeElement.section || "")
    ) return true

    return false
  }

  getForgeElementFilterByStatus(forgeElement: IModelElement) {
    if (!this.statusPanelSelectorViewModel) return true
    const bimElement = this.props.layers.repositories.bimElementsRepository.getByGuid(forgeElement.bim, forgeElement.id)
    if (!bimElement) {
      return this.statusPanelSelectorViewModel.has(0) ? true : false
    }

    const status = this.props.layers.repositories.statusHistoryRepository.getActualByBimElement(bimElement)
    if (!status) return this.statusPanelSelectorViewModel.has(0) ? true : false

    return this.statusPanelSelectorViewModel.has(status.status)
  }

  getForgeElementFilterByWorkType(forgeElement: IModelElement) {
    if (!this.workTypePanelSelectorViewModel) return true
    const workTypes = this.props.layers.usecases.elements.getWorkTypesByForgeElement(forgeElement)

    if (workTypes.size === 0) return this.workTypePanelSelectorViewModel.has("")

    return Array.from(workTypes).some(workType => this.workTypePanelSelectorViewModel?.has(workType.id))
  }

  private async applySelected(set: Set<IModelElement>) {
    this.props.layers.usecases.selectedElements.selectModelElements(set)
  }

  async onSave() {
    this.props.layers.usecases.selectedElements.setWindowStage(EWindowSelectStage.none)
  }

  onClose() {
    this.props.layers.usecases.selectedElements.clearSelection()
    this.props.layers.usecases.selectedElements.setWindowStage(EWindowSelectStage.none)
  }
}