import { action, computed, makeObservable, ObservableMap } from "mobx"
import { BaseModel } from "../../models/BaseModel"
import { Entity } from "../../models/Entity"
import { loadWithReTry } from "../SmartRequest/SmartRequest"
import { IReadOnlyRepository } from "./interface"
import { RequestCache, TRequestCacheProps } from "./RequestCache"

export class ReadOnlyRestRepository<Model extends Entity<BaseModel>, ResponseProps> implements IReadOnlyRepository<Model> {
  constructor(private props: {
    Model: new (props: Model["props"]) => Model

    authRepository?: {
      getAuthHeadersAsync(): Promise<Object>
    }
    path: string
    expirationTime?: number

    serialyze?(props: ResponseProps): Model["props"]
  }) {

    this.props = props

    makeObservable(this)
  }

  models = new ObservableMap<Model["id"], Model>()
  cache = new ObservableMap<string, RequestCache<Model["props"][]>>()

  serialyze(props: ResponseProps): Model["props"] {
    //@ts-ignore
    return this.props.serialyze ? this.props.serialyze(props) : props
  }

  private getOrCreateCache(key: string, props: TRequestCacheProps<Model["props"][]>) {
    let cache = this.getCache(key)
    if (cache) return cache

    return this.createCache(key, props)
  }

  private getCache(key: string) {
    return this.cache.get(key)
  }

  private createCache(key: string, props: TRequestCacheProps<Model["props"][]>) {
    const cache = new RequestCache<Model["props"][]>(props)
    this.cache.set(key, cache)
    return cache
  }

  private async loadWithCache(key: string, props: TRequestCacheProps<Model["props"][]>) {
    const cache = this.getOrCreateCache(key, props)

    if (cache.isExpired) {
      const props = await cache.update()
      this.updateOrCreateModels(props)

      return props
    }

    await cache.promise
  }

  getAuthHeadersAsync() {
    return this.props.authRepository?.getAuthHeadersAsync() || {}
  }

  @action
  updateOrCreateModels(modelsProps: Model["props"][]): Model[] {
    return modelsProps.map(props => this.updateOrCreateModel(props))
  }

  @action
  updateModels(modelsProps: (Partial<Model["props"]> & { id: Model["id"] })[]) {
    return modelsProps.map(props => this.updateModel(props))
  }

  @action
  updateModel(modelProps: Partial<Model["props"]> & { id: Model["id"] }) {
    const model = this.models.get(modelProps.id)
    if (model) model.patch(modelProps)
  }

  @action
  updateOrCreateModel(modelProps: Model["props"]): Model {
    let model = this.models.get(modelProps.id)

    if (model) {
      model.patch(modelProps)
    } else {
      model = new this.props.Model(modelProps)
      this.models.set(model.id, model)
    }

    return model
  }

  async loadPropsById(id: Model["id"]) {
    const url = `${this.props.path}${id}`
    const response = await loadWithReTry(url, {
      headers: await this.getAuthHeadersAsync()
    }, { maxTry: 3 })

    if (!response || response.status === 0) {
      return []
    }

    if (response.status === 200) {
      const responseProps: ResponseProps = await response.json()

      const modelProps = this.serialyze(responseProps)

      return [modelProps]
    }

    return []
  }

  async loadPropsByParams(params?: { [key: string]: any }): Promise<Model["props"][]> {
    const url = params ? `${this.props.path}?${new URLSearchParams(params)}` : this.props.path

    const response = await loadWithReTry(url, {
      headers: await this.getAuthHeadersAsync()
    }, { maxTry: 3 })

    if (!response || response.status === 0) {
      return []
    }

    if (response.status === 200) {
      const responseProps: ResponseProps[] = await response.json()

      const modelsProps = responseProps.map(props => this.serialyze(props))

      return modelsProps
    }

    return []
  }

  private filterByParams(params?: Partial<Model["props"]>) {
    if (!params) return this.modelsArray
    const paramsArray = Array.from(Object.entries(params))

    return this.modelsArray.filter(model => paramsArray.every(([key, value]) => model[key] === value))
  }

  @computed
  get modelsArray(): Model[] {
    return this.getModels()
  }

  getModels() {
    return Array.from(
      this.models.values()
    )
  }

  getModelById(id: Model["id"]): Model | undefined {
    return this.models.get(id)
  }

  getById(id: Model["id"]): Model | undefined {
    const key = String(id)

    this.loadWithCache(key, {
      expirationTime: this.props.expirationTime,
      getData: () => this.loadPropsById(id)
    })

    return this.getModelById(id)
  }

  async getByIdAsync(id: Model["id"]): Promise<Model | undefined> {
    const key = String(id)

    await this.loadWithCache(key, {
      expirationTime: this.props.expirationTime,
      getData: () => this.loadPropsById(id)
    })

    return this.getModelById(id)
  }

  getByParams(modelProps?: Partial<Model["props"]>, loadProps?: { [key: string]: any }): Model[] {
    const key = JSON.stringify(loadProps || modelProps || {})

    this.loadWithCache(key, {
      expirationTime: this.props.expirationTime,
      getData: () => this.loadPropsByParams(loadProps || modelProps),
    })

    return this.filterByParams(modelProps)
  }

  async getByParamsAsync(modelProps?: Partial<Model["props"]>, loadProps?: { [key: string]: any }): Promise<Model[]> {
    const key = JSON.stringify(loadProps || modelProps || {})

    await this.loadWithCache(key, {
      expirationTime: this.props.expirationTime,
      getData: () => this.loadPropsByParams(loadProps || modelProps),
    })

    return this.filterByParams(modelProps)
  }

  getAll(): Model[] {
    return this.getByParams()
  }

  async getAllAsync(): Promise<Model[]> {
    return this.getByParamsAsync()
  }

  getOneByParams(filterProps, loadProps?) {
    return this.getByParams(filterProps, loadProps)[0]
  }
  async getOneByParamsAsync(filterProps, loadProps?) {
    const rows = await this.getByParamsAsync(filterProps, loadProps)
    return rows[0]
  }
}