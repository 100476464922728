import { IButtonViewModel } from "../Buttons/interface";
import { IRemarksFormViewModel } from "../RemarksFormViewModel/interface";
import { IRemarksListViewModel } from "../RemarksListViewModel/interface";

export enum ERemarksPageContent {
  list = "list",
  form = "form"
}

export interface IRemarksPageViewModel {
  content: ERemarksPageContent
  remarksListViewModel: IRemarksListViewModel
  remarksFormViewModel: IRemarksFormViewModel
  onBack(): void
  onAdd?: () => void
  editButtonViewModel?: IButtonViewModel
}