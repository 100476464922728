import { computed, makeObservable } from "mobx";
import { EUnits } from "../../models/Units";
import { TWorkTypeGroupProps, WorkTypeGroup } from "../../models/WorkTypeGroup";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestRepository } from "../Repository/RestRepositoryOld";
import { IWorkTypeGroupRepository } from "./interface";

type TRestProps = {
  id: number
  name: string
  unit?: EUnits
}

export class RestWorkTypeGroupRepository extends RestRepository<WorkTypeGroup, TRestProps, any> implements IWorkTypeGroupRepository {
  Item = WorkTypeGroup

  constructor(private props: { authRepository: IAuthRepository, host: string }) {
    super()
    this.props = props

    makeObservable(this)
  }

  @computed
  get path() {
    return `${this.props.host}api/main/work-type-group/`
  }

  @computed
  get auth() {
    return this.props.authRepository
  }

  getById(id: TWorkTypeGroupProps["id"]) {
    const all = this.getAll()
    return all.find(workType => workType.id === id)
  }

  serialyze(props: TRestProps): WorkTypeGroup["props"] {
    return {
      id: props.id,
      name: props.name,
      unit: props.unit || EUnits.thing
    }
  }
}