import React, { memo } from "react";
import { CircularProgress, withStyles } from "@material-ui/core";
import clsx from "clsx";

function CircularProgressStyledPure({ classes, className }) {
  return (
    <div
      className={clsx(classes.root, className)}
    >
      <CircularProgress className={classes.loader} />
    </div>
  );
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: "100%",
    minHeight: 40,
    minWidth: 40,
  },
  loader: {
    position: "absolute"
  }
};

const CircularProgressStyled = memo(withStyles(styles)(CircularProgressStyledPure));

export default CircularProgressStyled;