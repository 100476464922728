import { EVariants, Notifications } from "../Notifications/Notifications"

export class ServiceWorker {
  constructor(private props: {
    workerPath: string,
    notifications: Notifications
  }) {
    this.props = props

    this.init()
  }

  // private sw?: globalThis.ServiceWorker

  private async init() {
    try {
      await this.registerSW()

      // if (this.sw) {
      //   this.sw.addEventListener("message", console.log)
      // }
    } catch (error) {
      this.props.notifications.notify(error.message, {
        variant: EVariants.error
      })
    }
  }

  private async registerSW() {
    if ('serviceWorker' in navigator) {
      const serviceWorkerRegistration = await navigator.serviceWorker.register(this.props.workerPath)
      await navigator.serviceWorker.ready
      // console.log(navigator.serviceWorker.controller)

      // if (serviceWorkerRegistration.active) {
      //   // this.sw = serviceWorkerRegistration.active
      //   // serviceWorkerRegistration.active?.postMessage("hello world")

      // } else {
      //   throw new Error("сервис воркер не активирован")
      // }
    } else {
      throw new Error("сервис воркер не поддерживается")
    }
  }
}