import { action, computed, makeObservable, observable, reaction } from "mobx";
import { IWindowSelectorViewModel } from "../../view-model/WindowSelectorViewModel/interface";
import { Viewer } from "./Viewer";

export class WindowSelector {
  private materialLine: any
  private lineGeometry: THREE.Geometry | undefined
  private mouseStart: THREE.Vector2 | undefined
  private mouseEnd: THREE.Vector2 | undefined

  private lineColor = 0x000000

  private overlayName = "selectionWindowOverlay"

  constructor(private props: { bimViewer: Viewer, viewModel: IWindowSelectorViewModel }) {
    this.props = props

    makeObservable(this)

    this.mount()
  }

  @computed
  get active() {
    return this.props.viewModel.active
  }

  private mount() {
    const onKeyDown = this.onKeyDown.bind(this)

    window.document.addEventListener("keydown", onKeyDown)

    const clearReaction1 = reaction(() => {
      if (!this.viewer) return false
      return this.active
    }, (active) => {
      active ? this.onActivated() : this.onDeactivated()
    }, {
      fireImmediately: true
    })

    this.unmount = () => {
      clearReaction1()
      window.document.removeEventListener("keydown", onKeyDown)
    }
  }

  // set in mount
  unmount() { }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  @action
  private onKeyDown(e: KeyboardEvent) {
    if (e.key === "Alt") {
      this.props.viewModel.setActive(true)
    }
  }

  @action
  private onKeyUp(e: KeyboardEvent) {
    if (e.key === "Alt") {
      this.props.viewModel.setActive(false)
    }
  }

  private onActivated() {
    if (!this.viewer) return

    this.materialLine = new THREE.LineBasicMaterial({
      color: this.lineColor,
      linewidth: 1,
      opacity: .6
    });

    //@ts-ignore
    this.viewer.navigation.setIsLocked(true)

    const onKeyUp = this.onKeyUp.bind(this)
    const onPointerDown = this.onPointerDown.bind(this)

    window.document.addEventListener('keyup', onKeyUp);
    this.viewer.container.addEventListener('pointerdown', onPointerDown);

    const canvas = this.viewer.canvas;
    const canvasWidth = canvas.clientWidth;
    const canvasHeight = canvas.clientHeight;

    const camera = new THREE.OrthographicCamera(
      0, canvasWidth, 0, canvasHeight, 1, 1000)

    this.viewer.impl.createOverlayScene(
      this.overlayName,
      this.materialLine,
      this.materialLine,
      camera
    );

    this.onDeactivated = () => {
      if (!this.viewer) return
      //@ts-ignore
      this.viewer.navigation.setIsLocked(false)

      window.document.removeEventListener('keyup', onKeyUp);
      this.viewer.container.removeEventListener('pointerdown', onPointerDown);

      this.viewer.impl.removeOverlayScene(this.overlayName)
    }
  }

  // set in onActivated
  private onDeactivated() { }

  onPointerDown(e: PointerEvent) {
    if (!this.viewer) return

    const viewerPos = this.viewer.container.getBoundingClientRect();

    const mouseStart = new THREE.Vector2(e.clientX - viewerPos.x, e.clientY - viewerPos.y)
    this.mouseStart = mouseStart
    this.mouseEnd = mouseStart.clone()

    const lineGroup = new THREE.Group();
    const lineGeometry = new THREE.Geometry();
    this.lineGeometry = lineGeometry

    const vertice = new THREE.Vector3(mouseStart.x, mouseStart.y, -10)

    lineGeometry.vertices.push(
      vertice.clone(),
      vertice.clone(),
      vertice.clone(),
      vertice.clone(),
      vertice.clone()
    );

    lineGeometry.verticesNeedUpdate = true;

    //@ts-ignore
    const lineMesh = new THREE.Line(lineGeometry, this.materialLine, THREE.LineStrip)
    lineGroup.add(lineMesh)

    this.viewer.impl.addOverlay(this.overlayName, lineGroup)

    this.viewer.impl.invalidate(false, false, true)

    const onPointerMove = this.onPointerMove.bind(this)

    const onPointerUp = (e) => {
      if (!this.viewer) return
      lineGroup.remove(lineMesh)

      this.viewer.container.removeEventListener('pointerup', onPointerUp)
      this.viewer.container.removeEventListener('pointermove', onPointerMove)

      this.onPointerUp(e)
    }

    this.viewer.container.addEventListener('pointermove', onPointerMove)
    this.viewer.container.addEventListener('pointerup', onPointerUp)
  }

  onPointerMove(e: PointerEvent) {
    if (
      !this.lineGeometry ||
      !this.viewer ||
      !this.mouseStart
    ) return

    const viewerPos = this.viewer.container.getBoundingClientRect();
    const mouseEnd = new THREE.Vector2(e.clientX - viewerPos.x, e.clientY - viewerPos.y)
    this.mouseEnd = mouseEnd
    const vertice = new THREE.Vector3(mouseEnd.x, mouseEnd.y, -10)

    this.lineGeometry.vertices[1].x = this.mouseStart.x
    this.lineGeometry.vertices[1].y = vertice.y

    this.lineGeometry.vertices[2] = vertice.clone()

    this.lineGeometry.vertices[3].x = vertice.x
    this.lineGeometry.vertices[3].y = this.mouseStart.y

    this.lineGeometry.vertices[4] = this.lineGeometry.vertices[0]

    this.lineGeometry.verticesNeedUpdate = true;

    this.viewer.impl.invalidate(false, false, true);
  }

  private windowGeometryContainsBox(windowGeometry: THREE.Plane[], box: THREE.Box3, partialContains = false) {
    // in feature
    // if (partialContains) {
    //   return this.windowGeometryContainsVector3(windowGeometry, box.min) ||
    //     this.windowGeometryContainsVector3(windowGeometry, box.max)
    // }

    return this.windowGeometryContainsVector3(windowGeometry, box.min) &&
      this.windowGeometryContainsVector3(windowGeometry, box.max)
  }

  private windowGeometryContainsVector3(windowGeometry: THREE.Plane[], vector: THREE.Vector3) {
    return windowGeometry.every(plane => plane.distanceToPoint(vector) <= 0)
  }

  private getWindowGeometry() {
    if (
      !this.viewer ||
      !this.mouseStart ||
      !this.mouseEnd
    ) return

    const camera = this.viewer.getCamera()
    const direction = camera.getWorldDirection()
    const canvas = this.viewer.container
    const width = canvas.clientWidth
    const height = canvas.clientHeight

    const xMin = Math.min(this.mouseStart.x, this.mouseEnd.x) * 2 / width - 1
    const xMax = Math.max(this.mouseStart.x, this.mouseEnd.x) * 2 / width - 1

    const yMin = -Math.max(this.mouseStart.y, this.mouseEnd.y) * 2 / height + 1
    const yMax = -Math.min(this.mouseStart.y, this.mouseEnd.y) * 2 / height + 1

    const nextPointLength = 100

    const point1 = new THREE.Vector3(xMin, yMin, -1).unproject(camera)
    const point2 = new THREE.Vector3(xMax, yMin, -1).unproject(camera)
    const point3 = new THREE.Vector3(xMin, yMax, -1).unproject(camera)
    const point4 = new THREE.Vector3(xMax, yMax, -1).unproject(camera)

    const point1Next = point1.clone().add(direction.clone().multiplyScalar(nextPointLength))
    const point2Next = point2.clone().add(direction.clone().multiplyScalar(nextPointLength))
    const point3Next = point3.clone().add(direction.clone().multiplyScalar(nextPointLength))
    const point4Next = point4.clone().add(direction.clone().multiplyScalar(nextPointLength))

    const plane1 = new THREE.Plane()
    const plane2 = new THREE.Plane()
    const plane3 = new THREE.Plane()
    const plane4 = new THREE.Plane()
    const plane5 = new THREE.Plane()
    const plane6 = new THREE.Plane()

    plane1.setFromCoplanarPoints(point1, point1Next, point2)
    plane2.setFromCoplanarPoints(point2, point2Next, point4)

    plane3.setFromCoplanarPoints(point4, point4Next, point3)
    plane4.setFromCoplanarPoints(point3, point3Next, point1)

    plane5.setFromCoplanarPoints(point1, point2, point3)
    plane6.setFromCoplanarPoints(point1Next, point2Next, point3Next).normal.negate()

    const planes = [
      plane1,
      plane2,
      plane3,
      plane4,
      plane5,
      plane6
    ]

    return planes
  }

  async applySelected() {
    if (
      !this.viewer ||
      !this.mouseStart ||
      !this.mouseEnd
    ) return

    const windowGeometry = this.getWindowGeometry()
    if (!windowGeometry) return

    const partialContains = this.mouseStart.x > this.mouseEnd.x

    const models = this.viewer.getAllModels()

    const result: [string, number[]][] = []

    for (const model of models) {
      //@ts-ignore
      const urn = model.getSeedUrn()
      const modelDbIds: number[] = this.getAllDbIds(model)
      const array: number[] = []

      for (const dbId of modelDbIds) {
        const box = await this.getDbIdBox3(dbId, model)

        const contains = this.windowGeometryContainsBox(windowGeometry, box, partialContains)

        if (contains) {
          array.push(dbId)
        }
      }

      if (array.length > 0) {
        result.push([urn, array])
      }
    }

    this.props.viewModel.onSelect(result)
  }

  getAllDbIds(model: Autodesk.Viewing.Model) {
    const fragId2dbId = model.getFragmentList().fragments.fragId2dbId;

    if (typeof fragId2dbId[0] === 'object') {
      return fragId2dbId.reduce((acc, ids) => [...acc, ...ids], []);
    }

    return fragId2dbId;
  }

  async getDbIdBox3(dbId, model: Autodesk.Viewing.Model) {
    const fragId = await this.getFragIdByDbId(dbId, model)
    const box = new THREE.Box3();
    model.getFragmentList().getWorldBounds(fragId, box);
    return box;
  };

  getFragIdByDbId(dbId: number, model: Autodesk.Viewing.Model) {
    return new Promise<number>((resolve, reject) => {
      model.getData().instanceTree.enumNodeFragments(dbId, fragId => {
        resolve(fragId);
      }, e => {
        reject(e);
      });
    });
  }

  @action
  onPointerUp(e: PointerEvent) {
    this.applySelected()
  }

  getModifiedWorldBoundingBox(fragIds, fragList) {
    const fragbBox = new THREE.Box3()
    const nodebBox = new THREE.Box3()

    fragIds.forEach(function (fragId) {
      fragList.getWorldBounds(fragId, fragbBox)
      nodebBox.union(fragbBox)
    })

    return nodebBox
  }
}