import React from "react"
import { IconButton, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IMeasurementsPanelViewModel } from "../../view-model/MeasurementsPanelViewModel/interface"
import { Check, Close, Delete } from "@material-ui/icons"
import { SimpleButton } from "../SimpleButton/SimpleButton"
import clsx from "clsx"

const useClasses = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",

    boxShadow: "1px 3px 10px 0 rgb(0 0 0 / 40%)",

    backgroundColor: "#fff",
    opacity: 0.95,
    borderRadius: 4,
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    boxShadow: "0 3px 5px 0 rgb(0 0 0 / 10%)",
    paddingLeft: 22,
  },
  header: {
    fontWeight: 600
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  list: {
    overflow: "auto",
    flex: 1
  },
  listItem: {
    padding: "10px 20px 10px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "12px",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0001"
    },
  },
  buttons: {
    boxShadow: "0 -3px 5px 0 rgb(0 0 0 / 10%)",

    padding: 10
  },
  addButton: {
    backgroundColor: "green",
    color: "white"
  },
  cancel: {}
}))

interface IMeasurementsPanelProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IMeasurementsPanelViewModel
}

export const MeasurementsPanel = observer((props: IMeasurementsPanelProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div className={classes.header}>{props.viewModel.header}</div>
        <IconButton onClick={props.viewModel.onClose} className={classes.cancel}>
          <Close />
        </IconButton>
      </div>
      <div className={clsx(classes.content, classes.cancel)}>
        <div className={classes.list}>
          {
            props.viewModel.measurements.map(measurement => (
              <div className={classes.listItem} key={measurement.key}>
                <div>{measurement.name}</div>
                <IconButton size="small" onClick={measurement.onDelete}>
                  <Delete />
                </IconButton>
              </div>
            ))
          }
        </div>
        <div className={clsx(classes.buttons, classes.cancel)}>
          {
            props.viewModel.addButtonViewModel ? (
              <SimpleButton className={classes.addButton} viewModel={props.viewModel.addButtonViewModel} />
            ) : null
          }
        </div>
      </div>
    </div>
  )
})