import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { ICollectionsMultiBoardViewModel } from "../../view-model/CollectionsMultiBoardViewModel/interface"
import { CollectionsBoard } from "../CollectionsBoard/CollectionsBoard"
import { StatusWrapper } from "../StatusWrapper/StatusWrapper"

const useClasses = makeStyles(theme => ({
  root: {
    height: "100%",
  },
  content: {
    "& >:not(:first-child)": {
      marginTop: 20
    }
  },
}))

interface ICollectionsMultiBoardProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: ICollectionsMultiBoardViewModel
}

export const CollectionsMultiBoard = observer((props: ICollectionsMultiBoardProps) => {
  const classes = useClasses(props)

  return (
    <StatusWrapper classes={classes} viewModel={props.viewModel.statusWrapperViewModel}>
      {
        props.viewModel.collectionsBoards.map(({ key, viewModel }) =>
          <div key={key}>
            <CollectionsBoard viewModel={viewModel} />
          </div>
        )
      }
    </StatusWrapper>
  )
})