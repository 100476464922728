import { BaseModel } from "../../models/BaseModel";
import { Entity } from "../../models/Entity";
import { IAuthRepository } from "../AuthRepository/interface";
import { ApiCache } from "../Cache/ApiCache";
import { loadWithReTry } from "../SmartRequest/SmartRequest";
import { IReadOnlyRepository } from "./interface";
import { ReadOnlyRestRepository } from "./ReadOnlyRestRepository";

export class RestOfflineReadOnlyRepository<Model extends Entity<BaseModel>, ResponseProps> implements IReadOnlyRepository<Model> {
  constructor(private props: {
    Model: new (props: Model["props"]) => Model

    authRepository?: IAuthRepository
    path: string
    expirationTime?: number

    serialyze?(props: ResponseProps): Model["props"]
  }) {
    this.props = props

    this.readOnlyRepository = new ReadOnlyRestRepository({
      ...props,
    })

    this.cache = new ApiCache({
      name: props.path
    })

    this.readOnlyRepository.loadPropsById = this.loadPropsById
    this.readOnlyRepository.loadPropsByParams = this.loadPropsByParams
  }

  readOnlyRepository: ReadOnlyRestRepository<Model, ResponseProps>
  cache: ApiCache<Model["props"]>

  get models() {
    return this.readOnlyRepository.models
  }

  get modelsArray() {
    return this.readOnlyRepository.modelsArray
  }

  getAuthHeadersAsync() {
    return this.readOnlyRepository.getAuthHeadersAsync()
  }

  loadPropsById = async (id: Model["id"]): Promise<Model["props"][]> => {
    const url = `${this.props.path}${id}/`
    const response = await loadWithReTry(url, {
      headers: await this.readOnlyRepository.getAuthHeadersAsync()
    }, { maxTry: 3 })

    if (!response || response.status === 0) {
      return this.cache.get(url)
    }

    if (response.status === 200) {
      const responseProps: ResponseProps = await response.json()

      const modelProps = this.readOnlyRepository.serialyze(responseProps)

      this.cache.put(url, [modelProps])

      return [modelProps]
    }

    return []
  }

  loadPropsByParams = async (params?: { [key: string]: any }): Promise<Model["props"][]> => {
    const url = params ? `${this.props.path}?${new URLSearchParams(params)}` : this.props.path
    const response = await loadWithReTry(url, {
      headers: await this.readOnlyRepository.getAuthHeadersAsync()
    }, { maxTry: 3 })

    if (!response || response.status === 0) {
      return this.cache.get(url)
    }

    if (response.status === 200) {
      const responseProps: ResponseProps[] = await response.json()

      const modelsProps = responseProps.map(props => this.readOnlyRepository.serialyze(props))

      this.cache.put(url, modelsProps)

      return modelsProps
    }

    return []
  }

  getById(props) {
    return this.readOnlyRepository.getById(props)
  }
  async getByIdAsync(props) {
    return this.readOnlyRepository.getByIdAsync(props)
  }

  getAll() {
    return this.readOnlyRepository.getAll()
  }
  async getAllAsync() {
    return this.readOnlyRepository.getAllAsync()
  }

  getByParams(filterProps, loadProps?) {
    return this.readOnlyRepository.getByParams(filterProps, loadProps)
  }
  getOneByParams(filterProps, loadProps?) {
    return this.getByParams(filterProps, loadProps)[0]
  }
  async getByParamsAsync(filterProps, loadProps?) {
    return this.readOnlyRepository.getByParamsAsync(filterProps, loadProps)
  }
  async getOneByParamsAsync(filterProps, loadProps?) {
    const rows = await this.getByParamsAsync(filterProps, loadProps)
    return rows[0]
  }
}