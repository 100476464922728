import React, { useCallback } from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import SimpleButton from "../../components/Buttons/SimpleButton";
import { observer } from "mobx-react-lite";
import { ICollectionsBoardViewModel } from "../../view-model/CollectionsBoardViewModel/interface";
import { CollectionCard } from "../CollectionCard/CollectionCard";
import { CollectionForm } from "../CollectionForm/CollectionForm";
import { MuuriComponent } from "muuri-react";
import clsx from "clsx";
import { StatusWrapper } from "../StatusWrapper/StatusWrapper";

const useClasses = makeStyles(theme => ({
    root: {
    },
    content: {
    },
    newCollectionButton: {
        marginLeft: 16,

        display: "inline-flex",
        width: 40,
        height: 40,
        padding: 0,
        backgroundColor: "#FFF",
        borderRadius: 40,
        alignItems: "center",
        justifyContent: "center",

        "& svg": {
            fill: theme.palette.primary.main
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between",

        margin: "0px 0px 20px 7px",
        fontSize: "16px",
        fontWeight: 700
    },
    headerBlock: {
        display: "flex",
        alignItems: "center",
    },

    primaryButton: {
        backgroundColor: theme.palette.primary.main,
        alignItems: "center",
        display: "flex",
    },

    addСonsolidatedButton: {
        marginLeft: 10,

        "& >svg": {
            padding: 3
        }
    }
}))

const useClassesForm = makeStyles(() => ({
    root: {
        width: 340,
        padding: 20,
    },
}))

interface ICollectionsBoardProps {
    viewModel: ICollectionsBoardViewModel
    classes?: Partial<ReturnType<typeof useClasses>>
}

const style: React.CSSProperties = {
    margin: 5,
    width: "406px"
}

export const CollectionsBoard = observer((props: ICollectionsBoardProps) => {
    const classes = useClasses(props);
    const classesForm = useClassesForm();

    const onDragEnd = useCallback((item) => {
        const items = item.getGrid().getItems().map((item) => String(item.getKey()));
        props.viewModel.onSort(items)
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerBlock}>
                    <div>{props.viewModel.header}</div>
                    {
                        props.viewModel.addButtonViewModel ?
                            <SimpleButton className={classes.newCollectionButton} {...props.viewModel.addButtonViewModel}>
                                <Add />
                            </SimpleButton>
                            : props.viewModel.addButtonViewModel
                    }
                </div>

                <div className={classes.headerBlock}>
                    {
                        props.viewModel.openСonsolidatedButtonViewModel ? (
                            <SimpleButton className={classes.primaryButton} {...props.viewModel.openСonsolidatedButtonViewModel}>
                                Сводная модель
                            </SimpleButton>
                        ) : undefined
                    }
                    {
                        props.viewModel.addСonsolidatedButtonViewModel ? (
                            <SimpleButton className={clsx(classes.primaryButton, classes.addСonsolidatedButton)} {...props.viewModel.addСonsolidatedButtonViewModel}>
                                <Edit />
                            </SimpleButton>
                        ) : null
                    }
                </div>

            </div>
            <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
                <MuuriComponent
                    dragEnabled
                    sort={props.viewModel.sort}
                    onDragEnd={onDragEnd}
                    containerClass={classes.content}
                >
                    {
                        props.viewModel.collections?.map(collection => (
                            <div key={collection.key} style={style}>
                                <CollectionCard viewModel={collection.viewModel} />
                            </div>
                        ))
                    }
                </MuuriComponent>
            </StatusWrapper>
            <Dialog open={props.viewModel.dialogOpened} onClose={() => props.viewModel.onCloseDialog()}>
                {
                    props.viewModel.collectionFormViewModel ? <CollectionForm classes={classesForm} viewModel={props.viewModel.collectionFormViewModel} /> : null
                }
            </Dialog>
        </div>
    )
})