import { TBaseModel } from "./BaseModel";
import { Entity } from "./Entity";

export type TBundleFileProps = {
  key: EBundleFileKeys
  url: string
} & TBaseModel

export enum EBundleFileKeys {
  bim_optimized = "bim_optimized",
  bim_optimizedGz = "bim_optimizedGz",

  bim_metadata_static = "bim_metadata_static",
  bim_metadata_staticGz = "bim_metadata_staticGz",

  bim_metadata = "bim_metadata",
  bim_metadataGz = "bim_metadataGz",
}

export class BundleFile extends Entity<TBundleFileProps> {
  constructor(props: TBundleFileProps) {
    super(props)
  }

  get key() {
    return this.props.key
  }

  get url() {
    return this.props.url
  }
}