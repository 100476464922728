import React from "react"
import { observer } from "mobx-react-lite"
import { IRightViewerPanelViewModel } from "../../view-model/RightViewerPanelViewModel/interface"
import { makeStyles } from "@material-ui/core/styles";
import { useMemo } from "react";
import { RightViewerControlPanel } from "../RightViewerControlPanel/RightViewerControlPanel";
import { SelectionFilter } from "../SelectionFilter/SelectionFilter";
import { PlanningMode } from "../PlanningMode/PlanningMode";
import { ElementsPropertyShortPanel } from "../ElementsPropertyShortPanel/ElementsPropertyShortPanel";
import ViewerCrumbs from "../../components/Crumbs/ViewerCrumbs";

const useClasses = makeStyles(theme => ({
  root: {
    position: "absolute",
    pointerEvents: "none",
    right: 40,
    top: 40,
    bottom: 0,
    left: 0,

    display: "flex",
    flexDirection: "column"
  },

  fakeCubeBlock: {
    height: 170
  },

  crumbsBlock: {
    flex: 1,
    marginBottom: 20
  },
  crumbsRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  crumb: {
    pointerEvents: "auto",

    margin: 0,

    "&:not(:last-child)": {
      marginBottom: 20,
    }
  },

  controlPanelBlock: {
    display: "flex",
    justifyContent: "flex-end"
  },
  controlPanelRoot: {
    pointerEvents: "auto",
    display: "inline-flex"
  },
  fakeBlock: {
    flex: 1
  },
  planningModeActive: {
    position: "absolute",
    top: "-40px",
    left: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  planningModeBlock: {
    padding: "10px",
    background: "#fdc611",
    fontWeight: 600,
    borderRadius: "8px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
  shortPanel: {
    position: "absolute",
    right: 0,
    bottom: 15,
    display: "grid",
    justifyItems: "end",
    gridTemplateColumns: "auto",
    rowGap: 10
  }
}))

const useSelectionFilterClasses = makeStyles(theme => ({
  root: {
    position: "absolute",
    right: 0,
    pointerEvents: "auto",
    padding: 20,
    width: 350,
    borderRadius: 14,
    boxSizing: "border-box",
    backgroundColor: "white"
  }
}))

interface IRightViewerPanelProps {
  viewer?: Autodesk.Viewing.Viewer3D
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IRightViewerPanelViewModel
}

export const RightViewerPanel = observer((props: IRightViewerPanelProps) => {
  const {
    viewer,
  } = props;
  const classes = useClasses(props);

  const classesSelectionFilter = useSelectionFilterClasses();

  const classesCrumbs = useMemo(() => ({
    root: classes.crumbsRoot,
    crumb: classes.crumb
  }), []);

  const classesControlPanel = useMemo(() => ({
    root: classes.controlPanelRoot
  }), []);

  return (
    <div className={classes.root}>
      {
        props.viewModel.showSelectionFilter ? (
          <SelectionFilter
            viewModel={props.viewModel.selectionFilterViewModel}
            classes={classesSelectionFilter}
          />
        ) : null
      }
      {
        props.viewModel.showSelectionPlanningMode ? (
          <PlanningMode
            viewModel={props.viewModel.planningModeViewModel}
            classes={classesSelectionFilter}
          />
        ) : null
      }
      {
        props.viewModel.isPlanningModeActive && (
          <div className={classes.planningModeActive}>
            <div className={classes.planningModeBlock} >
              Включен режим планирования
            </div>
          </div>
        )
      }
      <div className={classes.crumbsBlock}>
        <ViewerCrumbs
          classes={classesCrumbs}
          crumbs={props.viewModel.crumbs}
        />
      </div>
      <div className={classes.controlPanelBlock}>
        <RightViewerControlPanel viewer={viewer} classes={classesControlPanel} viewModel={props.viewModel.rightViewerControlPanelViewModel} />
      </div>
      <div className={classes.fakeBlock}></div>
      <div className={classes.shortPanel}>
        {
          props.viewModel.elementsPropertyShortPanelViewModels.map(({ key, viewModel }) => (
            <ElementsPropertyShortPanel key={key} viewModel={viewModel} />
          ))
        }
      </div>
    </div>
  );
})