import { action, computed, makeObservable, observable, ObservableMap, toJS } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { IPlanningModeViewModel, ISimpleProps } from "./interface";
import { EWindowSelectStage } from "../../usecase/SelectedElements/SelectedElements";
import { BimElement } from "../../models/BimElement";
import { BimStatus, TNewBimStatusProps } from "../../models/BimStatus";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { isEqual, isSameDay } from "date-fns";
import { IModelElement } from "../../models/ModelElement/interface";

export class PlanningModeViewModel implements IPlanningModeViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)

    this.init()
  }

  private async init() {
    this.props.layers.usecases.planningMode.selectedDeadline.onChange("")

    if (this.selectedForgeElements.length === 0) return


    const firstStatus = await this.getStatusByForgeElementAsync(this.selectedForgeElements[0])
    let setDeadline = true

    for (const forgeElement of this.selectedForgeElements) {
      const status = await this.getStatusByForgeElementAsync(forgeElement)

      if (status && firstStatus) {
        if (!isSameDay(status.deadline, firstStatus.deadline)) {
          setDeadline = false
          break
        }
      } else if (status || firstStatus) {
        setDeadline = false
        break
      }
    }


    if (setDeadline && firstStatus) {
      this.props.layers.usecases.planningMode.selectedDeadline.onChange(firstStatus.deadline.toISOString())
    }
  }

  async getStatusByForgeElementAsync(forgeElement: IModelElement): Promise<BimStatus | undefined> {
    const bimElement = await this.props.layers.usecases.elements.getBimElementAsync(forgeElement)

    if (!bimElement) return

    return this.props.layers.repositories.bimStatusRepository.getActualByBimElementAsync(bimElement)
  }

  statusWrapperViewModel = new StatusWrapperViewModel()

  onBack() {
    this.props.layers.usecases.viewState.setPanelOpened(false)
  }

  @computed
  get isPlanningModeActive() {
    return this.props.layers.usecases.planningMode.active.value
  }

  @computed
  get selectedForgeElements() {
    return this.props.layers.usecases.selectedElements.selectedForgeElements
  }

  @computed
  get selectedDeadline() {
    return this.props.layers.usecases.planningMode.selectedDeadline
  }

  @computed
  get differentDate() {
    return this.props.layers.usecases.planningMode.differentDate
  }

  @action
  async confirmSavePlannedStatus() {
    this.statusWrapperViewModel.load()

    const bimElements = await this.props.layers.usecases.elements.getOrCreateBimElements(this.selectedForgeElements)
    const firstStatus = await this.props.layers.repositories.bimStatusRepository.getActualByBimElementAsync(bimElements[0])

    for (const bimElement of bimElements) {
      const status = await this.props.layers.repositories.bimStatusRepository.getActualByBimElementAsync(bimElement)

      if (firstStatus?.props.deadline !== status?.props.deadline) {
        this.props.layers.usecases.planningMode.setDifferentDate(true)
        break
      }
    }

    this.statusWrapperViewModel.success()

    if (!this.differentDate) {
      this.savePlannedStatus(false)
    }
  }

  @computed
  get savePlannedStatusButtonViewModel() {
    return {
      disabled: !this.props.layers.usecases.planningMode.selectedDeadline.value,
      onClick: () => this.confirmSavePlannedStatus()
    }
  }

  @action
  async savePlannedStatus(isOnlyWithoutDate: boolean) {
    this.statusWrapperViewModel.load()

    const statuses: TNewBimStatusProps[] = []
    const bimElements = await this.props.layers.usecases.elements.getOrCreateBimElements(this.selectedForgeElements)

    for (const bimElement of bimElements) {
      const newStatus = await this.getNewPlannedStatus({ bimElement, isOnlyWithoutDate })

      if (newStatus) {
        statuses.push(newStatus)
      }
    }

    await this.props.layers.repositories.bimStatusRepository.savePlannedStatus(statuses)

    this.onClearSelectedElements()

    this.statusWrapperViewModel.success()
  }

  async getNewPlannedStatus(props: { bimElement: BimElement, isOnlyWithoutDate: boolean }): Promise<TNewBimStatusProps | undefined> {
    const forgeElement = this.props.layers.usecases.elements.getForgeElementByBimElement(props.bimElement)

    if (!forgeElement) return

    const statusDictionaries = this.props.layers.usecases.statuses.getStatusDictionaryForForgeElement(forgeElement)
    const lastStatusDictionary = statusDictionaries[statusDictionaries.length - 1]

    if (!lastStatusDictionary) return

    if (props.isOnlyWithoutDate) {
      const activePlannedStatus = this.props.layers.repositories.bimStatusRepository.getActualByBimElement(props.bimElement)

      return {
        bim: props.bimElement.bim,
        element: props.bimElement.id,
        status: lastStatusDictionary.id,
        deadline: activePlannedStatus ? activePlannedStatus.deadline.toISOString() : this.props.layers.usecases.planningMode.selectedDeadline.value,
      }
    }

    return {
      bim: props.bimElement.bim,
      element: props.bimElement.id,
      status: lastStatusDictionary.id,
      deadline: this.props.layers.usecases.planningMode.selectedDeadline.value,
    }
  }

  @action
  setDifferentDate(isOpen: boolean) {
    this.props.layers.usecases.planningMode.setDifferentDate(isOpen)
  }

  getPlannedStatus() {
    this.props.layers.usecases.planningMode.getPlannedStatus()
  }

  onClearSelectedElements() {
    this.props.layers.usecases.selectedElements.clearSelection()
    this.props.layers.usecases.selectedElements.setWindowStage(EWindowSelectStage.none)
  }
}