import { action, computed, makeObservable } from "mobx";
import { BaseModel, TBaseModel } from "./BaseModel";
import { TBimElementProps } from "./BimElement";
import { Entity } from "./Entity";
import { TUploadFileProps } from "./UploadFile";
import { TStatusDictionaryProps } from "./StatusDictionary";
import { v4 } from "uuid";
import { TUserProps } from "./User";

export type TStatusHistoryProps = {
  element: TBimElementProps["id"];
  isActive: boolean;
  status: TStatusDictionaryProps["id"];
  date: string;
  comment: string;
  files: TUploadFileProps["id"][];
  owner: TUserProps["id"];
  statusIndex: number | null;
} & TBaseModel;

export type TNewStatusHistoryProps = {
  element: TBimElementProps["id"];
  status: TStatusDictionaryProps["id"];
  comment: string;
  files: TUploadFileProps["id"][];
  isActive?: boolean;
  owner: TUserProps["id"];
  statusIndex: number | null;
};

export class StatusHistory extends Entity<TStatusHistoryProps> {
  constructor(props: TStatusHistoryProps) {
    super(props);

    makeObservable(this);
  }

  get element() {
    return this.props.element;
  }

  get isActive() {
    return this.props.isActive;
  }

  @action
  setActive(active: boolean) {
    this.props.isActive = active;
  }

  get status() {
    return this.props.status;
  }

  get statusIndex() {
    return this.props.statusIndex;
  }

  get comment() {
    return this.props.comment;
  }

  get files() {
    return this.props.files;
  }

  @computed
  get date() {
    return new Date(this.props.date);
  }

  get owner() {
    return this.props.owner;
  }
}

export class NewStatusHistory extends StatusHistory {
  constructor(props: TNewStatusHistoryProps & Partial<BaseModel>) {
    super({
      id: props.id || v4(),
      ...props,
      isActive: true,
      date: new Date().toISOString(),
    });
  }
}
