import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { EOrientations, IPanelDropdownMenuViewModel, TOption } from "../../view-model/PanelDropdownMenuViewModel/interface"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import clsx from "clsx"

const useClasses = makeStyles<
  any,
  {
    classes?: any
    orientation: EOrientations
  }>(theme => ({
    menuWrapper: {
      zIndex: 2,
      position: "fixed",
      overflow: "hidden",
      pointerEvents: "none",
    },
    menu: {
      backgroundColor: "white",

      pointerEvents: "auto",
      transition: "transform 0.4s ease",
      boxShadow: ({ orientation }) => orientation === EOrientations.right ? "inset 10px 0 20px -10px rgba(0, 0, 0, 0.05)" : "inset 0px 0px 7px 0px rgba(0, 0, 0, 0.05)",
      borderRadius: ({ orientation }) => orientation === EOrientations.right ? "0px 10px 10px 0px" : "0px 0px 10px 10px",
      overflow: "hidden",
      transform: ({ orientation }) => orientation === EOrientations.right ? "translateX(calc(-100% - 1px))" : "translateY(calc(-100% - 1px))",
    },
    menuOpen: {
      transform: ({ orientation }) => orientation === EOrientations.right ? "translateX(0)" : "translateY(0)",
    },
    options: {
      padding: "10px 0",
      userSelect: "none",
      overflow: "auto"
    },
    option: {
      cursor: "pointer",
      padding: "0 30px",
      fontSize: "12px",
      minHeight: 44,
      display: "flex",
      alignItems: "center",

      "&:hover": {
        backgroundColor: "#f5f5f5"
      }
    },
    emptyOptions: {
      lineHeight: "44px",
      padding: "10px 30px",
      fontSize: "12px",
    },
    selectedOption: {
      fontSize: "12px",
      fontWeight: "bold",
      opacity: 1,
    },
    disabledOption: {
      opacity: 0.3,
      cursor: "not-allowed"
    },
    icon: {
      transform: ({ orientation }) => orientation === EOrientations.bottom ? "rotate(90deg)" : undefined,
    },
    group: {

    },
    groupLabel: {
      padding: "0 30px",
      lineHeight: "44px",
      fontSize: "14px",

      cursor: "pointer",

      "&:hover": {
        backgroundColor: "#f5f5f5"
      },

      "&:hover ~ div": {
        backgroundColor: "#f5f5f5"
      }
    },
    groupOptions: {
      "& > *": {
        paddingLeft: 40
      }
    },
    groupLabelSelected: {
      fontWeight: "bold",
      fontSize: "14px",
    },
    groupSelected: {

    }
  }))

interface IPanelDropdownMenuProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPanelDropdownMenuViewModel
}

export const PanelDropdownMenu = observer((props: IPanelDropdownMenuProps) => {
  const classes = useClasses({
    classes: props.classes,
    orientation: props.viewModel.orientation
  })

  const rootRef = useRef<HTMLDivElement>(null);
  // // pre orientation
  // const preparedOrientation = useMemo(() => {
  //   if (orientation === "auto") {
  //     if (rootRef.current) {
  //       const allowedWidth = window.innerWidth - rootRef.current.getBoundingClientRect().right;
  //       if (allowedWidth < 100) {
  //         return "bottom"
  //       } else {
  //         return "right"
  //       }
  //     }
  //     return "right"
  //   } else {
  //     return orientation;
  //   }
  // }, [menuOpen]);

  // // post orientation
  // useEffect(() => {
  //   if (menuOpen && orientation === "auto" && rootRef.current) {
  //     const allowedWidth = window.innerWidth - rootRef.current.getBoundingClientRect().right;
  //     if (allowedWidth < 100) {
  //       setCalculatedOrientation("bottom")
  //     } else {
  //       setCalculatedOrientation("right")
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (rootRef.current) props.viewModel.onRootMount(rootRef.current)

    return () => {
      props.viewModel.unmount?.()
    }
  }, [props.viewModel])

  return (
    <div onClick={props.viewModel.onClick} ref={rootRef}>
      <div
        className={classes.menuWrapper}
        style={props.viewModel.menuWrapperStyle}
      >
        <div
          className={clsx(classes.menu, {
            [classes.menuOpen]: props.viewModel.opened
          })}
        >
          {
            props.viewModel.menuRendered ?
              props.viewModel.isEmpty ? <div className={classes.emptyOptions}>Выбор недоступен</div> :
                <div
                  className={classes.options}
                  style={props.viewModel.optionsStyle}
                >
                  {
                    props.viewModel.options.map((option) => (
                      <Option key={option.key} option={option} classes={classes} />
                    ))
                  }
                  {
                    props.viewModel.groups.map(group =>
                      <div className={clsx(classes.group, {
                        [classes.groupSelected]: group.selected
                      })} key={group.key}>
                        <div
                          className={clsx(classes.groupLabel, {
                            [classes.groupLabelSelected]: group.selected
                          })}
                          onClick={group.onClick}
                        >{group.name}</div>
                        <div className={classes.groupOptions}>
                          {
                            group.options.map((option) => (
                              <Option key={option.key} option={option} classes={classes} />
                            ))
                          }
                        </div>
                      </div>
                    )
                  }
                </div>
              : null
          }

        </div>
      </div>
    </div>
  )
})

interface IOptionProps {
  classes: ClassNameMap<string>
  option: TOption
}

const Option = observer(({ classes, option }: IOptionProps) => {
  return (
    <div
      className={clsx(classes.option, {
        [classes.selectedOption]: option.selected,
        [classes.disabledOption]: option.disabled,
      })}
      onClick={option.onClick}
    >
      {option.label}
    </div>
  )
})