import { computed, makeObservable } from "mobx"
import { TBaseModel } from "../BaseModel"
import { Bim } from "../Bim"
import { FrozenEntity } from "../Entity/FrozenEntity"
import { IModelElement } from "./interface"

export type TForgeElement = {
  modelID: number
  bim: Bim["id"]
  guid: string
  name: string

  properties?: {
    [key: string]: {
      [key: string]: string
    }
  }
} & TBaseModel

export class ForgeElement extends FrozenEntity<TForgeElement> implements IModelElement {
  constructor(props: TForgeElement) {
    super(props)

    makeObservable(this)
  }

  get id() {
    return this.objectID
  }

  get modelID() {
    return this.props.modelID
  }

  @computed({ keepAlive: true })
  get objectID() {
    const objectIDMatch = this.props.name.match(/\[\d+\]/)
    if (!objectIDMatch) return String(parseInt(this.props.guid.replace(/.+-/, ""), 16))
    const objectID = objectIDMatch[0].replace(/\[|\]/g, "")

    return objectID
  }

  get name() {
    return this.props.name
  }

  get bim() {
    return this.props.bim
  }

  get guid() {
    return this.props.guid
  }

  textKey = "Текст"
  sizeKey = "Размеры"
  otherKey = "Прочее"

  @computed
  get geometryElementsModelID() {
    return new Set([this.modelID])
  }

  get consolidatedModelElement() {
    return this
  }

  isConsolidatedModelElement = true
  isGeometryElement = true

  @computed
  get textProps() {
    return this.props.properties?.["Текст"]
  }

  @computed
  get modelProps() {
    return this.props.properties?.["Свойства модели"]
  }

  @computed
  get sizeProps() {
    return this.props.properties?.["Размеры"]
  }

  @computed
  get otherProps() {
    return this.props.properties?.["Прочее"]
  }

  @computed
  get constructionGroup() {
    return this.modelProps?.["INGD_Группа конструкции"] || this.textProps?.["INGD_Группа конструкции"]
  }

  @computed
  get section() {
    return this.modelProps?.["INGD_Секция"] || this.textProps?.["INGD_Секция"]
  }

  @computed
  get storey() {
    return this.modelProps?.["INGD_Номер этажа"] || this.textProps?.["INGD_Номер этажа"]
  }

  @computed
  get material() {
    return this.modelProps?.["INGD_Категория материала"] || this.textProps?.["INGD_Категория материала"]
  }

  @computed
  get materials() {
    return this.material?.split(",") || []
  }

  @computed
  get width() {
    return this.sizeProps?.["INGD_Ширина"]
  }

  @computed
  get length() {
    return this.sizeProps?.["INGD_Длина"]
  }

  @computed
  get height() {
    return this.sizeProps?.["INGD_Высота"]
  }

  @computed
  get value() {
    return this.sizeProps?.["Объем"]
  }

  @computed
  get volumeNumber() {
    return parseFloat(this.value || "0")
  }

  @computed
  get squareNumber() {
    return parseFloat(this.square || "0")
  }

  @computed
  get square() {
    return this.sizeProps?.["Площадь"]
  }

  @computed
  get squareValue() {
    return this.getValueFromString(this.square)
  }

  @computed
  get lengthValue() {
    return this.getValueFromString(this.length)
  }

  @computed
  get volumeValue(): number {
    return this.getValueFromString(this.value)
  }

  private getValueFromString(string: string | undefined): number {
    const value = string ? parseFloat(string) : 0
    return isNaN(value) ? 0 : value
  }
}