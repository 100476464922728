import { action, computed, makeObservable, ObservableSet, toJS } from "mobx";
import { IModelElementsRepository } from "../../repositories/ModelElementsRepository/interface";
import { Bims } from "../Bims/Bims";

export class SelectedSections {
  constructor(private props: {
    bims: Bims
    modelElementsRepository: IModelElementsRepository
  }) {
    this.props = props

    makeObservable(this)
  }

  private selectedSections = new ObservableSet<string>()

  @computed
  get bims() {
    return this.props.bims.selectedBims
  }

  @computed
  get selectedSectionsSize() {
    return this.selectedSections.size
  }

  @computed
  get selectedSectionsArray() {
    return Array.from(this.selectedSections)
  }

  @computed
  get sections() {
    const sections = new Map<string, { id: string, name: string, number: number }>()

    for (const bim of this.bims) {
      for (const forgeElement of this.props.modelElementsRepository.getByBim(bim)) {
        const section = forgeElement.section
        if (section) {
          sections.set(section, {
            name: section,
            number: Number(forgeElement.section),
            id: section
          })
        }
      }
    }
    const array = Array.from(sections.values())

    array.sort((a, b) => a.number - b.number)

    return array
  }

  getSectionSelected(section: string) {
    return this.selectedSections.has(section)
  }

  @action
  resetSelectedSection() {
    return this.selectedSections.clear()
  }

  @action
  switchSelectedSection(section: string) {
    this.selectedSections.has(section) ? this.selectedSections.delete(section) : this.selectedSections.add(section)
  }

  @action
  setSelectedSections(values: string[]) {
    this.selectedSections.replace(values)
  }
}