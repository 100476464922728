import { isAfter } from "date-fns"
import { computed, makeObservable } from "mobx"
import { Bim } from "../../models/Bim"
import { IModelElement } from "../../models/ModelElement/interface"
import { EAdditionalStatusDictionaryCodes } from "../../models/StatusDictionary"
import { IBimElementsRepository } from "../../repositories/BimElementsRepository/interface"
import { IBimStatusRepository } from "../../repositories/BimStatusRepository/interface"
import { IStatusHistoryRepository } from "../../repositories/StatusHistoryRepository/interface"
import { Bims } from "../Bims/Bims"
import { Elements } from "../Elements/Elements"
import { PlanningMode } from "../PlanningMode/PlanningMode"
import { Remarks } from "../Remarks/Remarks"
import { SelectedElements } from "../SelectedElements/SelectedElements"
import { SelectedSections } from "../SelectedSections/SelectedSections"
import { SelectedStatuses } from "../SelectedStatuses/SelectedStatuses"
import { SelectedStoreys } from "../SelectedStoreys/SelectedStoreys"
import { Settings } from "../Settings/Settings"
import { Statuses } from "../Statuses/Statuses"
import { TermsAndVolumes } from "../TermsAndVolumes/TermsAndVolumes"
import { WorkTypes } from "../WorkTypes/WorkTypes"

export class HidedElements {
  constructor(private props: {
    termsAndVolumes: TermsAndVolumes,
    selectedStatuses: SelectedStatuses,
    selectedStoreys: SelectedStoreys,
    selectedSections: SelectedSections,
    remarks: Remarks
    selectedElements: SelectedElements
    bims: Bims

    bimElementsRepository: IBimElementsRepository
    bimStatusRepository: IBimStatusRepository
    statusHistoryRepository: IStatusHistoryRepository
    workTypes: WorkTypes
    elements: Elements

    planningMode: PlanningMode
    settings: Settings

    statuses: Statuses
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  private get filterByDefect() {
    return this.props.selectedStatuses.hasStatuses([EAdditionalStatusDictionaryCodes.defective])
  }

  @computed
  private get filterByNotStarted() {
    return this.props.selectedStatuses.hasStatuses([EAdditionalStatusDictionaryCodes.notStarted])
  }

  @computed
  private get filterByStoreys() {
    return this.props.selectedStoreys.selectedStoreysSize > 0
  }

  @computed
  private get filterBySections() {
    return this.props.selectedSections.selectedSectionsSize > 0
  }

  @computed
  private get filterByStatuses() {
    return this.props.selectedStatuses.selectedStatusesLenght > 0
  }

  @computed
  private get filterByDeadline() {
    return this.props.termsAndVolumes.planningDateViewModel.value
  }

  @computed
  private get filterBySelectedWorkTypes() {
    return this.props.workTypes.selectedWorkTypes.size > 0
  }

  private get filterByOverdueDate() {
    return this.props.planningMode.expiredDateViewModel.value
  }

  private getForgeElementIsOverdueDate(forgeElement: IModelElement) {
    const bimElement = this.props.selectedElements.getBimElements([forgeElement])[0]
    if (!bimElement) return false

    const plannedStatus = this.props.bimStatusRepository.getActualByBimElement(bimElement)
    if (!plannedStatus) return false

    if (isAfter(plannedStatus.deadline, new Date())) return false

    if (this.props.statuses.getBimElementCompleted(bimElement)) return false

    return true
  }

  @computed
  private get hideNodes() {
    return this.props.bims.modelDataRepository?.get()?.hideNodes || {}
  }

  @computed
  private get hidedNodesForBim() {
    const result = {}

    for (const bimId in this.hideNodes) {
      result[bimId] = new Set(this.hideNodes[bimId])
    }

    return result
  }

  getElementHided(bim: Bim, modelElement: IModelElement): boolean {
    if (this.hidedNodesForBim[bim.id]?.has(modelElement.id)) return true

    if (this.filterByStoreys) {
      const storey = modelElement.storey || ""
      if (!this.props.selectedStoreys.getStoreySelected(storey)) return true
    }

    if (this.filterBySections) {
      const section = modelElement.section || ""
      if (!this.props.selectedSections.getSectionSelected(section)) return true
    }

    if (this.filterByDeadline) {
      const bimElement = this.props.bimElementsRepository.getByGuid(bim.id, modelElement.id)
      const bimStatus = bimElement ? this.props.bimStatusRepository.getActualByBimElement(bimElement) : undefined
      if (bimStatus && new Date(bimStatus.props?.deadline) > new Date(this.filterByDeadline)) return true
      if (!bimStatus) return true
    }

    if (this.props.settings.hideAdditionalModels.value) {
      const selectedModelWorkTypeId = this.props.bims.selectedModelWorkTypeId
      if (bim.modelWorkType !== selectedModelWorkTypeId) return true
    }

    if (this.filterByStatuses) {
      const bimElement = this.props.bimElementsRepository.getByGuid(bim.id, modelElement.id)

      // если фильтруем по дефектам
      if (this.filterByDefect) {
        const activeRemarks = bimElement ? this.props.remarks.getNotClosedRemarksForBimElements([bimElement]) : []
        // убираем элементы без дефектов
        if (activeRemarks.length === 0) return true

        // оставляем остальные элементы,если фильтруем только по дефектам 
        if (this.props.selectedStatuses.selectedStatusesLenght === 1) return false
      }

      const activeStatus = bimElement ? this.props.statusHistoryRepository.getActualByBimElement(bimElement) : undefined

      // если фильтруем по "работы не начаты" и если элемент не имеет статуса,то он нам подходит
      if (this.filterByNotStarted && !activeStatus) return false

      // если элемент не имеет статуса,то он нам не подходит
      if (!activeStatus) return true

      // проверяем элементы на наличие выбранного статуса
      if (this.props.selectedStatuses.hasStatuses([activeStatus.status])) return false

      // иначе он нам не подходит
      return true
    }

    if (this.filterByOverdueDate) {
      if (!this.getForgeElementIsOverdueDate(modelElement)) return true
    }

    if (this.filterBySelectedWorkTypes) {
      const workTypes = this.props.elements.getWorkTypesByForgeElement(modelElement)
      if (workTypes.size === 0) return true
      const has = Array.from(workTypes).some(workType => this.props.workTypes.selectedWorkTypes.has(workType.id))
      if (!has) return true
    }

    return false
  }
}