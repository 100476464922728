import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { CollectionsBoardViewModel } from "../CollectionsBoardViewModel/CollectionsBoardViewModel";
import { StatusWrapperForPromiseViewModel } from "../StatusWrapperViewModel/StatusWrapperForPromiseViewModel";
import { ICollectionsMultiBoardViewModel } from "./interface";

export class CollectionsMultiBoardViewModel implements ICollectionsMultiBoardViewModel {
  constructor(private props: {
    layers: ILayers
  }) {
    this.props = props

    this.statusWrapperViewModel = new StatusWrapperForPromiseViewModel({
      promise: this.props.layers.repositories.modelWorkTypeRepository.getAllAsync()
    })

    makeObservable(this)
  }

  statusWrapperViewModel

  @computed
  get selectedProject() {
    return this.props.layers.usecases.viewState.props.selectedProjectId
  }

  @computed
  get modelWorkTypes() {
    return this.props.layers.repositories.modelWorkTypeRepository.getAll()
  }

  @computed
  get collectionsBoards() {
    return this.modelWorkTypes.map(modelWorkType => ({
      key: String(modelWorkType.id),
      viewModel: new CollectionsBoardViewModel({
        ...this.props,
        modelWorkType
      })
    }))
  }
}