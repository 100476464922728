import React, { memo, useReducer } from "react";
import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { useState } from "react";
import { useCallback } from "react";
import clsx from "clsx";
import { useRef } from "react";
import { useEffect } from "react";

const useClasses = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: ({ orientation }) => orientation === "right" ? "row" : "column"
    },
    selector: {
        flex: 1,
        maxWidth: "100%",
        display: "flex",
        lineHeight: "44px",
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",

        "&:hover": {
            backgroundColor: "#f5f5f5"
        }
    },
    padding: {
        padding: "0 15px",
    },
    menuWrapper: {
        zIndex: 2,
        position: "fixed",
        overflow: "hidden",
        pointerEvents: "none",
        transform: ({ orientation }) => orientation === "right" ? "translateY(-10px)" : "none",
    },
    menu: {
        backgroundColor: "white",

        pointerEvents: "auto",
        transition: "transform 0.4s ease",
        boxShadow: ({ orientation }) => orientation === "right" ? "inset 10px 0 20px -10px rgba(0, 0, 0, 0.05)" : "inset 0px 0px 7px 0px rgba(0, 0, 0, 0.05)",
        borderRadius: ({ orientation }) => orientation === "right" ? "0px 10px 10px 0px" : "0px 0px 10px 10px",
        overflow: "hidden",
        transform: ({ orientation }) => orientation === "right" ? "translateX(calc(-100% - 1px))" : "translateY(calc(-100% - 1px))",
    },
    menuOpen: {
        transform: ({ orientation }) => orientation === "right" ? "translateX(0)" : "translateY(0)",
    },
    options: {
        userSelect: "none",
        overflow: "auto"
    },
    menuFloat: {
        display: "flex",
        justifyContent: ({ float }) => float === "left" ? "start" : "flex-end",
    }
}))

function MenuSelector(props) {
    const rootRef = useRef();
    const [menuRender, setMenuRender] = useState(false);
    const [menuOpen, setMenuOpen] = useReducer((state, action) => {
        if (action) {
            setMenuRender(true);
        }
        return action;
    }, false);

    const {
        label,
        options,
        float = "left",
        orientation = "bottom",
    } = props;

    const [calculatedOrientation, setCalculatedOrientation] = useState();
    const closeMenu = useCallback(() => setMenuOpen(false), []);

    // pre orientation
    const preparedOrientation = useMemo(() => {
        if (orientation === "auto") {
            if (rootRef.current) {
                const allowedWidth = window.innerWidth - rootRef.current.getBoundingClientRect().right;
                if (allowedWidth < 100) {
                    return "bottom"
                } else {
                    return "right"
                }
            }
            return "right"
        } else {
            return orientation;
        }
    }, [menuOpen]);

    // post orientation
    useEffect(() => {
        if (menuOpen && orientation === "auto") {
            const allowedWidth = window.innerWidth - rootRef.current.getBoundingClientRect().right;
            if (allowedWidth < 100) {
                setCalculatedOrientation("bottom")
            } else {
                setCalculatedOrientation("right")
            }
        }
    }, []);

    const classes = useClasses({
        classes: props.classes,
        float,
        orientation: calculatedOrientation || preparedOrientation
    });

    const stopPropagation = useCallback(e => e.stopPropagation(), []);
    const switchMenu = useCallback(e => {
        stopPropagation(e)
        setMenuOpen(!menuOpen)
    }, [menuOpen]);

    const menuWrapperStyle = useMemo(() => {
        if (rootRef.current) {
            const bc = rootRef.current.getBoundingClientRect();

            return {
                left: bc.right,
                maxHeight: window.innerHeight - bc.top
            }
        } else {
            return null;
        }
    }, [menuOpen]);

    const optionsStyle = useMemo(() => {
        if (!menuWrapperStyle) return null;
        return {
            maxHeight: menuWrapperStyle.maxHeight
        }
    }, [menuWrapperStyle]);

    useEffect(() => {
        if (menuOpen) {
            function closeMenuHandler() {
                setMenuOpen(false);
            }
            window.addEventListener("click", closeMenuHandler);

            return () => {
                window.removeEventListener("click", closeMenuHandler);
            }
        }
    }, [menuOpen]);

    return (
        <div className={classes.root} ref={rootRef}>
            <div className={clsx(classes.selector, classes.padding)} onClick={switchMenu}>
                {label}
            </div>
            <div className={classes.menuFloat} onClick={stopPropagation}>
                <div className={classes.menuWrapper}>
                    <div
                        className={clsx(classes.menu, {
                            [classes.menuOpen]: menuOpen
                        })}
                    >
                        {
                            menuRender ? (
                                <div className={classes.options} style={optionsStyle}>
                                    {
                                        options.map(({ render }) => (
                                            render({ closeMenu })
                                        ))
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(MenuSelector);