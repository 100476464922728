import React, { useMemo } from 'react'

import {
  makeStyles,
  withStyles,
  Button,
  Card,
  CardActions,
  Typography,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Dialog,
} from '@material-ui/core';
import {
  ChevronLeft
} from '@material-ui/icons';

import clsx from 'clsx';

import { observer } from 'mobx-react-lite';
import { ITargetsPageViewModel } from '../../view-model/TargetsPageViewModel/interface';
import { EHomeSectionCodes } from '../../models/HomeSectionCodes';
import { TargetsList } from '../TargetsList/TargetsList';
import { RemarksTable } from '../RemarksTable/RemarksTable';
import { PrescriptionsTable } from '../PrescriptionsTable/PrescriptionsTable';
import FilterCard from "../../components/FilterCard/FilterCard";
import { BimTableWithModelWorkType } from '../BimTableWithModelWorkType/BimTableWithModelWorkType';
import { CollectionsMultiBoard } from '../CollectionsMultiBoard/CollectionsMultiBoard';
import { SimpleButton } from '../SimpleButton/SimpleButton';
import { DatePicker } from '../DatePicker/DatePicker';
import { ProjectStatistics } from '../ProjectStatistics/ProjectStatistics';

const Tabs = withStyles(theme => ({
  root: {
    minHeight: 30,
    borderRadius: 8,
    padding: 2,
    //@ts-ignore
    background: theme.palette.primaryColorBgc,
  },
  indicator: {
    height: 0,
  }
}))(MuiTabs)

const Tab = withStyles(theme => ({
  root: {
    minWidth: 312 / 3,
    minHeight: 30,
    borderRadius: 8,
    //@ts-ignore
    color: `${theme.palette.primaryRedesignColor3} !important`,
    fontWeight: 'bold',
    fontSize: 12,
    opacity: 1,
    lineHeight: 0,
  },
  selected: {
    //@ts-ignore
    backgroundColor: theme.palette.primaryColor
  }
}))(MuiTab)


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    margin: "auto",
    maxWidth: 984,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    width: '100%',
    margin: '0 auto',
  },

  navigation: {
    // width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 54,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonExit: {
    height: 54,
    minWidth: 54,
    width: 54,
    //@ts-ignore
    background: theme.palette.primaryRedesignColor6,
    borderRadius: 14,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
    "&:hover": {
      //@ts-ignore
      background: theme.palette.primaryRedesignColor6,
      //@ts-ignore
      borderColor: theme.palette.primaryRedesignColor6,
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
    }
  },
  iconExit: {
    fontSize: 37,
    //@ts-ignore
    fill: theme.palette.primaryColor
  },
  headerContainer: {
    marginLeft: 20,
  },
  projectName: {
    fontSize: 20,
    fontWeight: "bold",
    //@ts-ignore
    color: theme.palette.primaryRedesignColor3,
  },
  tabs: {
    textTransform: 'none',
  },
  cards: {
    display: "flex",
    alignItems: "canter",
  },
  card: {
    borderRadius: 14,
    boxShadow: " 0px 4px 20px rgba(0, 0, 0, 0.05)"
  },
  content: {
    // marginTop: 10,
    height: "100%",
    overflowY: 'auto'
  },
  containerNavigation: {
    marginTop: 20,
    marginBottom: 5,
    padding: 0,
  },
  headerLabelContainer: {
    display: "flex",
    alignItems: "center"
  },
  statisticButton: {
    marginLeft: 10,
    backgroundColor: "white",

    height: 24,
    width: 24,
    padding: 0
  },
  projectStatistics: {
    padding: 40
  }
}))

const useStylesDesktop = makeStyles(theme => ({
  root: {
    maxWidth: "none"
  },
  projectName: {
    fontSize: 24
  },
}))

const useClassesCollectionsBoard = makeStyles(theme => ({
  root: {
    paddingTop: 10
  },
}))

interface ITargetsPageProps {
  mobile: boolean,

  viewModel: ITargetsPageViewModel
}

const statisticIcon = <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_2683_4184)">
    <path d="M6.78393 1.48958H8.87535C8.93019 1.48958 8.98278 1.51187 9.02155 1.55156C9.06033 1.59124 9.08212 1.64505 9.08212 1.70117V10.1097C9.08212 10.1658 9.06033 10.2196 9.02155 10.2593C8.98278 10.299 8.93019 10.3213 8.87535 10.3213H6.78393C6.72909 10.3213 6.6765 10.299 6.63773 10.2593C6.59895 10.2196 6.57717 10.1658 6.57717 10.1097V1.69271C6.57717 1.63659 6.59895 1.58277 6.63773 1.54309C6.6765 1.50341 6.72909 1.48112 6.78393 1.48112V1.48958ZM14 13H1.02658C0.892054 13.0005 0.758738 12.9739 0.634279 12.9217C0.509819 12.8694 0.396663 12.7925 0.301295 12.6954C0.205927 12.5983 0.130222 12.4829 0.0785202 12.3558C0.0268181 12.2287 0.000134927 12.0924 0 11.9547H0V0H1.31192V11.6575H14V13ZM10.6535 5.69173H12.7449C12.7998 5.69173 12.8524 5.71402 12.8911 5.7537C12.9299 5.79338 12.9517 5.8472 12.9517 5.90332V10.1097C12.9517 10.1658 12.9299 10.2196 12.8911 10.2593C12.8524 10.299 12.7998 10.3213 12.7449 10.3213H10.6535C10.5987 10.3213 10.5461 10.299 10.5073 10.2593C10.4685 10.2196 10.4468 10.1658 10.4468 10.1097V5.89803C10.4468 5.84191 10.4685 5.78809 10.5073 5.74841C10.5461 5.70873 10.5987 5.68644 10.6535 5.68644V5.69173ZM2.91434 3.11458H5.00576C5.05881 3.11592 5.10933 3.13809 5.14686 3.17649C5.18438 3.21489 5.20604 3.26659 5.20736 3.32088V10.1097C5.20737 10.1649 5.1863 10.2179 5.14863 10.2575C5.11097 10.297 5.0597 10.3199 5.00576 10.3213H2.91434C2.8595 10.3213 2.80691 10.299 2.76814 10.2593C2.72936 10.2196 2.70758 10.1658 2.70758 10.1097V3.32088C2.70893 3.26568 2.7313 3.21322 2.76993 3.17467C2.80856 3.13613 2.86039 3.11456 2.91434 3.11458Z" fill="black" />
  </g>
  <defs>
    <clipPath id="clip0_2683_4184">
      <rect width="14" height="13" fill="white" />
    </clipPath>
  </defs>
</svg>


export const TargetsPage = observer((props: ITargetsPageProps) => {
  const {
    mobile
  } = props;
  const classesDesktop = useStylesDesktop();
  const classes = useStyles(mobile ? undefined : { classes: classesDesktop });
  const classesCollectionsBoard = useClassesCollectionsBoard()

  const selectedSection = props.viewModel.selectedSection

  const render = useMemo(() => {
    return (targetId: number) => {
      switch (selectedSection) {
        case EHomeSectionCodes.remarks:
          {
            const viewModel = props.viewModel.getRemarksTableViewModel(targetId)
            return (
              <RemarksTable
                viewModel={viewModel}
              />
            )
          }
        case EHomeSectionCodes.files:
          {
            const viewModel = props.viewModel.getBimTableWithModelWorkTypeViewModel(targetId)
            return (
              <BimTableWithModelWorkType
                viewModel={viewModel}
              />
            )
          }
        case EHomeSectionCodes.prescriptions:
          {
            const viewModel = props.viewModel.getPrescriptionsTableViewModel(targetId)
            return (
              <PrescriptionsTable
                viewModel={viewModel}
              />
            )
          }
        default:
          return null
      }
    }
  }, [selectedSection, mobile])

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.navigation, classes.container, classes.containerNavigation)}>
        <div className={classes.button}>
          <Button
            variant="contained"
            onClick={() => props.viewModel.onClose()}
            className={classes.buttonExit}
            disableTouchRipple
          >
            <ChevronLeft className={classes.iconExit} />
          </Button>
          <div className={classes.headerContainer}>
            <div className={classes.headerLabelContainer}>
              <Typography variant="h5" component="h5" className={classes.projectName}>
                {props.viewModel.projectName}
              </Typography>
              {
                props.viewModel.statisticButtonViewModel ?
                  <SimpleButton className={classes.statisticButton} viewModel={props.viewModel.statisticButtonViewModel}>
                    {statisticIcon}
                  </SimpleButton>
                  : null
              }
              {props.viewModel.datePickerViewModel ? <DatePicker viewModel={props.viewModel.datePickerViewModel} /> : null}
              {props.viewModel.statisticDialogViewModel ? <Dialog open={props.viewModel.statisticDialogViewModel.open} onClose={props.viewModel.statisticDialogViewModel.onClose}>
                {props.viewModel.projectStatisticsViewModel ? <ProjectStatistics className={classes.projectStatistics} viewModel={props.viewModel.projectStatisticsViewModel} /> : null}
              </Dialog> : null}
            </div>
            <div>{props.viewModel.additionalHeaderContent}</div>
          </div>
        </div>
        <div className={classes.cards}>
          {selectedSection === EHomeSectionCodes.remarks &&
            <FilterCard
              filterCount={props.viewModel.filterCount}
              setFilterPanel={props.viewModel.onCloseFilter}
              сlearFilterRemarks={() => props.viewModel.сlearFilterRemarks()}
              isOpenFilterPanel={props.viewModel.isOpenFilterPanel}
            />}
          <Card className={classes.card}>
            <CardActions>
              <Tabs
                value={selectedSection}
                onChange={(e, value) => props.viewModel.onChangeSelectedSection(value)}
              >
                {
                  props.viewModel.sections.map((section, index) => (
                    <Tab key={index} label={section.name} className={classes.tabs} value={section.code} />
                  ))
                }
              </Tabs>
            </CardActions>
          </Card>
        </div>
      </div>
      <div className={clsx(classes.content)}>
        {
          selectedSection === EHomeSectionCodes.collections && props.viewModel.selectedProjectId ?
            <CollectionsMultiBoard
              classes={classesCollectionsBoard}
              viewModel={props.viewModel.collectionsMultiBoardViewModel}
            />
            : (
              <TargetsList
                viewModel={props.viewModel.targetsListViewModel}
                render={render}
              />
            )
        }
      </div>
    </div>
  )
})