import { computed, makeObservable, observable, ObservableSet } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { TUploadFileProps, UploadFile } from "../../models/UploadFile";
import { IUploadFilesListViewModel } from "./interface";
import { saveAs } from "file-saver";
import { format } from "date-fns";

export class UploadFilesListViewModel implements IUploadFilesListViewModel {
  constructor(private props: {
    layers: ILayers,
    ids: ObservableSet<TUploadFileProps["id"]>,
    disableDelete?: boolean
    disableDeleteNotMyFiles?: boolean
    getAllowDelete?(file: UploadFile): boolean
  }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  private modalOpened = false
  @observable
  private startIndex = 0

  @computed
  get uploadFiles() {
    const files: UploadFile[] = []

    for (const id of this.props.ids) {
      const file = this.props.layers.repositories.uploadFilesRepository.getById(id)
      if (file) {
        files.push(file)
      }
    }

    return files
  }

  getAllowDelete(file: UploadFile) {
    if (this.props.disableDelete) return false
    if (this.props.disableDeleteNotMyFiles && (file.user !== this.props.layers.repositories.authRepository.getUser()?.id)) return false

    return this.props.getAllowDelete ? this.props.getAllowDelete(file) : true
  }

  @computed
  get files() {
    return this.uploadFiles.map((file, index) => ({
      key: String(file.id),
      name: String(file.name),
      author: this.props.layers.repositories.usersRepository.getById(file.user)?.fullName || "?",
      date: format(file.uploadedAt, "dd.MM.yyyy"),
      allowDelete: this.getAllowDelete(file),
      onOpen: () => {
        this.startIndex = index
        this.modalOpened = true
      },
      onDelete: () => this.onDelete(file),
      onDownload: () => saveAs(file.file, file.name)
    }))
  }

  async onDelete(file: UploadFile) {
    await this.props.layers.repositories.uploadFilesRepository.delete(file.id)
    this.props.ids.delete(file.id)
  }

  onClose() {

  }

  @computed
  private get views() {
    return this.uploadFiles.map(file => ({
      caption: file.name,
      source: file.file,
    }))
  }

  @computed
  get modalGalleryViewModel() {
    return {
      opened: this.modalOpened,
      onClose: () => this.modalOpened = false,
      views: this.views,
      startIndex: this.startIndex
    }
  }
}