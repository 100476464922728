import { IAuthRepository } from "../../repositories/AuthRepository/interface";
import { RestBimsRepository } from "../../repositories/BimsRepository/RestBimsRepository";
import { RestCollectionsRepository } from "../../repositories/CollectionsRepository/RestCollectionsRepository";
import { RestPrescriptionRepository } from "../../repositories/PrescriptionRepository/RestPrescriptionRepository";
import { RestProjectRepository } from "../../repositories/ProjectsRepository/RestProjectRepository";
import { RestRemarkBaseRepository } from "../../repositories/RemarkBaseRepository/RestRemarkBaseRepository";
import { RestRemarksRepository } from "../../repositories/RemarksRepository/RestRemarksRepository";
import { RestTargetsRepository } from "../../repositories/TargetsRepository/RestTargetsRepository";
import { RestCompaniesRepository } from "../../repositories/CompaniesRepository/RestCompaniesRepository";
import { Bims } from "../../usecase/Bims/Bims";
import { Collections } from "../../usecase/Collections/Collections";
import { Prescriptions } from "../../usecase/Prescriptions/Prescriptions";
import { Remarks } from "../../usecase/Remarks/Remarks";
import { Route } from "../../usecase/Route/Route";
import { Targets } from "../../usecase/Targets/Targets";
import { ViewState } from "../../usecase/ViewState/ViewState";
import { ILayers } from "./interface";
import { Auth } from "../../usecase/Auth/Auth";
import { FiltredRemarksTable } from "../../usecase/FiltredRemarksTable/FiltredRemarksTable";
import { FiltredPrescriptionsTable } from "../../usecase/FiltredPrescriptionsTable/FiltredPrescriptionsTable";
import { SelectedElements } from "../../usecase/SelectedElements/SelectedElements";
import { RemoteForgeTokenRepository } from "../../repositories/ForgeTokenRepository/RemoteForgeTokenRepository";
import { ProxyForgeElementsRepository } from "../../repositories/ForgeElementsRepository/ProxyForgeElementsRepository";
import { ForgeRepository } from "../../repositories/ForgeRepository/ForgeRepository";
import { RestConstructionGroupsRepository } from "../../repositories/ConstructionGroupsRepository/RestConstructionGroupsRepository";
import { RestTargetNoteRepository } from "../../repositories/TargetNoteRepository/RestTargetNoteRepository";
import { Elements } from "../../usecase/Elements/Elements";
import { Statuses } from "../../usecase/Statuses/Statuses";
import { RestStatusHistoryRepository } from "../../repositories/StatusHistoryRepository/RestStatusHistoryRepository";
import { RestStatusDictionaryRepository } from "../../repositories/StatusDictionaryRepository/RestStatusDictionaryRepository";
import { RestBimElementsRepository } from "../../repositories/BimElementsRepository/RestBimElementsRepository";
import { RestWorkTypesRepository } from "../../repositories/WorkTypesRepository/RestWorkTypesRepository";
import { SelectedStatuses } from "../../usecase/SelectedStatuses/SelectedStatuses";
import { SelectedStoreys } from "../../usecase/SelectedStoreys/SelectedStoreys";
import { PDFGenerator } from "../../repositories/PDFGenerator/PDFGenerator";
import { Permissions } from "../../usecase/Permissions/Permissions";
import { RestUploadFilesRepository } from "../../repositories/UploadFilesRepository/RestUploadFilesRepository";
import { RestUsersRepository } from "../../repositories/UsersRepository/RestUsersRepository";
import { ForgeElements } from "../../usecase/ForgeElements/ForgeElements";
import { FilterPanel } from "../../usecase/FilterPanel/FilterPanel";
import { SelectedSections } from "../../usecase/SelectedSections/SelectedSections";
import { TermsAndVolumes } from "../../usecase/TermsAndVolumes/TermsAndVolumes";
import { PlanningMode } from "../../usecase/PlanningMode/PlanningMode";
import { RestBimStatusRepository } from "../../repositories/BimStatusRepository/RestBimStatusRepository";
import { Notifications } from "../../usecase/Notifications/Notifications";
import { StoreyNotes } from "../../usecase/StoreyNotes/StoreyNotes";
import { RestModelWorkTypeRepository } from "../../repositories/ModelWorkTypeRepository/RestModelWorkTypeRepository";
import { RestMaterialsRepository } from "../../repositories/MaterialsRepository/RestMaterialsRepository";
import { Statistics } from "../../usecase/Statistics/Statistics";
import { RestWorkTypeGroupRepository } from "../../repositories/WorkTypeGroupRepository/RestWorkTypeGroupRepository";
import { RestStatsRepository } from "../../repositories/StatsRepository/RestStatsRepository";
import { Settings } from "../../usecase/Settings/Settings";
import { WorkTypes } from "../../usecase/WorkTypes/WorkTypes";
import { ServiceWorker } from "../../usecase/ServiceWorker/ServiceWorker";
import { openDB } from "idb";
import { ICollectionsRepository } from "../../repositories/CollectionsRepository/interface";
import { IOffline } from "../../usecase/Offline/interface";
import { Offline } from "../../usecase/Offline/Offline";
import { IBimElementsRepository } from "../../repositories/BimElementsRepository/interface";
import { IStatusHistoryRepository } from "../../repositories/StatusHistoryRepository/interface";
import { IRemarksRepository } from "../../repositories/RemarksRepository/interface";
import { IUploadFilesRepository } from "../../repositories/UploadFilesRepository/interface";
import { IPrescriptionRepository } from "../../repositories/PrescriptionRepository/interface";
import { ITargetNoteRepository } from "../../repositories/TargetNoteRepository/interface";
import { PaintElements } from "../../usecase/PaintElements/PaintElements";
import { Device } from "../../usecase/Device/Device";
import { Companies } from "../../usecase/Companies/Companies";
import { RestActionsRepository } from "../../repositories/ActionsRepository/RestActionsRepository";
import { PDMAuthRepository } from "../../repositories/PDMAuthRepository/PDMAuthRepository";
import { RestBundleRepository } from "../../repositories/BundleRepository/RestBundleRepository";
import { ForgeAndBimApiModelElementsRepository } from "../../repositories/ModelElementsRepository/ForgeAndBimApiModelElementsRepository";
import { BimApiElementsRepository } from "../../repositories/BimApiElementsRepository/BimApiElementsRepository";
import { UI } from "../../usecase/UI/UI";
import { HidedElements } from "../../usecase/HidedElements/HidedElements";
import { StatisticsRepository } from "../../repositories/StatisticsRepository/StatisticsRepository";

export async function createApiLayers(props: {
  host: string
  pdmHost: string
  authRepository: IAuthRepository
  serviceWorkerPath: string
  components: ILayers["components"]
}): Promise<ApiLayers> {
  const {
    host,
    authRepository
  } = props

  const patchesTables = {
    bimGroup: "bim-group",
    bimElement: "bim-element",
    statusHistory: "status-history",
    remark: "remark",
    uploadFiles: "upload-files",
    prescription: "prescription",
    targetNote: "target-note"
  }

  const patchesDb = await openDB("patches", 1, {
    upgrade(db) {
      for (const name of Object.values(patchesTables)) {
        db.createObjectStore(name)
      }
    }
  })

  const collectionsRepository = new RestCollectionsRepository({
    host: props.host,
    authRepository: props.authRepository,
    patchesTableName: patchesTables.bimGroup,
    db: patchesDb
  })

  const bimElementsRepository = new RestBimElementsRepository({
    host,
    authRepository,
    db: patchesDb,
    patchesTableName: patchesTables.bimElement,
  })

  const statusHistoryRepository = new RestStatusHistoryRepository({
    host,
    authRepository,
    db: patchesDb,
    patchesTableName: patchesTables.statusHistory,
  })

  const remarksRepository = new RestRemarksRepository({
    host,
    authRepository,
    db: patchesDb,
    patchesTableName: patchesTables.remark,
  })

  const uploadFilesRepository = new RestUploadFilesRepository({
    path: `${host}api/main/uploads/`,
    authRepository: authRepository,
    db: patchesDb,
    patchesTableName: patchesTables.uploadFiles,
  })

  const prescriptionsRepository = new RestPrescriptionRepository({
    host,
    authRepository,
    db: patchesDb,
    patchesTableName: patchesTables.prescription,
  })

  const targetNoteRepository = new RestTargetNoteRepository({
    host,
    authRepository,
    db: patchesDb,
    patchesTableName: patchesTables.targetNote,
  })

  const offline = new Offline({
    collectionsRepository,
    bimElementsRepository,
    statusHistoryRepository,
    remarksRepository,
    uploadFilesRepository,
    prescriptionsRepository,
    targetNoteRepository
  })

  return new ApiLayers({
    ...props,
    collectionsRepository,
    bimElementsRepository,
    statusHistoryRepository,
    remarksRepository,
    uploadFilesRepository,
    prescriptionsRepository,
    targetNoteRepository,
    components: props.components,
    offline
  })
}
export class ApiLayers implements ILayers {
  usecases: ILayers["usecases"]
  repositories: ILayers["repositories"]
  components: ILayers["components"]

  constructor(props:
    {
      host: string
      pdmHost: string
      authRepository: IAuthRepository
      serviceWorkerPath: string
      collectionsRepository: ICollectionsRepository
      bimElementsRepository: IBimElementsRepository
      statusHistoryRepository: IStatusHistoryRepository
      remarksRepository: IRemarksRepository
      uploadFilesRepository: IUploadFilesRepository
      prescriptionsRepository: IPrescriptionRepository
      targetNoteRepository: ITargetNoteRepository
      components: ILayers["components"]
      offline: IOffline
    }
  ) {
    const {
      host,
      authRepository,
      offline,
      statusHistoryRepository,
      collectionsRepository,
      bimElementsRepository,
      remarksRepository,
      uploadFilesRepository,
      prescriptionsRepository,
      targetNoteRepository
    } = props

    this.components = props.components
    const notifications = new Notifications({})

    const usersRepository = new RestUsersRepository({
      path: `${host}api/core/user/`,
      authRepository
    })

    const targetsRepository = new RestTargetsRepository({
      host,
      authRepository
    })

    const bimsRepository = new RestBimsRepository({
      host,
      authRepository,
    })

    const projectsRepository = new RestProjectRepository({
      host,
      authRepository
    })

    const remarksBaseRepository = new RestRemarkBaseRepository({
      host,
      authRepository
    })

    const companiesRepository = new RestCompaniesRepository({
      host,
      authRepository
    })

    const constructionGroupsRepository = new RestConstructionGroupsRepository({
      host,
      authRepository
    })

    const forgeTokenRepository = new RemoteForgeTokenRepository({
      host
    })

    const forgeRepository = new ForgeRepository({
      forgeTokenRepository,
      notifications
    })

    const forgeElementsRepository = new ProxyForgeElementsRepository({
      forgeRepository
    })

    const statusDictionaryRepository = new RestStatusDictionaryRepository({
      host,
      authRepository
    })

    const termsAndVolumes = new TermsAndVolumes()

    const workTypesRepository = new RestWorkTypesRepository({
      host,
      authRepository
    })

    const materialsRepository = new RestMaterialsRepository({
      host,
      authRepository
    })

    const pdfGenerator = new PDFGenerator({
      authRepository,
      host: `${host}api/main/bim-prescription/gen_pdf/`,
    })

    const bimStatusRepository = new RestBimStatusRepository({
      host,
      authRepository,
    })

    const modelWorkTypeRepository = new RestModelWorkTypeRepository({
      authRepository,
      host
    })

    const workTypeGroupRepository = new RestWorkTypeGroupRepository({
      authRepository,
      host
    })

    const statsRepository = new RestStatsRepository({
      host,
      authRepository
    })

    const actionsRepository = new RestActionsRepository({
      host,
      authRepository
    })

    const pdmAuthRepository = new PDMAuthRepository({
      authRepository,
      href: `${props.host}api/pdm-api-token/`
    })

    const bundleRepository = new RestBundleRepository({
      authRepository: pdmAuthRepository,
      host: `${props.pdmHost}documentations/api/v1/bundles/`,
      filesHost: `${props.pdmHost}files/api/v1/bundles/`
    })

    const bimApiRepository = new BimApiElementsRepository({
      authRepository: pdmAuthRepository,
      bundleRepository,
      host: `${props.pdmHost}bimv2/`
    })

    const modelElementsRepository = new ForgeAndBimApiModelElementsRepository({
      forgeElementsRepository,
      bimsRepository,
      bimApiRepository
    })

    const statisticsRepository = new StatisticsRepository({
      authRepository,
      host
    })

    this.repositories = {
      statisticsRepository,
      pdmAuthRepository,
      bundleRepository,
      actionsRepository,
      targetNoteRepository,
      uploadFilesRepository,
      targetsRepository,
      pdfGenerator,
      collectionsRepository,
      bimsRepository,
      authRepository,
      projectsRepository,
      remarksRepository,
      prescriptionsRepository,
      usersRepository,
      remarksBaseRepository,
      companiesRepository,
      forgeTokenRepository,
      modelElementsRepository,
      statusHistoryRepository,
      statusDictionaryRepository,
      constructionGroupsRepository,
      workTypesRepository,
      bimStatusRepository,
      bimElementsRepository,
      modelWorkTypeRepository,
      materialsRepository,
      workTypeGroupRepository,
      statsRepository
    }

    const ui = new UI()

    const permissions = new Permissions({
      authRepository,
      usersRepository
    })

    const viewState = new ViewState({
      permissions
    })
    const targets = new Targets({ targetsRepository, projectsRepository })
    const bims = new Bims({
      bimsRepository,
      targetsRepository,
      targets,
      viewState,
      collectionsRepository,
      projectsRepository,
      ui
    })
    const collections = new Collections({
      collectionsRepository,
      bims,
      bimsRepository
    })

    const elements = new Elements({
      bimElementsRepository,
      modelElementsRepository,
      bimsRepository,
      bims,
      constructionGroupsRepository,
      workTypesRepository,
      materialsRepository,
      workTypeGroupRepository,
      collections
    })

    const selectedElements = new SelectedElements({
      bimElementsRepository,
      bimsRepository,
      modelElementsRepository,
      workTypesRepository,
      elements,
      constructionGroupsRepository,
      targetsRepository
    })

    const statuses = new Statuses({
      selectedElements,
      statusDictionaryRepository,
      statusHistoryRepository,
      bimElementsRepository,
      elements,
      viewState,
      authRepository
    })

    const remarks = new Remarks({
      bims,
      companiesRepository,
      remarksRepository,
      viewState,
      statuses,
      remarksBaseRepository,
      bimElementsRepository,
      selectedElements,
      elements,
      statusDictionaryRepository,
      bimsRepository
    })

    const prescriptions = new Prescriptions({
      remarks,
      bims,
      remarksRepository,
      prescriptionsRepository,
      viewState,
      bimsRepository
    })

    const route = new Route({
      viewState,
      selectedElements,
      remarks
    })

    const filterPanel = new FilterPanel({
    })

    const filtredRemarksTable = new FiltredRemarksTable({
      remarksBaseRepository,
      viewState,
      remarks,
      companiesRepository,
      usersRepository,
      targetsRepository,
      filterPanel,
      permissions
    })

    const auth = new Auth({ repositories: this.repositories })

    const filtredPrescriptionsTable = new FiltredPrescriptionsTable({
      viewState,
      prescriptions,
      companiesRepository,
      usersRepository,
      targetsRepository,
      permissions
    })

    const selectedStatuses = new SelectedStatuses({
      statusDictionaryRepository,
      elements
    })

    const selectedStoreys = new SelectedStoreys({
      modelElementsRepository,
      bims
    })

    const selectedSections = new SelectedSections({
      modelElementsRepository,
      bims
    })

    const planningMode = new PlanningMode({
      bimStatusRepository,
      permissions
    })

    const storeyNotes = new StoreyNotes({
      targetNoteRepository,
      bims
    })

    const statistics = new Statistics({
      bimElementsRepository,
      remarks,
      remarksRepository,
      statuses,
      bimsRepository,
      modelElementsRepository
    })

    const settings = new Settings({})
    const workTypes = new WorkTypes({
      bims,
      elements,
      modelElementsRepository
    })

    const serviceWorker = new ServiceWorker({
      workerPath: props.serviceWorkerPath,
      notifications
    })

    const device = new Device()

    const companies = new Companies({
      bims,
      companiesRepository
    })

    const paintElements = new PaintElements({
      viewState,
      bimElementsRepository,
      bimStatusRepository,
      bimsRepository,
      planningMode,
      statusHistoryRepository,
      statuses,
      remarks,
      statusDictionaryRepository,
      ui
    })

    const hidedElements = new HidedElements({
      termsAndVolumes,
      workTypes,
      selectedElements,
      selectedSections,
      selectedStatuses,
      selectedStoreys,
      settings,
      statusHistoryRepository,
      statuses,
      bimElementsRepository,
      bimStatusRepository,
      bims,
      remarks,
      elements,
      planningMode
    })

    const forgeElements = new ForgeElements({
      remarks,
      modelElementsRepository,
      selectedElements,
      bims,
      bimsRepository,
      permissions,
      viewState,
      hidedElements
    })

    this.usecases = {
      hidedElements,
      ui,
      device,
      paintElements,
      companies,
      workTypes,
      forgeElements,
      permissions,
      viewState,
      bims,
      collections,
      offline,
      selectedStoreys,
      selectedSections,
      route,
      remarks,
      targets,
      prescriptions,
      auth,
      filtredRemarksTable,
      filtredPrescriptionsTable,
      selectedElements,
      statuses,
      elements,
      selectedStatuses,
      filterPanel,
      termsAndVolumes,
      planningMode,
      notifications,
      storeyNotes,
      statistics,
      settings,
      serviceWorker
    }
  }
}