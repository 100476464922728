import { Bundle, TBundleProps } from "../../models/Bundle/Bundle";
import { EBundleFileKeys } from "../../models/BundleFile";
import { IPDMAuthRepository } from "../PDMAuthRepository/interface";
import { ReadOnlyRestRepository } from "../Repository/ReadOnlyRestRepository";
import { IBundleRepository } from "./interface";

type TResponseProps = {
  id: string
  created_at: string
  document_id: number
  upload_status: string
  workflow_status: string
  bim_id: number
  date: string
  type: string
  size: number
  workflow: { id: string }
  data_sources: {
    id: string
    key: EBundleFileKeys
    type: string
    format: string | null
    size: number
    pages: null
    file_name: string
  }[]
  author: {
    id: number
    first_name: string
    last_name: string
    username: string,
    service_account_id: string
  }
  attributes: null
}

export class RestBundleRepository implements IBundleRepository {
  constructor(private props: { host: string, authRepository: IPDMAuthRepository, filesHost: string }) {
    this.readonlyRepository = new ReadOnlyRestRepository({
      authRepository: props.authRepository,
      path: props.host,
      Model: Bundle,
      serialyze: (responseProps: TResponseProps) => {
        return {
          id: responseProps.id,
          files: responseProps.data_sources.map(dataSource => ({
            id: dataSource.id,
            key: dataSource.key,
            url: `${props.filesHost}${responseProps.id}/${dataSource.key}`
          })),
          bimApiId: responseProps.bim_id
        }
      }
    })
  }

  rangeRequestsUsingQuery = true

  private readonlyRepository: ReadOnlyRestRepository<Bundle, TResponseProps>

  getById(id: TBundleProps["id"]) {
    return this.readonlyRepository.getById(id)
  }

  getByIdAsync(id: TBundleProps["id"]) {
    return this.readonlyRepository.getByIdAsync(id)
  }
}