import { Bim, EBimType } from "../../models/Bim";
import { BimElement } from "../../models/BimElement";
import { IModelElement } from "../../models/ModelElement/interface";
import { IBimApiRepository } from "../BimApiElementsRepository/interface";
import { IBimsRepository } from "../BimsRepository/interface";
import { IForgeElementsRepository } from "../ForgeElementsRepository/interface";
import { IModelElementsRepository } from "./interface";

export class ForgeAndBimApiModelElementsRepository implements IModelElementsRepository {
  constructor(private props: {
    forgeElementsRepository: IForgeElementsRepository
    bimApiRepository: IBimApiRepository
    bimsRepository: IBimsRepository
  }) {
    this.props = props
  }

  get bimApiRepository() {
    return this.props.bimApiRepository
  }

  getRepository(bim: Bim): IForgeElementsRepository | IBimApiRepository {
    if (bim.type === EBimType.forge) return this.props.forgeElementsRepository

    return this.props.bimApiRepository
  }

  getBimByBimElement(bimElement: BimElement) {
    return this.props.bimsRepository.getById(bimElement.bim)
  }

  getBimByBimElementAsync(bimElement: BimElement) {
    return this.props.bimsRepository.getByIdAsync(bimElement.bim)
  }

  getByBim(bim: Bim): IModelElement[] {
    return this.getRepository(bim).getByBim(bim)
  }

  getByBimAsync(bim: Bim): Promise<IModelElement[]> {
    return this.getRepository(bim).getByBimAsync(bim)
  }

  getById(bim: Bim, id: IModelElement["id"]): IModelElement | undefined {
    return this.getRepository(bim).getById(bim, id)
  }

  getByIdAsync(bim: Bim, id: IModelElement["id"]): Promise<IModelElement | undefined> {
    return this.getRepository(bim).getByIdAsync(bim, id)
  }

  getByModelID(bim: Bim, modelID: IModelElement["modelID"]): IModelElement | undefined {
    return this.getRepository(bim).getByModelID(bim, modelID)
  }

  getByModelIDAsync(bim: Bim, modelID: IModelElement["modelID"]): Promise<IModelElement | undefined> {
    return this.getRepository(bim).getByModelIDAsync(bim, modelID)
  }

  getByBimElement(bimElement: BimElement): IModelElement | undefined {
    const bim = this.getBimByBimElement(bimElement)
    return bim ? this.getRepository(bim).getById(bim, bimElement.modelElement) : undefined
  }

  async getByBimElementAsync(bimElement: BimElement): Promise<IModelElement | undefined> {
    const bim = await this.getBimByBimElementAsync(bimElement)
    return bim ? this.getRepository(bim).getByIdAsync(bim, bimElement.modelElement) : undefined
  }

  async getByObjectIDAsync(bim: Bim, objectID: IModelElement["objectID"]) {
    return (await this.getByBimAsync(bim)).filter(el => el.objectID === objectID)
  }

  async getByGeometryModelIDAsync(bim: Bim, modelID: IModelElement["modelID"]): Promise<IModelElement | undefined> {
    return this.getRepository(bim).getByGeometryModelIDAsync(bim, modelID)
  }
}