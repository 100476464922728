import React from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import SimpleButton from "../../components/Buttons/SimpleButton";
import { observer } from "mobx-react-lite";
import { ICollectionCardViewModel } from "../../view-model/CollectionCardViewModel/interface";
import { CollectionForm } from "../CollectionForm/CollectionForm";

const useClasses = makeStyles(theme => ({
    root: {
        width: 406,
        height: 216,
        boxSizing: "border-box",

        padding: 20,
        background: "#FAF9F9",
        borderRadius: 14,

        display: "grid",
        gridTemplateColumns: "auto 54px 181px",
        gridTemplateRows: "1fr auto auto",

        gridTemplateAreas: `
            "name name img"
            "link link img"
            "openButton editButton img"
        `
    },
    name: {
        gridArea: "name",

        fontWeight: "bold",
        fontSize: "14px",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },

    link: {
        gridArea: "link",

        marginBottom: 10,
        width: 44,
        height: 44,
    },
    linkImg: {
        width: 44,
        height: 44,
    },

    img: {
        gridArea: "img",

        marginLeft: 20,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#c7c7c7"
    },

    openButton: {
        gridArea: "openButton",

        alignSelf: "flex-end",
        backgroundColor: theme.palette.primary.main
    },

    editButton: {
        gridArea: "editButton",

        width: 44,
        marginLeft: 10,
        alignSelf: "flex-end",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.main
    }
}))

const useClassesForm = makeStyles(() => ({
    root: {
        width: 340,
        padding: 20,
    },
}))

interface ICollectionCardProps {
    viewModel: ICollectionCardViewModel
    classes?: any
}

export const CollectionCard = observer((props: ICollectionCardProps) => {
    const classes = useClasses(props);
    const classesForm = useClassesForm();

    return (
        <>
            <div className={classes.root}>
                <div className={classes.name}>{props.viewModel.name}</div>
                {
                    props.viewModel.link ? (
                        <a href={props.viewModel.link} target="_blank" className={classes.link}>
                            <img
                                className={classes.linkImg}
                                src="/sarex_logo.png"
                            />
                        </a>
                    ) : null
                }
                <div style={props.viewModel.image ? {
                    backgroundImage: `url(${props.viewModel.image})`
                } : undefined} className={classes.img} />
                {
                    props.viewModel.onOpen ? <SimpleButton className={classes.openButton} onClick={props.viewModel.onOpen}>Открыть</SimpleButton> : null
                }
                {
                    props.viewModel.allowedEdit ?
                        <SimpleButton className={classes.editButton} onClick={() => props.viewModel.onEdit()}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.5 14.375V17.5H5.625L14.8417 8.28333L11.7167 5.15833L2.5 14.375ZM17.8417 5.28333L14.7167 2.15833L12.6083 4.27499L15.7333 7.39999L17.8417 5.28333Z" fill="black" />
                            </svg>
                        </SimpleButton>
                        : null
                }
            </div>
            {
                props.viewModel.dialogOpened && props.viewModel.collectionFormViewModel ? (
                    <Dialog open={props.viewModel.dialogOpened} onClose={() => props.viewModel.onCloseDialog()}>
                        <CollectionForm classes={classesForm} viewModel={props.viewModel.collectionFormViewModel} />
                    </Dialog>
                ) : null
            }
        </>
    )
})