import { makeObservable, observable } from "mobx";
import { IDialogViewModel } from "./interface";

export class DialogViewModel implements IDialogViewModel {
  constructor(private props: { open: boolean, onOpen?(): void }) {
    this.props = props

    this.open = props.open

    makeObservable(this)
  }

  @observable
  open: boolean

  onClose = () => {
    this.open = false
  }

  onOpen = () => {
    this.props.onOpen ? this.props.onOpen() : this.open = true
  }
}