import { difference } from "lodash";
import { computed, makeObservable, reaction } from "mobx";
import { IModelElement } from "../../models/ModelElement/interface";
import { IHideModelNodesViewModel, IHideNodesViewModel } from "../../view-model/HideNodesViewModel/interface";
import { ModelLoader } from "./ModelLoader";
import { Viewer } from "./Viewer";

export class HideNodes {
  constructor(private props: { viewModel: IHideNodesViewModel, bimViewer: Viewer, modelLoader: ModelLoader }) {
    this.props = props

    makeObservable(this)

    this.mount()
  }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  private mount() {
    const clearReaction1 = reaction(() => this.props.viewModel.hideModelNodesViewModels, (viewModels) => {
      for (const viewModel of viewModels) {
        new HideModelNodes({
          ...this.props,
          viewModel
        })
      }
    }, {
      fireImmediately: true
    })

    this.unmount = () => {
      clearReaction1()
    }
  }

  unmount() { }
}

class HideModelNodes {
  constructor(private props: { viewModel: IHideModelNodesViewModel, bimViewer: Viewer, modelLoader: ModelLoader }) {
    this.props = props

    makeObservable(this)
    this.mount()
  }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  @computed
  get model() {
    return this.props.modelLoader.getModelByUrn(this.props.viewModel.urn)
  }

  @computed
  get forgeDbIds() {
    return this.model?.getFragmentList().fragments.fragId2dbId || []
  }

  @computed
  get transparentHiding() {
    return this.props.viewModel.transparentHiding
  }

  hideElements(elements: IModelElement["modelID"][], transparentHiding: boolean) {
    if (transparentHiding) {
      this.model?.visibilityManager.hide(elements)
    } else {
      for (const element of elements) {
        this.model?.visibilityManager.setNodeOff(element, true)
      }
    }
  }

  showElements(elements: IModelElement["modelID"][], transparentHiding: boolean) {
    if (transparentHiding) {
      this.model?.visibilityManager.show(elements)
    } else {
      for (const element of elements) {
        this.model?.visibilityManager.setNodeOff(element, false)
      }
    }
  }

  @computed
  get hideDbIds() {
    return this.props.viewModel.hideDbIds.filter(dbId => this.forgeDbIds.includes(dbId))
  }

  mount() {
    reaction(() => {
      if (!this.model) return []
      return this.hideDbIds
    }, (elements, oldElements = []) => {
      const showElements = difference(oldElements, elements)
      const hideElements = difference(elements, oldElements)

      this.hideElements(hideElements, this.transparentHiding)
      this.showElements(showElements, this.transparentHiding)
    }, {
      fireImmediately: true
    })

    reaction(() => {
      if (!this.model) return
      return this.transparentHiding
    }, (transparentHiding, transparentHidingOld) => {
      if (transparentHidingOld !== undefined && transparentHiding !== undefined) {
        this.showElements(this.hideDbIds, transparentHidingOld)
        this.hideElements(this.hideDbIds, transparentHiding)
      }
    }, {
      fireImmediately: true
    })
  }

  unmount() { }
}