import { computed, makeObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { Bim } from "../../models/Bim";
import { TTargetProps } from "../../models/Target";
import { ETargetNoteAttributesStoreyKeys, TargetNote } from "../../models/TargetNote";
import { ITargetNoteRepository } from "../../repositories/TargetNoteRepository/interface";
import { Bims } from "../Bims/Bims";

export class StoreyNotes {
  constructor(private props: {
    targetNoteRepository: ITargetNoteRepository
    bims: Bims
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  private get modelWorkTypeID() {
    return this.props.bims.selectedModelWorkTypeId
  }

  @computed
  private get useAttributesStoreyKey(): string {
    if (this.modelWorkTypeID === 1) return ETargetNoteAttributesStoreyKeys.storeys

    return ETargetNoteAttributesStoreyKeys.storeysOther
  }

  getStoreyMarkInTargetNote(props: { targetNote: TargetNote, storey: string }) {
    return props.targetNote.attributes[this.useAttributesStoreyKey]?.[props.storey]
  }

  getLevelMarksByBim = computedFn((bim: Bim) => {
    const targetNotes = this.getTargetNotesByBims(new Set([bim]))
    const marks = new Set<string>()

    for (const targetNote of targetNotes) {
      const storeyMarks: string[] = Object.values(targetNote.attributes[this.useAttributesStoreyKey] || {})

      for (const mark of storeyMarks) {
        marks.add(mark)
      }
    }

    return marks
  })

  async saveLevelMarkForTarget(props: { target: TTargetProps["id"], storey: string, mark: string }) {
    return this.props.targetNoteRepository.saveStoreyMarkForTarget({
      ...props,
      key: this.useAttributesStoreyKey
    })
  }

  getLevelMarksByStorey = computedFn((storey: { name: string, bim: Set<Bim> }): string[] => {
    const targetNotes = this.getTargetNotesByBims(storey.bim)
    let marks: string[] = []

    for (const targetNote of targetNotes) {

      const storeyMark = this.getStoreyMarkInTargetNote({ targetNote, storey: storey.name })
      if (storeyMark) {
        marks.push(storeyMark)
      }
    }

    return marks
  })

  getTargetNotesByBims = computedFn((bims: Set<Bim>) => {
    const notes = new Set<TargetNote>()

    for (const bim of bims) {
      const targetMark = this.props.targetNoteRepository.getByTargetId(bim.target)

      if (targetMark) {
        notes.add(targetMark)
      }
    }

    return notes
  })
}