import { TBimProps } from "./Bim";
import { TCollectionProps } from "./Collection";
import { EHomeSectionCodes } from "./HomeSectionCodes";
import { EPages } from "./Pages";
import { TPrescriptionProps } from "./Prescription";
import { TProjectProps } from "./Project";
import { TRemarkProps } from "./Remark";

export type TViewStateProps = TDefaultState;
export enum EPanelSection {
  status = "status",
  storeys = "storeys",
  remarks = "remarks",
  prescriptions = "prescriptions",
  planfact = "planfact",
  terms_and_volumes = "terms_and_volumes",
  history = "history",
  settings = "settings",
}

// дефолтное состояние приложения
type TDefaultState = {
  // выбранный проект
  selectedProjectId?: TProjectProps["id"];

  // поиск на главной странице
  mainPageSearch: string;

  // выбранный раздел на главной странице
  mainPageSection: EHomeSectionCodes;

  // выбранные модели
  selectedBims?: TBimProps["id"][];

  // выбранная коллекция
  selectedCollection?: TCollectionProps["id"]

  // активная страница
  selectedPage: EPages;

  // выбранные замечания
  selectedRemakrs?: TRemarkProps["id"][];

  // режим редактирования замечания
  editRemarkMode: boolean

  // выбранные предписания
  selectedPrescriptions?: TPrescriptionProps["id"][];

  // выбранный раздел в левой панели
  panelSection: EPanelSection;

  // открытие левой панели
  panelOpened: boolean;

  // узкать/широкая левая панель
  shortPanelActive: boolean;

  // режим добавления замечания
  addRemarkMode: boolean;

  // активен диалог назначения статуса
  applyStatusDialogActive: boolean;

  // активен диалог создания замечания
  addRemarkDialogActive: boolean

  // открыть/закрыть фильтр
  isOpenFilterPanel: boolean;
}