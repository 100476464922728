import axios from "axios";
import { TBimProps } from "../../models/Bim";
import { Collection, NewCollection, TNewCollectionProps } from "../../models/Collection";
import { TModelWorkType } from "../../models/ModelWorkType";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineRepository } from "../Repository/RestOfflineRepository";
import { ICollectionsRepository } from "./interface";
import { IndexedDBPatchesRepository } from "../PatchesRepository/IndexedDBPatchesRepository";
import { IDBPDatabase } from "idb";

type TRestProps = {
  id: number
  bims: TBimProps["id"][]
  image: string | null
  name: string
  order: number
  model_type: TModelWorkType["id"]
  consolidated_model: boolean
  external_link?: string
}

type TNewRestCollectionProps = {
  name: string
  bims: TBimProps["id"][]
  order: number
  image: string | null
  model_type: TModelWorkType["id"]
  consolidated_model: boolean
  external_link: string
}
export class RestCollectionsRepository implements ICollectionsRepository {
  constructor(private props: {
    host: string
    authRepository: IAuthRepository
    db: IDBPDatabase
    patchesTableName: string
  }) {
    this.props = props

    this.offlineRepository = new RestOfflineRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: Collection,
      NewModel: NewCollection,
      serialyze: this.serialyze,
      serialyzeAdd(props: TNewCollectionProps): TNewRestCollectionProps {
        return {
          name: props.name,
          bims: props.bims,
          order: props.order,
          model_type: props.modelWorkType,
          image: props.image ? props.image : null,
          consolidated_model: props.consolidated,
          external_link: props.link
        }
      },
      patchesRepository: new IndexedDBPatchesRepository({
        db: props.db,
        name: props.patchesTableName,
        Model: Collection
      })
    })
  }

  get path() {
    return `${this.props.host}api/main/bim-group/`
  }

  serialyze(props: TRestProps): Collection["props"] {
    return {
      id: props.id,
      name: props.name,
      bims: props.bims,
      image: props.image ? props.image : "",
      order: props.order,
      modelWorkType: props.model_type,
      consolidated: props.consolidated_model,
      link: props.external_link || ""
    }
  }

  offlineRepository: RestOfflineRepository<Collection, TRestProps, TNewRestCollectionProps, TNewCollectionProps>

  getAll() {
    return this.offlineRepository.getAll()
  }

  getAllAsync() {
    return this.offlineRepository.getAllAsync()
  }

  getById(props) {
    return this.offlineRepository.getById(props)
  }

  getByIdAsync(props) {
    return this.offlineRepository.getByIdAsync(props)
  }

  edit(id, props) {
    return this.offlineRepository.edit(id, props)
  }

  add(props) {
    return this.offlineRepository.add(props)
  }

  delete(props) {
    return this.offlineRepository.delete(props)
  }

  async sort(data: { id: number, order: number }[]) {
    const response = await axios.patch<{ id: number, order: number }[]>(`${this.path}bulk_update/`, data, {
      headers: this.offlineRepository.getAuthHeadersAsync()
    })

    this.offlineRepository.restRepository.readOnlyRepository.updateModels(response.data)

    return undefined
  }
}