import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IEditStatusFormViewModel } from "../../view-model/EditStatusFormViewModel/interface"
import SimpleButton from '../../components/Buttons/SimpleButton';
import { StatusWrapper } from "../StatusWrapper/StatusWrapper";
import { PanelSelector } from "../PanelSelector/PanelSelector";

const useClasses = makeStyles(theme => ({
  root: {
  },
  header: {
    textAlign: "center",
    fontSize: "22px",
    fontWeight: "bold",
    lineHeight: "54px"
  },
  buttons: {
    marginTop: 21,
    display: "flex",
  },
  send: {
    flex: 1,
    marginLeft: 20,
    backgroundColor: "#fdc50a"
  },
  close: {
    flex: 1,
    backgroundColor: "#EE2E2E",
    color: "white"
  },
  content: {
  },
}))

interface IEditStatusFormProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IEditStatusFormViewModel
}

export const EditStatusForm = observer((props: IEditStatusFormProps) => {
  const classes = useClasses(props)

  return (
    <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
      <div className={classes.root}>
        <div className={classes.header}>
          Редактировать
        </div>
        <div className={classes.content}>
          <PanelSelector
            viewModel={props.viewModel.statusViewModel}
          />
          <div className={classes.buttons}>
            <SimpleButton
              className={classes.close}
              onClick={props.viewModel.cancelButtonViewModel.onClick}
            >Закрыть</SimpleButton>
            <SimpleButton
              className={classes.send}
              onClick={props.viewModel.applyButtonViewModel.onClick}
            >Применить</SimpleButton>
          </div>
        </div>
      </div>
    </StatusWrapper>
  )
})