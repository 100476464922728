import { computed, makeObservable } from "mobx";
import { IPieChartWithLegendViewModel } from "./interface";

export class SimplePieChartWithLegendViewModel implements IPieChartWithLegendViewModel {
  constructor(private props: {
    header?: string
    unit: string
    data:
    {
      name: string
      value: number
      color?: string
    }[]
  }) {
    this.props = props

    makeObservable(this)
  }

  get header() {
    return this.props.header || ""
  }

  private getRandomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`
  }

  private getPercent(value, totalValue) {
    return (value / totalValue * 100).toFixed(0)
  }

  private getValueLabel(props: {
    value: number,
    totalValue: number,
    unit: string
  }) {
    return `${this.getPercent(props.value, props.totalValue)}% (${props.value.toFixed(0)} ${props.unit}.)`
  }

  @computed
  get legends() {
    return this.data.map(({ name, value, color }) => ({
      key: name,
      color: color,
      valueLabel: this.getValueLabel({ value, totalValue: this.totalValue, unit: this.props.unit }),
      label: name
    }))
  }

  @computed
  private get data() {
    return this.props.data.map(props => ({
      ...props,
      color: props.color || this.getRandomColor(),
    }))
  }

  @computed
  private get totalValue() {
    return this.data.reduce((acc, { value }) => acc + value, 0)
  }

  @computed
  get pieChartViewModel() {
    return {
      data: this.data.map(({ value, color }) => ({ value, color }))
    }
  }
}