import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { issuedByNameDictionary, Prescription } from "../../models/Prescription";
import { Remark } from "../../models/Remark";
import { UploadFile } from "../../models/UploadFile";
import { EVariants } from "../../usecase/Notifications/Notifications";
import { getPrescriptionDocumentString } from "../../view/PrescriptionDocument/PrescriptionDocument";
import { EDocumentMode, IPrescriptionDocumentViewModel } from "./interface";

export class PrescriptionDocumentViewModel implements IPrescriptionDocumentViewModel {
  constructor(private props: { layers: ILayers, prescription: Prescription }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get prescription() {
    return this.props.prescription
  }

  @computed
  private get _remarks() {
    const remarks: Remark[] = []
    for (const remark of this.props.layers.usecases.prescriptions.getPrescriptionRemarks(this.prescription)) {
      if (remark) remarks.push(remark)
    }

    return remarks
  }

  @computed
  get prescriptionCode() {
    return this.props.layers.usecases.prescriptions.getPrescriptionCode(this.prescription)
  }

  @computed
  get mainCompan() {
    return this.props.layers.repositories.companiesRepository.getById(this.props.prescription.issuedBy)
  }

  @computed
  get mainCompanyName() {
    return this.mainCompan?.name || ""
  }

  @computed
  get targetAddress() {
    if (this.prescription.targetText) return this.prescription.targetText
    return this.props.layers.usecases.prescriptions.getPrescriptionTarget(this.prescription)?.address || ""
  }

  @computed
  get specialConditions() {
    return this.props.prescription.specialConditions || "За невыполнение или ненадлежащее исполнение настоящего Предписания, осведомленное лицо за нарушение технических условий производства работ и строительного законодательства несет административную и уголовную ответственность."
  }

  @computed
  get notes() {
    return this.prescription.notes
  }

  @computed
  get ownerFullName() {
    return this.owners.map(owner => owner?.fullName || "?").join(", ")
    // return this.props.layers.repositories.usersRepository.getById(this.prescription.owner)?.fullName || ""
  }

  @computed
  get owners() {
    return this.unique(this._remarks.map(remark =>
      this.props.layers.repositories.usersRepository.getById(remark.owner)
    ))
  }

  private unique<T>(array: T[]): T[] {
    return Array.from((new Set(array)).values())
  }

  @computed
  get receiverFullName() {
    return this.prescription.receiver
  }

  @computed
  get createdAtDate() {
    return format(this.prescription.deadlineAt, '"dd" MMMM yyyy г.', { locale: ru })
  }

  @computed
  get mode() {
    return EDocumentMode.development
    // return this.mainCompan?.prescriptionType === 1 ? EDocumentMode.development : EDocumentMode.bc
  }

  @computed
  get contractorPureCompanyName() {
    return this.props.layers.usecases.prescriptions.getPrescriptionContractor(this.prescription)?.name || ""
  }

  @computed
  get contractorCompanyName() {
    return `Производителю работ ${this.contractorPureCompanyName}`
  }

  get developmentCompanyName() {
    return `ООО «ИР Девелопмент»`
  }

  @computed
  get targetName() {
    const targets = this.props.layers.usecases.bims.selectedTargets
    return targets.filter(target => target).map(target => target?.name || "").join(",")
  }

  @computed
  get workDescription() {
    return `Проведена проверка работ ${this.prescription.documentWorkDescription} на отм. ${this.prescription.documentLevelMark}. ${this.targetName}`
  }

  @computed
  get remarks() {
    let photoNumber = 0

    return this._remarks.map((remark, index) => {
      const attachmentsLength = remark.attachments.length
      const nextPhotoNumber = photoNumber + 1
      photoNumber += attachmentsLength

      return {
        title: [
          remark.location,
          this.props.layers.usecases.remarks.getRemarkBase(remark)?.title || "",
          remark.normativeDocument,
          remark.body
        ].filter(Boolean).join(", "),
        annex: attachmentsLength === 0 ? "" : attachmentsLength === 1 ? `фото ${nextPhotoNumber}` : `фото ${nextPhotoNumber} - ${photoNumber}`,
        deadline: format(remark.deadline, "dd.MM.yyyy")
      }
    })
  }

  @computed
  get images() {
    const files = new Set<UploadFile>()

    for (const remark of this._remarks) {
      for (const attachment of remark.attachments) {
        const file = this.props.layers.repositories.uploadFilesRepository.getById(attachment)
        if (file && file.file) {
          files.add(file)
        }
      }
    }

    return Array.from(files).map(file => ({ src: file.file }))
  }

  async getImagesAsync() {
    const set = new Set<{
      src: string
    }>()

    for (const remark of this._remarks) {
      for (const attachment of remark.attachments) {
        const file = await this.props.layers.repositories.uploadFilesRepository.getByIdAsync(attachment)
        if (file) {
          set.add({
            src: file.file
          })
        }
      }
    }

    return Array.from(set.values())
  }

  async getWorkDescription() {
    return this.workDescription
  }

  async getProps(): Promise<IPrescriptionDocumentViewModel> {
    return {
      remarks: this.remarks,
      images: await this.getImagesAsync(),

      contractorCompanyName: this.contractorCompanyName,
      contractorPureCompanyName: this.contractorPureCompanyName,
      workDescription: await this.getWorkDescription(),
      developmentCompanyName: this.developmentCompanyName,

      receiverFullName: this.receiverFullName,
      createdAtDate: this.createdAtDate,
      mainCompanyName: this.mainCompanyName,
      targetAddress: this.targetAddress,
      prescriptionCode: this.prescriptionCode,
      notes: this.notes,
      ownerFullName: this.ownerFullName,
      mode: this.mode,

      specialConditions: this.specialConditions
    }
  }

  async download() {
    const a = document.createElement('a')

    try {
      const props = await this.getProps()
      const content = getPrescriptionDocumentString(props)
      const html = `<!DOCTYPE html><html><head><meta charset="utf-8"></head><body style="margin: 0;">${content}</body></html>`
      const data = await this.props.layers.repositories.pdfGenerator.getPDF(html)

      const src = URL.createObjectURL(new Blob([data], {
        type: "application/pdf"
      }))

      a.setAttribute('href', src)
      a.setAttribute('download', `Предписание ${props.prescriptionCode}.pdf`)
      a.setAttribute('target', '_blank')
      a.click()
    } catch (error) {
      this.props.layers.usecases.notifications.notify("ошибка формирования pdf", {
        variant: EVariants.error
      })
    }

    a.remove()
  }
}