import { TBaseModel } from "./BaseModel";
import { Entity } from "./Entity";

export type TModelWorkType = {
  name: string
} & TBaseModel

export class ModelWorkType extends Entity<TModelWorkType> {
  get name() {
    return this.props.name
  }
}