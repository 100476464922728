import { IDBPDatabase } from "idb";
import { computed, makeObservable } from "mobx";
import { TTargetProps } from "../../models/Target";
import { NewTargetNote, TargetNote, TNewTargetNoteProps } from "../../models/TargetNote";
import { IAuthRepository } from "../AuthRepository/interface";
import { IndexedDBPatchesRepository } from "../PatchesRepository/IndexedDBPatchesRepository";
import { RestOfflineRepository } from "../Repository/RestOfflineRepository";
import { ITargetNoteRepository } from "./interface";

type TGetTargetNoteResponse = {
  id: number
  target: number
  attributes: any
}

type TNewRestProps = {
  id: number
  target: TTargetProps["id"]
  // name: string
  // note: string
  attributes: {
    storeys?: { [s: string]: string }
    storeysOther?: { [s: string]: string }
  }
}

export class RestTargetNoteRepository implements ITargetNoteRepository {
  constructor(private props: {
    host: string
    authRepository: IAuthRepository
    db: IDBPDatabase
    patchesTableName: string
  }) {
    this.props = props

    this.offlineRepository = new RestOfflineRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: TargetNote,
      NewModel: NewTargetNote,
      // serialyze: this.serialyze,
      // serialyzeAdd: this.serialyzeAdd,
      patchesRepository: new IndexedDBPatchesRepository({
        db: props.db,
        name: props.patchesTableName,
        Model: TargetNote
      })
    })

    makeObservable(this)
  }

  offlineRepository: RestOfflineRepository<TargetNote, TGetTargetNoteResponse, TNewRestProps, TNewTargetNoteProps>

  @computed
  get path() {
    return `${this.props.host}api/main/target-note/`
  }

  serialyze(responseProps: TGetTargetNoteResponse): TargetNote["props"] {
    return responseProps
  }

  async getByTargetIdAsync(target: TTargetProps["id"]) {
    const marks = await this.offlineRepository.getByParamsAsync({ target })

    return marks[0]
  }

  getByTargetId(target: TTargetProps["id"]) {
    return this.offlineRepository.getOneByParams({ target })
  }

  async saveStoreyMarkForTarget(props: { target: number, storey: string, mark: string, key: string }) {
    const targetMark = await this.getByTargetIdAsync(props.target)

    if (!targetMark) {
      this.offlineRepository.add({
        target: props.target,
        //@ts-ignore
        name: "name",
        note: "note",
        attributes: {
          [props.key]: { [props.storey]: props.mark }
        }
      })
    } else {
      this.offlineRepository.edit(targetMark.id, {
        target: props.target,
        attributes: {
          ...targetMark.props.attributes,
          [props.key]: {
            ...targetMark.props.attributes[props.key],
            [props.storey]: props.mark
          },
        }
      })
    }
  }
}