import { makeObservable } from "mobx";
import { SimpleViewModel } from "./SimpleViewModel";

export class BooleanViewModel extends SimpleViewModel<boolean> {
  constructor(value?: boolean) {
    super({
      value: value || false
    })

    makeObservable(this)
  }

  switch = () => {
    this.onChange(!this.value)
  }
}