import { withStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { memo, useCallback, useEffect, useRef } from "react";

function SearchFieldPure({ classes, value, onChange, placeholder, className }) {
    const inputRef = useRef();

    const onBlur = useCallback(e => e.target.scrollLeft = 0, []);

    const onInputHandler = useCallback(onChange ? e => {
        onChange(e.target.innerText)
    } : null, [onChange]);

    const onClick = useCallback(e => inputRef.current.focus(), []);

    const onKeyDown = useCallback(e => e.keyCode === 13 && e.preventDefault(), []);

    useEffect(() => {
        if (inputRef.current && value !== inputRef.current.innerText) {
            inputRef.current.innerText = value ? value : ""
        }
    }, [value]);

    return (
        <div className={clsx(classes.root, className)} onClick={onClick}>
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.50006 0.222168C8.53765 0.222168 11.0001 2.68414 11.0001 5.72114C11.0001 6.96923 10.5842 8.1202 9.88346 9.04309L13.7791 12.9424C14.0718 13.2354 14.0715 13.7102 13.7785 14.0029C13.4854 14.2955 13.0105 14.2952 12.7178 14.0022L8.82266 10.1037C7.89959 10.8043 6.74839 11.2201 5.50006 11.2201C2.46246 11.2201 0 8.75814 0 5.72114C0 2.68414 2.46246 0.222168 5.50006 0.222168ZM5.50006 1.72181C3.2909 1.72181 1.5 3.51236 1.5 5.72114C1.5 7.92993 3.2909 9.72047 5.50006 9.72047C7.70921 9.72047 9.50011 7.92993 9.50011 5.72114C9.50011 3.51236 7.70921 1.72181 5.50006 1.72181Z" fill="#AAAAA9" />
            </svg>
            <div
                className={classes.input}
                ref={inputRef}
                contentEditable
                placeholder={placeholder}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onInput={onInputHandler}
            />
        </div>
    );
}

const SearchField = withStyles(theme => ({
    root: {
        backgroundColor: "#F1F1F1",
        borderRadius: 10,
        padding: 10,
        display: "flex",
        cursor: "text",
    },
    input: {
        flex: 1,
        marginLeft: 10,

        fontSize: "12px",

        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderBottom: "1px solid #0000",

        "&:focus": {
            borderBottom: "1px solid #000",
            textOverflow: "initial",
            outline: "none"
        },

        "&:empty:before": {
            content: "attr(placeholder)",
            color: "#AAAAA9"
        }
    }
}))(SearchFieldPure)


export default memo(SearchField);