import { format } from "date-fns";
import { action, computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { BimElement } from "../../models/BimElement";
import { EUnits, unitLabel } from "../../models/Units";
import { LoadingStatusWrapperViewModel } from "../StatusWrapperViewModel/LoadingStatusWrapperViewModel";
import { StatusWrapperForPromiseViewModel } from "../StatusWrapperViewModel/StatusWrapperForPromiseViewModel";
import { EPropertyType, IPropertiesPanelViewModel, TObjectProperty, TStringProperty } from "./interface";

export class PropertiesPanelViewModel implements IPropertiesPanelViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  header = "Свойства"

  onClose = () => {
    this.props.layers.usecases.ui.propertiesPanelFloatingWindowDataRepository.patch({
      opened: false
    })
  }

  @computed
  get statusWrapperViewModel() {
    if (this.modelElements.length === 0) return new LoadingStatusWrapperViewModel({ loadingLabel: "Для отображения свойств\nвыберите элементы" })
    return new StatusWrapperForPromiseViewModel({ promise: this.loadProps() })
  }

  @computed
  get properties() {
    return [
      this.IDProperty,
      this.statusProperty,
      this.remarksProperty,
      this.prescriptionsProperty,
      this.deadlineProperty,
      this.locationProps,
      this.sizeProps,
      this.otherProps,
      this.workTypesProperty,
    ]
  }

  @computed
  private get hidedPropertiesState() {
    return this.props.layers.usecases.ui.propertiesPanelFloatingWindowDataRepository.get()?.hidedProperties || {}
  }

  @action
  private switchHidedProperty(key: string) {
    this.hidedPropertiesState[key] = !this.hidedPropertiesState[key]
    this.props.layers.usecases.ui.propertiesPanelFloatingWindowDataRepository.patch({
      hidedProperties: this.hidedPropertiesState
    })
  }

  private async loadProps() {
    return Promise.all([
      this.loadStatusPropertyAsync(),
      this.loadRemarkPropertyAsync(),
      this.loadPrescriptionProperty(),
      this.loadDeadlineProperty(),
      this.loadWorkTypesProperty()
    ])
  }

  @computed
  private get IDProperty(): TStringProperty {
    const modelElements = this.props.layers.usecases.selectedElements.selectedModelElements

    return {
      key: "ID",
      type: EPropertyType.string,
      name: "ID",
      label: modelElements.map(modelElement => modelElement.objectID || "-").join(", "),
    }
  }

  @computed
  private get statusProperty(): TStringProperty {
    const elements = this.props.layers.usecases.selectedElements.selectedBimElements
    const statuses = this.props.layers.usecases.statuses.getActiveStatusHistoriesForBimElements(elements)
    const statusDictionaries = this.props.layers.usecases.statuses.getStatusDictionaryFromStatusHistory(statuses)

    return {
      key: "status",
      type: EPropertyType.string,
      name: "Статус",
      label: statusDictionaries.map(statusDictionary => statusDictionary?.name || "Работы не начаты").join(", "),
    }
  }

  private async loadStatusPropertyAsync() {
    const elements = await this.props.layers.usecases.selectedElements.getSelectedBimElementsAsync()
    const statuses = await this.props.layers.usecases.statuses.getActiveStatusHistoriesForBimElements(elements)
    await this.props.layers.usecases.statuses.getStatusDictionaryFromStatusHistory(statuses)
  }

  @computed
  private get remarksProperty(): TStringProperty {
    const elements = this.props.layers.usecases.selectedElements.selectedBimElements
    const existElements: BimElement[] = elements.filter<BimElement>((e): e is BimElement => e instanceof BimElement)

    const remarks = this.props.layers.usecases.remarks.getRemarksForBimElements(existElements)
    const notClosedRemarks = this.props.layers.usecases.remarks.getNotClosedRemarks(remarks)
    const codes: string[] = []
    for (const remark of notClosedRemarks) {
      const code = this.props.layers.usecases.remarks.getRemarkCode(remark)
      const date = format(remark.createdAt, "dd.MM.yyyy")
      codes.push(`${code} (${date})`)
    }

    return {
      key: "remark",
      type: EPropertyType.string,
      name: "Замечания",
      label: codes.join(", ") || "отсутствуют",
    }
  }

  private async loadRemarkPropertyAsync() {
    const elements = await this.props.layers.usecases.selectedElements.getSelectedBimElementsAsync()
    const existElements: BimElement[] = elements.filter<BimElement>((e): e is BimElement => e instanceof BimElement)

    const remarks = await this.props.layers.usecases.remarks.getRemarksForBimElementsAsync(existElements)
    const notClosedRemarks = this.props.layers.usecases.remarks.getNotClosedRemarks(remarks)
    for (const remark of notClosedRemarks) {
      await this.props.layers.usecases.remarks.getRemarkCodeAsync(remark)
    }
  }

  @computed
  private get prescriptionsProperty(): TStringProperty {
    const elements = this.props.layers.usecases.selectedElements.selectedBimElements
    const existElements: BimElement[] = elements.filter<BimElement>((e): e is BimElement => e instanceof BimElement)
    const prescriptions = this.props.layers.usecases.prescriptions.getNotClosedPrescriptionsByBimElements(existElements)

    const codes: string[] = []
    for (const prescription of prescriptions) {
      const code = this.props.layers.usecases.prescriptions.getPrescriptionCode(prescription)
      const date = format(prescription.deadlineAt, "dd.MM.yyyy")
      codes.push(`${code} (${date})`)
    }

    return {
      key: "prescription",
      type: EPropertyType.string,
      name: "Предписания",
      label: codes.join(", ") || "отсутствуют",
    }
  }

  private async loadPrescriptionProperty() {
    const elements = await this.props.layers.usecases.selectedElements.getSelectedBimElementsAsync()
    const existElements: BimElement[] = elements.filter<BimElement>((e): e is BimElement => e instanceof BimElement)
    const prescriptions = await this.props.layers.usecases.prescriptions.getNotClosedPrescriptionsByBimElementsAsync(existElements)

    for (const prescription of prescriptions) {
      await this.props.layers.usecases.prescriptions.getPrescriptionCodeAsync(prescription)
    }
  }

  @computed
  private get deadlineProperty(): TStringProperty {
    const elements = this.props.layers.usecases.selectedElements.selectedBimElements
    const dates: Set<string> = new Set()

    for (const element of elements) {
      if (element) {
        const bimStatus = this.props.layers.repositories.bimStatusRepository.getActualByBimElement(element)

        if (bimStatus) dates.add(format(bimStatus.deadline, "dd.MM.yyyy"))
      }
    }

    return {
      key: "planned",
      type: EPropertyType.string,
      name: "Плановая дата",
      label: Array.from(dates).join(", ") || "отсутствует",
    }
  }

  private async loadDeadlineProperty() {
    const elements = await this.props.layers.usecases.selectedElements.getSelectedBimElementsAsync()

    for (const element of elements) {
      if (element) {
        await this.props.layers.repositories.bimStatusRepository.getActualByBimElementAsync(element)
      }
    }
  }

  @computed
  private get workTypesProperty(): TStringProperty {
    let workTypes = this.props.layers.usecases.elements.getWorkTypesByForgeElements(this.modelElements)

    for (const forgeElement of this.modelElements) {
      const statusDictionaries = this.props.layers.usecases.statuses.getStatusDictionaryForForgeElement(forgeElement)
      const workTypesSet = new Set(statusDictionaries.map(s => s.workType))
      workTypes = workTypes.filter(workType => workType ? workTypesSet.has(workType.id) : true)
    }

    return {
      key: "work type",
      type: EPropertyType.string,
      name: "Вид работ",
      label: workTypes.map(workType => workType?.fullName || "неизвестно").join(", "),
    }
  }

  private async loadWorkTypesProperty() {
    await this.props.layers.usecases.elements.getWorkTypesByForgeElementsAsync(this.modelElements)

    for (const forgeElement of this.modelElements) {
      await this.props.layers.usecases.statuses.getStatusDictionaryForForgeElementAsync(forgeElement)
    }
  }

  private unique<T>(array: T[]): T[] {
    return Array.from((new Set(array)).values())
  }

  private splitProperties(array: (string | undefined)[]) {
    return this.unique(array).join(", ") || "отсутствует"
  }

  private get modelElements() {
    return this.props.layers.usecases.selectedElements.selectedForgeElements
  }

  @computed
  private get locationProps(): TObjectProperty {
    const modelElements = this.modelElements
    const key = "location"

    return {
      key,
      type: EPropertyType.object,
      name: "Местоположение",
      expanded: !this.hidedPropertiesState[key],
      onClick: () => this.switchHidedProperty(key),
      properties: [
        {
          key: "1",
          type: EPropertyType.string,
          name: "Этаж",
          label: this.splitProperties(modelElements.map(forgeElement => forgeElement.storey))
        },
        {
          key: "2",
          type: EPropertyType.string,
          name: "Секция",
          label: this.splitProperties(modelElements.map(forgeElement => forgeElement.section))
        },
      ]
    }
  }

  private formatValue(value: string | undefined, unit: string) {
    return value === undefined ? "не указана" : `${parseFloat(value).toFixed(2)} ${unit}`
  }

  @computed
  private get sizeProps(): TObjectProperty {
    const modelElements = this.modelElements
    const key = "size"

    return {
      key,
      type: EPropertyType.object,
      name: "Размеры",
      expanded: !this.hidedPropertiesState[key],
      onClick: () => this.switchHidedProperty(key),
      properties: [
        {
          key: "1",
          type: EPropertyType.string,
          name: "Длина",
          label: this.splitProperties(modelElements.map(modelElement => this.formatValue(modelElement.length, unitLabel[EUnits.length])))
        },
        {
          key: "2",
          type: EPropertyType.string,
          name: "Ширина",
          label: this.splitProperties(modelElements.map(modelElement => this.formatValue(modelElement.width, unitLabel[EUnits.length])))
        },
        {
          key: "3",
          type: EPropertyType.string,
          name: "Высота",
          label: this.splitProperties(modelElements.map(modelElement => this.formatValue(modelElement.height, unitLabel[EUnits.length])))
        },
        {
          key: "4",
          type: EPropertyType.string,
          name: "Объем",
          label: this.splitProperties(modelElements.map(modelElement => this.formatValue(modelElement.value, unitLabel[EUnits.volume])))
        },
        {
          key: "5",
          type: EPropertyType.string,
          name: "Площадь",
          label: this.splitProperties(modelElements.map(modelElement => this.formatValue(modelElement.square, unitLabel[EUnits.square])))
        },
      ]
    }
  }

  @computed
  private get otherProps(): TObjectProperty {
    const modelElements = this.modelElements
    const key = "other"

    return {
      key: "other",
      type: EPropertyType.object,
      name: "Прочее",
      expanded: !this.hidedPropertiesState[key],
      onClick: () => this.switchHidedProperty(key),
      properties: [
        {
          key: "1",
          type: EPropertyType.string,
          name: "Группа конструкции",
          label: this.splitProperties(modelElements.map(forgeElement => forgeElement.constructionGroup))
        },
        {
          key: "2",
          type: EPropertyType.string,
          name: "Марка материала",
          label: this.splitProperties(modelElements.map(forgeElement => forgeElement.material))
        },
      ]
    }
  }

  // async getProperties() {
  //   return [
  //     await this.getStatusProperty(),
  //     await this.getRemarkProperty(),
  //     await this.getPrescriptionProperty(),
  //     await this.getDeadlineProperty(),
  //     ...await this.getForgeElementsProperties(),
  //     await this.getWorkTypesProperty()
  //   ]
  // }
}