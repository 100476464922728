import { format, isAfter } from "date-fns";
import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Action, EActionTarget, EActionType } from "../../models/Action";
import { EPanelSection } from "../../models/ViewState";
import { EVariants } from "../../usecase/Notifications/Notifications";
import { EOrientations } from "../PanelDropdownMenuViewModel/interface";
import { PanelSelectorMultiAllViewModel } from "../PanelSelectorViewModel/PanelSelectorMultiAllViewModel";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { StatusWrapperForPromiseViewModel } from "../StatusWrapperViewModel/StatusWrapperForPromiseViewModel";
import { IHistoryPageViewModel } from "./interface";

export class HistoryPageViewModel implements IHistoryPageViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  searchViewModel = new SimpleViewModel({ value: "" })

  @computed
  get actionFilterPanelSelectorViewModel() {
    const values = new Set(this.allHistories.map(h => h.name))
    const options: { value: string, label: string }[] = Array.from(values).map(n => ({ value: n, label: n }))

    return new PanelSelectorMultiAllViewModel({
      options,
      defaultValue: Array.from(values),
      label: "события",
      orientation: EOrientations.right
    })
  }

  @computed
  get statusWrapperViewModel() {
    return new StatusWrapperForPromiseViewModel({
      promise: this.loadActions()
    })
  }

  async loadActions() {
    for (const bim of this.props.layers.usecases.bims.selectedBims) {
      for (const action of await this.props.layers.repositories.actionsRepository.getForBimAsync(bim.id)) { }
    }
  }

  @computed
  get actions(): Action[] {
    const actionsSet = new Set<Action>()

    for (const bim of this.props.layers.usecases.bims.selectedBims) {
      for (const action of this.props.layers.repositories.actionsRepository.getForBim(bim.id)) {
        actionsSet.add(action)
      }
    }

    return Array.from(actionsSet).filter(action =>
      (action.target !== EActionTarget.bimElement) &&
      !action.isUnknown
    ).sort((a, b) => isAfter(a.createdAt, b.createdAt) ? -1 : 1)
  }

  private getActionTypeName(action: Action): string {
    if (action.target === EActionTarget.bimStatusHistory) return "обновлен"

    switch (action.type) {
      case EActionType.create:
        return "создано"
      case EActionType.edit:
        return "изменено"
      case EActionType.delete:
        return "удалено"
      default:
        return ""
    }
  }

  private getActionTargetName(action: Action): string {
    switch (action.target) {
      case EActionTarget.remark:
        return "замечание"
      case EActionTarget.prescription:
        return "предписание"
      case EActionTarget.bimElement:
        return "элемент"
      case EActionTarget.bimStatusHistory:
        return "статус элемента"
      default:
        return ""
    }
  }


  private getActionName(action: Action) {
    const actionTypeName = this.getActionTypeName(action)
    const targetName = this.getActionTargetName(action)

    return `${actionTypeName} ${targetName}`
  }

  private async onClickAction(action: Action) {
    switch (action.target) {
      case EActionTarget.remark:
        const remark = await this.props.layers.repositories.remarksRepository.getByIdAsync(action.targetID)
        if (remark) {
          this.props.layers.usecases.viewState.setSelectedRemarks(
            [action.targetID]
          )
          this.props.layers.usecases.viewState.setPanelSection(EPanelSection.remarks)
        } else {
          this.props.layers.usecases.notifications.notify("замечание удалено", { variant: EVariants.error })
        }

        break
      case EActionTarget.prescription:
        const prescription = await this.props.layers.repositories.prescriptionsRepository.getByIdAsync(action.targetID)
        if (prescription) {
          this.props.layers.usecases.viewState.setSelectedPrescriptions(
            [action.targetID]
          )
          this.props.layers.usecases.viewState.setPanelSection(EPanelSection.prescriptions)
        } else {
          this.props.layers.usecases.notifications.notify("предписание удалено", { variant: EVariants.error })
        }

        break
      case EActionTarget.bimStatusHistory:
        const status = await this.props.layers.repositories.statusHistoryRepository.getByIdAsync(action.targetID)
        if (status) {
          this.props.layers.usecases.selectedElements.selectBimElementsIds([status.element])
          this.props.layers.usecases.viewState.setPanelSection(EPanelSection.status)
        } else {
          this.props.layers.usecases.notifications.notify("статус удален", { variant: EVariants.error })
        }

        break
      default:
        break;
    }
  }

  @computed
  get allHistories() {
    return this.actions.map(action => {
      const params = {
        key: String(action.id),
        name: this.getActionName(action),
        user: this.props.layers.repositories.usersRepository.getById(action.user)?.fullName || "",
        date: format(action.createdAt, "dd.MM.yyyy"),
        search: "",
        onClick: () => this.onClickAction(action)
      }

      params.search = `${params.name} ${params.user} ${params.date}`
      return params
    })
  }

  filterByType<T>(histories: (T & { search: string, name: string })[]): T[] {
    if (this.actionFilterPanelSelectorViewModel.allSelected) return histories

    return histories.filter(h => this.actionFilterPanelSelectorViewModel.has(h.name))
  }

  filterBySearch<T>(histories: (T & { search: string })[]): T[] {
    if (!this.searchViewModel.value) return histories
    return histories.filter(history => history.search.includes(this.searchViewModel.value))
  }

  @computed
  get histories() {
    return this.filterBySearch(
      this.filterByType(this.allHistories)
    )
  }

  backButtonViewModel = {
    onClick: () => this.props.layers.usecases.viewState.setPanelOpened(false),
  }
}