import { action, computed, flow, makeObservable, observable, ObservableMap, ObservableSet, toJS } from "mobx"
import { Bim, TBimProps } from "../../models/Bim"
import { ConstructionGroup } from "../../models/ConstructionGroup"
import { BimElement, TBimElementProps } from "../../models/BimElement"
import { WorkType } from "../../models/WorkType"
import { IBimsRepository } from "../../repositories/BimsRepository/interface"
import { IConstructionGroupsRepository } from "../../repositories/ConstructionGroupsRepository/interface"
import { IWorkTypesRepository } from "../../repositories/WorkTypesRepository/interface"
import { IBimElementsRepository } from "../../repositories/BimElementsRepository/interface"
import { Elements } from "../Elements/Elements"
import { Target } from "../../models/Target"
import { ITargetsRepository } from "../../repositories/TargetsRepository/interface"
import { IModelElementsRepository } from "../../repositories/ModelElementsRepository/interface"
import { IModelElement } from "../../models/ModelElement/interface"

export enum EWindowSelectStage {
  none = "none",
  select = "select",
  selected = "selected",
  deselect = "deselect"
}

export class SelectedElements {
  constructor(private props: {
    modelElementsRepository: IModelElementsRepository
    bimsRepository: IBimsRepository,
    constructionGroupsRepository: IConstructionGroupsRepository
    bimElementsRepository: IBimElementsRepository
    workTypesRepository: IWorkTypesRepository
    elements: Elements
    targetsRepository: ITargetsRepository
  }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  windowStage: EWindowSelectStage = EWindowSelectStage.none

  @observable
  selectedModelElementsSet = new ObservableSet<IModelElement>()

  @computed
  get count(): number {
    return this.selectedModelElementsSet.size
  }

  @computed
  private get bimsIds() {
    const bimsIds = new Set<IModelElement["bim"]>()

    for (const modelElement of this.selectedModelElementsSet) {
      bimsIds.add(modelElement.bim)
    }

    return bimsIds
  }

  @computed
  get modelsCount(): number {
    return this.bimsIds.size
  }

  @computed
  get selectedBimElements(): (BimElement | undefined)[] {
    return this.getBimElements(this.selectedForgeElements)
  }

  @computed
  get selectedBims() {
    const bims = new Set<Bim>()

    for (const id of this.bimsIds) {
      const bim = this.props.bimsRepository.getById(id)

      if (bim) {
        bims.add(bim)
      }
    }

    return bims
  }

  @computed
  get selectedTargets() {
    const targets = new Set<Target>()

    for (const bim of this.selectedBims) {
      const target = this.props.targetsRepository.getById(bim.target)

      if (target) {
        targets.add(target)
      }
    }

    return targets
  }

  @computed
  get selectedModelElements(): IModelElement[] {
    return Array.from(this.selectedModelElementsSet)
  }

  //legacy
  @computed
  get selectedForgeElements(): IModelElement[] {
    return this.selectedModelElements
  }

  @computed
  get selectedConstructionGroupNames(): (string | undefined)[] {
    return this.getConstructionsGroups(this.selectedForgeElements)
  }

  @computed
  get selectedConstructionGroups(): (ConstructionGroup | undefined)[] {
    return this.props.elements.getConstructionGroupsForForgeElement(this.selectedForgeElements)
  }

  @computed
  get selectedWorkTypes(): (WorkType | undefined)[] {
    const selectedConstructionGroups = this.selectedConstructionGroups
    return this.props.elements.getWorkTypesByForConstructionGroups(selectedConstructionGroups)
  }

  @computed
  get selectedSections(): (string | undefined)[] {
    return this.getSections(this.selectedForgeElements)
  }

  unique<T>(array: T[]): T[] {
    return Array.from((new Set(array)).values())
  }

  getForgeElementSelected(forgeElement: IModelElement): boolean {
    return this.selectedModelElementsSet.has(forgeElement)
  }

  getBimElements(forgeElements: IModelElement[]): (BimElement | undefined)[] {
    const set = new Set<BimElement | undefined>()
    for (const forgeElement of forgeElements) {
      const bim = this.props.bimsRepository.getById(forgeElement.bim)
      if (bim) {
        const element = this.props.bimElementsRepository.getByGuid(bim.id, forgeElement.id)
        set.add(element)
      }
    }

    return Array.from(set.values())
  }

  getForgeElements(guidsMap: Map<TBimProps["id"], Set<TBimElementProps["modelElement"]>>): IModelElement[] {
    const set = new Set<IModelElement>()
    for (const [bimId, guids] of guidsMap.entries()) {
      const bim = this.props.bimsRepository.getById(bimId)
      if (bim) {
        for (const guid of guids) {
          const element = this.props.modelElementsRepository.getById(bim, guid)
          if (element) {
            set.add(element)
          }
        }
      }
    }

    return Array.from(set)
  }

  getConstructionsGroups(forgeElements: IModelElement[]): (string | undefined)[] {
    const set = new Set<string | undefined>()
    for (const forgeElement of forgeElements) {
      if (forgeElement) {
        set.add(forgeElement.constructionGroup)
      }
    }

    return Array.from(set)
  }

  getSections(forgeElements: IModelElement[]): (string | undefined)[] {
    const set = new Set<string | undefined>()
    for (const forgeElement of forgeElements) {
      if (forgeElement) {
        set.add(forgeElement.section)
      }
    }

    return Array.from(set)
  }

  async getSelectedBimElementsAsync(): Promise<(BimElement | undefined)[]> {
    return this.getBimElementsAsync(await this.getSelectedForgeElementsAsync())
  }

  async getSelectedForgeElementsAsync(): Promise<IModelElement[]> {
    return this.selectedForgeElements
  }

  async getBimElementsAsync(forgeElements: IModelElement[]): Promise<(BimElement | undefined)[]> {
    const set = new Set<BimElement | undefined>()
    for (const forgeElement of forgeElements) {
      const bim = await this.props.bimsRepository.getByIdAsync(forgeElement.bim)
      if (bim) {
        const element = await this.props.bimElementsRepository.getByGuidAsync(bim.id, forgeElement.id)
        set.add(element)
      }
    }

    return Array.from(set.values())
  }

  // async getForgeElementsAsync(guidsMap: Map<TBimProps["id"], Set<TBimElementProps["modelElement"]>>): Promise<IModelElement[]> {
  //   const set = new Set<IModelElement>()
  //   for (const [bimId, guids] of guidsMap.entries()) {
  //     const bim = await this.props.bimsRepository.getByIdAsync(bimId)
  //     if (bim) {
  //       for (const guid of guids) {
  //         const element = await this.props.modelElementsRepository.getByIdAsync(bim, guid)
  //         if (element) {
  //           set.add(element)
  //         }
  //       }
  //     }
  //   }

  //   return Array.from(set)
  // }

  @action
  setWindowStage(stage: EWindowSelectStage) {
    this.windowStage = stage
  }

  // @action
  // async setSelectedGuidsMap(guidsMap: Map<TBimProps["id"], ObservableSet<TBimElementProps["modelElement"]>>) {
  //   this.selectedModelElementsSet.clear()
  //   this.selectedModelElementsSet.merge(guidsMap)
  // }

  @action
  clearSelection() {
    this.selectedModelElementsSet.clear()
  }

  @action
  selectModelElements(set: Set<IModelElement>) {
    this.selectedModelElementsSet.replace(set)
  }

  @action
  deselectModelElements(set: Set<IModelElement>) {
    for (const modelElement of set) {
      this.selectedModelElementsSet.delete(modelElement)
    }
  }

  //   function select(element) {
  //     if (element.children.size > 0) {
  //         for (const child of element.children) {
  //             select(child)
  //         }
  //     } else {
  //         layers.usecases.selectedElements.selectedModelElementsSet.add(element)
  //     }

  // }

  @action
  switchModelElements(set: Set<IModelElement>) {
    for (const modelElement of set) {
      if (this.selectedModelElementsSet.has(modelElement)) {
        this.selectedModelElementsSet.delete(modelElement)
      } else {
        this.selectedModelElementsSet.add(modelElement)
      }
    }
  }

  @action
  async selectBimElementsIds(elements: TBimElementProps["id"][]) {
    const set = new Set<IModelElement>()

    for (const elementId of elements) {
      const element = await this.props.bimElementsRepository.getByIdAsync(elementId)
      if (element) {
        const forgeElement = await this.props.elements.getForgeElementByBimElementAsync(element)

        if (forgeElement) {
          set.add(forgeElement)
        }
      }
    }

    this.selectModelElements(set)
  }

  // @action
  // async selectDbIds(props: [TBimProps["id"], IModelElement["modelID"][]][]) {
  //   this.selectedGuidsMap.clear()

  //   for (const [bimID, dbIds] of props) {
  //     const bim = await this.props.bimsRepository.getByIdAsync(bimID)

  //     if (bim) {
  //       const set = new ObservableSet<TBimElementProps["modelElement"]>()

  //       for (const dbId of dbIds) {
  //         const element = await this.props.modelElementsRepository.getByModelIDAsync(bim, dbId)
  //         if (element) {
  //           set.add(element.id)
  //         }
  //       }

  //       this.setSelectedGuidsMapValue(bim.id, set)
  //     }
  //   }
  // }

  // @action
  // private setSelectedGuidsMapValue(id: TBimProps["id"], set: ObservableSet<TBimElementProps["modelElement"]>) {
  //   this.selectedGuidsMap.set(id, set)
  // }

  // async switchDbIds(props: [TBimProps["id"], IModelElement["modelID"][]][]) {
  //   for (const [bimID, dbIds] of props) {
  //     const bim = await this.props.bimsRepository.getByIdAsync(bimID)
  //     if (bim) {
  //       const set = toJS(this.selectedGuidsMap.get(bim.id)) || new ObservableSet()

  //       for (const dbId of dbIds) {
  //         const element = await this.props.modelElementsRepository.getByModelIDAsync(bim, dbId)
  //         if (element) {
  //           if (set.has(element.id)) {
  //             set.delete(element.id)
  //           } else {
  //             set.add(element.id)
  //           }
  //         }
  //       }

  //       this.setSelectedGuidsMapValue(bim.id, set)
  //     }
  //   }
  // }

  // async deSelectDbIds(props: [TBimProps["id"], IModelElement["modelID"][]][]) {
  //   for (const [bimID, dbIds] of props) {
  //     const bim = await this.props.bimsRepository.getByIdAsync(bimID)
  //     if (bim) {
  //       const set = toJS(this.selectedGuidsMap.get(bim.id)) || new ObservableSet()

  //       for (const dbId of dbIds) {
  //         const element = await this.props.modelElementsRepository.getByModelIDAsync(bim, dbId)
  //         if (element) {
  //           set.delete(element.id)
  //         }
  //       }

  //       this.setSelectedGuidsMapValue(bim.id, set)
  //     }
  //   }
  // }
}