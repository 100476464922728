import React, { useCallback, useState } from "react";
import { Divider, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IRightViewerControlPanelViewModel } from "../../view-model/RightViewerControlPanelViewModel/interface"
import SimpleButton from "../../components/Buttons/SimpleButton";
import { DialogWindow } from "../../components/DialogWindow/DialogWindow";

import NewRemarkButton from "../../components/ControlPanel/NewRemarkButton";
import NewStatusButton from "../../components/ControlPanel/NewStatusButton";
import clsx from "clsx";
import { StatusSelector } from "../StatusSelector/StatusSelector";
import { RemarksForm } from "../RemarksForm/RemarksForm";
import { MoreButtonsPanel } from "../MoreButtonsPanel/MoreButtonsPanel";

interface IRightViewerControlPanelProps {
  viewer?: Autodesk.Viewing.Viewer3D
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IRightViewerControlPanelViewModel
}

const useClasses = makeStyles(theme => ({
  root: {

  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: 14,
    padding: 5,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",

    "& > *": {
      margin: 5
    }
  },
  content: {
    width: 198,
    padding: 20
  },
  windowButton: {
    backgroundColor: "#eee"
  },
  dashedIcon: {
    border: "1px dashed",
    borderColor: "#000",
    height: 24,
    width: 24,
    margin: "10px 0",
  },
  dashedIconActive: {
    border: '1px dashed',
    borderColor: theme.palette.primary.main,
  },
  dashedIconDeactive: {
    borderColor: "red",
  },

  statusContent: {
    padding: 30,
    width: 400
  },
  statusHeader: {
    padding: 16,
    fontSize: "22px",
    fontWeight: "bold",
  },
  statusDivider: {
    marginBottom: 20
  }
}))

export const RightViewerControlPanel = observer((props: IRightViewerControlPanelProps) => {
  const {
    viewer,
  } = props;
  const classes = useClasses(props);

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <MoreButton onClick={() => props.viewModel.moreButtonsDialogViewModel.onOpen()} />
        {
          props.viewModel.remarkButtonProps ? <NewRemarkButton {...props.viewModel.remarkButtonProps} /> : null
        }
        {
          props.viewModel.statusButtonProps ? <NewStatusButton {...props.viewModel.statusButtonProps} /> : null
        }
        <SimpleButton className={classes.windowButton} onClick={() => props.viewModel.onClickWindowButton()}>
          <div className={clsx(classes.dashedIcon, {
            [classes.dashedIconActive]: props.viewModel.windowButtonActive,
            [classes.dashedIconDeactive]: props.viewModel.windowButtonDeactive,
          })} />
        </SimpleButton>
      </div>

      <DialogWindow
        {...props.viewModel.moreButtonsDialogViewModel}
      >
        <div className={classes.content}>
          <MoreButtonsPanel viewer={viewer} viewModel={props.viewModel.moreButtonsPanelViewModel} />
        </div>
      </DialogWindow>
      <DialogWindow
        {...props.viewModel.statudDialogProps}
      >
        <div className={classes.statusContent}>
          <div className={classes.statusHeader}>Добавить статус</div>
          <Divider className={classes.statusDivider} />
          <StatusSelector viewModel={props.viewModel.statusSelectorViewModel} />
        </div>
      </DialogWindow>
      <DialogWindow
        {...props.viewModel.remarkDialogProps}
      >
        <div className={classes.statusContent}>
          <div className={classes.statusHeader}>Новое замечание</div>
          <Divider className={classes.statusDivider} />
          <RemarksForm viewModel={props.viewModel.remarkFormViewModel} />
        </div>
      </DialogWindow>
    </div>
  );
})

const useClassesMoreButton = makeStyles(theme => ({
  root: {
    width: 44,
    height: 44,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
}))

function MoreButton({ onClick }) {
  const classes = useClassesMoreButton();

  return (
    <SimpleButton className={classes.root} onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.99994" cy="11.5" r="2" transform="rotate(-180 4.99994 11.5)" fill="white" />
        <circle cx="17.9999" cy="11.5" r="2" transform="rotate(-180 17.9999 11.5)" fill="white" />
        <circle cx="11.4999" cy="11.5" r="2" transform="rotate(-180 11.4999 11.5)" fill="white" />
      </svg>
    </SimpleButton>
  )
}