import React, { useCallback, useMemo, useState } from "react"
import { Checkbox, Dialog, FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core"
import { observer } from "mobx-react-lite"

import PanelDateSelector from "../../components/Selectors/PanelDateSelector";
import SimpleButtonOld from "../../components/Buttons/SimpleButton";
import { SimpleButton } from "../SimpleButton/SimpleButton";
import { IPlanningModeViewModel } from "../../view-model/PlanningModeViewModel/interface";
import clsx from "clsx";
import { StatusWrapper } from "../StatusWrapper/StatusWrapper";

const useClasses = makeStyles(theme => ({
  root: {
  },
  header: {
    gridArea: "header",
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "10px",
  },
  saveButton: {
    width: "100%",
    gridArea: "saveButton",
    backgroundColor: theme.palette.primary.main,
  },
  closeButton: {
    width: "100%",
    marginLeft: 20,
    gridArea: "closeButton",
    backgroundColor: "#F5F5F5",
  },
  padding: {
    padding: "0 26px"
  },
  actions: {
    display: "flex",
    marginTop: 10,
  },
  buttons: {
    display: "flex",
    marginTop: "10px",
  },
  cancelButton: {
    padding: "0 20px",
    marginRight: 20,
    "&:hover": {
      backgroundColor: "grey",
      color: "white"
    }
  },
  confirmButton: {
    padding: "0 20px",
    backgroundColor: "red",
    color: "#fff"
  },
  dialog: {
    padding: "20px",
    borderRadius: "14px",
  },
  radioGroup: {

  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    width: "100%",
    border: `1px solid`,
    borderColor: "#FEC60F",
    minHeight: 44,
    "&:not(:last-child)": {
      marginRight: 20
    },
    // "&:hover": {
    //   // borderColor: "#FEC60F",
    //   boxShadow: "none",
    // },
  },
  save: {
    backgroundColor: "#FEC60F",
  },
  close: {
    backgroundColor: "#F5F5F5",
    borderColor: "#F5F5F5",
  },
  label: {
    fontSize: "14px",
  },
  radio: {
    padding: "10px",
    paddingRight: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  dialogHeader: {
    textAlign: "left",
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "10px",
  },
  description: {
    color: "#AAAAA9",
    margin: "5px 0 10px",
    display: "block",
    fontSize: "12px",
  }
}))

interface ITermsAndVolumesProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPlanningModeViewModel
}

export const PlanningMode = observer((props: ITermsAndVolumesProps) => {
  const classes = useClasses(props)
  const paddingClasses = useMemo(() => ({
    padding: classes.padding
  }), []);
  const [isOnlyWithoutDate, setOnlyWithoutDate] = useState(false)

  return (
    <>
      <div className={classes.root}>
        <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
          <div className={classes.header}>Область выделения</div>
          <PanelDateSelector
            className={paddingClasses}
            label="Дата монтажа"
            value={props.viewModel.selectedDeadline.value}
            placeholder="..."
            onChange={values => props.viewModel.selectedDeadline.onChange(values)}
          />
          <div className={classes.actions}>
            <SimpleButton
              viewModel={props.viewModel.savePlannedStatusButtonViewModel}
              className={classes.saveButton}
            >Сохранить</SimpleButton>
            <SimpleButtonOld
              className={classes.closeButton}
              // onClick={() => props.viewModel.onClose()}
              onClick={() => props.viewModel.onClearSelectedElements()}
            >
              Закрыть
            </SimpleButtonOld>
          </div>
        </StatusWrapper>
      </div>
      <Dialog
        open={props.viewModel.differentDate}
        onClose={() => props.viewModel.setDifferentDate(false)}
        PaperProps={{
          style: {
            borderRadius: "14px",
          },
        }} >
        <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
          <div className={classes.dialog}>
            <div className={classes.dialogHeader}>Выбраны элементы с разной плановой датой</div>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              className={classes.radioGroup}

            >
              <FormControlLabel value="female" onClick={() => setOnlyWithoutDate(true)} control={<Radio color="primary" className={classes.radio} />} label={<span className={classes.label}>Назначить срок только на элементы без плановой даты</span>} />
              <FormControlLabel value="male" onClick={() => setOnlyWithoutDate(false)} control={<Radio color="primary" className={classes.radio} />} label={<span className={classes.label}>Обновить дату на элементах с назначенной датой планирования</span>} />
            </RadioGroup>
            <div className={classes.description}>
              Для редактирования выбора элементов нажмите кнопку “Отменить”
            </div>
            <div className={classes.buttons}>
              <SimpleButtonOld className={clsx(classes.button, classes.save)} onClick={() => {
                props.viewModel.savePlannedStatus(isOnlyWithoutDate)
                props.viewModel.setDifferentDate(false)
              }}>
                Сохранить
              </SimpleButtonOld>
              <SimpleButtonOld className={clsx(classes.button, classes.close)} onClick={() => props.viewModel.setDifferentDate(false)}>
                Отменить
              </SimpleButtonOld>
            </div>
          </div>
        </StatusWrapper>

      </Dialog>
    </>
  )
})