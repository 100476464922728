import { computed, makeObservable } from "mobx";
import { BimElement } from "../../models/BimElement";
import { Material } from "../../models/Material";
import { IModelElement } from "../../models/ModelElement/interface";
import {
  StatusDictionary,
  TStatusDictionaryProps,
} from "../../models/StatusDictionary";
import {
  StatusHistory,
  TNewStatusHistoryProps,
} from "../../models/StatusHistory";
import { TUploadFileProps } from "../../models/UploadFile";
import { WorkType } from "../../models/WorkType";
import { IAuthRepository } from "../../repositories/AuthRepository/interface";
import { IBimElementsRepository } from "../../repositories/BimElementsRepository/interface";
import { IStatusDictionaryRepository } from "../../repositories/StatusDictionaryRepository/interface";
import { IStatusHistoryRepository } from "../../repositories/StatusHistoryRepository/interface";
import { Bims } from "../Bims/Bims";
import { Elements } from "../Elements/Elements";
import { SelectedElements } from "../SelectedElements/SelectedElements";
import { ViewState } from "../ViewState/ViewState";

export class Statuses {
  constructor(
    private props: {
      selectedElements: SelectedElements;
      statusDictionaryRepository: IStatusDictionaryRepository;
      statusHistoryRepository: IStatusHistoryRepository;
      bimElementsRepository: IBimElementsRepository;
      elements: Elements;
      viewState: ViewState;
      authRepository: IAuthRepository;
    }
  ) {
    this.props = props;

    makeObservable(this);
  }

  @computed
  get canAssignStatusByCount() {
    return this.getCanAssignStatusByCountForForgeElements(
      this.props.selectedElements.selectedForgeElements
    );
  }

  @computed
  get canAssignStatusByStatusHistory() {
    return this.getCanAssignStatusByStatusHistoryForForgeElements(
      this.props.selectedElements.selectedForgeElements
    );
  }

  @computed
  get selectedActiveStatusHistories() {
    return this.getActiveStatusHistoriesForBimElements(
      this.props.selectedElements.selectedBimElements
    );
  }

  @computed
  get selectedStatusDictionary() {
    return this.getStatusDictionaryForBimElements(
      this.props.selectedElements.selectedBimElements
    );
  }

  @computed
  get canAssignStatusByConstructionGroups() {
    return this.getCanAssignStatusByConstructionGroupsForForgeElements(
      this.props.selectedElements.selectedForgeElements
    );
  }

  @computed
  get canAssignStatusByMaterials() {
    return this.getCanAssignStatusByMaterialsForForgeElements(
      this.props.selectedElements.selectedForgeElements
    );
  }

  @computed
  get activeStatusDictionary() {
    return this.selectedStatusDictionary[0];
  }

  // methods
  getCanAssignStatusByCountForForgeElements(forgeElements: IModelElement[]) {
    return forgeElements.length > 0;
  }

  getCanAssignStatusByMaterialsForForgeElements(
    forgeElements: IModelElement[]
  ) {
    if (forgeElements.length === 0) return;
    const firstForgeElement = forgeElements[0];

    return forgeElements.every(
      (forgeElement) => forgeElement.material === firstForgeElement.material
    );
  }

  getCanAssignStatusByConstructionGroupsForForgeElements(
    forgeElements: IModelElement[]
  ) {
    if (forgeElements.length === 0) return;
    const firstForgeElement = forgeElements[0];

    return forgeElements.every(
      (forgeElement) =>
        forgeElement.constructionGroup === firstForgeElement.constructionGroup
    );
  }

  getCanAssignStatusByStatusHistoryForForgeElements(
    forgeElements: IModelElement[]
  ) {
    const bimElements = this.props.elements.getBimElements(forgeElements);
    const statusDictionaries =
      this.getStatusDictionaryForBimElements(bimElements);
    return statusDictionaries.length === 1;
  }

  getActiveStatusHistoriesForBimElements(
    bimElements: (BimElement | undefined)[]
  ): (StatusHistory | undefined)[] {
    const set = new Set<StatusHistory | undefined>();

    for (const bimElement of bimElements) {
      if (bimElement) {
        const status =
          this.props.statusHistoryRepository.getActualByBimElement(bimElement);
        set.add(status);
      } else {
        set.add(undefined);
      }
    }

    return Array.from(set);
  }

  getStatusDictionaryFromStatusHistory(
    statusHistories: (StatusHistory | undefined)[]
  ): (StatusDictionary | undefined)[] {
    const set = new Set<StatusDictionary | undefined>();
    for (const statusHistory of statusHistories) {
      if (statusHistory) {
        const statusDictionary = this.props.statusDictionaryRepository.getById(
          statusHistory.status
        );
        set.add(statusDictionary);
      } else {
        set.add(undefined);
      }
    }

    return Array.from(set);
  }

  getStatusDictionaryForBimElements(
    bimElements: (BimElement | undefined)[]
  ): (StatusDictionary | undefined)[] {
    const selectedActiveStatusHistory =
      this.getActiveStatusHistoriesForBimElements(bimElements);
    return this.getStatusDictionaryFromStatusHistory(
      selectedActiveStatusHistory
    );
  }

  getStatusDictionaryForForgeElement(
    element: IModelElement
  ): StatusDictionary[] {
    const constructionGroups =
      this.props.elements.getConstructionGroupsForForgeElement([element]);
    const workTypes =
      this.props.elements.getWorkTypesByForConstructionGroups(
        constructionGroups
      );
    const statusDictionaries = this.getStatusDictionaryForWorkTypes(workTypes);
    const materials = this.props.elements.getForgeElementMaterials(element);

    return this.getStatusDictionariesForMaterials(
      statusDictionaries,
      materials
    );
  }

  async getStatusDictionaryForForgeElementAsync(
    element: IModelElement
  ): Promise<StatusDictionary[]> {
    const constructionGroups =
      await this.props.elements.getConstructionGroupsForForgeElementAsync([
        element,
      ]);
    const workTypes =
      await this.props.elements.getWorkTypesByForConstructionGroupsAsync(
        constructionGroups
      );
    const statusDictionaries = await this.getStatusDictionaryForWorkTypesAsync(
      workTypes
    );
    const materials = await this.props.elements.getForgeElementMaterialsAsync(
      element
    );

    return this.getStatusDictionariesForMaterials(
      statusDictionaries,
      materials
    );
  }

  getStatusDictionariesForMaterials(
    statusDictionaries: StatusDictionary[],
    materials: Material[]
  ): StatusDictionary[] {
    if (materials.length === 0)
      return this.filterStatusDictionariesWithEmptyMaterials(
        statusDictionaries
      );

    const materialStatusDictionaries = this.groupStatusDictionariesByMaterials(
      statusDictionaries,
      materials
    );

    // поддержка старых моделей
    if (materialStatusDictionaries.length === 0)
      return this.filterStatusDictionariesWithEmptyMaterials(
        statusDictionaries
      );

    return materialStatusDictionaries;
  }

  filterStatusDictionariesWithEmptyMaterials(
    statusDictionaries: StatusDictionary[]
  ) {
    return statusDictionaries.filter(
      (statusDictionary) => statusDictionary.materials.length === 0
    );
  }

  groupStatusDictionariesByMaterials(
    statusDictionaries: StatusDictionary[],
    materials: Material[]
  ) {
    let result: StatusDictionary[] = [];

    for (const material of materials) {
      const materialStatusDictionaries = statusDictionaries.filter(
        (statusDictionary) => statusDictionary.hasMaterial(material.id)
      );

      if (materialStatusDictionaries.length > 0) {
        result = [...result, ...materialStatusDictionaries];
      }
    }

    return result;
  }

  getStatusDictionaryForWorkTypes(
    workTypes: (WorkType | undefined)[]
  ): StatusDictionary[] {
    let statusDictionaries: StatusDictionary[] = [];

    for (const workType of workTypes) {
      if (workType) {
        statusDictionaries = [
          ...statusDictionaries,
          ...this.getStatusDictionaryForWorkType(workType),
        ];
      }
    }

    return statusDictionaries;
  }

  async getStatusDictionaryForWorkTypesAsync(
    workTypes: (WorkType | undefined)[]
  ): Promise<StatusDictionary[]> {
    let statusDictionaries: StatusDictionary[] = [];

    for (const workType of workTypes) {
      if (workType) {
        statusDictionaries = [
          ...statusDictionaries,
          ...(await this.getStatusDictionaryForWorkTypeAsync(workType)),
        ];
      }
    }

    return statusDictionaries;
  }

  getStatusDictionaryForWorkType(workType: WorkType): StatusDictionary[] {
    const statusDictionaries =
      this.props.statusDictionaryRepository.getByWorkType(workType.id);

    statusDictionaries.sort((a, b) => a.order - b.order);

    return statusDictionaries;
  }

  async getStatusDictionaryForWorkTypeAsync(
    workType: WorkType
  ): Promise<StatusDictionary[]> {
    const statusDictionaries =
      await this.props.statusDictionaryRepository.getByWorkTypeAsync(
        workType.id
      );

    statusDictionaries.sort((a, b) => a.order - b.order);

    return statusDictionaries;
  }

  getBimElementCompleted(bimElement: BimElement): boolean {
    const activeStatus =
      this.props.statusHistoryRepository.getActualByBimElement(bimElement);
    if (!activeStatus) return false;

    const forgeElement =
      this.props.elements.getForgeElementByBimElement(bimElement);
    if (!forgeElement) return false;

    const statusDictionary =
      this.getStatusDictionaryForForgeElement(forgeElement);

    return this.checkStatusHistoryIsLast(activeStatus, statusDictionary);
  }

  checkStatusHistoryIsLast(
    statusHistory: StatusHistory,
    statusDictionary: StatusDictionary[]
  ) {
    if (statusHistory.statusIndex === null)
      return (
        statusDictionary[statusDictionary.length - 1]?.id ===
        statusHistory?.status
      );

    const statusDictionaryByIndex = statusDictionary[statusHistory.statusIndex];

    if (statusDictionaryByIndex.id === statusHistory.status) {
      return statusHistory.statusIndex === statusDictionary.length - 1;
    } else {
      this.statusAndStatusHistoryNotEqualByIndexLog(
        statusDictionaryByIndex,
        statusHistory
      );

      return (
        statusDictionary[statusDictionary.length - 1]?.id === statusHistory?.id
      );
    }
  }

  statusAndStatusHistoryNotEqualByIndexLog(
    statusDictionary: StatusDictionary,
    statusHistory: StatusHistory
  ) {
    console.log(
      `status id=${statusDictionary.id} не сходится с status history status=${statusHistory.status}`
    );
  }

  async getBimElementCompletedAsync(bimElement: BimElement): Promise<boolean> {
    const activeStatus =
      await this.props.statusHistoryRepository.getActualByBimElementAsync(
        bimElement
      );
    if (!activeStatus) return false;

    const forgeElement =
      await this.props.elements.getForgeElementByBimElementAsync(bimElement);
    if (!forgeElement) return false;

    const statusDictionary = await this.getStatusDictionaryForForgeElementAsync(
      forgeElement
    );

    return this.checkStatusHistoryIsLast(activeStatus, statusDictionary);
  }

  getBimElementInProgress(bimElement: BimElement): boolean {
    const activeStatus =
      this.props.statusHistoryRepository.getActualByBimElement(bimElement);
    if (!activeStatus) return false;

    return !this.getBimElementCompleted(bimElement);
  }

  async getBimElementInProgressAsync(bimElement: BimElement): Promise<boolean> {
    const activeStatus =
      await this.props.statusHistoryRepository.getActualByBimElementAsync(
        bimElement
      );
    if (!activeStatus) return false;

    const completed = await this.getBimElementCompletedAsync(bimElement);
    return !completed;
  }

  getBimElementNotStarted(bimElement: BimElement): boolean {
    const activeStatus =
      this.props.statusHistoryRepository.getActualByBimElement(bimElement);
    if (activeStatus) return false;

    return true;
  }

  async getBimElementNotStartedAsync(bimElement: BimElement): Promise<boolean> {
    const activeStatus =
      await this.props.statusHistoryRepository.getActualByBimElementAsync(
        bimElement
      );
    if (activeStatus) return false;

    return true;
  }

  // actions
  async applyStatus(props: {
    comment: string;
    files: TUploadFileProps["id"][];
    status: TStatusDictionaryProps["id"];
    statusIndex: number | null;
  }) {
    const owner = this.props.authRepository.getUser();
    if (owner) {
      const elements = this.props.selectedElements.selectedModelElements;
      const bimElements = await this.props.elements.getOrCreateBimElements(
        elements
      );

      const newStatuses: TNewStatusHistoryProps[] = bimElements.map(
        (element) => ({
          comment: props.comment,
          files: props.files,
          status: props.status,
          element: element.id,
          owner: owner.id,
          statusIndex: props.statusIndex,
        })
      );

      const statuses =
        await this.props.statusHistoryRepository.applyStatusBatch(newStatuses);

      if (this.props.viewState.props.applyStatusDialogActive) {
        this.props.viewState.setApplyStatusDialogActive(false);
      }
    }
  }
}
