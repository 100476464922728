import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { TTargetProps } from "../../models/Target";
import { IStatusWrapperViewModel } from "../StatusWrapperViewModel/interface";
import { StatusWrapperForPromiseViewModel } from "../StatusWrapperViewModel/StatusWrapperForPromiseViewModel";
import { IPrescriptionsTableViewModel } from "./interface";

export class PrescriptionsTableViewModel implements IPrescriptionsTableViewModel {
  constructor(private props: { layers: ILayers, targetId: TTargetProps["id"] }) {
    this.props = props

    this.statusWrapperViewModel = new StatusWrapperForPromiseViewModel({
      promise: this.props.layers.usecases.filtredPrescriptionsTable.getFiltredPrescriptionsByTargetIdPromise(this.props.targetId)
    })

    makeObservable(this)
  }

  statusWrapperViewModel: IStatusWrapperViewModel

  @computed
  get prescriptions(): IPrescriptionsTableViewModel["prescriptions"] {
    return this.props.layers.usecases.filtredPrescriptionsTable.getFiltredPrescriptionsByTargetId(this.props.targetId)
  }
}