import { CSSProperties, MouseEvent } from "react";

export interface IPanelDropdownMenuViewModel {
  opened: boolean
  menuRendered: boolean

  orientation: EOrientations

  optionsStyle?: CSSProperties
  menuWrapperStyle?: CSSProperties

  onClick(e: MouseEvent): void
  onRootMount(root: HTMLDivElement): void

  options: TOption[]
  groups: TGroup[]
  isEmpty: boolean

  unmount?(): void
}

export enum EOrientations {
  bottom = "bottom",
  left = "left",
  top = "top",
  right = "right"
}

export type TOption = {
  key: string
  label: string
  selected: boolean

  onClick: () => void

  disabled?: boolean
}

export type TGroup = {
  key: string
  name: string
  selected: boolean
  options: TOption[]
  onClick: () => void
}