import React from "react";
import { makeStyles, Typography, IconButton } from "@material-ui/core";
import clsx from 'clsx';

const useClasses = makeStyles(theme => ({
	cardFilter: {
		marginRight: "15px",
		background: "#fff",
		borderRadius: 14,
		boxShadow: " 0px 4px 20px rgba(0, 0, 0, 0.05)"
	},
	cardFilterOpen: {
		background: "#fdc611",
	},
	tabsFilter: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		padding: "8px",
		color: "#000000",
		background: "transparent",
		cursor: "pointer"
	},
	titleFilter: {
		margin: "0 5px",
		fontSize: "12px",
		fontWeight: 600,
	},
	filterCount: {
		width: "24px",
		height: "24px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "#000",
		background: "#ECEBE9",
		borderRadius: "50px",
		fontSize: "12px",
		fontWeight: 600,
		margin: "0 5px",
	},
	filterCountOpen: {
		background: "#000",
		color: "#fff",
	},
	button: {
		padding: "0"
	}
}));

const FilterIcon = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M16.5 2.25H1.5L7.5 9.345V14.25L10.5 15.75V9.345L16.5 2.25Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
)

const CloseIcon = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.5 4.5L4.5 13.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M4.5 4.5L13.5 13.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
)


const FilterCard = ({ setFilterPanel, isOpenFilterPanel, filterCount, сlearFilterRemarks }) => {
	const classes = useClasses();

	const filterStyle = clsx({
		[classes.cardFilter]: true,
		[classes.cardFilterOpen]: isOpenFilterPanel,
	});

	const filterCountStyle = clsx({
		[classes.filterCount]: true,
		[classes.filterCountOpen]: isOpenFilterPanel,
	});

	return (
		<div className={filterStyle}>
			<div
				className={classes.tabsFilter}
				onClick={setFilterPanel}
			>
				<FilterIcon />
				<Typography variant="h5" component="h5" className={classes.titleFilter}>
					Фильтр
				</Typography>
				{filterCount > 0 &&
					<>
						<div className={filterCountStyle}>
							{filterCount}
						</div>
						<IconButton
							className={classes.button}
							onClick={(e) => {
								e.stopPropagation(e)
								сlearFilterRemarks()
							}}>
							<CloseIcon />
						</IconButton>
					</>
				}
			</div>
		</div>
	);
}

export default FilterCard;