import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EMenuOptionsCodes, IHomeHeaderViewModel } from "./interface";
import packageJSON from "../../../package.json";

export class HomeHeaderViewModel implements IHomeHeaderViewModel {
    constructor(private props: { layers: ILayers }) {
        this.props = props

        makeObservable(this)
    }

    @computed
    get searchText() {
        return this.props.layers.usecases.viewState.props.mainPageSearch
    }

    @computed
    get menuLabel() {
        return this.props.layers.repositories.authRepository.getUser()?.fullName || "?"
    }

    private async update() {
        const cacheKeys = await window.caches.keys();
        for (const cacheKey of cacheKeys) {
            window.caches.delete(cacheKey);
        }
        window.location.reload();
    }

    get menuOptions() {
        return [
            {
                key: "version",
                code: EMenuOptionsCodes.version,
                name: `версия ${packageJSON.version}`
            },
            {
                key: "update",
                name: "Обновить",
                onClick: () => this.update()
            },
            {
                key: "logout",
                name: "Выйти",
                onClick: () => this.props.layers.usecases.auth.logout()
            }
        ]
    }

    onChangeSearchText(v: string) {
        this.props.layers.usecases.viewState.setMainPageSearch(v)
    }
}