import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EBimType } from "../../models/Bim";
import { CheckboxWithLabelViewModelOverSimpleViewModel } from "../CheckboxViewModel/CheckboxWithLabelViewModelOverSimpleViewModel";
import { ISettingsPageViewModel } from "./interface";

export class SettingsPageViewModel implements ISettingsPageViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get isForge() {
    return this.props.layers.usecases.bims.selectedBimsType === EBimType.forge
  }

  @computed
  get inversionCheckboxViewModel() {
    if (!this.isForge) return
    return new CheckboxWithLabelViewModelOverSimpleViewModel({
      simpleViewModel: this.props.layers.usecases.settings.inversion,
      label: "Инверсия направления масштабирования"
    })
  }

  @computed
  get transparentHidingCheckboxViewModel() {
    if (!this.isForge) return
    return new CheckboxWithLabelViewModelOverSimpleViewModel({
      simpleViewModel: this.props.layers.usecases.settings.transparentHiding,
      label: "Скрытые элементы прозрачны"
    })
  }

  @computed
  get hideAdditionalModelsCheckboxViewModel() {
    return new CheckboxWithLabelViewModelOverSimpleViewModel({
      simpleViewModel: this.props.layers.usecases.settings.hideAdditionalModels,
      label: "Скрыть дополнительные модели"
    })
  }

  onBack = () => {
    this.props.layers.usecases.viewState.setPanelOpened(false)
  }
}