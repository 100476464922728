import { isAfter } from "date-fns"
import { action, computed, makeObservable, observable } from "mobx"

export type TCache<T> = {
  data?: T
  promise?: Promise<T>
  loading: boolean
}

export class SimpleCache<T> {
  @observable
  data?: T = undefined

  constructor(private props: { promise: Promise<T>, expiredDate?: Date }) {
    this.props = props

    makeObservable(this)

    this.init(props.promise)
  }

  private async init(promise: Promise<T>) {
    const data = await promise
    this.setData(data)
  }

  @action
  private setData(data: T) {
    this.data = data
  }

  get isExpired(): boolean {
    if (!this.props.expiredDate) return false
    return isAfter(new Date(), this.props.expiredDate)
  }

  get promise() {
    return this.props.promise
  }

  @computed
  get loading() {
    return this.data === undefined
  }
}