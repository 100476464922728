import axios from "axios";
import { computed, makeObservable } from "mobx";
import { TCollectionProps } from "../../models/Collection";
import { EDeadlineStatus, ERemarkStatus } from "../../models/Remark";
import { EUnits } from "../../models/Units";
import { EWorkStatus } from "../../models/WorkStatus";
import { TWorkTypeGroupProps } from "../../models/WorkTypeGroup";
import { IAuthRepository } from "../AuthRepository/interface";
import { IStatsRepository } from "./interface";

export class RestStatsRepository implements IStatsRepository {
  constructor(private props: {
    host: string
    authRepository: IAuthRepository
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get remarkStatsUrl() {
    return `${this.props.host}api/main/bim-remark-stats/`
  }

  @computed
  get planFactStatsUrl() {
    return `${this.props.host}api/main/plan-fact-stats/`
  }

  @computed
  get bimElementStatsV2() {
    return `${this.props.host}api/main/bim-element-stats-v2/`
  }

  @computed
  get headers() {
    return {
      ...this.props.authRepository.getAuthHeaders(),
      "content-type": "application/json"
    }
  }

  async getRemarkCountForCollectionsGroupByElementStatusAsync(props: {
    collections: TCollectionProps["id"][]
    lte?: Date
    status?: ERemarkStatus[]
  }) {
    const response = await axios.get<{ count: { [key: string]: number, "uncertain": number } }>(this.remarkStatsUrl, {
      headers: this.headers,
      params: {
        groupby: "element_status",
        "aggregate[count]": "id",
        bim_group: props.collections.join(","),
        status: props.status?.join(","),
        actual_time__lte: props.lte ? props.lte.toISOString() : undefined
      }
    })

    const data: [number | "uncertain", number][] = Object.entries(response.data.count).map(([key, count]) => key === "uncertain" ? ([key, count]) : ([parseInt(key), count]))

    return data
  }

  async getRemarkCountForCollectionsGroupByStatusAsync(props: {
    collections: TCollectionProps["id"][]
    lte?: Date
  }) {
    const response = await axios.get<{ count: { [key: string]: number } }>(this.remarkStatsUrl, {
      headers: this.headers,
      params: {
        groupby: "status",
        "aggregate[count]": "id",
        bim_group: props.collections.join(","),
        actual_time__lte: props.lte ? props.lte.toISOString() : undefined
      }
    })

    //@ts-ignore
    const data: [ERemarkStatus, number][] = Object.entries(response.data.count)

    return data
  }

  async getRemarkCountForCollectionsGroupByDeadlineStatusAsync(props: {
    collections: TCollectionProps["id"][]
    lte?: Date
  }) {
    const response = await axios.get<{ count: { [key: string]: number } }>(this.remarkStatsUrl, {
      headers: this.headers,
      params: {
        groupby: "deadline_status",
        "aggregate[count]": "id",
        bim_group: props.collections.join(","),
        actual_time__lte: props.lte ? props.lte.toISOString() : undefined
      }
    })

    //@ts-ignore
    const data: [EDeadlineStatus, number][] = Object.entries(response.data.count)

    return data
  }

  async getRemarkCountForCollectionsGroupByCollectionAsync(props: {
    collections: TCollectionProps["id"][]
    lte?: Date
    status?: ERemarkStatus[]
  }) {
    const response = await axios.get<{ count: { [key: string]: number } }>(this.remarkStatsUrl, {
      headers: this.headers,
      params: {
        groupby: "bim_group",
        "aggregate[count]": "id",
        bim_group: props.collections.join(","),
        status: props.status?.join(","),
        actual_time__lte: props.lte ? props.lte.toISOString() : undefined
      }
    })

    const data: [TCollectionProps["id"], number][] = Object.entries(response.data.count).map(([stringId, count]) => ([parseInt(stringId), count]))

    return data
  }

  async getPlanFactForCollectionsGroupByGroupWorkTypeAsync(props: {
    collections?: TCollectionProps["id"][]
    lte?: Date
  }) {
    const response = await axios.get<{
      id: number
      name: string
      unit: EUnits
      plan: { time: string, value: number }[]
      fact: { time: string, value: number }[]
    }[]>(this.planFactStatsUrl, {
      headers: this.headers,
      params: {
        bim_group: props.collections?.join(","),
        actual_time__lte: props.lte ? props.lte.toISOString() : undefined
      }
    })

    const data: {
      id: TWorkTypeGroupProps["id"]
      values: { plan: [Date, number][], fact: [Date, number][] }
    }[] = response.data.map(row => ({
      id: row.id,
      values: {
        plan: row.plan.map(({ time, value }) => ([new Date(time), value])),
        fact: row.plan.map(({ time, value }) => ([new Date(time), value])),
      }
    }))

    return data
  }

  async getBimElementsStatsForCollectionsGroupByGroupWorkTypeAsync(props: {
    collections?: TCollectionProps["id"][]
    lte?: Date
  }) {
    const response = await axios.get<{
      id: number
      name: string
      unit: EUnits
      data: {
        value: {
          completed?: number
          in_progress?: number
          not_started?: number
        }
      } | string
    }[]>(this.bimElementStatsV2, {
      headers: this.headers,
      params: {
        bim_group: props.collections?.join(","),
        actual_time__lte: props.lte ? props.lte.toISOString() : undefined
      }
    })

    const data: { id: TWorkTypeGroupProps["id"], values: [EWorkStatus, number][] }[] = response.data.map(row => {
      const data = typeof row.data === "string" ? JSON.parse(row.data) as {
        value: {
          completed?: number
          in_progress?: number
          not_started?: number
        }
      } : row.data

      return {
        id: row.id,
        values: [
          [EWorkStatus.completed, data.value.completed || 0],
          [EWorkStatus.inProgress, data.value.in_progress || 0],
          [EWorkStatus.notStarted, data.value.not_started || 0],
        ]
      }
    })

    return data
  }
}