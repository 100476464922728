import { action, makeObservable, observable } from "mobx";
import { TBimProps } from "../../models/Bim";
import { TCollectionProps } from "../../models/Collection";
import { EHomeSectionCodes } from "../../models/HomeSectionCodes";
import { EPages } from "../../models/Pages";
import { TPrescriptionProps } from "../../models/Prescription";
import { TRemarkProps } from "../../models/Remark";
import { EPanelSection, TViewStateProps } from "../../models/ViewState";
import { EActions, Permissions } from "../Permissions/Permissions";

const defaultState: TViewStateProps = {
    selectedProjectId: undefined,
    mainPageSearch: "",
    mainPageSection: EHomeSectionCodes.collections,
    selectedBims: undefined,
    selectedPage: EPages.main,
    selectedRemakrs: undefined,
    selectedPrescriptions: undefined,
    panelSection: EPanelSection.storeys,
    panelOpened: false,
    shortPanelActive: false,
    addRemarkMode: false,
    applyStatusDialogActive: false,
    addRemarkDialogActive: false,
    selectedCollection: undefined,
    isOpenFilterPanel: false,
    editRemarkMode: false
}
export class ViewState {
    @observable
    private _props: TViewStateProps = { ...defaultState }
    private permissions: Permissions

    constructor(props: {
        permissions: Permissions
    }) {
        this.permissions = props.permissions

        makeObservable(this)
    }

    get props() {
        return this._props
    }

    // поиск на главной странице
    @action
    setMainPageSearch(v: string) {
        this.props.mainPageSearch = v
    }

    // выбрать проект
    @action
    setSelectedProjectId(v?: number | string) {
        this.props.selectedProjectId = v

        // сброс поиска при смене выбранного проекта
        this.setMainPageSearch("")
    }

    // выбранный раздел на главной странице
    @action
    setMainPageSelectedSection(v: EHomeSectionCodes) {
        this.props.mainPageSection = v
    }

    // выбрать модели для отображения
    @action
    setSelectedBims(bims?: TBimProps["id"][]) {
        this.props.selectedBims = bims
    }

    // выбрать модели для отображения
    @action
    setSelectedPage(page: EPages) {
        if (page === EPages.viewer && this.permissions.actionIsBlocked(EActions.showModels)) return

        this.props.selectedPage = page
        if (page === EPages.main) {
            this.setSelectedBims()
            this.setSelectedRemarks()
            this.setSelectedPrescriptions()
            this.setSelectedCollection()
        }
    }

    // выбрать замечания
    @action
    setSelectedRemarks(remarks?: TRemarkProps["id"][]) {
        this.props.selectedRemakrs = remarks
    }

    // выбрать предписания
    @action
    setSelectedPrescriptions(prescriptions?: TPrescriptionProps["id"][]) {
        this.props.selectedPrescriptions = prescriptions
    }

    // сброс до дефолтных значений
    @action
    resetState() {
        this._props = { ...defaultState }
    }

    // открыть/закрыть левую панель
    @action
    setPanelOpened(opened: boolean) {
        this.props.panelOpened = opened
    }

    @action
    setShortPanelActive(active: boolean) {
        this.props.shortPanelActive = active
    }

    @action
    setAddRemarkMode(value: boolean) {
        this.props.addRemarkMode = value
    }

    @action
    setApplyStatusDialogActive(value: boolean) {
        if (value) {
            this.setPanelOpened(false)
        }
        this.props.applyStatusDialogActive = value
    }

    @action
    setAddRemarkDialogActive(value: boolean) {
        if (value) {
            this.setPanelOpened(false)
        }
        this.props.addRemarkDialogActive = value
    }

    @action
    setSelectedCollection(value?: TCollectionProps["id"]) {
        this.props.selectedCollection = value
    }

    // выбрать раздел левой панели
    @action
    setPanelSection(section: EPanelSection) {
        this.props.panelSection = section;
    }

    // открыть или закрыть панель с флитрами
    @action
    switchFilterPanel() {
        this.props.isOpenFilterPanel = !this.props.isOpenFilterPanel;
    }

    @action
    setEditRemarkMode(value: boolean) {
        this.props.editRemarkMode = value
    }
}
