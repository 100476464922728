import { TBaseModel } from "./BaseModel";
import { Entity } from "./Entity";
import { EUnits } from "./Units";

export type TWorkTypeGroupProps = {
  name: string
  unit: EUnits
} & TBaseModel

export class WorkTypeGroup extends Entity<TWorkTypeGroupProps & { other?: boolean }> {
  get name() {
    return this.props.name
  }

  get unit() {
    return this.props.unit
  }

  get other(): boolean {
    return this.props.other || false
  }
}