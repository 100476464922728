import { TBaseModel } from "../../models/BaseModel";
import { ModelWorkType } from "../../models/ModelWorkType";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { IModelWorkTypeRepository } from "./interface";

type TRestProps = {
  name: string
} & TBaseModel

export class RestModelWorkTypeRepository implements IModelWorkTypeRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.offlineRepository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: `${props.host}api/main/model-work-type/`,
      Model: ModelWorkType,
    })
  }

  offlineRepository: RestOfflineReadOnlyRepository<ModelWorkType, TRestProps>

  getAll() {
    return this.offlineRepository.getAll()
  }

  getById(id) {
    this.getAll()

    return this.offlineRepository.models.get(id)
  }

  getAllAsync() {
    return this.offlineRepository.getAllAsync()
  }
}