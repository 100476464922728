import axios from "axios";
import { add } from "date-fns";
import { makeObservable, observable } from "mobx";
import { SimpleCache } from "../../models/Cache";
import { IForgeTokenRepository } from "./interface";

export class RemoteForgeTokenRepository implements IForgeTokenRepository {
  @observable
  private tokenCache?: SimpleCache<string> = undefined

  constructor(private props: { host: string }) {
    this.props = props

    makeObservable(this)
  }

  private get path() {
    return `${this.props.host}api/forge-api-token/`
  }

  private loadToken() {
    this.tokenCache = new SimpleCache({
      promise: new Promise(async res => {
        try {
          const response = await axios.get<{ access_token: string }>(this.path)
          res(response.data.access_token)
        } catch (error) {
          res("offline")
        }
      }),
      expiredDate: add(new Date(), { minutes: 10 })
    })

    return this.tokenCache.promise
  }

  private async updateToken() {
    if (this.tokenCache && !this.tokenCache.isExpired) return this.tokenCache.promise

    return this.loadToken()
  }

  getTokenAsync() {
    return this.tokenCache?.promise || this.updateToken()
  }

  getToken() {
    this.updateToken()

    return this.tokenCache?.data || ""
  }
}