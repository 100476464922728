import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { numberFormat } from "../../heplpers/formats";
import { EWindowSelectStage } from "../../usecase/SelectedElements/SelectedElements";
import { PlanningModeViewModel } from "../PlanningModeViewModel/PlanningModeViewModel";
import { RightViewerControlPanelViewModel } from "../RightViewerControlPanelViewModel/RightViewerControlPanelViewModel";
import { SelectionFilterViewModel } from "../SelectionFilterViewModel/SelectionFilterViewModel";
import { ElementsPropertyShortPanelViewModel } from "../ElementsPropertyShortPanelViewModel/ElementsPropertyShortPanelViewModel";
import { IRightViewerPanelViewModel } from "./interface";
import { IElementsPropertyShortPanelViewModel } from "../ElementsPropertyShortPanelViewModel/interface";
import { ElementsStatusPropertyShortPanelViewModel } from "../ElementsPropertyShortPanelViewModel/ElementsStatusPropertyShortPanelViewModel";

export class RightViewerPanelViewModel implements IRightViewerPanelViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props
    makeObservable(this)
  }

  @computed
  get rightViewerControlPanelViewModel() {
    return new RightViewerControlPanelViewModel(this.props)
  }

  @computed
  get showSelectionFilter() {
    return this.props.layers.usecases.selectedElements.windowStage === EWindowSelectStage.selected &&
      !this.props.layers.usecases.planningMode.active.value
  }

  @computed
  get showSelectionPlanningMode() {
    if (!this.props.layers.usecases.planningMode.allowedSetPlanningDate) return false

    return this.props.layers.usecases.selectedElements.selectedBimElements.length > 0 &&
      this.props.layers.usecases.planningMode.active.value
  }

  @computed
  get selectionFilterViewModel() {
    return new SelectionFilterViewModel(this.props)
  }

  @computed
  get elementsPropertyShortPanelViewModels() {
    const viewModels: { key: string, viewModel: IElementsPropertyShortPanelViewModel }[] = []

    if (this.props.layers.usecases.selectedElements.selectedForgeElements.length > 0) {
      if (this.props.layers.usecases.remarks.remarkEditing || this.props.layers.usecases.viewState.props.addRemarkMode) {
        viewModels.push({
          key: "2",
          viewModel: new ElementsStatusPropertyShortPanelViewModel(this.props)
        })
      }

      viewModels.push({
        key: "1",
        viewModel: new ElementsPropertyShortPanelViewModel(this.props)
      })
    }
    return viewModels
  }

  @computed
  get planningModeViewModel() {
    return new PlanningModeViewModel(this.props)
  }

  @computed
  get isPlanningModeActive() {
    return this.props.layers.usecases.planningMode.active.value
  }

  @computed
  get elementsPropertyShortPanelViewModel() {
    if (this.props.layers.usecases.selectedElements.selectedForgeElements.length === 0) return
    return new ElementsPropertyShortPanelViewModel(this.props)
  }

  private onCloseWindowDeselect() {
    this.props.layers.usecases.selectedElements.setWindowStage(EWindowSelectStage.none)
  }

  private onCloseSelectedElements() {
    this.props.layers.usecases.forgeElements.onClear()
  }

  @computed
  get crumbs() {
    const crumbs: { label: string, onClose(): void }[] = []
    if (this.props.layers.usecases.selectedElements.windowStage === EWindowSelectStage.deselect) {
      crumbs.push({
        label: "Рамка отмены",
        onClose: () => this.onCloseWindowDeselect()
      })
    }

    const count = this.props.layers.usecases.selectedElements.count
    if (count > 0) {
      crumbs.push({
        label: getSelectedItemsOnModelLabel(count),
        onClose: () => this.onCloseSelectedElements()
      })
    }

    return crumbs
  }
}

function getSelectedItemsOnModelLabel(length) {
  return `${numberFormat(length, ["Выделен", "Выделено", "Выделено"])} ${length} ${numberFormat(length, ["элемент", "элемента", "элементов"])}`
}