import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Bim } from "../../models/Bim";
import { IHideModelNodesViewModel, IHideNodesViewModel } from "./interface";

export class HideNodesViewModel implements IHideNodesViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get hideModelNodesViewModels() {
    return this.props.layers.usecases.bims.selectedAllBims.map(bim => new HideModelNodesViewModel({ ...this.props, bim }))
  }
}

export class HideModelNodesViewModel implements IHideModelNodesViewModel {
  constructor(private props: { layers: ILayers, bim: Bim }) {
    this.props = props
    makeObservable(this)
  }

  @computed
  get urn() {
    return this.props.bim.urn
  }

  @computed
  get hideDbIds() {
    const modelElements = this.props.layers.repositories.modelElementsRepository.getByBim(this.props.bim)
    return modelElements.filter(modelElement => this.props.layers.usecases.hidedElements.getElementHided(this.props.bim, modelElement)).map(el => el.modelID)
  }

  @computed
  get transparentHiding() {
    return this.props.layers.usecases.settings.transparentHiding.value
  }
}