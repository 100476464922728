import { BaseModel } from "../../models/BaseModel"
import { Entity } from "../../models/Entity"
import { IRepository } from "./interface"
import { IAuthRepository } from "../AuthRepository/interface";
import { ApiCache } from "../Cache/ApiCache";
import { RestRepository } from "./RestRepository"
import { EHTTPMethods } from "../../workers/FetchHandler/interface";
import { action, computed, makeObservable } from "mobx";
import { IPatchesRepository, TAddPatch, TDeletePatch, TEditPatch, TPatch } from "../PatchesRepository/interface";
import { loadWithReTry } from "../SmartRequest/SmartRequest";

type TPostPatch<Model> = {
  method: EHTTPMethods.post
  createdAt: Date
  model: Model
}

type TPatchResults<Model extends BaseModel> = { patch: TAddPatch<Model>, model: Model } | { patch: TEditPatch<Model>, model: Model } | { patch: TDeletePatch<Model> }

export class RestOfflineRepository<Model extends Entity<BaseModel & NewModelProps>, ResponseProps, NewRestProps, NewModelProps> implements IRepository<Model, NewModelProps> {
  constructor(private props: {
    Model: new (props: Model["props"]) => Model
    NewModel: new (props: NewModelProps & { id?: BaseModel["id"] }) => Model

    authRepository?: IAuthRepository
    path: string
    expirationTime?: number

    serialyze?(props: ResponseProps): Model["props"]
    serialyzeAdd?(props: NewModelProps): NewRestProps

    patchesRepository: IPatchesRepository<Model>
  }) {
    this.props = props

    this.restRepository = new RestRepository({
      ...props,
    })

    this.cache = new ApiCache({
      name: props.path
    })

    this.restRepository.readOnlyRepository.loadPropsById = this.loadPropsById
    this.restRepository.readOnlyRepository.loadPropsByParams = this.loadPropsByParams
    this.restRepository.readOnlyRepository.getModels = this.getModels

    makeObservable(this)
  }

  get patchesRepository() {
    return this.props.patchesRepository
  }

  restRepository: RestRepository<Model, ResponseProps, NewModelProps>
  cache: ApiCache<Model["props"]>
  offlineMode = false

  get modelsArray() {
    return this.restRepository.readOnlyRepository.modelsArray
  }

  async getHeadersAsync() {
    return {
      ...(await this.restRepository.getAuthHeadersAsync()),
      "Content-Type": "application/json"
    }
  }

  getAuthHeadersAsync() {
    return this.props.authRepository ? this.props.authRepository.getAuthHeadersAsync() : {}
  }

  loadPropsById = async (id: Model["id"]): Promise<Model["props"][]> => {
    const url = `${this.props.path}${id}/`
    const response = await loadWithReTry(url, {
      headers: await this.getHeadersAsync()
    }, { maxTry: 3 })

    if (!response || response.status === 0) {
      return this.cache.get(url)
    }

    if (response.status === 200) {
      const responseProps: ResponseProps = await response.json()

      const modelProps = this.restRepository.serialyze(responseProps)

      this.cache.put(url, [modelProps])

      return [modelProps]
    }

    return []
  }

  loadPropsByParams = async (params?: { [key: string]: any }): Promise<Model["props"][]> => {
    const url = params ? `${this.props.path}?${new URLSearchParams(params)}` : this.props.path
    const response = await loadWithReTry(url, {
      headers: await this.getHeadersAsync()
    }, { maxTry: 3 })

    if (!response || response.status === 0) {
      return this.cache.get(url)
    }

    if (response.status === 200) {
      const responseProps: ResponseProps[] = await response.json()

      const modelsProps = responseProps.map(props => this.restRepository.serialyze(props))

      this.cache.put(url, modelsProps)

      return modelsProps
    }

    return []
  }

  getModelsArray() {
    const pureModels = Array.from(
      this.restRepository.readOnlyRepository.models.values()
    )

    return this.patchModels(pureModels)
  }

  getAllPatches(): Promise<TPatch<Model>[]> {
    return this.props.patchesRepository.getAllAsync()
  }

  getPostPatches(): TPostPatch<Model>[] {
    return this.props.patchesRepository.getAddPatches()
  }

  patchModels(models: Model[]): Model[] {
    const postPatches = this.getPostPatches()

    const notDeletedModels = models.filter(model => {
      const patch = this.props.patchesRepository.getById(model.id)
      return patch ? patch.method !== EHTTPMethods.delete : true
    })

    const patchedModels = notDeletedModels.map(model => {
      const patch = this.props.patchesRepository.getById(model.id)
      if (patch?.method === EHTTPMethods.patch) {
        return patch.model
      }

      return model
    })

    return [
      ...patchedModels,
      ...postPatches.map(patch => patch.model)
    ]
  }

  patchModel(model?: Model, patch?: TPatch<Model>): Model | undefined {
    if (!patch) return model
    if (patch.method === EHTTPMethods.delete) return
    return patch.model
  }

  getById(id) {
    const model = this.restRepository.getById(id)
    const patch = this.patchesRepository.getById(id)

    return this.patchModel(model, patch)
  }

  async getByIdAsync(id) {
    const model = await this.restRepository.getByIdAsync(id)
    const patch = await this.patchesRepository.getByIdAsync(id)

    return this.patchModel(model, patch)
  }

  getAll() {
    return this.restRepository.getAll()
  }
  async getAllAsync() {
    return this.restRepository.getAllAsync()
  }

  getByParams(filterProps, uploadProps?) {
    return this.restRepository.getByParams(filterProps, uploadProps)
  }
  async getByParamsAsync(filterProps, uploadProps?) {
    return this.restRepository.getByParamsAsync(filterProps, uploadProps)
  }
  getOneByParams(filterProps, uploadProps?) {
    return this.restRepository.getOneByParams(filterProps, uploadProps)
  }
  async getOneByParamsAsync(filterProps, uploadProps?) {
    return this.restRepository.getOneByParamsAsync(filterProps, uploadProps)
  }

  getModels = (): Model[] => {
    const pureModels = Array.from(
      this.restRepository.readOnlyRepository.models.values()
    )

    return this.patchModels(pureModels)
  }

  async add(props: NewModelProps) {
    if (this.offlineMode) return this.addOffline(props)

    let response: Response | undefined

    try {
      response = await fetch(this.props.path, {
        headers: await this.getHeadersAsync(),
        method: EHTTPMethods.post,
        body: JSON.stringify(this.props.serialyzeAdd ? this.props.serialyzeAdd(props) : props)
      })
      if (this.responseIsOk(response)) {
        const data: ResponseProps = await response.json()

        const modelProps = this.restRepository.serialyze(data)
        return this.restRepository.readOnlyRepository.updateOrCreateModel(modelProps)
      } else {
        throw new Error()
      }
    } catch (error) {
      if (!response || response.status === 0) {
        return this.addOffline(props)
      } else {
        throw new Error()
      }
    }
  }

  @action
  async addOffline(props: NewModelProps) {
    const model = new this.props.NewModel(props)
    await this.props.patchesRepository.add(model)

    return model
  }

  async addBatch(modelsProps: NewModelProps[]) {
    if (this.offlineMode) return this.addBatchOffline(modelsProps)

    const models: Model[] = []

    for (const props of modelsProps) {
      const model = await this.add(props)
      if (model) models.push(model)
    }

    return models
  }

  @action
  async addBatchOffline(modelsProps: NewModelProps[]) {
    const models: Model[] = []
    for (const props of modelsProps) {
      models.push(await this.addOffline(props))
    }

    return models
  }

  responseIsOk(response: Response) {
    return response.status === 200 || response.status === 201 || response.status === 204
  }

  responseWithNetworkError(response: Response | undefined) {
    return !response || response.status === 0
  }

  async edit(id: Model["id"], props: NewModelProps) {
    if (this.offlineMode) return this.editOffline(id, props)

    let response: Response | undefined

    try {
      response = await fetch(`${this.props.path}${id}/`, {
        headers: await this.getHeadersAsync(),
        method: EHTTPMethods.patch,
        body: JSON.stringify(this.props.serialyzeAdd ? this.props.serialyzeAdd(props) : props)
      })
      if (this.responseIsOk(response)) {
        const data: ResponseProps = await response.json()

        const modelProps = this.restRepository.serialyze(data)
        return this.restRepository.readOnlyRepository.updateOrCreateModel(modelProps)
      } else {
        throw new Error()
      }
    } catch (error) {
      if (this.responseWithNetworkError(response)) {
        return this.editOffline(id, props)
      } else {
        throw new Error()
      }
    }
  }

  async editOffline(id: Model["id"], props: NewModelProps) {
    const model = new this.props.NewModel({ id, ...props })
    await this.props.patchesRepository.edit(model)

    return model
  }

  async delete(id: Model["id"]) {
    if (this.offlineMode) return this.deleteOffline(id)
    let response: Response | undefined

    try {
      response = await fetch(`${this.props.path}${id}/`, {
        headers: await this.getHeadersAsync(),
        method: EHTTPMethods.delete,
      })
      if (this.responseIsOk(response)) {
        this.restRepository.readOnlyRepository.models.delete(id)
      } else {
        throw new Error()
      }
    } catch (error) {
      if (this.responseWithNetworkError(response)) {
        return this.deleteOffline(id)
      } else {
        throw new Error()
      }
    }
  }

  async deleteOffline(id: Model["id"]) {
    await this.props.patchesRepository.delete(id)
  }

  async sendAllPatches(): Promise<TPatchResults<Model>[]> {
    const results: TPatchResults<Model>[] = []

    while (true) {
      const result = await this.sendNextPatch()
      if (result) {
        results.push(result)
      } else {
        break
      }
    }

    return results
  }

  async sendNextPatch(): Promise<TPatchResults<Model> | undefined> {
    const patch = await this.props.patchesRepository.getFirstPatchAsync()
    return patch ? this.sendPatch(patch) : undefined
  }

  async sendPatch(patch: TPatch<Model>): Promise<TPatchResults<Model> | undefined> {
    if (patch) {
      switch (patch.method) {
        case EHTTPMethods.post:
          return this.sendPostPatch(patch)
        case EHTTPMethods.patch:
          return this.sendEditPatch(patch)
        case EHTTPMethods.delete:
          return this.sendDeletePatch(patch)
      }
    }
  }

  async sendPostPatch(patch: TAddPatch<Model>): Promise<{ patch: TAddPatch<Model>, model: Model }> {
    const model = await this.restRepository.add(patch.model.props)
    await this.props.patchesRepository.remove(patch.id)

    return {
      patch,
      model
    }
  }

  async sendEditPatch(patch: TEditPatch<Model>): Promise<{ patch: TEditPatch<Model>, model: Model }> {
    const model = await this.restRepository.edit(patch.id, patch.model.props)
    await this.props.patchesRepository.remove(patch.id)

    return {
      patch,
      model
    }
  }

  async sendDeletePatch(patch: TDeletePatch<Model>): Promise<{ patch: TDeletePatch<Model> }> {
    await this.restRepository.delete(patch.id)
    await this.props.patchesRepository.remove(patch.id)

    return {
      patch,
    }
  }
}