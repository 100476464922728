import { TCompanyProps } from "../../models/Company";
import { EUserRoles, TUserProps, User } from "../../models/User";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { IUsersRepository } from "./interface";

type TRestProps = {
  id: number
  first_name: string
  last_name: string
  role: EUserRoles
  email: string
  company: TCompanyProps["id"]
}

export class RestUsersRepository implements IUsersRepository {
  constructor(private props: { authRepository: IAuthRepository, path: string }) {
    this.props = props

    this.offlineRepository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: User,
      serialyze: this.serialyze
    })
  }

  getById(id) {
    return this.offlineRepository.getById(id)
  }

  addToCache(props: TUserProps) {
    const user = new User(props)
    this.offlineRepository.readOnlyRepository.models.set(props.id, user)

    return user
  }

  offlineRepository: RestOfflineReadOnlyRepository<User, TRestProps>

  get path() {
    return this.props.path
  }

  serialyze(props: TRestProps): User["props"] {
    return {
      id: props.id,
      firstName: props.first_name,
      lastName: props.last_name,
      role: props.role,
      email: props.email,
      company: props.company
    }
  }
}