import { computed, makeObservable } from "mobx";
import { TBaseModel } from "./BaseModel";
import { Entity } from "./Entity";
import { TWorkTypeGroupProps } from "./WorkTypeGroup";

export type TWorkTypeProps = {
  name: string
  orientation: EWorkTypeOrientation
  group?: TWorkTypeGroupProps["id"]
} & TBaseModel

export enum EWorkTypeOrientation {
  vertical = "vertical",
  horizontal = "horizontal",
  none = "none"
}

export class WorkType extends Entity<TWorkTypeProps> {
  constructor(props: TWorkTypeProps) {
    super(props)

    makeObservable(this)
  }

  get name() {
    return this.props.name
  }

  @computed
  get fullName() {
    return this.orientationName ? `${this.props.name} (${this.orientationName})` : this.props.name
  }

  @computed
  get orientationName() {
    switch (this.props.orientation) {
      case EWorkTypeOrientation.horizontal:
        return "горизонтальные"
      case EWorkTypeOrientation.vertical:
        return "вертикальные"
      default:
        return ""
    }
  }

  get orientation() {
    return this.props.orientation
  }

  get group() {
    return this.props.group
  }
}