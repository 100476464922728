import { v4 } from "uuid";
import { BaseModel } from "./BaseModel";
import { TBimProps } from "./Bim";
import { Entity } from "./Entity";
import { TModelElementProps } from "./ModelElement/ModelElement";
import { TWorkTypeProps } from "./WorkType";

export type TBimElementProps = BaseModel & TNewBimElementProps

export type TNewBimElementProps = {
  modelElement: TModelElementProps["id"]
  bim: TBimProps["id"]
  workType: TWorkTypeProps["id"]
}

export class BimElement extends Entity<TBimElementProps> {
  get modelElement() {
    return this.props.modelElement
  }

  get bim() {
    return this.props.bim
  }
}

export class NewBimElement extends BimElement {
  constructor(props: TNewBimElementProps & Partial<BaseModel>) {
    super({
      id: props.id || v4(),
      ...props
    })
  }
}