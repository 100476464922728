import { makeObservable, observable } from "mobx";
import { IStatusWrapperViewModel } from "./interface";

export class StatusWrapperForPromiseViewModel implements IStatusWrapperViewModel {
  constructor(private props: { promise: Promise<any> }) {
    this.props = props

    makeObservable(this)
    this.init()
  }

  @observable
  loading = true

  @observable
  error = ""

  async init() {
    try {
      await this.props.promise
    } catch (error) {
      this.error = error
    }

    this.loading = false
  }
}