import React, { useMemo } from "react"
import { Grid, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IAppViewModel } from "../../view-model/AppViewModel/interface"
import { AuthForm } from "../AuthForm/AuthForm"
import { HomePage } from "../HomePage/HomePage"
import { BimPage } from "../BimPage/BimPage"

const useClasses = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    overflow: "overlay",
    display: "flex"
  },
}))
const useChildClasses = makeStyles(theme => ({
  root: {},
}))

interface IAppProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IAppViewModel
}

export const App = observer((props: IAppProps) => {
  const classes = useClasses(props)
  const childClasses = useChildClasses()

  const activePage = useMemo(() => {
    switch (props.viewModel.activePage) {
      case "auth":
        return (
          <Grid container justifyContent="center" alignContent="center">
            <AuthForm viewModel={props.viewModel.authFormViewModel} classes={childClasses} />
          </Grid>
        )
      case "home":
        return <HomePage viewModel={props.viewModel.homePageViewModel} classes={childClasses} />
      case "bim":
        return <BimPage viewModel={props.viewModel.bimPageViewModel} classes={childClasses} />
      default:
        return "Page not found"
    }
  }, [props.viewModel.activePage])

  return (
    <div className={classes.root}>
      {
        activePage
      }
    </div>
  )
})