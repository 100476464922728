import { computed, makeObservable } from "mobx";
import { ISimpleViewModel } from "../SimpleViewModel/interface";
import { ICheckboxWithLabelViewModel } from "./interface";

export class CheckboxWithLabelViewModelOverSimpleViewModel implements ICheckboxWithLabelViewModel {
  constructor(private props: {
    simpleViewModel: ISimpleViewModel<boolean>
    label: string

    disabled?: boolean
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get label() {
    return this.props.label
  }

  @computed
  get value() {
    return this.props.simpleViewModel.value
  }

  @computed
  get disabled() {
    return this.props.disabled
  }

  onClick = () => {
    this.props.simpleViewModel.onChange(!this.value)
  }
}