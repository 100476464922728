import { action, computed, makeObservable, observable, ObservableSet, toJS } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { numberFormat } from "../../heplpers/formats";
import { EActionTarget } from "../../models/Action";
import { TCompanyProps } from "../../models/Company";
import { ERemarkStatus, Remark, TNewRemarkProps } from "../../models/Remark";
import { TRemarkBaseProps } from "../../models/RemarkBase";
import { UploadFile } from "../../models/UploadFile";
import { EActions } from "../../usecase/Permissions/Permissions";
import { UploadButtonViewModel } from "../Buttons/UploadButtonViewModel";
import { CheckboxWithLabelViewModelOverSimpleViewModel } from "../CheckboxViewModel/CheckboxWithLabelViewModelOverSimpleViewModel";
import { InputViewModel } from "../InputViewModel/InputViewModel";
import { EOrientations } from "../PanelDropdownMenuViewModel/interface";
import { PanelSelectorSingleViewModel } from "../PanelSelectorViewModel/PanelSelectorSingleViewModel";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { UploadFilesListViewModel } from "../UploadFilesListViewModel/UploadFilesListViewModel";
import { IRemarksFormViewModel } from "./interface";

export class RemarksFormViewModel implements IRemarksFormViewModel {
  constructor(private props: { layers: ILayers, remark: Remark }) {
    this.props = props

    this.mount(props.remark)
    makeObservable(this)
  }

  @action
  private mount(remark: Remark) {
    this.props.layers.repositories.actionsRepository.silentSendEntityAction({
      entityId: remark.id,
      entityType: EActionTarget.remark
    })

    this.files = new ObservableSet(remark.attachments)
  }

  @computed
  get editMode() {
    return this.props.layers.usecases.viewState.props.editRemarkMode
  }

  @observable
  private showFiles = false

  private files = new ObservableSet<number | string>()

  @computed
  get showFilesViewModel() {
    return {
      label: `Посмотреть ${this.files.size} ${numberFormat(this.files.size, ["файл", "файла", "файлов"])}`,
      onClick: () => {
        if (this.files.size > 0) this.showFiles = true
      }
    }
  }

  @computed
  get normativeDocumentViewModel() {
    if (!this.remarkBase || !this.remarkBase.requiresNormativeDocument) return

    return new InputViewModel({
      value: this.props.remark.normativeDocument,
      disabled: !this.allowedEdit
    })
  }

  @computed
  get remarkBase() {
    return this.remarkBaseViewModel.value ? this.props.layers.repositories.remarksBaseRepository.getById(this.remarkBaseViewModel.value) : undefined
  }

  @computed
  get activeStatusDictionary() {
    return this.props.layers.usecases.statuses.activeStatusDictionary
  }

  @computed
  get remarksBaseForSelectedElementsOptions() {
    return this.props.layers.usecases.remarks.remarksBaseForSelectedElements
      .sort((a, b) => {
        if (a.commonRemark === b.commonRemark) return a.title > b.title ? 1 : -1
        return a.commonRemark ? -1 : 1
      })
      .map(remarkBase => ({
        value: remarkBase.id,
        group: remarkBase.commonRemark ? "Общие замечания" : "Замечания для статуса",
        label: remarkBase.title
      }))
  }

  @computed
  get remarkBaseAllOptions() {
    type TOption = { value: TRemarkBaseProps["id"], group: string, label: string }

    // сортировки
    const sortByTitle = (a, b) => {
      return a.title > b.title ? 1 : -1
    }

    const sortByName = (a, b) => {
      return a.name > b.name ? 1 : -1
    }

    // для статуса не назначен
    const priorityOptions: TOption[] = []
    for (const remarkBase of this.props.layers.repositories.remarksBaseRepository.getCommonRemarks().sort(sortByTitle)) {
      priorityOptions.push({
        value: remarkBase.id,
        group: "Не назначен",
        label: remarkBase.title
      })
    }

    // для других статусов
    let options: TOption[] = []
    for (const baseStatus of this.props.layers.usecases.selectedStatuses.activeStatusDictionaryOptions.sort(sortByName)) {
      const groupOptions: TOption[] = []
      const remarksBaseForBaseStatus = this.props.layers.repositories.remarksBaseRepository.getByBaseStatus(baseStatus.id).sort(sortByTitle)
      const workType = this.props.layers.repositories.workTypesRepository.getById(baseStatus.workType)

      for (const remarkBase of remarksBaseForBaseStatus) {
        groupOptions.push({
          value: remarkBase.id,
          group: `${baseStatus.name} - ${workType?.fullName}`,
          label: remarkBase.title
        })
      }

      options = [
        ...options,
        ...groupOptions
      ]
    }

    return [
      ...priorityOptions,
      ...options
    ]
  }

  @computed
  get remarkBaseViewModel() {
    return new PanelSelectorSingleViewModel<TRemarkBaseProps["id"]>({
      orientation: EOrientations.right,
      defaultValue: this.props.remark.remarkBase,
      label: "Замечание",
      options: this.props.layers.usecases.selectedElements.count === 0 ? this.remarkBaseAllOptions : this.remarksBaseForSelectedElementsOptions,
      disabled: !this.allowedEdit
    })
  }

  @computed
  get contractorViewModel() {
    return new PanelSelectorSingleViewModel<TCompanyProps["id"]>({
      orientation: EOrientations.right,
      defaultValue: this.props.remark.contractor,
      label: "Подрядчик",
      options: this.props.layers.usecases.companies.contractorsForSelectedProjects.map(company => ({
        value: company.id,
        label: company.name,
        // group: this.getCompanyScopeName(company.scope)
      })),
      disabled: !this.allowedEdit
    })
  }

  @computed
  get controlViewModel() {
    const user = this.props.layers.repositories.authRepository.getUser()
    const supervisor = this.props.layers.repositories.usersRepository.getById(this.props.remark.supervisor)
    const users = user && supervisor ? user.id === supervisor.id ? [supervisor] : [user, supervisor] : []

    return new PanelSelectorSingleViewModel<TCompanyProps["id"]>({
      orientation: EOrientations.right,
      defaultValue: this.props.remark.supervisor,
      label: "Стройконтроль",
      options: users.map(user => ({
        value: user.id,
        label: user.fullName
      })),
      disabled: !this.allowedEdit
    })
  }

  // сохраняем список только что добавленных файлов
  private newFiles = new Set<UploadFile>()

  @computed
  get uploadButtonViewModel() {
    if (!this.allowedAddNewRemarkFiles) return

    return new UploadButtonViewModel({
      ...this.props,
      onAdd: (files: UploadFile[]) => {
        for (const file of files) {
          this.files.add(file.id)
          this.newFiles.add(file)
        }
      }
    })
  }

  @computed
  get commentViewModel() {
    return new InputViewModel({
      value: this.props.remark.body,
      disabled: !this.allowedEdit
    })
  }

  @computed
  get deadlineViewModel() {
    return new InputViewModel({
      value: this.props.remark.deadline.toISOString(),
      disabled: !this.allowedEdit
    })
  }

  @computed
  get locationViewModel() {
    return new InputViewModel({
      value: this.props.remark.location,
      disabled: !this.allowedEdit
    })
  }

  // private getCompanyScopeName(scope: ECompanyScope): string {
  //   switch (scope) {
  //     case ECompanyScope.geoworks:
  //       return "Геодезические работы"
  //     case ECompanyScope.monolith:
  //       return "Монолитные работы"
  //     case ECompanyScope.control:
  //       return "Строительный контроль"
  //     default:
  //       return "Другие"
  //   }
  // }

  @computed
  private get allowedChangeStatus() {
    if (!this.editMode) return false
    if (this.props.layers.usecases.permissions.actionIsAllowed(EActions.editRemark)) return true

    if (this.remarkClosed) return false

    return (
      this.props.layers.usecases.permissions.actionIsAllowed(EActions.setRemarkStatusClose) ||
      this.props.layers.usecases.permissions.actionIsAllowed(EActions.setRemarkStatusOpen) ||
      this.props.layers.usecases.permissions.actionIsAllowed(EActions.setRemarkStatusPending)
    )
  }

  @computed
  private get allowedAddNewRemarkFiles() {
    if (!this.editMode) return false
    if (this.props.layers.usecases.permissions.actionIsAllowed(EActions.editRemark)) return true

    if (this.remarkClosed) return false

    return this.props.layers.usecases.permissions.actionIsAllowed(EActions.addNewRemarkFiles)
  }

  @computed
  private get allowedEdit() {
    return this.editMode && this.props.layers.usecases.permissions.actionIsAllowed(EActions.editRemark)
  }

  @computed
  private get remarkClosed() {
    return this.props.remark.status === ERemarkStatus.closed
  }

  @computed
  get statusViewModel() {
    return new PanelSelectorSingleViewModel<ERemarkStatus>({
      orientation: EOrientations.right,
      defaultValue: this.props.remark.status,
      label: "Статус",
      disabled: !this.allowedChangeStatus,
      options: [
        {
          value: ERemarkStatus.opened,
          label: "Открыто",
          disabled: this.props.layers.usecases.permissions.actionIsBlocked(EActions.setRemarkStatusOpen)
        },
        {
          value: ERemarkStatus.pending,
          label: "Ожидает",
          disabled: this.props.layers.usecases.permissions.actionIsBlocked(EActions.setRemarkStatusPending)
        },
        {
          value: ERemarkStatus.closed,
          label: "Закрыто",
          disabled: this.props.layers.usecases.permissions.actionIsBlocked(EActions.setRemarkStatusClose)
        },
      ]
    })
  }

  @computed
  get warnings() {
    const warnings: string[] = []

    if (!this.props.layers.usecases.remarks.canAssingRemarkForSelectedElements) {
      warnings.push("- выберите элементы")
    } else if (!this.props.layers.usecases.remarks.canAssingRemarkForSelectedStatusDictionary) {
      warnings.push("- выберите элементы с одинаковым статусом работ")
    }

    if (warnings.length > 0) {
      return {
        header: "чтобы изменить замечание",
        items: warnings
      }
    }
  }

  @computed
  get allowDelete() {
    return this.editMode && this.props.layers.usecases.permissions.actionIsAllowed(EActions.deleteRemark)
  }

  @computed
  private get criticalViewModel() {
    return new SimpleViewModel({
      value: this.props.remark.isCritical
    })
  }

  @computed
  get criticalCheckboxWithLabelViewModel() {
    return new CheckboxWithLabelViewModelOverSimpleViewModel({
      disabled: !this.allowedEdit,
      simpleViewModel: this.criticalViewModel,
      label: "Критичное"
    })
  }

  @computed
  get remarkProps(): Omit<TNewRemarkProps, "elements"> {
    return {
      bim: this.props.remark.bim,
      body: this.commentViewModel.value,
      remarkBase: this.remarkBaseViewModel.value || this.props.remark.remarkBase,
      location: this.locationViewModel.value,
      contractor: this.contractorViewModel.value || this.props.remark.contractor,
      deadline: this.deadlineViewModel.value,
      status: this.statusViewModel.value || this.props.remark.status,
      title: "title",
      supervisor: this.controlViewModel.value || this.props.remark.supervisor,
      attachments: Array.from(this.files),
      normativeDocument: this.normativeDocumentViewModel?.value || "",
      isCritical: this.criticalViewModel.value
    }
  }

  statusWrapperViewModel = new StatusWrapperViewModel()

  @computed
  get saveButtonViewModel() {
    if (this.allowedChangeStatus || this.allowedEdit || this.allowedAddNewRemarkFiles) return {
      disabled: !this.props.layers.usecases.remarks.getRemarkCanBeSave(this.remarkProps),
      onClick: async () => {
        this.statusWrapperViewModel.load()
        await this.props.layers.usecases.remarks.editSelectedRemark(this.remarkProps)

        this.statusWrapperViewModel.success()
      },
      label: "Сохранить"
    }
  }

  @computed
  get showFilesDialogViewModel() {
    return {
      open: this.showFiles,
      onClose: () => this.showFiles = false
    }
  }

  async onDelete() {
    this.statusWrapperViewModel.load()
    await this.props.layers.usecases.remarks.deleteSelectedRemark()
    this.statusWrapperViewModel.success()
  }

  @computed
  get uploadFilesListViewModel() {
    const viewModel = new UploadFilesListViewModel({
      ...this.props,
      ids: this.files,

      getAllowDelete: (file: UploadFile) => {
        if (this.allowedEdit) return true

        if (this.allowedAddNewRemarkFiles) return this.newFiles.has(file)
        return false
      }
    })

    viewModel.onDelete = async (file: UploadFile) => {
      this.files.delete(file.id)
      if (this.files.size === 0) this.showFiles = false
    }

    viewModel.onClose = () => {
      this.showFiles = false
    }

    return viewModel
  }
}