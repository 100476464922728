import { IUploadButtonViewModel } from "../Buttons/interface";
import { IStatusWrapperViewModel } from "../StatusWrapperViewModel/interface";
import { IUploadFilesListViewModel } from "../UploadFilesListViewModel/interface";

export enum EStepStatus {
  completed = "completed",
  editable = "editable",
  none = "none"
}
export interface IButtonProps {
  onClick(): void
  disabled?: boolean
  label: string
}
export interface IStatusFormViewModel {
  comment: string

  onChangeComment(e: string): void
  onApply(): void

  statusWrapperViewModel: IStatusWrapperViewModel

  uploadButtonViewModel: IUploadButtonViewModel
  showFilesDialogViewModel: IShowFilesDialogViewModel
  uploadFilesListViewModel: IUploadFilesListViewModel
  showFilesViewModel?: IButtonProps
}

export interface IShowFilesDialogViewModel {
  open: boolean
  onClose(): void
}

export type TStepCompleted = {
  status: EStepStatus.completed
  username: string
  name: string
  date: string
  comment: string

  showFilesViewModel?: IButtonProps
  showFilesDialogViewModel: IShowFilesDialogViewModel
  uploadFilesListViewModel: IUploadFilesListViewModel
}

export type TStep = TStepCompleted | {
  status: EStepStatus.editable
  name: string

  statusFormViewModel: IStatusFormViewModel
} | {
  status: EStepStatus.none
  name: string
}
export interface IStatusSelectorViewModel {
  warnings?: {
    header: string
    items: string[]
  }
  steps: TStep[]
}