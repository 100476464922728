import { IStatusWrapperViewModel } from "./interface";

export class LoadingStatusWrapperViewModel implements IStatusWrapperViewModel {
  constructor(private props: { loadingLabel?: string }) {
    this.props = props
  }

  get loadingLabel() {
    return this.props.loadingLabel
  }

  loading = true
}