import React from "react"
import {
  FormControlLabel,
  makeStyles,
  Checkbox,
  FormGroup
} from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IRemarksListRowViewModel, IRemarksListViewModel } from "../../view-model/RemarksListViewModel/interface"
import SearchField from '../../components/Inputs/SearchField';
import clsx from "clsx";

const useClasses = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "100%"
  },
  content: {
    overflow: "overlay",
    marginTop: 10,
  },
  row: {
    display: "grid",
    gridTemplateColumns: 'min-content 12px auto auto',
    gridTemplateAreas: `
      "status warning code time"
      "storey storey storey storey"
      "description description description description"
      "comment comment comment comment";
    `,

    cursor: "pointer",
    padding: "10px 0",

    "&:hover": {
      //@ts-ignore
      backgroundColor: theme.palette.baseBackgroundColor
    },
  },
  status: {
    width: 12,
    height: 12,
    borderRadius: 6,
    margin: "auto",

    gridArea: "status",
  },
  warning: {
    gridArea: "warning",
    margin: "auto 0",
    color: "#BF0A0A",
    fontWeight: 500
  },
  code: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "24px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    gridArea: "code",
  },
  time: {
    color: "#AAAAA9",
    fontSize: "12px",
    lineHeight: "24px",

    gridArea: "time",
    textAlign: "right"
  },
  description: {
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "16px",
    gridArea: "description",
    alignSelf: "center",
    marginTop: 6
  },
  comment: {
    fontSize: "10px",
    lineHeight: "16px",
    gridArea: "comment",
    marginTop: 5
  },
  search: {
  },
  storey: {
    gridArea: "storey",
    fontSize: "12px",
    marginTop: 6
  },
  statusFilter: {
    marginTop: 15
  },
  statuses: {
    display: "grid",
    gridTemplateColumns: "min-content min-content",
    columnGap: 37
  },
  statusFormControlLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
      fontWeight: 500,
    },
    margin: "10px 0 0 0",
    whiteSpace: "nowrap",
    userSelect: "none"
  },
  statusCheckbox: {
    padding: 0,
    marginRight: 7
  },
  darkenCode: {
    color: "#AAAAA9"
  }
}))

interface IRemarksListProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IRemarksListViewModel
}

export const RemarksList = observer((props: IRemarksListProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      <SearchField className={classes.search} value={props.viewModel.search} onChange={(e) => props.viewModel.onChangeSearch(e)} placeholder="Поиск" />

      <div className={classes.statusFilter}>
        <FormGroup className={classes.statuses}>
          {
            props.viewModel.statuses.map(status => (
              <FormControlLabel
                className={classes.statusFormControlLabel}
                control={
                  <Checkbox
                    className={classes.statusCheckbox}
                    checked={status.checked}
                    onClick={status.onClick}
                    size="small"
                  />
                }
                label={status.label}
                style={{
                  color: status.color
                }}
              />
            ))
          }
        </FormGroup>

      </div>
      <div className={classes.content}>
        {
          props.viewModel.remarks.map(remark => (
            <Row key={remark.key} viewModel={remark.viewModel} classes={classes} />
          ))
        }
      </div>
    </div>
  )
})

interface IRemarksListRowProps {
  classes: ReturnType<typeof useClasses>
  viewModel: IRemarksListRowViewModel
}

function Row(props: IRemarksListRowProps) {
  const classes = props.classes

  return (
    <div className={classes.row} onClick={() => props.viewModel.onClick()}>
      <div className={classes.status} style={{ backgroundColor: props.viewModel.statusColor }}></div>
      {
        props.viewModel.warning ?
          <div className={classes.warning} title={props.viewModel.warning}>!</div>
          : null
      }
      <div className={clsx(classes.code, {
        [classes.darkenCode]: props.viewModel.darken
      })}>{props.viewModel.code}</div>
      <div className={classes.time}>{props.viewModel.time}</div>
      <div className={classes.storey}>{props.viewModel.storey}</div>

      <div className={classes.description}>{props.viewModel.description}</div>

      <div className={classes.comment}>{props.viewModel.comment}</div>
    </div>
  )
}