import { action, computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { IAuthFormViewModel } from "./interface";

export class AuthFormViewModel implements IAuthFormViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  login = ""

  @observable
  password = ""

  @computed
  get statusWrapperViewModel() {
    return new StatusWrapperViewModel({
      status: this.props.layers.repositories.authRepository.status
    })
  }

  @action
  onChangeLogin(v: string) {
    this.login = v
  }

  @action
  onChangePassword(v: string) {
    this.password = v
  }

  private get isValid() {
    return this.login.length > 0 && this.password.length > 0
  }

  @computed
  get disableLoginButton() {
    return !this.isValid
  }

  onKeyPress(code: string) {
    if (this.isValid && code === "Enter") {
      this.onLogin()
    }
  }

  async onLogin() {
    try {
      await this.props.layers.repositories.authRepository.login({
        login: this.login,
        password: this.password
      })
    } catch (error) { }
  }
}