import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { IInversionExtensionViewModel } from "./interface";

export class InversionExtensionViewModel implements IInversionExtensionViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get inversion() {
    return this.props.layers.usecases.settings.inversion.value
  }
}