import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Collection } from "../../models/Collection";
import { EPages } from "../../models/Pages";
import { EActions } from "../../usecase/Permissions/Permissions";
import { CollectionFormEditViewModel } from "../CollectionFormViewModel/CollectionFormEditViewModel";
import { ICollectionCardViewModel } from "./interface";

export class CollectionCardViewModel implements ICollectionCardViewModel {
  constructor(
    private props: { layers: ILayers; collection: Collection }
  ) {
    this.props = props;

    makeObservable(this);
  }

  @computed
  private get collection() {
    return this.props.collection
  }

  @observable
  collectionFormViewModel

  @computed
  get name() {
    return this.collection?.name || "?";
  }

  @computed
  get link() {
    return this.collection?.link;
  }

  @computed
  get image() {
    return this.collection?.image || "";
  }

  @computed
  get allowedEdit() {
    return this.props.layers.usecases.permissions.actionIsAllowed(EActions.createAndEditCollections)
  }

  get dialogOpened() {
    return Boolean(this.collectionFormViewModel);
  }

  @computed
  get onOpen() {
    if (this.props.layers.usecases.permissions.actionIsBlocked(EActions.showModels)) return

    return () => {
      this.props.layers.usecases.viewState.setSelectedCollection(this.props.collection.id)
      this.props.layers.usecases.viewState.setSelectedPage(EPages.viewer)
    }
  }

  onEdit() {
    this.collectionFormViewModel = new CollectionFormEditViewModel(this.props);
  }

  onCloseDialog() {
    this.collectionFormViewModel = undefined
  }
}
