import { format } from "date-fns";
import { computedFn } from "mobx-utils";
import { EPages } from "../../models/Pages";
import { TProjectProps } from "../../models/Project";
import { Prescription } from "../../models/Prescription";
import { TTargetProps } from "../../models/Target";
import { ICompaniesRepository } from "../../repositories/CompaniesRepository/interface";
import { ITargetsRepository } from "../../repositories/TargetsRepository/interface";
import { IUsersRepository } from "../../repositories/UsersRepository/interface";
import { TPrescriptionTableRow } from "../../view-model/PrescriptionsTableViewModel/interface";
import { Prescriptions } from "../Prescriptions/Prescriptions";
import { ViewState } from "../ViewState/ViewState";
import { EPanelSection } from "../../models/ViewState";
import { ERemarkStatus, ERemarkStatusColor } from "../../models/Remark";
import { EActions, Permissions } from "../Permissions/Permissions";

export class FiltredPrescriptionsTable {
  constructor(private props: {
    prescriptions: Prescriptions
    usersRepository: IUsersRepository
    companiesRepository: ICompaniesRepository
    viewState: ViewState
    targetsRepository: ITargetsRepository
    permissions: Permissions
  }) {
    this.props = props
  }

  getFiltredPrescriptionsByProjectId(id: TProjectProps["id"]) {
    const set = new Set<TPrescriptionTableRow & {
      filter: string;
    }>()

    const targets = this.props.targetsRepository.getByProjectId(id)

    for (const target of targets) {
      const prescriptions = this.getFiltredPrescriptionsByTargetId(target.id)

      for (const prescription of prescriptions) {
        set.add(prescription)
      }
    }

    return Array.from(set)
  }

  private getPrescriptionStatusName(status: ERemarkStatus): string {
    switch (status) {
      case ERemarkStatus.opened:
        return "Открыто"
      case ERemarkStatus.closed:
        return "Закрыто"
      case ERemarkStatus.pending:
        return "Ожидает"
    }

    return ""
  }

  private preparePrescription(prescription: Prescription) {
    const deadline = this.props.prescriptions.getPrescriptionDeadline(prescription)
    const contractor = this.props.prescriptions.getPrescriptionContractor(prescription)
    const status = this.props.prescriptions.getPrescriptionStatus(prescription)
    const remarks = this.props.prescriptions.getPrescriptionRemarks(prescription)
    const expired = remarks.some(remark => remark ? remark.expired : false)

    const row: TPrescriptionTableRow & { filter: string } = {
      key: String(prescription.id),
      id: String(prescription.id),
      status: `${expired ? "!" : ""}${status ? this.getPrescriptionStatusName(status) : ""}`,
      statusColor: status ? ERemarkStatusColor[status] : "black",
      code: this.props.prescriptions.getPrescriptionCode(prescription),
      owner: this.props.usersRepository.getById(prescription.owner)?.fullName || "",
      contractor: contractor?.name || "",
      remarksCount: String(prescription.remarks.length),
      deadline: deadline ? format(deadline, "dd.MM.yyyy") : "",
      onOpen: this.props.permissions.actionIsAllowed(EActions.showModels) ? () => {
        this.props.viewState.setSelectedPrescriptions([prescription.id])
        this.props.viewState.setSelectedBims([prescription.bim])
        this.props.viewState.setSelectedPage(EPages.viewer)
        this.props.viewState.setPanelSection(
          EPanelSection.prescriptions
        )
        this.props.viewState.setPanelOpened(true)
      } : undefined,
      filter: ""
    }

    row.filter = `
        ${row.id}
        ${row.code}
        ${row.owner}
        ${row.status}
        ${row.contractor}
        ${row.remarksCount}
        ${row.deadline}
      `.toLowerCase()

    return row
  }

  getPreparedTargetPrescriptions = computedFn((id: TTargetProps["id"]) => {
    const result: (TPrescriptionTableRow & {
      filter: string;
    })[] = []
    const prescriptions = this.props.prescriptions.getPrescriptionsByTargetId(id)

    for (const prescription of prescriptions) {
      result.push(
        this.preparePrescription(prescription)
      )
    }
    return result
  })

  getFiltredPrescriptionsByTargetIdPromise(id: TTargetProps["id"]) {
    return this.props.prescriptions.getPrescriptionsByTargetIdAsync(id)
  }

  getFiltredPrescriptionsByTargetId(id: TTargetProps["id"]) {
    const search = this.props.viewState.props.mainPageSearch.toLowerCase()
    const prescriptions = this.getPreparedTargetPrescriptions(id)

    return prescriptions.filter(({ filter }) =>
      filter.includes(search)
    );
  }
}