import { action } from "mobx"
import { ISimpleViewModel } from "../../view-model/SimpleViewModel/interface"
import { SimpleViewModel } from "../../view-model/SimpleViewModel/SimpleViewModel"
import { ISimpleDataRepository } from "./interface"

export class LocalStorageJSONRepository<T extends Object> implements ISimpleDataRepository<T> {
  constructor(private props: { key: string, defaultData?: T }) {
    this.props = props

    this.simpleViewModel = new SimpleViewModel({
      value: props.defaultData
    })

    this.init(props.defaultData)
  }

  private init(defaultData?: T) {
    const value = this._get()

    if (value) {
      this.simpleViewModel.onChange({
        ...defaultData,
        ...value,
      })
    }
  }

  private simpleViewModel: ISimpleViewModel<T | undefined>

  get() {
    return this.simpleViewModel.value
  }

  @action
  async patch(patch: Partial<T>) {
    if (this.simpleViewModel.value) {
      let value = this.simpleViewModel.value

      for (const key in patch) {
        //@ts-ignore
        value[key] = patch[key]
      }

      localStorage.setItem(this.props.key, JSON.stringify(value))

      this.simpleViewModel.onChange(value)
    }
  }

  @action
  async save(value: T) {
    localStorage.setItem(this.props.key, JSON.stringify(value))

    this.simpleViewModel.onChange(value)
  }

  @action
  async delete() {
    localStorage.removeItem(this.props.key)

    this.simpleViewModel.onChange(undefined)
  }

  private _get() {
    const storeValue = localStorage.getItem(this.props.key)

    if (storeValue) {
      try {
        const value = JSON.parse(storeValue)
        return value
      } catch (error) {

      }
    }
  }
}