import { IUploadFileViewModel } from "./interface";

export class UploadFileViewModel implements IUploadFileViewModel {
  constructor(private props: { onLoad(file: File): void, accept?: string[] }) {
    this.props = props
  }

  onClick = () => {
    const input = document.createElement('input')
    input.type = 'file'
    if (this.props.accept) {
      input.accept = this.props.accept.join(",")
    }

    input.onchange = (e) => {
      //@ts-ignore
      if (e.target.files[0]) {
        //@ts-ignore
        this.props.onLoad(e.target.files[0])
      }
      input.remove()
    }

    input.click();
  }
}