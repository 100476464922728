import { action, computed, makeObservable, observable } from "mobx";
import { BaseModel } from "./BaseModel";

export class Entity<T extends BaseModel> {
  @observable
  props: T

  constructor(props: T) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get id() {
    return this.props.id
  }

  @action
  patch(data: Partial<T>) {
    this.props = {
      ...this.props,
      ...data
    }
  }
}