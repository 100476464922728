import { IBarChartViewModel } from "../BarChartViewModel/interface";
import { IButtonViewModel } from "../Buttons/interface";
import { IPanelSelectorViewModel } from "../PanelSelectorViewModel/interface";
import { IStatisticsTableViewModel } from "../StatisticsTableViewModel/interface";
import { ISummaryStatisticViewModel } from "../SummaryStatisticViewModel/interface";

export interface IStatisticsPageViewModel {
  targetsSelectorViewModel?: IPanelSelectorViewModel
  unitSelectorViewModel?: IPanelSelectorViewModel
  unitRepresentationSelectorViewModel?: IPanelSelectorViewModel
  storeysSelectorViewModel?: IPanelSelectorViewModel
  sectionsSelectorViewModel?: IPanelSelectorViewModel
  workTypeSelectorViewModel?: IPanelSelectorViewModel
  workStatusSelectorViewModel?: IPanelSelectorViewModel
  summaryStatisticsButtonViewModel?: IButtonViewModel
  summaryStatisticsForSectionsButtonViewModel?: IButtonViewModel

  barChartViewModels?: {
    key: string
    viewModel: IBarChartViewModel
  }[]

  statisticsTableViewModel?: IStatisticsTableViewModel

  summaryStatisticViewModel?: ISummaryStatisticViewModel

  summaryStatisticDialogViewModel: {
    open: boolean
    onClose: () => void
  }
}

export enum EWorkStatus {
  inProgress,
  completed,
  hasRemarks,
  notStarted
}