import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import { IPieChartViewModel } from "../../view-model/PieChartViewModel/interface"

export interface IPieChartProps {
  className?: string
  width: number
  height: number
  backgroundColor?: string
  viewModel: IPieChartViewModel
}


export const PieChart = observer((props: IPieChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const data = props.viewModel.data
  const backgroundColor = props.backgroundColor || "#fff"

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas && data) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        let lastend = 0;
        const total = data.reduce((acc, { value }) => acc + value, 0);

        const width = canvas.width;
        const height = canvas.height;
        const radius = width / 2;
        const centerX = width / 2;
        const centerY = height / 2;

        data.forEach(({ color, value }, index) => {
          const endAngle = lastend + (Math.PI * 2 * (value / total));

          ctx.fillStyle = color;
          ctx.beginPath();
          ctx.moveTo(centerX, centerY);
          ctx.arc(centerX, centerY, radius, lastend, endAngle, false);

          ctx.fill();

          ctx.beginPath();
          ctx.moveTo(centerX, centerY);
          ctx.lineTo(centerX + Math.cos(lastend) * (radius + 1), centerY + Math.sin(lastend) * (radius + 1));
          ctx.moveTo(centerX, centerY);
          if (index === data.length - 1) {
            ctx.lineTo(centerX + Math.cos(endAngle) * radius, centerY + Math.sin(endAngle) * radius);
          }

          ctx.lineWidth = 3;
          ctx.strokeStyle = backgroundColor;
          ctx.stroke();

          lastend = endAngle;
        })

        return () => {
          ctx.clearRect(0, 0, width, height);
        }
      }
    }
  }, [data, backgroundColor]);

  return <canvas
    className={props.className}
    ref={canvasRef}
    width={props.width}
    height={props.height}
    style={{
      width: props.width,
      height: props.height
    }}
  />;
})