import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IHistoryPageViewModel } from "../../view-model/HistoryPageViewModel/interface"
import BackButton from '../../components/Buttons/BackButton';
import { PanelSelector } from "../PanelSelector/PanelSelector";
import SearchField from '../../components/Inputs/SearchField';
import { StatusWrapper } from "../StatusWrapper/StatusWrapper";

const useClasses = makeStyles(theme => ({
  root: {
    padding: "100px 0 56px 0",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflow: "hidden"
  },
  header: {
    margin: "0px 0px 30px 26px",
    display: "flex",
    justifyContent: "space-between"
  },
  list: {
    flex: 1,
    overflow: "auto",
    userSelect: "none"
  },
  row: {
    height: 44,
    boxSizing: "content-box",
    fontSize: "12px",
    padding: "10px 26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0001"
    },
  },
  label: {
    fontWeight: "bold",
  },
  date: {
    whiteSpace: "nowrap",
    color: "#AAAAA9"
  },
  search: {
    margin: "0px 26px 20px 26px"
  },
}))

const useClassesSelector = makeStyles(() => ({
  root: {
    overflow: "auto",
    flex: 1,
    marginLeft: 10
  },
  padding: {
    padding: "0 30px"
  }
}))

interface IHistoryPageProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IHistoryPageViewModel
}

export const HistoryPage = observer((props: IHistoryPageProps) => {
  const classes = useClasses(props)
  const selectorClasses = useClassesSelector()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <BackButton onClick={props.viewModel.backButtonViewModel.onClick} />

        <PanelSelector
          classes={selectorClasses}
          viewModel={props.viewModel.actionFilterPanelSelectorViewModel}
        />
      </div>
      <SearchField className={classes.search} value={props.viewModel.searchViewModel.value} onChange={props.viewModel.searchViewModel.onChange} placeholder="Поиск" />
      <StatusWrapper className={classes.list} viewModel={props.viewModel.statusWrapperViewModel}>
        {
          props.viewModel.histories.map(history => (
            <div className={classes.row} onClick={history.onClick} key={history.key}>
              <div>
                <div className={classes.label}>{history.name}</div>
                <div>{history.user}</div>
              </div>
              <div className={classes.date}>{history.date}</div>
            </div>
          ))
        }
      </StatusWrapper>
    </div>
  )
})