import { action, computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { ECamera } from "../../models/Camera";
import { BaseFloatingWindowViewModel } from "../FloatingWindowViewModel/BaseFloatingWindowViewModel";
import { StaticFloatingWindowViewModel } from "../FloatingWindowViewModel/StaticFloatingWindowViewModel";
import { MeasurementsPanelViewModel } from "../MeasurementsPanelViewModel/MeasurementsPanelViewModel";
import { PropertiesPanelViewModel } from "../PropertiesPanelViewModel/PropertiesPanelViewModel";
import { RightViewerPanelViewModel } from "../RightViewerPanelViewModel/RightViewerPanelViewModel";
import { SarexViewerViewModel } from "../SarexViewerViewModel/SarexViewerViewModel";
import { ViewerContextMenuViewModel } from "../ViewerContextMenuViewModel/ViewerContextMenuViewModel";
import { IBimViewerV2ViewModel } from "./interface";

export class BimViewerV2ViewModel implements IBimViewerV2ViewModel {
  constructor(private props: { layers: ILayers }) {

    makeObservable(this)

    this.loadProperties()
  }

  @computed
  get sarexViewerViewModel() {
    return new SarexViewerViewModel(this.props)
  }

  @computed
  get rightViewerPanelViewModel() {
    return new RightViewerPanelViewModel(this.props)
  }

  @computed
  get propertiesPanel() {
    if (!this.propertiesPanelOpened) return

    return {
      floatingWindowViewModel: new BaseFloatingWindowViewModel({
        floatingWindowDataRepositoy: this.props.layers.usecases.ui.propertiesPanelFloatingWindowDataRepository
      }),
      propertiesPanelViewModel: new PropertiesPanelViewModel(this.props)
    }
  }

  @computed
  get measurementsPanel() {
    if (!this.measurementsPanelActive) return

    return {
      floatingWindowViewModel: new BaseFloatingWindowViewModel({
        floatingWindowDataRepositoy: this.props.layers.usecases.ui.measurementsDataRepository
      }),
      measurementsPanelViewModel: new MeasurementsPanelViewModel(this.props)
    }
  }

  @computed
  private get propertiesPanelOpened() {
    return this.props.layers.usecases.ui.propertiesPanelFloatingWindowDataRepository.get()?.opened || false
  }

  @computed
  private get measurementsPanelActive() {
    return this.props.layers.usecases.ui.measurementsDataRepository.get()?.toolActive
  }

  @computed
  get propertiesButtonViewModel() {
    return {
      active: this.propertiesPanelOpened,
      onClick: () => {
        this.props.layers.usecases.ui.propertiesPanelFloatingWindowDataRepository.patch({ opened: !this.propertiesPanelOpened })
      }
    }
  }

  @computed
  get cameraButtonViewModel() {
    const active = this.props.layers.usecases.ui.viewerDataRepository.get()?.camera === ECamera.orthographic
    return {
      active,
      onClick: () => {
        this.props.layers.usecases.ui.viewerDataRepository.patch({
          camera: active ? ECamera.default : ECamera.orthographic
        })
      }
    }
  }

  @computed
  get measurementButtonViewModel() {
    if (this.props.layers.usecases.device.isMobile) return

    const active = this.measurementsPanelActive
    return {
      active,
      onClick: () => {
        this.props.layers.usecases.ui.measurementsDataRepository.patch({
          toolActive: active ? false : true
        })
      }
    }
  }

  @computed
  get viewerContextMenu() {
    const contextMenu = this.props.layers.usecases.ui.temporaryViewerDataRepository.contextMenu

    if (!contextMenu) return

    return {
      floatingWindowViewModel: new StaticFloatingWindowViewModel({ position: { x: contextMenu.x, y: contextMenu.y } }),
      viewerContextMenuViewModel: new ViewerContextMenuViewModel(this.props)
    }
  }

  @observable
  private loadStatus = {
    loading: false,
    propertyProcessing: false,
    propertyLoaded: 0,
    totalProperty: 0,
    error: ""
  }

  async loadProperties() {
    this.loadStatus.loading = true

    try {
      const bims = await this.props.layers.usecases.bims.getSelectedBimsAsync()
      this.loadStatus.totalProperty = bims.length

      for (const bim of bims) {
        this.props.layers.repositories.modelElementsRepository.getByBimAsync(bim).then(elements => {
          if (elements.length === 0) {
            this.loadStatus.propertyProcessing = true
          } else {
            this.loadStatus.propertyLoaded++
          }
        }).catch(err => {
          this.loadStatus.error = "ошибка загрузки свойств"
        })
      }
    } catch (error) {
      this.loadStatus.error = "ошибка загрузки"
    }
  }

  @computed
  get loadingStatusPanel(): IBimViewerV2ViewModel["loadingStatusPanel"] {
    if (!this.loadStatus.loading) return
    if (this.loadStatus.error) return {
      status: this.loadStatus.error
    }
    if (this.loadStatus.totalProperty === 0) return {
      status: `загрузка свойств`
    }
    if (this.loadStatus.propertyProcessing) return {
      status: "идёт обработка модели"
    }

    if (this.loadStatus.propertyLoaded === this.loadStatus.totalProperty) return

    return {
      status: `загрузка свойств ${this.loadStatus.propertyLoaded}/${this.loadStatus.totalProperty}`
    }
  }
}