import { makeObservable, observable, reaction } from "mobx";
import Viewer from "@sarex-team/viewer";
import { ISarexViewerViewModel, TModel } from "../../view-model/SarexViewerViewModel/interface";
import { computedFn } from "mobx-utils";
import { ECamera } from "../../models/Camera";
import { Bim } from "../../models/Bim";

export type BIMColorSchema = Record<number, any>;
export type ColorSchema = BIMColorSchema;

type TSelectNodeListener = (key: "node-selected", callback: (props: { selectedNodeID, modelID }) => void) => void
type TSelectNodesWithBoxListener = (key: "nodes-selected-with-box", callback: (props: { selection: { [key: string]: number[] } }) => void) => void

export interface ModelViewerInterface {
  setEdgeDetection: (v: boolean) => void
  setEdgeDetectionStrength: (v: number) => void
  disposeAll: () => Promise<any>;
  disposeModel: (modelURL: string) => Promise<any>;
  getLoadedModels: () => Map<string, any>;
  selectNodes: (p: {
    [key: string]: {
      color?: number;
      elements: any[];
      // elements: TBimElement["sarex_id"][];
    }[];
  }) => Promise<any>;
  loadModelWithMetadata: ({
    urls,
    onProgress,
    getHeaders,
  }: {
    urls: {
      modelURL: string
      staticMetadataURL: string
      metadataURL: string

      filterObjects?: number[]
      modelFormat?: string
      rangeRequestsUsingQuery?: boolean;
    }[],
    onProgress?(res: any): any
    getHeaders?(): Promise<any>
  }) => Promise<void>;
  loadModel({
    url,
    onProgress,
    headers,
    modelFormat
  }: any): Promise<void>;
  setModelVisibility: (modelURL: string, visibility: boolean) => Promise<any>;
  colorizeABAPStatus: (
    modelId: string,
    colorizeInfo: ColorSchema
  ) => Promise<void>;
  colorizeMesh: (
    modelId: string,
    colorizeInfo: ColorSchema
  ) => Promise<void>;
  filterABAPStatus: (
    modelId: string,
    attributesToFilter: Array<number>
  ) => Promise<void>;
  addEventListener: TSelectNodeListener & TSelectNodesWithBoxListener & ((
    eventName: string,
    handler: (...props: any) => void
  ) => void);
  removeEventListener: (
    eventName: string,
    handler: (...props: any) => void
  ) => void;
  filterObjectsBesides: (modelURL: string, sarexIds: Array<number>) => void;
  filterObjects: (modelURL: string, sarexIds: Array<number>) => void;
  zoomToNode: (modelURL: string, sarexId: number) => void;
  setABAPStatus: (
    modelURL: string,
    sarexId: number,
    abapStatus: number
  ) => void;
  abortLoading(urls: string[]): void
  _scenePresenter: {
    picker: {
      _selectionBox: {
        domElement: HTMLElement
        _active: boolean
        resetState(): void
        _onMouseDown(e: any): void
        _onMouseMove(e: any): void
        _onMouseUp(e: any): void

        _orbitControls: {
          enabled: boolean
          stop(): void
        }
      }
    }
  }
}

interface IViewer {
  progressiveRendering: {
    renderOneFrame: Function
  }
  fitToScreen: Function
  modelViewer: ModelViewerInterface

  setCameraIsPerspective(v: boolean): void
  activateNavigationCube(v: boolean): void

  potreeViewer: {
    createMeasure(props: {}): void
    addMeasure(options: any, points: number[][]): {
      isEditable: boolean
    }
    removeAllMeasurements(): Promise<void>
    viewer: {
      renderer: {
        domElement: HTMLCanvasElement
      }
      scene: any
    }

    addEventListener(type: 'measurement-finished', callback: (props: {
      measurement: {
        points: {
          position: { x: number, y: number, z: number }
        }[]
      }
    }) => void): void
    removeEventListener(type: any, callback: any): void
  }
}


export class SarexViewer {
  @observable
  viewer: IViewer
  clearReactions = new Array<Function>()

  constructor(private props: { viewModel: ISarexViewerViewModel, container: HTMLDivElement }) {
    this.props = props

    makeObservable(this)

    const viewer: IViewer = new Viewer(this.props.container)

    this.viewer = viewer

    this.init()
  }

  async init() {
    this.props.viewModel.setGetScreenShotCanvasMethod?.(this.getScreenShotCanvas)

    let needFitToScreen = true

    // load and apply camera initial data
    if (this.props.viewModel.getCameraInitialDataAsync) {
      const cameraData = await this.props.viewModel.getCameraInitialDataAsync()

      if (cameraData) {
        needFitToScreen = false
        this.viewer.potreeViewer.viewer.scene.view.copy(cameraData)
        this.viewer.progressiveRendering.renderOneFrame()
      }
    }

    const startListenAfterLoadReactions = () => {
      // every 5 seconds get and save camera data. Potree don't have events to detect camera changes
      const cameraTimer = setInterval(() => {
        const view = this.viewer.potreeViewer.viewer.scene.view.clone()

        this.props.viewModel.onCameraDataChanged?.(
          JSON.parse(
            JSON.stringify(
              view
            )
          )
        )
      }, 3000)

      this.clearReactions.push(() => clearInterval(cameraTimer))
    }

    // load models and set viewer settings
    // if view model don't have camera initial data, we fit to screen
    this.clearReactions.push(
      reaction(() => this.props.viewModel.models, async (models, oldModels) => {
        if (models.size === 0) return

        for (const [id, model] of models) {
          if (oldModels) {
            if (!oldModels.has(id)) {
              await this.loadModelWithMetadata(model)
            }
          } else {
            await this.loadModelWithMetadata(model)
          }
        }

        if (needFitToScreen) {
          this.viewer.fitToScreen()
        }

        // await this.viewer.modelViewer.setEdgeDetection(true)
        // await this.viewer.modelViewer.setEdgeDetectionStrength(0.25)

        if (!oldModels || oldModels.size === 0) {
          startListenAfterLoadReactions()
        }
      }, {
        fireImmediately: true
      })
    )

    // listen models for hide
    this.clearReactions.push(
      reaction(() => this.props.viewModel.modelsNodesHide, (modelsNodesHide) => {
        this.hideMesh(modelsNodesHide)
      }, {
        fireImmediately: true
      })
    )

    // listen models for paint
    this.clearReactions.push(
      reaction(() => this.props.viewModel.modelsNodesColors, (modelsNodesColors) => {
        this.colorizeMesh(modelsNodesColors)
      }, {
        fireImmediately: true
      })
    )

    this.clearReactions.push(
      reaction(() => this.props.viewModel.measurements, (measurements, oldMeasurements) => {
        this.viewer.potreeViewer.removeAllMeasurements()
        for (const measurement of measurements) {
          this.viewer.potreeViewer.addMeasure({
            isEditable: false,
            closed: false
          }, measurement.points.map(point => ([point.x, point.y, point.z])))
        }
      }, {
        fireImmediately: true
      })
    )

    this.clearReactions.push(
      reaction(() => this.props.viewModel.camera, (camera) => {
        if (camera === ECamera.orthographic) {
          this.viewer.setCameraIsPerspective(false);
        } else {
          this.viewer.setCameraIsPerspective(true);
        }
      }, {
        fireImmediately: true
      })
    )

    this.clearReactions.push(
      reaction(() => this.props.viewModel.selectionBoxActive, (selectionBoxActive) => {
        if (selectionBoxActive) {
          this.activeSelectionBox()
        } else {
          this.finishSelectionBox()
        }
      }, {
        fireImmediately: true
      })
    )

    this.clearReactions.push(
      reaction(() => this.props.viewModel.editMeasurement, (editMeasurement) => {
        if (editMeasurement) {
          this.viewer.potreeViewer.createMeasure({})
          this.viewer.potreeViewer.addEventListener('measurement-finished', this.onMeasurementCreated)
        } else {
          this.viewer.potreeViewer.removeEventListener('measurement-finished', this.onMeasurementCreated)
        }
      }, {
        fireImmediately: true
      })
    )

    window.addEventListener("keydown", this.keydown)
    this.clearReactions.push(() => window.removeEventListener("keydown", this.keydown))

    window.addEventListener("keyup", this.keyup)
    this.clearReactions.push(() => window.removeEventListener("keyup", this.keyup))

    this.props.container.addEventListener("mousemove", this.onMouseMove)
    this.clearReactions.push(() => this.props.container.removeEventListener("mousemove", this.onMouseMove))

    this.props.container.addEventListener("contextmenu", this.onContextMenu)
    this.clearReactions.push(() => this.props.container.removeEventListener("contextmenu", this.onContextMenu))

    this.props.container.addEventListener("click", this.onClick)
    this.clearReactions.push(() => this.props.container.removeEventListener("click", this.onClick))

    this.props.container.addEventListener("touchstart", this.onTouchStart)
    this.clearReactions.push(() => this.props.container.removeEventListener("touchstart", this.onTouchStart))

    this.props.container.addEventListener("touchmove", this.onTouchMove)
    this.clearReactions.push(() => this.props.container.removeEventListener("touchmove", this.onTouchMove))

    this.props.container.addEventListener("touchend", this.onTouchEnd)
    this.clearReactions.push(() => this.props.container.removeEventListener("touchend", this.onTouchEnd))

    this.viewer.modelViewer.addEventListener("node-selected", this.nodeSelected)

    this.viewer.modelViewer.addEventListener('nodes-selected-with-box', this.nodesSelectedWithBox)
  }

  private getScreenShotCanvas = async (): Promise<HTMLCanvasElement> => {
    this.viewer.activateNavigationCube(false)
    this.viewer.progressiveRendering.renderOneFrame()

    await new Promise(res => requestAnimationFrame(res))

    const viwerCanvas: HTMLCanvasElement = this.viewer.potreeViewer.viewer.renderer.domElement
    const canvas = document.createElement("canvas")

    canvas.width = viwerCanvas.width
    canvas.height = viwerCanvas.height

    const ctx = canvas.getContext("2d")

    if (ctx) {
      // const grd = ctx.createRadialGradient(canvas.width / 2, canvas.height / 2, Math.max(canvas.width, canvas.height) / 2, canvas.width / 2, canvas.height / 2, 0);
      // grd.addColorStop(0, "#fff5");
      // grd.addColorStop(1, "#fff");
      ctx.fillStyle = "#fff5";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      await new Promise(res => requestAnimationFrame(res))

      ctx.drawImage(viwerCanvas, 0, 0)
    }

    await new Promise(res => requestAnimationFrame(res))

    this.viewer.activateNavigationCube(true)

    requestAnimationFrame(() => {
      canvas.remove()
    })

    return canvas
  }

  // selectionBox
  get selectionBox() {
    return this.viewer.modelViewer._scenePresenter.picker._selectionBox
  }

  activeSelectionBox() {
    this.selectionBox._active = true;
    this.selectionBox._orbitControls.enabled = false;
  }

  private onClick = () => {
    this.props.viewModel.onClick?.()
  }

  private onContextMenu = (e: MouseEvent) => {
    this.props.viewModel.onContextMenu?.({ x: e.offsetX, y: e.offsetY })
  }

  private onMouseMove = () => {
    this.props.viewModel.onMouseMove?.()
  }

  private onTouchStart = (e: TouchEvent) => {
    const touch = e.changedTouches[0]

    if (this.props.viewModel.onTouchStart) {
      const bb = this.props.container.getBoundingClientRect()
      this.props.viewModel.onTouchStart({
        x: touch.clientX - bb.x,
        y: touch.clientY - bb.y
      })
    }
    this.selectionBox._onMouseDown(touch)
  }

  private onTouchEnd = (e: TouchEvent) => {
    this.props.viewModel.onTouchEnd?.()
    this.selectionBox._onMouseUp(e.changedTouches[0])
  }

  private onTouchMove = (e: TouchEvent) => {
    this.props.viewModel.onTouchMove?.()

    this.selectionBox._onMouseMove(e.changedTouches[0])
  }

  finishSelectionBox() {
    this.selectionBox._orbitControls.stop();
    this.selectionBox._orbitControls.enabled = true;

    this.selectionBox._active = false;
    this.selectionBox.resetState();
  }
  //

  private onMeasurementCreated = (props: {
    measurement: {
      getTotalDistance(): number
      points: {
        position: { x: number, y: number, z: number }
      }[]
    }
  }) => {
    this.props.viewModel.onMeasurementCreated({
      distance: props.measurement.getTotalDistance(),
      points: props.measurement.points.map(point => (
        {
          x: point.position.x,
          y: point.position.y,
          z: point.position.z,
        }
      ))
    })
  }

  private keydown = (e: KeyboardEvent) => {
    this.props.viewModel.onKeyDown?.(e)
  }

  private keyup = (e: KeyboardEvent) => {
    this.props.viewModel.onKeyUp?.(e)
  }

  nodesSelectedWithBox = (props: { selection: { [key: string]: number[] } }) => {
    const modelsNodes: [string, number[]][] = []
    for (const model in props.selection) {
      modelsNodes.push([model, props.selection[model]])
    }
    this.props.viewModel.selectNodes(modelsNodes)

    if (this.props.viewModel.selectionBoxActive) {
      this.activeSelectionBox()
    }
  }

  nodeSelected = (props: { selectedNodeID: number, modelID: string }) => {
    this.props.viewModel.onNodeSelected?.(props)
  }

  colorizeMesh(modelsNodesColors: ISarexViewerViewModel["modelsNodesColors"]) {
    for (const { model, colorGroups } of modelsNodesColors) {
      this.viewer.modelViewer.colorizeMesh(model, colorGroups.map(({ color, nodes }) => ({
        color: this.getTHREEColor(color),
        elements: nodes,
      })))
    }
  }

  hideMesh(modelsNodesHide: ISarexViewerViewModel["modelsNodesHide"]) {
    for (const { model, nodes } of modelsNodesHide) {

      this.viewer.modelViewer.filterObjects(model, nodes)
    }
  }

  private getTHREEColor = computedFn((color: string) => {
    return new THREE.Color(color)
  })

  private async loadModelWithMetadata(model: TModel) {
    await this.viewer.modelViewer.loadModelWithMetadata({
      urls: [{
        modelFormat: model.modelFormat,
        modelURL: model.modelURL,
        metadataURL: model.metadataURL,
        staticMetadataURL: model.staticMetadataURL,
        rangeRequestsUsingQuery: model.rangeRequestsUsingQuery,
        filterObjects: await model.getFilterObjectsAsync?.()
      }],
      getHeaders: this.props.viewModel.getHeaders ? this.props.viewModel.getHeaders : undefined,
    })

    model.onModelLoaded()

    const colorGroups = await model.getColorGroupsAsync?.()

    if (colorGroups) {
      this.colorizeMesh([{ model: model.modelURL, colorGroups: colorGroups }])
    }
  }

  // remove reactions and listeners
  unmount() {
    this.clearReactions.forEach(reaction => reaction())

    this.props.viewModel.setGetScreenShotCanvasMethod?.()
  }
}