import { computed, makeObservable, reaction } from "mobx";
import { EPages } from "../../models/Pages";
import { ViewState } from "../ViewState/ViewState";
import { History, createBrowserHistory } from "history";
import { SelectedElements } from "../SelectedElements/SelectedElements";
import { EPanelSection } from "../../models/ViewState";
import { Remarks } from "../Remarks/Remarks";

// class for sync location with view state
export class Route {
  history: History

  private pathToPageDictionary = new Map<string, EPages>([
    ["/", EPages.main],
    ["/bim", EPages.viewer],
  ])

  constructor(private props: {
    viewState: ViewState
    selectedElements: SelectedElements
    remarks: Remarks
  }) {
    this.props = props

    makeObservable(this)

    this.history = createBrowserHistory()
    this.init()
  }

  @computed
  private get pageToPathDictionary(): Map<EPages, string> {
    // reverse map
    return new Map(
      Array.from(this.pathToPageDictionary.entries()).map(([path, page]) => ([page, path]))
    )
  }

  private makePath(page: EPages) {
    return this.pageToPathDictionary.get(page) || "/"
  }

  private changePath(page: EPages) {
    const path = this.makePath(page)
    this.history.push({
      pathname: path,
      search: this.history.location.search
    })
  }

  parseIdOrGuid(string: string): number | string {
    return Number(string) || string
  }

  private async init() {
    const urlSearchParams = new URLSearchParams(this.history.location.search)

    // get page from path
    const page = this.pathToPageDictionary.get(this.history.location.pathname) || EPages.main
    // set page
    this.props.viewState.setSelectedPage(page)

    // bims
    this.props.viewState.setSelectedBims(urlSearchParams.get("id")?.split(",").map(this.parseIdOrGuid))

    // collection
    const collection = urlSearchParams.get("collection")
    if (collection) {
      this.props.viewState.setSelectedCollection(this.parseIdOrGuid(collection))
    }

    // remarks
    const remarks = urlSearchParams.get("remark_id")?.split(",").map(this.parseIdOrGuid)
    if (remarks && remarks[0]) {
      await this.props.remarks.setSelectedRemark(remarks[0])
      this.props.viewState.setPanelSection(
        EPanelSection.remarks
      )
      this.props.viewState.setPanelOpened(true)
    }

    // prescriptions
    const prescriptions = urlSearchParams.get("prescription_id")?.split(",").map(this.parseIdOrGuid)
    if (prescriptions && prescriptions[0]) {
      this.props.viewState.setSelectedPrescriptions(prescriptions)
      this.props.viewState.setPanelSection(
        EPanelSection.prescriptions
      )
      this.props.viewState.setPanelOpened(true)
    }

    // elements
    const elements = urlSearchParams.get("elements")?.split(",").map(this.parseIdOrGuid)
    if (elements) {
      await this.props.selectedElements.selectBimElementsIds(elements)
    }

    // listen page to change path
    reaction(() => this.props.viewState.props.selectedPage, (page) => {
      this.changePath(page)
    })

    // listen selected bims to change search
    reaction(() => this.props.viewState.props.selectedBims, (bims) => {
      const urlSearchParams = new URLSearchParams(this.history.location.search)
      if (bims && bims.length > 0) {
        urlSearchParams.set("id", bims.join(","))
      } else {
        urlSearchParams.delete("id")
      }

      this.history.push({
        pathname: this.history.location.pathname,
        search: urlSearchParams.toString()
      })
    })

    // listen selected collection to change search
    reaction(() => this.props.viewState.props.selectedCollection, (collection) => {
      const urlSearchParams = new URLSearchParams(this.history.location.search)
      if (collection) {
        urlSearchParams.set("collection", String(collection))
      } else {
        urlSearchParams.delete("collection")
      }

      this.history.push({
        pathname: this.history.location.pathname,
        search: urlSearchParams.toString()
      })
    })

    // listen selected remarks to change search
    reaction(() => this.props.viewState.props.selectedRemakrs, (remarks) => {
      const urlSearchParams = new URLSearchParams(this.history.location.search)
      if (remarks && remarks.length > 0) {
        urlSearchParams.set("remark_id", String(remarks[0]))
      } else {
        urlSearchParams.delete("remark_id")
      }

      this.history.push({
        pathname: this.history.location.pathname,
        search: urlSearchParams.toString()
      })
    })

    // listen selected prescriptions to change search
    reaction(() => this.props.viewState.props.selectedPrescriptions, (prescriptions) => {
      const urlSearchParams = new URLSearchParams(this.history.location.search)
      if (prescriptions && prescriptions.length > 0) {
        urlSearchParams.set("prescription_id", String(prescriptions[0]))
      } else {
        urlSearchParams.delete("prescription_id")
      }

      this.history.push({
        pathname: this.history.location.pathname,
        search: urlSearchParams.toString()
      })
    })

    // listen selected elements to change search
    reaction(() => this.props.selectedElements.selectedBimElements, async () => {
      const bimElements = await this.props.selectedElements.getSelectedBimElementsAsync()
      const urlSearchParams = new URLSearchParams(this.history.location.search)
      const existBimElements = bimElements.filter(Boolean)

      if (existBimElements && existBimElements.length > 0) {
        urlSearchParams.set("elements", String(existBimElements.map(el => el?.id).join(",")))
      } else {
        urlSearchParams.delete("elements")
      }

      this.history.push({
        pathname: this.history.location.pathname,
        search: urlSearchParams.toString()
      })
    })
  }
}