import { computed, makeObservable, observable } from "mobx";
import { computedFn, fromPromise } from "mobx-utils";
import { ILayers } from "../../core/Layers/interface";
import { Bim } from "../../models/Bim";
import { IModelElement } from "../../models/ModelElement/interface";
import { IPaintElementsViewModel, IPaintModelElementsViewModel } from "./interface";

export class PaintElementsViewModel implements IPaintElementsViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get paintModelElementsViewModels() {
    return this.props.layers.usecases.bims.selectedBims.map(bim => new PaintModelElementsViewModel({ ...this.props, bim }))
  }
}

class PaintModelElementsViewModel implements IPaintModelElementsViewModel {
  constructor(private props: { layers: ILayers, bim: Bim }) {
    this.props = props

    makeObservable(this)

    this.loadData()
  }

  @observable
  loading = true

  private loadData() {
    Promise.all([
      this.props.layers.repositories.bimElementsRepository.getByBimIdAsync(this.props.bim.id),
      this.props.layers.repositories.remarksRepository.getByBimIdAsync(this.props.bim.id),
      this.props.layers.repositories.statusDictionaryRepository.getAllAsync(),
      this.props.layers.repositories.statusHistoryRepository.getActualByBimIdAsync(this.props.bim.id),
      this.props.layers.repositories.workTypesRepository.getAllAsync(),
      this.props.layers.repositories.materialsRepository.getAllAsync(),
      this.props.layers.repositories.constructionGroupsRepository.getAllAsync(),
    ]).then(() => {
      this.loading = false
    })
  }

  @computed
  get elementsColor() {
    const elements = this.props.layers.repositories.modelElementsRepository.getByBim(this.props.bim)
    if (this.loading) return []

    const elementsColor: {
      dbId: IModelElement["modelID"],
      color: THREE.Vector4 | undefined
    }[] = []

    for (const element of elements) {
      const color = this.getElementColor(element)

      elementsColor.push({
        dbId: element.modelID,
        color
      })
    }

    return elementsColor
  }

  @computed
  get urn() {
    return this.props.bim.urn
  }

  @computed
  get paintModelElementsViewModelsPromise() {
    return fromPromise(this.getPaintModelElementsViewModels())
  }

  async getPaintModelElementsViewModels() {
    return this.props.layers.usecases.bims.selectedBims.map(bim => new PaintModelElementsViewModel({ ...this.props, bim }))
  }

  private getElementColor(element: IModelElement): THREE.Vector4 | undefined {
    const color = this.props.layers.usecases.paintElements.getElementColor(element)

    return color ? this.getVector4FromColor(color) : undefined
  }

  private getVector4FromColor = computedFn((color: string, a = 1) => {
    const { r, g, b } = new THREE.Color(color);

    return new THREE.Vector4(r, g, b, a);
  })
}