import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { StatusDictionary, TStatusDictionaryProps } from "../../models/StatusDictionary";
import { StatusHistory } from "../../models/StatusHistory";
import { PanelSelectorSingleViewModel } from "../PanelSelectorViewModel/PanelSelectorSingleViewModel";
import { PanelSelectorViewModel } from "../PanelSelectorViewModel/PanelSelectorViewModel";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { IEditStatusFormViewModel } from "./interface";

export class EditStatusFormViewModel implements IEditStatusFormViewModel {
  constructor(private props: { layers: ILayers, onClose?(): void }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  private get statusDictionaries() {
    const forgeElement = this.props.layers.usecases.selectedElements.selectedForgeElements[0]

    return this.props.layers.usecases.statuses.getStatusDictionaryForForgeElement(forgeElement)
  }

  @computed
  private get activeStatusHistory() {
    const forgeElement = this.props.layers.usecases.selectedElements.selectedForgeElements[0]
    const bim = this.props.layers.repositories.bimsRepository.getById(forgeElement.bim)
    if (!bim) return

    const bimElement = this.props.layers.repositories.bimElementsRepository.getByGuid(bim.id, forgeElement.id)
    return bimElement ? this.props.layers.repositories.statusHistoryRepository.getActualByBimElement(bimElement) : undefined
  }

  @computed
  private get activeStatusDictionaries() {
    const statusDictionaries = this.statusDictionaries
    const activeStatusHistory = this.activeStatusHistory
    const currentStatusDictionaryIndex = activeStatusHistory ? statusDictionaries.findIndex(statusDictionary => statusDictionary.id === activeStatusHistory.status) : -1

    return statusDictionaries.filter((statusDictionary, index) => index <= currentStatusDictionaryIndex)
  }

  // @computed
  // get statusViewModel() {
  //   return new PanelSelectorViewModel<number, false>({
  //     value: this.activeStatusHistory?.status || -1,
  //     options: [
  //       {
  //         value: -1,
  //         label: "не назначено"
  //       },
  //       ...this.activeStatusDictionaries.map(step => ({
  //         value: step.id,
  //         label: step.name
  //       }))
  //     ]
  //   })
  // }

  @computed
  get statusViewModel() {
    return new PanelSelectorSingleViewModel({
      label: "Статус",
      defaultValue: this.activeStatusHistory?.status,
      options: [
        {
          value: undefined,
          label: "не назначено"
        },
        ...this.activeStatusDictionaries.map(step => ({
          value: step.id,
          label: step.name
        }))
      ]
    })
  }

  statusWrapperViewModel = new StatusWrapperViewModel()

  @computed
  get applyButtonViewModel() {
    return {
      onClick: () => this.applyNewStatus()
    }
  }

  @computed
  get cancelButtonViewModel() {
    return {
      onClick: () => {
        this.props.onClose?.()
      }
    }
  }

  private async applyNewStatus() {
    this.statusWrapperViewModel.load()

    const newStatusDictionary = this.statusViewModel.value

    const newActiveStatuses = await this.getNewActiveStatusHistories(newStatusDictionary)
    const removeStatuses = await this.getRemoveStatusHistories(newStatusDictionary)

    for (const status of newActiveStatuses) {
      await this.props.layers.repositories.statusHistoryRepository.edit(status.id, {
        isActive: true
      })
    }

    for (const status of removeStatuses) {
      await this.props.layers.repositories.statusHistoryRepository.delete(status.id)
    }

    this.statusWrapperViewModel.success()
    this.props.onClose?.()
  }

  private getActiveStatusDictionary(newStatusDictionary?: TStatusDictionaryProps["id"]): StatusDictionary | undefined {
    if (!newStatusDictionary) return

    return this.activeStatusDictionaries
      .find(statusDictionary => statusDictionary.id === newStatusDictionary)
  }

  private getRemoveStatusDictionary(newStatusDictionary?: TStatusDictionaryProps["id"]): StatusDictionary[] {
    if (!newStatusDictionary) return this.activeStatusDictionaries
    const index = this.activeStatusDictionaries.findIndex(statusDictionary => statusDictionary.id === newStatusDictionary)
    return this.activeStatusDictionaries.slice(index + 1)
  }

  private async getStatusHistories(): Promise<StatusHistory[]> {
    const bimElements = await this.props.layers.usecases.selectedElements.getSelectedBimElementsAsync()
    const statuses: StatusHistory[] = []
    for (const bimElement of bimElements) {
      if (bimElement) {
        const elementStatuses = await this.props.layers.repositories.statusHistoryRepository.getByBimElementAsync(bimElement)
        statuses.push(
          ...elementStatuses
        )
      }
    }
    return statuses
  }

  private async getNewActiveStatusHistories(newStatusDictionary?: TStatusDictionaryProps["id"]): Promise<StatusHistory[]> {
    const statusDictionary = this.getActiveStatusDictionary(newStatusDictionary)
    if (!statusDictionary) return []
    const statuses = await this.getStatusHistories()

    return statuses.filter(status => (status.status === statusDictionary.id) && !status.isActive)
  }

  private async getRemoveStatusHistories(newStatusDictionary?: TStatusDictionaryProps["id"]): Promise<StatusHistory[]> {
    const statusDictionary = this.getRemoveStatusDictionary(newStatusDictionary)
    if (!statusDictionary) return []
    const statuses = await this.getStatusHistories()

    return statuses.filter(status => statusDictionary.find(sd => sd.id === status.status))
  }
}