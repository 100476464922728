import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { targetsPageViewModel } from "../../stories/TargetsPage/data";
import { FilterPanelViewModel } from "../FilterPanel/FilterPanelViewModel";
import { HomeHeaderViewModel } from "../HomeHeaderViewModel/HomeHeaderViewModel";
import { ProjectsListViewModel } from "../ProjectsListViewModel/ProjectsListViewModel";
import { TargetsPageViewModel } from "../TargetsPageViewModel/TargetsPageViewModel";
import { IHomePageViewModel } from "./interface";

export class HomePageViewModel implements IHomePageViewModel {
    constructor(private props: { layers: ILayers }) {
        this.props = props

        makeObservable(this)
    }

    @computed
    get selectedProject() {
        return this.props.layers.usecases.viewState.props.selectedProjectId
    }

    @computed
    get homeHeaderViewModel() {
        return new HomeHeaderViewModel(this.props)
    }

    @computed
    get projectsListViewModel() {
        return new ProjectsListViewModel(this.props)
    }

    @computed
    get targetsPageViewModel() {
        return new TargetsPageViewModel(this.props)
    }

    @computed
    get filterPanelViewModel() {
        return new FilterPanelViewModel(this.props)
    }

    get isMobile() {
        return this.props.layers.usecases.device.isMobile
    }
}