import { computed, makeObservable, observable } from "mobx"
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel"
import { IPanelSelectorViewModel } from "./interface"
import { PanelSelectorCommonBehaviorViewModel, TOptionProps, TPanelSelectorCommonBehaviorViewModelProps } from "./PanelSelectorCommonBehaviorViewModel"

export class PanelSelectorSingleViewModel<T> extends PanelSelectorCommonBehaviorViewModel<T> implements IPanelSelectorViewModel {
  constructor(private props: {
    onChange?(): void
  } & ({
    defaultValue: T
  } | {
    valueViewModel: SimpleViewModel<T>
  }) & TPanelSelectorCommonBehaviorViewModelProps<T>) {
    super(props)

    this.props = props

    this.valueViewModel = "valueViewModel" in props ? props.valueViewModel : new SimpleViewModel({ value: props.defaultValue })
    makeObservable(this)
  }

  @observable
  valueViewModel: SimpleViewModel<T | undefined>

  @computed
  get valueLabel() {
    return this.value ? this.optionsMap.get(this.value)?.label || "значение отсутствует" : "не выбрано"
  }

  @computed
  public get value(): T | undefined {
    return this.valueViewModel.value
  }

  getOptionSelected(option: TOptionProps<T>) {
    return this.valueViewModel.value === option.value
  }

  deselectOptions() { }
  selectOptions() { }

  switchOption(option: TOptionProps<T>) {
    if (this.disabled) return
    if (option.disabled) return

    if (this.getOptionSelected(option)) {
      this.valueViewModel.onChange(undefined)
    } else {
      this.valueViewModel.onChange(option.value)
    }

    this.panelDropdownMenuViewModel.close()

    this.props.onChange?.()
  }
}