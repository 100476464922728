import { makeObservable } from "mobx";
import { Project, TProjectProps } from "../../models/Project";
import { IAuthRepository } from "../AuthRepository/interface";
import { IReadOnlyRepository } from "../Repository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { IProjectsRepository } from "./interface";

type TGetProjectResponse = {
  id: number
  code: string
  company: unknown
  cover: string
  name: string
}

export class RestProjectRepository implements IProjectsRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.repository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: `${props.host}api/core/project/`,
      Model: Project,
      serialyze(responseProp: TGetProjectResponse): TProjectProps {
        return {
          id: responseProp.id,
          name: responseProp.name,
          code: responseProp.code,
          image: responseProp.cover
        }
      }
    })
  }

  private repository: IReadOnlyRepository<Project>

  getById(props) {
    return this.repository.getById(props)
  }

  getByIdAsync(props) {
    return this.repository.getByIdAsync(props)
  }

  getAll() {
    return this.repository.getAll()
  }
}