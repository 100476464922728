import { computed, makeObservable, observable, ObservableSet } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { ERemarkStatus, TNewRemarkProps } from "../../models/Remark";
import { TUploadFileProps, UploadFile } from "../../models/UploadFile";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { numberFormat } from "../../heplpers/formats";
import { IRemarksFormViewModel } from "./interface";
import { UploadFilesListViewModel } from "../UploadFilesListViewModel/UploadFilesListViewModel";
import { UploadButtonViewModel } from "../Buttons/UploadButtonViewModel";
import { CheckboxWithLabelViewModelOverSimpleViewModel } from "../CheckboxViewModel/CheckboxWithLabelViewModelOverSimpleViewModel";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { ISimpleViewModel } from "../SimpleViewModel/interface";
import { TRemarkBaseProps } from "../../models/RemarkBase";
import { PanelSelectorSingleViewModel } from "../PanelSelectorViewModel/PanelSelectorSingleViewModel";
import { TCompanyProps } from "../../models/Company";
import { EOrientations } from "../PanelDropdownMenuViewModel/interface";

export class NewRemarksFormViewModel implements IRemarksFormViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }
  @observable
  private showFiles = false
  private files = new ObservableSet<TUploadFileProps["id"]>()

  @computed
  get activeStatusDictionary() {
    return this.props.layers.usecases.statuses.activeStatusDictionary
  }

  @computed
  get normativeDocumentViewModel() {
    if (!this.remarkBase || !this.remarkBase.requiresNormativeDocument) return

    return new SimpleViewModel({ value: "" })
  }

  @computed
  get remarkBase() {
    return this.remarkBaseViewModel.value ? this.props.layers.repositories.remarksBaseRepository.getById(this.remarkBaseViewModel.value) : undefined
  }

  // @computed
  // get remarkBaseViewModel() {
  //   return new PanelSelectorViewModel<number | string, false>({
  //     value: undefined,
  //     options: this.props.layers.usecases.remarks.remarksBaseForSelectedElements
  //       .sort((a, b) => a.commonRemark ? -1 : 1)
  //       .map(remarkBase => ({
  //         value: remarkBase.id,
  //         group: remarkBase.commonRemark ? "Общие замечания" : "Замечания для статусов",
  //         label: remarkBase.title
  //       }))
  //   })
  // }

  @computed
  get remarksBaseForSelectedElementsOptions() {
    return this.props.layers.usecases.remarks.remarksBaseForSelectedElements
      .sort((a, b) => {
        if (a.commonRemark === b.commonRemark) return a.title > b.title ? 1 : -1
        return a.commonRemark ? -1 : 1
      })
      .map(remarkBase => ({
        value: remarkBase.id,
        group: remarkBase.commonRemark ? "Общие замечания" : "Замечания для статуса",
        label: remarkBase.title
      }))
  }

  @computed
  get remarkBaseAllOptions() {
    type TOption = { value: TRemarkBaseProps["id"], group: string, label: string }

    // сортировки
    const sortByTitle = (a, b) => {
      return a.title > b.title ? 1 : -1
    }

    const sortByName = (a, b) => {
      return a.name > b.name ? 1 : -1
    }

    // для статуса не назначен
    const priorityOptions: TOption[] = []
    for (const remarkBase of this.props.layers.repositories.remarksBaseRepository.getCommonRemarks().sort(sortByTitle)) {
      priorityOptions.push({
        value: remarkBase.id,
        group: "Не назначен",
        label: remarkBase.title
      })
    }

    // для других статусов
    let options: TOption[] = []
    for (const baseStatus of this.props.layers.usecases.selectedStatuses.activeStatusDictionaryOptions.sort(sortByName)) {
      const groupOptions: TOption[] = []
      const remarksBaseForBaseStatus = this.props.layers.repositories.remarksBaseRepository.getByBaseStatus(baseStatus.id).sort(sortByTitle)
      const workType = this.props.layers.repositories.workTypesRepository.getById(baseStatus.workType)

      for (const remarkBase of remarksBaseForBaseStatus) {
        groupOptions.push({
          value: remarkBase.id,
          group: `${baseStatus.name} - ${workType?.fullName}`,
          label: remarkBase.title
        })
      }

      options = [
        ...options,
        ...groupOptions
      ]
    }

    return [
      ...priorityOptions,
      ...options
    ]
  }

  @computed
  get remarkBaseViewModel() {
    return new PanelSelectorSingleViewModel<TRemarkBaseProps["id"] | undefined>({
      orientation: EOrientations.right,
      defaultValue: undefined,
      label: "Замечание",
      options: this.props.layers.usecases.selectedElements.count === 0 ? this.remarkBaseAllOptions : this.remarksBaseForSelectedElementsOptions
    })
  }

  @computed
  get contractorViewModel() {
    return new PanelSelectorSingleViewModel<TCompanyProps["id"] | undefined>({
      orientation: EOrientations.right,
      defaultValue: undefined,
      label: "Подрядчик",
      options: this.props.layers.usecases.companies.contractorsForSelectedProjects.map(company => ({
        value: company.id,
        label: company.name,
        // group: company.scopeName
      }))
    })
  }

  @computed
  get controlViewModel() {
    const user = this.props.layers.repositories.authRepository.getUser()

    return new PanelSelectorSingleViewModel<TCompanyProps["id"] | undefined>({
      orientation: EOrientations.right,
      defaultValue: undefined,
      label: "Стройконтроль",
      options: user ? [{
        value: user.id,
        label: user.fullName
      }] : []
    })
  }

  @computed
  get deadlineViewModel() {
    return new SimpleViewModel({ value: (new Date()).toISOString() })
  }

  @computed
  get locationViewModel() {
    return new LocationViewModel(this.props)
  }

  @computed
  get commentViewModel() {
    return new SimpleViewModel({ value: "" })
  }

  @computed
  get showFilesViewModel() {
    return {
      label: `Посмотреть ${this.files.size} ${numberFormat(this.files.size, ["файл", "файла", "файлов"])}`,
      onClick: () => {
        if (this.files.size > 0) this.showFiles = true
      }
    }
  }

  @computed
  get showFilesDialogViewModel() {
    return {
      open: this.showFiles,
      onClose: () => this.showFiles = false
    }
  }

  @computed
  get uploadButtonViewModel() {
    return new UploadButtonViewModel({
      ...this.props,
      onAdd: (files: UploadFile[]) => {
        for (const file of files) {
          this.files.add(file.id)
        }
      }
    })
  }

  @computed
  get allowDelete() {
    return false
  }

  @computed
  get allowSave() {
    return true
  }

  @computed
  private get criticalViewModel() {
    return new SimpleViewModel({
      value: false
    })
  }

  @computed
  get criticalCheckboxWithLabelViewModel() {
    return new CheckboxWithLabelViewModelOverSimpleViewModel({
      simpleViewModel: this.criticalViewModel,
      label: "Критичное"
    })
  }

  statusWrapperViewModel = new StatusWrapperViewModel()

  @computed
  get newRemarkProps(): Omit<TNewRemarkProps, "elements" | "bim"> | undefined {
    if (
      !this.remarkBaseViewModel.value ||
      !this.contractorViewModel.value ||
      !this.controlViewModel.value ||
      !this.statusViewModel.value
    ) return

    return {
      body: this.commentViewModel.value,
      remarkBase: this.remarkBaseViewModel.value,
      location: this.locationViewModel.value,
      contractor: this.contractorViewModel.value,
      deadline: this.deadlineViewModel.value,
      status: this.statusViewModel.value,
      title: "title",
      supervisor: this.controlViewModel.value,
      attachments: Array.from(this.files),
      normativeDocument: this.normativeDocumentViewModel?.value || "",
      isCritical: this.criticalViewModel.value
    }
  }

  onDelete() { }

  @computed
  get saveButtonViewModel() {
    return {
      disabled: this.newRemarkProps ? !this.props.layers.usecases.remarks.getNewRemarkCanBeAdd(this.newRemarkProps) : true,
      onClick: async () => {
        if (!this.newRemarkProps) return
        const newRemarkProps = this.newRemarkProps

        if (newRemarkProps.remarkBase) {
          this.statusWrapperViewModel.load()
          await this.props.layers.usecases.remarks.addRemark(newRemarkProps)
          this.statusWrapperViewModel.success()
        }
      },
      label: "Добавить"
    }
  }

  @computed
  get statusViewModel() {
    return new PanelSelectorSingleViewModel<ERemarkStatus>({
      orientation: EOrientations.right,
      defaultValue: ERemarkStatus.opened,
      label: "Статус",
      options: [
        {
          value: ERemarkStatus.opened,
          label: "Открыто"
        }
      ]
    })
  }

  @computed
  get warnings() {
    const warnings: string[] = []

    if (!this.props.layers.usecases.remarks.canAssingRemarkForSelectedElements) {
      warnings.push("- выберите элементы")
    } else if (!this.props.layers.usecases.remarks.canAssingRemarkForSelectedStatusDictionary) {
      warnings.push("- выберите элементы с одинаковым статусом работ")
    }

    if (warnings.length > 0) {
      return {
        header: "чтобы добавить замечание",
        items: warnings
      }
    }
  }

  @computed
  get uploadFilesListViewModel() {
    const viewModel = new UploadFilesListViewModel({
      ...this.props,
      ids: this.files,
    })

    viewModel.onDelete = async (file: UploadFile) => {
      await this.props.layers.repositories.uploadFilesRepository.delete(file.id)
      this.files.delete(file.id)
      if (this.files.size === 0) this.showFiles = false
    }

    viewModel.onClose = () => {
      this.showFiles = false
    }

    return viewModel
  }
}
class LocationViewModel implements ISimpleViewModel<string> {
  constructor(private props: { layers: ILayers }) {
    this.simpleViewModel = new SimpleViewModel({
      value: this.location
    })

    this.props = props

    makeObservable(this)
  }

  simpleViewModel: ISimpleViewModel<string>

  get value() {
    return this.simpleViewModel.value
  }

  get disabled() {
    return this.simpleViewModel.disabled
  }

  get onChange() {
    return this.simpleViewModel.onChange
  }

  unique<T>(array: T[]): T[] {
    return Array.from((new Set(array)).values())
  }

  @computed
  get forgeElements() {
    return this.props.layers.usecases.selectedElements.selectedForgeElements
  }

  @computed
  get sections() {
    return this.unique(
      this.forgeElements.map(element => element.section).filter(Boolean)
    )
  }

  @computed
  get storeys() {
    return this.unique(
      this.forgeElements.map(element => element.storey).filter(Boolean)
    )
  }

  @computed
  get targetsNames() {
    const targetsNames: string[] = []
    for (const target of this.props.layers.usecases.selectedElements.selectedTargets) {
      targetsNames.push(target.name)
    }
    return targetsNames
  }

  getValue(array: (string | undefined)[], label: string = "") {
    return array.length > 0 ? `${label} ${array.join(", ")}` : ""
  }

  @computed
  get location() {
    return [
      this.getValue(this.targetsNames),
      this.getValue(this.storeys, "этаж"),
      this.getValue(this.sections, "секция"),
    ].filter(Boolean).join(", ")
  }
}