import { computed, makeObservable } from "mobx";
import { v4 } from "uuid";
import { BaseModel, TBaseModel } from "./BaseModel";
import { Entity } from "./Entity";
import { TUserProps } from "./User";

export type TUploadFileProps = {
  file: string
  name: string
  user: TUserProps["id"]
  uploadedAt: string
} & TBaseModel

export type TNewUploadFileProps = {
  file: string
  name: string
  user: TUserProps["id"]
}

export class UploadFile extends Entity<TUploadFileProps> {
  constructor(props: TUploadFileProps) {
    super(props)

    makeObservable(this)
  }

  @computed
  get file() {
    return this.props.file
  }

  @computed
  get name() {
    return this.props.name
  }

  @computed
  get user() {
    return this.props.user
  }

  @computed
  get uploadedAt() {
    return new Date(this.props.uploadedAt)
  }
}

export class NewUploadFile extends UploadFile {
  constructor(props: TNewUploadFileProps & Partial<BaseModel>) {
    super({
      id: props.id || v4(),
      uploadedAt: (new Date()).toISOString(),
      ...props
    })
  }
}