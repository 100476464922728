import { action, makeObservable, observable } from "mobx";
import { IViewerViewModel } from "../../view-model/ViewerViewModel/interface";

export class Viewer {
  @observable
  viewer: Autodesk.Viewing.Viewer3D | undefined = undefined

  constructor(private props: { viewModel: IViewerViewModel, container: HTMLDivElement }) {
    this.props = props

    makeObservable(this)

    this.mount()
  }

  private async mount() {
    if (!window.Autodesk) {
      await this.loadCss('https://developer.api.autodesk.com/modelderivative/v2/viewers/7.25.1/style.min.css');
      await this.loadScript('https://developer.api.autodesk.com/modelderivative/v2/viewers/7.25.1/viewer3D.min.js')
      await this.initializeAutodesk()
    }

    const viewer = new Autodesk.Viewing.GuiViewer3D(this.props.container, {
      disabledExtensions: {
        fusionOrbit: true,
        section: true,
        layerManager: true,
        modelBrowser: true,
      },
    });

    this.props.viewModel.setGetScreenShotCanvasMethod?.(this.getCanvas)

    viewer.addEventListener(Autodesk.Viewing.VIEWER_INITIALIZED, () => this.setViewer(viewer))

    viewer.start()
    viewer.setTheme('light-theme')
  }

  unmount() {
    this.props.viewModel.setGetScreenShotCanvasMethod?.()
  }

  private getCanvas = async () => {
    return this.viewer?.canvas
  }

  @action
  private setViewer(viewer: Autodesk.Viewing.Viewer3D) {
    this.viewer = viewer
  }

  private loadScript(src: string) {
    return new Promise(res => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = res;
    })
  }

  private async loadCss(src: string) {
    return new Promise(res => {
      const link = document.createElement('link');
      link.rel = "stylesheet";
      link.href = src;
      link.type = "text/css";
      document.head.appendChild(link);
      link.onload = res;
    })
  }

  private async initializeAutodesk() {
    return new Promise<void>(res => {
      Autodesk.Viewing.Initializer(
        {
          env: 'AutodeskProduction',
          api: 'derivativeV2',
          getAccessToken: async (setToken: (t: string, n: number) => void) => {
            const token = await this.props.viewModel.getForgeToken()
            setToken(token, 600)

            return token
          }
        },
        res,
      );
    })
  }
}