import { action, computed, makeObservable, observable } from "mobx"

export type TRequestStatusProps = {
  loading: boolean
  error?: string
}

export class RequestStatus {
  @observable

  private props: TRequestStatusProps

  constructor(props: TRequestStatusProps) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get loading() {
    return this.props.loading
  }

  @computed
  get error() {
    return this.props.error
  }

  @action
  load() {
    this.props.loading = true
    this.props.error = ""
  }

  @action
  success() {
    this.props.loading = false
    this.props.error = ""
  }

  @action
  fail(error: string) {
    this.props.loading = false
    this.props.error = error
  }
}