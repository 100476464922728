import { BimElement } from "../../models/BimElement";
import { Remark } from "../../models/Remark";
import { UploadFile } from "../../models/UploadFile";
import { RestBimElementsRepository } from "../../repositories/BimElementsRepository/RestBimElementsRepository";
import { RestCollectionsRepository } from "../../repositories/CollectionsRepository/RestCollectionsRepository";
import { RestPrescriptionRepository } from "../../repositories/PrescriptionRepository/RestPrescriptionRepository";
import { RestRemarksRepository } from "../../repositories/RemarksRepository/RestRemarksRepository";
import { RestStatusHistoryRepository } from "../../repositories/StatusHistoryRepository/RestStatusHistoryRepository";
import { RestTargetNoteRepository } from "../../repositories/TargetNoteRepository/RestTargetNoteRepository";
import { RestUploadFilesRepository } from "../../repositories/UploadFilesRepository/RestUploadFilesRepository";
import { BooleanViewModel } from "../../view-model/SimpleViewModel/BooleanViewModel";
import { EHTTPMethods } from "../../workers/FetchHandler/interface";
import { IOffline } from "./interface";

export class Offline implements IOffline {
  constructor(private props: {
    collectionsRepository: RestCollectionsRepository
    bimElementsRepository: RestBimElementsRepository
    statusHistoryRepository: RestStatusHistoryRepository
    remarksRepository: RestRemarksRepository
    uploadFilesRepository: RestUploadFilesRepository
    prescriptionsRepository: RestPrescriptionRepository
    targetNoteRepository: RestTargetNoteRepository
  }) {
    this.props = props

    this.init()

    this.sendPatches()
  }

  init() {
    window.addEventListener("online", this.online)
    window.addEventListener("offline", this.offline)
  }

  online = () => {
    this.offlineMode.onChange(false)

    this.sendPatches()
  }

  offline = () => {
    this.offlineMode.onChange(true)
  }

  offlineMode = new BooleanViewModel()

  async sendBimElementsPatches() {
    while (true) {
      const result = await this.props.bimElementsRepository.offlineRepository.sendNextPatch()
      if (result === undefined) break

      if (result.patch.method === EHTTPMethods.post) {
        //@ts-ignore
        const model: BimElement = result.model

        const statusHistoryPatches = await this.props.statusHistoryRepository.offlineRepository.getAllPatches()

        for (const patch of statusHistoryPatches) {
          if (patch.method === EHTTPMethods.post || patch.method === EHTTPMethods.patch) {
            if (patch.model.props.element === result.patch.id) {
              patch.model.props.element = model.id

              await this.props.statusHistoryRepository.offlineRepository.patchesRepository.savePatch(patch)
            }
          }
        }

        const remarksPatches = await this.props.remarksRepository.offlineRepository.getAllPatches()

        for (const patch of remarksPatches) {
          if (patch.method === EHTTPMethods.post || patch.method === EHTTPMethods.patch) {
            if (patch.model.props.elements.includes(result.patch.id)) {
              patch.model.props.attachments = this.replaceArrayElement(patch.model.props.attachments, result.patch.id, model.id)

              await this.props.remarksRepository.offlineRepository.patchesRepository.savePatch(patch)
            }
          }
        }
      }
    }
  }

  replaceArrayElement<T>(array: T[], replaceElement: T, newElement: T) {
    return array.map(item => item === replaceElement ? newElement : item)
  }

  async sendUploadFilesPatches() {
    while (true) {
      const result = await this.props.uploadFilesRepository.offlineRepository.sendNextPatch()
      if (result === undefined) break

      if (result.patch.method === EHTTPMethods.post) {
        //@ts-ignore
        const model: UploadFile = result.model

        const statusHistoryPatches = await this.props.statusHistoryRepository.offlineRepository.getAllPatches()

        for (const patch of statusHistoryPatches) {
          if (patch.method === EHTTPMethods.post || patch.method === EHTTPMethods.patch) {
            if (patch.model.props.files.includes(result.patch.id)) {
              patch.model.props.files = this.replaceArrayElement(patch.model.props.files, result.patch.id, model.id)

              await this.props.statusHistoryRepository.offlineRepository.patchesRepository.savePatch(patch)
            }
          }
        }

        const remarksPatches = await this.props.remarksRepository.offlineRepository.getAllPatches()

        for (const patch of remarksPatches) {
          if (patch.method === EHTTPMethods.post || patch.method === EHTTPMethods.patch) {
            if (patch.model.props.attachments.includes(result.patch.id)) {
              patch.model.props.attachments = this.replaceArrayElement(patch.model.props.attachments, result.patch.id, model.id)

              await this.props.remarksRepository.offlineRepository.patchesRepository.savePatch(patch)
            }
          }
        }
      }
    }
  }

  async sendRemarksPatches() {
    while (true) {
      const result = await this.props.remarksRepository.offlineRepository.sendNextPatch()
      if (result === undefined) break

      if (result.patch.method === EHTTPMethods.post) {
        //@ts-ignore
        const model: Remark = result.model

        const prescriptionsPatches = await this.props.prescriptionsRepository.offlineRepository.getAllPatches()

        for (const patch of prescriptionsPatches) {
          if (patch.method === EHTTPMethods.post || patch.method === EHTTPMethods.patch) {
            if (patch.model.props.remarks.includes(result.patch.id)) {
              patch.model.props.remarks = this.replaceArrayElement(patch.model.props.remarks, result.patch.id, model.id)

              await this.props.prescriptionsRepository.offlineRepository.patchesRepository.savePatch(patch)
            }
          }
        }
      }
    }
  }

  async sendPatches() {
    await this.props.collectionsRepository.offlineRepository.sendAllPatches()

    await this.sendBimElementsPatches()

    await this.sendUploadFilesPatches()

    await this.props.statusHistoryRepository.offlineRepository.sendAllPatches()

    await this.sendRemarksPatches()

    await this.props.prescriptionsRepository.offlineRepository.sendAllPatches()

    await this.props.targetNoteRepository.offlineRepository.sendAllPatches()
  }
}