export interface IMeasurement {
  id: string
  name: string
  type: EMeasurementType
  points: TMeasurementPoint[]
}

export enum EMeasurementType {
  polyline = "polyline"
}

export type TMeasurementPoint = {
  x: number
  y: number
  z: number
}