import { IButtonViewModel } from "../Buttons/interface";
import { IPrescriptionFormViewModel } from "../PrescriptionFormViewModel/interface";
import { IPrescriptionsListViewModel } from "../PrescriptionsListViewModel/interface";

export interface IPrescriptionsPageViewModel {
  addButtonViewModel?: {
    onClick(): void
  }
  showButtonViewModel?: IButtonViewModel
  onBack(): void
  content: EPrescriptionsPageContent

  prescriptionsListViewModel: IPrescriptionsListViewModel
  prescriptionFormViewModel: IPrescriptionFormViewModel
}

export enum EPrescriptionsPageContent {
  form = "form",
  list = "list"
}