import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IViewerContextMenuViewModel } from "../../view-model/ViewerContextMenuViewModel/interface"

const useClasses = makeStyles(theme => ({
  root: {
    boxShadow: "1px 3px 10px 0 rgb(0 0 0 / 40%)",
    backgroundColor: "#fff",
    opacity: 0.95,
    borderRadius: 4,
  },
  item: {
    padding: "10px 15px",
    // lineHeight: "16px",
    fontSize: "14px",

    "&:hover": {
      backgroundColor: "#0001"
    },
    cursor: "pointer"
  }
}))

interface IViewerContextMenuProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IViewerContextMenuViewModel
}

export const ViewerContextMenu = observer((props: IViewerContextMenuProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      {
        props.viewModel.items.map(item => (
          <div className={classes.item} key={item.key} onClick={item.onClick}>
            {item.label}
          </div>
        ))
      }
    </div>
  )
})