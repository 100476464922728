import { BaseModel } from "../../models/BaseModel";
import { Entity } from "../../models/Entity";
import { IPatchesRepository, TAddPatch, TDeletePatch, TEditPatch, TPatch } from "./interface"
import { IDBPDatabase } from 'idb';
import { EHTTPMethods } from "../../workers/FetchHandler/interface";
import { ObservableMap, toJS } from "mobx";

type TJsonPatch<Model extends BaseModel> = ({
  method: EHTTPMethods.post
  model: Model
} | {
  method: EHTTPMethods.patch
  model: Model
} | {
  method: EHTTPMethods.delete
}) & {
  id: Model["id"]
  createdAt: string
}

export class IndexedDBPatchesRepository<Model extends Entity<BaseModel>> implements IPatchesRepository<Model> {
  constructor(private props: {
    db: IDBPDatabase
    name: string
    Model: new (props: Model["props"]) => Model
  }) {
    this.props = props

    const init = this._init()

    this.init = () => init
  }

  init: () => Promise<any>

  async _init() {
    const patches: TJsonPatch<Model>[] = await this.props.db.getAll(this.props.name)
    this.patches.merge(patches.map(patch => ([patch.id, this.jsonToPatch(patch)])))
  }

  patches = new ObservableMap<Model["id"], TPatch<Model>>()

  getById(id: Model["id"]) {
    return this.patches.get(id)
  }

  async getByIdAsync(id: Model["id"]) {
    await this.init()

    return this.patches.get(id)
  }

  getAll() {
    return Array.from(this.patches.values())
  }

  getAddPatches(): TAddPatch<Model>[] {
    const patches = this.getAll()

    //@ts-ignore
    return patches.filter(patch => patch.method === EHTTPMethods.post)
  }

  jsonToPatch(jsonPatch: TJsonPatch<Model>): TPatch<Model> {
    return {
      id: jsonPatch.id,
      method: jsonPatch.method,
      createdAt: new Date(jsonPatch.createdAt),
      //@ts-ignore
      model: jsonPatch.method === EHTTPMethods.delete ? undefined : new this.props.Model(jsonPatch.model)
    }
  }

  getPatchJson(patch: TPatch<Model>) {
    switch (patch.method) {
      case EHTTPMethods.post:
        return {
          id: patch.id,
          createdAt: patch.createdAt.toISOString(),
          model: toJS(patch.model.props),
          method: patch.method
        }
      case EHTTPMethods.patch:
        return {
          id: patch.id,
          createdAt: patch.createdAt.toISOString(),
          model: toJS(patch.model.props),
          method: patch.method
        }
      case EHTTPMethods.delete:
        return {
          id: patch.id,
          createdAt: patch.createdAt.toISOString(),
          method: patch.method
        }
    }
  }

  putToDb(patch: TPatch<Model>) {
    return this.props.db.put(this.props.name, this.getPatchJson(patch), patch.id)
  }

  async savePatch(patch: TPatch<Model>) {
    await this.putToDb(patch)

    return patch
  }

  deleteFromDb(id: Model["id"]) {
    return this.props.db.delete(this.props.name, id)
  }

  async remove(id) {
    await this.deleteFromDb(id)
    this.patches.delete(id)
  }

  async getFirstPatchAsync() {
    await this.init()
    return this.patches.values().next().value
  }

  async getAllAsync() {
    await this.init()
    return this.getAll()
  }

  async add(model: Model) {
    const patch: TAddPatch<Model> = {
      id: model.id,
      method: EHTTPMethods.post,
      model: model,
      createdAt: new Date()
    }

    await this.putToDb(patch)
    this.patches.set(patch.id, patch)

    return patch
  }

  async edit(model: Model) {
    const patch = await this.getById(model.id)

    if (patch && patch.method === EHTTPMethods.post) {
      const patch: TAddPatch<Model> = {
        id: model.id,
        method: EHTTPMethods.post,
        model: model,
        createdAt: new Date()
      }
      await this.putToDb(patch)
      this.patches.set(patch.id, patch)

      return patch
    } else {
      const patch: TEditPatch<Model> = {
        id: model.id,
        method: EHTTPMethods.patch,
        model: model,
        createdAt: new Date()
      }

      await this.putToDb(patch)
      this.patches.set(patch.id, patch)

      return patch
    }
  }

  async delete(id: Model["id"]) {
    const patch = await this.getById(id)

    if (patch && patch.method === EHTTPMethods.post) {
      this.deleteFromDb(patch.id)
      this.patches.delete(patch.id)
    } else {
      const patch: TDeletePatch<Model> = {
        id: id,
        method: EHTTPMethods.delete,
        createdAt: new Date()
      }

      await this.putToDb(patch)
      this.patches.set(patch.id, patch)

      return patch
    }
  }
}