import { action, makeObservable, observable } from "mobx";
import { IDatePickerViewModel } from "./interface";

export class DatePickerViewModel implements IDatePickerViewModel {
  constructor(private props: {}) {
    this.props = props

    makeObservable(this)
  }

  @observable
  value = new Date()

  @observable
  open = false

  @observable
  applyButtonLabel = "Применить"

  @action
  onClose = () => {
    this.open = false
  }

  onChange = (value: Date) => {
    this.value = value
    this.open = false
  }
}