import { format } from "date-fns";
import { computed, makeObservable, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import { EPages } from "../../models/Pages";
import { TProjectProps } from "../../models/Project";
import { ERemarkStatus, Remark } from "../../models/Remark";
import { RemarkBase } from "../../models/RemarkBase";
import { TTargetProps } from "../../models/Target";
import { EPanelSection } from "../../models/ViewState";
import { ICompaniesRepository } from "../../repositories/CompaniesRepository/interface";
import { IRemarkBaseRepository } from "../../repositories/RemarkBaseRepository/interface";
import { ITargetsRepository } from "../../repositories/TargetsRepository/interface";
import { IUsersRepository } from "../../repositories/UsersRepository/interface";
import { TRemarkTable } from "../../view-model/RemarksTableViewModel/interface";
import { FilterPanel } from "../FilterPanel/FilterPanel";
import { EActions, Permissions } from "../Permissions/Permissions";
import { Remarks } from "../Remarks/Remarks";
import { ViewState } from "../ViewState/ViewState";

type TPreparedRemark = TRemarkTable & { filter: string }

export class FiltredRemarksTable {
  constructor(private props: {
    remarks: Remarks
    remarksBaseRepository: IRemarkBaseRepository
    usersRepository: IUsersRepository
    companiesRepository: ICompaniesRepository
    viewState: ViewState
    targetsRepository: ITargetsRepository
    filterPanel: FilterPanel
    permissions: Permissions
  }) {
    this.props = props
  }

  private getRemarkStatusName(status: ERemarkStatus): string {
    switch (status) {
      case ERemarkStatus.opened:
        return "Открыто"
      case ERemarkStatus.closed:
        return "Закрыто"
      case ERemarkStatus.pending:
        return "Ожидает"
    }
  }

  getFiltredRemarksByProjectId(id: TProjectProps["id"]) {
    const remarksSet = new Set<TRemarkTable & {
      filter: string;
    }>()
    const targets = this.props.targetsRepository.getByProjectId(id)

    for (const target of targets) {
      const remarks = this.getFiltredRemarksByTargetId(target.id)
      for (const remark of remarks) {
        remarksSet.add(remark)
      }
    }

    return Array.from(remarksSet)
  }

  getContent(remark: Remark, remarkBase: RemarkBase | undefined): string {
    return [remarkBase?.title || "", remark.normativeDocument].filter(Boolean).join(", ")
  }

  private prepareRemark(remark: Remark): TPreparedRemark {
    const remarkBase = this.props.remarksBaseRepository.getById(remark.remarkBase)
    const contractor = this.props.companiesRepository.getById(remark.contractor)

    const row: TPreparedRemark = {
      id: remark.id,
      key: remark.id,
      code: this.props.remarks.getRemarkCode(remark),
      content: this.getContent(remark, remarkBase),
      dealine: format(remark.deadline, "dd.MM.yyyy"),
      createdAt: format(remark.createdAt, "dd.MM.yyyy"),
      owner: this.props.usersRepository.getById(remark.owner)?.fullName || "",
      onOpen: this.props.permissions.actionIsAllowed(EActions.showModels) ? () => {
        this.props.viewState.setSelectedBims([remark.bim])
        this.props.remarks.setSelectedRemark(remark.id)
        this.props.viewState.setSelectedPage(EPages.viewer)
        this.props.viewState.setPanelSection(
          EPanelSection.remarks
        )
        this.props.viewState.setPanelOpened(true)
      } : undefined,
      contractor: contractor?.name || "",
      status: `${remark.expired && !remark.closed ? "!" : ""}${this.getRemarkStatusName(remark.status)}`,
      statusColor: remark.statusColor,
      filter: ""
    }

    row.filter = `
      ${row.id}
      ${row.code}
      ${row.content}
      ${row.dealine}
      ${row.createdAt}
      ${row.owner}
      ${row.contractor}
      ${row.status}
    `.toLowerCase()

    return row
  }

  getPreparedTargetRemarks = computedFn((id: TTargetProps["id"]) => {
    const result: (TRemarkTable & { filter: string })[] = []
    const remarks = this.props.remarks.getRemarksByTargetId(id)

    for (const remark of remarks) {
      result.push(
        this.prepareRemark(remark)
      )
    }

    return result
  })

  getPreparedRemarks(remarks: Remark[]) {
    const result: TPreparedRemark[] = []

    for (const remark of remarks) {
      result.push(
        this.prepareRemark(remark)
      )
    }

    return result
  }

  searchRemarks(search: string, preparedRemakrs: TPreparedRemark[]) {
    return preparedRemakrs.filter(({ filter }) => {
      return (
        filter.includes(search)
      )
    })
  }

  getFiltredRemarksByTargetIdPromise(id: TTargetProps["id"]) {
    return this.props.remarks.getRemarksByTargetIdAsync(id)
  }

  getFiltredRemarksByTargetId(id: TTargetProps["id"]) {
    const targetRemarks = this.props.remarks.getRemarksByTargetId(id)

    const filtredRemarks = this.props.filterPanel.filterRemarks(targetRemarks)
    const preparedRemarks = this.getPreparedRemarks(filtredRemarks)

    const search = this.props.viewState.props.mainPageSearch.toLowerCase()

    return this.searchRemarks(search, preparedRemarks)
  }
}