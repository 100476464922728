import { IRepositories } from "../../core/Layers/repositories"

export class Auth {
  constructor(private props: { repositories: IRepositories }) {
    this.props = props
  }

  logout() {
    this.props.repositories.authRepository.logout()
    // this.props.repositories.projectsRepository.resetCache()
    // this.props.repositories.bimsRepository.resetCache()
  }
}