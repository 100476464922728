import { Project } from "../../models/Project";
import { TTargetProps } from "../../models/Target";
import { IProjectsRepository } from "../../repositories/ProjectsRepository/interface";
import { ITargetsRepository } from "../../repositories/TargetsRepository/interface";

export class Targets {
  constructor(private props: { targetsRepository: ITargetsRepository, projectsRepository: IProjectsRepository }) {
    this.props = props
  }

  getProjectByTargetId(id: TTargetProps["id"]) {
    const projectId = this.props.targetsRepository.getById(id)?.project
    if (!projectId) return
    return this.props.projectsRepository.getById(projectId)
  }

  async getTargetsForProjectAsync(project: Project) {
    return this.props.targetsRepository.getByProjectIdAsync(project.id)
  }

  async getProjectByTargetIdAsync(id: TTargetProps["id"]) {
    const target = await this.props.targetsRepository.getByIdAsync(id)
    if (!target) return
    return this.props.projectsRepository.getByIdAsync(target.project)
  }
}