import { isAfter, isBefore, isSameDay } from "date-fns"
import { computed, makeObservable } from "mobx"
import { computedFn } from "mobx-utils"
import { BimElement } from "../../models/BimElement"
import { IModelElement } from "../../models/ModelElement/interface"
import { ERemarkStatus, ERemarkStatusColor } from "../../models/Remark"
import { IBimElementsRepository } from "../../repositories/BimElementsRepository/interface"
import { IBimsRepository } from "../../repositories/BimsRepository/interface"
import { IBimStatusRepository } from "../../repositories/BimStatusRepository/interface"
import { IStatusDictionaryRepository } from "../../repositories/StatusDictionaryRepository/interface"
import { IStatusHistoryRepository } from "../../repositories/StatusHistoryRepository/interface"
import { PlanningMode } from "../PlanningMode/PlanningMode"
import { Remarks } from "../Remarks/Remarks"
import { Statuses } from "../Statuses/Statuses"
import { UI } from "../UI/UI"
import { ViewState } from "../ViewState/ViewState"

export class PaintElements {
  constructor(private props: {
    viewState: ViewState
    bimsRepository: IBimsRepository
    bimElementsRepository: IBimElementsRepository
    planningMode: PlanningMode
    statusHistoryRepository: IStatusHistoryRepository
    bimStatusRepository: IBimStatusRepository
    statuses: Statuses
    remarks: Remarks
    statusDictionaryRepository: IStatusDictionaryRepository
    ui: UI
  }) {
    this.props = props

    makeObservable(this)
  }

  // defaultColor = "rgb(255,255,255)"
  defaultColor = "rgb(240,240,240)"
  completedColor = "#4caf50"
  completedAfterDeadlineColor = "#9ccc65"
  inProgressColor = "#ffeb3b"
  errorColor = ERemarkStatusColor.open
  overdueDate = "#E30B5C"
  // dateColor = "#178deb"
  dateColor = "#8ac6f5"
  baseColor = "rgb(245,245,245)"
  warningColor = "#e7b826"

  @computed
  private get now() {
    return new Date()
  }

  // warnign! computed color, when element not selected and not changed
  // if element drop select, getElementColor compute new color
  getElementColor = computedFn((element: IModelElement): string | undefined => {
    if (!this.props.ui.viewerDataRepository.get()?.paintElementsInStatusColor) return
    const bim = this.props.bimsRepository.getById(element.bim)
    if (!bim) return this.defaultColor

    const bimElement = this.props.bimElementsRepository.getByGuid(bim.id, element.id)
    if (!bimElement) return this.defaultColor

    if (bimElement) {
      if (this.props.planningMode.painByDateViewModel.value) {
        return this.getBimElementColorByPlanningDate(bimElement)
      } else if (this.props.planningMode.active.value) {
        return this.getBimElementColorForPlanningMode(bimElement)
      } else {
        return this.getBimElementColorByStatusAndRemarks(bimElement)
      }
    }

    return this.defaultColor
  })

  private getBimElementColorForPlanningMode(bimElement: BimElement): string {
    const status = this.props.bimStatusRepository.getActualByBimElement(bimElement)
    if (!status) return this.baseColor

    return this.dateColor
  }

  private getBimElementColorByPlanningDate(bimElement: BimElement): string {
    const status = this.props.bimStatusRepository.getActualByBimElement(bimElement)
    if (!status) return this.baseColor

    const activeStatus = this.props.statusHistoryRepository.getActualByBimElement(bimElement)
    const completed = this.props.statuses.getBimElementCompleted(bimElement)

    if (completed && activeStatus) {
      if (isBefore(activeStatus.date, status.deadline) || isSameDay(activeStatus.date, status.deadline)) return this.completedColor

      return this.completedAfterDeadlineColor
    }

    if (isAfter(this.now, status.deadline) && !isSameDay(this.now, status.deadline)) return this.overdueDate

    return this.dateColor
  }

  private getBimElementColorByStatusAndRemarks(bimElement: BimElement): string {
    const remarks = this.props.remarks.getRemarksByBimElement(bimElement)
    if (remarks.some(remark => remark.props.status === ERemarkStatus.opened)) return this.errorColor
    if (remarks.some(remark => remark.status === ERemarkStatus.pending)) return this.warningColor

    const status = this.props.statusHistoryRepository.getActualByBimElement(bimElement)

    if (status) {
      const statusDictionary = this.props.statusDictionaryRepository.getById(status.status)
      if (!statusDictionary) return this.defaultColor
      if (statusDictionary?.color) return statusDictionary.color

      const completed = this.props.statuses.getBimElementCompleted(bimElement)
      if (completed) {
        return this.completedColor
      }
      return this.inProgressColor
    }

    return this.defaultColor
  }
}