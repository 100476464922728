import axios from "axios";
import { action, makeObservable, ObservableMap } from "mobx";
import { SimpleCache } from "../../models/Cache";
import { TForgeMetadata, TForgeProperties } from "../../models/Forge";
import { EVariants, Notifications } from "../../usecase/Notifications/Notifications";
import { IForgeTokenRepository } from "../ForgeTokenRepository/interface";
import { IForgeRepository } from "./interface";

export class ForgeRepository implements IForgeRepository {
  private host = "https://developer.api.autodesk.com"

  private metadataCache = new ObservableMap<string, SimpleCache<TForgeMetadata>>()
  private propertiesCache = new ObservableMap<string, SimpleCache<TForgeProperties>>()

  constructor(private props: {
    forgeTokenRepository: IForgeTokenRepository
    notifications: Notifications
  }) {
    this.props = props

    makeObservable(this)
  }

  getPropertiesByUrn(urn: string): TForgeProperties | undefined {
    this.updateProperties(urn)

    return (this.propertiesCache.get(urn) || this.loadProperties(urn)).data
  }

  async getPropertiesByUrnAsync(urn: string): Promise<TForgeProperties | undefined> {
    await this.updateProperties(urn)

    return (this.propertiesCache.get(urn) || this.loadProperties(urn)).promise
  }

  private getMetadataByUrn(urn: string) {
    this.updateMetadata(urn)

    return (this.metadataCache.get(urn) || this.loadMetadata({ urn })).data
  }

  private async getMetadataByUrnAsync(urn: string) {
    this.updateMetadata(urn)

    return (this.metadataCache.get(urn) || this.loadMetadata({ urn })).promise
  }

  private updateProperties(urn: string) {
    const propertiesCache = this.propertiesCache.get(urn)
    if (propertiesCache) return propertiesCache.promise

    return this.loadProperties(urn).promise
  }

  private updateMetadata(urn: string): Promise<TForgeMetadata | undefined> {
    const metadataCache = this.metadataCache.get(urn)
    if (metadataCache) return metadataCache.promise

    return this.loadMetadata({ urn }).promise
  }

  private async _loadMetadata(props: { urn: string, guid?: string }) {
    let url = `${this.host}/modelderivative/v2/designdata/${props.urn}/metadata`;
    if (props.guid) {
      url += `/${props.guid}`;
    }
    const token = await this.props.forgeTokenRepository.getTokenAsync()

    const options = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios.get<{
      data: {
        metadata: {
          guid: string
          name: string
          role: string
        }[]
      }
    }>(url, options)

    return response.data
  }

  private delay(ms: number) {
    return new Promise(res => setTimeout(res, ms))
  }

  private loadMetadata(props: { urn: string, guid?: string }) {
    const cache = new SimpleCache({
      promise: new Promise<TForgeMetadata>(async res => {
        try {
          let data = await this._loadMetadata(props)
          let notificationKey: number | string | undefined = undefined

          while (!data || !data.data || !data.data.metadata || data.data.metadata.length === 0) {
            notificationKey ? undefined : notificationKey = this.props.notifications.wait("идёт подготовка свойств модели...")
            await this.delay(5000);
            data = await this._loadMetadata(props)
          }

          notificationKey ? this.props.notifications.close(notificationKey) : undefined

          res(data.data.metadata)
        } catch (error) {
          this.props.notifications.wait("ошибка загрузки свойств", {
            variant: EVariants.error
          })
        }
      })
    })

    this.setMetadataCache(props.urn, cache)

    return cache
  }

  @action
  private setMetadataCache(urn: string, cache: SimpleCache<TForgeMetadata>) {
    this.metadataCache.set(urn, cache)
  }

  @action
  private setPropertiesCache(urn: string, cache: SimpleCache<TForgeProperties>) {
    this.propertiesCache.set(urn, cache)
  }

  private async _loadProperties(urn: string) {
    const metadata = await this.getMetadataByUrnAsync(urn)
    const metadata3D = metadata?.find(row => row.role === "3d")
    if (metadata3D) {
      const url = `${this.host}/modelderivative/v2/designdata/${urn}/metadata/${metadata3D.guid}/properties?forceget=true`;
      const token = await this.props.forgeTokenRepository.getTokenAsync()

      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const response = await axios.get<{
        result: string
        data: {
          collection: TForgeProperties
        }
      }>(url, options)

      return response.data
    }
  }

  private loadProperties(urn: string) {
    const cache = new SimpleCache({
      promise: new Promise<TForgeProperties>(async res => {
        try {
          let data = await this._loadProperties(urn)
          let notificationKey: number | string | undefined = undefined

          while (!data || data.result === "success") {
            notificationKey ? undefined : notificationKey = this.props.notifications.wait("идёт подготовка свойств модели...")
            await this.delay(5000);
            data = await this._loadProperties(urn);
          }
          notificationKey ? this.props.notifications.close(notificationKey) : undefined

          res(data.data.collection)
        } catch (error) {
          this.props.notifications.wait("ошибка загрузки свойств", {
            variant: EVariants.error
          })
        }

      })
    })

    this.setPropertiesCache(urn, cache)

    return cache
  }
}