import React from "react"
import { Dialog, IconButton, makeStyles } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { observer } from "mobx-react-lite"
import { ILevelsFilterViewModel } from "../../view-model/LevelsFilterViewModel/interface"
import BackButton from '../../components/Buttons/BackButton';
import clsx from "clsx";
import { StoreyForm } from "../StoreyForm/StoreyForm"

const useClasses = makeStyles(theme => ({
  root: {
    padding: "100px 0 56px 0",
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  button: {
    margin: "0px 0px 30px 26px"
  },
  list: {
    overflow: "auto",
    userSelect: "none",
  },
  listItem: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#000",
    height: 48,

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#f5f5f5"
    }
  },
  listItemSelected: {
    fontWeight: "bold"
  },
  icon: {
    color: "#0008",
  },
  name: {
    flex: 1,
    padding: "10px 0 10px 40px"
  }
}))

const useClassesForm = makeStyles(theme => ({
  root: {
    width: 300,
    padding: 10
  },
}))

interface ILevelsFilterProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: ILevelsFilterViewModel
}

export const LevelsFilter = observer((props: ILevelsFilterProps) => {
  const classes = useClasses(props)
  const classesForm = useClassesForm()

  return (
    <div className={classes.root}>
      <BackButton className={classes.button} onClick={() => props.viewModel.onBack()} />
      <div className={classes.list}>
        {
          props.viewModel.storeys.map((storey) => {
            return (
              <div
                className={clsx(classes.listItem, {
                  [classes.listItemSelected]: storey.selected
                })}
                onClick={storey.onClick}
                key={storey.key}
              >
                <div
                  className={classes.name}
                >
                  {storey.name}
                </div>
                {
                  storey.onEdit ? (
                    <IconButton onClick={e => {
                      e.stopPropagation()
                      storey.onEdit?.()
                    }}>
                      <Edit className={classes.icon} />
                    </IconButton>
                  ) : null
                }
              </div>
            )
          })
        }
      </div>
      {
        props.viewModel.dialogViewModel ?
          <Dialog {...props.viewModel.dialogViewModel}>
            {
              props.viewModel.storeyFormViewModel ? <StoreyForm classes={classesForm} viewModel={props.viewModel.storeyFormViewModel} /> : null
            }
          </Dialog>
          : null
      }
    </div>
  )
})