import React from "react"
import { Dialog, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { EActiveComponent, IStatusPageViewModel } from "../../view-model/StatusPageViewModel/interface"
import { StatusSelector } from "../StatusSelector/StatusSelector"
import BackButton from '../../components/Buttons/BackButton';
import SimpleButton from '../../components/Buttons/SimpleButton';
import { StatusFilter } from "../StatusFilter/StatusFilter"
import { EditStatusForm } from "../EditStatusForm/EditStatusForm"

const useClasses = makeStyles(theme => ({
  root: {
    padding: "100px 0 56px 0",
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  button: {
    margin: "0px 0px 30px 26px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 16
  }
}))

const useClassesStatusSelector = makeStyles(theme => ({
  root: {
    padding: "0 26px"
  },
}))

const useClassesStatusFilter = makeStyles(theme => ({
  title: {
    paddingLeft: 26,
    paddingRight: 26
  },
  listItem: {
    paddingLeft: 26,
    paddingRight: 26
  },
}))

const useClassesEditStatusForm = makeStyles(theme => ({
  root: {
    width: 300,
    padding: 20
  }
}))

interface IStatusPageProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IStatusPageViewModel
}

export const StatusPage = observer((props: IStatusPageProps) => {
  const classes = useClasses(props)
  const statusSelectorClasses = useClassesStatusSelector()
  const statusFilterClasses = useClassesStatusFilter()
  const classesEditStatusForm = useClassesEditStatusForm()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <BackButton className={classes.button} onClick={() => props.viewModel.onBack()} />
        {
          props.viewModel.editButtonViewModel ? (
            <SimpleButton onClick={props.viewModel.editButtonViewModel.onClick}>Редактировать</SimpleButton>
          ) : null
        }
      </div>

      {
        props.viewModel.activeComponent === EActiveComponent.filter ?
          <StatusFilter classes={statusFilterClasses} viewModel={props.viewModel.statusFilterViewModel} />
          : <StatusSelector classes={statusSelectorClasses} viewModel={props.viewModel.statusSelectorViewModel} />
      }

      {
        props.viewModel.editStatusFormViewModel ? (
          <Dialog open onClose={props.viewModel.editStatusDialogViewModel.onClose}>
            <EditStatusForm
              classes={classesEditStatusForm}
              viewModel={props.viewModel.editStatusFormViewModel}
            />
          </Dialog>
        ) : null
      }
    </div>
  )
})