export enum EVariants {
  default = "default",
  error = "error",
  success = "success",
  warning = "warning",
  info = "info"
}

export class Notifications {
  constructor(private props: {}) {
    this.props = props
  }

  // ждет закрытия сообщения
  wait(message: string, options?: {
    variant?: EVariants
  }) {
    const key = this.onSend(message, {
      ...options,
      autoHideDuration: null,
      onClick: () => this.close(key)
    })

    return key
  }

  // закрывает сообщение по ключу
  close(key: string | number) {
    this.onClose(key)
  }

  // информирует на 5 сек
  notify(message: string, options?: {
    variant?: EVariants
  }) {
    this.onSend(message, {
      ...options,
      autoHideDuration: 5000
    })
  }

  // изменяется при подключении библиотеки
  onSend(message: string, options): string | number {
    return 0
  }

  onClose(key: string | number) { }
}