import { difference } from "lodash";
import { action, computed, makeObservable, ObservableMap, reaction } from "mobx";
import { IModelsLoaderViewModel } from "../../view-model/ModelsLoaderViewModel/interface";
import { Viewer } from "./Viewer";

export class ModelLoader {
  private modelsMap = new ObservableMap<string, Autodesk.Viewing.Model>()

  constructor(private props: { viewModel: IModelsLoaderViewModel, bimViewer: Viewer }) {
    this.props = props

    makeObservable(this)

    this.mount()
  }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  getModelByUrn(urn: string) {
    return this.modelsMap.get(urn)
  }

  @computed
  get models() {
    return Array.from(
      this.modelsMap.values()
    )
  }

  private mount() {
    reaction(() => this.viewer ? this.props.viewModel.urns : [], (urns, oldUrns = []) => {
      const viewer = this.viewer
      if (viewer) {
        const addedUrns = difference(urns, oldUrns)
        const removedUrns = difference(oldUrns, urns)

        this.loadDocuments({ urns: addedUrns, viewer: viewer })
        this.unloadDocuments({ urns: removedUrns, viewer: viewer })
      }
    }, {
      fireImmediately: true
    })
  }

  private async unloadDocuments(props: { urns: string[], viewer: Autodesk.Viewing.Viewer3D }) {
    for (const urn of props.urns) {
      const model = this.modelsMap.get(urn)
      if (model) {
        await props.viewer.unloadModel(model);
        this.removeModel(urn)
      }
    }
  }

  private async loadDocuments(props: { urns: string[], viewer: Autodesk.Viewing.Viewer3D }) {
    for (const urn of props.urns) {
      const document = await this.loadDoument(urn)
      await this.loadModel({ document, viewer: props.viewer, urn })
    }
  }

  private async loadModel(props: { urn: string, document: Autodesk.Viewing.Document, viewer: Autodesk.Viewing.Viewer3D }) {
    const nodes = props.document.getRoot().search({
      type: "geometry",
      role: "3d"
    })

    const model = await props.viewer.loadDocumentNode(props.document, nodes[0], {
      globalOffset: { x: 0, y: 0, z: 0 },
      applyRefPoint: true,
      createWireframe: true,
      isAEC: true,
      keepCurrentModels: true,
    });

    if (!model.isLoadDone()) {
      await this.loadGeometry({ viewer: props.viewer, model });
    }

    if (!model.isObjectTreeCreated()) {
      await this.createObjectTree({ viewer: props.viewer, model });
    }

    props.viewer.fitToView()
    props.viewer.setDisplayEdges(true)

    this.addModel(props.urn, model)
  }

  private createObjectTree(props: { viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model }) {
    return new Promise<void>(res => {
      function callback(e) {
        if (e.model === props.model) {
          props.viewer.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, callback)
          res();
        }
      }
      props.viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, callback)
    })
  }

  private loadGeometry(props: { viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model }) {
    return new Promise<void>(res => {
      function callback(e) {
        if (e.model === props.model) {
          props.viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, callback)
          res();
        }
      }
      props.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, callback)
    })
  }

  @action
  private removeModel(urn: string) {
    this.modelsMap.delete(urn)
  }

  @action
  private addModel(urn: string, model: Autodesk.Viewing.Model) {
    this.modelsMap.set(urn, model)
  }

  private async loadDoument(urn: string) {
    return new Promise<Autodesk.Viewing.Document>(res => {
      Autodesk.Viewing.Document.load(`urn: ${urn}`, res, console.log);
    });
  }
}