import { format } from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Prescription } from "../../models/Prescription";
import { ERemarkStatus, ERemarkStatusColor } from "../../models/Remark";
import { IPrescriptionRowViewModel, IPrescriptionsListViewModel } from "./interface";

export class PrescriptionsListViewModel implements IPrescriptionsListViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  search = ""

  @action
  onChangeSearch(value: string) {
    this.search = value
  }

  @computed
  private get allPrescriptions() {
    return this.props.layers.usecases.prescriptions.prescriptionsForSelectedBims.map(prescription => ({
      key: String(prescription.id),
      viewModel: new PrescriptionRowViewModel({ ...this.props, prescription })
    }))
  }

  @computed
  get prescriptions() {
    return this.allPrescriptions.filter(p => p.viewModel.search(this.search))
  }
}

class PrescriptionRowViewModel implements IPrescriptionRowViewModel {
  constructor(private props: { layers: ILayers, prescription: Prescription }) {
    this.props = props

    makeObservable(this)
  }

  search(search: string): boolean {
    return this.searchString.includes(search)
  }

  private get searchString() {
    return `
      ${this.statusName}
      ${this.statusColor}
      ${this.code}
      ${this.time}
    `
  }

  @computed
  private get status() {
    return this.props.layers.usecases.prescriptions.getPrescriptionStatus(this.props.prescription)
  }

  getStatusName(status: ERemarkStatus) {
    if (status === ERemarkStatus.closed) return "закрыто"
    if (status === ERemarkStatus.pending) return "ожидает"
    if (status === ERemarkStatus.opened) return "открыто"
    return "?"
  }

  @computed
  get statusName() {
    return this.status ? this.getStatusName(this.status) : "?"
  }

  @computed
  get statusColor() {
    return this.status ? ERemarkStatusColor[this.status] : "#0005"
  }

  @computed
  get code() {
    return this.props.layers.usecases.prescriptions.getPrescriptionCode(this.props.prescription)
  }

  @computed
  get time() {
    return format(this.props.prescription.deadlineAt, "dd.MM.yyyy")
  }

  onClick() {
    this.props.layers.usecases.viewState.setSelectedPrescriptions([this.props.prescription.id])
  }
}