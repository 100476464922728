import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EActions } from "../../usecase/Permissions/Permissions";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { IMoreButtonsPanelViewModel } from "./interface";
import jsPDF from 'jspdf';

export class MoreButtonsPanelViewModel implements IMoreButtonsPanelViewModel {
  constructor(private props: { layers: ILayers, onClose(): void }) {
    this.props = props

    makeObservable(this)
  }
  statusWrapperViewModel = new StatusWrapperViewModel()

  @computed
  get paintButtonViewModel() {
    if (this.props.layers.usecases.ui.viewerDataRepository.get()?.paintElementsInStatusColor) return {
      onClick: () => {
        this.props.layers.usecases.ui.viewerDataRepository.patch({
          paintElementsInStatusColor: false
        })
      },
      label: "Снять выделение"
    }

    return {
      onClick: () => {
        this.props.layers.usecases.ui.viewerDataRepository.patch({
          paintElementsInStatusColor: true
        })
      },
      label: "Выделить цветом"
    }
  }

  @computed
  get updateScreenButtonViewModel() {
    const selectedCollection = this.props.layers.usecases.viewState.props.selectedCollection
    if (!selectedCollection || !this.props.layers.usecases.permissions.actionIsAllowed(EActions.createAndEditCollections)) return undefined

    return {
      onClick: async () => {
        const canvas = await this.props.layers.usecases.ui.getViewerCanvas?.()
        if (!canvas) return
        const data = canvas.toDataURL('image/jpeg', 1.0);

        this.statusWrapperViewModel.load()

        await this.props.layers.repositories.collectionsRepository.edit(selectedCollection, {
          image: data
        })

        this.statusWrapperViewModel.success()
      },
    }
  }

  @computed
  get downloadPDFButtonViewModel() {
    return {
      onClick: async () => {
        const canvas = await this.props.layers.usecases.ui.getViewerCanvas?.()
        if (!canvas) return

        this.statusWrapperViewModel.load()

        let pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();

        pdf.addImage(canvas, 'JPEG', 0, 0, pdfWidth, canvas.height / canvas.width * pdfWidth);
        pdf.save(`${this.fileName}.pdf`);

        this.statusWrapperViewModel.success()
      },
    }
  }

  @computed
  private get fileName() {
    return this.props.layers.usecases.bims.selectedCollection?.name || this.props.layers.usecases.bims.selectedBims[0].name
  }

  @computed
  get downloadImageButtonViewModel() {
    return {
      onClick: async () => {
        const canvas = await this.props.layers.usecases.ui.getViewerCanvas?.()
        if (!canvas) return
        const data = canvas.toDataURL('image/jpeg', 1.0);

        const link = document.createElement('a');
        link.href = data;
        link.target = "_blank";
        link.download = `${this.fileName}.jpg`;

        link.click();

        link.remove()
      },
    }
  }

  onClose = () => {
    this.props.onClose()
  }
}