import { Bim } from "../../models/Bim";
import { Collection, TCollectionProps, TNewCollectionProps } from "../../models/Collection";
import { TProjectProps } from "../../models/Project";
import { IBimsRepository } from "../../repositories/BimsRepository/interface";
import { ICollectionsRepository } from "../../repositories/CollectionsRepository/interface";
import { Bims } from "../Bims/Bims";

export class Collections {
  constructor(private props: {
    bims: Bims
    collectionsRepository: ICollectionsRepository
    bimsRepository: IBimsRepository
  }) {
    this.props = props
  }

  getByProjectId(projectId: TProjectProps["id"]) {
    const collections = this.props.collectionsRepository.getAll()
    const bimsIds = this.props.bims.getBimsByProjectId(projectId).map(bim => bim.id)
    return collections.filter(collection => collection.bims.some(bim => bimsIds.includes(bim)))
  }

  async getByProjectIdAsync(projectId: TProjectProps["id"]) {
    const collections = await this.props.collectionsRepository.getAllAsync()
    const bims = await this.props.bims.getBimsByProjectIdAsync(projectId)
    const bimsIds = bims.map(bim => bim.id)
    return collections.filter(collection => collection.bims.some(bim => bimsIds.includes(bim)))
  }

  getCollectionIsValid(props: TNewCollectionProps) {
    return props.bims.length > 0 && props.name.length > 0
  }

  async getCollectionBimsAsync(collection: Collection) {
    const bims = new Set<Bim>()

    for (const bimId of collection.bims) {
      const bim = await this.props.bimsRepository.getByIdAsync(bimId)

      if (bim) bims.add(bim)
    }

    return Array.from(bims.values())
  }
}