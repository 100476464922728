import { BaseModel } from "./BaseModel";
import { Entity } from "./Entity";
import { TStatusDictionaryProps } from "./StatusDictionary";

export type TRemarkBaseProps = BaseModel & {
  baseStatus: TStatusDictionaryProps["id"][]
  title: string
  isCritical: boolean
  requiresNormativeDocument: boolean
}

export class RemarkBase extends Entity<TRemarkBaseProps> {
  get title() {
    return this.props.title
  }

  get baseStatus() {
    return this.props.baseStatus
  }

  get requiresNormativeDocument() {
    return this.props.requiresNormativeDocument
  }

  get commonRemark() {
    return this.baseStatus.length === 0
  }
}