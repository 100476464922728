import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { IElementsPropertyShortPanelViewModel } from "./interface";

export class ElementsStatusPropertyShortPanelViewModel implements IElementsPropertyShortPanelViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  header = "Статус элементов"

  @computed
  get rows() {
    return this.statuses.map(status => ({
      name: "-",
      value: status,
      key: status,
      separator: ""
    }))
  }

  @computed
  get statuses() {
    const statusDictionaries = this.props.layers.usecases.statuses.selectedStatusDictionary

    return statusDictionaries.map(statusDictionary => statusDictionary?.name || "не назначен")
  }
}