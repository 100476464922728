import { LocalStorageBooleanRepository } from "../../repositories/SimpleDataRepository/LocalStorageBooleanRepository";
import { SimpleViewModelWithSaver } from "../../view-model/SimpleViewModel/SimpleViewModelWithSaver";

export class Settings {
  constructor(private props: {}) {
    this.props = props
  }

  inversion = new SimpleViewModelWithSaver({
    defaultValue: false,
    repository: new LocalStorageBooleanRepository({ key: "inversion" })
  })

  transparentHiding = new SimpleViewModelWithSaver({
    defaultValue: false,
    repository: new LocalStorageBooleanRepository({ key: "transparentHiding" })
  })

  hideAdditionalModels = new SimpleViewModelWithSaver({
    defaultValue: false,
    repository: new LocalStorageBooleanRepository({ key: "hideAdditionalModels" })
  })
}