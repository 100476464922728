import React, { useCallback, useEffect, useRef, useState } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IBimViewerViewModel } from "../../view-model/BimViewerViewModel/interface"
import { Viewer } from "../Viewer/Viewer"
import { ModelLoader } from "../Viewer/ModelLoader"
import { debounce } from "lodash"
import ReactResizeDetector from 'react-resize-detector';
import { WindowSelector } from "../Viewer/WindowSelector"
import { RightViewerPanel } from "../RightViewerPanel/RightViewerPanel"
import { ModelsSelector } from "../Viewer/ModelsSelector"
import { PaintElements } from "../Viewer/PaintElements"
import { HideNodes } from "../Viewer/HideNodes"
import { CustomPropertyPanel } from "../Viewer/CustomPropertyPanel"
import clsx from "clsx"
import { InversionExtension } from "../Viewer/InversionExtension"

const useClasses = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    position: "relative",
    overflow: 'hidden',
  },
  viewer: {}
}))

const useClassesPanel = makeStyles(theme => ({
  root: {
    zIndex: 1
  },
}))


interface IBimViewerProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IBimViewerViewModel
}

export const BimViewer = observer((props: IBimViewerProps) => {
  const [bimViewer, setBimViewer] = useState<Viewer>()

  const classes = useClasses(props)
  const classesPanel = useClassesPanel()
  const viewerContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (viewerContainerRef.current) {
      const bimViewer = new Viewer({
        container: viewerContainerRef.current,
        viewModel: props.viewModel.viewerViewModel
      })

      const modelLoader = new ModelLoader({
        bimViewer,
        viewModel: props.viewModel.modelsLoaderViewModel
      })

      new ModelsSelector({
        bimViewer,
        viewModel: props.viewModel.modelsSelectorViewModel,
        modelLoader
      })

      new WindowSelector({
        bimViewer,
        viewModel: props.viewModel.windowSelectorViewModel
      })

      new PaintElements({
        bimViewer,
        viewModel: props.viewModel.paintElementsViewModel,
        modelLoader
      })

      new HideNodes({
        bimViewer,
        viewModel: props.viewModel.hideNodesViewModel,
        modelLoader
      })

      new CustomPropertyPanel({
        bimViewer,
        viewModel: props.viewModel.customPropertyPanelViewModel,
      })

      new InversionExtension({
        bimViewer,
        viewModel: props.viewModel.inversionExtensionViewModel,
      })

      setBimViewer(bimViewer)
    }
  }, [])

  const viewer = bimViewer?.viewer

  const resizeModel = useCallback(debounce(() => {
    viewer?.resize();
  }, 50), [viewer]);

  return (
    <div className={classes.root}>
      <ReactResizeDetector handleWidth handleHeight onResize={resizeModel} />
      <div className={clsx(classes.viewer, "viewer")} ref={viewerContainerRef} />
      {
        viewer ? (
          <RightViewerPanel classes={classesPanel} viewer={viewer} viewModel={props.viewModel.rightViewerPanelViewModel} />
        ) : null
      }
    </div>
  )
})