import { ILayers } from "../../core/Layers/interface";
import { IViewerViewModel } from "./interface";

export class ViewerViewModel implements IViewerViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props
  }

  getForgeToken() {
    return this.props.layers.repositories.forgeTokenRepository.getTokenAsync()
  }

  setGetScreenShotCanvasMethod(method) {
    this.props.layers.usecases.ui.getViewerCanvas = method
  }
}