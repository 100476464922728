import { ICache } from "./interface";

export class Cache implements ICache {
  constructor(private props: {
    name: string
  }) {
    this.props = props
  }

  private getCache() {
    if ("caches" in self) {
      return self.caches.open(this.props.name)
    }
  }

  async put(url: string, response: Response) {
    const cache = await this.getCache()
    if (cache) {
      await cache.put(url, response)
    }
  }

  async get(url: string) {
    const cache = await this.getCache()

    if (cache) {
      return cache.match(url)
    }
  }
}