import React from "react"
import { Dialog, makeStyles, Step, StepContent, StepLabel, Stepper } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { EStepStatus, IStatusFormViewModel, IStatusSelectorViewModel } from "../../view-model/StatusSelectorViewModel/interface"
import clsx from "clsx"
import { TableButton } from "../Buttons/TableButton"
import TextArea from "../../components/Inputs/TextArea";
import { StatusWrapper } from "../StatusWrapper/StatusWrapper"
import { UploadButton } from "../Buttons/UploadButton"
import { UploadFilesList } from "../UploadFilesList/UploadFilesList"
import SimpleButton from "../../components/Buttons/SimpleButton";

const useClasses = makeStyles(theme => ({
  root: {
    overflow: "auto"
  },

  warnings: {
    marginBottom: 20
  },

  stepper: {
    padding: 0,

    "& .MuiStepConnector-root": {
      display: "none !important"
    },
  },

  stepContent: {
    position: "relative",
    minHeight: 26,
    paddingRight: 0,
    margin: "10px 0 10px 9px",
    marginLeft: 9,
  },
  stepContentEditable: {
    borderLeft: "1px solid #bdbdbd !important"
  },

  stepContentActive: {
    borderColor: theme.palette.primary.main,
  },

  stepLabelContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  stepLabelLabel: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "#AAAAA9",
  },

  stepLabelLabelCompleted: {
    color: "#000",
  },

  stepLabelLabelEditable: {
    color: "#000",
  },

  stepLabelSecondLabel: {
    fontWeight: "normal",
    fontSize: "12px",
    color: "#AAAAA9",
  },

  stepLabel: {
    fill: "#AAAAA9"
  },

  stepLabelActive: {
    fill: theme.palette.primary.main
  },

  applyStatusBlockRootHide: {
    opacity: 0,
  },

  applyStatusBlockButtons: {
    display: "flex",
    marginTop: 10
  },

  addFilesButton: {
    flex: 1,
    backgroundColor: theme.palette.primary.main
  },

  showFiles: {
    margin: "10px 0 0 0",
    backgroundColor: "#F5F5F5",
    color: "#0005",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#000"
    }
  },
  applyStatusButton: {
    flex: 1,
    marginLeft: 20,
    //@ts-ignore
    backgroundColor: theme.palette.greenColor,
    color: "white"
  },

  historyComment: {
    fontWeight: "normal",
    fontSize: "12px",
    color: "#AAAAA9",
  },
  username: {
    fontWeight: "normal",
    fontSize: "12px",
    color: "#000",
  },
  showFilesButton: {
    marginTop: 10,
    backgroundColor: "#F5F5F5",
    color: "#0005"
  },
  filesList: {
    padding: 20,
    width: 656
  }
}))

interface IStatusSelectorProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IStatusSelectorViewModel
}

export const StatusSelector = observer((props: IStatusSelectorProps) => {
  const classes = useClasses(props)

  const warnings = props.viewModel.warnings
  const steps = props.viewModel.steps

  return (
    <div className={classes.root}>
      {
        warnings ? (
          <div className={classes.warnings}>
            {warnings.header}
            {
              warnings.items.map(warning => (
                <div>{warning}</div>
              ))
            }
          </div>
        ) : null
      }
      <Stepper
        orientation="vertical"
        className={classes.stepper}
      >
        {
          steps.map(step => (
            <Step active>
              <StepLabel
                className={clsx(classes.stepLabel, {
                  [classes.stepLabelActive]: step.status === EStepStatus.completed || step.status === EStepStatus.editable
                })}
                StepIconComponent={StepIconComponent}
              >
                <div className={classes.stepLabelContent}>
                  <div className={clsx(classes.stepLabelLabel, {
                    [classes.stepLabelLabelCompleted]: step.status === EStepStatus.completed,
                    [classes.stepLabelLabelEditable]: step.status === EStepStatus.editable,
                  })}>{step.name}</div>
                  {
                    step.status === EStepStatus.completed ? (
                      <div className={classes.stepLabelSecondLabel}>{step.date}</div>
                    ) : null
                  }
                </div>
              </StepLabel>
              <StepContent
                className={clsx(classes.stepContent, {
                  [classes.stepContentActive]: step.status === EStepStatus.completed,
                  [classes.stepContentEditable]: step.status === EStepStatus.editable,
                })}
              >
                {
                  step.status === EStepStatus.completed ? (
                    <div>
                      <div className={classes.username}>{step.username}</div>
                      <div className={classes.historyComment}>{step.comment}</div>
                      {
                        step.showFilesViewModel ? (
                          <SimpleButton
                            className={classes.showFilesButton}
                            {...step.showFilesViewModel}
                          >
                            {step.showFilesViewModel.label}
                          </SimpleButton>
                        ) : null
                      }
                      <Dialog open={step.showFilesDialogViewModel.open} onClose={step.showFilesDialogViewModel.onClose}>
                        <UploadFilesList className={classes.filesList} viewModel={step.uploadFilesListViewModel} />
                      </Dialog>
                    </div>
                  ) : step.status === EStepStatus.editable ? (
                    <StatusWrapper viewModel={step.statusFormViewModel.statusWrapperViewModel}>
                      <StatusForm viewModel={step.statusFormViewModel} classes={classes} />
                    </StatusWrapper>
                  ) : null
                }
              </StepContent>
            </Step>
          ))
        }
      </Stepper>
    </div>
  )
})

interface IStatusFormProps {
  viewModel: IStatusFormViewModel
  classes: ReturnType<typeof useClasses>
}


const StatusForm = observer((props: IStatusFormProps) => {
  return (
    <div>
      <TextArea
        value={props.viewModel.comment}
        onChange={(e) => props.viewModel.onChangeComment(e)}
        placeholder="Комментарий"
      />
      {
        props.viewModel.showFilesViewModel ? (
          <SimpleButton
            className={props.classes.showFilesButton}
            {...props.viewModel.showFilesViewModel}
          >
            {props.viewModel.showFilesViewModel.label}
          </SimpleButton>
        ) : null
      }
      <div className={props.classes.applyStatusBlockButtons}>
        {
          props.viewModel.uploadButtonViewModel ? (
            <UploadButton
              className={props.classes.addFilesButton}
              loading={props.viewModel.uploadButtonViewModel.loading}
              onChange={e => props.viewModel.uploadButtonViewModel?.onChange(e)}
            >
              Добавить файл
            </UploadButton>
          ) : null
        }
        <TableButton className={props.classes.applyStatusButton} onClick={() => props.viewModel.onApply()}>Назначить</TableButton>
      </div>
      <Dialog open={props.viewModel.showFilesDialogViewModel.open} onClose={props.viewModel.showFilesDialogViewModel.onClose}>
        <UploadFilesList className={props.classes.filesList} viewModel={props.viewModel.uploadFilesListViewModel} />
      </Dialog>
    </div>
  )
})

const StepIconComponent = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0ZM12.7772 7.13945L8.3023 11.6164C8.29027 11.6264 8.28626 11.6384 8.27423 11.6505C8.16635 11.7539 8.02269 11.8116 7.87325 11.8116C7.72381 11.8116 7.58014 11.7539 7.47227 11.6505C7.46024 11.6384 7.45623 11.6224 7.4442 11.6124L5.22277 9.38694C5.13064 9.27989 5.08241 9.14194 5.08778 9.0008C5.09315 8.85966 5.15171 8.72577 5.25171 8.62603C5.35171 8.52628 5.48575 8.46806 5.6269 8.46305C5.76806 8.45804 5.90588 8.50661 6.0127 8.59902L7.87726 10.4636L11.9873 6.34952C12.0938 6.25385 12.233 6.20266 12.3761 6.20649C12.5192 6.21032 12.6554 6.26889 12.7566 6.37012C12.8579 6.47136 12.9164 6.60756 12.9203 6.75068C12.9241 6.8938 12.8729 7.03294 12.7772 7.13945Z" />
  </svg>
)