import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { memo } from "react";
import { useRef } from "react";
import { useCallback } from "react";

const useClasses = makeStyles(theme => ({
    root: {
        display: "flex",
        lineHeight: "44px",
        cursor: "pointer",
    },
    disabled: {
        cursor: "default !important",
    },
    padding: {
        padding: "0 15px",
    },
    label: {
        flex: 1,
        whiteSpace: "nowrap",
        userSelect: "none",
        fontWeight: "normal",
        fontSize: "12px",
    },
    value: {
        background: "inherit",
        border: "none",

        flex: 1,

        textAlign: "right",
        fontWeight: "bold",
        fontSize: "12px",
        cursor: "text",

        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "50%",
        lineHeight: "43px",
        borderBottom: "1px solid #0000",

        "&:focus": {
            borderBottom: "1px solid #000",
            textOverflow: "initial",
            outline: "none"
        },

        "&:empty:before": {
            content: "attr(placeholder)",
            color: "#AAAAA9"
        }
    },
}));

function PanelInput(props) {
    const {
        value,
        label,
        placeholder,
        disabled,
        onChange
    } = props;

    const classes = useClasses(props);
    const inputRef = useRef();

    const onChangeHandler = useCallback(onChange ? e => {
        onChange(e.target.value)
    } : null, [onChange, value]);

    const onClick = useCallback(e => {
        inputRef.current.focus()
    }, []);

    return (
        <div className={clsx(classes.root, classes.padding, {
            [classes.disabled]: disabled
        })} onClick={onClick}>
            <div className={classes.label}>{label}</div>
            <input
                ref={inputRef}
                className={clsx(classes.value, {
                    [classes.disabled]: disabled
                })}
                disabled={disabled}
                value={value}
                onChange={onChangeHandler}
                placeholder={placeholder}
            />
        </div>
    )
}

export default memo(PanelInput)