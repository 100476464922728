import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IPrescriptionsListViewModel, IPrescriptionRowViewModel } from "../../view-model/PrescriptionsListViewModel/interface"
import SearchField from '../../components/Inputs/SearchField';

const useClasses = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto"
  },
  content: {
    overflow: "overlay",
  },
  search: {
    margin: "0px 26px 20px 26px"
  },
  row: {
    display: "grid",
    gridTemplateColumns: '1fr 1fr min-content',
    gridTemplateAreas: `"code time status icon";`,
    padding: "0 26px 0 26px",
    cursor: "pointer",
    lineHeight: "44px",

    "&:hover": {
      //@ts-ignore
      backgroundColor: theme.palette.baseBackgroundColor
    },
  },
  code: {
    fontWeight: "bold",
    fontSize: "12px",
    marginRight: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    gridArea: "code",
  },
  time: {
    //@ts-ignore
    color: theme.palette.secondTextColor,
    fontSize: "12px",

    gridArea: "time",
  },
  status: {
    width: 80,
    height: 24,
    borderRadius: 8,
    margin: "auto",
    paddingTop: 1,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10px",
    lineHeight: "10px",
    color: "#fff",
    fontWeight: "bold",

    gridArea: "status",
  },
}))

interface IPrescriptionsListProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPrescriptionsListViewModel
}

export const PrescriptionsList = observer((props: IPrescriptionsListProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      <SearchField className={classes.search} value={props.viewModel.search} onChange={(e) => props.viewModel.onChangeSearch(e)} placeholder="Поиск" />
      <div className={classes.content}>
        {
          props.viewModel.prescriptions.map(prescription => (
            <Row key={prescription.key} viewModel={prescription.viewModel} classes={classes} />
          ))
        }
      </div>
    </div>
  )
})


interface IPrescriptionRowProps {
  classes: Partial<ReturnType<typeof useClasses>>
  viewModel: IPrescriptionRowViewModel
}

function Row(props: IPrescriptionRowProps) {
  const {
    classes,
  } = props;

  return (
    <div className={classes.row} onClick={() => props.viewModel.onClick()}>
      <div className={classes.status} style={{ backgroundColor: props.viewModel.statusColor }}><span>{props.viewModel.statusName}</span></div>
      <div className={classes.code}>{props.viewModel.code}</div>
      <div className={classes.time}>{props.viewModel.time}</div>
    </div>
  )
}