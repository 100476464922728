import { action, makeObservable, observable } from "mobx";
import { EUserRoles, TUserProps, User } from "../../models/User";
import { IAuthRepository } from "./interface";
import axios from "axios";
import { RequestStatus } from "../../models/RequestStatus";

type TTokenResponse = {
  user: {
    id: number
    company: number
    email: string
    first_name: string
    last_name: string
    role: EUserRoles
    token: string
    username: string
  }
}

export class StageAuthRepository implements IAuthRepository {
  constructor(private props: { host: string }) {
    this.props = props
    makeObservable(this)

    this.loadState()
  }

  status = new RequestStatus({
    loading: false,
    error: undefined
  })

  @observable
  private authorized = false

  @observable
  private user?: User = undefined

  @observable
  private accessToken?: string = undefined

  private loadState() {
    const accessToken: string | null = localStorage.getItem("accessToken")
    const user: TUserProps | null = JSON.parse(localStorage.getItem("user") || "null")

    if (user) {
      this.setUser(new User(user))
    }

    if (accessToken) {
      this.setAccessToken(accessToken)
    }

    if (user && accessToken) {
      this.setAuthorized(true)
    }
  }

  getAuthHeaders() {
    return {
      'Authorization': `Bearer ${this.getAccessToken()}`
    }
  }

  async getAuthHeadersAsync() {
    return this.getAuthHeaders()
  }

  getIsAuthorized() {
    return this.authorized
  }

  getUser() {
    return this.user
  }

  getAccessToken() {
    return this.accessToken
  }

  login = async (props: { login: string, password: string }) => {
    try {
      this.status.load()

      const response = await axios.post<TTokenResponse>(`${this.props.host}api/authentication/token/`, {
        username: props.login,
        password: props.password
      })

      if (response.status === 200) {
        const user = response.data.user
        this.setAccessToken(user.token)

        this.setUser(new User({
          id: user.id,
          company: user.company,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          role: user.role
        }))

        this.setAuthorized(true)
        this.status.success()
      } else {
        this.status.fail("произошла ошибка")
      }
    } catch (error) {
      this.status.fail("произошла ошибка")
    }
  }

  logout() {
    this.clearUser()
    this.clearAccessToken()
    this.setAuthorized(false)
  }

  @action
  private setUser(user: User) {
    this.user = user
    localStorage.setItem("user", JSON.stringify(user.props))
  }

  @action
  private clearUser() {
    this.user = undefined
    localStorage.removeItem("user")
  }

  @action
  private setAccessToken(token: string) {
    this.accessToken = token
    localStorage.setItem("accessToken", token)
  }

  @action
  private clearAccessToken() {
    this.accessToken = undefined
    localStorage.removeItem("accessToken")
  }

  @action
  private setAuthorized(v: boolean) {
    this.authorized = v
  }
}