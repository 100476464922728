import { action, computed, flow, makeObservable, observable, ObservableMap, ObservableSet, toJS } from "mobx"
import { fromPromise } from "mobx-utils"
import { IBimStatusRepository } from "../../repositories/BimStatusRepository/interface"
import { BooleanViewModel } from "../../view-model/SimpleViewModel/BooleanViewModel"
import { SimpleViewModel } from "../../view-model/SimpleViewModel/SimpleViewModel"
import { EActions, Permissions } from "../Permissions/Permissions"
export class PlanningMode {
  constructor(private props: {
    bimStatusRepository: IBimStatusRepository,
    permissions: Permissions
  }) {
    this.props = props

    makeObservable(this)
  }

  // вкл/выкл режим планирования 
  active = new BooleanViewModel(false)

  // раскрасить по дате
  painByDateViewModel = new BooleanViewModel(false)

  // отображать только просроченную дата
  expiredDateViewModel = new BooleanViewModel(false)

  selectedDeadline = new SimpleViewModel({ value: "" })

  @observable bimId
  @observable bimStatusPlanned = new ObservableSet<any>()
  @observable differentDate = false

  @computed
  get allowedSetPlanningDate() {
    return this.props.permissions.actionIsAllowed(EActions.setPlanningDate)
  }

  @action
  setDifferentDate(isOpne: boolean) {
    this.differentDate = isOpne
  }

  @computed
  get plannedStatus() {
    return fromPromise(this.getPlannedStatus())
  }

  @action
  async getPlannedStatus() {
    return await this.props.bimStatusRepository.getByBimId(this.bimId)
  }


}