import { computed, makeObservable } from "mobx"
import { Bim, TBimProps } from "../../models/Bim"
import { Project, TProjectProps } from "../../models/Project"
import { Target, TTargetProps } from "../../models/Target"
import { IBimsRepository } from "../../repositories/BimsRepository/interface"
import { ICollectionsRepository } from "../../repositories/CollectionsRepository/interface"
import { IProjectsRepository } from "../../repositories/ProjectsRepository/interface"
import { ITargetsRepository } from "../../repositories/TargetsRepository/interface"
import { Targets } from "../Targets/Targets"
import { UI } from "../UI/UI"
import { ViewState } from "../ViewState/ViewState"

export class Bims {
  constructor(private props: {
    bimsRepository: IBimsRepository,
    targetsRepository: ITargetsRepository,
    targets: Targets,
    viewState: ViewState,
    collectionsRepository: ICollectionsRepository
    projectsRepository: IProjectsRepository
    ui: UI
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get selectedBims() {
    if (!this.selectedModelWorkTypeId) return this.selectedAllBims

    return this.selectedAllBims.filter(bim => bim.modelWorkType === this.selectedModelWorkTypeId)
  }

  @computed
  get selectedAllBims() {
    const bims: Bim[] = []

    for (const bimId of this.selectedBimsIds || []) {
      const bim = this.props.bimsRepository.getById(bimId)
      if (bim) bims.push(bim)
    }

    return bims
  }

  @computed
  get selectedBimsType() {
    const bim = this.selectedAllBims[0]

    return bim?.type
  }

  @computed
  get selectedTargets() {
    const targets = new Set<Target>()

    for (const bim of this.selectedBims) {
      const target = this.props.targetsRepository.getById(bim.target)
      if (target) {
        targets.add(target)
      }
    }

    return Array.from(targets.values())
  }

  @computed
  get selectedProjects() {
    const projects = new Set<Project>()

    for (const target of this.selectedTargets) {
      const project = this.props.projectsRepository.getById(target.project)

      if (project) {
        projects.add(project)
      }
    }

    return Array.from(projects)
  }

  @computed
  get selectedBimsIds() {
    return this.selectedCollection?.bims || this.props.viewState.props.selectedBims
  }

  @computed
  get selectedCollection() {
    if (!this.props.viewState.props.selectedCollection) return undefined
    return this.props.collectionsRepository.getById(this.props.viewState.props.selectedCollection)
  }

  @computed
  get selectedModelWorkTypeId() {
    return this.selectedCollection?.modelWorkType
  }

  @computed
  get modelDataRepository() {
    const collection = this.props.viewState.props.selectedCollection
    if (collection) return this.props.ui.getModelDataRepository({ collection })

    const bims = this.props.viewState.props.selectedBims
    if (bims && bims.length > 0) return this.props.ui.getModelDataRepository({ bims })
  }

  async getModelDataRepositoryAsync() {
    return this.modelDataRepository
  }

  unique<T>(array: T[]): T[] {
    return Array.from((new Set(array)).values())
  }

  // methods
  getProjectByBimId(id: TBimProps["id"]) {
    const bim = this.props.bimsRepository.getById(id)
    const target = bim?.target
    if (!target) return

    return this.props.targets.getProjectByTargetId(target)
  }

  async getProjectByBimIdAsync(id: TBimProps["id"]) {
    const bim = await this.props.bimsRepository.getByIdAsync(id)
    const target = bim?.target
    if (!target) return

    return this.props.targets.getProjectByTargetIdAsync(target)
  }

  getTarget(bim: Bim) {
    return this.props.targetsRepository.getById(bim.target)
  }

  // methods
  async getSelectedBimsIdsAsync() {
    const collection = await this.getSelectedCollectionAsync()
    if (collection) return collection.bims

    return this.props.viewState.props.selectedBims || []
  }

  async getSelectedCollectionAsync() {
    if (!this.props.viewState.props.selectedCollection) return undefined
    return this.props.collectionsRepository.getByIdAsync(this.props.viewState.props.selectedCollection)
  }

  async getSelectedBimsAsync() {
    const bims: Bim[] = []
    const ids = await this.getSelectedBimsIdsAsync()

    for (const bimId of ids) {
      const bim = await this.props.bimsRepository.getByIdAsync(bimId)
      if (bim) bims.push(bim)
    }

    return bims
  }

  getBimsByProjectId(id: TProjectProps["id"]) {
    const targetsBims = new Set<Bim>()
    const targets = this.props.targetsRepository.getByProjectId(id)

    for (const target of targets) {
      for (const bim of this.props.bimsRepository.getByTargetId(target.id)) {
        targetsBims.add(bim)
      }
    }

    return Array.from(targetsBims)
  }

  async getBimsByProjectIdAsync(id: TProjectProps["id"]) {
    const targetsBims: Bim[] = []
    const targets = await this.props.targetsRepository.getByProjectIdAsync(id)

    for (const target of targets) {
      const bims = await this.props.bimsRepository.getByTargetIdAsync(target.id)
      targetsBims.push(
        ...bims
      )
    }

    return targetsBims
  }

  getByTargetId(id: TTargetProps["id"]) {
    return this.props.bimsRepository.getByTargetId(id)
  }

  getByUrn(urn: Bim["urn"]) {
    const bims = this.selectedBims
    return bims.find(bim => bim.urn === urn)
  }

  getByUrl(url: Bim["urn"]) {
    const bims = this.selectedBims
    return bims.find(bim => bim.url === url)
  }

  async getByUrnAsync(urn: Bim["urn"]) {
    return this.props.bimsRepository.getByUrnAsync(urn)
  }
}