import React from "react"
import { observer } from "mobx-react-lite"
import { EDocumentMode, IPrescriptionDocumentViewModel } from "../../view-model/PrescriptionDocumentViewModel/interface"

interface IPrescriptionDocumentProps {
  className?: string
  viewModel: IPrescriptionDocumentViewModel
}

export const PrescriptionDocument = observer((props: IPrescriptionDocumentProps) => {
  return <div dangerouslySetInnerHTML={{ __html: getPrescriptionDocumentString(props.viewModel, props.className) }} />
})

export function getPrescriptionDocumentString(props: IPrescriptionDocumentViewModel, className: string = "") {
  return (
    `
          <div
              style="
                margin: 20mm 0 0 30mm;
                line-height: 1.5;
                font-family: initial;
                width: 210mm;
                box-sizing: border-box;
                overflow: hidden;
                font-size: 9pt;
            "
            class="${className}"
          >
              <div style="font-weight: 600; font-size: 14pt; text-align: center">Предписание по строительному контролю № ${props.prescriptionCode}</div>
              <div style="font-weight: 600; font-size: 14pt; text-align: center">${props.mainCompanyName}</div>
              <div style="text-align: end; font-weight: 600; margin-top: 6pt;">
                ${props.createdAtDate}
              </div>
              <div>
                <b>Кому:</b> ${props.contractorCompanyName}
              </div>
              <div>
                <b>Объект:</b> ${props.targetAddress}
              </div>
              <div style="margin-top: 10pt;">
                ${props.workDescription}
              </div>
              <table style="width: 100%; border: 1px solid black; border-collapse: collapse; margin-top: 2pt;" >
                  <tbody>
                      <tr style="text-align: center; font-weight: 600;">
                          <td style="border: 1px solid black; width: 2em">№ п/п</td>
                          <td style="border: 1px solid black">Перечень выявленных нарушений</td>
                          <td style="border: 1px solid black">Приложение</td>
                          <td style="border: 1px solid black">Сроки устранения</td>
                          <td style="border: 1px solid black; padding: 0 5mm">Отметка об устранении</td>
                      </tr>
                      ${props.remarks.map(({ title, deadline, annex }, index) => (
      `
                                  <tr key=${index}>
                                      <td style="text-align: center; border: 1px solid black;">${index + 1}</td>
                                      <td style="border: 1px solid black; padding: 2mm 5mm">
                                          <span>${title}</span>
                                      </td>
                                      <td style="text-align: center; border: 1px solid black;">${annex}</td>
                                      <td style="text-align: center; border: 1px solid black; padding: 0 5mm;">${deadline}</td>
                                      <td style="border: 1px solid black;"></td>
                                  </tr>
                                `
    )).join("")}
                  </tbody>
              </table>
              <div style="margin-left: calc(2px + 2em); margin-top: 12pt;">
                  <div style="font-style: italic; font-weight: 600;">Требования по результатам проверки:</div>
                  <div>${props.notes}</div>
              </div>
                    <div style="border: 2px solid black; padding-left: 5px; margin-top: 12pt;">
                    <b>Особые условия:</b> ${props.specialConditions}
              </div>
              ${props.mode === EDocumentMode.development ?
      `
                    <div style="margin-top: 20pt; border-bottom: 1px solid black;">
                  Предписание выдал Руководитель строительства ${props.developmentCompanyName}
              </div>
              ${signatureAndDate}
                    `
      : ""
    }
              
              <div style="margin-top: 14pt">
                Представитель Технического заказчика инженер СК
              </div>
              <div style="border-bottom: 1px solid black">
                ${props.mainCompanyName} ${props.ownerFullName}
              </div>
              ${signatureAndDate}

              ${props.mode === EDocumentMode.bc ?
      `
                        <div style="margin-top: 3em; border-bottom: 1px solid black"></div>
                        ${signatureAndDate}
                        `
      : ""
    }
              <div style="margin-top: 18pt; border-bottom: 1px solid black">
                  Предписание получил представитель ${props.contractorPureCompanyName} ${props.receiverFullName}
              </div>
              ${signatureAndDate}
              <div style="margin-top: 18pt; border-bottom: 1px solid black">
                  Контроль устранения замечаний провёл:
              </div>
              ${signatureAndDate}
              <div style="margin-top: 14pt;">
                ${props.images.map((image, index) => `
                  <div>
                    <img style="width: 100%;" src="${image.src}"/>
                    <div>Фото ${index + 1}</div>
                  </div>
                `)}
              </div>
        </div>
      `
  )
}

const signatureAndDate = '<div style="font-size: 6pt; margin-left: 70%">(подпись, дата)</div>'
