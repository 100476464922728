import { Bim } from "../../models/Bim";
import { IBimApiRepository } from "./interface"
import { IPDMAuthRepository } from "../PDMAuthRepository/interface"
import { IBundleRepository } from "../BundleRepository/interface"
import { ModelElement } from "../../models/ModelElement/ModelElement"
import { ObservableMap } from "mobx";
import { computedFn } from "mobx-utils";
import { Bundle } from "../../models/Bundle/Bundle";
import { EBundleFileKeys } from "../../models/BundleFile";

export class BimApiElementsRepository implements IBimApiRepository {
  constructor(private props: {
    authRepository: IPDMAuthRepository,
    bundleRepository: IBundleRepository
    host: string
  }) {
    this.props = props
  }

  private caches = new Map<Bim["id"], Promise<any>>()

  // consolidated models
  private models = new ObservableMap<Bim["id"], Map<ModelElement["id"], ModelElement>>()

  // geometry models
  private geometryModels = new ObservableMap<Bim["id"], Map<ModelElement["modelID"], ModelElement>>()

  private getBundleByBimAsync(bim: Bim) {
    if (!bim.bundle) return undefined
    return this.props.bundleRepository.getByIdAsync(bim.bundle)
  }

  private getElementsHref(bundle: Bundle) {
    return `${this.props.host}api/v1/bims/${bundle.bimApiId}/properties`
  }

  private getMetadataHref(bundle: Bundle) {
    return bundle.getFileByKey(EBundleFileKeys.bim_metadata)?.url
  }

  getByBim(bim: Bim) {
    this.loadModelElementsWithCacheAsync(bim)

    return Array.from(this.models.get(bim.id)?.values() || [])
  }

  async getByBimAsync(bim: Bim) {
    await this.loadModelElementsWithCacheAsync(bim)

    return Array.from(this.models.get(bim.id)?.values() || [])
  }

  private async loadModelElementsWithCacheAsync(bim: Bim): Promise<any> {
    const cache = this.caches.get(bim.id)

    if (cache) return cache

    const promise = this.loadModelElementsAsync(bim)
    this.caches.set(bim.id, promise)

    return promise
  }

  private async loadModelElementsAsync(bim: Bim): Promise<any> {
    const bundle = await this.getBundleByBimAsync(bim)
    if (!bundle) return

    const propertiesResponse = await fetch(this.getElementsHref(bundle), {
      headers: await this.props.authRepository.getAuthHeadersAsync()
    })

    const properties: {
      [key: string]: {
        Sarex: { SarexId: number }
      }
    } = await propertiesResponse.json()

    const modelElements = new Map<ModelElement["modelID"], ModelElement>()

    for (const modelIDKey in properties) {
      const row = properties[modelIDKey]

      const modelID = Number(modelIDKey)

      const model = new ModelElement({
        id: String(row.Sarex.SarexId),
        modelID: modelID,
        bim: bim.id,
        properties: row
      })

      modelElements.set(model.modelID, model)
    }

    const metadataHref = this.getMetadataHref(bundle)

    if (metadataHref) {
      const metadataResponse = await fetch(metadataHref, {
        headers: await this.props.authRepository.getAuthHeadersAsync()
      })
      const metadata: {
        elements: {
          [key: string]: {
            children: number[]
            hierarchy: number[]
          }
        }
      } = await metadataResponse.json()

      for (const modelIDKey in metadata.elements) {
        const model = modelElements.get(Number(modelIDKey))
        if (!model) continue

        const parentModelID = metadata.elements[modelIDKey].hierarchy.pop()
        if (!parentModelID) continue

        const parent = modelElements.get(parentModelID)
        if (!parent) continue

        model.setParent(parent)
      }
    }

    const consolidatedModelElements = new Map<ModelElement["id"], ModelElement>()
    const geometryModelElements = new Map<ModelElement["modelID"], ModelElement>()

    for (const [modeID, modelElement] of modelElements) {
      if (modelElement.isConsolidatedModelElement) {
        consolidatedModelElements.set(modelElement.id, modelElement)
      }
      if (modelElement.isGeometryElement) {
        geometryModelElements.set(modelElement.modelID, modelElement)
      }
    }

    this.models.set(bim.id, consolidatedModelElements)
    this.geometryModels.set(bim.id, geometryModelElements)
  }

  getById(bim: Bim, id: ModelElement["id"]) {
    this.loadModelElementsWithCacheAsync(bim)

    return this.models.get(bim.id)?.get(id)
  }

  async getByIdAsync(bim: Bim, id: ModelElement["id"]) {
    await this.loadModelElementsWithCacheAsync(bim)

    return this.models.get(bim.id)?.get(id)
  }

  private getModelIDToModelElementMap = computedFn((models: Map<ModelElement["id"], ModelElement>): Map<ModelElement["modelID"], ModelElement> => {
    return new Map(
      Array.from(models.values()).map(model => ([model.modelID, model]))
    )
  }, { keepAlive: true })

  getByModelID(bim: Bim, modelID: ModelElement["modelID"]) {
    this.loadModelElementsWithCacheAsync(bim)
    const models = this.models.get(bim.id)

    return models ? this.getModelIDToModelElementMap(models).get(modelID) : undefined
  }

  async getByModelIDAsync(bim: Bim, modelID: ModelElement["modelID"]) {
    await this.loadModelElementsWithCacheAsync(bim)
    const models = this.models.get(bim.id)

    return models ? this.getModelIDToModelElementMap(models).get(modelID) : undefined
  }

  async getByGeometryModelIDAsync(bim: Bim, modelID: ModelElement["modelID"]): Promise<ModelElement | undefined> {
    await this.loadModelElementsWithCacheAsync(bim)

    return this.geometryModels.get(bim.id)?.get(modelID)?.consolidatedModelElement
  }
}