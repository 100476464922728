import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EActions } from "../../usecase/Permissions/Permissions";
import { NewPrescriptionFormViewModel } from "../PrescriptionFormViewModel/NewPrescriptionFormViewModel";
import { PrescriptionFormViewModel } from "../PrescriptionFormViewModel/PrescriptionFormViewModel";
import { PrescriptionsListViewModel } from "../PrescriptionsListViewModel/PrescriptionsListViewModel";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { EPrescriptionsPageContent, IPrescriptionsPageViewModel } from "./interface";

export class PrescriptionsPageViewModel implements IPrescriptionsPageViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get showViewModel() {
    if (this.content !== EPrescriptionsPageContent.form) return

    return new SimpleViewModel<boolean>({
      value: false,
    })
  }

  @computed
  get content() {
    if (
      this.props.layers.usecases.prescriptions.selectedPrescription ||
      this.props.layers.usecases.prescriptions.addMode
    ) {
      return EPrescriptionsPageContent.form
    }

    return EPrescriptionsPageContent.list
  }

  @computed
  get addButtonViewModel() {
    if (this.props.layers.usecases.permissions.actionIsBlocked(EActions.addPrescription)) return

    if (this.content === EPrescriptionsPageContent.list) return {
      onClick: () => {
        this.props.layers.usecases.prescriptions.setAddMode(true)
      }
    }
  }

  @computed
  get showButtonViewModel() {
    const showViewModel = this.showViewModel
    if (!showViewModel) return

    return {
      active: showViewModel.value,
      onClick: () => showViewModel.onChange(!showViewModel.value)
    }
  }

  @computed
  get prescriptionsListViewModel() {
    return new PrescriptionsListViewModel(this.props)
  }

  @computed
  get prescriptionFormViewModel() {
    const selectedPrescription = this.props.layers.usecases.prescriptions.selectedPrescription
    if (selectedPrescription) {
      return new PrescriptionFormViewModel({
        ...this.props,
        prescription: selectedPrescription,
        previewViewModel: this.showViewModel
      })
    }
    return new NewPrescriptionFormViewModel({
      ...this.props,
      previewViewModel: this.showViewModel
    })
  }

  onBack() {
    if (this.content === EPrescriptionsPageContent.list) {
      this.props.layers.usecases.viewState.setPanelOpened(false)
    } else if (this.content === EPrescriptionsPageContent.form) {
      this.props.layers.usecases.viewState.setSelectedPrescriptions()
      this.props.layers.usecases.prescriptions.setAddMode(false)
    }
  }
}