import { makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Collection } from "../../models/Collection";
import { ERemarkStatus, ERemarkStatusColor } from "../../models/Remark";
import { IPieChartViewModel } from "../PieChartViewModel/interface";
import { IPieChartWithLegendViewModel } from "./interface";

export class PieChartWithRemarkStatusForCollectionsAsync implements IPieChartWithLegendViewModel {
  constructor(private props: {
    layers: ILayers
    collections: Collection[]
    header?: string
    date: Date
  }) {
    this.props = props

    makeObservable(this)
  }

  get header() {
    return this.props.header || ""
  }

  formatValue(value: number, totalValue: number) {
    if (totalValue === 0) return "?"

    return `${(value / totalValue * 100).toFixed(0)}% (${value} шт.)`
  }

  @observable
  pieChartViewModel: IPieChartViewModel = {
    data: []
  }

  @observable
  legends: IPieChartWithLegendViewModel["legends"] = []

  private getRemarkStatusName(status: ERemarkStatus): string {
    switch (status) {
      case ERemarkStatus.opened:
        return "Открыто"
      case ERemarkStatus.closed:
        return "Закрыто"
      case ERemarkStatus.pending:
        return "Ожидает"
    }
  }

  async compute() {
    this.legends = []
    this.pieChartViewModel.data = []

    const data = await this.props.layers.repositories.statsRepository.getRemarkCountForCollectionsGroupByStatusAsync({
      collections: this.props.collections.map(c => c.id),
      lte: this.props.date
    })

    const totalCount = data.reduce((acc, [_, count]) => acc + count, 0)

    for (const [status, count] of data) {
      const color = ERemarkStatusColor[status]

      this.legends.push({
        key: status,
        color,
        valueLabel: this.formatValue(count, totalCount),
        label: this.getRemarkStatusName(status)
      })

      this.pieChartViewModel.data.push({
        color,
        value: count,
      })

    }
  }
}