import { action, computed, makeObservable, observable } from "mobx";
import { ChangeEvent, CSSProperties, MouseEvent } from "react";
import { EOrientations } from "../PanelDropdownMenuViewModel/interface";
import { PanelDropdownMenuViewModel } from "../PanelDropdownMenuViewModel/PanelDropdownMenuViewModel";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { IPanelInputViewModel } from "./interface";

export class PanelInputViewModel implements IPanelInputViewModel {
  constructor(private props: {
    label?: string
    placeholder?: string
    disabled?: boolean
    hints?: string[]
    hintOrientation?: EOrientations
  } & ({
    value?: string
  } | {
    valueViewModel: SimpleViewModel<string>
  })) {
    this.props = props

    if ("valueViewModel" in props) {
      this.valueViewModel = props.valueViewModel
    } else {
      this.valueViewModel = new SimpleViewModel({ value: props.value || "" })
    }

    makeObservable(this)
  }

  private valueViewModel: SimpleViewModel<string>

  @computed
  get panelDropdownMenuViewModel() {
    const hints = this.props.hints
    if (!hints || (hints.length === 0)) return

    return new PanelDropdownMenuViewModel({
      orientation: this.props.hintOrientation,
      getOptions: () => this.getHintOptions()
    })
  }

  getHintOptions() {
    return this.props.hints?.map(hint => ({
      key: hint,
      label: hint,
      selected: this.value === hint,
      onClick: () => {
        if (this.props.disabled) return

        this.valueViewModel.value = hint
        this.input?.focus()
      }
    })) || []
  }

  @computed
  get placeholder() {
    return this.props.placeholder || ""
  }

  @computed
  get value() {
    return this.valueViewModel.value || ""
  }

  @computed
  get label() {
    return this.props.label || ""
  }

  @computed
  get disabled() {
    return this.props.disabled || false
  }

  @computed
  get rootStyle() {
    const orientation = this.panelDropdownMenuViewModel?.orientation
    return {
      flexDirection: (orientation === EOrientations.right) || (orientation === EOrientations.left) ? "row" : "column"
    } as CSSProperties
  }

  private input?: HTMLInputElement

  onInputMount(input: HTMLInputElement) {
    this.input = input
  }

  onClick = (e: MouseEvent) => {
    if (this.panelDropdownMenuViewModel) {
      this.panelDropdownMenuViewModel.skipEvent = e.nativeEvent
      this.panelDropdownMenuViewModel.open()
    }
    this.input?.focus()
  }

  @action
  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.disabled) return

    this.valueViewModel.onChange(e.target.value)
  }
}