import React, { useEffect } from "react"
import { Dialog, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IPrescriptionFormViewModel } from "../../view-model/PrescriptionFormViewModel/interface"
import PanelSelectorOld from "../../components/Selectors/PanelSelector";
import PanelInputOld from "../../components/Inputs/PanelInput";
import PanelCommentInput from "../../components/Inputs/PanelCommentInput";
import SimpleButton from "../../components/Buttons/SimpleButton";
import { StatusWrapper } from "../StatusWrapper/StatusWrapper";
import { PrescriptionDocument } from "../PrescriptionDocument/PrescriptionDocument";
import PanelDateSelector from "../../components/Selectors/PanelDateSelector";
import { PanelSelector } from "../PanelSelector/PanelSelector";
import { UploadButton } from "../Buttons/UploadButton";
import { UploadFilesList } from "../UploadFilesList/UploadFilesList";
import { PanelInput } from "../PanelInput/PanelInput";

const useClasses = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto"
  },
  header: {
    margin: "0px 0px 27px 26px"
  },
  button: {
    marginRight: 20
  },
  code: {
    display: "inline-block",
    fontWeight: "bold",
    fontSize: "24px",
  },
  buttons: {
    marginTop: 20,
    margin: "0 26px",
  },
  addButton: {
    marginTop: 20,
    //@ts-ignore
    backgroundColor: theme.palette.greenColor,
    color: "white"
  },
  groupButtons: {
    marginTop: 20,
    display: "grid",
    gridTemplateColumns: "repeat( auto-fit, minmax(0%, 1fr) )",
    columnGap: 22
  },
  downloadButton: {
    flex: 1,
    //@ts-ignore
    backgroundColor: theme.palette.primary1Color,
  },
  deleteButton: {
    flex: 1,
    color: "white",
    //@ts-ignore
    backgroundColor: theme.palette.redColor,
  },
  preview: {
    position: "absolute",
    left: "calc(100% + 20px)",
    top: "20px",
    bottom: "20px",
    backgroundColor: "white",
    overflow: "auto"
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main
  },
  showFilesButton: {
    marginTop: 20,
    backgroundColor: "#F5F5F5",
    color: "#0005"
  },
  filesList: {
    padding: 20,
    width: 656
  }
}))

const usePaddingClasses = makeStyles(theme => ({
  padding: {
    padding: "0 26px"
  },
}))

const useClassesPrescriptionDocument = makeStyles(theme => ({
  root: {
    padding: "60px !important",
    margin: "0px !important",
  },
}))

interface IPrescriptionFormProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPrescriptionFormViewModel
}

export const PrescriptionForm = observer((props: IPrescriptionFormProps) => {
  const classes = useClasses(props)

  const paddingClasses = usePaddingClasses()
  const prescriptionDocumentClasses = useClassesPrescriptionDocument()

  return (
    <div className={classes.root}>
      <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
        <>
          <PanelSelector
            classes={paddingClasses}
            viewModel={props.viewModel.remarksSelectorViewModel}
          // label="Замечание"

          // value={props.viewModel.remarksSelectorViewModel.value}
          // onChange={e => props.viewModel.remarksSelectorViewModel.onChange(e)}
          // options={props.viewModel.remarksSelectorViewModel.options}
          // disabled={props.viewModel.remarksSelectorViewModel.disabled}
          // multiselect={props.viewModel.remarksSelectorViewModel.multiselect}
          />
          <PanelSelector
            classes={paddingClasses}
            viewModel={props.viewModel.contractorSelectorViewModel}
          // label="Подрядчик"

          // value={props.viewModel.contractorSelectorViewModel.value}
          // onChange={e => props.viewModel.contractorSelectorViewModel.onChange(e)}
          // options={props.viewModel.contractorSelectorViewModel.options}
          // disabled={props.viewModel.contractorSelectorViewModel.disabled}

          // group
          />
          {/* <PanelSelectorOld
            classes={paddingClasses}
            label="Предписание выдал"

            value={props.viewModel.issuedBySelectorViewModel.value}
            onChange={e => props.viewModel.issuedBySelectorViewModel.onChange(e)}
            options={props.viewModel.issuedBySelectorViewModel.options}
            disabled={props.viewModel.issuedBySelectorViewModel.disabled}
          /> */}
          <PanelSelector
            classes={paddingClasses}
            viewModel={props.viewModel.issuedBySelectorViewModel}
          // label="Предписание выдал"

          // value={props.viewModel.issuedBySelectorViewModel.value}
          // onChange={e => props.viewModel.issuedBySelectorViewModel.onChange(e)}
          // options={props.viewModel.issuedBySelectorViewModel.options}
          // disabled={props.viewModel.issuedBySelectorViewModel.disabled}
          />
          <PanelDateSelector
            classes={paddingClasses}

            label="Дата выдачи"
            value={props.viewModel.createdAtViewModel.value}
            disabled={props.viewModel.createdAtViewModel.disabled}
            placeholder="..."
            onChange={e => props.viewModel.createdAtViewModel.onChange(e)}
          />
          <PanelInputOld
            classes={paddingClasses}
            label="Получатель"
            placeholder="..."

            disabled={props.viewModel.receiverInputViewModel.disabled}
            value={props.viewModel.receiverInputViewModel.value}
            onChange={e => props.viewModel.receiverInputViewModel.onChange(e)}
          />
          {/* {
            props.viewModel.customReceiverCompanyInputViewModel ? (
              <PanelInputOld
                classes={paddingClasses}
                label="Кому"
                placeholder="..."

                disabled={props.viewModel.customReceiverCompanyInputViewModel.disabled}
                value={props.viewModel.customReceiverCompanyInputViewModel.value}
                onChange={e => props.viewModel.customReceiverCompanyInputViewModel?.onChange(e)}
              />
            ) : null
          } */}
          {
            props.viewModel.customTargetInputViewModel ? (
              <PanelInputOld
                classes={paddingClasses}
                label="Объект"
                placeholder="..."

                disabled={props.viewModel.customTargetInputViewModel.disabled}
                value={props.viewModel.customTargetInputViewModel.value}
                onChange={e => props.viewModel.customTargetInputViewModel?.onChange(e)}
              />
            ) : null
          }
          {
            props.viewModel.customCodeInputViewModel ? (
              <PanelInputOld
                classes={paddingClasses}
                label="Дополнительный номер"
                placeholder="Заполнить опционально"

                disabled={props.viewModel.customCodeInputViewModel.disabled}
                value={props.viewModel.customCodeInputViewModel.value}
                onChange={props.viewModel.customCodeInputViewModel.onChange}
              />
            ) : null
          }
          {
            props.viewModel.workDescriptionInputViewModel ? (
              <PanelInputOld
                classes={paddingClasses}
                label="Вид работ"
                placeholder="..."

                disabled={props.viewModel.workDescriptionInputViewModel.disabled}
                value={props.viewModel.workDescriptionInputViewModel.value}
                onChange={e => props.viewModel.workDescriptionInputViewModel?.onChange(e)}
              />
            ) : null
          }
          {
            props.viewModel.levelMarkSelectorViewModel ? (
              <PanelInput classes={paddingClasses} viewModel={props.viewModel.levelMarkSelectorViewModel} />
            ) : null
          }
          {
            props.viewModel.specialConditionsInputViewModel ? (
              <PanelCommentInput
                classes={paddingClasses}
                label="Особые условия"
                placeholder="..."

                disabled={props.viewModel.specialConditionsInputViewModel.disabled}
                value={props.viewModel.specialConditionsInputViewModel.value}
                onChange={e => props.viewModel.specialConditionsInputViewModel?.onChange(e)}
              />
            ) : null
          }
          <PanelCommentInput
            classes={paddingClasses}
            label="Требования по результатам проверки:"
            placeholder="Оставить требования"

            disabled={props.viewModel.notesInputViewModel.disabled}
            value={props.viewModel.notesInputViewModel.value}
            onChange={e => props.viewModel.notesInputViewModel.onChange(e)}
          />
          <div className={classes.buttons}>
            {
              props.viewModel.uploadButtonViewModel ? (
                <UploadButton
                  className={classes.uploadButton}
                  loading={props.viewModel.uploadButtonViewModel.loading}
                  onChange={e => props.viewModel.uploadButtonViewModel?.onChange(e)}
                >
                  Добавить файл
                </UploadButton>
              ) : null
            }

            {
              props.viewModel.showFilesViewModel ? (
                <SimpleButton
                  className={classes.showFilesButton}
                  {...props.viewModel.showFilesViewModel}
                >
                  {props.viewModel.showFilesViewModel.label}
                </SimpleButton>
              ) : null
            }
            {
              props.viewModel.applyButtonViewModel ? (
                <SimpleButton
                  className={classes.addButton}
                  {...props.viewModel.applyButtonViewModel}
                >
                  {props.viewModel.applyButtonViewModel.label}
                </SimpleButton>
              ) : null
            }
            <div className={classes.groupButtons}>
              {
                props.viewModel.downloadButtonViewModel ? (
                  <SimpleButton
                    className={classes.downloadButton}
                    {...props.viewModel.downloadButtonViewModel}
                  >
                    Скачать PDF
                  </SimpleButton>
                ) : null
              }
              {
                props.viewModel.deleteButtonViewModel ? (
                  <SimpleButton
                    className={classes.deleteButton}
                    {...props.viewModel.deleteButtonViewModel}
                  >
                    Удалить
                  </SimpleButton>
                ) : null
              }
            </div>
          </div>
        </>
      </StatusWrapper>
      {
        props.viewModel.preview ? (
          <div className={classes.preview}>
            {
              props.viewModel.preview.prescriptionDocumentViewModel ? (
                <PrescriptionDocument className={prescriptionDocumentClasses.root} viewModel={props.viewModel.preview.prescriptionDocumentViewModel} />
              ) : null
            }
          </div>
        ) : null
      }
      {
        props.viewModel.showFilesDialogViewModel ? (
          <Dialog open={props.viewModel.showFilesDialogViewModel.open} onClose={props.viewModel.showFilesDialogViewModel.onClose}>
            {props.viewModel.uploadFilesListViewModel ? <UploadFilesList className={classes.filesList} viewModel={props.viewModel.uploadFilesListViewModel} /> : null}
          </Dialog>
        ) : null
      }
    </div>
  );
})