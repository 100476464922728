import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';

import SimpleButton from '../Buttons/SimpleButton';

const useClasses = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.greenColor,
    width: 44,
    height: 44,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
}));

function NewStatusButtonPure(props) {
  const classes = useClasses(props);
  const {
    disabled,
    onClick
  } = props;

  return (
    <SimpleButton className={classes.root} onClick={onClick} disabled={disabled}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89471 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3ZM15.7772 10.1395L11.3023 14.6164C11.2903 14.6264 11.2863 14.6384 11.2742 14.6505C11.1664 14.7539 11.0227 14.8116 10.8732 14.8116C10.7238 14.8116 10.5801 14.7539 10.4723 14.6505C10.4602 14.6384 10.4562 14.6224 10.4442 14.6124L8.22277 12.3869C8.13064 12.2799 8.08241 12.1419 8.08778 12.0008C8.09315 11.8597 8.15171 11.7258 8.25171 11.626C8.35171 11.5263 8.48575 11.4681 8.6269 11.463C8.76806 11.458 8.90588 11.5066 9.0127 11.599L10.8773 13.4636L14.9873 9.34952C15.0938 9.25385 15.233 9.20266 15.3761 9.20649C15.5192 9.21032 15.6554 9.26889 15.7566 9.37012C15.8579 9.47136 15.9164 9.60756 15.9203 9.75068C15.9241 9.8938 15.8729 10.0329 15.7772 10.1395Z" fill="white" />
      </svg>
    </SimpleButton>
  );
}

export default memo(NewStatusButtonPure);