import { format } from "date-fns";
import { ILayers } from "../../core/Layers/interface";
import { BimElement } from "../../models/BimElement";
import { ICustomPropertyPanelViewModel, TProperty } from "./interface";

// const displayedModelProperties = [
//   'INGD_Миникарта',
//   'INGD_Описание',
//   'INGD_Марка',
//   materialsForgePropertyRegExp
// ];

export class CustomPropertyPanelViewModel implements ICustomPropertyPanelViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props
  }

  async getStatusProperty(): Promise<TProperty> {
    const elements = await this.props.layers.usecases.selectedElements.getSelectedBimElementsAsync()
    const statuses = await this.props.layers.usecases.statuses.getActiveStatusHistoriesForBimElements(elements)
    const statusDictionaries = await this.props.layers.usecases.statuses.getStatusDictionaryFromStatusHistory(statuses)

    return {
      name: "Статус",
      value: statusDictionaries.map(statusDictionary => statusDictionary?.name || "Работы не начаты").join(", "),
      category: ""
    }
  }

  async getRemarkProperty(): Promise<TProperty> {
    const elements = await this.props.layers.usecases.selectedElements.getSelectedBimElementsAsync()
    const existElements: BimElement[] = elements.filter<BimElement>((e): e is BimElement => e instanceof BimElement)

    const remarks = await this.props.layers.usecases.remarks.getRemarksForBimElementsAsync(existElements)
    const notClosedRemarks = this.props.layers.usecases.remarks.getNotClosedRemarks(remarks)
    const codes: string[] = []
    for (const remark of notClosedRemarks) {
      const code = await this.props.layers.usecases.remarks.getRemarkCodeAsync(remark)
      const date = format(remark.createdAt, "dd.MM.yyyy")
      codes.push(`${code} (${date})`)
    }

    return {
      name: "Замечания",
      value: codes.join(", ") || "Отсутствуют",
      category: ""
    }
  }

  async getPrescriptionProperty() {
    const elements = await this.props.layers.usecases.selectedElements.getSelectedBimElementsAsync()
    const existElements: BimElement[] = elements.filter<BimElement>((e): e is BimElement => e instanceof BimElement)
    const prescriptions = await this.props.layers.usecases.prescriptions.getNotClosedPrescriptionsByBimElementsAsync(existElements)

    const codes: string[] = []
    for (const prescription of prescriptions) {
      const code = await this.props.layers.usecases.prescriptions.getPrescriptionCodeAsync(prescription)
      const date = format(prescription.deadlineAt, "dd.MM.yyyy")
      codes.push(`${code} (${date})`)
    }

    return {
      name: "Предписания",
      value: codes.join(", ") || "Отсутствуют",
      category: ""
    }
  }

  async getDeadlineProperty() {
    const elements = await this.props.layers.usecases.selectedElements.getSelectedBimElementsAsync()
    const dates: Set<string> = new Set()

    for (const element of elements) {
      if (element) {
        const bimStatus = await this.props.layers.repositories.bimStatusRepository.getActualByBimElementAsync(element)

        if (bimStatus) dates.add(format(bimStatus.deadline, "dd.MM.yyyy"))
      }
    }

    return {
      name: "Плановая дата",
      value: Array.from(dates).join(", ") || "Отсутствуют",
      category: ""
    }
  }

  async getWorkTypesProperty() {
    const forgeElements = this.props.layers.usecases.selectedElements.selectedForgeElements
    let workTypes = await this.props.layers.usecases.elements.getWorkTypesByForgeElementsAsync(forgeElements)

    for (const forgeElement of forgeElements) {
      const statusDictionaries = await this.props.layers.usecases.statuses.getStatusDictionaryForForgeElementAsync(forgeElement)
      const workTypesSet = new Set(statusDictionaries.map(s => s.workType))
      workTypes = workTypes.filter(workType => workType ? workTypesSet.has(workType.id) : true)
    }

    return {
      name: "Вид работ",
      value: workTypes.map(workType => workType?.fullName || "неизвестно").join(", "),
      category: ""
    }
  }

  unique<T>(array: T[]): T[] {
    return Array.from((new Set(array)).values())
  }

  getPropertiesValue(array: (string | undefined)[]) {
    return this.unique(array).join(", ") || "Отсутствует"
  }

  async getForgeElementsProperties() {
    const forgeElements = this.props.layers.usecases.selectedElements.selectedForgeElements

    return [
      {
        name: "Этаж",
        value: this.getPropertiesValue(forgeElements.map(forgeElement => forgeElement.storey)),
        category: "Местоположение"
      },
      {
        name: "Секция",
        value: this.getPropertiesValue(forgeElements.map(forgeElement => forgeElement.section)),
        category: "Местоположение"
      },
      {
        name: "Длина",
        value: this.getPropertiesValue(forgeElements.map(forgeElement => forgeElement.length)),
        category: "Размеры"
      },
      {
        name: "Ширина",
        value: this.getPropertiesValue(forgeElements.map(forgeElement => forgeElement.width)),
        category: "Размеры"
      },
      {
        name: "Высота",
        value: this.getPropertiesValue(forgeElements.map(forgeElement => forgeElement.height)),
        category: "Размеры"
      },
      {
        name: "Объем",
        value: this.getPropertiesValue(forgeElements.map(forgeElement => forgeElement.value)),
        category: "Размеры"
      },
      {
        name: "Площадь",
        value: this.getPropertiesValue(forgeElements.map(forgeElement => forgeElement.square)),
        category: "Размеры"
      },
      {
        name: "Группа конструкции",
        value: this.getPropertiesValue(forgeElements.map(forgeElement => forgeElement.constructionGroup)),
        category: "Прочее"
      },
      {
        name: "Марка материала",
        value: this.getPropertiesValue(forgeElements.map(forgeElement => forgeElement.material)),
        category: "Прочее"
      },
    ]
  }

  // {
  //   name: "Вид работ",
  //   value: this.getPropertiesValue(
  //     // forgeElements.map(forgeElement => forgeElement.constructionGroup)
  //     ),
  //   category: "Прочее"
  // },

  async getProperties() {
    return [
      await this.getStatusProperty(),
      await this.getRemarkProperty(),
      await this.getPrescriptionProperty(),
      await this.getDeadlineProperty(),
      ...await this.getForgeElementsProperties(),
      await this.getWorkTypesProperty()
    ]
  }
}