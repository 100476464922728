import { BimElement, NewBimElement, TBimElementProps, TNewBimElementProps } from "../../models/BimElement";
import { IBimElementsRepository } from "./interface";
import { TBimProps } from "../../models/Bim";
import { computed } from "mobx";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineRepository } from "../Repository/RestOfflineRepository";
import { computedFn } from "mobx-utils";
import { IndexedDBPatchesRepository } from "../PatchesRepository/IndexedDBPatchesRepository";
import { IDBPDatabase } from "idb";

type TRestProps = {
  id: number
  object_id: string | number
  upload: number
  work_type: number | string
}

type TNewRestProps = {
  object_id: string | number
  upload: number | string
  work_type: number | string
}

export class RestBimElementsRepository implements IBimElementsRepository {
  constructor(private props: {
    host: string
    authRepository: IAuthRepository
    db: IDBPDatabase
    patchesTableName: string
  }) {
    this.props = props

    this.offlineRepository = new RestOfflineRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: BimElement,
      NewModel: NewBimElement,
      serialyze: this.serialyze,
      serialyzeAdd: this.serialyzeAdd,
      patchesRepository: new IndexedDBPatchesRepository({
        db: props.db,
        name: props.patchesTableName,
        Model: BimElement
      })
    })
  }

  offlineRepository: RestOfflineRepository<BimElement, TRestProps, TNewRestProps, TNewBimElementProps>

  @computed
  get path() {
    return `${this.props.host}api/main/bim-element/`
  }

  getById(id) {
    return this.offlineRepository.getById(id)
  }

  getByIdAsync(id) {
    return this.offlineRepository.getByIdAsync(id)
  }

  add(props) {
    return this.offlineRepository.add(props)
  }

  addBatch(props) {
    return this.offlineRepository.addBatch(props)
  }

  getByBimId = computedFn((bim: TBimProps["id"]) => {
    return this.offlineRepository.getByParams({ bim }, { upload: bim })
  })

  getGuidsMap = computedFn((array: BimElement[]) => {
    return new Map(array.map(element => [element.modelElement, element]))
  })

  getByBimIdAsync = (bim: TBimProps["id"]) => {
    return this.offlineRepository.getByParamsAsync({ bim }, { upload: bim })
  }

  getByGuid(bim: TBimProps["id"], guid: TBimElementProps["modelElement"]) {
    const elements = this.getByBimId(bim)
    const map = this.getGuidsMap(elements)
    return map.get(guid)
  }

  async getByGuidAsync(bim: TBimProps["id"], guid: TBimElementProps["modelElement"]) {
    const elements = await this.getByBimIdAsync(bim)
    return elements.find(element => element.modelElement === guid)
  }

  serialyzeAdd(props: TNewBimElementProps): TNewRestProps {
    return {
      object_id: props.modelElement,
      work_type: props.workType,
      upload: props.bim
    }
  }

  serialyze(props: TRestProps): BimElement["props"] {
    return {
      id: props.id,
      modelElement: props.object_id,
      bim: props.upload,
      workType: props.work_type
    }
  }
}