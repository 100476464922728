import { action, computed, makeObservable, ObservableSet } from "mobx"
import { EAdditionalStatusDictionaryCodes, TStatusDictionaryProps } from "../../models/StatusDictionary"
import { IStatusDictionaryRepository } from "../../repositories/StatusDictionaryRepository/interface"
import { Elements } from "../Elements/Elements"

export class SelectedStatuses {
  constructor(private props: {
    statusDictionaryRepository: IStatusDictionaryRepository
    elements: Elements
  }) {
    this.props = props

    makeObservable(this)
  }

  private selectedStatuses = new ObservableSet<TStatusDictionaryProps["id"] | EAdditionalStatusDictionaryCodes>()

  get selectedStatusesLenght() {
    return this.selectedStatuses.size
  }

  @computed
  get selectedStatusesArray() {
    return Array.from(this.selectedStatuses)
  }

  @computed
  get activeWorkTypeIds() {
    return new Set(this.props.elements.getWorkTypesByForgeElements(this.props.elements.forgeElements).map(workType => workType?.id))
  }

  @computed
  get activeStatusDictionaryOptions() {
    return this.statusDictionaryOptions.filter(statusDictionary => !statusDictionary.workType || this.activeWorkTypeIds.has(statusDictionary.workType))
  }

  @computed
  get statusDictionaryOptions() {
    const options = this.props.statusDictionaryRepository.getAll().sort((s1, s2) => s1.order - s2.order)
    return options
  }

  hasStatuses(statuses: (TStatusDictionaryProps["id"] | EAdditionalStatusDictionaryCodes)[]) {
    return statuses.some(status => this.selectedStatuses.has(status))
  }

  @action
  switchStatuses(statuses: (TStatusDictionaryProps["id"] | EAdditionalStatusDictionaryCodes)[]) {
    for (const status of statuses) {
      if (this.selectedStatuses.has(status)) {
        this.selectedStatuses.delete(status)
      } else {
        this.selectedStatuses.add(status)
      }
    }
  }

  @action
  setStatuses(values: any) {
    this.selectedStatuses.replace(values)
  }
}