import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IElementsPropertyShortPanelViewModel } from "../../view-model/ElementsPropertyShortPanelViewModel/interface"

const useClasses = makeStyles(theme => ({
  root: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "14px",
    display: "inline-block",
    whiteSpace: "pre",
    padding: 10
  },
  header: {
    textAlign: "center",
  },
  row: {
    marginBottom: 2,
    fontFamily: "initial"
  }
}))

interface IElementsPropertyShortPanelProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IElementsPropertyShortPanelViewModel
}

export const ElementsPropertyShortPanel = observer((props: IElementsPropertyShortPanelProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      <div className={classes.header}>{props.viewModel.header}</div>
      {
        props.viewModel.rows.map(row => (
          <div className={classes.row} key={row.key}>{row.name}{row.separator !== undefined ? row.separator : ":"} <b>{row.value}</b></div>
        ))
      }
    </div>
  )
})