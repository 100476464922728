import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IStoreyFormViewModel } from "../../view-model/StoreyFormViewModel/interface"
import PanelSelector from "../../components/Selectors/PanelSelector"
import PanelInput from "../../components/Inputs/PanelInput"
import SimpleButton from "../../components/Buttons/SimpleButton"
import clsx from "clsx"
import { StatusWrapper } from "../StatusWrapper/StatusWrapper"

const useClasses = makeStyles(theme => ({
  root: {},
  header: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: 15
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10
  },
  button: {
    flex: 1
  },
  cancelButton: {
    backgroundColor: "#0001",
    marginRight: 10,
  },
  applyButton: {
    backgroundColor: "green",
    color: "white"
  }
}))

interface IStoreyFormProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IStoreyFormViewModel
}

export const StoreyForm = observer((props: IStoreyFormProps) => {
  const classes = useClasses(props)

  return (
    <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
      <div className={classes.root}>
        <div className={classes.header}>Редактирование отметки</div>
        {
          props.viewModel.targetSelectorViewModel ?
            <PanelSelector
              value={props.viewModel.targetSelectorViewModel.value}
              options={props.viewModel.targetSelectorViewModel.options}
              onChange={props.viewModel.targetSelectorViewModel.onChange}
              label="Объект"
            />
            : null
        }
        {
          props.viewModel.noteInputViewModel ?
            <PanelInput
              value={props.viewModel.noteInputViewModel.value}
              onChange={props.viewModel.noteInputViewModel.onChange}
              label="Отметка"
            />
            : null
        }
        <div className={classes.buttons}>
          <SimpleButton onClick={props.viewModel.onCancel} className={clsx(classes.button, classes.cancelButton)}>Отменить</SimpleButton>
          <SimpleButton
            onClick={props.viewModel.saveButtonViewModel.onClick}
            disabled={props.viewModel.saveButtonViewModel.disabled}
            className={clsx(classes.button, classes.applyButton)}
          >Сохранить</SimpleButton>
        </div>
      </div>
    </StatusWrapper>

  )
})