import { action, computed, makeObservable, observable, toJS } from "mobx";
import { ISimpleViewModel } from "./interface";

export class SimpleViewModel<T> implements ISimpleViewModel<T> {
  constructor(private props: { value: T, disabled?: boolean }) {
    this.props = props
    this.value = props.value

    makeObservable(this)
  }

  @observable
  value: T

  @computed
  get disabled() {
    return this.props.disabled === true
  }

  @action
  onChange = (value: T) => {
    this.value = value
  }

  clone() {
    return new SimpleViewModel({
      value: toJS(this.value)
    })
  }
}