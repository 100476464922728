import { action, computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { numberFormat } from "../../heplpers/formats";
import { Bim } from "../../models/Bim";
import { Company, TCompanyProps } from "../../models/Company";
import { Prescription } from "../../models/Prescription";
import { Remark } from "../../models/Remark";
import { TNewPrescriptionProps } from "../../repositories/PrescriptionRepository/interface";
import { EVariants } from "../../usecase/Notifications/Notifications";
import { EActions } from "../../usecase/Permissions/Permissions";
import { UploadButtonViewModelV2 } from "../Buttons/UploadButtonViewModelV2";
import { DialogViewModel } from "../DialogViewModel/DialogViewModel";
import { InputViewModel } from "../InputViewModel/InputViewModel";
import { EOrientations } from "../PanelDropdownMenuViewModel/interface";
import { PanelInputViewModel } from "../PanelInputViewModel/PanelInputViewModel";
import { PanelSelectorMultiViewModel } from "../PanelSelectorViewModel/PanelSelectorMultiViewModel";
import { PanelSelectorSingleViewModel } from "../PanelSelectorViewModel/PanelSelectorSingleViewModel";
import { PrescriptionDocumentViewModel } from "../PrescriptionDocumentViewModel/PrescriptionDocumentViewModel";
import { ISimpleViewModel } from "../SimpleViewModel/interface";
import { SetViewModel } from "../SimpleViewModel/SetViewModel";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { UploadFilesListViewModelV2 } from "../UploadFilesListViewModel/UploadFilesListViewModelV2";
import { IPrescriptionFormViewModel } from "./interface";

export class NewPrescriptionFormViewModel implements IPrescriptionFormViewModel {
  constructor(private props: {
    layers: ILayers
    previewViewModel?: ISimpleViewModel<boolean>
  }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  statusWrapperViewModel = new StatusWrapperViewModel()

  @computed
  private get contractors() {
    return this.props.layers.repositories.companiesRepository.getAll().filter(company => company.isContractor)
  }

  private getRemarkLabel(remark: Remark) {
    const base = this.props.layers.repositories.remarksBaseRepository.getById(remark.remarkBase)
    return `${this.props.layers.usecases.remarks.getRemarkCode(remark)} - ${base?.title || "?"} ${remark.normativeDocument}`
  }

  @computed
  private get selectedRemarks() {
    const remarks: Remark[] = []

    for (const id of this.selectedRemarksSet.array) {
      const remark = this.props.layers.repositories.remarksRepository.getById(id)
      if (remark) {
        remarks.push(remark)
      }
    }

    return remarks
  }

  @computed
  private get remarksForContractor() {
    const contractor = this.contractorSelectorViewModel.value

    if (!contractor) return this.props.layers.usecases.prescriptions.freeRemarks

    return this.props.layers.usecases.prescriptions.freeRemarks
      .filter(remark => remark.contractor === contractor)
  }

  private selectedRemarksSet = new SetViewModel<Remark["id"]>()

  @computed
  get remarksSelectorViewModel() {
    return new PanelSelectorMultiViewModel<Remark["id"]>({
      orientation: EOrientations.right,
      getOptions: () => this.remarksForContractor
        .map(remark => ({
          value: remark.id,
          label: this.getRemarkLabel(remark)
        })),
      setViewModel: this.selectedRemarksSet,
      label: "Замечание",
      onChange: () => this.applyContractorForRemarks()
    })
  }

  @action
  private applyContractorForRemarks() {
    let contractor: TCompanyProps["id"] | undefined = undefined
    for (const id of this.selectedRemarksSet.array) {
      const remark = this.props.layers.repositories.remarksRepository.getById(id)
      if (remark) {
        contractor = remark.contractor
        break
      }
    }

    this.contractorSelectorViewModel.valueViewModel.onChange(contractor)
  }

  @action
  private applyRemarksForContractor() {
    this.selectedRemarksSet.clear()
  }

  @computed
  get contractorSelectorViewModel() {
    return new PanelSelectorSingleViewModel<Company["id"] | undefined>({
      orientation: EOrientations.right,
      options: this.contractors.map(contractor => ({
        value: contractor.id,
        label: contractor.name
      })),
      defaultValue: undefined,
      label: "Подрядчик",
      onChange: () => {
        this.applyRemarksForContractor()
      }
    })
  }

  @computed
  get notesInputViewModel() {
    return new SimpleViewModel({
      value: ""
    })
  }

  @computed
  get receiverInputViewModel() {
    return new SimpleViewModel({
      value: ""
    })
  }

  @computed
  get specialConditionsInputViewModel() {
    return new SimpleViewModel({
      value: ""
    })
  }

  @computed
  get customTargetInputViewModel() {
    if (!this.props.layers.usecases.permissions.actionIsAllowed(EActions.editCustomPrescriptionsFields)) return

    return new SimpleViewModel({
      value: ""
    })
  }

  @computed
  get createdAtViewModel() {
    return new SimpleViewModel({
      value: (new Date()).toISOString()
    })
  }

  // @computed
  // get customReceiverCompanyInputViewModel() {
  //   if (!this.props.layers.usecases.permissions.actionIsAllowed(EActions.editCustomPrescriptionsFields)) return

  //   return new SimpleViewModel({
  //     value: ""
  //   })
  // }

  @computed
  get preview() {
    if (this.props.previewViewModel?.value) return {
      prescriptionDocumentViewModel: this.prescriptionDocumentViewModel
    }
  }

  @computed
  get applyButtonViewModel() {
    return {
      disabled: this.selectedRemarksSet.size === 0 || !this.newPrescriptionProps,
      onClick: async () => {
        if (!this.newPrescriptionProps) return

        this.statusWrapperViewModel.load()
        await this.props.layers.repositories.prescriptionsRepository.add(this.newPrescriptionProps)
        this.props.layers.usecases.prescriptions.setAddMode(false)
        this.statusWrapperViewModel.success()
      },
      label: "Добавить"
    }
  }

  @computed
  get project() {
    return this.props.layers.usecases.bims.selectedProjects[0]
  }

  issuedByViewModel = new SimpleViewModel({ value: undefined })

  @computed
  get issuedBySelectorViewModel() {
    return new PanelSelectorSingleViewModel({
      valueViewModel: this.issuedByViewModel,
      label: "Предписание выдал",
      orientation: EOrientations.right,
      options: this.project ? this.props.layers.repositories.companiesRepository.getForProject(this.project.id).filter(company => company.isControlCompany).map(company => ({
        value: company.id,
        label: company.name
      })) : []
    })
  }

  @computed
  get workDescriptionInputViewModel() {
    return new InputViewModel({
      value: "",
    })
  }

  @computed
  private get selectedBimId(): number | string | undefined {
    return this.selectedRemarks[0]?.bim
  }

  @computed
  private get selectedBim(): Bim | undefined {
    return this.selectedBimId ? this.props.layers.repositories.bimsRepository.getById(this.selectedBimId) : undefined
  }

  @computed
  private get levelMarks(): string[] {
    const marks = new Set<string>()
    marks.add("отсутствует")

    if (this.selectedBim) {
      const marksFromBim = this.props.layers.usecases.storeyNotes.getLevelMarksByBim(this.selectedBim)
      for (const mark of marksFromBim) {
        marks.add(mark)
      }
    }

    return Array.from(marks.values()).sort((a, b) => {
      if (a === "отсутствует") return -1
      if (b === "отсутствует") return 1

      return parseFloat(a) - parseFloat(b)
    })
  }

  @computed
  get levelMarkSelectorViewModel() {
    return new PanelInputViewModel({
      label: "Отметка",
      placeholder: "...",
      hintOrientation: EOrientations.right,
      value: "",
      hints: this.levelMarks,
    })
  }

  @computed
  get customCodeInputViewModel() {
    return new InputViewModel({
      value: "",
      mask: /\d+/g,
      onMaskPrevented: () => {
        this.props.layers.usecases.notifications.notify("Дополнительный номер может состоять только из цифр", {
          variant: EVariants.info
        })
      }
    })
  }

  @computed
  private get newPrescriptionProps(): TNewPrescriptionProps | undefined {
    const bim = this.selectedBimId
    if (!bim) return
    if (!this.issuedBySelectorViewModel.value) return

    return {
      remarks: this.selectedRemarksSet.array,
      receiver: this.receiverInputViewModel.value,
      notes: this.notesInputViewModel.value,
      bim: bim,
      specialConditions: this.specialConditionsInputViewModel.value,
      targetText: this.customTargetInputViewModel?.value || "",
      // recipientText: this.customReceiverCompanyInputViewModel?.value,
      issuedBy: this.issuedBySelectorViewModel.value,
      deadlineAt: this.createdAtViewModel.value,
      attributes: {
        documentWorkDescription: this.workDescriptionInputViewModel.value,
        documentLevelMark: this.levelMarkSelectorViewModel.value || "",
        customCode: this.customCodeInputViewModel.value || ""
      },
      attachments: this.uploadButtonViewModel.files.array
    }
  }

  @computed
  private get newPrescription() {
    if (!this.newPrescriptionProps) return

    return new Prescription({
      id: 0,
      projectNumber: 0,
      owner: this.props.layers.repositories.authRepository.getUser()?.id || 0,
      createdAt: (new Date()).toISOString(),
      updatedAt: (new Date()).toISOString(),
      ...this.newPrescriptionProps
    })
  }

  @computed
  get prescriptionDocumentViewModel() {
    if (!this.newPrescription) return

    return new PrescriptionDocumentViewModel({
      ...this.props,
      prescription: this.newPrescription
    })
  }

  @computed
  get downloadButtonViewModel() {
    return {
      disabled: this.selectedRemarksSet.size === 0 || !this.prescriptionDocumentViewModel,
      onClick: async () => {
        if (!this.prescriptionDocumentViewModel) return

        this.statusWrapperViewModel.load()

        await this.prescriptionDocumentViewModel.download()

        this.statusWrapperViewModel.success()
      }
    }
  }

  // attachments
  @computed
  get uploadButtonViewModel() {
    return new UploadButtonViewModelV2({
      ...this.props,
      files: []
    })
  }

  @computed
  get uploadFilesListViewModel() {
    if (!this.showFilesDialogViewModel.open) return

    const viewModel = new UploadFilesListViewModelV2({
      ...this.props,
      files: this.uploadButtonViewModel.files,
      onClose: () => this.showFilesDialogViewModel.onClose()
    })

    return viewModel
  }

  @computed
  get emptyAttachments() {
    return this.uploadButtonViewModel.files.size === 0
  }

  @computed
  get showFilesDialogViewModel() {
    return new DialogViewModel({
      open: false,
      onOpen: this.emptyAttachments ? () => { } : () => this.showFilesDialogViewModel.open = true,
    })
  }

  @computed
  get showFilesViewModel() {
    return {
      label: `Посмотреть ${this.uploadButtonViewModel.files.size} ${numberFormat(this.uploadButtonViewModel.files.size, ["файл", "файла", "файлов"])}`,
      onClick: this.showFilesDialogViewModel.onOpen
    }
  }
}