import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { ISelectionFilterViewModel } from "../../view-model/SelectionFilterViewModel/interface"
// import PanelSelector from "../../components/Selectors/PanelSelector";
import SimpleButton from "../../components/Buttons/SimpleButton";
import { PanelSelector } from "../PanelSelector/PanelSelector";

const useClasses = makeStyles(theme => ({
  root: {
    display: "grid",
    gridTemplateAreas: `
      "header header"
      "modelSelector modelSelector"
      "sectionSelector sectionSelector"
      "workTypeSelector workTypeSelector"
      "typeSelector typeSelector"
      "statusSelector statusSelector"
      "saveButton closeButton"
    `
  },
  header: {
    gridArea: "header",
    fontSize: "22px",
    textAlign: "center"
  },
  modelSelector: {
    gridArea: "modelSelector",
    overflow: "hidden",
    marginTop: 10
  },
  typeSelector: {
    gridArea: "typeSelector",
    overflow: "hidden",
    marginTop: 10
  },
  statusSelector: {
    gridArea: "statusSelector",
    overflow: "hidden",
    marginTop: 10
  },
  sectionSelector: {
    gridArea: "sectionSelector",
    overflow: "hidden",
    marginTop: 10
  },
  workTypeSelector: {
    gridArea: "workTypeSelector",
    overflow: "hidden",
    marginTop: 10
  },
  saveButton: {
    marginTop: 20,
    gridArea: "saveButton",
    backgroundColor: theme.palette.primary.main,
  },
  closeButton: {
    marginTop: 20,
    marginLeft: 20,
    gridArea: "closeButton",
    backgroundColor: "#F5F5F5",
  },
  disabledButton: {
    opacity: 0.5
  }
}))

interface ISelectionFilterProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: ISelectionFilterViewModel
}

export const SelectionFilter = observer((props: ISelectionFilterProps) => {
  const classes = useClasses(props)

  useEffect(() => {
    props.viewModel.mount?.()

    return props.viewModel.unmount
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.header}>Область выделения</div>
      {
        props.viewModel.modelPanelSelectorViewModel ? (
          <PanelSelector
            className={classes.modelSelector}
            viewModel={props.viewModel.modelPanelSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.sectionPanelSelectorViewModel ? (
          <PanelSelector
            className={classes.sectionSelector}
            viewModel={props.viewModel.sectionPanelSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.typePanelSelectorViewModel ? (
          <PanelSelector
            className={classes.typeSelector}
            viewModel={props.viewModel.typePanelSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.statusPanelSelectorViewModel ? (
          <PanelSelector
            className={classes.statusSelector}
            viewModel={props.viewModel.statusPanelSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.workTypePanelSelectorViewModel ? (
          <PanelSelector
            className={classes.workTypeSelector}
            viewModel={props.viewModel.workTypePanelSelectorViewModel}
          />
        ) : null
      }
      <SimpleButton
        className={classes.saveButton}
        disabled={props.viewModel.disabledSaveButton}
        onClick={() => props.viewModel.onSave()}
      >Сохранить</SimpleButton>
      <SimpleButton
        className={classes.closeButton}
        onClick={() => props.viewModel.onClose()}
      >Закрыть</SimpleButton>
    </div>
  )
})