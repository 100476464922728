import { computed, makeObservable } from "mobx";
import { BaseModel } from "./BaseModel";
import { Entity } from "./Entity";
import { TProjectProps } from "./Project";

export enum ECompanyScope {
  monolith = "monolith",
  geoworks = "geoworks",
  none = "none",
  control = "control",
  controlContract = "control_contract"
}

function getCompanyScopeName(scope: ECompanyScope): string {
  switch (scope) {
    case ECompanyScope.geoworks:
      return "Геодезические работы"
    case ECompanyScope.monolith:
      return "Монолитные работы"
    case ECompanyScope.control:
      return "Строительный контроль"
    case ECompanyScope.controlContract:
      return "Строительный контроль_Подряд"
    default:
      return "Другие"
  }
  switch (scope) {
    case ECompanyScope.geoworks:
      return "Геодезические работы"
    case ECompanyScope.monolith:
      return "Монолитные работы"
    case ECompanyScope.control:
      return "Строительный контроль"
    case ECompanyScope.controlContract:
      return "Строительный контроль_Подряд"
    default:
      return "Другие"
  }
}

export type TPrescriptionType = 1 | 2

export type TCompanyProps = BaseModel & {
  name: string
  scope: ECompanyScope
  isInternal: boolean
  projects: TProjectProps["id"][]
  prescriptionType: TPrescriptionType
}

export class Company extends Entity<TCompanyProps> {
  constructor(props: TCompanyProps) {
    super(props)

    makeObservable(this)
  }

  @computed
  get isContractor() {
    return this.props.scope === ECompanyScope.geoworks || this.props.scope === ECompanyScope.monolith
  }

  hasProject(id: TProjectProps["id"]) {
    return this.props.projects.includes(id)
  }

  get name() {
    return this.props.name
  }

  get scope() {
    return this.props.scope
  }

  get isInternal() {
    return this.props.isInternal
  }

  get prescriptionType() {
    return this.props.prescriptionType
  }

  @computed
  get isControlCompany() {
    return this.scope === ECompanyScope.control || this.scope === ECompanyScope.controlContract
  }

  @computed
  get scopeName() {
    return getCompanyScopeName(this.props.scope)
  }
}