import React from "react"
import { Fab, makeStyles, IconButton } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IUploadFileViewModel } from "../../view-model/UploadFileViewModel/interface"
import { Add } from "@material-ui/icons"

const useClasses = makeStyles(theme => ({
  root: {},
}))

interface IUploadFileProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IUploadFileViewModel
  children: React.ReactChild
}

export const UploadFile = observer((props: IUploadFileProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root} onClick={props.viewModel.onClick}>
      {props.children}
    </div>
  )
})

const useClassesPlusButton = makeStyles(theme => ({
  iconAdd: {
    fontSize: 18,
    fill: "#FEC60F"
  },
  root: {
    width: 40,
    height: 40,
    background: "#FFFFFF",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.05)",
    "&:hover": {
      background: "#FFFFFF",
      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.05)",
    },
  },
}))

export const PlusButton = () => {
  const classes = useClassesPlusButton()

  return (
    <Fab
      className={classes.root}
      component="span"
      disableTouchRipple
    >
      <Add className={classes.iconAdd} />
    </Fab>
  )
}

const useClassesRefreshButton = makeStyles(theme => ({
  root: {
    borderRadius: 8,
    padding: "5px",
    background: `#f5f5f5 !important`,
    '&:hover': {
      backgroundColor: `#FEC60F !important`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: "10px"
    },

    '& svg': {
      fill: "rgba(0, 0, 0, 0.5)",
    },

    '&:hover svg': {
      fill: "#000"
    }
  },
}))

export const RefreshButton = () => {
  const classes = useClassesRefreshButton()

  return (
    <IconButton
      className={classes.root}
      component="span"
      disableTouchRipple
    >
      <RefreshIcon />
    </IconButton>
  )
}

const RefreshIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M12 3C7.03 3 3 7.03 3 12H0L3.89 15.89L3.96 16.03L8 12H5C5 8.13 8.13 5 12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19C10.07 19 8.32 18.21 7.06 16.94L5.64 18.36C7.27 19.99 9.51 21 12 21C16.97 21 21 16.97 21 12C21 7.03 16.97 3 12 3ZM11 8V13L15.28 15.54L16 14.33L12.5 12.25V8H11Z" />
  </svg>
)