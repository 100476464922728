import { makeStyles } from "@material-ui/core";
import React from "react";
import PanelInput from "../../components/Inputs/PanelInput";
import { observer } from "mobx-react-lite";
import { ICollectionFormViewModel } from "../../view-model/CollectionFormViewModel/interface";
import clsx from "clsx";
import { PanelSelector } from "../PanelSelector/PanelSelector";
import { SimpleButton } from "../SimpleButton/SimpleButton";
import { StatusWrapper } from "../StatusWrapper/StatusWrapper";

const useClasses = makeStyles(theme => ({
    root: {
        backgroundColor: "white",
        position: "relative"
    },
    header: {
        fontWeight: "bold",
        fontSize: "22px",
        textAlign: "center",
        marginBottom: 10,
        lineHeight: "54px"
    },
    buttonWrapper: {
        marginTop: 21,
        display: "grid",
        columnGap: 10,
        gridTemplateColumns: "repeat(auto-fit, minmax(0px, 1fr))"
    },
    button: {
        flex: 1,
    },
    deleteButton: {
        color: "white",
        backgroundColor: "#EE2E2E"
    },
    saveButton: {
        color: "white",
        backgroundColor: theme.palette.primary.main
    },
    loader: {
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "#0001",
    }
}))

const useClassesInput = makeStyles(() => ({
    root: {
        backgroundColor: "#F1F1F1",
        borderRadius: 8,
        marginBottom: 10,
    },
    label: {
        display: "none"
    },
    value: {
        "&:empty:before": {
            color: "#0005",
        },
        "&:focus": {
            borderBottom: "1px solid #0000",
        },
        maxWidth: "none",
        textAlign: "left"
    }
}))

const useClassesSelector = makeStyles(() => ({
    root: {
        backgroundColor: "#F1F1F1",
        borderRadius: 8,
        marginBottom: 10,
        overflow: "hidden",
    },
    label: {
        display: "none"
    },
    value: {
        color: "#000",
        maxWidth: "none",
        textAlign: "left"
    }
}))

// function getValueLabel({ values, options }) {
//     if (!values || !options) return "Выберите модели";

//     return `Выбрано моделей ${values.length} из ${options.length}`
// }

interface ICollectionFormProps {
    viewModel: ICollectionFormViewModel
    classes?: any
}

export const CollectionForm = observer((props: ICollectionFormProps) => {
    const classes = useClasses(props);
    const classesInput = useClassesInput();
    const classesSelector = useClassesSelector();

    return (
        <div className={classes.root}>
            <div className={classes.header}>{props.viewModel.header}</div>
            <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
                <>
                    <PanelInput
                        classes={classesInput}
                        placeholder="Название модели"
                        value={props.viewModel.namePanelInputViewModel.value}
                        onChange={props.viewModel.namePanelInputViewModel.onChange}
                    />
                    <PanelSelector
                        classes={classesSelector}
                        viewModel={props.viewModel.bimsPanelSelectorViewModel}
                    />
                    <PanelInput
                        classes={classesInput}
                        placeholder="Ссылка на СОД Sarex"
                        value={props.viewModel.linkPanelInputViewModel.value}
                        onChange={props.viewModel.linkPanelInputViewModel.onChange}
                    />
                    <div className={classes.buttonWrapper}>
                        {
                            props.viewModel.deleteButtonViewModel ?
                                <SimpleButton
                                    className={clsx(classes.button, classes.deleteButton)}
                                    viewModel={props.viewModel.deleteButtonViewModel}
                                >Удалить</SimpleButton>
                                : null
                        }
                        <SimpleButton
                            className={clsx(classes.button, classes.saveButton)}
                            viewModel={props.viewModel.saveButtonViewModel}
                        />
                    </div>
                </>
            </StatusWrapper>
        </div>
    )
})