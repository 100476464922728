import { IWorkTypesRepository } from "./interface";
import { computed } from "mobx";
import { IAuthRepository } from "../AuthRepository/interface";
import { EWorkTypeOrientation, TWorkTypeProps, WorkType } from "../../models/WorkType";
import { TWorkTypeGroupProps } from "../../models/WorkTypeGroup";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";

type TRestProps = {
  id: number
  model_work_type: number
  name: string
  orientation: EWorkTypeOrientation
  group: TWorkTypeGroupProps["id"] | null
}

export class RestWorkTypesRepository implements IWorkTypesRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.offlineRepository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: WorkType,
      serialyze: this.serialyze
    })
  }

  offlineRepository: RestOfflineReadOnlyRepository<WorkType, TRestProps>

  @computed
  get path() {
    return `${this.props.host}api/main/work-type/`
  }

  @computed
  get auth() {
    return this.props.authRepository
  }

  getAllAsync() {
    return this.offlineRepository.getAllAsync()
  }
  getByIdAsync(id) {
    return this.offlineRepository.getByIdAsync(id)
  }

  getById(id: TWorkTypeProps["id"]) {
    return this.offlineRepository.getOneByParams({ id }, {})
  }

  serialyze(props: TRestProps): WorkType["props"] {
    return {
      id: props.id,
      name: props.name,
      orientation: props.orientation,
      group: props.group ? props.group : undefined
    }
  }
}