import { computed, makeObservable } from "mobx";
import { ConstructionGroup, TConstructionGroupProps } from "../../models/ConstructionGroup";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { RestRepository } from "../Repository/RestRepositoryOld";
import { IConstructionGroupsRepository } from "./interface";

type TRestProps = {
  id: number
  name: string
  reg_exp: string | null
  work_type: number
}

export class RestConstructionGroupsRepository implements IConstructionGroupsRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.offlineRepository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: ConstructionGroup,
      serialyze: this.serialyze
    })
  }

  offlineRepository: RestOfflineReadOnlyRepository<ConstructionGroup, TRestProps>

  @computed
  get path() {
    return `${this.props.host}api/main/bim-element-construction-group/`
  }

  serialyze(restProp: TRestProps): TConstructionGroupProps {
    return {
      id: restProp.id,
      name: restProp.name,
      workType: restProp.work_type
    }
  }

  getAll() {
    return this.offlineRepository.getAll()
  }
  getAllAsync() {
    return this.offlineRepository.getAllAsync()
  }

  getAllByName(name: string) {
    return this.offlineRepository.getByParams({ name }, {})
  }

  async getAllByNameAsync(name: string) {
    return this.offlineRepository.getByParamsAsync({ name }, {})
  }
}