import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Bim } from "../../models/Bim";
import { EActions } from "../../usecase/Permissions/Permissions";
import { StoreyFormViewModel } from "../StoreyFormViewModel/StoreyFormViewModel";
import { ILevelsFilterViewModel } from "./interface";

export class LevelsFilterViewModel implements ILevelsFilterViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  onBack() {
    this.props.layers.usecases.viewState.setPanelOpened(false)
  }

  @computed
  get dialogViewModel() {
    return {
      open: this.editableStorey !== undefined,
      onClose: () => this.editableStorey = undefined
    }
  }

  @computed
  get storeyFormViewModel() {
    if (!this.editableStorey) return
    return new StoreyFormViewModel({ ...this.props, onClose: () => this.dialogViewModel.onClose(), storey: this.editableStorey })
  }

  @observable
  editableStorey: { name: string, bim: Set<Bim> } | undefined = undefined

  @computed
  get storeys() {
    return [
      {
        key: "common view",
        name: "Общий вид",
        onClick: () => this.props.layers.usecases.selectedStoreys.resetSelectedStoreys(),
        selected: this.props.layers.usecases.selectedStoreys.selectedStoreysSize === 0
      },
      {
        key: "noname",
        name: "Не указан",
        onClick: () => this.props.layers.usecases.selectedStoreys.switchSelectedStorey(""),
        selected: this.props.layers.usecases.selectedStoreys.getStoreySelected("")
      },
      ...this.props.layers.usecases.selectedStoreys.storeys.map(storey => {
        const marks = this.props.layers.usecases.storeyNotes.getLevelMarksByStorey(storey)

        return {
          key: storey.id,
          name: marks.length > 0 ? `Этаж ${storey.name} (${marks.join(", ")})` : `Этаж ${storey.name}`,
          onClick: () => this.props.layers.usecases.selectedStoreys.switchSelectedStorey(storey.id),
          onEdit: this.props.layers.usecases.permissions.actionIsAllowed(EActions.editStoreyMark) ? () => this.editableStorey = storey : undefined,
          selected: this.props.layers.usecases.selectedStoreys.getStoreySelected(storey.id)
        }
      })
    ]
  }
}