import React from 'react';

import {
    makeStyles,
    withStyles,
    Card,
    CardContent,
    Typography,
    Button,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { IProjectCardViewModel, IProjectsListViewModel } from '../../view-model/ProjectsListViewModel/interface';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // paddingLeft: 20,
        boxSizing: 'border-box',
        // display: "flex",
        // flexWrap: 'wrap',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 312px)',
        gridRowGap: 20,
        gridColumnGap: 24,
        padding: "20px 0",
    },
    container: {
        maxWidth: 984,
        width: '100%',
        margin: '0 auto',
    },
    card: {
        display: 'flex',
        // width: "26.7%",
        padding: '19px 20px',
        borderRadius: 14,
        boxShadow: "none",
        // marginRight: 20,
        // marginTop: 20
    },
    cardContent: {
        padding: 0
    },
    details: {
        width: "59.5%",
        marginRight: 20,
    },
    name: {
        width: "100%",
        fontWeight: 'bold',
        fontSize: 14,
        //@ts-ignore
        color: theme.palette.primaryRedesignColor3,
        marginTop: 5,
    },
    info: {
        width: '100%',
        display: 'flex',
        justifyContent: "space-between",
        marginTop: 26,
    },
    text: {
        fontWeight: "normal",
        fontSize: 14,
        //@ts-ignore
        color: theme.palette.primaryRedesignColor3,
    },
    button: {
        width: "100%",
        marginTop: 18,
        fontWeight: "bold",
        height: 44,
        fontSize: 14,
        color: "#000",
        textTransform: 'none',
        border: `1px solid #FEC60F`,
        "&:hover": {
            borderColor: "#FEC60F",
            backgroundColor: "#FEC60F",
            boxShadow: "none",
        },
    },
    buttonModile: {
        height: 44
    },
    media: {
        width: 93,
        background: "#F5F5F4",
        borderRadius: 10
    },
    mediaDesktop: {
        width: 143.
    },
    actions: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    backgroundImage: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    }
}));

const useStylesDesktop = makeStyles(theme => ({
    root: {
        gridColumnGap: 20,
        gridTemplateColumns: 'repeat(auto-fill, 406px)',
    },
    container: {
        maxWidth: 1284
    },
    text: {
        fontSize: 12
    },
    button: {
        fontSize: 12,
    },
}));

export const CustomButton = withStyles(theme => ({
    root: {
        borderRadius: 10,
    },
    contained: {
        backgroundColor: "#FEC60F !important",
        color: "#000",
        boxShadow: "none",
    },
    disabled: {
        color: "#000",
        boxShadow: "none",
        opacity: 0.3
    }
}))(Button)

interface IProjectsLisProps {
    viewModel: IProjectsListViewModel
    mobile: boolean
}

export const ProjectsList = observer((props: IProjectsLisProps) => {
    const {
        mobile
    } = props;

    const classesDesktop = useStylesDesktop();
    const classes = useStyles(mobile ? undefined : { classes: classesDesktop });

    return (
        <div className={clsx(classes.root, classes.container)}>
            {
                props.viewModel.projects.map(project => <ProjectCard key={project.key} viewModel={project} classes={classes} />)
            }
        </div>
    )
})

interface ICardProps {
    classes: ReturnType<typeof useStyles>
    viewModel: IProjectCardViewModel
}

const ProjectCard = observer((props: ICardProps) => {
    const project = props.viewModel
    const classes = props.classes

    return (
        <Card className={classes.card}>
            <div className={classes.details}>
                <CardContent className={classes.cardContent}>
                    <Typography className={classes.name} gutterBottom variant="h5" component="h5">
                        {project.name}
                    </Typography>
                    {
                        <div className={classes.info}>
                            <Typography className={classes.text}>
                                {project.sections.name}
                            </Typography>
                            <Typography className={classes.text}>
                                {project.sections.count}
                            </Typography>
                        </div>
                    }
                    {
                        <div className={classes.info}>
                            <Typography className={classes.text}>
                                {project.files.name}
                            </Typography>
                            <Typography className={classes.text}>
                                {project.files.count}
                            </Typography>
                        </div>
                    }
                </CardContent>
                <div className={classes.actions}>
                    <CustomButton
                        className={classes.button}
                        variant='contained'
                        disableTouchRipple
                        onClick={() => project.onOpen()}
                    >
                        Открыть
                    </CustomButton>
                    {
                        project.allSectionsButton ? (
                            <CustomButton
                                className={classes.button}
                                variant='contained'
                                disableTouchRipple
                                disabled={project.allSectionsButton.disabled}
                                onClick={project.allSectionsButton.onClick}
                            >
                                Сводная модель
                            </CustomButton>
                        ) : null
                    }

                </div>
            </div>
            <div className={clsx(classes.media, {
                [classes.backgroundImage]: Boolean(project.image)
            })} style={project.image ? { backgroundImage: `url(${project.image})` } : {}}>
            </div>
        </Card>
    )
})