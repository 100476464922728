import { action, makeObservable, observable } from "mobx"
import { ILayers } from "../../core/Layers/interface"
import { UploadFile } from "../../models/UploadFile"
import { IUploadButtonViewModel } from "./interface"

export class UploadButtonViewModel implements IUploadButtonViewModel {
  constructor(private props: { layers: ILayers, onAdd(files: UploadFile[]): void }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  loading = false

  @action
  async onChange(files: FileList | null) {
    this.loading = true
    const user = this.props.layers.repositories.authRepository.getUser()
    const uploadFiles: UploadFile[] = []

    if (user && files) {
      //@ts-ignore
      for (const file of files) {
        const result = await this.readFile(file)

        if (result) {
          const uploadFile = await this.props.layers.repositories.uploadFilesRepository.add({
            user: user.id,
            name: file.name,
            file: result
          })

          uploadFiles.push(uploadFile)
        }
      }
    }

    this.props.onAdd(uploadFiles)
    this.loading = false
  }

  private async readFile(file: File) {
    return new Promise<string>((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(String(fr.result))
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }
}