import React from "react"
import { Dialog, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IStatisticsPageViewModel } from "../../view-model/StatisticsPageViewModel/interface"
import SimpleButton from "../../components/Buttons/SimpleButton";
import BarChart from "../../components/Charts/BarChart";
import { StatisticsTable } from "../StatisticsTable/StatisticsTable";
import { PanelSelector } from "../PanelSelector/PanelSelector";
import { SummaryStatistic } from "../SummaryStatistic/SummaryStatistic";

const useClasses = makeStyles(theme => ({
  root: {
    padding: "100px 0 56px 0",
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    margin: "10px 26px 0 26px",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    maxWidth: 308,
    margin: "auto",

    "&:first-child": {
      marginBottom: 10
    }
  },
  selectorPadding: {
    padding: "0 26px"
  },

  barChartsContainer: {
    userSelect: "none",
    margin: "30px 26px 46px 26px",
    backgroundColor: "#F5F5F5",
    minHeight: 120,
    height: 120,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    padding: "0 20px",

    "& > *:not(:last-child)": {
      marginRight: 20
    }
  },
  barLegends: {

  },
  barLegend: {
    textAlign: "center",
    fontSize: "10px",
    fontWeight: "bold",
    lineHeight: "20px"
  },

  table: {
    marginTop: 20
  },
  tableRow: {
    margin: "0 26px",
  },
  tableContainer: {
    padding: "0 26px",
    overflow: "auto"
  }
}))

const useClassesSelector = makeStyles(theme => ({
  padding: {
    padding: "0 26px"
  },
  value: {
    maxWidth: 200
  }
}))

const useClassesSummaryStatistics = makeStyles(() => ({
  root: {
    padding: "20px 40px 40px 40px"
  }
}))

interface IStatisticsPageProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IStatisticsPageViewModel
}

export const StatisticsPage = observer((props: IStatisticsPageProps) => {
  const classes = useClasses(props)
  const classesSelector = useClassesSelector()
  const classesSummaryStatistics = useClassesSummaryStatistics()

  return (
    <div className={classes.root}>
      {
        props.viewModel.targetsSelectorViewModel ? (
          <PanelSelector
            classes={classesSelector}
            viewModel={props.viewModel.targetsSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.storeysSelectorViewModel ? (
          <PanelSelector
            classes={classesSelector}
            viewModel={props.viewModel.storeysSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.sectionsSelectorViewModel ? (
          <PanelSelector
            classes={classesSelector}
            viewModel={props.viewModel.sectionsSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.workTypeSelectorViewModel ? (
          <PanelSelector
            classes={classesSelector}
            viewModel={props.viewModel.workTypeSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.workStatusSelectorViewModel ? (
          <PanelSelector
            classes={classesSelector}
            viewModel={props.viewModel.workStatusSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.unitSelectorViewModel ? (
          <PanelSelector
            classes={classesSelector}
            viewModel={props.viewModel.unitSelectorViewModel}
          />
        ) : null
      }
      {
        props.viewModel.unitRepresentationSelectorViewModel ? (
          <PanelSelector
            classes={classesSelector}
            viewModel={props.viewModel.unitRepresentationSelectorViewModel}
          />
        ) : null
      }
      <div className={classes.buttonContainer}>
        {
          props.viewModel.summaryStatisticsButtonViewModel ? (
            <SimpleButton className={classes.button} onClick={props.viewModel.summaryStatisticsButtonViewModel.onClick}>
              {props.viewModel.summaryStatisticsButtonViewModel.label}
            </SimpleButton>
          ) : null
        }
        {
          props.viewModel.summaryStatisticsForSectionsButtonViewModel ? (
            <SimpleButton className={classes.button} onClick={props.viewModel.summaryStatisticsForSectionsButtonViewModel.onClick}>
              {props.viewModel.summaryStatisticsForSectionsButtonViewModel.label}
            </SimpleButton>
          ) : null
        }

      </div>
      {
        props.viewModel.barChartViewModels ?
          <div className={classes.barChartsContainer}>
            {
              props.viewModel.barChartViewModels.map(({ key, viewModel }) => (
                <div key={key}>
                  <BarChart data={viewModel.data} totalValue={viewModel.value} height={120} width={60 * viewModel.data.length} />
                  <div className={classes.barLegend}>{viewModel.label}</div>
                </div>
              ))
            }
          </div>
          : null
      }

      {
        props.viewModel.statisticsTableViewModel ? (
          <div className={classes.tableContainer}>
            <StatisticsTable
              viewModel={props.viewModel.statisticsTableViewModel}
            />
          </div>
        ) : null
      }

      <Dialog maxWidth={false} fullWidth open={props.viewModel.summaryStatisticDialogViewModel.open} onClose={props.viewModel.summaryStatisticDialogViewModel.onClose}>
        {
          props.viewModel.summaryStatisticViewModel ?
            <SummaryStatistic viewModel={props.viewModel.summaryStatisticViewModel} classes={classesSummaryStatistics} />
            : null
        }
      </Dialog>
    </div>
  )
})