import { makeStyles } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useRef } from "react";

const useClasses = makeStyles(theme => ({
    root: {
        padding: 10,
        overflow: "auto",
        lineHeight: "22px",
        height: 80,
        borderRadius: 8,
        backgroundColor: "#F5F5F5",
        fontSize: "12px",
        border: "1px solid #0000",

        "&:empty:before": {
            content: "attr(placeholder)",
            color: "#0003"
        },

        "&:focus": {
            border: "1px solid #AAAAA9",
            outline: "none"
        },
    }
}))

function TextArea(props) {
    const classes = useClasses(props);
    const inputRef = useRef();
    const {
        placeholder,
        value,

        onChange
    } = props;

    useEffect(() => {
        if (inputRef.current && value !== inputRef.current.innerText) {
            inputRef.current.innerText = value ? value : ""
        }
    }, [value]);

    const onInputHandler = useCallback(onChange ? e => {
        onChange(e.target.innerText)
    } : null, [onChange]);
    
    return (
        <div
            className={classes.root}
            ref={inputRef}
            contentEditable
            placeholder={placeholder}
            // title={value}
            // onFocus={onFocus}
            // onKeyDown={onKeyDown}
            onInput={onInputHandler}
        />
    )
}

export default memo(TextArea);