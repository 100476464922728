import { makeObservable, observable } from "mobx";
import { computedFn } from "mobx-utils";
import { Bim } from "../../models/Bim";
import { ECamera } from "../../models/Camera";
import { Collection } from "../../models/Collection";
import { IMeasurement } from "../../models/Measurement/interface";
import { IModelElement } from "../../models/ModelElement/interface";
import { LocalStorageJSONRepository } from "../../repositories/SimpleDataRepository/LocalStorageJSONRepository";

export class UI {
  constructor() {
    makeObservable(this)
  }

  getViewerCanvas?: () => Promise<HTMLCanvasElement | undefined> = undefined

  propertiesPanelFloatingWindowDataRepository = new LocalStorageJSONRepository<{
    position: { x: number, y: number },
    size: { width: number, height: number },
    hidedProperties: { [key: string]: boolean },
    opened: boolean
  }>({
    key: "propertiesPanelFloatingWindowData",
    defaultData: {
      position: {
        x: 10,
        y: 10
      },
      size: {
        width: 300,
        height: 300
      },
      hidedProperties: {},
      opened: false
    }
  })

  viewerDataRepository = new LocalStorageJSONRepository<{
    camera: ECamera,
    paintElementsInStatusColor: boolean
  }>({
    key: "viewerData",
    defaultData: {
      paintElementsInStatusColor: false,
      camera: ECamera.default,
    }
  })

  @observable
  temporaryViewerDataRepository: {
    contextMenu?: {
      x: number
      y: number
    },
  } = {}

  private getKeyForModelDataRepository(props: { bims: Bim["id"][] } | { collection: Collection["id"] }) {
    if ("bims" in props) return `model data for bims ${props.bims.join(",")}`
    return `model data for collection ${props.collection}`
  }

  getModelDataRepository = computedFn((props: { bims: Bim["id"][] } | { collection: Collection["id"] }) => {
    const key = this.getKeyForModelDataRepository(props)

    return new LocalStorageJSONRepository<{
      selectedStoreys: string[]
      cameraData: any
      hideNodes?: {
        [key: string]: IModelElement["id"][]
        [key: number]: IModelElement["id"][]
      }
    }>({
      key,
      defaultData: {
        selectedStoreys: [],
        cameraData: undefined
      }
    })
  })

  interfaceDataRepository = new LocalStorageJSONRepository<{
    shortBimPanel: boolean,
  }>({
    key: "interfaceData",
    defaultData: {
      shortBimPanel: false,
    }
  })

  measurementsDataRepository = new LocalStorageJSONRepository<{
    position: { x: number, y: number },
    size: { width: number, height: number },
    toolActive: boolean,
    measurements: {
      [key: string]: IMeasurement
    }
  }>({
    key: "measurementsData",
    defaultData: {
      position: {
        x: 10,
        y: 10
      },
      size: {
        width: 300,
        height: 300
      },
      toolActive: false,
      measurements: {}
    },
  })

  @observable
  temporaryMeasurementsDataRepository = {
    addMode: false
  }
}