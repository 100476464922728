import { computed, makeObservable } from "mobx";
import { v4 } from "uuid";
import { BaseModel } from "./BaseModel";
import { TBimProps } from "./Bim";
import { TBimElementProps } from "./BimElement";
import { TCompanyProps } from "./Company";
import { Entity } from "./Entity";
import { TRemarkBaseProps } from "./RemarkBase";
import { TUploadFileProps } from "./UploadFile";
import { TUserProps } from "./User";

export enum ERemarkStatus {
  closed = "closed",
  opened = "open",
  pending = "pending"
}

export enum EDeadlineStatus {
  completed = "completed",
  expired = "expired",
  processing = "processing",
}

export enum ERemarkStatusColor {
  closed = "#48A410",
  open = "#EE2E2E",
  pending = "#FEC60F"
}

export type TRemarkProps = BaseModel & {
  bim: TBimProps["id"]
  elements: TBimElementProps["id"][]
  owner: TUserProps["id"]
  remarkBase: TRemarkBaseProps["id"]
  supervisor: TUserProps["id"]
  body: string
  contractor: TCompanyProps["id"]
  location: string
  deadline: string
  createdAt: string
  updatedAt: string
  status: ERemarkStatus
  title: string
  attachments: TUploadFileProps["id"][]
  projectNumber: number
  normativeDocument: string
  isCritical: boolean
}

export type TNewRemarkProps = {
  bim: TBimProps["id"]
  elements: TBimElementProps["id"][]
  remarkBase: TRemarkBaseProps["id"]
  supervisor: TUserProps["id"]
  body: string
  contractor: TCompanyProps["id"]
  location: string
  deadline: string
  status: ERemarkStatus
  title: string
  attachments: any[]
  normativeDocument: string
  isCritical: boolean
}

export class Remark extends Entity<TRemarkProps> {
  constructor(props: TRemarkProps) {
    super(props)

    makeObservable(this)
  }

  get bim() {
    return this.props.bim
  }

  @computed
  get deadline() {
    return new Date(this.props.deadline)
  }

  @computed
  get createdAt() {
    return new Date(this.props.createdAt)
  }

  get owner() {
    return this.props.owner
  }

  get projectNumber() {
    return this.props.projectNumber
  }

  get remarkBase() {
    return this.props.remarkBase
  }

  get contractor() {
    return this.props.contractor
  }

  get location() {
    return this.props.location
  }

  get body() {
    return this.props.body
  }

  get status() {
    return this.props.status
  }

  @computed
  get expired(): boolean {
    if (this.status === ERemarkStatus.closed) return false

    return ((new Date()).getTime() - this.deadline.getTime()) > 0
  }

  @computed
  get closed(): boolean {
    return this.status === ERemarkStatus.closed
  }

  get elements() {
    return this.props.elements
  }

  get supervisor() {
    return this.props.supervisor
  }

  @computed
  get statusColor() {
    return ERemarkStatusColor[this.props.status]
  }

  get attachments() {
    return this.props.attachments
  }

  get normativeDocument() {
    return this.props.normativeDocument
  }

  get isCritical() {
    return this.props.isCritical
  }
}

export class NewRemark extends Remark {
  constructor(props: TNewRemarkProps & Partial<BaseModel>) {
    super({
      id: props.id || v4(),
      ...props,
      owner: 0,
      createdAt: (new Date()).toISOString(),
      updatedAt: (new Date()).toISOString(),
      projectNumber: 0
    })
  }
}