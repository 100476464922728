import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { ISimpleButtonViewModel } from "../../view-model/SimpleButtonViewModel/interface"
import clsx from "clsx"

const useClasses = makeStyles(theme => ({
  root: {
    height: 44,
    textAlign: "center",
    boxSizing: "border-box",

    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "44px",

    borderRadius: 8,

    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "0 10px",
    userSelect: "none",

    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  disabled: {
    backgroundColor: "#ddd !important",
    cursor: "initial",
    opacity: 0.5,
  }
}))

interface ISimpleButtonProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: ISimpleButtonViewModel
  className?: string
  children?: any
}

export const SimpleButton = observer((props: ISimpleButtonProps) => {
  const classes = useClasses(props)

  return (
    <div
      className={clsx(classes.root, props.className, {
        [classes.disabled]: props.viewModel.disabled
      })}
      onClick={props.viewModel.disabled ? undefined : props.viewModel.onClick}
    >
      {props.viewModel.label || props.children}
    </div>
  );
})