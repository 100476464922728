import React from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';

import { observer } from 'mobx-react-lite'
import { IHomePageViewModel } from '../../view-model/HomePageViewModel/interface';
import { ProjectsList } from '../ProjectsList/ProjectsList';
import { HomeHeader } from '../HomeHeader/HomeHeader';
import { TargetsPage } from '../TargetsPage/TargetsPage';
import FilterPanel from "../FilterPanel/FilterPanel";

const headerHeight = 60;

const useClasses = makeStyles(theme => ({
    root: {
        height: '100%',
        width: "100%",
        //@ts-ignore
        backgroundColor: theme.palette.primaryRedesignColor7,
    },
    content: {
        boxSizing: 'border-box',
        height: `calc(100% - ${headerHeight}px)`,
        maxHeight: `calc(100% - ${headerHeight}px)`,
        overflow: "hidden",
        overflowY: 'auto',
        padding: "0 91px",
    },
    header: {
        height: headerHeight
    },
}));

const useStylesMobile = makeStyles(() => ({
    content: {
        padding: "0 20px",
    }
}))

interface IHomePageProps {
    viewModel: IHomePageViewModel
    classes?: Partial<ReturnType<typeof useClasses>>
}

export const HomePage = observer((props: IHomePageProps) => {
    const mobile = props.viewModel.isMobile || useMediaQuery("(max-width: 1345px)", {
        defaultMatches: window.matchMedia("(max-width: 1345px)").matches
    })

    const classesMobile = useStylesMobile();
    const classes = useClasses(mobile ? { classes: classesMobile } : undefined);

    const selectedProject = props.viewModel.selectedProject

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <HomeHeader
                    visibleFinder={Boolean(selectedProject || mobile)}
                    mobile={mobile}
                    visibleModeLists={Boolean(mobile && selectedProject)}

                    viewModel={props.viewModel.homeHeaderViewModel}
                />
            </div>
            <div className={classes.content}>
                {
                    selectedProject ? (
                        <>
                            <FilterPanel
                                viewModel={props.viewModel.filterPanelViewModel}
                            />
                            <TargetsPage
                                mobile={mobile}

                                viewModel={props.viewModel.targetsPageViewModel}
                            />
                        </>
                    ) : (
                        <ProjectsList
                            mobile={mobile}
                            viewModel={props.viewModel.projectsListViewModel}
                        />
                    )
                }
            </div>
        </div>
    )
})