import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { EPrescriptionsPageContent, IPrescriptionsPageViewModel } from "../../view-model/PrescriptionsPageViewModel/interface"
import BackButton from '../../components/Buttons/BackButton';
import SimpleButton from '../../components/Buttons/SimpleButton';
import { Add, Visibility } from "@material-ui/icons";
import { PrescriptionsList } from "../PrescriptionsList/PrescriptionsList";
import { PrescriptionForm } from "../PrescriptionForm/PrescriptionForm";
import clsx from "clsx";

const useClasses = makeStyles(theme => ({
  root: {
    padding: "100px 0 56px 0",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxSizing: "border-box",
  },
  header: {
    margin: "0px 30px 30px 26px",
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    width: 44,
    height: 44,
    flex: "none",
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 14,
    "& svg": {
      fill: "white",
    }
  },
  showButton: {
    "& svg": {
      fill: "#0004",
    },
    backgroundColor: "inherit"
  },
  showButtonActive: {
    "& svg": {
      fill: theme.palette.primary.main
    }
  }
}))

interface IPrescriptionsPageProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPrescriptionsPageViewModel
}

export const PrescriptionsPage = observer((props: IPrescriptionsPageProps) => {
  const classes = useClasses(props)

  const content = useMemo(() => {
    switch (props.viewModel.content) {
      case EPrescriptionsPageContent.form:
        return <PrescriptionForm viewModel={props.viewModel.prescriptionFormViewModel} />
      default:
        return <PrescriptionsList viewModel={props.viewModel.prescriptionsListViewModel} />
    }
  }, [props.viewModel.content])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <BackButton onClick={() => props.viewModel.onBack()} />
        {
          props.viewModel.addButtonViewModel ? (
            <SimpleButton className={classes.button} {...props.viewModel.addButtonViewModel}>
              <Add />
            </SimpleButton>
          ) : null
        }
        {
          props.viewModel.showButtonViewModel ? (
            <SimpleButton
              className={clsx(classes.button, classes.showButton, {
                [classes.showButtonActive]: props.viewModel.showButtonViewModel.active
              })}
              {...props.viewModel.showButtonViewModel}
            >
              <Visibility />
            </SimpleButton>
          ) : null
        }
      </div>
      {content}
    </div>
  )
})