import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { ModelWorkType } from "../../models/ModelWorkType";
import { TTargetProps } from "../../models/Target";
import { EVariants } from "../../usecase/Notifications/Notifications";
import { EActions } from "../../usecase/Permissions/Permissions";
import { BimTableViewModel } from "../BimTableViewModel/BimTableViewModel";
import { UploadFileViewModel } from "../UploadFileViewModel/UploadFileViewModel";
import { IBimTableWithModelWorkTypeViewModel } from "./interface";

export class BimTableWithModelWorkTypeViewModel implements IBimTableWithModelWorkTypeViewModel {
  constructor(private props: { layers: ILayers, targetId: TTargetProps["id"] }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get bimTableViewModels() {
    return this.props.layers.repositories.modelWorkTypeRepository.getAll().map(modelWorkType => ({
      key: `${this.props.targetId}/${modelWorkType.id}`,
      viewModel: new BimTableViewModel({
        ...this.props,
        modelWorkType
      }),
      header: `${modelWorkType.name}`,
      uploadFileViewModel: this.props.layers.usecases.permissions.actionIsAllowed(EActions.createAndEditBims) ? new UploadFileViewModel({
        accept: this.props.layers.repositories.bimsRepository.accept,
        onLoad: (file: File) => {
          this.onAdd({ file, modelWorkType })
        }
      }) : undefined
    }))
  }

  onAdd = async (props: { file: File, modelWorkType: ModelWorkType }) => {
    const notificationKey = await this.props.layers.usecases.notifications.wait(`загрузка файла ${props.file.name}`)

    try {
      await this.props.layers.repositories.bimsRepository.add({
        target: this.props.targetId,
        file: props.file,
        modelWorkType: props.modelWorkType.id
      })

      this.props.layers.usecases.notifications.notify("загрузка завершена", {
        variant: EVariants.success
      })
    } catch (error) {
      this.props.layers.usecases.notifications.wait("ошибка", {
        variant: EVariants.error
      })
    }
    this.props.layers.usecases.notifications.close(notificationKey)
  }
}