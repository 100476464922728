import { makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Collection } from "../../models/Collection";
import { ERemarkStatus } from "../../models/Remark";
import { IPieChartViewModel } from "../PieChartViewModel/interface";
import { IPieChartWithLegendViewModel } from "./interface";

export class PieChartWithWorkStatusRemarksOnProjectForForgeElementsAsync implements IPieChartWithLegendViewModel {
  constructor(private props: {
    layers: ILayers
    collections: Collection[]
    header?: string
    date: Date
  }) {
    this.props = props

    makeObservable(this)
  }

  get header() {
    return this.props.header || ""
  }

  @observable
  pieChartViewModel: IPieChartViewModel = {
    data: []
  }

  @observable
  legends: IPieChartWithLegendViewModel["legends"] = []

  formatValue(value: number, totalValue: number) {
    if (totalValue === 0) return "?"

    return `${(value / totalValue * 100).toFixed(0)}%`
  }

  getRandomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`
  }

  async compute() {
    this.legends = []
    this.pieChartViewModel.data = []

    let data: [number | "uncertain" | "other", number][] = await this.props.layers.repositories.statsRepository.getRemarkCountForCollectionsGroupByElementStatusAsync({
      collections: this.props.collections.map(c => c.id),
      lte: this.props.date,
      status: [ERemarkStatus.opened, ERemarkStatus.pending]
    })

    data = data.filter(([a, b]) => b > 0)

    data.sort((a, b) => b[1] - a[1])


    if (data.length > 5) {
      const otherData = data.splice(5)
      data = [
        ...data.splice(0, 5),
        ["other", otherData.reduce((acc, row) => acc + row[1], 0)]
      ]
    }

    const totalCount = data.reduce((acc, [_, count]) => acc + count, 0)

    for (const [statusDictionaryId, count] of data) {
      if (count > 0) {
        const statusDictionary = statusDictionaryId === "uncertain" ? {
          name: "Работы не начаты"
        } : statusDictionaryId === "other" ? {
          name: "Другое"
        } : await this.props.layers.repositories.statusDictionaryRepository.getByIdAsync(statusDictionaryId)

        const color = this.getRandomColor()

        this.legends.push({
          key: String(statusDictionaryId),
          color: color,
          valueLabel: this.formatValue(count, totalCount),
          label: statusDictionary?.name || "?"
        })

        this.pieChartViewModel.data.push({
          color: color,
          value: count,
        })
      }
    }
  }
}