import { action, computed, makeObservable, observable, ObservableSet } from "mobx"
import { SetViewModel } from "../SimpleViewModel/SetViewModel"
import { IPanelSelectorViewModel } from "./interface"
import { PanelSelectorCommonBehaviorViewModel, TOptionProps, TPanelSelectorCommonBehaviorViewModelProps } from "./PanelSelectorCommonBehaviorViewModel"

export type TPanelSelectorMultiViewModelProps<T> = {
  onChange?(): void
} & ({
  defaultValue: T[]
} | {
  setViewModel: SetViewModel<T>
} | {
  defaultAllSelected: boolean
}) & TPanelSelectorCommonBehaviorViewModelProps<T>
export class PanelSelectorMultiViewModel<T> extends PanelSelectorCommonBehaviorViewModel<T> implements IPanelSelectorViewModel {
  constructor(private props: TPanelSelectorMultiViewModelProps<T>) {
    super(props)

    this.props = props


    this.setViewModel = "setViewModel" in props ? props.setViewModel : new SetViewModel()

    makeObservable(this)

    this.init()
  }

  setViewModel: SetViewModel<T>

  @action
  private init() {
    if ("defaultValue" in this.props) {
      this.setViewModel.replace(this.props.defaultValue)
    } else if ("defaultAllSelected" in this.props) {
      this.setViewModel.replace(this.allOptions.map(o => o.value))
    }
  }

  @computed
  public get values() {
    return this.setViewModel.array
  }

  @computed
  get notSelected() {
    return this.setViewModel.size === 0
  }

  @computed
  get valueLabel() {
    if (this.setViewModel.size === 0) return "не выбрано"

    return this.setViewModel.array.map(value => this.optionsMap.get(value)?.label || "значение отсутствует").join(", ")
  }

  @action
  selectOptions(options: TOptionProps<T>[]) {
    this.setViewModel.addBatch(options.filter(option => !option.disabled).map(option => option.value))

    this.props.onChange?.()
  }

  @action
  deselectOptions(options: TOptionProps<T>[]) {
    this.setViewModel.deleteBatch(options.map(option => option.value))

    this.props.onChange?.()
  }

  getOptionSelected(option: TOptionProps<T>) {
    return this.setViewModel.has(option.value)
  }

  @action
  switchOption(option: TOptionProps<T>) {
    if (this.props.disabled) return
    if (option.disabled) return

    if (this.setViewModel.has(option.value)) {
      this.setViewModel.delete(option.value)
    } else {
      this.setViewModel.add(option.value)
    }

    this.props.onChange?.()
  }
}