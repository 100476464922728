import { BaseModel } from "./BaseModel";
import { Entity } from "./Entity";

export type TTargetProps = BaseModel & {
  project: number
  name: string
  address: string
}

export class Target extends Entity<TTargetProps> {
  get name() {
    return this.props.name
  }

  get project() {
    return this.props.project
  }

  get address() {
    return this.props.address
  }
}