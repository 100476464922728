import { isAfter, add } from "date-fns"

export type TRequestCacheProps<T> = {
  expirationTime?: number,
  getData: () => Promise<T>,
}

export class RequestCache<T> {
  constructor(private props: TRequestCacheProps<T>) {
    this.props = props

    // makeObservable(this)
  }

  promise?: Promise<T>
  private loadDate?: Date

  get isExpired(): boolean {
    if (this.promise === undefined) return true
    if (this.loadDate === undefined) return true
    if (this.props.expirationTime === undefined) return false

    return isAfter(new Date(), add(this.loadDate, { seconds: this.props.expirationTime || 0 }))
  }

  async update(): Promise<T> {
    this.promise = this.loadData()

    return this.promise
  }

  private async loadData(): Promise<T> {
    this.loadDate = new Date()
    const value = await this.props.getData()
    return value
  }
}