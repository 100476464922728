import { IDBPDatabase } from "idb";
import { action, computed } from "mobx";
import { computedFn } from "mobx-utils";
import { TBimProps } from "../../models/Bim";
import { BimElement, TBimElementProps } from "../../models/BimElement";
import {
  NewStatusHistory,
  StatusHistory,
  TNewStatusHistoryProps,
  TStatusHistoryProps,
} from "../../models/StatusHistory";
import { TUploadFileProps } from "../../models/UploadFile";
import { IAuthRepository } from "../AuthRepository/interface";
import { IndexedDBPatchesRepository } from "../PatchesRepository/IndexedDBPatchesRepository";
import { RestOfflineRepository } from "../Repository/RestOfflineRepository";
import { IStatusHistoryRepository } from "./interface";

type TRestProps = {
  attachments: number[];
  base_status: number;
  comment: string;
  element: number;
  file: null;
  id: number;
  is_active: boolean;
  owner: number;
  progress_percentage: number;
  set_at_datetime: string;
  status_index: number | null;
};

type TNewRestProps = {
  attachments: TUploadFileProps["id"][];
  base_status: number | string;
  element: TBimElementProps["id"];
  comment: string;
  is_active?: boolean;
  status_index: number | null;
};

export class RestStatusHistoryRepository implements IStatusHistoryRepository {
  constructor(
    private props: {
      host: string;
      authRepository: IAuthRepository;
      db: IDBPDatabase;
      patchesTableName: string;
    }
  ) {
    this.props = props;

    this.offlineRepository = new RestOfflineRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: StatusHistory,
      NewModel: NewStatusHistory,
      serialyze: this.serialyze,
      serialyzeAdd: this.serialyzeAdd,
      patchesRepository: new IndexedDBPatchesRepository({
        db: props.db,
        name: props.patchesTableName,
        Model: StatusHistory,
      }),
    });
  }

  offlineRepository: RestOfflineRepository<
    StatusHistory,
    TRestProps,
    TNewRestProps,
    TNewStatusHistoryProps
  >;

  serialyze(restProps: TRestProps): StatusHistory["props"] {
    return {
      id: restProps.id,
      element: restProps.element,
      isActive: restProps.is_active,
      status: restProps.base_status,
      date: restProps.set_at_datetime,
      comment: restProps.comment,
      files: restProps.attachments,
      owner: restProps.owner,
      statusIndex: restProps.status_index,
    };
  }

  serialyzeAdd(props: TNewStatusHistoryProps): TNewRestProps {
    return {
      is_active: props.isActive,
      attachments: props.files,
      base_status: props.status,
      element: props.element,
      comment: props.comment,
      status_index: props.statusIndex,
    };
  }

  @computed
  get path() {
    return `${this.props.host}api/main/bim-status-history/`;
  }

  getByIdAsync(id) {
    return this.offlineRepository.getByIdAsync(id);
  }

  edit(id, props) {
    return this.offlineRepository.edit(id, props);
  }

  delete(id) {
    return this.offlineRepository.delete(id);
  }

  getActualByBimId = computedFn((bimId: TBimProps["id"]) => {
    return this.offlineRepository.getByParams(
      {
        isActive: true,
      },
      {
        upload: bimId,
        is_active: true,
      }
    );
  });

  getActualByBimIdAsync(bimId: TBimProps["id"]) {
    return this.offlineRepository.getByParamsAsync(
      {
        isActive: true,
      },
      {
        upload: bimId,
        is_active: true,
      }
    );
  }

  getElementMap = computedFn((array: StatusHistory[]) => {
    return new Map(array.map((i) => [i.element, i]));
  });

  async getByBimElementAsync(bimElement: BimElement) {
    return this.offlineRepository.getByParamsAsync(
      {
        element: bimElement.id,
      },
      {
        element: bimElement.id,
        // upload: bimElement.bim,
      }
    );
  }

  getByBimElement(bimElement: BimElement) {
    return this.offlineRepository.getByParams(
      {
        element: bimElement.id,
      },
      {
        element: bimElement.id,
        // upload: bimElement.bim,
      }
    );
  }

  getActualByBimElement(bimElement: BimElement) {
    const statuses = this.getActualByBimId(bimElement.bim);
    const map = this.getElementMap(statuses);
    return map.get(bimElement.id);
  }

  async getActualByBimElementAsync(bimElement: BimElement) {
    const statuses = await this.getActualByBimIdAsync(bimElement.bim);
    const map = this.getElementMap(statuses);
    return map.get(bimElement.id);
  }

  private getLocalActualByBimElementId(id: TBimElementProps["id"]) {
    return this.offlineRepository
      .getModels()
      .find((status) => status.isActive && status.element === id);
  }

  @action
  async applyStatusBatch(statuses: TNewStatusHistoryProps[]) {
    const activeStatuses = statuses.map((status) =>
      this.getLocalActualByBimElementId(status.element)
    );
    const result = await this.offlineRepository.addBatch(statuses);
    activeStatuses.forEach((status) => status?.setActive(false));

    return result;
  }
}
