import { computed, makeObservable } from "mobx";
import { BimElement } from "../../models/BimElement";
import { IModelElement } from "../../models/ModelElement/interface";
import { ERemarkStatus, Remark, TNewRemarkProps, TRemarkProps } from "../../models/Remark";
import { RemarkBase } from "../../models/RemarkBase";
import { StatusDictionary } from "../../models/StatusDictionary";
import { TTargetProps } from "../../models/Target";
import { EPanelSection } from "../../models/ViewState";
import { IBimElementsRepository } from "../../repositories/BimElementsRepository/interface";
import { IBimsRepository } from "../../repositories/BimsRepository/interface";
import { ICompaniesRepository } from "../../repositories/CompaniesRepository/interface";
import { IRemarkBaseRepository } from "../../repositories/RemarkBaseRepository/interface";
import { IRemarksRepository } from "../../repositories/RemarksRepository/interface";
import { IStatusDictionaryRepository } from "../../repositories/StatusDictionaryRepository/interface";
import { Bims } from "../Bims/Bims";
import { Elements } from "../Elements/Elements";
import { SelectedElements } from "../SelectedElements/SelectedElements";
import { Statuses } from "../Statuses/Statuses";
import { ViewState } from "../ViewState/ViewState";

export class Remarks {
  constructor(private props: {
    bims: Bims
    viewState: ViewState
    statuses: Statuses
    companiesRepository: ICompaniesRepository
    remarksRepository: IRemarksRepository
    remarksBaseRepository: IRemarkBaseRepository
    bimElementsRepository: IBimElementsRepository
    selectedElements: SelectedElements
    elements: Elements
    statusDictionaryRepository: IStatusDictionaryRepository
    bimsRepository: IBimsRepository
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get remarkEditing() {
    return (this.props.viewState.props.selectedRemakrs || []).length > 0 && this.props.viewState.props.panelSection === EPanelSection.remarks
  }

  @computed
  get selectedBimsRemarks() {
    const remarks: Remark[] = []
    const bims = this.props.bims.selectedBims

    for (const bim of bims) {
      const bimRemarks = this.props.remarksRepository.getByBimId(bim.id)
      bimRemarks.forEach(remark => remarks.push(remark))
    }

    return remarks
  }

  @computed
  get selectedElementsRemarks() {
    const remarksSet = new Set<Remark>()

    for (const element of this.props.selectedElements.selectedBimElements) {
      if (element) {
        const remarks = this.getRemarksByBimElement(element)
        for (const remark of remarks) {
          remarksSet.add(remark)
        }
      }
    }

    return Array.from(remarksSet.values())
  }

  @computed
  get selectedRemarks() {
    const remarks: Remark[] = []

    for (const id of this.props.viewState.props.selectedRemakrs || []) {
      const remark = this.props.remarksRepository.getById(id)
      if (remark) {
        remarks.push(remark)
      }
    }

    return remarks
  }

  @computed
  get selectedRemark() {
    return this.selectedRemarks[0]
  }

  @computed
  get canAssingRemarkForSelectedElements() {
    return true
  }

  @computed
  get canAssingRemarkForSelectedStatusDictionary() {
    return this.props.statuses.selectedStatusDictionary.length <= 1
  }

  @computed
  get remarksBaseForSelectedElements() {
    if (this.props.selectedElements.count === 0) return this.props.remarksBaseRepository.getAll()

    const status = this.props.statuses.activeStatusDictionary
    const commonRemarksBases = this.props.remarksBaseRepository.getCommonRemarks()
    const forStatusRemarksBases = status ? this.props.remarksBaseRepository.getByBaseStatus(status.id) : []

    return [
      ...commonRemarksBases,
      ...forStatusRemarksBases,
    ]
  }

  // @computed
  get canAssignStatusByRemarks() {
    return !this.getForgeElementsHasNotClosedRemarks(this.props.selectedElements.selectedForgeElements)
  }

  // methods
  getForgeElementsHasNotClosedRemarks(forgeElements: IModelElement[]) {
    const bimElements = this.props.elements.getExistBimElements(forgeElements)
    const remarks = this.getNotClosedRemarksForBimElements(bimElements)
    return remarks.length > 0
  }

  async getForgeElementsRemarksAsync(forgeElements: IModelElement[]) {
    const bimElements = await this.props.elements.getExistBimElementsAsync(forgeElements)
    return this.getRemarksForBimElementsAsync(bimElements)
  }

  async getForgeElementsWithOpenRemarksAsync(forgeElements: IModelElement[]) {
    const result: IModelElement[] = []

    for (const forgeElement of forgeElements) {
      const has = await this.getForgeElementHasOpenRemarkAsync(forgeElement)
      if (has) {
        result.push(forgeElement)
      }
    }

    return result
  }

  async getForgeElementsWithPendingRemarksAsync(forgeElements: IModelElement[]) {
    const result: IModelElement[] = []

    for (const forgeElement of forgeElements) {
      const has = await this.getForgeElementHasPendingRemarkAsync(forgeElement)
      if (has) {
        result.push(forgeElement)
      }
    }

    return result
  }

  async getForgeElementsWithClosedRemarksAsync(forgeElements: IModelElement[]) {
    const result: IModelElement[] = []

    for (const forgeElement of forgeElements) {
      const has = await this.getForgeElementHasClosedRemarkAsync(forgeElement)
      if (has) {
        result.push(forgeElement)
      }
    }

    return result
  }

  async getForgeElementHasOpenRemarkAsync(forgeElement: IModelElement) {
    const remarks = await this.getForgeElementsRemarksAsync([forgeElement])

    return remarks.some(remark => remark.status === ERemarkStatus.opened)
  }

  async getForgeElementHasPendingRemarkAsync(forgeElement: IModelElement) {
    const remarks = await this.getForgeElementsRemarksAsync([forgeElement])

    return remarks.some(remark => remark.status === ERemarkStatus.pending)
  }

  async getForgeElementHasClosedRemarkAsync(forgeElement: IModelElement) {
    const remarks = await this.getForgeElementsRemarksAsync([forgeElement])

    return remarks.some(remark => remark.status === ERemarkStatus.closed)
  }

  getNotClosedRemarksForBimElements(bimElements: BimElement[]) {
    return this.getNotClosedRemarks(
      this.getRemarksForBimElements(bimElements)
    )
  }

  getNotClosedRemarks(remarks: Remark[]) {
    return remarks.filter(remark => !remark.closed)
  }

  getRemarkCode(remark: Remark): string {
    const project = this.props.bims.getProjectByBimId(remark.bim)
    return `${project?.code || ""}_З_${remark.projectNumber}`
  }

  async getRemarkCodeAsync(remark: Remark): Promise<string> {
    const project = await this.props.bims.getProjectByBimIdAsync(remark.bim)
    return `${project?.code || ""}_З_${remark.projectNumber}`
  }

  getRemarkContractor(remark: Remark) {
    return this.props.companiesRepository.getById(remark.contractor)
  }

  getRemarkBase(remark: Remark) {
    return this.props.remarksBaseRepository.getById(remark.remarkBase)
  }

  async getRemarksBaseAsync(remarks: Remark[]) {
    const result = new Set<RemarkBase>()

    for (const remark of remarks) {
      const remarkBase = await this.getRemarkBase(remark)
      if (remarkBase) {
        result.add(remarkBase)
      }
    }

    return Array.from(result.values())
  }

  getContractorByRemarkId(id: TRemarkProps["id"]) {
    const remark = this.props.remarksRepository.getById(id)
    if (!remark) return
    return this.getRemarkContractor(remark)
  }

  getRemarksByTargetId(id: TTargetProps["id"]) {
    const remarksSet = new Set<Remark>()

    const bims = this.props.bimsRepository.getByTargetId(id)

    for (const bim of bims) {
      const remarks = this.props.remarksRepository.getByBimId(bim.id)

      for (const remark of remarks) {
        remarksSet.add(remark)
      }
    }

    return Array.from(remarksSet)
  }

  async getRemarksByTargetIdAsync(id: TTargetProps["id"]) {
    const remarksSet = new Set<Remark>()

    const bims = await this.props.bimsRepository.getByTargetIdAsync(id)

    for (const bim of bims) {
      const remarks = await this.props.remarksRepository.getByBimIdAsync(bim.id)

      for (const remark of remarks) {
        remarksSet.add(remark)
      }
    }

    return Array.from(remarksSet)
  }

  getById(id: TRemarkProps["id"]) {
    return this.props.remarksRepository.getById(id)
  }

  getRemarksForBimElements(bimElements: (BimElement | undefined)[]): Remark[] {
    const set = new Set<Remark>()

    for (const bimElement of bimElements) {
      if (bimElement) {
        const remarks = this.getRemarksByBimElement(bimElement)
        remarks.forEach(remark => set.add(remark))
      }
    }

    return Array.from(set.values())
  }

  getRemarksByBimElement(bimElement: BimElement) {
    const remarks = this.props.remarksRepository.getByBimId(bimElement.bim)
    return remarks.filter(remark => remark.elements.includes(bimElement.id))
  }

  async getRemarksByBimElementAsync(bimElement: BimElement) {
    const remarks = await this.props.remarksRepository.getByBimIdAsync(bimElement.bim)
    return remarks.filter(remark => remark.elements.includes(bimElement.id))
  }

  async getRemarksForBimElementsAsync(bimElements: (BimElement | undefined)[]): Promise<Remark[]> {
    const set = new Set<Remark>()

    for (const bimElement of bimElements) {
      if (bimElement) {
        const remarks = await this.getRemarksByBimElementAsync(bimElement)
        remarks.forEach(remark => set.add(remark))
      }
    }

    return Array.from(set.values())
  }

  getRemarkCanBeSave(props: Partial<TNewRemarkProps>) {
    if (
      !props.contractor ||
      !props.supervisor ||
      !props.remarkBase ||
      !props.status
    ) return false

    return true
  }

  getNewRemarkCanBeAdd(props: Partial<TNewRemarkProps>) {
    if (!props.contractor) return false
    if (!props.supervisor) return false
    if (!props.remarkBase) return false

    return true
  }

  // actions
  async setSelectedRemark(remarkId: TRemarkProps["id"]) {
    const remark = await this.props.remarksRepository.getByIdAsync(remarkId)
    if (remark) {
      this.props.viewState.setSelectedRemarks([remarkId])
      this.props.selectedElements.selectBimElementsIds(remark.elements)
    }
  }

  async setSelectedRemarks(remarkIds: TRemarkProps["id"][]) {
    const remarks: Remark[] = []
    for (const remarkId of remarkIds) {
      const remark = await this.props.remarksRepository.getByIdAsync(remarkId)

      if (remark) {
        remarks.push(remark)
      }
    }
    this.props.viewState.setSelectedRemarks(remarks.map(r => r.id))
    this.props.selectedElements.selectBimElementsIds(remarks.map(r => r.elements).flat())
  }

  async deleteSelectedRemark() {
    const selectedRemark = this.selectedRemark
    if (selectedRemark) {
      await this.props.remarksRepository.delete(
        selectedRemark.id
      )
      this.dropSelectedRemark()
    }
  }

  async editSelectedRemark(props: Omit<TNewRemarkProps, "elements">) {
    const selectedRemark = this.selectedRemark
    if (selectedRemark) {
      const forgeElements = this.props.selectedElements.selectedForgeElements
      const bimElements = await this.props.elements.getOrCreateBimElements(forgeElements)

      await this.props.remarksRepository.edit(
        selectedRemark.id,
        {
          ...props,
          elements: bimElements.map(e => e.id)
        }
      )

      this.props.viewState.setEditRemarkMode(false)
      this.dropSelectedRemark()
    }
  }

  async addRemark(props: Omit<TNewRemarkProps, "elements" | "bim">) {
    const forgeElements = this.props.selectedElements.selectedForgeElements
    const bimElements = await this.props.elements.getOrCreateBimElements(forgeElements)
    const bim = bimElements[0]?.bim || this.props.bims.selectedBims[0]?.id

    if (!bim) return

    await this.props.remarksRepository.add(
      {
        ...props,
        bim,
        elements: bimElements.map(e => e.id),
      }
    )

    this.dropAddRemarkMode()
  }

  async getStatusDictionaryForRemarkAsync(remark: Remark) {
    const remarkBase = await this.getRemarkBaseForRemarkAsync(remark)
    if (!remarkBase) return

    return this.getStatusDictionariesForBaseRemarkAsync(remarkBase)
  }

  async getRemarkBaseForRemarkAsync(remark: Remark) {
    return this.props.remarksBaseRepository.getByIdAsync(remark.remarkBase)
  }

  async getStatusDictionariesForBaseRemarkAsync(remarkBase: RemarkBase) {
    const baseStatusSet = new Set<StatusDictionary>()
    for (const baseStatusId of remarkBase.baseStatus) {
      const baseStatus = await this.props.statusDictionaryRepository.getByIdAsync(baseStatusId)
      if (baseStatus) baseStatusSet.add(baseStatus)
    }

    return Array.from(baseStatusSet.values())
  }

  dropAddRemarkMode() {
    this.props.viewState.setAddRemarkDialogActive(false)
    this.props.viewState.setAddRemarkMode(false)
  }

  dropSelectedRemark() {
    this.props.viewState.setSelectedRemarks(undefined)
    this.props.selectedElements.selectBimElementsIds([])
  }
}