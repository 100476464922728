import React from "react"
import { CircularProgress, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import SimpleButton from "../../components/Buttons/SimpleButton";
import clsx from "clsx";

const useClasses = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  input: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",

    opacity: 0
  },
  label: {
    cursor: "pointer",
  },
  loader: {
    color: "white"
  }
}))

interface IUploadButtonProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  className?: string
  loading?: boolean
  children: JSX.Element | string
  onChange(e: FileList | null): void
}

export const UploadButton = observer((props: IUploadButtonProps) => {
  const classes = useClasses(props)

  return (
    <SimpleButton className={clsx(classes.root, props.className)} disabled={props.loading}>
      {
        props.loading ? (
          <CircularProgress className={classes.loader} size={20} />
        ) : (
          <>
            <input className={classes.input} type="file" multiple onChange={e => props.onChange(e.target.files)} />
            <div className={classes.label}>
              {props.children}
            </div>
          </>
        )
      }

    </SimpleButton>
  )
})