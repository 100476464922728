import React from "react";
import { withStyles } from "@material-ui/core";
import SimpleButton from "./SimpleButton";

function BackIcon() {
    return (
        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.469309 8.06584L7.34581 1.18934C7.79582 0.739335 8.52543 0.739335 8.97544 1.18934C9.42545 1.63935 9.42545 2.36896 8.97544 2.81897L2.86433 8.93009L8.97544 15.0412C9.42545 15.4912 9.42545 16.2208 8.97544 16.6708C8.52543 17.1208 7.79582 17.1208 7.34581 16.6708L0.469309 9.79433C-0.00799876 9.31702 -0.00799876 8.54315 0.469309 8.06584Z" fill="black" />
        </svg>
    )
}

function BackButtonPure(props) {
    return (
        <SimpleButton {...props}>
            <BackIcon/>
        </SimpleButton>
    );
}

const size = 44;

const styles = {
    root: {
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        backgroundColor: "#F5F5F5",
        padding: 0,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 14
    },
};

const BackButton = withStyles(styles)(BackButtonPure);

export default BackButton;