import { computed, makeObservable, reaction } from "mobx"
import { Viewer } from "./Viewer"
import { ICustomPropertyPanelViewModel } from "../../view-model/CustomPropertyPanelViewModel/interface"


export class CustomPropertyPanel {
  constructor(private props: { viewModel: ICustomPropertyPanelViewModel, bimViewer: Viewer }) {
    this.props = props

    makeObservable(this)

    this.mount()
  }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  private mount() {
    const clearReaction1 = reaction(() => this.viewer, (viewer) => {
      if (viewer) {
        viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, e => {
          if (e.extensionId === "Autodesk.PropertiesManager") {
            this.panelCreated();
          }
        });
      }
    }, {
      fireImmediately: true
    })

    this.unmount = () => {
      clearReaction1()
    }
  }

  private panelCreated() {
    //@ts-ignore
    const panel = this.viewer?.getPropertyPanel(true);
    const viewModel = this.props.viewModel

    panel.setProperties = async function () {
      this.removeAllProperties();
      const properties = await viewModel.getProperties();

      for (const property of properties) {
        this.addProperty(property.name, property.value, property.category);
      }

      this.resizeToContent();
    };
  }

  unmount() { }
}