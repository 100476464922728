import React, { useCallback, useMemo } from 'react';

import {
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";

import { useDropzone } from 'react-dropzone';

import clsx from 'clsx';
import CircularProgressStyled from '../../components/CircularProgressStyled/CircularProgressStyled';
import { EmptyCard } from '../../components/EmptyCard/EmptyCard'
import { observer } from 'mobx-react-lite';
import { IBimTableViewModel, TBimTableModel } from '../../view-model/BimTableViewModel/interface';
import { TableButton } from '../Buttons/TableButton';
import { useTableClasses } from '../../styles/TableClasses';
import { RefreshButton, UploadFile } from '../UploadFile/UploadFile';

const useClasses = makeStyles(theme => ({
  root: {},
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  openButton: {
    marginLeft: 10,
    width: 158
  },
  activeRow: {
    backgroundColor: theme.palette.primary.main
  },
  fileNameCell: {
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}))

const tableHeadersList = ["Имя", "Описание", "Размер", "Дата загрузки", "Кем загружено", "Файл"];

interface IBimTableProps {
  viewModel: IBimTableViewModel
  classes?: Partial<ReturnType<typeof useClasses>>
}

export const BimTable = observer((props: IBimTableProps) => {
  const classes = useClasses(props);
  const tableClasses = useTableClasses();

  const onDrop = useCallback((files: File[]) => {
    props.viewModel.onAdd(files[0])
  }, [props.viewModel.onAdd]);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    disabled: props.viewModel.disableAdd,
    multiple: false,
    accept: props.viewModel.accept
  });

  const dragProps = useMemo(getRootProps, []);

  return (
    <div
      {...dragProps}
      className={clsx({
        [classes.activeRow]: isDragActive
      })}
    >
      {
        props.viewModel.loading ? <CircularProgressStyled /> :
          props.viewModel.empty ? <EmptyCard title="нет ни одной модели" /> :
            <TableContainer>
              <Table className={tableClasses.table}>
                <TableHead>
                  <TableRow>
                    {
                      tableHeadersList.map((name, index) => (
                        <TableCell key={index} className={tableClasses.headerCell}>
                          {name}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props.viewModel.bims.map((model) =>
                      <TableRowComponent
                        key={model.id}
                        model={model}
                        classes={classes}
                        tableClasses={tableClasses}
                      />
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
      }
    </div>
  )
})

interface ITableRowComponentProps {
  model: TBimTableModel
  classes: ReturnType<typeof useClasses>
  tableClasses: ReturnType<typeof useTableClasses>
}

const TableRowComponent = (props: ITableRowComponentProps) => {
  const { model, classes, tableClasses } = props;

  const onFileLoad = useCallback((file: File) => {
    model.onUpdate(file)
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileLoad(acceptedFiles[0]);
  }, []);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    disabled: !model.allowUpdate,
    multiple: false,
    accept: model.accept
  });

  const dragProps = useMemo(getRootProps, []);

  return (
    <TableRow
      {...dragProps}
      className={clsx(tableClasses.bodyRow, {
        [classes.activeRow]: isDragActive
      })}
    >
      <TableCell className={clsx(tableClasses.bodyCell, tableClasses.boldCell)}>
        {model.name}
      </TableCell>
      <TableCell className={tableClasses.bodyCell}>
        {model.description}
      </TableCell>
      <TableCell className={tableClasses.bodyCell}>
        {model.size}
      </TableCell>
      <TableCell className={tableClasses.bodyCell}>
        {model.uploadedAt}
      </TableCell>
      <TableCell className={tableClasses.bodyCell}>
        {model.userName}
      </TableCell>
      <TableCell className={clsx(tableClasses.bodyCell, classes.fileNameCell)} placeholder={model.fileName}>
        {model.fileName}
      </TableCell>
      <TableCell className={tableClasses.bodyCell}>
        <div className={classes.buttons}>
          {
            model.allowUpdate && model.uploadFileViewModel ? (
              <UploadFile viewModel={model.uploadFileViewModel}>
                <RefreshButton />
              </UploadFile>
            ) : null
          }
          {
            model.onOpen ? (
              <TableButton className={classes.openButton} onClick={model.onOpen}>
                Открыть
              </TableButton>
            ) : null
          }
        </div>
      </TableCell>
    </TableRow>
  )
}