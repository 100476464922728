import React, { useMemo } from "react"
import { Dialog, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IRemarksFormViewModel } from "../../view-model/RemarksFormViewModel/interface"
import PanelSelectorOld from "../../components/Selectors/PanelSelector";
import PanelDateSelector from "../../components/Selectors/PanelDateSelector";
import PanelInput from "../../components/Inputs/PanelInput";
import PanelCommentInput from "../../components/Inputs/PanelCommentInput";
import SimpleButton from "../../components/Buttons/SimpleButton";
import clsx from "clsx";
import { StatusWrapper } from "../StatusWrapper/StatusWrapper";
import { UploadButton } from "../Buttons/UploadButton";
import { UploadFilesList } from "../UploadFilesList/UploadFilesList";
import { SliderCheckboxWithLabel } from "../Checkbox/SliderCheckboxWithLabel";
import { PanelSelector } from "../PanelSelector/PanelSelector";

const useClasses = makeStyles(theme => ({
  root: {
    overflow: "auto"
  },
  padding: {
    paddingLeft: 26,
    paddingRight: 26
  },
  warnings: {
    padding: "0 26px"
  },
  buttonsGroup: {
    marginTop: 20,
    padding: "0 26px",
  },
  buttons: {
    marginTop: 20,
    display: "grid",
    columnGap: 22,
    gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))"
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main
  },
  showFilesButton: {
    marginTop: 20,
    backgroundColor: "#F5F5F5",
    color: "#0005"
  },
  deleteButton: {
    //@ts-ignore
    backgroundColor: theme.palette.redColor || "red"
  },
  saveButton: {
    //@ts-ignore
    backgroundColor: theme.palette.greenColor || "green"
  },
  button: {
    color: "white"
  },
  filesList: {
    padding: 20,
    width: 656
  }
}))

interface IRemarksFormProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IRemarksFormViewModel
}

export const RemarksForm = observer((props: IRemarksFormProps) => {
  const classes = useClasses(props)
  const paddingClasses = useMemo(() => ({
    padding: classes.padding
  }), []);
  const paddingClasses2 = useMemo(() => ({
    root: classes.padding
  }), []);

  return (
    <div className={classes.root}>
      <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
        {
          props.viewModel.warnings ? (
            <div className={classes.warnings}>
              {props.viewModel.warnings.header}
              {
                props.viewModel.warnings.items.map(warning => (
                  <div>{warning}</div>
                ))
              }
            </div>
          ) : (
            <>
              <PanelSelector
                classes={paddingClasses}
                viewModel={props.viewModel.remarkBaseViewModel}
              />
              {
                props.viewModel.normativeDocumentViewModel ?
                  <PanelInput
                    classes={paddingClasses}

                    label="Нормативная документация"
                    value={props.viewModel.normativeDocumentViewModel.value}
                    disabled={props.viewModel.normativeDocumentViewModel.disabled}
                    placeholder="..."
                    onChange={e => props.viewModel.normativeDocumentViewModel?.onChange(e)}
                  />
                  : null
              }

              <PanelSelector
                classes={paddingClasses}
                viewModel={props.viewModel.statusViewModel}
              />
              <PanelSelector
                classes={paddingClasses}
                viewModel={props.viewModel.contractorViewModel}
              />
              <PanelDateSelector
                classes={paddingClasses}

                label="Срок"
                value={props.viewModel.deadlineViewModel.value}
                disabled={props.viewModel.deadlineViewModel.disabled}
                placeholder="..."
                onChange={e => props.viewModel.deadlineViewModel.onChange(e)}
              />
              <PanelInput
                classes={paddingClasses}

                label="Местоположение"
                value={props.viewModel.locationViewModel.value}
                disabled={props.viewModel.locationViewModel.disabled}
                placeholder="..."
                onChange={e => props.viewModel.locationViewModel.onChange(e)}
              />
              <PanelSelector
                classes={paddingClasses}
                viewModel={props.viewModel.controlViewModel}
              />
              {
                props.viewModel.criticalCheckboxWithLabelViewModel ?
                  <SliderCheckboxWithLabel classes={paddingClasses2} viewModel={props.viewModel.criticalCheckboxWithLabelViewModel} />
                  : null
              }
              <PanelCommentInput
                classes={paddingClasses}

                label="Комментарий"
                placeholder="Оставить комментарий"
                value={props.viewModel.commentViewModel.value}
                disabled={props.viewModel.commentViewModel.disabled}
                onChange={e => props.viewModel.commentViewModel.onChange(e)}
              />
              <div className={classes.buttonsGroup}>
                {
                  props.viewModel.uploadButtonViewModel ? (
                    <UploadButton
                      className={classes.uploadButton}
                      loading={props.viewModel.uploadButtonViewModel.loading}
                      onChange={e => props.viewModel.uploadButtonViewModel?.onChange(e)}
                    >
                      Добавить файл
                    </UploadButton>
                  ) : null
                }

                {
                  props.viewModel.showFilesViewModel ? (
                    <SimpleButton
                      className={classes.showFilesButton}
                      {...props.viewModel.showFilesViewModel}
                    >
                      {props.viewModel.showFilesViewModel.label}
                    </SimpleButton>
                  ) : null
                }

                <div className={classes.buttons}>
                  {
                    props.viewModel.saveButtonViewModel ? (
                      <SimpleButton
                        className={clsx(classes.button, classes.saveButton)}
                        {...props.viewModel.saveButtonViewModel}
                      >
                        {props.viewModel.saveButtonViewModel.label}
                      </SimpleButton>
                    ) : null
                  }
                  {
                    props.viewModel.allowDelete ? (
                      <SimpleButton className={clsx(classes.button, classes.deleteButton)} onClick={() => props.viewModel.onDelete()}>Удалить</SimpleButton>
                    ) : null
                  }
                </div>
              </div>
            </>
          )
        }
      </StatusWrapper>
      <Dialog open={props.viewModel.showFilesDialogViewModel.open} onClose={props.viewModel.showFilesDialogViewModel.onClose}>
        <UploadFilesList className={classes.filesList} viewModel={props.viewModel.uploadFilesListViewModel} />
      </Dialog>
    </div>
  )
})