import { Material } from "../../models/Material";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { IMaterialsRepository } from "./interface";

type TRestProps = {
  id: number
  name: string
  code: string
}

export class RestMaterialsRepository implements IMaterialsRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.offlineRepository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: Material,
    })
  }

  offlineRepository: RestOfflineReadOnlyRepository<Material, TRestProps>

  get path() {
    return `${this.props.host}api/main/materials/`
  }

  getAll() {
    return this.offlineRepository.getAll()
  }

  getAllAsync() {
    return this.offlineRepository.getAllAsync()
  }

  getByCode(code: string) {
    return this.offlineRepository.getOneByParams({ code }, {})
  }

  async getByCodeAsync(code: string) {
    return this.offlineRepository.getOneByParamsAsync({ code }, {})
  }
}