import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { ERemarksPageContent, IRemarksPageViewModel } from "../../view-model/RemarksPageViewModel/interface"
import BackButton from '../../components/Buttons/BackButton';
import SimpleButton from '../../components/Buttons/SimpleButton';
import { RemarksList } from "../RemarksList/RemarksList";
import { RemarksForm } from "../RemarksForm/RemarksForm";
import { Add } from "@material-ui/icons";
import clsx from "clsx";

const useClasses = makeStyles(theme => ({
  root: {
    padding: "100px 0 56px 0",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflow: "auto"
  },
  header: {
    margin: "0px 30px 30px 26px",
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    width: 44,
    height: 44,
    flex: "none",
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 14,
    "& svg": {
      fill: "white",
    }
  },
  list: {
    overflow: "auto"
    // padding: "0 26px"
  },
  hidden: {
    display: "none"
  }
}))

const useClassesList = makeStyles(() => ({
  search: {
    marginLeft: 26,
    marginRight: 26
  },
  statusFilter: {
    marginLeft: 26,
    marginRight: 26
  },
  row: {
    paddingLeft: 26,
    paddingRight: 26
  }
}))

interface IRemarksPageProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IRemarksPageViewModel
}

export const RemarksPage = observer((props: IRemarksPageProps) => {
  const classes = useClasses(props)
  const classesList = useClassesList()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <BackButton onClick={() => props.viewModel.onBack()} />
        {
          props.viewModel.onAdd ? (
            <SimpleButton className={classes.button} onClick={props.viewModel.onAdd}>
              <Add />
            </SimpleButton>
          ) : null
        }
        {
          props.viewModel.editButtonViewModel ? (
            <SimpleButton onClick={props.viewModel.editButtonViewModel.onClick}>Редактировать</SimpleButton>
          ) : null
        }
      </div>
      <div className={clsx(classes.list, {
        // hidden and save scroll position
        [classes.hidden]: props.viewModel.content !== ERemarksPageContent.list
      })}>
        <RemarksList classes={classesList} viewModel={props.viewModel.remarksListViewModel} />
      </div>
      {
        props.viewModel.content === ERemarksPageContent.form ? (
          <RemarksForm viewModel={props.viewModel.remarksFormViewModel} />
        ) : null
      }
    </div>
  )
})