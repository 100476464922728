export interface IFetchHandler {
  match(request: Request): boolean
  handle(request: Request): Promise<Response>
}

export enum EHTTPMethods {
  get = "GET",
  post = "POST",
  put = "PUT",
  patch = "PATCH",
  delete = "DELETE",
}