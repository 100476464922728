import { computed, makeObservable } from "mobx";
import { EUserRoles } from "../../models/User";
import { IAuthRepository } from "../../repositories/AuthRepository/interface";
import { IUsersRepository } from "../../repositories/UsersRepository/interface";

// актуализировано по таблице https://sarex.jetbrains.space/d/4Rwfpg3FaOyz?f=0&download=true
export enum EActions {
  //// модели
  // просмотр моделей
  showModels,
  // cоздание моделей
  createAndEditBims,
  // редактирование сводных моделей
  createAndEditCollections,


  //// замечания
  // просмотр замечаний
  showRemarks,
  // добавление замечаний
  addRemark,
  // редактирование замечаний (*)
  editRemark,
  // cмена статуса замечаний
  // => открыто
  setRemarkStatusOpen,
  // => ожидает
  setRemarkStatusPending,
  // => закрыто
  setRemarkStatusClose,
  // удаление замечаний
  deleteRemark,
  // добавление новых файлов в замечание
  addNewRemarkFiles,


  //// предписания
  // просмотр предписаний
  showPrescriptions,
  // добавление предписаний
  addPrescription,
  // редактирование предписания
  editPrescription,
  // редактирование специфичных полей предписания
  editCustomPrescriptionsFields,
  // редактирование поля дата выдачи
  editPrescriptionCreatedAtField,
  // удаление предписаний
  deletePrescription,

  //// рабочее пространство
  // редактирование отметок этажей
  editStoreyMark,
  // режим планирования, назначение даты (Редактирование режима планирования)
  setPlanningDate,
  // просмотр истории
  showHistory,

  // работа со статусами
  // назначение следующего статуса
  applyNextStatus,
  // изменение последнего статуса
  editLastStatusHistory,
  // редактирование статуса
  editStatusHistory,
  //
}

const roleAllowedActions = new Map<EUserRoles, Set<EActions>>([
  [
    EUserRoles.test,
    new Set([
      EActions.showModels,
    ])
  ],
  [
    EUserRoles.none,
    new Set([
    ])
  ],
  [
    EUserRoles.designer,
    new Set([
      EActions.showModels,

      EActions.showRemarks,

      EActions.showPrescriptions,
    ])
  ],
  [
    EUserRoles.customer,
    new Set([
      EActions.showModels,

      EActions.showRemarks,

      EActions.showPrescriptions,

      EActions.showHistory,
    ])
  ],
  [
    EUserRoles.scheduler,
    new Set([
      EActions.showModels,

      EActions.showRemarks,

      EActions.showPrescriptions,

      EActions.setPlanningDate
    ])
  ],
  [
    EUserRoles.contractor,
    new Set([
      EActions.showModels,

      EActions.showRemarks,
      EActions.setRemarkStatusPending,
      EActions.addNewRemarkFiles,

      EActions.showPrescriptions,

      EActions.showHistory,
    ])
  ],
  [
    EUserRoles.general_contractor,
    new Set([
      EActions.showModels,

      EActions.showRemarks,
      EActions.setRemarkStatusPending,
      EActions.addNewRemarkFiles,

      EActions.showPrescriptions,

      EActions.showHistory,
    ])
  ],
  [
    EUserRoles.supervisor,
    new Set([
      EActions.showModels,

      EActions.showRemarks,

      EActions.showPrescriptions,

      EActions.showHistory,
    ])
  ],
  [
    EUserRoles.engineer,
    new Set([
      EActions.showModels,

      EActions.showPrescriptions,
      EActions.addPrescription,
      EActions.editPrescriptionCreatedAtField,

      EActions.applyNextStatus,

      EActions.showRemarks,
      EActions.addRemark,
      EActions.setRemarkStatusOpen,
      EActions.setRemarkStatusClose,
      EActions.addNewRemarkFiles,
      EActions.showHistory,
    ])
  ],
  [
    EUserRoles.head,
    new Set([
      EActions.showModels,

      EActions.showPrescriptions,
      EActions.addPrescription,
      EActions.editPrescriptionCreatedAtField,

      EActions.applyNextStatus,

      EActions.showRemarks,
      EActions.addRemark,
      EActions.setRemarkStatusOpen,
      EActions.setRemarkStatusClose,
      EActions.addNewRemarkFiles,
      EActions.showHistory,
    ])
  ],
  [
    EUserRoles.admin,
    new Set(
      [
        EActions.showModels,
        EActions.createAndEditCollections,
        EActions.createAndEditBims,

        EActions.editLastStatusHistory,
        EActions.editStatusHistory,
        EActions.applyNextStatus,

        EActions.showRemarks,
        EActions.addRemark,
        EActions.editRemark,
        EActions.deleteRemark,
        EActions.setRemarkStatusOpen,
        EActions.setRemarkStatusPending,
        EActions.setRemarkStatusClose,
        EActions.addNewRemarkFiles,

        EActions.showPrescriptions,
        EActions.addPrescription,
        EActions.editPrescription,
        EActions.editCustomPrescriptionsFields,
        EActions.editPrescriptionCreatedAtField,
        EActions.deletePrescription,

        EActions.editStoreyMark,
        EActions.showHistory,
        EActions.setPlanningDate,
      ]
    )
  ],
])

export class Permissions {
  constructor(private props: {
    authRepository: IAuthRepository
    usersRepository: IUsersRepository
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get user() {
    const user = this.props.authRepository.getUser()
    if (!user) return

    return this.props.usersRepository.getById(user.id) || user
  }

  @computed
  get role() {
    return this.user?.role || EUserRoles.none
  }

  @computed
  get allowedActions() {
    return roleAllowedActions.get(this.role) || new Set()
  }

  actionIsAllowed(action: EActions) {
    return this.allowedActions.has(action)
  }

  someActionsIsAllowed(actions: EActions[]) {
    return actions.some(action => this.allowedActions.has(action))
  }

  actionIsBlocked(action: EActions) {
    return !this.allowedActions.has(action)
  }
}