import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IPieChartWithLegendViewModel } from "../../view-model/PieChartWithLegendViewModel/interface"
import { IPieChartProps, PieChart } from "../PieChart/PieChart"
import clsx from "clsx"

const useClasses = makeStyles(theme => ({
  root: {
    display: "inline-block"
  },
  circle: {
    display: "inline-block",
    width: 12,
    height: 12,
    borderRadius: 6,
    marginTop: 2
  },
  name: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: 20,
  },
  legends: {
    verticalAlign: "top",
    display: "inline-block",
    margin: "15px 0 5px 30px",
  },
  valueLabel: {
    whiteSpace: "nowrap",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "15px"
  },
  label: {
    whiteSpace: "nowrap",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "15px"
  },
  legendRow: {
    "&:not(:last-child)": {
      marginBottom: 20
    },
    display: "flex",
    alignItems: "center"
  },
  valueInformation: {
    display: "inline-block",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    marginLeft: 8
  },
  header: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: 20,
  },
  data: {
    display: "flex"
  },
}))

interface IPieChartWithLegendProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  pieChartProps: Pick<IPieChartProps, "width" | "height">
  viewModel: IPieChartWithLegendViewModel
  className?: string
}

export const PieChartWithLegend = observer((props: IPieChartWithLegendProps) => {
  const classes = useClasses(props)

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={classes.header}>{props.viewModel.header}</div>
      <div className={classes.data}>
        <PieChart {...props.pieChartProps} viewModel={props.viewModel.pieChartViewModel} />
        <div className={classes.legends}>
          {
            props.viewModel.legends.map(row => (
              <div className={classes.legendRow} key={row.key}>
                <div className={classes.circle} style={{ backgroundColor: row.color }}></div>
                <div className={classes.valueInformation}>
                  <div className={classes.valueLabel}>{row.valueLabel}</div>
                  <div className={classes.label}>{row.label}</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
})