import axios from "axios";
import { add, isAfter } from "date-fns";
import { action, computed, makeObservable, ObservableMap } from "mobx";
import { BaseModel } from "../../models/BaseModel";
import { Entity } from "../../models/Entity";
import { IAuthRepository } from "../AuthRepository/interface";
import { IRepository } from "./interface";

export abstract class RestRepository<T extends Entity<BaseModel>, TResponseProps, TNewProps> implements IRepository<T, TNewProps> {
  abstract get path(): string
  abstract get auth(): IAuthRepository
  abstract serialyze(props: TResponseProps): T["props"]
  abstract Item: new (props: T["props"]) => T

  constructor() {
    makeObservable(this)
  }

  // second
  expirationTime = undefined

  models = new ObservableMap<T["id"], T>()
  private cache = new ObservableMap<string, Cache<T["props"][]>>()

  serialyzeAdd(props: TNewProps): any {
    return props
  }

  serialyzeEdit(props: Partial<T["props"]>): any {
    return props
  }

  @computed
  get headers() {
    return this.auth.getAuthHeaders()
  }

  @action
  updatedOrCreateModels(props: T["props"][]): T[] {
    return props.map(data => {
      let model = this.models.get(data.id)
      if (model) {
        model.patch(data)
      } else {
        model = new this.Item(data)
        this.models.set(model.id, model)
      }
      return model
    })
  }

  private async loadPropsById(id: T["id"]) {
    const response = await axios.get<TResponseProps>(`${this.path}${id}/`, {
      headers: this.headers,
    })

    return [this.serialyze(response.data)]
  }

  private async loadPropsByParams(params?: { [key: string]: any }): Promise<T["props"][]> {
    const response = await axios.get<TResponseProps[]>(this.path, {
      headers: this.headers,
      params
    })

    return response.data.map(d => this.serialyze(d))
  }

  async _add(props: TNewProps) {
    const response = await axios.post<TResponseProps>(`${this.path}`, this.serialyzeAdd ? this.serialyzeAdd(props) : props, {
      headers: this.headers,
    })

    return this.serialyze(response.data)
  }

  async _edit(id: T["id"], props: TNewProps) {
    const response = await axios.patch<TResponseProps>(`${this.path}${id}/`, this.serialyzeEdit ? this.serialyzeEdit(props) : props, {
      headers: this.headers,
    })

    return this.serialyze(response.data)
  }

  private async _delete(id: T["id"]) {
    const response = await axios.delete<TResponseProps>(`${this.path}${id}/`, {
      headers: this.headers,
    })

    return response.data
  }

  private getCache(key: string) {
    return this.cache.get(key)
  }

  private createCache(key: string, props: CacheProps<T["props"][]>) {
    const cache = new Cache<T["props"][]>(props)
    this.cache.set(key, cache)
    return cache
  }

  filterByParams(params?: Partial<T["props"]>) {
    if (!params) return this.getModels()
    const paramsArray = Array.from(Object.entries(params))

    return this.getModels().filter(model => paramsArray.every(([key, value]) => model[key] === value))
  }

  private async updateCache(cache: Cache<T["props"][]>) {
    if (cache.isExpired) {
      const data = await cache.update()
      return this.updatedOrCreateModels(data)
    } else {
      await cache.update()
    }
  }

  getAll(): T[] {
    return this.getByParams()
  }

  getByParams(modelProps?: Partial<T["props"]>, loadProps?: { [key: string]: any }): T[] {
    const key = JSON.stringify(loadProps || modelProps)

    const cache = (
      this.getCache(key) ||
      this.createCache(key, {
        expirationTime: this.expirationTime,
        getData: () => this.loadPropsByParams(loadProps || modelProps)
      })
    )

    this.updateCache(cache)
    return this.filterByParams(modelProps)
  }

  getById(id: T["id"]): T | undefined {
    const key = String(id)

    const cache = (
      this.getCache(key) || this.createCache(key, {
        expirationTime: this.expirationTime,
        getData: () => this.loadPropsById(id)
      })
    )

    this.updateCache(cache)

    return this.models.get(id)
  }

  async getByIdAsync(id: T["id"]): Promise<T | undefined> {
    const key = String(id)

    const cache = (
      this.getCache(key) || this.createCache(key, {
        expirationTime: this.expirationTime,
        getData: () => this.loadPropsById(id)
      })
    )

    await this.updateCache(cache)

    return this.models.get(id)
  }

  async getAllAsync(): Promise<T[]> {
    return this.getByParamsAsync()
  }

  getOneByParams(filterProps, uploadProps?) {
    return this.getByParams(filterProps, uploadProps)[0]
  }
  async getOneByParamsAsync(filterProps, uploadProps?) {
    const rows = await this.getByParamsAsync(filterProps, uploadProps)
    return rows[0]
  }

  async getByParamsAsync(modelProps?: Partial<T["props"]>, loadProps?: { [key: string]: any }): Promise<T[]> {
    const key = JSON.stringify(loadProps || modelProps)

    const cache = (
      this.getCache(key) ||
      this.createCache(key, {
        expirationTime: this.expirationTime,
        getData: () => this.loadPropsByParams(loadProps || modelProps)
      })
    )

    await this.updateCache(cache)

    return this.filterByParams(modelProps)
  }

  async add(props: TNewProps): Promise<T> {
    const newProps = await this._add(props)
    return this.updatedOrCreateModels([newProps])[0]
  }

  @action
  async addBatch(newItemsProps: TNewProps[]): Promise<T[]> {
    const itemsProps: T["props"][] = []

    for (const newItemProps of newItemsProps) {
      itemsProps.push(
        await this._add(newItemProps)
      )
    }

    return this.updatedOrCreateModels(itemsProps)
  }

  async edit(id: T["id"], props: TNewProps): Promise<T> {
    const newProps = await this._edit(id, props)

    return this.updatedOrCreateModels([newProps])[0]
  }

  async delete(id: T["id"]): Promise<any> {
    await this._delete(id)
    this.models.delete(id)
  }

  getModels(): T[] {
    return Array.from(
      this.models.values()
    )
  }

  @action
  addToCache(props: T["props"]) {
    const item = new this.Item(props)
    const key = String(props.id)

    this.models.set(item.id, item)

    this.getCache(key) || this.createCache(key, {
      expirationTime: this.expirationTime,
      getData: () => this.loadPropsById(props.id)
    })

    return item
  }
}


type CacheProps<T> = {
  expirationTime?: number,
  getData: () => Promise<T>
}

class Cache<T> {
  constructor(private props: CacheProps<T>) {
    this.props = props

    // makeObservable(this)
  }

  private promise?: Promise<T>
  private loadDate?: Date

  get isExpired(): boolean {
    if (this.promise === undefined) return true
    if (this.loadDate === undefined) return true
    if (this.props.expirationTime === undefined) return false

    return isAfter(new Date(), add(this.loadDate, { seconds: this.props.expirationTime || 0 }))
  }

  async update(): Promise<T> {
    if (this.isExpired || !this.promise) {
      this.promise = this.loadData()

      return this.promise
    }

    return this.promise
  }

  async loadData(): Promise<T> {
    this.loadDate = new Date()
    const value = await this.props.getData()
    return value
  }
}