import { Cache } from "./Cache"

export class ApiCache<T> implements ApiCache<T> {
  constructor(private props: { name: string }) {
    this.props = props

    this.cache = new Cache(props)
  }

  private cache: Cache

  async put(url: string, data: T[]) {
    return this.cache.put(url, new Response(JSON.stringify(data)))
  }

  async get(url: string) {
    const response = await this.cache.get(url)
    if (!response) return []

    const data: T[] = await response.json()

    return data
  }
}