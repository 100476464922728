import { action, computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EPages } from "../../models/Pages";
import { EPanelSection } from "../../models/ViewState";
import { RemarksPageViewModel } from "../RemarksPageViewModel/RemarksPageViewModel";
import { StatusPageViewModel } from "../StatusPageViewModel/StatusPageViewModel";
import { LevelsFilterViewModel } from "../LevelsFilterViewModel/LevelsFilterViewModel";
import { EBimPanelContent, IBimPanelViewModel } from "./interface";
import { PrescriptionsPageViewModel } from "../PrescriptionsPageViewModel/PrescriptionsPageViewModel";
import { TermsAndVolumesViewModel } from "../TermsAndVolumesViewModel/TermsAndVolumesViewModel";
import { StatisticsPageViewModel } from "../StatisticsPageViewModel/StatisticsPageViewModel";
import { SettingsPageViewModel } from "../SettingsPageViewModel/SettingsPageViewModel";
import { HistoryPageViewModel } from "../HistoryPageViewModel/HistoryPageViewModel";
import { EActions } from "../../usecase/Permissions/Permissions";

export class BimPanelViewModel implements IBimPanelViewModel {
  @observable
  closeDialogOpened = false

  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  get isMobile() {
    return this.props.layers.usecases.device.isMobile
  }

  @computed
  get shortModeActive() {
    return this.props.layers.usecases.ui.interfaceDataRepository.get()?.shortBimPanel || false
  }

  @action
  switchShortModeActive() {
    this.props.layers.usecases.ui.interfaceDataRepository.patch({
      shortBimPanel: !this.shortModeActive
    })
  }

  @action
  onCloseDialog() {
    this.closeDialogOpened = false
  }

  exit() {
    this.props.layers.usecases.selectedElements.clearSelection()
    this.props.layers.usecases.viewState.setSelectedPage(EPages.main)
  }

  @action
  onExit() {
    this.closeDialogOpened = true
  }

  private sectionsDic = new Map([
    [EBimPanelContent.storeys, {
      name: "Этаж"
    }],
    [EBimPanelContent.status, {
      name: "Статус"
    }],
    [EBimPanelContent.remarks, {
      name: "Замечания"
    }],
    [EBimPanelContent.prescriptions, {
      name: "Предписания"
    }],
    [EBimPanelContent.planfact, {
      name: "Статистика"
    }],
    [EBimPanelContent.terms_and_volumes, {
      name: "Сроки и объемы"
    }],
    [EBimPanelContent.history, {
      name: "История"
    }],
    [EBimPanelContent.settings, {
      name: "Настройки"
    }],
  ]);

  @computed
  get sections() {
    const allowedSectionCodes: EBimPanelContent[] = []

    allowedSectionCodes.push(...[
      EBimPanelContent.storeys,
      EBimPanelContent.status,
    ])

    if (this.props.layers.usecases.permissions.actionIsAllowed(EActions.showRemarks)) {
      allowedSectionCodes.push(
        EBimPanelContent.remarks,
      )
    }

    if (this.props.layers.usecases.permissions.actionIsAllowed(EActions.showPrescriptions)) {
      allowedSectionCodes.push(
        EBimPanelContent.prescriptions,
      )
    }

    allowedSectionCodes.push(...[
      EBimPanelContent.planfact,
      EBimPanelContent.terms_and_volumes,
    ])

    if (this.props.layers.usecases.permissions.actionIsAllowed(EActions.showHistory)) {
      allowedSectionCodes.push(
        EBimPanelContent.history,
      )
    }

    allowedSectionCodes.push(...[
      EBimPanelContent.settings,
    ])

    return allowedSectionCodes.map(section => ({
      name: this.sectionsDic.get(section)?.name || "",
      code: section,
      selected: this.content === section,
      onClick: () => this.setContent(section),
      count: section === EBimPanelContent.remarks ? this.remarksCount : section === EBimPanelContent.prescriptions ? this.prescriptionsCount : undefined
    }))
  }

  @computed
  get remarksCount(): string[] {
    const remarks = this.props.layers.usecases.remarks.selectedBimsRemarks
    const notClosedRemarks = this.props.layers.usecases.remarks.getNotClosedRemarks(remarks)
    return [String(notClosedRemarks.length), String(remarks.length)]
  }

  @computed
  get prescriptionsCount() {
    const prescriptions = this.props.layers.usecases.prescriptions.prescriptionsForSelectedBims
    const notClosedPrescriptions = this.props.layers.usecases.prescriptions.getNotClosedPrescriptions(prescriptions)
    return [String(notClosedPrescriptions.length), String(prescriptions.length)]
  }

  @computed
  get content() {
    switch (this.props.layers.usecases.viewState.props.panelSection) {
      case EPanelSection.storeys:
        return EBimPanelContent.storeys
      case EPanelSection.status:
        return EBimPanelContent.status
      case EPanelSection.remarks:
        return EBimPanelContent.remarks
      case EPanelSection.prescriptions:
        return EBimPanelContent.prescriptions
      case EPanelSection.planfact:
        return EBimPanelContent.planfact
      case EPanelSection.history:
        return EBimPanelContent.history
      case EPanelSection.settings:
        return EBimPanelContent.settings
      case EPanelSection.terms_and_volumes:
        return EBimPanelContent.terms_and_volumes
      default:
        return EBimPanelContent.storeys
    }
  }

  @computed
  get historyPageViewModel() {
    return new HistoryPageViewModel(this.props)
  }

  @computed
  get prescriptionsPageViewModel() {
    return new PrescriptionsPageViewModel(this.props)
  }

  @computed
  get statisticsPageViewModel() {
    return new StatisticsPageViewModel(this.props)
  }

  @computed
  get statusPageViewModel() {
    return new StatusPageViewModel(this.props)
  }

  @computed
  get remarksPageViewModel() {
    return new RemarksPageViewModel(this.props)
  }

  @computed
  get levelsFilterViewModel() {
    return new LevelsFilterViewModel(this.props)
  }

  @computed
  get termsAndVolumesViewModel() {
    return new TermsAndVolumesViewModel(this.props)
  }

  @computed
  get settingsPageViewModel() {
    return new SettingsPageViewModel(this.props)
  }

  @computed
  get contentOpen() {
    return this.props.layers.usecases.viewState.props.panelOpened
  }

  setContent(e: EBimPanelContent) {
    const section = this.getSection(e)
    if (section === this.props.layers.usecases.viewState.props.panelSection) {
      this.props.layers.usecases.viewState.setPanelOpened(
        !this.props.layers.usecases.viewState.props.panelOpened
      )
    } else {
      this.props.layers.usecases.viewState.setPanelSection(section)
      this.props.layers.usecases.viewState.setPanelOpened(true)
    }
  }

  private getSection(e: EBimPanelContent): EPanelSection {
    switch (e) {
      case EBimPanelContent.status:
        return EPanelSection.status
      case EBimPanelContent.storeys:
        return EPanelSection.storeys
      case EBimPanelContent.settings:
        return EPanelSection.settings
      case EBimPanelContent.remarks:
        return EPanelSection.remarks
      case EBimPanelContent.prescriptions:
        return EPanelSection.prescriptions
      case EBimPanelContent.planfact:
        return EPanelSection.planfact
      case EBimPanelContent.terms_and_volumes:
        return EPanelSection.terms_and_volumes
      case EBimPanelContent.history:
        return EPanelSection.history
      default:
        return EPanelSection.storeys
    }
  }
}