import { computed, makeObservable } from "mobx";
import { Company, ECompanyScope, TCompanyProps } from "../../models/Company";
import { TProjectProps } from "../../models/Project";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { ICompaniesRepository } from "./interface";

type TRestProps = {
  id: number
  bucket_name: unknown
  logo: unknown
  name: string
  projects: TProjectProps["id"][]
  is_internal: boolean
  template: 1 | 2
  scope: "geoworks" | "monolith" | "none" | "control"
}

const ScopeDictionary = {
  "geoworks": ECompanyScope.geoworks,
  "monolith": ECompanyScope.monolith,
  "control": ECompanyScope.control,
  "control_contract": ECompanyScope.controlContract,
}

export class RestCompaniesRepository implements ICompaniesRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.offlineRepository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: Company,
      serialyze: this.serialyze
    })

    makeObservable(this)
  }

  offlineRepository: RestOfflineReadOnlyRepository<Company, TRestProps>

  @computed
  get path() {
    return `${this.props.host}api/core/company/`
  }

  getById(id) {
    return this.offlineRepository.getById(id)
  }

  getAll() {
    return this.offlineRepository.getAll()
  }

  getContractors() {
    return this.offlineRepository.getByParams({ isContractor: true }, {})
  }

  getForProject(id) {
    return this.getAll().filter(c => c.hasProject(id))
  }

  serialyze(p: TRestProps): TCompanyProps {
    return {
      id: p.id,
      name: p.name,
      scope: ScopeDictionary[p.scope] || ECompanyScope.none,
      projects: p.projects,
      isInternal: p.is_internal,
      prescriptionType: p.template
    }
  }
}