import { action, makeObservable, observable } from "mobx"
import { ILayers } from "../../core/Layers/interface"
import { TUploadFileProps } from "../../models/UploadFile"
import { SetViewModel } from "../SimpleViewModel/SetViewModel"
import { IUploadButtonViewModel } from "./interface"

export class UploadButtonViewModelV2 implements IUploadButtonViewModel {
  constructor(private props: { layers: ILayers, files: TUploadFileProps["id"][] }) {
    this.props = props

    this.files = new SetViewModel(props.files)

    makeObservable(this)
  }

  @observable
  files: SetViewModel<TUploadFileProps["id"]>

  @observable
  loading = false

  @action
  async onChange(files: FileList | null) {
    this.loading = true
    const user = this.props.layers.repositories.authRepository.getUser()

    if (user && files) {
      //@ts-ignore
      for (const file of files) {
        const result = await this.readFile(file)

        if (result) {
          const uploadFile = await this.props.layers.repositories.uploadFilesRepository.add({
            user: user.id,
            name: file.name,
            file: result
          })

          this.files.add(uploadFile.id)
        }
      }
    }

    this.loading = false
  }

  private async readFile(file: File) {
    return new Promise<string>((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(String(fr.result))
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }
}