const reTryStatusCodes = new Set([503])

const getDelayMS = (reTry: number) => {
  return (reTry - 1) * 500
}

const delay = (ms: number) => {
  if (ms === 0) return

  return new Promise(res => setTimeout(res, ms))
}

export const loadWithReTry = async (requestInfo: RequestInfo, requestInit: RequestInit, props: { maxTry: number }) => {
  let response: Response | undefined = undefined
  let reTry = 0
  const maxTry = props.maxTry

  while (true) {
    reTry++
    if (reTry > maxTry) break

    await delay(getDelayMS(reTry))

    try {
      response = await fetch(requestInfo, requestInit)

      if (reTryStatusCodes.has(response.status)) {
        if (reTry === maxTry) break
        continue
      }
      break
    } catch (error) {
      if (!response) {
        // don't retry
        break
        // if (reTry === maxTry) break
        // continue
      }

      break
    }
  }

  return response
}

// export class SmartRequest {
//   constructor(requestInfo: RequestInfo, requestInit: RequestInit) {
//     this.requestInfo = requestInfo
//     this.requestInit = requestInit
//   }

//   requestInfo: RequestInfo
//   requestInit: RequestInit

//   private reTryStatusCodes = new Set(reTryStatusCodes)

//   private getDelayMS(reTry: number) {
//     return (reTry - 1) * 500
//   }

//   delay(ms: number) {
//     if (ms === 0) return

//     return new Promise(res => setTimeout(res, ms))
//   }

//   async loadWithTryCache() {
//     let response: Response | undefined

//     try {
//       response = await fetch(this.requestInfo, this.requestInit)
//     } catch (error) {

//     }

//     return response
//   }

//   async loadWithReTry(maxTry: number) {
//     let response: Response | undefined = undefined
//     let reTry = 0

//     while (true) {
//       reTry++
//       if (reTry > maxTry) break

//       await this.delay(this.getDelayMS(reTry))

//       try {
//         response = await fetch(this.requestInfo, this.requestInit)

//         if (this.reTryStatusCodes.has(response.status)) {
//           if (reTry === maxTry) break
//           continue
//         }
//         break
//       } catch (error) {

//         if (!response) {
//           if (reTry === maxTry) break
//           continue
//         }

//         break
//       }
//     }

//     return response
//   }
// }