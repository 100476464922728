import { action, computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { ITermsAndVolumesViewModel } from "./interface";
import { EWindowSelectStage } from "../../usecase/SelectedElements/SelectedElements";
import { PanelSelectorMultiAllViewModel } from "../PanelSelectorViewModel/PanelSelectorMultiAllViewModel";
import { CheckboxWithLabelViewModelOverSimpleViewModel } from "../CheckboxViewModel/CheckboxWithLabelViewModelOverSimpleViewModel";
import { EOrientations } from "../PanelDropdownMenuViewModel/interface";

export class TermsAndVolumesViewModel implements ITermsAndVolumesViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  onBack() {
    this.props.layers.usecases.viewState.setPanelOpened(false)
  }

  @action
  onClickPlainMode = () => {
    this.props.layers.usecases.selectedElements.clearSelection()
    this.props.layers.usecases.selectedElements.setWindowStage(EWindowSelectStage.none)

    this.props.layers.usecases.planningMode.active.switch()
  }

  @computed
  get storeysSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      defaultValue: this.props.layers.usecases.selectedStoreys.selectedStoreysArray,
      options: this.props.layers.usecases.selectedStoreys.storeys
        .map(storey => ({
          value: storey.id,
          label: `Этаж ${storey.name}`,
        })),
      label: "Этажи",
      orientation: EOrientations.right,
      onChange: () => {
        this.props.layers.usecases.selectedStoreys.setSelectedStorey(
          this.storeysSelectorViewModel?.values || []
        )
      }
    })
  }

  @computed
  get sectionsSelectorViewModel() {
    if (this.props.layers.usecases.selectedSections.sections.length === 0) return

    return new PanelSelectorMultiAllViewModel({
      defaultValue: this.props.layers.usecases.selectedSections.selectedSectionsArray,
      options: this.props.layers.usecases.selectedSections.sections
        .map(section => ({
          value: section.id,
          label: section.name,
        })),
      label: "Секция",
      orientation: EOrientations.right,
      onChange: () => {
        this.props.layers.usecases.selectedSections.setSelectedSections(
          this.sectionsSelectorViewModel?.values || []
        )
      }
    })
  }

  @computed
  get workStatusSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      defaultValue: this.props.layers.usecases.selectedStatuses.selectedStatusesArray,
      options: this.props.layers.usecases.selectedStatuses.activeStatusDictionaryOptions
        .map(statusDictionary => ({
          value: statusDictionary.id,
          label: statusDictionary.name,
          group: this.props.layers.repositories.workTypesRepository.getById(statusDictionary.workType)?.fullName
        })),
      label: "Статус работ",
      orientation: EOrientations.right,
      onChange: () => {
        this.props.layers.usecases.selectedStatuses.setStatuses(
          this.workStatusSelectorViewModel?.values || []
        )
      }
    })
  }

  @computed
  get workTypeSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      defaultValue: Array.from(this.props.layers.usecases.workTypes.selectedWorkTypes.values()),
      options: this.props.layers.usecases.workTypes.workTypes
        .map(workType => ({
          value: workType.id,
          label: workType.fullName,
        })),
      label: "Тип работ",
      orientation: EOrientations.right,
      onChange: () => {
        this.props.layers.usecases.workTypes.setSelectedWorkTypes(
          this.workTypeSelectorViewModel.values
        )
      }
    })
  }

  @computed
  get painByDateCheckboxViewModel() {
    return new CheckboxWithLabelViewModelOverSimpleViewModel({
      label: "Раскрасить по дате",
      simpleViewModel: this.props.layers.usecases.planningMode.painByDateViewModel
    })
  }

  @computed
  get expiredDateCheckboxViewModel() {
    return new CheckboxWithLabelViewModelOverSimpleViewModel({
      label: "Просроченная дата",
      simpleViewModel: this.props.layers.usecases.planningMode.expiredDateViewModel
    })
  }

  @computed
  get planningDateViewModel() {
    return this.props.layers.usecases.termsAndVolumes.planningDateViewModel
  }

  @computed
  get isPlanningModeActive() {
    return this.props.layers.usecases.planningMode.active.value
  }
}