import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { CustomPropertyPanelViewModel } from "../CustomPropertyPanelViewModel/CustomPropertyPanelViewModel";
import { HideNodesViewModel } from "../HideNodesViewModel/HideNodesViewModel";
import { InversionExtensionViewModel } from "../InversionExtensionViewModel/InversionExtensionViewModel";
import { ModelsLoaderViewModel } from "../ModelsLoaderViewModel/ModelsLoaderViewModel";
import { ModelsSelectorViewModel } from "../ModelsSelectorViewModel/ModelsSelectorViewModel";
import { PaintElementsViewModel } from "../PaintElementsViewModel/PaintElementsViewModel";
import { RightViewerPanelViewModel } from "../RightViewerPanelViewModel/RightViewerPanelViewModel";
import { ViewerViewModel } from "../ViewerViewModel/ViewerViewModel";
import { WindowSelectorViewModel } from "../WindowSelectorViewModel/WindowSelectorViewModel";
import { IBimViewerViewModel } from "./interface";

export class BimViewerViewModel implements IBimViewerViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props;

    makeObservable(this);
  }

  @computed
  get viewerViewModel() {
    return new ViewerViewModel(this.props);
  }

  @computed
  get modelsLoaderViewModel() {
    return new ModelsLoaderViewModel(this.props);
  }

  @computed
  get modelsSelectorViewModel() {
    return new ModelsSelectorViewModel(this.props);
  }

  @computed
  get rightViewerPanelViewModel() {
    return new RightViewerPanelViewModel(this.props);
  }

  @computed
  get windowSelectorViewModel() {
    return new WindowSelectorViewModel(this.props);
  }

  @computed
  get paintElementsViewModel() {
    return new PaintElementsViewModel(this.props);
  }

  @computed
  get hideNodesViewModel() {
    return new HideNodesViewModel(this.props);
  }

  @computed
  get customPropertyPanelViewModel() {
    return new CustomPropertyPanelViewModel(this.props)
  }

  @computed
  get inversionExtensionViewModel() {
    return new InversionExtensionViewModel(this.props)
  }
}
