import { computed, makeObservable } from "mobx"
import { IAuthRepository } from "../AuthRepository/interface"
import { IPDFGenerator } from "./interface"
import { toSvg } from "dom-to-image";
export class PDFGenerator implements IPDFGenerator {
  constructor(private props: {
    host: string
    authRepository: IAuthRepository,
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get headers() {
    return this.props.authRepository.getAuthHeaders()
  }

  async getPDF(html: string) {
    const response = await fetch(`${this.props.host}`, {
      method: "POST",
      body: JSON.stringify({ string: html }),
      headers: {
        'Content-Type': 'application/json',
        ...this.headers
      }
    })

    if (response.status !== 200) throw new Error()

    return response.arrayBuffer()
  }

  async downloadPDF(element: HTMLElement, name: string) {
    const dataUrl = await toSvg(element)

    const svg = dataUrl
      .replace("data:image/svg+xml;charset=utf-8,", "")
      .replace(/<style>.+<\/style>/, "")
      .replace(/<\/foreignObject.+/, "")
      .replace(/.+<foreignObject[^>]+>/, "")


    const html = `<!DOCTYPE html><html><head><meta charset="utf-8"></head><body style='margin: 0;display:flex;justify-content: center;'>${svg}</body></html>`;
    const data = await this.getPDF(html)

    const src = URL.createObjectURL(new Blob([data], {
      type: "application/pdf"
    }))

    const a = document.createElement('a')

    a.setAttribute('href', src)
    a.setAttribute('download', `${name}.pdf`)
    a.setAttribute('target', '_blank')
    a.click()
    a.remove()
  }
}