import { computed, makeObservable } from "mobx";
import { BaseModel } from "./BaseModel";
import { TBimProps } from "./Bim";
import { Entity } from "./Entity";
import { TUserProps } from "./User";

export enum EActionTarget {
  remark = "remark",
  prescription = "prescription",
  bimElement = "bimElement",
  bimStatusHistory = "bimStatusHistory",
  unknown = "unknown"
}

export enum EActionType {
  create = "create",
  edit = "edit",
  delete = "delete",
  unknown = "unknown"
}

type TActionData<T extends EActionTarget, K extends EActionType, P = any> = {
  target: T
  type: K
  props: P
}

type TActions<T extends EActionTarget, M extends BaseModel> =
  TActionData<T, EActionType.create, M> |
  TActionData<T, EActionType.edit, M> |
  TActionData<T, EActionType.delete, M>

export type TActionProps = {
  bim: TBimProps["id"]
  user: TUserProps["id"]
  data:
  TActions<EActionTarget.remark, BaseModel> |
  TActions<EActionTarget.prescription, BaseModel> |
  TActions<EActionTarget.bimElement, BaseModel> |
  TActions<EActionTarget.bimStatusHistory, BaseModel> |
  TActionData<EActionTarget.unknown, EActionType.unknown>
  createdAt: string
} & BaseModel

export class Action extends Entity<TActionProps> {
  constructor(props) {
    super(props)

    makeObservable(this)
  }

  get bim() {
    return this.props.bim
  }

  get type() {
    return this.props.data.type
  }

  get target() {
    return this.props.data.target
  }

  @computed
  get targetID(): string | number {
    const int = parseInt(this.props.data.props.id)
    return isNaN(int) ? this.props.data.props.id : int
  }

  get user() {
    return this.props.user
  }

  @computed
  get isUnknown() {
    return (this.target === EActionTarget.unknown) || (this.type === EActionType.unknown)
  }

  @computed
  get createdAt() {
    return new Date(this.props.createdAt)
  }
}