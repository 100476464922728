import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, {
    memo,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";

const useClasses = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        // overflow: "hidden"
    },
    padding: {
        padding: "0 15px",
    },
    actionGroup: {
        zIndex: 1,
        display: "flex",
        lineHeight: "44px",
        cursor: "pointer",
        userSelect: "none",

        "&:hover": {
            backgroundColor: "#f5f5f5"
        }
    },
    disabled: {
        cursor: "initial",
        "&:hover": {
            backgroundColor: "inherit"
        }
    },
    label: {
        flex: 1,

        fontWeight: "normal",
        fontSize: "12px",
    },
    action: {
        fontWeight: "bold",
        fontSize: "12px",
        color: "#AAAAA9"
    },
    value: {
        padding: 10,
        overflow: "auto",
        lineHeight: "22px",
        height: 80,
        borderRadius: 8,
        backgroundColor: "#F5F5F5",
        fontSize: "12px",
        border: "1px solid #0000",

        "&:empty:before": {
            content: "attr(placeholder)",
            color: "#0003"
        },

        "&:focus": {
            border: "1px solid #AAAAA9",
            outline: "none"
        },
    },
    drawlerWrapper: {
        overflow: "hidden"
    },
    drawler: {
        marginTop: -82,
        transition: "margin-top 0.5s ease"
    },
    drawlerOpen: {
        marginTop: 0
    }
}));

function PanelCommentInput(props) {
    const {
        value,
        label,
        placeholder,
        disabled,
        onChange
    } = props;

    const classes = useClasses(props);
    const [editModeEnable, setEditModeEnable] = useState(false);

    const showComment = (editModeEnable || value) && true;

    const inputRef = useRef();

    const onInputHandler = useCallback(onChange ? e => {
        onChange(e.target.innerText)
    } : null, [onChange]);

    const onClick = useCallback(!disabled ? e => {
        if (showComment) {
            onChange("");
        } else {
            inputRef.current.focus()
        }
        setEditModeEnable(!showComment);
    } : null, [showComment, disabled]);

    useEffect(() => {
        if (inputRef.current && value !== inputRef.current.innerText) {
            inputRef.current.innerText = value ? value : ""
        }
    }, [value]);

    const onFocus = useCallback(() => setEditModeEnable(true), []);
    const onKeyDown = useCallback(e => e.keyCode === 13 && e.preventDefault(), []);

    return (
        <div className={classes.root}>
            <div className={clsx(classes.actionGroup, classes.padding, {
                [classes.disabled]: disabled
            })} onClick={onClick}>
                <div className={classes.label}>{label}</div>
                {
                    !disabled ? (
                        <div className={classes.action}>{showComment ? "очистить" : "добавить"}</div>
                    ) : null
                }
            </div>
            <div className={clsx(classes.drawlerWrapper, classes.padding)}>
                <div
                    className={clsx(classes.drawler, {
                        [classes.drawlerOpen]: showComment
                    })}
                >
                    <div
                        className={classes.value}
                        ref={inputRef}
                        contentEditable={!disabled}
                        placeholder={placeholder}
                        title={value}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        onInput={onInputHandler}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(PanelCommentInput)