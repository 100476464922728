import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IUploadFilesListViewModel } from "../../view-model/UploadFilesListViewModel/interface"
import SimpleButton from "../../components/Buttons/SimpleButton";
import clsx from "clsx";
import Carousel, { Modal, ModalGateway } from 'react-images';

const useClasses = makeStyles(theme => ({
  root: {

  },
  file: {
    display: "flex",
    alignItems: "center",

    "&:not(:last-child)": {
      marginBottom: 20
    },
  },
  header: {
    fontSize: "22px",
    fontWeight: "bold",
    lineHeight: "54px"
  },
  table: {
    marginTop: 11,

    display: "grid",
    gridTemplateColumns: "auto auto auto min-content",
    alignItems: "center",
    rowGap: 10,
    fontSize: "12px"
  },
  tableHeader: {
    fontSize: "15px",
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  button: {
    backgroundColor: "#F5F5F5",
    width: 44,
    height: 44,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:not(:last-child)": {
      marginRight: 10
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "end"
  },
  dialogButtons: {
    display: "flex",
    justifyContent: "end",
    marginTop: 20
  },
  closeButton: {
    backgroundColor: "#EE2E2E",
    width: 130,
    color: "white"
  }
}))

interface IUploadFilesListProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IUploadFilesListViewModel
  className?: string
}

const FullScreenIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.83049 10.5763C5.77705 10.5765 5.72409 10.5661 5.67473 10.5456C5.62537 10.5251 5.58059 10.495 5.54304 10.457C5.46686 10.3807 5.42407 10.2773 5.42407 10.1695C5.42407 10.0617 5.46686 9.95831 5.54304 9.88204L14.6115 0.813569H10.1695C10.0616 0.813569 9.95813 0.770711 9.88184 0.694425C9.80555 0.618139 9.7627 0.514674 9.7627 0.406789C9.7627 0.298904 9.80555 0.195438 9.88184 0.119152C9.95813 0.0428664 10.0616 9.20749e-06 10.1695 9.20749e-06H15.5932C15.6734 -0.000533665 15.7519 0.0229407 15.8186 0.0674075C15.8853 0.111874 15.9371 0.175296 15.9674 0.249501C15.9987 0.323881 16.0072 0.405881 15.9917 0.485076C15.9763 0.56427 15.9376 0.63708 15.8807 0.694246L6.11795 10.457C6.0804 10.495 6.03562 10.5251 5.98626 10.5456C5.9369 10.5661 5.88394 10.5765 5.83049 10.5763Z" fill="black" />
    <path d="M15.5933 6.23729C15.4859 6.23588 15.3832 6.19257 15.3072 6.11659C15.2312 6.04061 15.1879 5.93796 15.1865 5.83051V0.40678C15.1865 0.298895 15.2294 0.195429 15.3057 0.119143C15.382 0.0428572 15.4854 0 15.5933 0C15.7012 0 15.8047 0.0428572 15.8809 0.119143C15.9572 0.195429 16.0001 0.298895 16.0001 0.40678V5.83051C15.9987 5.93796 15.9554 6.04061 15.8794 6.11659C15.8034 6.19257 15.7008 6.23588 15.5933 6.23729Z" fill="black" />
    <path d="M14.0149 16H1.98508C1.45861 16 0.953693 15.7909 0.581418 15.4186C0.209142 15.0463 0 14.5414 0 14.0149V1.98508C0 1.45861 0.209142 0.953693 0.581418 0.581418C0.953693 0.209142 1.45861 0 1.98508 0H6.91525C7.02314 0 7.12661 0.0428572 7.20289 0.119143C7.27918 0.195429 7.32203 0.298895 7.32203 0.40678C7.32203 0.514664 7.27918 0.61813 7.20289 0.694416C7.12661 0.770702 7.02314 0.813559 6.91525 0.813559H1.98508C1.67438 0.813559 1.37639 0.936988 1.15669 1.15669C0.936988 1.37639 0.813559 1.67438 0.813559 1.98508V14.0149C0.813559 14.3256 0.936988 14.6236 1.15669 14.8433C1.37639 15.063 1.67438 15.1864 1.98508 15.1864H14.0149C14.3256 15.1864 14.6236 15.063 14.8433 14.8433C15.063 14.6236 15.1864 14.3256 15.1864 14.0149V9.08475C15.1864 8.97686 15.2293 8.87339 15.3056 8.79711C15.3819 8.72082 15.4853 8.67797 15.5932 8.67797C15.7011 8.67797 15.8046 8.72082 15.8809 8.79711C15.9571 8.87339 16 8.97686 16 9.08475V14.0149C16 14.5414 15.7909 15.0463 15.4186 15.4186C15.0463 15.7909 14.5414 16 14.0149 16Z" fill="black" />
  </svg>
)

const DownloadIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.83325 8.33325L9.99992 12.4999L14.1666 8.33325" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 12.5V2.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const DeleteIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2544 17L3.25439 2" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.00017 16.7542L17.5088 2.24561" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const styles = {
  blanket: base => ({
    ...base,
    zIndex: 1399,
  }),
  positioner: base => ({
    ...base,
    zIndex: 1400,
  })
}

export const UploadFilesList = observer((props: IUploadFilesListProps) => {
  const classes = useClasses(props)

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={classes.header}>
        Файлы
      </div>

      <div className={classes.table}>
        <div className={classes.tableHeader}>Имя</div>
        <div className={classes.tableHeader}>Автор</div>
        <div className={classes.tableHeader}>Дата</div>
        <div className={classes.tableHeader}></div>
        {
          props.viewModel.files.map(file => (
            <>
              <div className={classes.name}>{file.name}</div>
              <div>{file.author}</div>
              <div>{file.date}</div>
              <div className={classes.buttons}>
                <SimpleButton className={classes.button} onClick={file.onOpen}>
                  {FullScreenIcon}
                </SimpleButton>
                <SimpleButton className={classes.button} onClick={file.onDownload}>
                  {DownloadIcon}
                </SimpleButton>
                {
                  file.allowDelete ? (
                    <SimpleButton className={classes.button} onClick={file.onDelete}>
                      {DeleteIcon}
                    </SimpleButton>
                  ) : null
                }
              </div>
            </>
          ))
        }
      </div>
      <div className={classes.dialogButtons}>
        <SimpleButton className={classes.closeButton} onClick={props.viewModel.onClose}>
          Закрыть
        </SimpleButton>
      </div>
      {
        props.viewModel.modalGalleryViewModel.opened ? (
          <ModalGateway>
            <Modal
              onClose={props.viewModel.modalGalleryViewModel.onClose}
              styles={styles}
            >
              <Carousel
                views={props.viewModel.modalGalleryViewModel.views}
                currentIndex={props.viewModel.modalGalleryViewModel.startIndex}
              />
            </Modal>
          </ModalGateway>
        ) : null
      }
    </div>
  )
})