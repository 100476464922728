import { makeStyles } from "@material-ui/core"

export const useTableClasses = makeStyles(theme => ({
  table: {
    borderSpacing: "0 10px",
    borderCollapse: "unset",
    color: "#000000",
  },
  tableFixed: {
    tableLayout: "fixed"
  },
  headerCell: {
    border: "none",
    fontSize: "12px",
    textAlign: "center",
    padding: "0 10px",
    fontWeight: "bold",

    "&:first-child": {
      paddingLeft: 20,
    },
  },
  bodyRow: {
    height: 64,
    backgroundColor: "#FAF9F9",

    "& >:first-child": {
      paddingLeft: 20,
      borderTopLeftRadius: 14,
      borderBottomLeftRadius: 14,
    },
    "& >:last-child": {
      borderTopRightRadius: 14,
      borderBottomRightRadius: 14,
    }
  },
  bodyCell: {
    fontSize: "12px",
    border: "none",
    padding: 10,
    textAlign: "center"
  },
  boldCell: {
    fontWeight: "bold"
  },
  rightCell: {
    textAlign: "right"
  },
  nowrapCell: {
    whiteSpace: "nowrap"
  },
  widthCell: {
    width: "5%"
  },
  width2Cell: {
    width: "10%",
  },
  width3Cell: {
    width: "15%"
  },
  width5Cell: {
    width: "25%",
  },
  overflowCell: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
}))