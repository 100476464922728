import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { ISettingsPageViewModel } from "../../view-model/SettingsPageViewModel/interface"
import { SliderCheckboxWithLabel } from "../Checkbox/SliderCheckboxWithLabel"
import BackButton from '../../components/Buttons/BackButton';

const useClasses = makeStyles(theme => ({
  root: {
    padding: "100px 0 56px 0",
    margin: "0px 26px 30px 26px"
  },
  margin: {
    marginTop: 10
  },
  button: {
    marginBottom: 20
  },
}))

interface ISettingsPageProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: ISettingsPageViewModel
}

export const SettingsPage = observer((props: ISettingsPageProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      <BackButton className={classes.button} onClick={props.viewModel.onBack} />
      {
        props.viewModel.inversionCheckboxViewModel ?
          <SliderCheckboxWithLabel className={classes.margin} viewModel={props.viewModel.inversionCheckboxViewModel} />
          : null
      }
      {
        props.viewModel.axisCheckboxViewModel ?
          <SliderCheckboxWithLabel className={classes.margin} viewModel={props.viewModel.axisCheckboxViewModel} />
          : null
      }
      {
        props.viewModel.transparentHidingCheckboxViewModel ?
          <SliderCheckboxWithLabel className={classes.margin} viewModel={props.viewModel.transparentHidingCheckboxViewModel} />
          : null
      }
      {
        props.viewModel.hideAdditionalModelsCheckboxViewModel ?
          <SliderCheckboxWithLabel className={classes.margin} viewModel={props.viewModel.hideAdditionalModelsCheckboxViewModel} />
          : null
      }
    </div>
  )
})