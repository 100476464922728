import { TForgeProperties } from "../../models/Forge";
import { ForgeElement } from "../../models/ModelElement/ForgeElement";
import { IForgeRepository } from "../ForgeRepository/interface";
import { IForgeElementsRepository } from "./interface";
import { computedFn } from "mobx-utils";
import { Bim } from "../../models/Bim";
import { IModelElement } from "../../models/ModelElement/interface";

export class ProxyForgeElementsRepository implements IForgeElementsRepository {
  constructor(private props: {
    forgeRepository: IForgeRepository
  }) {
    this.props = props
  }

  getByBim(bim: Bim) {
    if (!bim.urn) return []
    const props = this.props.forgeRepository.getPropertiesByUrn(bim.urn)
    if (!props) return []
    const elementsMap = this.getElementsMapByGuid(props, bim)
    if (!elementsMap) return []

    return Array.from(elementsMap.values())
  }

  async getByBimAsync(bim: Bim) {
    if (!bim.urn) return []
    const props = await this.props.forgeRepository.getPropertiesByUrnAsync(bim.urn)
    if (!props) return []
    const elementsMap = this.getElementsMapByGuid(props, bim)
    if (!elementsMap) return []

    return Array.from(elementsMap.values())
  }

  getById(bim: Bim, id: IModelElement["id"]) {
    if (!bim.urn) return undefined
    const props = this.props.forgeRepository.getPropertiesByUrn(bim.urn)
    if (!props) return
    return this.getElementsMapByGuid(props, bim)?.get(id)
  }

  async getByIdAsync(bim: Bim, id: IModelElement["id"]) {
    if (!bim.urn) return undefined
    const props = await this.props.forgeRepository.getPropertiesByUrnAsync(bim.urn)
    if (!props) return
    return this.getElementsMapByGuid(props, bim)?.get(id)
  }

  getByModelID(bim: Bim, modeID: IModelElement["modelID"]) {
    if (!bim.urn) return undefined
    const props = this.props.forgeRepository.getPropertiesByUrn(bim.urn)
    if (!props) return
    const elementsMap = this.getElementsMapByGuid(props, bim)
    if (!elementsMap) return
    return this.getElementsMapByDbId(elementsMap)?.get(modeID)
  }

  async getByModelIDAsync(bim: Bim, modeID: IModelElement["modelID"]) {
    if (!bim.urn) return undefined
    const props = await this.props.forgeRepository.getPropertiesByUrnAsync(bim.urn)
    if (!props) return
    const elementsMap = this.getElementsMapByGuid(props, bim)
    if (!elementsMap) return
    return this.getElementsMapByDbId(elementsMap)?.get(modeID)
  }

  private getElementsMapByGuid = computedFn((props: TForgeProperties, bim: Bim) => {
    return props.reduce((acc, element) => {
      const forgeElement = new ForgeElement({
        id: element.externalId,
        guid: element.externalId,
        modelID: element.objectid,
        name: element.name,
        properties: element.properties,
        bim: bim.id
      })

      acc.set(forgeElement.id, forgeElement)

      return acc
    }, new Map<any, ForgeElement>())
  }, true)

  private getElementsMapByDbId = computedFn((map: Map<any, ForgeElement>): Map<IModelElement["modelID"], ForgeElement> => {
    return Array.from(
      map.values()
    ).reduce((acc, element) => {
      acc.set(element.modelID, element)
      return acc
    }, new Map<number, ForgeElement>())
  }, true)

  async getByGeometryModelIDAsync(bim: Bim, modelID: IModelElement["modelID"]): Promise<IModelElement | undefined> {
    return this.getByModelIDAsync(bim, modelID)
  }
}
