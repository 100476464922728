import { computed, makeObservable, reaction } from "mobx"
import { IInversionExtensionViewModel } from "../../view-model/InversionExtensionViewModel/interface"
import { Viewer } from "./Viewer"

export class InversionExtension {
  constructor(private props: {
    viewModel: IInversionExtensionViewModel,
    bimViewer: Viewer
  }) {
    this.props = props

    makeObservable(this)

    this.mount()
  }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  @computed
  get inversion() {
    return !this.props.viewModel.inversion
  }

  private mount() {
    const clearReaction1 = reaction(() => this.viewer, (viewer) => {
      if (viewer) {
        const handler = () => {
          viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, handler)
          viewer.navigation.setReverseZoomDirection(this.inversion);
        }
        viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, handler)
      }
    }, {
      fireImmediately: true
    })

    const clearReaction2 = reaction(() => this.inversion, (inversion) => {
      if (this.viewer) {
        this.viewer.navigation.setReverseZoomDirection(inversion);
      }
    }, {
      fireImmediately: true
    })

    this.unmount = () => {
      clearReaction1()
      clearReaction2()
    }
  }

  unmount() { }
}