import { makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Collection } from "../../models/Collection";
import { ERemarkStatus } from "../../models/Remark";
import { IPieChartViewModel } from "../PieChartViewModel/interface";
import { IPieChartWithLegendViewModel } from "./interface";

export class PieChartWithRemarkCountForCollectionsAsync implements IPieChartWithLegendViewModel {
  constructor(private props: {
    layers: ILayers
    collections: Collection[]
    header?: string
    date: Date
  }) {
    this.props = props

    makeObservable(this)
  }

  get header() {
    return this.props.header || ""
  }

  formatValue(value: number, totalValue: number) {
    if (totalValue === 0) return "?"

    return `${(value / totalValue * 100).toFixed(0)}% (${value} шт.)`
  }

  @observable
  pieChartViewModel: IPieChartViewModel = {
    data: []
  }

  @observable
  legends: IPieChartWithLegendViewModel["legends"] = []

  getRandomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`
  }

  async compute() {
    this.legends = []
    this.pieChartViewModel.data = []

    const collections = this.props.collections.map(c => c.id)

    const data = await this.props.layers.repositories.statsRepository.getRemarkCountForCollectionsGroupByCollectionAsync({
      collections,
      lte: this.props.date,
      status: [ERemarkStatus.opened, ERemarkStatus.pending]
    })

    const totalCount = data.reduce((acc, [_, count]) => acc + count, 0)

    for (const [collectionId, count] of data) {
      if (collections.includes(collectionId)) {
        const collection = await this.props.layers.repositories.collectionsRepository.getByIdAsync(collectionId)

        if (collection) {
          const color = this.getRandomColor()

          this.legends.push({
            key: String(collection.id),
            color,
            valueLabel: this.formatValue(count, totalCount),
            label: collection.name
          })

          this.pieChartViewModel.data.push({
            color,
            value: count,
          })
        }
      }
    }
  }
}