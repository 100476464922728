import { computed, makeObservable } from "mobx";
import { TBimProps } from "./Bim";
import { TBimElementProps } from "./BimElement";
import { Entity } from "./Entity";
import { TStatusDictionaryProps } from "./StatusDictionary";

export type TBimStatusProps = {
  id: number | string
} & TNewBimStatusProps

export type TNewBimStatusProps = {
  element: TBimElementProps["id"]
  bim: TBimProps["id"]
  status: TStatusDictionaryProps["id"]
  deadline: string

  // file: null
  // comment: `test-${bimElement.bim}`,
  // deadline: isOnlyWithoutDate ? status.props.deadline || this.props.layers.usecases.planningMode.selectedDeadline.value : this.props.layers.usecases.planningMode.selectedDeadline.value,
  // deadline: this.props.layers.usecases.planningMode.selectedDeadline.value,
  // attachments: []
}

export class BimStatus extends Entity<TBimStatusProps> {
  constructor(props: TBimStatusProps) {
    super(props)

    makeObservable(this)
  }

  get bim() {
    return this.props.bim
  }

  @computed
  get deadline() {
    return new Date(this.props.deadline)
  }

  get element() {
    return this.props.element
  }

  get status() {
    return this.props.status
  }
}