import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EAdditionalStatusDictionaryCodes, StatusDictionary } from "../../models/StatusDictionary";
import { EWorkTypeOrientation, TWorkTypeProps } from "../../models/WorkType";
import { IStatusFilterViewModel } from "./interface";

export class StatusFilterViewModel implements IStatusFilterViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get groups() {
    const groups = new Map<TWorkTypeProps["id"] | undefined, { name: string, onClick(): void, selected: boolean }[]>()
    groups.set(undefined, [
      {
        name: "Работы не начаты",
        onClick: () => this.props.layers.usecases.selectedStatuses.switchStatuses([EAdditionalStatusDictionaryCodes.notStarted]),
        selected: this.props.layers.usecases.selectedStatuses.hasStatuses([EAdditionalStatusDictionaryCodes.notStarted])
      },
      {
        name: "Выполнено с дефектом",
        onClick: () => this.props.layers.usecases.selectedStatuses.switchStatuses([EAdditionalStatusDictionaryCodes.defective]),
        selected: this.props.layers.usecases.selectedStatuses.hasStatuses([EAdditionalStatusDictionaryCodes.defective])
      }
    ])

    for (const statusDictionary of this.props.layers.usecases.selectedStatuses.activeStatusDictionaryOptions) {
      if (groups.has(statusDictionary.workType)) {
        groups.get(statusDictionary.workType)?.push({
          name: statusDictionary.name,
          onClick: () => this.props.layers.usecases.selectedStatuses.switchStatuses([statusDictionary.id]),
          selected: this.props.layers.usecases.selectedStatuses.hasStatuses([statusDictionary.id])
        })
      } else {
        groups.set(statusDictionary.workType, [{
          name: statusDictionary.name,
          onClick: () => this.props.layers.usecases.selectedStatuses.switchStatuses([statusDictionary.id]),
          selected: this.props.layers.usecases.selectedStatuses.hasStatuses([statusDictionary.id])
        }])
      }
    }

    return Array.from(groups.entries()).map(([id, options]) => ({
      name: this.getGroupName(id),
      options
    }))
  }

  private orientationDic = new Map(
    [
      [EWorkTypeOrientation.horizontal, "горизонтальные"],
      [EWorkTypeOrientation.vertical, "вертикальные"],
      [EWorkTypeOrientation.none, ""],
    ]
  )

  getGroupName(id: TWorkTypeProps["id"] | undefined) {
    const workType = id ? this.props.layers.repositories.workTypesRepository.getById(id) : undefined
    const orientationName = this.orientationDic.get(workType?.orientation || EWorkTypeOrientation.none)
    return workType ? `${workType.name} ${orientationName ? `(${orientationName})` : ""}` : ""
  }
}