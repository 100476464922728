import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Bim } from "../../models/Bim";
import { Target } from "../../models/Target";
import { InputViewModel } from "../InputViewModel/InputViewModel";
import { PanelSelectorViewModel } from "../PanelSelectorViewModel/PanelSelectorViewModel";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { IStoreyFormViewModel } from "./interface";

export class StoreyFormViewModel implements IStoreyFormViewModel {
  constructor(private props: { layers: ILayers, onClose(): void, storey: { name: string, bim: Set<Bim> } }) {
    this.props = props

    makeObservable(this)
  }

  statusWrapperViewModel = new StatusWrapperViewModel()

  @computed
  get targetSelectorViewModel() {
    const targets = this.targets

    return new PanelSelectorViewModel<number | string, false>({
      value: targets.length === 1 ? targets[0].id : undefined,
      options: targets.map(target => ({
        value: target.id,
        label: target.name
      }))
    })
  }

  @computed
  get targets() {
    const targets = new Set<Target>()

    for (const bim of this.props.storey.bim) {
      const target = this.props.layers.repositories.targetsRepository.getById(bim.target)

      if (target) {
        targets.add(target)
      }
    }

    return Array.from(targets)
  }

  @computed
  get selectedMark(): string {
    if (!this.targetSelectorViewModel.value) return ""
    const targetNote = this.props.layers.repositories.targetNoteRepository.getByTargetId(this.targetSelectorViewModel.value)

    if (!targetNote) return ""
    return this.props.layers.usecases.storeyNotes.getStoreyMarkInTargetNote({ targetNote, storey: this.props.storey.name }) || ""
  }

  @computed
  get noteInputViewModel() {
    return new InputViewModel({
      value: this.selectedMark
    })
  }

  onCancel = () => {
    this.props.onClose()
  }

  get saveButtonViewModel() {
    const target = this.targetSelectorViewModel.value
    if (!target) return {
      disabled: true,
      onClick: console.log
    }

    return {
      onClick: async () => {
        this.statusWrapperViewModel.load()
        try {
          await this.props.layers.usecases.storeyNotes.saveLevelMarkForTarget({
            target: target,
            storey: this.props.storey.name,
            mark: this.noteInputViewModel.value
          })

          this.props.onClose()
          this.statusWrapperViewModel.success()
        } catch (error) {
          this.statusWrapperViewModel.fail("произошла ошибка")
        }
      }
    }
  }
}