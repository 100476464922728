import { computed, makeObservable } from "mobx";
import { TBaseModel } from "./BaseModel";
import { Entity } from "./Entity";
import { TMaterialProps } from "./Material";
import { TWorkTypeProps } from "./WorkType";

export type TStatusDictionaryProps = {
  name: string
  // "service_name": "",
  workType: TWorkTypeProps["id"]
  order: number
  // "comment": "",
  materials: TMaterialProps["id"][],
  color?: string
  isBlockedByRemarks: boolean
} & TBaseModel

export enum EAdditionalStatusDictionaryCodes {
  notStarted = "notStarted",
  defective = "defective"
}

export class StatusDictionary extends Entity<TStatusDictionaryProps> {
  constructor(props: TStatusDictionaryProps) {
    super(props)

    makeObservable(this)
  }

  get name() {
    return this.props.name
  }

  get workType() {
    return this.props.workType
  }

  get order() {
    return this.props.order
  }

  get color() {
    return this.props.color
  }

  get isBlockedByRemarks() {
    return this.props.isBlockedByRemarks
  }

  @computed
  get materials() {
    return this.props.materials
  }

  hasMaterial(materialId: TMaterialProps["id"]) {
    return this.materials.includes(materialId)
  }
}