import { autorun, computed, makeObservable, reaction } from "mobx";
import { IModelsSelectorViewModel } from "../../view-model/ModelsSelectorViewModel/interface";
import { Viewer } from "./Viewer";
import { ModelLoader } from "./ModelLoader";

export class ModelsSelector {
  constructor(private props: { viewModel: IModelsSelectorViewModel, bimViewer: Viewer, modelLoader: ModelLoader }) {
    this.props = props

    this.mount()
  }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  private mount() {
    const clearReaction1 = reaction(() => this.props.viewModel.urns, (urns) => {
      for (const urn of urns) {
        new ModelSelector({
          ...this.props,
          urn
        })
      }
    }, {
      fireImmediately: true
    })

    const clearReaction2 = reaction(() => this.clickHandler, (clickHandler) => {
      if (clickHandler) {
        clickHandler.handleSingleClick = this.handleSingleClick.bind(this)
        clickHandler.handleDoubleClick = this.handleDoubleClick.bind(this)
        clickHandler.handleSingleTap = this.handleSingleTap.bind(this)
      }
    }, {
      fireImmediately: true
    })

    this.unmount = () => {
      clearReaction1()
      clearReaction2()
    }
  }

  unmount() { }

  @computed
  get impl() {
    return this.viewer?.impl
  }

  @computed
  get clickHandler() {
    //@ts-ignore
    return this.viewer?.clickHandler
  }

  // taken from forge
  private handleSingleClick(event, button) {
    if (this.impl && this.clickHandler) {
      if (button === 0) {
        const multiSelect = event.ctrlKey || event.metaKey | event.shiftKey | event.altKey
        const result = this.getEventResult(event)

        if (multiSelect) {
          if (result) {
            this.props.viewModel.switchSelectedElements([
              [
                //@ts-ignore
                result.model.getSeedUrn(),
                [result.dbId]
              ]
            ])
          }
        } else {
          if (result) {
            this.props.viewModel.selectElements([[
              //@ts-ignore
              result.model.getSeedUrn(),
              [result.dbId]]
            ])
          } else {
            this.props.viewModel.dropSelectedElements()
          }
        }
      }
    }

    return false
  }

  private getEventResult(event) {
    if (!this.impl) return

    const vpVec = this.impl.clientToViewport(event.canvasX, event.canvasY);
    return this.impl.hitTestViewport(vpVec, false);
  }

  private getResultBB(result: Autodesk.Viewing.Private.HitTestResult) {
    if (!this.viewer) return

    const box = new THREE.Box3();
    this.viewer.model.getFragmentList().getWorldBounds(result.fragId, box);
    return box
  }

  private handleDoubleClick(event, button) {
    if (this.impl && this.viewer) {
      const result = this.getEventResult(event)

      if (result) {
        const bb = this.getResultBB(result)

        if (bb) {
          this.viewer.navigation.fitBounds(false, bb)
        }
      } else {
        this.viewer.fitToView();
      }
    }

    return false;
  }

  private async handleSingleTap(event) {
    event.clientX = event.pointers[0].clientX;
    event.clientY = event.pointers[0].clientY;

    if (this.impl && this.clickHandler) {
      const result = this.getEventResult(event)
      if (result) {
        //@ts-ignore
        this.props.viewModel.switchSelectedElements([[result.model.getSeedUrn(), [result.dbId]]])
      } else {
        this.props.viewModel.dropSelectedElements()
      }
    }

    return false
  }
}

class ModelSelector {
  constructor(private props: { viewModel: IModelsSelectorViewModel, bimViewer: Viewer, modelLoader: ModelLoader, urn: string }) {
    this.props = props

    makeObservable(this)

    reaction(() => {
      if (!this.viewer || !this.model) return []
      return this.selectedElements
    }, (newElements, oldElements) => {
      this.select(newElements)
    }, {
      fireImmediately: true
    })
  }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  @computed
  get model() {
    return this.props.modelLoader.getModelByUrn(this.props.urn)
  }

  @computed
  get selectedElements() {
    return this.props.viewModel.getSelectedElementsByUrn(this.props.urn)
  }

  private select(ids: number[]) {
    if (!this.viewer || !this.model) return
    this.viewer.impl.selector.setAggregateSelection([
      {
        model: this.model,
        ids
      }
    ])
  }
}