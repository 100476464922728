import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IFloatingWindowViewModel } from "../../view-model/FloatingWindowViewModel/interface"
import { Rnd } from "react-rnd";

const useClasses = makeStyles(theme => ({
  root: {},
}))

type IFloatingWindowProps = {
  classes?: Partial<ReturnType<typeof useClasses>>
  cancel?: string
  viewModel: IFloatingWindowViewModel
} & React.PropsWithChildren

export const FloatingWindow = observer((props: IFloatingWindowProps) => {
  const classes = useClasses(props)

  return (
    <Rnd
      className={classes.root}
      position={props.viewModel.position}
      cancel={props.cancel}
      size={props.viewModel.size}
      bounds="parent"
      onDragStop={props.viewModel.onDragStop}
      onResizeStop={props.viewModel.onResizeStop}
      enableResizing={props.viewModel.enableResizable}
      disableDragging={props.viewModel.disableDragging}
    >
      {props.children}
    </Rnd>
  )
})