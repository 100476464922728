import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { TNewCollectionProps } from "../../models/Collection";
import { ModelWorkType } from "../../models/ModelWorkType";
import { PanelSelectorMultiViewModel } from "../PanelSelectorViewModel/PanelSelectorMultiViewModel";
import { SimpleViewModel } from "../SimpleViewModel/SimpleViewModel";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { ICollectionFormViewModel } from "./interface";

export class CollectionFormAddViewModel implements ICollectionFormViewModel {
  constructor(private props: {
    layers: ILayers,
    modelWorkType: ModelWorkType,
    consolidated?: boolean
    onClose(): void
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get bimsPanelSelectorViewModel() {
    return new PanelSelectorMultiViewModel({
      defaultValue: [],
      options: this.bimsOptions,
      label: ""
    })
  }

  @computed
  private get bimsOptions() {
    return this.projectBims.map(bim => {
      if (bim.modelWorkType === this.props.modelWorkType.id) return {
        value: bim.id,
        label: bim.name || bim.fileName
      }

      const bimModelWorkType = this.props.layers.repositories.modelWorkTypeRepository.getById(bim.modelWorkType)

      return {
        value: bim.id,
        label: bimModelWorkType ? `${bim.name || bim.fileName} (${bimModelWorkType.name})` : bim.name || bim.fileName
      }
    })
  }

  @computed
  get namePanelInputViewModel() {
    return new SimpleViewModel({
      value: ""
    })
  }

  @computed
  get linkPanelInputViewModel() {
    return new SimpleViewModel({
      value: ""
    })
  }

  @computed
  get saveButtonViewModel() {
    return {
      label: "Добавить",
      disabled: !this.isValid,
      onClick: this.onSave
    }
  }

  statusWrapperViewModel = new StatusWrapperViewModel()

  @computed
  private get selectedProjectId() {
    return this.props.layers.usecases.viewState.props.selectedProjectId
  }

  @computed
  private get projectBims() {
    if (!this.selectedProjectId) return []
    return this.props.layers.usecases.bims.getBimsByProjectId(this.selectedProjectId)
  }

  @computed
  get header() {
    return "Новая модель"
  }

  @computed
  private get isValid() {
    return this.props.layers.usecases.collections.getCollectionIsValid(this.collectionProps)
  }

  @computed
  private get collectionProps(): TNewCollectionProps {
    return {
      name: this.namePanelInputViewModel.value,
      bims: this.bimsPanelSelectorViewModel.values,
      image: "",
      order: 0,
      modelWorkType: this.props.modelWorkType.id,
      consolidated: this.props.consolidated || false,
      link: this.linkPanelInputViewModel.value
    }
  }

  private onSave = async () => {
    try {
      this.statusWrapperViewModel.load()

      await this.props.layers.repositories.collectionsRepository.add(this.collectionProps)
      this.props.onClose()

      this.statusWrapperViewModel.success()
    } catch (error) {
      this.statusWrapperViewModel.fail("Ошибка")
    }
  }
}