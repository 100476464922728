import { IEditStatusFormViewModel } from "../EditStatusFormViewModel/interface";
import { IStatusFilterViewModel } from "../StatusFilterViewModel/interface";
import { IStatusSelectorViewModel } from "../StatusSelectorViewModel/interface";


export enum EActiveComponent {
  filter = "filter",
  form = "form"
}
export interface IStatusPageViewModel {
  statusSelectorViewModel: IStatusSelectorViewModel
  activeComponent: EActiveComponent
  statusFilterViewModel: IStatusFilterViewModel
  editStatusFormViewModel?: IEditStatusFormViewModel
  editStatusDialogViewModel: {
    onClose(): void
  }
  editButtonViewModel?: {
    onClick(): void
  }
  onBack(): void
}