import { computed, makeObservable } from "mobx"
import { IModelElement } from "../../models/ModelElement/interface"
import { IBimsRepository } from "../../repositories/BimsRepository/interface"
import { IModelElementsRepository } from "../../repositories/ModelElementsRepository/interface"
import { Bims } from "../Bims/Bims"
import { HidedElements } from "../HidedElements/HidedElements"
import { EActions, Permissions } from "../Permissions/Permissions"
import { Remarks } from "../Remarks/Remarks"
import { EWindowSelectStage, SelectedElements } from "../SelectedElements/SelectedElements"
import { ViewState } from "../ViewState/ViewState"

export class ForgeElements {
  constructor(private props: {
    hidedElements: HidedElements
    remarks: Remarks
    selectedElements: SelectedElements
    bims: Bims
    bimsRepository: IBimsRepository
    permissions: Permissions
    viewState: ViewState
    modelElementsRepository: IModelElementsRepository
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get allowChangeSelectedElements() {
    if (this.props.remarks.remarkEditing && !this.props.permissions.actionIsAllowed(EActions.editRemark)) return false

    return true
  }

  @computed
  get dropSelectedRemarks() {
    if (this.props.remarks.remarkEditing && !this.props.viewState.props.editRemarkMode) return true

    return false
  }

  async onSelect(props: Set<IModelElement>) {
    if (this.dropSelectedRemarks) this.props.remarks.dropSelectedRemark()

    if (!this.allowChangeSelectedElements) return

    if (this.props.selectedElements.windowStage === EWindowSelectStage.none) {
      await this.props.selectedElements.selectModelElements(
        await this.getValidModelElements(props)
      )
    } else if (this.props.selectedElements.windowStage === EWindowSelectStage.select) {
      await this.props.selectedElements.selectModelElements(
        await this.getValidModelElements(props)
      )
      this.props.selectedElements.setWindowStage(EWindowSelectStage.selected)
    } else {
      await this.props.selectedElements.deselectModelElements(
        props
      )
    }
  }

  async onClear() {
    if (this.dropSelectedRemarks) this.props.remarks.dropSelectedRemark()
    if (!this.allowChangeSelectedElements) return

    await this.props.selectedElements.clearSelection()
  }

  async onSwitch(props: Set<IModelElement>) {
    if (this.dropSelectedRemarks) this.props.remarks.dropSelectedRemark()

    if (!this.allowChangeSelectedElements) return

    await this.props.selectedElements.switchModelElements(
      props
    )
  }

  async getValidModelElements(props: Set<IModelElement>): Promise<Set<IModelElement>> {
    return await this.getNotHidedModelElements(
      await this.getDbIdsForActualModelWorkTypeAsync(props)
    )
  }

  async getDbIdsForActualModelWorkTypeAsync(props: Set<IModelElement>): Promise<Set<IModelElement>> {
    const actualModelWorkTypeId = this.props.bims.selectedModelWorkTypeId
    if (!actualModelWorkTypeId) return props
    const result = new Set<IModelElement>()

    for (const modelElement of props) {
      const bim = await this.props.bimsRepository.getByIdAsync(modelElement.bim)
      if (bim && bim.modelWorkType === actualModelWorkTypeId) result.add(modelElement)
    }

    return result
  }

  async getNotHidedModelElements(props: Set<IModelElement>): Promise<Set<IModelElement>> {
    const result = new Set<IModelElement>()

    for (const modelElement of props) {
      const bim = await this.props.bimsRepository.getByIdAsync(modelElement.bim)
      if (bim) {
        const hided = this.props.hidedElements.getElementHided(bim, modelElement)
        if (!hided) result.add(modelElement)
      }
    }

    return result
  }

  async getModelElementsFromUrnAndModelID(props: [string, number[]][]): Promise<Set<IModelElement>> {
    const set = new Set<IModelElement>()

    for (const [urn, dbIds] of props) {
      const bim = await this.props.bims.getByUrn(urn)

      if (bim) {
        for (const dbId of dbIds) {
          const modelElement = await this.props.modelElementsRepository.getByModelID(bim, dbId)

          if (modelElement) {
            set.add(modelElement)
          }
        }
      }
    }

    return set
  }
}