import { difference } from "lodash"
import { Bim } from "../../models/Bim"
import { BimElement } from "../../models/BimElement"
import { IModelElement } from "../../models/ModelElement/interface"
import { EUnits } from "../../models/Units"
import { IBimElementsRepository } from "../../repositories/BimElementsRepository/interface"
import { IBimsRepository } from "../../repositories/BimsRepository/interface"
import { IModelElementsRepository } from "../../repositories/ModelElementsRepository/interface"
import { IRemarksRepository } from "../../repositories/RemarksRepository/interface"
import { EWorkStatus } from "../../view-model/StatisticsPageViewModel/interface"
import { Remarks } from "../Remarks/Remarks"
import { Statuses } from "../Statuses/Statuses"

export class Statistics {
  constructor(private props: {
    remarks: Remarks
    remarksRepository: IRemarksRepository
    bimElementsRepository: IBimElementsRepository
    bimsRepository: IBimsRepository
    statuses: Statuses
    modelElementsRepository: IModelElementsRepository
  }) {
    this.props = props
  }

  getDataForBims(bims: Bim[]): Map<EWorkStatus, number> {
    const initValue = this.getDataForBim(bims[0])

    return bims.reduce((acc, bim) => {
      const data = this.getDataForBim(bim)

      for (const [workStatus, value] of data.entries()) {
        initValue.set(workStatus, initValue.get(workStatus) || 0 + value)
      }

      return acc
    }, initValue)
  }

  getDataForBim(bim: Bim): Map<EWorkStatus, number> {
    const map = new Map<EWorkStatus, number>()
    const forgeElements = this.props.modelElementsRepository.getByBim(bim)

    map.set(EWorkStatus.completed, this.getForgeElementsVolume(this.getCompletedForgeElements(forgeElements)))
    map.set(EWorkStatus.inProgress, this.getForgeElementsVolume(this.getInProgressForgeElements(forgeElements)))
    map.set(EWorkStatus.hasRemarks, this.getForgeElementsVolume(this.getFailedForgeElements(forgeElements)))
    map.set(EWorkStatus.notStarted, this.getForgeElementsVolume(this.getNotStartedForgeElements(forgeElements)))

    return map
  }

  getFailedForgeElements(elements: IModelElement[]) {
    const failedElements: IModelElement[] = []

    for (const element of elements) {
      const bim = this.props.bimsRepository.getById(element.bim)

      if (bim) {
        const bimElement = this.props.bimElementsRepository.getByGuid(bim.id, element.id)
        if (bimElement && this.getBimElementHasNotClosedRemark(bimElement)) {
          failedElements.push(element)
        }
      }
    }

    return failedElements
  }

  getNotFailedForgeElements(elements: IModelElement[]) {
    const failedElements = this.getFailedForgeElements(elements)

    return difference(elements, failedElements)
  }

  getBimElementHasNotClosedRemark(bimElement: BimElement) {
    const remarks = this.props.remarksRepository.getByBimId(bimElement.bim)
    const notClosedRemarks = this.props.remarks.getNotClosedRemarks(remarks)
    return notClosedRemarks.some(remark => remark.elements.includes(bimElement.id))
  }

  getNotStartedForgeElements(elements: IModelElement[]) {
    const forgeElements: IModelElement[] = []

    for (const element of elements) {
      const bim = this.props.bimsRepository.getById(element.bim)

      if (bim) {
        const bimElement = this.props.bimElementsRepository.getByGuid(bim.id, element.id)
        if (bimElement) {
          const notStarted = this.props.statuses.getBimElementNotStarted(bimElement)
          if (notStarted) {
            forgeElements.push(element)
          }
        } else {
          forgeElements.push(element)
        }
      }
    }

    return forgeElements
  }

  async getNotStartedForgeElementsAsync(elements: IModelElement[]) {
    const forgeElements: IModelElement[] = []

    for (const element of elements) {
      const bim = await this.props.bimsRepository.getByIdAsync(element.bim)

      if (bim) {
        const bimElement = await this.props.bimElementsRepository.getByGuidAsync(bim.id, element.id)
        if (bimElement) {
          const notStarted = await this.props.statuses.getBimElementNotStartedAsync(bimElement)
          if (notStarted) {
            forgeElements.push(element)
          }
        } else {
          forgeElements.push(element)
        }
      }
    }

    return forgeElements
  }

  getCompletedForgeElements(elements: IModelElement[]) {
    const forgeElements: IModelElement[] = []

    for (const element of elements) {
      const bim = this.props.bimsRepository.getById(element.bim)

      if (bim) {
        const bimElement = this.props.bimElementsRepository.getByGuid(bim.id, element.id)
        if (bimElement) {
          const completed = this.props.statuses.getBimElementCompleted(bimElement)
          if (completed) {
            forgeElements.push(element)
          }
        }
      }
    }

    return forgeElements
  }

  async getCompletedForgeElementAsync(elements: IModelElement[]) {
    const forgeElements: IModelElement[] = []

    for (const element of elements) {
      const bim = await this.props.bimsRepository.getByIdAsync(element.bim)

      if (bim) {
        const bimElement = await this.props.bimElementsRepository.getByGuidAsync(bim.id, element.id)
        if (bimElement) {
          const completed = await this.props.statuses.getBimElementCompletedAsync(bimElement)
          if (completed) {
            forgeElements.push(element)
          }
        }
      }
    }

    return forgeElements
  }

  getInProgressForgeElements(elements: IModelElement[]) {
    const forgeElements: IModelElement[] = []

    for (const element of elements) {
      const bim = this.props.bimsRepository.getById(element.bim)

      if (bim) {
        const bimElement = this.props.bimElementsRepository.getByGuid(bim.id, element.id)
        if (bimElement) {
          const inProgress = this.props.statuses.getBimElementInProgress(bimElement)
          if (inProgress) {
            forgeElements.push(element)
          }
        }
      }
    }

    return forgeElements
  }

  async getInProgressForgeElementsAsync(elements: IModelElement[]) {
    const forgeElements: IModelElement[] = []

    for (const element of elements) {
      const bim = await this.props.bimsRepository.getByIdAsync(element.bim)

      if (bim) {
        const bimElement = await this.props.bimElementsRepository.getByGuidAsync(bim.id, element.id)
        if (bimElement) {
          const inProgress = await this.props.statuses.getBimElementInProgressAsync(bimElement)
          if (inProgress) {
            forgeElements.push(element)
          }
        }
      }
    }

    return forgeElements
  }

  getForgeElementsVolume(elements: IModelElement[]) {
    return elements.reduce((sum, element) => sum + element.volumeValue, 0)
  }

  getForgeElementsSquare(elements: IModelElement[]) {
    return elements.reduce((sum, element) => sum + element.squareValue, 0)
  }

  getForgeElementsLength(elements: IModelElement[]) {
    return elements.reduce((sum, element) => sum + element.lengthValue, 0)
  }

  getForgeElementsUnitValue(elements: IModelElement[], unit: EUnits): number {
    switch (unit) {
      case EUnits.thing:
        return elements.length
      case EUnits.volume:
        return this.getForgeElementsVolume(elements)
      case EUnits.square:
        return this.getForgeElementsSquare(elements)
      case EUnits.length:
        return this.getForgeElementsLength(elements)
      default:
        return 0
    }
  }
}