import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EWindowSelectStage } from "../../usecase/SelectedElements/SelectedElements";
import { IWindowSelectorViewModel } from "./interface";

export class WindowSelectorViewModel implements IWindowSelectorViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get active() {
    return this.props.layers.usecases.selectedElements.windowStage === EWindowSelectStage.select ||
      this.props.layers.usecases.selectedElements.windowStage === EWindowSelectStage.deselect
  }

  setActive(value: boolean) {
    if (value) {
      this.props.layers.usecases.selectedElements.setWindowStage(EWindowSelectStage.select)
    } else if (this.active) {
      this.props.layers.usecases.selectedElements.setWindowStage(EWindowSelectStage.none)
    }
  }

  async onSelect(props: [string, number[]][]) {
    const set = await this.props.layers.usecases.forgeElements.getModelElementsFromUrnAndModelID(props)
    this.props.layers.usecases.forgeElements.onSelect(set)
  }
}