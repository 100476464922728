import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IStatisticsTableViewModel } from "../../view-model/StatisticsTableViewModel/interface"
import clsx from "clsx"

const useClasses = makeStyles(theme => ({
  root: {
    display: "grid",
    overflow: "auto"
  },
  cell: {
    fontSize: "10px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
  }
}))

interface IStatisticsTableProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IStatisticsTableViewModel
  className?: string
}

export const StatisticsTable = observer((props: IStatisticsTableProps) => {
  const classes = useClasses(props);

  return (
    <div
      className={clsx(classes.root, props.className)}
      style={{
        gridTemplateAreas: props.viewModel.gridArea,
        gridTemplateColumns: props.viewModel.gridTemplateColumns,
      }}
    >
      {
        props.viewModel.gridData.map(cell => (
          <div className={classes.cell} key={cell.id} style={{
            gridArea: cell.id,
            backgroundColor: cell.even ? "#FAFAFA" : "",
            fontWeight: cell.header ? "bold" : "",
          }}>{cell.value}</div>
        ))
      }
    </div>
  )
})