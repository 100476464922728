import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EHomeSectionCodes } from "../../models/HomeSectionCodes";
import { Project, TProjectProps } from "../../models/Project";
import { BimTableWithModelWorkTypeViewModel } from "../BimTableWithModelWorkTypeViewModel/BimTableWithModelWorkTypeViewModel";
import { CollectionsMultiBoardViewModel } from "../CollectionsMultiBoardViewModel/CollectionsMultiBoardViewModel";
import { PrescriptionsTableViewModel } from "../PrescriptionsTableViewModel/PrescriptionsTableViewModel";
import { RemarksTableViewModel } from "../RemarksTableViewModel/RemarksTableViewModel";
import { TargetsListViewModel } from "../TargetsListViewModel/TargetsListViewModel";
import { DatePickerViewModel } from "../DatePickerViewModel/DatePickerViewModel";
import { ITargetsPageViewModel } from "./interface";
import { ProjectStatisticsAsyncViewModel } from "../ProjectStatisticsViewModel/ProjectStatisticsAsyncViewModel";
import { computedFn } from "mobx-utils";
import { EActions } from "../../usecase/Permissions/Permissions";

export class TargetsPageViewModel implements ITargetsPageViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props;

    makeObservable(this);
  }

  @computed
  get selectedProjectId() {
    return this.props.layers.usecases.viewState.props.selectedProjectId;
  }

  @computed
  get project(): Project | undefined {
    if (!this.selectedProjectId) return;

    return (
      this.props.layers.repositories.projectsRepository.getById(
        this.selectedProjectId
      )
    );
  }

  @computed
  get projectName() {
    return this.project?.name || ""
  }

  @computed
  get isOpenFilterPanel() {
    return this.props.layers.usecases.viewState.props.isOpenFilterPanel
  }

  switchFilterPanel() {
    this.props.layers.usecases.viewState.switchFilterPanel();
  }

  @computed
  get sections() {
    const homeSections: { name: string, code: string }[] = []
    homeSections.push({
      name: 'Модели',
      code: EHomeSectionCodes.collections,
    })
    if (this.props.layers.usecases.permissions.actionIsAllowed(EActions.showRemarks)) homeSections.push({
      name: 'Замечания',
      code: EHomeSectionCodes.remarks,
    })
    if (this.props.layers.usecases.permissions.actionIsAllowed(EActions.showPrescriptions)) homeSections.push({
      name: 'Предписания',
      code: EHomeSectionCodes.prescriptions,
    })
    homeSections.push({
      name: 'Файлы',
      code: EHomeSectionCodes.files,
    })


    return homeSections.map(section => ({
      key: section.code,
      name: section.name,
      code: section.code
    }))
  }

  @computed
  get selectedSection() {
    return this.props.layers.usecases.viewState.props.mainPageSection;
  }

  getBimTableWithModelWorkTypeViewModel = computedFn((targetId: number) => {
    return new BimTableWithModelWorkTypeViewModel({
      ...this.props,
      targetId,
    });
  }, { keepAlive: true })

  getRemarksTableViewModel = computedFn((targetId: number) => {
    return new RemarksTableViewModel({
      ...this.props,
      targetId,
    });
  }, { keepAlive: true })

  getPrescriptionsTableViewModel = computedFn((targetId: number) => {
    return new PrescriptionsTableViewModel({
      ...this.props,
      targetId,
    });
  }, { keepAlive: true })

  getRemarksLabel(id: TProjectProps["id"]) {
    return `Замечаний: ${this.props.layers.usecases.filtredRemarksTable.getFiltredRemarksByProjectId(
      id
    ).length
      }`;
  }

  getPrescriptionsLabel(id: TProjectProps["id"]) {
    return `Предписаний: ${this.props.layers.usecases.filtredPrescriptionsTable.getFiltredPrescriptionsByProjectId(
      id
    ).length
      }`;
  }

  @computed
  get additionalHeaderContent() {
    if (!this.selectedProjectId) return "";

    const mainPageSection =
      this.props.layers.usecases.viewState.props.mainPageSection;
    switch (mainPageSection) {
      case EHomeSectionCodes.remarks:
        return String(this.getRemarksLabel(this.selectedProjectId));
      case EHomeSectionCodes.prescriptions:
        return String(this.getPrescriptionsLabel(this.selectedProjectId));
    }

    return "";
  }

  @computed
  get targets() {
    if (!this.selectedProjectId) return [];

    return this.props.layers.repositories.targetsRepository.getByProjectId(
      this.selectedProjectId
    );
  }

  @computed
  get filterCount() {
    return this.props.layers.usecases.filterPanel.filterCount
  }

  @computed
  get targetsListViewModel() {
    return new TargetsListViewModel(this.props);
  }

  @computed
  get collectionsMultiBoardViewModel() {
    return new CollectionsMultiBoardViewModel({
      ...this.props,
    })
  }

  @computed
  get datePickerViewModel() {
    const viewModel = new DatePickerViewModel({})
    viewModel.applyButtonLabel = "Сформировать"

    viewModel.onChange = async (date: Date) => {
      viewModel.open = false

      this.statisticDialogViewModel = {
        open: true,
        onClose: () => {
          this.statisticDialogViewModel.open = false
        }
      }

      if (this.project) {
        const projectStatisticsViewModel = new ProjectStatisticsAsyncViewModel({
          ...this.props,
          project: this.project,
          date
        })

        this.projectStatisticsViewModel = projectStatisticsViewModel

        await projectStatisticsViewModel.compute()
      }
    }

    return viewModel
  }

  @observable
  statisticDialogViewModel

  @observable
  projectStatisticsViewModel

  @computed
  get statisticButtonViewModel() {
    return {
      onClick: async () => {
        if (!this.project) return
        this.props.layers.repositories.statisticsRepository.downloadProjectReport({
          project: this.project.id
        })
        // this.datePickerViewModel.open = true
      }
    }
  }

  onChangeSelectedSection(v: EHomeSectionCodes) {
    this.props.layers.usecases.viewState.setMainPageSelectedSection(v);
  }

  onClose() {
    this.props.layers.usecases.viewState.setSelectedProjectId(undefined);
  }

  onCloseFilter = () => {
    this.props.layers.usecases.viewState.switchFilterPanel();
  }

  сlearFilterRemarks() {
    this.props.layers.usecases.filterPanel.сlearFilters()
  }
}
