import { TProjectProps } from "../../models/Project";
import { Target } from "../../models/Target";
import { IAuthRepository } from "../AuthRepository/interface";
import { IReadOnlyRepository } from "../Repository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { ITargetsRepository } from "./interface";

type TGetTargetsResponse = {
  id: number
  address: string
  company: unknown
  lat: unknown
  lng: unknown
  name: string
  project: {
    id: number
  }
}

export class RestTargetsRepository implements ITargetsRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.repository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: `${props.host}api/core/target/`,
      Model: Target,
      serialyze(responseProp: TGetTargetsResponse): Target["props"] {
        return {
          id: responseProp.id,
          name: responseProp.name,
          project: responseProp.project.id,
          address: responseProp.address
        }
      }
    })
  }

  private repository: IReadOnlyRepository<Target>

  getById(props) {
    return this.repository.getById(props)
  }

  getByIdAsync(props) {
    return this.repository.getByIdAsync(props)
  }

  getAll() {
    return this.repository.getAll()
  }

  getByProjectId(id: TProjectProps["id"]) {
    return this.repository.getByParams({
      project: id
    })
  }

  async getByProjectIdAsync(id: TProjectProps["id"]) {
    return this.repository.getByParamsAsync({
      project: id
    })
  }
}