import { computed, makeObservable, observable } from "mobx";
import { ISimpleDataRepository } from "../../repositories/SimpleDataRepository/interface";
import { ISimpleViewModel } from "./interface";

export class SimpleViewModelWithSaver<T> implements ISimpleViewModel<T> {
  constructor(private props: {
    defaultValue: T
    repository: ISimpleDataRepository<T>
  }) {
    this.props = props

    makeObservable(this)
  }

  disabled = false

  @computed
  get value() {
    const value = this.props.repository.get()
    return value === undefined ? this.props.defaultValue : value
  }

  async onChange(value: T) {
    await this.props.repository.save(value)
  }
}