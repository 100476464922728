import { BimElement, TBimElementProps, TNewBimElementProps } from "../../models/BimElement";
import { IBimStatusRepository } from "./interface";
import { TBimProps } from "../../models/Bim";
import { RestRepository } from "../Repository/RestRepositoryOld";
import { computed, makeObservable, observable, ObservableMap, toJS } from "mobx";
import { IAuthRepository } from "../AuthRepository/interface";
import { computedFn } from "mobx-utils";
import { BimStatus, TBimStatusProps, TNewBimStatusProps } from "../../models/BimStatus";
import { TStatusDictionaryProps } from "../../models/StatusDictionary";
import axios from "axios";

type TRestProps = {
  id: number | string
  bim: number
  deadline: any
  element: number
  status: number
}

export type TNewRestBimStatusProps = {
  element: TBimElementProps["id"]
  status: TStatusDictionaryProps["id"]
  comment: string
  deadline: string

  file: null
  attachments: []
}

export class RestBimStatusRepository extends RestRepository<BimStatus, TRestProps, TNewBimStatusProps> implements IBimStatusRepository {
  @observable
  collections = new ObservableMap<any, any>()

  constructor(private props: {
    authRepository: IAuthRepository,
    host: string
  }) {
    super()
    this.props = props

    makeObservable(this)
  }

  Item = BimStatus

  @computed
  get path() {
    return `${this.props.host}api/main/bim-status-planned/`
  }

  @computed
  get auth() {
    return this.props.authRepository
  }

  getByBimId = computedFn((bim: TBimProps["id"]) => {
    return this.getByParams({ bim }, { upload: bim })
  })

  getByBimIdAsync = computedFn((bim: TBimProps["id"]) => {
    return this.getByParamsAsync({ bim }, { upload: bim })
  })

  private getElementsMap = computedFn((plannedStatuses: BimStatus[]) => {
    return new Map(plannedStatuses.map(status => [status.element, status]))
  })

  getActualByBimElement(bimElement: BimElement) {
    const statuses = this.getByBimId(bimElement.bim)
    return this.getElementsMap(statuses).get(bimElement.id)
  }

  async getActualByBimElementAsync(bimElement: BimElement) {
    const statuses = await this.getByBimIdAsync(bimElement.bim)
    return this.getElementsMap(statuses).get(bimElement.id)
  }

  async savePlannedStatus(plannedStatuses: TNewBimStatusProps[]) {
    const response = await axios.post<TRestProps[]>(`${this.path}bulk_create/`, plannedStatuses.map(ps => this.serialyzeAdd(ps)), {
      headers: this.headers,
    })

    return this.updatedOrCreateModels(response.data)
  }

  serialyzeAdd(props: TNewBimStatusProps): TNewRestBimStatusProps {
    return {
      file: null,
      comment: "",
      attachments: [],
      ...props,
    }
  }

  serialyze(props: TRestProps): TBimStatusProps {
    return props
  }
}