import { computed, makeObservable } from "mobx";
import { MouseEvent } from "react";
import { EOrientations, TOption } from "../PanelDropdownMenuViewModel/interface";
import { PanelDropdownMenuViewModel } from "../PanelDropdownMenuViewModel/PanelDropdownMenuViewModel";
import { IPanelSelectorViewModel } from "./interface";

export type TOptionProps<T> = {
  value: T
  label: string
  disabled?: boolean
  group?: string
}

export type TPanelSelectorCommonBehaviorViewModelProps<T> = {
  label: string
  disabled?: boolean,
  orientation?: EOrientations
  opened?: boolean
} & ({ options: TOptionProps<T>[] } | { getOptions(): TOptionProps<T>[] })

export abstract class PanelSelectorCommonBehaviorViewModel<T> implements IPanelSelectorViewModel {
  constructor(private _props: TPanelSelectorCommonBehaviorViewModelProps<T>) {
    this._props = _props

    this.panelDropdownMenuViewModel.opened = Boolean(_props.opened)

    makeObservable(this)
  }

  @computed
  get label() {
    return this._props.label
  }

  @computed
  get disabled() {
    return this._props.disabled || false
  }

  @computed
  get orientation() {
    return this.panelDropdownMenuViewModel.orientation
  }

  onClick = (e: MouseEvent) => {
    this.panelDropdownMenuViewModel.switch()
    this.panelDropdownMenuViewModel.skipEvent = e.nativeEvent
  }

  @computed
  get panelDropdownMenuViewModel() {
    return new PanelDropdownMenuViewModel({
      getOptions: () => this.getOptions(),
      getGroups: () => this.getGroups(),
      orientation: this._props.orientation
    })
  }

  abstract deselectOptions(options: TOptionProps<T>[]): void
  abstract selectOptions(options: TOptionProps<T>[]): void
  abstract switchOption(option: TOptionProps<T>): void
  abstract getOptionSelected(option: TOptionProps<T>): boolean
  abstract get valueLabel(): string

  @computed
  get allOptions() {
    return "options" in this._props ? this._props.options : this._props.getOptions()
  }

  @computed
  private get pureOptions() {
    return this.allOptions.filter(option => option.group === undefined)
  }

  @computed
  private get groupOptions() {
    return this.allOptions.filter(option => option.group)
  }

  @computed
  get groups() {
    const groups = new Map<string, { option: TOption, pureOption: TOptionProps<T> }[]>()

    for (const option of this.groupOptions) {
      if (option.group) {
        groups.has(option.group) ?
          groups.get(option.group)?.push({
            option: this.getOption(option),
            pureOption: option
          }) :
          groups.set(option.group, [{
            option: this.getOption(option),
            pureOption: option
          }])
      }
    }

    return Array.from(groups.entries()).map(([name, options]) => {
      const selected = options.every(option => option.option.disabled || option.option.selected)
      const pureOptions = options.map(option => option.pureOption)

      return {
        key: name,
        name,
        selected,
        onClick: () => selected ? this.deselectOptions(pureOptions) : this.selectOptions(pureOptions),
        options: options.map(option => option.option)
      }
    })
  }

  @computed
  get optionsMap(): Map<T, { label: string }> {
    return new Map<T, { label: string }>(this.allOptions.map(option => ([option.value, option])))
  }

  @computed
  get options() {
    return this.pureOptions.map(this.getOption)
  }

  private getOptions() {
    return this.options
  }

  private getGroups() {
    return this.groups
  }

  private getOption = (option: TOptionProps<T>): TOption & { value: T } => {
    return {
      key: String(option.value),
      label: option.label,
      disabled: option.disabled,
      selected: this.getOptionSelected(option),
      value: option.value,
      onClick: () => this.switchOption(option)
    }
  }
}