import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { TTargetProps } from "../../models/Target";
import { IBimTableViewModel, TBimTableModel } from "./interface";
import { format } from "date-fns";
import { EPages } from "../../models/Pages";
import { EVariants } from "../../usecase/Notifications/Notifications";
import { ModelWorkType } from "../../models/ModelWorkType";
import { UploadFileViewModel } from "../UploadFileViewModel/UploadFileViewModel";
import { EActions } from "../../usecase/Permissions/Permissions";

export class BimTableViewModel implements IBimTableViewModel {
  constructor(private props: {
    layers: ILayers,
    targetId: TTargetProps["id"],
    modelWorkType: ModelWorkType
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get allBims() {
    const bims = this.props.layers.repositories.bimsRepository.getByTargetId(this.props.targetId).filter(bim => bim.modelWorkType === this.props.modelWorkType.id)

    return bims.map(bim => {
      const uploader = bim.uploader ? this.props.layers.repositories.usersRepository.getById(bim.uploader)?.fullName : undefined

      const onUpdate = async (file: File) => {
        const notificationKey = await this.props.layers.usecases.notifications.wait(`обновление модели...`)

        try {
          await this.props.layers.repositories.bimsRepository.edit(bim, file)
          this.props.layers.usecases.notifications.notify("обновление модели завершено", {
            variant: EVariants.success
          })
        } catch (error) {

          this.props.layers.usecases.notifications.wait("ошибка", {
            variant: EVariants.error
          })
        }

        this.props.layers.usecases.notifications.close(notificationKey)
      }

      const model = {
        id: bim.id,
        name: bim.name,
        description: bim.description,
        size: `${(bim.size / 1024 / 1024).toFixed(1)} Мб`,
        uploadedAt: format(bim.uploadedAt, "dd.MM.yyyy"),
        userName: uploader || "",
        fileName: bim.fileName,
        targetId: bim.target,
        accept: this.accept,
        onOpen: this.props.layers.usecases.permissions.actionIsAllowed(EActions.showModels) ? () => {
          this.props.layers.usecases.viewState.setSelectedBims([bim.id])
          this.props.layers.usecases.viewState.setSelectedPage(EPages.viewer)
        } : undefined,
        uploadFileViewModel: new UploadFileViewModel({
          accept: this.props.layers.repositories.bimsRepository.accept,
          onLoad: onUpdate
        }),
        onUpdate: onUpdate,
        allowUpdate: this.props.layers.usecases.permissions.actionIsAllowed(EActions.createAndEditBims),
        filter: "",
      } as TBimTableModel & { filter: string }

      model.filter = `
              ${model.id}
              ${model.name}
              ${model.size}
              ${model.description}
              ${model.uploadedAt}
              ${model.userName}
              ${model.fileName}
            `.toLowerCase()

      return model
    }).sort((a, b) => a.name > b.name ? 1 : -1)
  }

  get accept() {
    return this.props.layers.repositories.bimsRepository.accept
  }

  get disableAdd() {
    return false
  }

  onAdd = async (file: File) => {
    if (!file) return
    const notificationKey = await this.props.layers.usecases.notifications.wait(`загрузка файла ${file.name}`)

    try {
      await this.props.layers.repositories.bimsRepository.add({
        target: this.props.targetId,
        file: file,
        modelWorkType: this.props.modelWorkType.id
      })

      this.props.layers.usecases.notifications.notify("загрузка завершена", {
        variant: EVariants.success
      })
    } catch (error) {
      this.props.layers.usecases.notifications.wait("ошибка", {
        variant: EVariants.error
      })
    }
    this.props.layers.usecases.notifications.close(notificationKey)
  }

  @computed
  get bims() {
    return this.allBims.filter(({ filter }) =>
      filter.includes(this.search)
    )
  }

  get loading() {
    return false
  }

  @computed
  get search() {
    return this.props.layers.usecases.viewState.props.mainPageSearch.toLowerCase()
  }

  @computed
  get empty() {
    return this.bims.length === 0
  }
}