import { ISimpleViewModel } from "../../view-model/SimpleViewModel/interface"
import { SimpleViewModel } from "../../view-model/SimpleViewModel/SimpleViewModel"
import { ISimpleDataRepository } from "./interface"

export class LocalStorageBooleanRepository implements ISimpleDataRepository<boolean> {
  constructor(private props: { key: string }) {
    this.props = props

    this.simpleViewModel = new SimpleViewModel<boolean | undefined>({
      value: undefined
    })

    this.init()
  }

  init() {
    const data = this._get()

    this.simpleViewModel.onChange(data)
  }

  private simpleViewModel: ISimpleViewModel<boolean | undefined>

  get() {
    return this.simpleViewModel.value
  }

  async save(value: boolean) {
    localStorage.setItem(this.props.key, value ? "true" : "false")
    this.simpleViewModel.onChange(value)
  }

  async patch(value: boolean) {
    return this.save(value)
  }

  async delete() {
    localStorage.removeItem(this.props.key)
    this.simpleViewModel.onChange(undefined)
  }

  private _get() {
    const storeValue = localStorage.getItem(this.props.key)

    switch (storeValue) {
      case null:
        return
      case "false":
        return false
      case "true":
        return true
      default:
        return
    }
  }
}