import { computed, makeObservable } from "mobx";
import { BaseModel } from "./BaseModel";
import { Entity } from "./Entity";

export type TProjectProps = BaseModel & {
  name: string
  image: string
  code: string
}

export class Project extends Entity<TProjectProps> {
  constructor(props: TProjectProps) {
    super(props)

    makeObservable(this)
  }

  @computed
  get name() {
    return this.props.name
  }

  @computed
  get image() {
    return this.props.image
  }

  @computed
  get code() {
    return this.props.code
  }
}