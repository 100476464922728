import { computed, makeObservable } from "mobx";
import { TBaseModel } from "./BaseModel";
import { TBundleProps } from "./Bundle/Bundle";
import { Entity } from "./Entity";
import { TModelWorkType } from "./ModelWorkType";
import { TTargetProps } from "./Target";

export enum EBimType {
  forge = "forge",
  bundle = "bundle",
}

export type TBimProps = TBaseModel & {
  name: string
  target: TTargetProps["id"]
  description: string
  size: number
  uploadedAt: string
  file: string
  uploader?: number
  modelWorkType: TModelWorkType["id"]
} & ({
  forgeId: string
  type: EBimType.forge
} | {
  bundle: TBundleProps["id"]
  type: EBimType.bundle
})
export class Bim extends Entity<TBimProps> {
  constructor(props: TBimProps) {
    super(props)
    makeObservable(this)
  }

  @computed
  get name() {
    return this.props.name
  }

  @computed
  get target() {
    return this.props.target
  }

  @computed
  get description() {
    return this.props.description
  }

  @computed
  get uploadedAt() {
    return new Date(this.props.uploadedAt)
  }

  @computed
  get fileName() {
    return this.props.file.replace(/.+\/|\?.+/g, "")
  }

  @computed
  get size() {
    return this.props.size
  }

  @computed
  get uploader() {
    return this.props.uploader
  }

  @computed
  get type() {
    return this.props.type
  }

  @computed
  get modelWorkType() {
    return this.props.modelWorkType
  }

  @computed
  get urn() {
    if (this.props.type === EBimType.forge) return btoa(this.props.forgeId).replace(/=/g, "")
    return ""
  }

  @computed
  get bundle() {
    if (this.props.type === EBimType.bundle) return this.props.bundle

    return ""
  }

  @computed
  get url() {
    if (this.props.type === EBimType.bundle) return String(this.props.bundle)
    return ""
  }
}