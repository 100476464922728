import React, { memo } from "react";
import { Fab, IconButton, makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const useClasses = makeStyles(theme => ({
    input: {
        display: "none"
    },
    iconAdd: {
        fontSize: 18,
        fill: theme.palette.primaryColor
    },
    buttonAdd: {
        width: 40,
        height: 40,
        background: theme.palette.primaryRedesignColor6,
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.05)",
        "&:hover": {
            background: theme.palette.primaryRedesignColor6,
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.05)",
        },
    },
}));

function UploadButton({ onFileLoad, identifier }) {
    const classes = useClasses();

    const fileChangeHandler = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            onFileLoad(file);
        }
    }

    return (
        <div>
            <input
                className={classes.input}
                accept=".rvt"
                onChange={fileChangeHandler}
                id={`add-model-button-${identifier}`}
                type="file"
            />
            <label
                htmlFor={`add-model-button-${identifier}`}
            >
                <Fab
                    className={classes.buttonAdd}
                    component="span"
                    disableTouchRipple
                >
                    <Add className={classes.iconAdd} />
                </Fab>
            </label>
        </div>
    );
}

export default memo(UploadButton);