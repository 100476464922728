import { BaseModel } from "./BaseModel";
import { TBimProps } from "./Bim";
import { Entity } from "./Entity";
import { TModelWorkType } from "./ModelWorkType";
import { v4 } from 'uuid';

export type TNewCollectionProps = {
  name: string
  bims: TBimProps["id"][]
  order: number
  image: string
  modelWorkType: TModelWorkType["id"]
  link: string
  consolidated: boolean
}

export type TCollectionProps = BaseModel & TNewCollectionProps & {}

export class Collection extends Entity<TCollectionProps> {
  get name() {
    return this.props.name
  }

  get image() {
    return this.props.image
  }

  get bims() {
    return this.props.bims
  }

  get order() {
    return this.props.order
  }

  get link() {
    return this.props.link
  }

  get modelWorkType() {
    return this.props.modelWorkType
  }

  get consolidated() {
    return this.props.consolidated
  }
}

export class NewCollection extends Collection {
  constructor(props: TNewCollectionProps & Partial<BaseModel>) {
    super({
      id: props.id || v4(),
      ...props
    })
  }
}