import { TBaseModel } from "../BaseModel";
import { TBundleFileProps } from "../BundleFile";
import { Entity } from "../Entity";

export type TBundleProps = {
  files: TBundleFileProps[]

  bimApiId: number
} & TBaseModel

export class Bundle extends Entity<TBundleProps> {
  constructor(props: TBundleProps) {
    super(props)
  }

  get files() {
    return this.props.files
  }

  getFileByKey(key: TBundleFileProps["key"]) {
    return this.files.find(file => file.key === key)
  }

  get bimApiId() {
    return this.props.bimApiId
  }
}