import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EActions } from "../../usecase/Permissions/Permissions";
import { EWindowSelectStage } from "../../usecase/SelectedElements/SelectedElements";
import { MoreButtonsPanelViewModel } from "../MoreButtonsPanelViewModel/MoreButtonsPanelViewModel";
import { NewRemarksFormViewModel } from "../RemarksFormViewModel/NewRemarksFormViewModel";
import { StatusSelectorViewModel } from "../StatusSelectorViewModel/StatusSelectorViewModel";
import { IRightViewerControlPanelViewModel } from "./interface";

export class RightViewerControlPanelViewModel implements IRightViewerControlPanelViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  onClickWindowButton() {
    if (this.windowButtonActive) {
      this.props.layers.usecases.selectedElements.setWindowStage(
        EWindowSelectStage.deselect
      )
    } else if (this.windowButtonDeactive) {
      this.props.layers.usecases.selectedElements.setWindowStage(
        EWindowSelectStage.none
      )
    } else {
      this.props.layers.usecases.selectedElements.setWindowStage(
        EWindowSelectStage.select
      )
    }
  }

  @computed
  get windowButtonActive() {
    return this.props.layers.usecases.selectedElements.windowStage === EWindowSelectStage.select || this.props.layers.usecases.selectedElements.windowStage === EWindowSelectStage.selected
  }

  @computed
  get statusSelectorViewModel() {
    return new StatusSelectorViewModel(this.props)
  }

  @computed
  get windowButtonDeactive() {
    return this.props.layers.usecases.selectedElements.windowStage === EWindowSelectStage.deselect
  }

  @computed
  get statusButtonProps() {
    return {
      disabled: this.props.layers.usecases.selectedElements.count === 0,
      onClick: () => this.props.layers.usecases.viewState.setApplyStatusDialogActive(true)
    }
  }

  @computed
  get remarkButtonProps() {
    if (this.props.layers.usecases.permissions.actionIsBlocked(EActions.addRemark)) return

    return {
      disabled: false,
      onClick: () => this.props.layers.usecases.viewState.setAddRemarkDialogActive(true)
    }
  }

  @computed
  get remarkDialogProps() {
    return {
      open: this.props.layers.usecases.viewState.props.addRemarkDialogActive,
      onClose: () => this.props.layers.usecases.viewState.setAddRemarkDialogActive(false)
    }
  }

  @computed
  get statudDialogProps() {
    return {
      open: this.props.layers.usecases.viewState.props.applyStatusDialogActive,
      onClose: () => this.props.layers.usecases.viewState.setApplyStatusDialogActive(false)
    }
  }

  @computed
  get remarkFormViewModel() {
    return new NewRemarksFormViewModel(this.props)
  }

  @computed
  get moreButtonsPanelViewModel() {
    return new MoreButtonsPanelViewModel({
      ...this.props,
      onClose: () => {
        this.dialogOpen = false
      }
    })
  }

  @observable
  dialogOpen = false

  @computed
  get moreButtonsDialogViewModel() {
    return {
      open: this.dialogOpen,
      onClose: () => {
        this.dialogOpen = false
      },
      onOpen: () => {
        this.dialogOpen = true
      }
    }
  }
}