import React, { useMemo } from "react"
import { Checkbox, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"

import BackButton from '../../components/Buttons/BackButton';
import PanelDateSelector from "../../components/Selectors/PanelDateSelector";
import SimpleButton from "../../components/Buttons/SimpleButton";
import { ITermsAndVolumesViewModel } from "../../view-model/TermsAndVolumesViewModel/interface";
import { PanelSelector } from "../PanelSelector/PanelSelector";
import { SliderCheckboxWithLabel } from "../Checkbox/SliderCheckboxWithLabel";

const useClasses = makeStyles(theme => ({
	root: {
		padding: "100px 0 56px 0",
		display: "flex",
		flexDirection: "column",
		height: "100%"
	},
	backButton: {
		margin: "0px 0px 30px 26px"
	},
	button: {
		width: "max-content",
		margin: "0px 0px 30px 26px",
		background: "#FEC60F",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		paddingRight: 16
	},
	padding: {
		paddingLeft: 26,
		paddingRight: 26
	},
	item: {
		color: "#000000",
		padding: 0,
		marginLeft: 10,
		'&.Mui-checked': {
			color: "#000000",
		},
	},
	disabled: {
		opacity: 0.3
	},
	forTest: {
		display: "flex",
		alignItems: "center",
		"&:hover": {
			backgroundColor: "#f5f5f5",
		}
	},
	icon: {
		marginRight: "18px",
		marginLeft: "-15px",
		marginTop: "2px",
		cursor: "pointer"
	},
}))

interface ITermsAndVolumesProps {
	classes?: Partial<ReturnType<typeof useClasses>>
	viewModel: ITermsAndVolumesViewModel
}

export const TermsAndVolumes = observer((props: ITermsAndVolumesProps) => {
	const classes = useClasses(props)

	const paddingClasses = useMemo(() => ({
		padding: classes.padding
	}), []);

	const CloseIcon = () => (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="2" stroke="#aaaaa9" strokeLinejoin="round">
			<path d="M19 7L7.68629 18.3137" />
			<path d="M19 18.3137L7.68629 7.00001" />
		</svg>
	)

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<BackButton className={classes.backButton} onClick={() => props.viewModel.onBack()} />
			</div>
			<SimpleButton className={classes.button} onClick={props.viewModel.onClickPlainMode} >
				Режим планирования
				<Checkbox className={classes.item} checked={props.viewModel.isPlanningModeActive} size="small" />
			</SimpleButton>
			{
				props.viewModel.storeysSelectorViewModel ? (
					<PanelSelector
						classes={paddingClasses}
						viewModel={props.viewModel.storeysSelectorViewModel}
					/>
				) : null
			}
			{
				props.viewModel.sectionsSelectorViewModel ? (
					<PanelSelector
						classes={paddingClasses}
						viewModel={props.viewModel.sectionsSelectorViewModel}
					/>
				) : null
			}
			{
				props.viewModel.workStatusSelectorViewModel ? (
					<PanelSelector
						classes={paddingClasses}
						viewModel={props.viewModel.workStatusSelectorViewModel}
					/>
				) : null
			}
			{
				props.viewModel.workTypeSelectorViewModel ? (
					<PanelSelector
						classes={paddingClasses}
						viewModel={props.viewModel.workTypeSelectorViewModel}
					/>
				) : null
			}
			<span className={classes.forTest}>
				<PanelDateSelector
					className={paddingClasses}
					label="Плановая дата"
					value={props.viewModel.planningDateViewModel.value}
					placeholder="..."
					onChange={values => props.viewModel.planningDateViewModel.onChange(values)}
				/>
				{props.viewModel.planningDateViewModel.value &&
					<div className={classes.icon} onClick={(e) => {
						e.preventDefault()
						props.viewModel.planningDateViewModel.onChange(undefined)
					}
					}>
						<CloseIcon />
					</div>
				}
			</span>
			{
				props.viewModel.expiredDateCheckboxViewModel ?
					<SliderCheckboxWithLabel className={classes.padding} viewModel={props.viewModel.expiredDateCheckboxViewModel} />
					: null
			}
			{
				props.viewModel.painByDateCheckboxViewModel ?
					<SliderCheckboxWithLabel className={classes.padding} viewModel={props.viewModel.painByDateCheckboxViewModel} />
					: null
			}
		</div>
	)
})