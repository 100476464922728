import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IStatusWrapperViewModel } from "../../view-model/StatusWrapperViewModel/interface"
import clsx from "clsx"

const useClasses = makeStyles(theme => ({
  root: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  content: {},
  hideContent: {
    opacity: 0
  },
  status: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "pre",
    textAlign: "center"
  },
  error: {
    color: "#E25241"
  }
}))

interface IStatusWrapperProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IStatusWrapperViewModel
  className?: string
  children: JSX.Element | (JSX.Element | null)[]
}

export const StatusWrapper = observer((props: IStatusWrapperProps) => {
  const classes = useClasses(props)

  const showStatus = props.viewModel.loading || props.viewModel.error

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={clsx(classes.content, {
        [classes.hideContent]: showStatus
      })}>
        {props.children}
      </div>
      {
        showStatus ? (
          <div className={classes.status}>
            {props.viewModel.loading ? props.viewModel.loadingLabel || "загрузка..." : <div className={classes.error}>{props.viewModel.error}</div>}
          </div>
        ) : null
      }
    </div>
  )
})