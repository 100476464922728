import React from "react"
import { Button, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IPrescriptionsTableViewModel } from "../../view-model/PrescriptionsTableViewModel/interface"
import { TableButton } from "../Buttons/TableButton"
import { useTableClasses } from "../../styles/TableClasses"
import clsx from "clsx"
import { StatusWrapper } from "../StatusWrapper/StatusWrapper"

const useClasses = makeStyles(theme => ({
  root: {},
}))

interface IPrescriptionsTableProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IPrescriptionsTableViewModel
}

export const PrescriptionsTable = observer((props: IPrescriptionsTableProps) => {
  const classes = useClasses(props)
  const tableClasses = useTableClasses()

  return (
    <StatusWrapper className={classes.root} viewModel={props.viewModel.statusWrapperViewModel}>
      <TableContainer>
        <Table className={clsx(tableClasses.table, tableClasses.tableFixed)}>
          <TableHead>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.widthCell)}>
              ID
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
              Шифр
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
              Статус
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width5Cell)}>
              Инженер СК
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width5Cell)}>
              Подрядчик
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width3Cell)}>
              Кол-во замечаний
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
              Устранить до
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
            </TableCell>
          </TableHead>
          <TableBody>
            {
              props.viewModel.prescriptions.map(prescription => (
                <TableRow key={prescription.key} className={tableClasses.bodyRow}>
                  <TableCell className={clsx(tableClasses.bodyCell, tableClasses.boldCell)}>
                    {prescription.id}
                  </TableCell>
                  <TableCell className={clsx(tableClasses.bodyCell, tableClasses.boldCell)}>
                    {prescription.code}
                  </TableCell>
                  <TableCell
                    className={clsx(tableClasses.bodyCell, tableClasses.boldCell, tableClasses.nowrapCell)}
                    style={{ color: prescription.statusColor }}
                  >
                    {prescription.status}
                  </TableCell>
                  <TableCell className={tableClasses.bodyCell}>
                    {prescription.owner}
                  </TableCell>
                  <TableCell className={tableClasses.bodyCell}>
                    {prescription.contractor}
                  </TableCell>
                  <TableCell className={tableClasses.bodyCell}>
                    {prescription.remarksCount}
                  </TableCell>
                  <TableCell className={tableClasses.bodyCell}>
                    {prescription.deadline}
                  </TableCell>
                  <TableCell className={clsx(tableClasses.bodyCell, tableClasses.rightCell)}>
                    {
                      prescription.onOpen ? (
                        <TableButton onClick={prescription.onOpen}>
                          Открыть
                        </TableButton>
                      ) : null
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </StatusWrapper>
  )
})