import React, {memo} from 'react';

import {  
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: 20, 
  },
  root: {
    height: 54,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: "#FAF9F9",
    borderRadius: 14,
    [theme.breakpoints.up('lg')]: {
      height: 64
    }
  },
  text: {
    fontWeight: 'bold',
    fontSize: 12,
    color: "#000"
  }
}))

export const EmptyCard = memo(({title}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Typography className={classes.text}>
          {title}
        </Typography>
      </div>
    </div>
  )
})
