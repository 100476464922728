import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { IModelElement } from "../../models/ModelElement/interface";
import { unitLabel, EUnitRepresentation, EUnits } from "../../models/Units";
import { WorkTypeGroup } from "../../models/WorkTypeGroup";
import { EWorkStatus } from "../StatisticsPageViewModel/interface";
import { IStatisticsTableViewModel } from "./interface";

export class StatisticsTableViewModel implements IStatisticsTableViewModel {
  constructor(private props: {
    layers: ILayers
    storeys: string[]
    workTypesGroups: WorkTypeGroup[]
    unitRepresentation: EUnitRepresentation[]
    workStatuses: EWorkStatus[]
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get forgeElements() {
    return this.props.layers.usecases.elements.forgeElements
  }

  @computed
  get storeys() {
    const storeys = [...this.props.storeys]

    storeys.sort((a, b) => a > b ? -1 : 1)

    return storeys
  }

  private prepareStoreyNameForGrid(name: string) {
    return name.replace(/\s|\+|\./g, "")
  }

  @computed
  get gridData() {
    return [
      ...this.headersGridData,
      ...this.storeys.map((storey, index) => {
        const even = Boolean(index % 2)

        const storeyForgeElements = this.forgeElements.filter(forgeElement => forgeElement.storey === storey)

        const storeyForGrid = this.prepareStoreyNameForGrid(storey)

        return [
          {
            id: `storey${storeyForGrid}`,
            value: storey,
            even
          },
          ...this.props.workTypesGroups.map((workTypeGroup, index) => {
            const workTypeForgeElements = this.props.layers.usecases.elements.filterForgeElementsForWorkTypeGroup(storeyForgeElements, workTypeGroup)

            return [
              { id: `storey${storeyForGrid}-${workTypeGroup.id}`, value: workTypeGroup.name, even },
              { id: `storey${storeyForGrid}-${workTypeGroup.id}-value`, value: this.getStatisticValue({ elements: workTypeForgeElements, unit: workTypeGroup.unit }), even },
            ]
          }).flat()
        ]
      }).flat()
    ]
  }

  unique<T>(array: T[]): T[] {
    return Array.from((new Set(array)).values())
  }

  getStatisticValue(props: { elements: IModelElement[], unit: EUnits }): string {
    if (props.elements.length === 0) return "-"

    let elements: IModelElement[] = []

    if (this.props.workStatuses.includes(EWorkStatus.completed)) {
      elements = [...elements, ...this.props.layers.usecases.statistics.getCompletedForgeElements(props.elements)]
    }
    if (this.props.workStatuses.includes(EWorkStatus.inProgress)) {
      elements = [...elements, ...this.props.layers.usecases.statistics.getInProgressForgeElements(props.elements)]
    }
    if (this.props.workStatuses.includes(EWorkStatus.hasRemarks)) {
      elements = [...elements, ...this.props.layers.usecases.statistics.getFailedForgeElements(props.elements)]
    }

    const value = this.props.layers.usecases.statistics.getForgeElementsUnitValue(this.unique(elements), props.unit)

    let result = ""
    const valueResult = `${value.toFixed(0)} ${unitLabel[props.unit]}`

    if (this.props.unitRepresentation.includes(EUnitRepresentation.percent)) {
      const volume = this.props.layers.usecases.statistics.getForgeElementsVolume(props.elements)
      if (volume > 0) {
        result += `${(value / volume * 100).toFixed(0)}%`
      } else {
        result += "100%"
      }

      if (this.props.unitRepresentation.includes(EUnitRepresentation.absolute)) {
        result += ` (${valueResult})`
      }
    } else if (this.props.unitRepresentation.includes(EUnitRepresentation.absolute)) {
      result += valueResult
    }

    return result
  }

  @computed
  get headersGridData() {
    return [
      { id: "storey", value: "этаж", even: true, header: true },
      { id: "workType", value: "вид работ", even: true, header: true },
      { id: "total", value: "всего", even: true, header: true },
    ]
  }

  @computed
  get gridArea() {
    const gridArea = `"storey workType total" ` + this.storeys.map(storey => {
      const storeyForGrid = this.prepareStoreyNameForGrid(storey)

      return this.props.workTypesGroups.map(workType => `"storey${storeyForGrid} storey${storeyForGrid}-${workType.id} storey${storeyForGrid}-${workType.id}-value"`).join(" ")
    }
    ).join(" ")

    return gridArea
  }

  @computed
  get gridTemplateColumns() {
    return this.headersGridData.map(() => "auto").join(" ")
  }
}