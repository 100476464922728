import { computed, makeObservable } from "mobx";
import { v4 } from "uuid";
import { TNewPrescriptionProps } from "../repositories/PrescriptionRepository/interface";
import { BaseModel } from "./BaseModel";
import { TBimProps } from "./Bim";
import { TCompanyProps } from "./Company";
import { Entity } from "./Entity";
import { TRemarkProps } from "./Remark";
import { TUploadFileProps } from "./UploadFile";
import { TUserProps } from "./User";

export type TPrescriptionProps = BaseModel & {
  projectNumber: number
  owner: TUserProps["id"]
  bim: TBimProps["id"]
  receiver: string
  notes: string
  remarks: TRemarkProps["id"][]
  createdAt: string
  updatedAt: string
  specialConditions: string
  targetText: string
  issuedBy: TCompanyProps["id"]
  deadlineAt: string
  attributes: {
    documentWorkDescription?: string
    documentLevelMark?: string
    customCode?: string
  }
  attachments: TUploadFileProps["id"][]
}

export const issuedByNameDictionary = new Map([
  [1, 'Неизвестно'],
  [2, 'ООО "ИР Девелопмент"'],
  [3, 'ООО "Инвестиции в градостроительство"'],
])
export class Prescription extends Entity<TPrescriptionProps> {
  constructor(props: TPrescriptionProps) {
    super(props)

    makeObservable(this)
  }

  @computed
  get bim() {
    return this.props.bim
  }

  @computed
  get owner() {
    return this.props.owner
  }

  @computed
  get remarks() {
    return this.props.remarks
  }

  @computed
  get projectNumber() {
    return this.props.projectNumber
  }

  @computed
  get createdAt() {
    return new Date(this.props.createdAt)
  }

  @computed
  get receiver() {
    return this.props.receiver
  }

  @computed
  get notes() {
    return this.props.notes
  }

  @computed
  get specialConditions() {
    return this.props.specialConditions
  }

  @computed
  get targetText() {
    return this.props.targetText
  }

  // @computed
  // get recipientText() {
  //   return this.props.recipientText
  // }

  @computed
  get issuedBy() {
    return this.props.issuedBy
  }

  @computed
  get attributes() {
    return this.props.attributes || {}
  }

  @computed
  get documentWorkDescription() {
    return this.attributes.documentWorkDescription || ""
  }

  @computed
  get documentLevelMark() {
    return this.attributes.documentLevelMark || ""
  }

  @computed
  get customCode() {
    return this.attributes.customCode || ""
  }

  @computed
  get deadlineAt() {
    return new Date(this.props.deadlineAt)
  }

  @computed
  get attachments() {
    return this.props.attachments
  }
}

export class NewPrescription extends Prescription {
  constructor(props: TNewPrescriptionProps & Partial<BaseModel>) {
    super({
      id: props.id || v4(),
      ...props,
      projectNumber: 0,
      owner: 0,
      createdAt: (new Date()).toISOString(),
      updatedAt: (new Date()).toISOString()
    })
  }
}