import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { ModelWorkType } from "../../models/ModelWorkType";
import { EPages } from "../../models/Pages";
import { EActions } from "../../usecase/Permissions/Permissions";
import { CollectionCardViewModel } from "../CollectionCardViewModel/CollectionCardViewModel";
import { CollectionFormAddViewModel } from "../CollectionFormViewModel/CollectionFormAddViewModel";
import { CollectionFormEditViewModel } from "../CollectionFormViewModel/CollectionFormEditViewModel";
import { StatusWrapperForPromiseViewModel } from "../StatusWrapperViewModel/StatusWrapperForPromiseViewModel";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { ICollectionsBoardViewModel } from "./interface";

export class CollectionsBoardViewModel implements ICollectionsBoardViewModel {
  constructor(private props: {
    layers: ILayers
    modelWorkType: ModelWorkType
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get statusWrapperViewModel() {
    if (!this.projectId) {
      const viewModel = new StatusWrapperViewModel()
      viewModel.load()

      return viewModel
    }

    return new StatusWrapperForPromiseViewModel({
      promise: this.props.layers.usecases.collections.getByProjectIdAsync(this.projectId)
    })
  }

  @computed
  private get projectId() {
    return this.props.layers.usecases.viewState.props.selectedProjectId
  }

  get header() {
    return `${this.props.modelWorkType.name}`
  }

  @computed
  get modelWorkTypeCollection() {
    if (!this.projectId) return []

    return this.props.layers.usecases.collections.getByProjectId(this.projectId)
      .filter(collection => collection.modelWorkType === this.props.modelWorkType.id)
  }

  @computed
  get preparedCollections() {
    return this.modelWorkTypeCollection
      .filter(collection => !collection.consolidated)
      .map(collection => {
        const viewModel = new CollectionCardViewModel({
          ...this.props,
          collection: collection
        })
        return {
          key: String(collection.id),
          viewModel: viewModel,
          order: collection.order,
          filter: `
          ${viewModel.name}
        `.toLowerCase()
        }
      })
  }

  @computed
  get collections() {
    const search = this.props.layers.usecases.viewState.props.mainPageSearch.toLowerCase()

    return this.preparedCollections.filter(collection => collection.filter.includes(search))
  }

  @computed
  get consolidatedCollection() {
    return this.modelWorkTypeCollection.find(collection => collection.consolidated)
  }

  @computed
  get dialogOpened() {
    return Boolean(this.collectionFormViewModel)
  }

  @observable
  collectionFormViewModel

  @computed
  get addButtonViewModel() {
    if (!this.props.layers.usecases.permissions.actionIsAllowed(EActions.createAndEditCollections)) return

    return {
      onClick: () => {

        this.collectionFormViewModel = new CollectionFormAddViewModel({
          ...this.props,
          onClose: this.onCloseDialog
        })
      }
    }
  }

  @computed
  get sort() {
    return this.collections.sort((a, b) => a.order - b.order).map(collection => collection.key)
  }

  @computed
  get addСonsolidatedButtonViewModel() {
    if (this.props.layers.usecases.permissions.actionIsBlocked(EActions.createAndEditCollections)) return

    return {
      onClick: () => {
        if (this.consolidatedCollection) {
          this.collectionFormViewModel = new CollectionFormEditViewModel({
            ...this.props,
            collection: this.consolidatedCollection,
            onClose: this.onCloseDialog,
          })
        } else {
          this.collectionFormViewModel = new CollectionFormAddViewModel({
            ...this.props,
            onClose: this.onCloseDialog,
            consolidated: true
          })
        }
      },
      editMode: Boolean(this.consolidatedCollection)
    }
  }

  @computed
  get openСonsolidatedButtonViewModel() {
    if (this.props.layers.usecases.permissions.actionIsBlocked(EActions.showModels)) return

    return {
      onClick: () => {
        this.props.layers.usecases.viewState.setSelectedCollection(this.consolidatedCollection?.id)
        this.props.layers.usecases.viewState.setSelectedPage(EPages.viewer)
      },
      disabled: !this.consolidatedCollection
    }
  }

  onSort(sort: string[]) {
    if (sort.join("") === this.sort.join("")) return

    this.props.layers.repositories.collectionsRepository.sort(
      sort.map((key, index) => ({ id: Number(key), order: index }))
    )
  }

  onCloseDialog = () => {
    this.collectionFormViewModel = undefined
  }
}