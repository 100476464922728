
export interface IHomeHeaderViewModel {
    searchText: string
    menuLabel: string

    menuOptions: {
        key: number | string
        code?: EMenuOptionsCodes
        name: string
        onClick?: () => void
    }[]

    onChangeSearchText: (v: string) => void
}

export enum EMenuOptionsCodes {
    version = "version"
}