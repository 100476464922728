import { computed, makeObservable } from "mobx";
import { BimElement, TNewBimElementProps } from "../../models/BimElement";
import { Collection } from "../../models/Collection";
import { ConstructionGroup } from "../../models/ConstructionGroup";
import { Material } from "../../models/Material";
import { ModelElement } from "../../models/ModelElement/ModelElement";
import { Project } from "../../models/Project";
import { Target } from "../../models/Target";
import { WorkType } from "../../models/WorkType";
import { WorkTypeGroup } from "../../models/WorkTypeGroup";
import { IBimElementsRepository } from "../../repositories/BimElementsRepository/interface";
import { IBimsRepository } from "../../repositories/BimsRepository/interface";
import { IConstructionGroupsRepository } from "../../repositories/ConstructionGroupsRepository/interface";
import { IMaterialsRepository } from "../../repositories/MaterialsRepository/interface";
import { IModelElementsRepository } from "../../repositories/ModelElementsRepository/interface";
import { IWorkTypeGroupRepository } from "../../repositories/WorkTypeGroupRepository/interface";
import { IWorkTypesRepository } from "../../repositories/WorkTypesRepository/interface";
import { Bims } from "../Bims/Bims";
import { Collections } from "../Collections/Collections";
import { IModelElement } from "../../models/ModelElement/interface";

export class Elements {
  constructor(
    private props: {
      constructionGroupsRepository: IConstructionGroupsRepository;
      workTypesRepository: IWorkTypesRepository;
      modelElementsRepository: IModelElementsRepository;
      bimsRepository: IBimsRepository;
      bimElementsRepository: IBimElementsRepository;
      bims: Bims;
      collections: Collections;
      materialsRepository: IMaterialsRepository;
      workTypeGroupRepository: IWorkTypeGroupRepository;
    }
  ) {
    this.props = props;

    makeObservable(this);
  }

  @computed
  get forgeElements() {
    const elements: IModelElement[] = [];
    for (const bim of this.props.bims.selectedBims) {
      const forgeElements = this.props.modelElementsRepository.getByBim(bim);

      elements.push(...forgeElements);
    }

    return elements;
  }

  getForgeElementMaterials(element: IModelElement) {
    const result = new Array<Material>();

    for (const materialCode of element.materials) {
      const material = this.props.materialsRepository.getByCode(materialCode);

      if (material) {
        result.push(material);
      }
    }

    return result;
  }

  async getForgeElementMaterialsAsync(element: IModelElement) {
    const result = new Array<Material>();

    for (const materialCode of element.materials) {
      const material = await this.props.materialsRepository.getByCodeAsync(
        materialCode
      );

      if (material) {
        result.push(material);
      }
    }

    return result;
  }

  getConstructionGroupsForForgeElement(
    forgeElements: IModelElement[]
  ): (ConstructionGroup | undefined)[] {
    const set = new Set<ConstructionGroup | undefined>();

    for (const forgeElement of forgeElements) {
      if (forgeElement.constructionGroup) {
        const constructionGroups =
          this.props.constructionGroupsRepository.getAllByName(
            forgeElement.constructionGroup
          );
        for (const constructionGroup of constructionGroups) {
          set.add(constructionGroup);
        }
      } else {
        set.add(undefined);
      }
    }

    return Array.from(set);
  }

  async getConstructionGroupsForForgeElementAsync(
    forgeElements: IModelElement[]
  ): Promise<(ConstructionGroup | undefined)[]> {
    const set = new Set<ConstructionGroup | undefined>();

    for (const forgeElement of forgeElements) {
      if (forgeElement.constructionGroup) {
        const constructionGroups =
          await this.props.constructionGroupsRepository.getAllByNameAsync(
            forgeElement.constructionGroup
          );
        for (const constructionGroup of constructionGroups) {
          set.add(constructionGroup);
        }
      } else {
        set.add(undefined);
      }
    }

    return Array.from(set);
  }

  getWorkTypesByForConstructionGroups(
    constructionGroups: (ConstructionGroup | undefined)[]
  ): (WorkType | undefined)[] {
    const set = new Set<WorkType | undefined>();
    for (const constructionGroup of constructionGroups) {
      if (constructionGroup) {
        const workType = this.props.workTypesRepository.getById(
          constructionGroup.workType
        );
        set.add(workType);
      } else {
        set.add(undefined);
      }
    }

    return Array.from(set);
  }

  getWorkTypesByForgeElements(
    forgeElements: IModelElement[]
  ): (WorkType | undefined)[] {
    return this.getWorkTypesByForConstructionGroups(
      this.getConstructionGroupsForForgeElement(forgeElements)
    );
  }

  getWorkTypeGroupsByForgeElements(
    forgeElements: IModelElement[]
  ): (WorkTypeGroup | undefined)[] {
    const workTypes = this.getWorkTypesByForgeElements(forgeElements);
    return this.getWorkTypeGroupsByWorkTypes(workTypes);
  }

  filterForgeElementsForWorkTypeGroup(
    forgeElements: IModelElement[],
    workTypeGroup: WorkTypeGroup
  ): IModelElement[] {
    return forgeElements.filter((forgeElement) => {
      const workTypes = this.getWorkTypesByForgeElement(forgeElement);
      const has = Array.from(workTypes).some(
        (workType) => workType.group === workTypeGroup.id
      );

      return has || workTypeGroup.other;
    });
  }

  notUndenfined<T>(item: T | undefined): item is T {
    return item !== undefined;
  }

  getWorkTypeGroupsByWorkTypes(
    workTypes: (WorkType | undefined)[]
  ): (WorkTypeGroup | undefined)[] {
    const workTypesGroupsSet = new Set<WorkTypeGroup | undefined>();

    for (const workType of workTypes) {
      if (workType && workType.group) {
        const workTypeGroup = this.props.workTypeGroupRepository.getById(
          workType.group
        );

        workTypesGroupsSet.add(workTypeGroup);
      } else {
        workTypesGroupsSet.add(undefined);
      }
    }

    return Array.from(workTypesGroupsSet.values());
  }

  getWorkTypesByForgeElement(forgeElement: IModelElement): Set<WorkType> {
    //@ts-ignore
    return new Set(
      this.getWorkTypesByForgeElements([forgeElement]).filter(
        (workType) => workType
      )
    );
  }

  getForgeElementByBimElement(bimElement: BimElement) {
    const bim = this.props.bimsRepository.getById(bimElement.bim);
    if (!bim) return;
    return this.props.modelElementsRepository.getByBimElement(bimElement);
  }

  async getForgeElementByBimElementAsync(bimElement: BimElement) {
    const bim = await this.props.bimsRepository.getByIdAsync(bimElement.bim);
    if (!bim) return;
    return this.props.modelElementsRepository.getByBimElementAsync(bimElement);
  }

  getBimElements(forgeElements: IModelElement[]): (BimElement | undefined)[] {
    const set = new Set<BimElement | undefined>();
    for (const forgeElement of forgeElements) {
      const bim = this.props.bimsRepository.getById(forgeElement.bim);
      if (bim) {
        const element = this.props.bimElementsRepository.getByGuid(
          bim.id,
          forgeElement.id
        );
        set.add(element);
      }
    }

    return Array.from(set.values());
  }

  async getForgeElementsForProjectAsync(project: Project) {
    const bims = await this.props.bims.getBimsByProjectIdAsync(project.id);

    const forgeElementsSet = new Set<IModelElement>();

    for (const bim of bims) {
      const forgeElementsForBim =
        await this.props.modelElementsRepository.getByBimAsync(bim);

      for (const forgeElement of forgeElementsForBim) {
        forgeElementsSet.add(forgeElement);
      }
    }

    return Array.from(forgeElementsSet.values());
  }

  async getForgeElementsForTargetAsync(target: Target) {
    const bims = await this.props.bimsRepository.getByTargetIdAsync(target.id);

    const forgeElementsSet = new Set<IModelElement>();

    for (const bim of bims) {
      const forgeElementsForBim =
        await this.props.modelElementsRepository.getByBimAsync(bim);

      for (const forgeElement of forgeElementsForBim) {
        forgeElementsSet.add(forgeElement);
      }
    }

    return Array.from(forgeElementsSet.values());
  }

  async getForgeElementsForCollectionAsync(collection: Collection) {
    const bims = await this.props.collections.getCollectionBimsAsync(
      collection
    );

    const forgeElementsSet = new Set<IModelElement>();

    for (const bim of bims) {
      const forgeElementsForBim =
        await this.props.modelElementsRepository.getByBimAsync(bim);

      for (const forgeElement of forgeElementsForBim) {
        forgeElementsSet.add(forgeElement);
      }
    }

    return Array.from(forgeElementsSet.values());
  }

  async getForgeElementsForCollectionsAsync(collections: Collection[]) {
    const forgeElementsSet = new Set<IModelElement>();

    for (const collection of collections) {
      const forgeElements = await this.getForgeElementsForCollectionAsync(
        collection
      );

      for (const forgeElement of forgeElements) {
        forgeElementsSet.add(forgeElement);
      }
    }

    return Array.from(forgeElementsSet.values());
  }

  async getBimElementAsync(
    forgeElement: IModelElement
  ): Promise<BimElement | undefined> {
    const bim = await this.props.bimsRepository.getByIdAsync(forgeElement.bim);
    if (!bim) return;

    return this.props.bimElementsRepository.getByGuidAsync(
      bim.id,
      forgeElement.id
    );
  }

  getBimElement(forgeElement: IModelElement): BimElement | undefined {
    const bim = this.props.bimsRepository.getById(forgeElement.bim);
    if (!bim) return;

    return this.props.bimElementsRepository.getByGuid(bim.id, forgeElement.id);
  }

  getExistBimElements(forgeElements: IModelElement[]): BimElement[] {
    const set = new Set<BimElement>();
    for (const forgeElement of forgeElements) {
      const bim = this.props.bimsRepository.getById(forgeElement.bim);
      if (bim) {
        const element = this.props.bimElementsRepository.getByGuid(
          bim.id,
          forgeElement.id
        );
        if (element) {
          set.add(element);
        }
      }
    }

    return Array.from(set.values());
  }

  async getExistBimElementsAsync(
    forgeElements: IModelElement[]
  ): Promise<BimElement[]> {
    const set = new Set<BimElement>();
    for (const forgeElement of forgeElements) {
      const bim = await this.props.bimsRepository.getByIdAsync(
        forgeElement.bim
      );
      if (bim) {
        const element = await this.props.bimElementsRepository.getByGuidAsync(
          bim.id,
          forgeElement.id
        );
        if (element) {
          set.add(element);
        }
      }
    }

    return Array.from(set.values());
  }

  async getWorkTypesByForConstructionGroupsAsync(
    constructionGroups: (ConstructionGroup | undefined)[]
  ): Promise<(WorkType | undefined)[]> {
    const set = new Set<WorkType | undefined>();
    for (const constructionGroup of constructionGroups) {
      if (constructionGroup) {
        const workType = await this.props.workTypesRepository.getByIdAsync(
          constructionGroup.workType
        );
        set.add(workType);
      } else {
        set.add(undefined);
      }
    }

    return Array.from(set);
  }

  async getWorkTypesByForgeElementsAsync(
    forgeElements: IModelElement[]
  ): Promise<(WorkType | undefined)[]> {
    return this.getWorkTypesByForConstructionGroupsAsync(
      await this.getConstructionGroupsForForgeElementAsync(forgeElements)
    );
  }

  async getOrCreateBimElements(forgeElements: IModelElement[]) {
    const bimElements: BimElement[] = [];
    const newElementsProps: TNewBimElementProps[] = [];

    for (const forgeElement of forgeElements) {
      const bim = await this.props.bimsRepository.getByIdAsync(
        forgeElement.bim
      );
      if (bim) {
        const bimElement =
          await this.props.bimElementsRepository.getByGuidAsync(
            bim.id,
            forgeElement.id
          );
        if (bimElement) {
          bimElements.push(bimElement);
        } else {
          const workTypes = await this.getWorkTypesByForgeElements([
            forgeElement,
          ]);
          newElementsProps.push({
            bim: bim.id,
            modelElement: forgeElement.id,
            workType: workTypes[0]?.id || 1,
          });
        }
      }
    }

    const newElements = await this.props.bimElementsRepository.addBatch(
      newElementsProps
    );

    return [...bimElements, ...newElements];
  }
}
