import { format } from "date-fns";
import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Collection } from "../../models/Collection";
import { Project } from "../../models/Project";
import { IPieChartWithLegendViewModel } from "../PieChartWithLegendViewModel/interface";
import { PieChartWithRemarkStatusForCollectionsAsync } from "../PieChartWithLegendViewModel/PieChartWithRemarkStatusForCollectionsAsync";
import { PieChartWithRemarkDeadlineStatusForCollectionsAsync } from "../PieChartWithLegendViewModel/PieChartWithRemarkDeadlineStatusForCollectionsAsync";
import { PieChartWithWorkStatusRemarksOnProjectForForgeElementsAsync } from "../PieChartWithLegendViewModel/PieChartWithWorkStatusRemarksOnProjectForForgeElementsAsync";
import { IProjectStatisticsViewModel } from "./interface";
import { PieChartWithRemarkCountForCollectionsAsync } from "../PieChartWithLegendViewModel/PieChartWithRemarkCountForCollectionsAsync"
import { toSvg } from "dom-to-image";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { WorkTypeGroup } from "../../models/WorkTypeGroup";
import { IBarChartViewModel } from "../BarChartViewModel/interface";
import { EWorkStatus } from "../../models/WorkStatus";
import { SimplePieChartWithLegendViewModel } from "../PieChartWithLegendViewModel/SimplePieChartWithLegendViewModel";

export class ProjectStatisticsAsyncViewModel implements IProjectStatisticsViewModel {
  constructor(private props: { layers: ILayers, project: Project, date: Date }) {
    this.props = props

    makeObservable(this)
  }

  private get date() {
    return this.props.date
  }

  private get project() {
    return this.props.project
  }

  @computed
  get header() {
    return `Сводная статистика проекта на ${format(this.date, "dd.MM.yyyy")}`
  }

  @computed
  get projectName() {
    return this.project.name
  }

  delay(ms: number) {
    return new Promise(res => setTimeout(res, ms))
  }

  completedStatisticsHeader = "Выполненный фактический объем по проекту"

  private async getCollections() {
    const collections = await this.props.layers.usecases.collections.getByProjectIdAsync(this.project.id)
    return collections.filter(collection => !collection.consolidated)
  }

  emptyPieChartViewModel: IPieChartWithLegendViewModel = {
    header: "",
    pieChartViewModel: {
      data: []
    },
    legends: []
  }

  emptyPieChartViewModels = [{
    key: "empty",
    viewModel: this.emptyPieChartViewModel
  }]

  @observable
  factForProjectViewModels: IProjectStatisticsViewModel["factForProjectViewModels"] = undefined

  async computeFactForProjectViewModel(collections: Collection[]) {
    const statusWrapperViewModel = new StatusWrapperViewModel()
    statusWrapperViewModel.load()

    this.factForProjectViewModels = {
      header: "Выполненный фактический объем по проекту",
      statusWrapperViewModel,
      viewModels: []
    }

    try {
      const dataGroupByGroupWorkType = new Map<WorkTypeGroup, [Collection, number][]>()

      for (const collection of collections) {
        const groupWorkTypeData = await this.props.layers.repositories.statsRepository.getPlanFactForCollectionsGroupByGroupWorkTypeAsync({
          collections: [collection.id],
          lte: this.props.date
        })

        for (const data of groupWorkTypeData) {
          const groupWorkType = await this.props.layers.repositories.workTypeGroupRepository.getByIdAsync(data.id)

          if (groupWorkType) {
            const value = data.values.fact.reduce((acc, [date, value]) => acc + value, 0)
            if (dataGroupByGroupWorkType.has(groupWorkType)) {
              dataGroupByGroupWorkType.get(groupWorkType)?.push([collection, value])
            } else {
              dataGroupByGroupWorkType.set(groupWorkType, [[collection, value]])
            }
          }
        }
      }

      for (const [groupWorkType, collectionsData] of dataGroupByGroupWorkType.entries()) {
        const barChartViewModels: { key: string, viewModel: IBarChartViewModel }[] = []
        const totalVolume = collectionsData.reduce((acc, [c, value]) => acc + value, 0)

        for (const [collection, value] of collectionsData) {
          barChartViewModels.push({
            key: String(collection.id),
            viewModel: {
              data: [{
                value: value,
                valueLabel: `${value} ${groupWorkType.unit}`,
                color: "green"
              }],
              value: totalVolume,
              label: collection.name
            }
          })
        }
        this.factForProjectViewModels.viewModels.push({
          key: String(groupWorkType.id),
          header: groupWorkType.name,
          barChartViewModels,
          statisticsTableViewModel: {
            gridData: [],
            gridArea: "",
            gridTemplateColumns: ""
          }
        })
      }

      statusWrapperViewModel.success()
    } catch (error) {
      statusWrapperViewModel.fail(error.message)
    }
  }

  private prepareWorkStatusData(data: Map<EWorkStatus, number>): { value: number, color: string, name: string }[] {
    return Array.from(data.entries()).map(([workStatus, value]) => ({
      value,
      name: this.workStatusInfo.get(workStatus)?.name || "",
      color: this.workStatusInfo.get(workStatus)?.color || ""
    }))
  }

  private workStatusInfo = new Map<EWorkStatus, { color: string, name: string }>([
    [EWorkStatus.completed, { color: "#48A410", name: "Выполнено" }],
    [EWorkStatus.inProgress, { color: "#FEC60F", name: "В процессе работы" }],
    [EWorkStatus.notStarted, { color: "#0002", name: "Работы не начаты" }],
  ])

  @observable
  workStatusViewModels: IProjectStatisticsViewModel["workStatusViewModels"]

  private async computeWorkStatusViewModels(collections: Collection[]) {
    const statusWrapperViewModel = new StatusWrapperViewModel()
    statusWrapperViewModel.load()

    this.workStatusViewModels = {
      header: "Данные о выполнении объема работ",
      statusWrapperViewModel,
      viewModels: []
    }

    try {
      const workTypeGroupsData = new Map<WorkTypeGroup, Map<Collection, Map<EWorkStatus, number>>>()

      for (const collection of collections) {
        const collectionDataGroupedByWorkTypeGroups = await this.props.layers.repositories.statsRepository.getBimElementsStatsForCollectionsGroupByGroupWorkTypeAsync({
          collections: [collection.id],
          lte: this.props.date
        })

        for (const collectionDataForWorkTypeGroup of collectionDataGroupedByWorkTypeGroups) {
          const workTypeGroup = await this.props.layers.repositories.workTypeGroupRepository.getByIdAsync(collectionDataForWorkTypeGroup.id)

          if (workTypeGroup) {
            const collectionData = workTypeGroupsData.get(workTypeGroup) || new Map()
            const workStatusData = new Map(collectionDataForWorkTypeGroup.values)

            collectionData.set(collection, workStatusData)

            workTypeGroupsData.set(workTypeGroup, collectionData)
          }
        }
      }

      type ViewModel = {
        key: string
        header: string
        projectPieChartViewModel: IPieChartWithLegendViewModel
        collectionsPieChartViewModels: {
          key: string
          viewModel: IPieChartWithLegendViewModel
        }[]
      }

      const viewModels: ViewModel[] = []

      for (const [workTypeGroup, collectionsData] of workTypeGroupsData.entries()) {
        const viewModel: ViewModel = {
          key: String(workTypeGroup.id),
          header: workTypeGroup.name,
          projectPieChartViewModel: this.emptyPieChartViewModel,
          collectionsPieChartViewModels: []
        }

        const totalData = new Map<EWorkStatus, number>()

        for (const [collection, workStatusData] of collectionsData) {
          for (const [workStatus, value] of workStatusData.entries()) {
            totalData.set(workStatus, (totalData.get(workStatus) || 0) + value)
          }

          const collectionViewModel: {
            key: string
            viewModel: IPieChartWithLegendViewModel
          } = {
            key: String(collection.id),
            viewModel: new SimplePieChartWithLegendViewModel({
              header: collection.name,
              unit: workTypeGroup.unit,
              data: this.prepareWorkStatusData(workStatusData)
            })
          }

          viewModel.collectionsPieChartViewModels.push(collectionViewModel)
        }

        viewModel.projectPieChartViewModel = new SimplePieChartWithLegendViewModel({
          header: "",
          unit: workTypeGroup.unit,
          data: this.prepareWorkStatusData(totalData)
        })

        viewModels.push(viewModel)
      }

      this.workStatusViewModels = {
        ...this.workStatusViewModels,
        viewModels
      }

      statusWrapperViewModel.success()
    } catch (error) {
      statusWrapperViewModel.fail(error.message)
    }
  }

  @observable
  remarksStatusForProjectViewModel: IProjectStatisticsViewModel["remarksStatusForProjectViewModel"]

  async computeRemarksStatusForProjectViewModel(collections: Collection[]) {
    const statusWrapperViewModel = new StatusWrapperViewModel()
    statusWrapperViewModel.load()

    this.remarksStatusForProjectViewModel = {
      header: "Статусы замечаний по проекту",
      pieChartViewModel: this.emptyPieChartViewModel,
      statusWrapperViewModel
    }

    try {
      const pieChartViewModel = new PieChartWithRemarkStatusForCollectionsAsync({
        ...this.props,
        collections
      })

      await pieChartViewModel.compute()

      this.remarksStatusForProjectViewModel = {
        ...this.remarksStatusForProjectViewModel,
        pieChartViewModel
      }
      statusWrapperViewModel.success()
    } catch (error) {
      statusWrapperViewModel.fail("Ошибка")
    }
  }

  @observable
  remarksStatusForCollectionsViewModel: IProjectStatisticsViewModel["remarksStatusForCollectionsViewModel"]

  async computeRemarksStatusForCollectionsViewModel(collections: Collection[]) {
    const statusWrapperViewModel = new StatusWrapperViewModel()
    statusWrapperViewModel.load()

    this.remarksStatusForCollectionsViewModel = {
      header: "Статусы замечаний по корпусам",
      pieChartViewModels: this.emptyPieChartViewModels,
      statusWrapperViewModel
    }

    try {
      const pieChartViewModels: {
        key: string
        viewModel: IPieChartWithLegendViewModel
      }[] = []

      for (const collection of collections) {
        const viewModel = new PieChartWithRemarkStatusForCollectionsAsync({
          ...this.props,
          collections: [collection],
          header: collection.name
        })

        await viewModel.compute()

        pieChartViewModels.push({
          key: String(collection.id),
          viewModel
        })
      }

      this.remarksStatusForCollectionsViewModel = {
        ...this.remarksStatusForCollectionsViewModel,
        pieChartViewModels
      }

      statusWrapperViewModel.success()
    } catch (error) {
      statusWrapperViewModel.fail(error.message)
    }
  }

  @observable
  workStatusRemarksOnProject: IProjectStatisticsViewModel["workStatusRemarksOnProject"]

  async computeWorkStatusRemarksOnProject(collections: Collection[]) {
    const statusWrapperViewModel = new StatusWrapperViewModel()
    statusWrapperViewModel.load()

    this.workStatusRemarksOnProject = {
      header: "Замечания по статусам видов работ по проекту",
      pieChartViewModel: this.emptyPieChartViewModel,
      statusWrapperViewModel
    }

    try {
      const pieChartViewModel = new PieChartWithWorkStatusRemarksOnProjectForForgeElementsAsync({
        ...this.props,
        collections
      })

      await pieChartViewModel.compute()

      this.workStatusRemarksOnProject = {
        ...this.workStatusRemarksOnProject,
        pieChartViewModel
      }

      statusWrapperViewModel.success()
    } catch (error) {
      statusWrapperViewModel.fail("Ошибка")
    }
  }

  @observable
  deadlineForTheProject: IProjectStatisticsViewModel["deadlineForTheProject"]

  async computeDeadlineForTheProject(collections: Collection[]) {
    const statusWrapperViewModel = new StatusWrapperViewModel()
    statusWrapperViewModel.load()

    this.deadlineForTheProject = {
      header: "Срок по проекту",
      pieChartViewModel: this.emptyPieChartViewModel,
      statusWrapperViewModel
    }

    try {
      const pieChartViewModel = new PieChartWithRemarkDeadlineStatusForCollectionsAsync({
        ...this.props,
        collections
      })

      await pieChartViewModel.compute()

      this.deadlineForTheProject = {
        ...this.deadlineForTheProject,
        pieChartViewModel
      }

      statusWrapperViewModel.success()
    } catch (error) {
      statusWrapperViewModel.fail("Ошибка")
    }
  }

  @observable
  deadlineForTheCollections: IProjectStatisticsViewModel["deadlineForTheCollections"]

  async computeDeadlineForTheCollections(collections: Collection[]) {
    const statusWrapperViewModel = new StatusWrapperViewModel()
    statusWrapperViewModel.load()

    this.deadlineForTheCollections = {
      header: "Срок по корпусам",
      pieChartViewModels: this.emptyPieChartViewModels,
      statusWrapperViewModel
    }

    try {
      const pieChartViewModels: {
        key: string
        viewModel: IPieChartWithLegendViewModel
      }[] = []

      for (const collection of collections) {
        const viewModel = new PieChartWithRemarkDeadlineStatusForCollectionsAsync({
          ...this.props,
          collections: [collection],
          header: collection.name
        })

        await viewModel.compute()

        pieChartViewModels.push({
          key: String(collection.id),
          viewModel
        })
      }

      this.deadlineForTheCollections = {
        ...this.deadlineForTheCollections,
        pieChartViewModels
      }

      statusWrapperViewModel.success()
    } catch (error) {
      statusWrapperViewModel.fail("Ошибка")
    }
  }

  @observable
  collectionRemarksForTheProject: IProjectStatisticsViewModel["collectionRemarksForTheProject"]

  async computeCollectionRemarksForTheProjectAsync(collections: Collection[]) {
    const statusWrapperViewModel = new StatusWrapperViewModel()
    statusWrapperViewModel.load()

    this.collectionRemarksForTheProject = {
      header: "Общее количество замечаний по корпусам",
      pieChartViewModel: this.emptyPieChartViewModel,
      statusWrapperViewModel
    }

    try {
      const pieChartViewModel = new PieChartWithRemarkCountForCollectionsAsync({
        ...this.props,
        collections
      })

      await pieChartViewModel.compute()

      this.collectionRemarksForTheProject = {
        ...this.collectionRemarksForTheProject,
        pieChartViewModel
      }

      statusWrapperViewModel.success()
    } catch (error) {
      statusWrapperViewModel.fail("Ошибка")
    }
  }

  @observable
  planFactViewModels: IProjectStatisticsViewModel["planFactViewModels"]

  async computePlanFactViewModelAsync(collections: Collection[]) {
    const statusWrapperViewModel = new StatusWrapperViewModel()
    statusWrapperViewModel.load()

    this.planFactViewModels = {
      header: "План-факт график проекта",
      statusWrapperViewModel,
      viewModels: []
    }

    try {
      const chartsData = await this.props.layers.repositories.statsRepository.getPlanFactForCollectionsGroupByGroupWorkTypeAsync({
        collections: collections.map(({ id }) => id),
        lte: this.props.date
      })
      for (const chartData of chartsData) {
        const workTypeGroup = await this.props.layers.repositories.workTypeGroupRepository.getByIdAsync(chartData.id)

        if (workTypeGroup) {
          this.planFactViewModels.viewModels.push({
            key: String(workTypeGroup.id),
            planFactChartViewModel: {
              name: workTypeGroup.name,
              unit: workTypeGroup.unit,
              labels: chartData.values.plan.map(([date]) => date),
              datasets: [
                {
                  data: chartData.values.fact.map(([_, value]) => value),
                  color: "green",
                  label: "Факт"
                },
                {
                  data: chartData.values.plan.map(([_, value]) => value),
                  color: "#4783cc",
                  label: "План"
                },
              ]
            }
          })
        }
      }
      statusWrapperViewModel.success()
    } catch (error) {
      statusWrapperViewModel.fail("Ошибка")
    }
  }

  async compute() {
    const collections = await this.getCollections()

    await Promise.all([
      this.computeWorkStatusViewModels(collections),
      this.computeFactForProjectViewModel(collections),
      this.computeRemarksStatusForProjectViewModel(collections),
      this.computeRemarksStatusForCollectionsViewModel(collections),
      this.computeWorkStatusRemarksOnProject(collections),
      this.computeDeadlineForTheProject(collections),
      this.computeDeadlineForTheCollections(collections),
      this.computeCollectionRemarksForTheProjectAsync(collections),
      this.computePlanFactViewModelAsync(collections)
    ])

    if (this.node) {
      await this.onSavePDF(this.node)
    }
  }

  setNode(node: HTMLElement) {
    this.node = node
  }

  node?: HTMLElement

  async onSavePDF(node: HTMLElement) {
    this.props.layers.repositories.pdfGenerator.downloadPDF(node, this.header)
  }
}