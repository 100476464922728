import { computed } from "mobx";
import { ICompaniesRepository } from "../../repositories/CompaniesRepository/interface";
import { Bims } from "../Bims/Bims";

export class Companies {
  constructor(private props: {
    bims: Bims
    companiesRepository: ICompaniesRepository
  }) {
    this.props = props
  }

  @computed
  get contractorsForSelectedProjects() {
    const projects = this.props.bims.selectedProjects
    const companies = this.props.companiesRepository.getContractors()

    return companies.filter(company => projects.some(project => company.hasProject(project.id)))
  }
}