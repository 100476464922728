import { difference } from "lodash";
import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { IModelElement } from "../../models/ModelElement/interface";
import { Target } from "../../models/Target";
import { unitLabel, EUnitRepresentation, EUnits } from "../../models/Units";
import { WorkType } from "../../models/WorkType";
import { WorkTypeGroup } from "../../models/WorkTypeGroup";
import { IBarChartViewModel } from "../BarChartViewModel/interface";
import { EOrientations } from "../PanelDropdownMenuViewModel/interface";
import { PanelSelectorMultiAllViewModel } from "../PanelSelectorViewModel/PanelSelectorMultiAllViewModel";
import { StatisticsTableViewModel } from "../StatisticsTableViewModel/StatisticsTableViewModel";
import { ISummaryStatisticViewModel } from "../SummaryStatisticViewModel/interface";
import { SummaryStatisticForTargetsViewModel } from "../SummaryStatisticViewModel/SummaryStatisticForTargetsViewModel";
import { EWorkStatus, IStatisticsPageViewModel } from "./interface";

export class StatisticsPageViewModel implements IStatisticsPageViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get singleMode() {
    return this.props.layers.usecases.bims.selectedTargets.length <= 1
  }

  @computed
  get selectedTargets() {
    return this.targetsSelectorViewModel?.values || this.props.layers.usecases.bims.selectedTargets.map(target => target.id)
  }

  @computed
  get targetsSelectorViewModel() {
    if (this.singleMode) return

    return new PanelSelectorMultiAllViewModel({
      options: this.props.layers.usecases.bims.selectedTargets.map(target => ({
        value: target.id,
        label: target.name || target.address
      })),
      orientation: EOrientations.right,
      defaultValue: this.props.layers.usecases.bims.selectedTargets.map(target => target.id),
      label: "Корпус"
    })
  }

  @computed
  get storeysSelectorViewModel() {
    if (!this.singleMode) return
    const storeys = [...this.props.layers.usecases.selectedStoreys.storeys]

    storeys.sort((a, b) => a.name > b.name ? -1 : 1)

    return new PanelSelectorMultiAllViewModel({
      options: storeys.map(storey => ({
        value: storey.id,
        label: `Этаж ${storey.name}`
      })),
      orientation: EOrientations.right,
      defaultValue: storeys.map(storey => storey.id),
      label: "Этаж"
    })
  }

  @computed
  get sectionsSelectorViewModel() {
    if (!this.singleMode) return

    const sections = this.props.layers.usecases.selectedSections.sections

    if (sections.length === 0) return

    return new PanelSelectorMultiAllViewModel({
      options: this.props.layers.usecases.selectedSections.sections.map(section => ({
        value: section.name,
        label: `Секция ${section.name}`
      })),
      orientation: EOrientations.right,
      defaultValue: this.props.layers.usecases.selectedSections.sections.map(section => section.id),
      label: "Секция"
    })
  }

  unitRepresentationLabel: { [key: number]: string } = {
    [EUnitRepresentation.percent]: "Процент",
    [EUnitRepresentation.absolute]: "Абсолютное",
  }

  @computed
  get unitRepresentationSelectorViewModel() {
    const options = [
      EUnitRepresentation.percent,
      EUnitRepresentation.absolute,
    ]
    return new PanelSelectorMultiAllViewModel({
      options: options.map(unit => ({
        value: unit,
        label: this.unitRepresentationLabel[unit]
      })),
      orientation: EOrientations.right,
      defaultValue: options,
      label: "Представление"
    })
  }

  @computed
  get workTypes() {
    const workTypesSet = new Set<WorkType>()

    for (const bim of this.props.layers.usecases.bims.selectedBims) {
      const forgeElements = this.props.layers.repositories.modelElementsRepository.getByBim(bim)
      const workTypes = this.props.layers.usecases.elements.getWorkTypesByForgeElements(forgeElements)

      for (const workType of workTypes) {
        if (workType) workTypesSet.add(workType)
      }
    }

    return Array.from(workTypesSet.values())
  }

  @computed
  get workTypeGroups() {
    const workTypesSet = new Set<WorkTypeGroup>()

    for (const bim of this.props.layers.usecases.bims.selectedBims) {
      const forgeElements = this.props.layers.repositories.modelElementsRepository.getByBim(bim)
      const workTypeGroups = this.props.layers.usecases.elements.getWorkTypeGroupsByForgeElements(forgeElements)

      for (const workTypeGroup of workTypeGroups) {
        workTypesSet.add(workTypeGroup || this.workTypeOther)
      }
    }

    const result = Array.from(workTypesSet.values())
    result.sort((a, b) => a.id === undefined || (a.name > b.name) ? 1 : -1)

    return result
  }

  @observable
  workTypeOther = new WorkTypeGroup({
    id: 0,
    name: "другое",
    unit: EUnits.thing,
    other: true
  })

  @computed
  get workTypeSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      options: this.workTypeGroups.map(workType => ({
        value: workType,
        label: workType.name
      })),
      orientation: EOrientations.right,
      defaultValue: this.workTypeGroups,
      label: "Тип работ",
    })
  }

  workStatusLabel: { [key: number]: string } = {
    [EWorkStatus.inProgress]: "В процессе работы",
    [EWorkStatus.completed]: "Фактический объем",
    [EWorkStatus.hasRemarks]: "Имеют замечания",
  }

  @computed
  get workStatusSelectorViewModel() {
    return new PanelSelectorMultiAllViewModel({
      options: [
        EWorkStatus.inProgress,
        EWorkStatus.completed,
        EWorkStatus.hasRemarks
      ].map(status => ({
        value: status,
        label: this.workStatusLabel[status]
      })),
      orientation: EOrientations.right,
      defaultValue: [
        EWorkStatus.completed,
        EWorkStatus.inProgress,
        EWorkStatus.hasRemarks,
      ],
      label: "Статус работ",
    })
  }

  @computed
  get barChartViewModels() {
    if (this.workStatusSelectorViewModel.notSelected) return

    return this.selectedTargets.map(targetId => ({
      key: String(targetId),
      viewModel: new BarChartForTargetViewModel({
        ...this.props,
        targetId,
        unit: EUnits.volume,
        unitRepresentationSelectorViewModel: this.unitRepresentationSelectorViewModel,
        workStatusSelectorViewModel: this.workStatusSelectorViewModel
      })
    }))
  }

  @computed
  get statisticsTableViewModel() {
    if (!this.storeysSelectorViewModel || this.storeysSelectorViewModel.notSelected) return
    if (!this.workTypeSelectorViewModel || this.workTypeSelectorViewModel.notSelected) return
    if (this.workStatusSelectorViewModel.notSelected) return

    return new StatisticsTableViewModel({
      ...this.props,
      storeys: this.storeysSelectorViewModel.values,
      workTypesGroups: this.workTypeSelectorViewModel.values,
      unitRepresentation: this.unitRepresentationSelectorViewModel.values,
      workStatuses: this.workStatusSelectorViewModel.values
    })
  }

  @observable
  summaryStatisticDialogViewModel = {
    open: false,
    onClose: () => this.summaryStatisticDialogViewModel.open = false
  }

  summaryStatisticsButtonViewModel = {
    onClick: () => {
      this.summaryStatisticViewModel = new SummaryStatisticForTargetsViewModel({
        ...this.props,
        onClose: () => {
          this.summaryStatisticDialogViewModel.open = false
        },
        bims: this.props.layers.usecases.bims.selectedBims
      })
      this.summaryStatisticDialogViewModel.open = true
    },
    label: "Сводная статистика"
  }

  @observable
  summaryStatisticViewModel: ISummaryStatisticViewModel | undefined = undefined
}

class BarChartForTargetViewModel implements IBarChartViewModel {
  constructor(private props: {
    layers: ILayers,
    targetId: Target["id"],
    unit: EUnits,
    unitRepresentationSelectorViewModel: PanelSelectorMultiAllViewModel<EUnitRepresentation>
    workStatusSelectorViewModel: PanelSelectorMultiAllViewModel<EWorkStatus>
  }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get bims() {
    return this.props.layers.usecases.bims.selectedBims.filter(bim => bim.target === this.props.targetId)
  }

  @computed
  get forgeElements() {
    const elements: IModelElement[] = []
    for (const bim of this.bims) {
      const forgeElements = this.props.layers.repositories.modelElementsRepository.getByBim(bim)

      elements.push(...forgeElements)
    }

    return elements
  }

  @computed
  get failedForgeElements() {
    return this.props.layers.usecases.statistics.getFailedForgeElements(this.forgeElements)
  }

  @computed
  get notFailedForgeElements() {
    return difference(this.forgeElements, this.failedForgeElements)
  }

  @computed
  get completedForgeElements() {
    return this.props.layers.usecases.statistics.getCompletedForgeElements(this.notFailedForgeElements)
  }

  @computed
  get inProgressForgeElements() {
    return this.props.layers.usecases.statistics.getInProgressForgeElements(this.notFailedForgeElements)
  }

  @computed
  get failedValue() {
    return this.props.layers.usecases.statistics.getForgeElementsVolume(this.failedForgeElements)
  }

  @computed
  get completedValue() {
    return this.props.layers.usecases.statistics.getForgeElementsVolume(this.completedForgeElements)
  }

  @computed
  get totalValue() {
    return this.props.layers.usecases.statistics.getForgeElementsVolume(this.forgeElements)
  }

  @computed
  get inProgressValue() {
    return this.props.layers.usecases.statistics.getForgeElementsVolume(this.inProgressForgeElements)
  }

  @computed
  get unitLabel() {
    return unitLabel[this.props.unit]
  }

  formatValue(props: {
    value: number,
    separator: string,
  }) {
    const needPercent = this.props.unitRepresentationSelectorViewModel.values.includes(EUnitRepresentation.percent)
    const needValue = this.props.unitRepresentationSelectorViewModel.values.includes(EUnitRepresentation.absolute)

    if (needPercent && needValue) {
      return `${(props.value / this.totalValue * 100).toFixed(0)}%${props.separator}(${props.value.toFixed(0)} ${this.unitLabel})`
    }
    if (needValue) return `${props.value.toFixed(0)} ${this.unitLabel}`
    if (needPercent) return `${(props.value / this.totalValue * 100).toFixed(0)}%`

    return ""
  }

  @computed
  get data() {
    const result: {
      color: string
      value: number
      valueLabel: string
    }[] = []

    this.props.workStatusSelectorViewModel.values.forEach(status => {
      if (status === EWorkStatus.completed) result.push({
        color: "#48A410",
        value: this.completedValue,
        valueLabel: this.formatValue({
          value: this.completedValue,
          separator: "\n"
        })
      })
      if (status === EWorkStatus.hasRemarks) result.push({
        color: "#EE2E2E",
        value: this.failedValue,
        valueLabel: this.formatValue({
          value: this.failedValue,
          separator: "\n"
        })
      })
      if (status === EWorkStatus.inProgress) result.push({
        color: "#FEC60F",
        value: this.inProgressValue,
        valueLabel: this.formatValue({
          value: this.inProgressValue,
          separator: "\n"
        })
      })
    })

    return result
  }

  @computed
  get target() {
    return this.props.layers.repositories.targetsRepository.getById(this.props.targetId)
  }

  @computed
  get label() {
    return this.target ? this.target.name || this.target.address : "?"
  }

  @computed
  get value() {
    return this.data.reduce((s, data) => s + data.value, 0)
  }
}