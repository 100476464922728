import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IBimPageViewModel } from "../../view-model/BimPageViewModel/interface"

import { BimPanel } from "../BimPanel/BimPanel"
import { BimViewer } from "../BimViewer/BimViewer"
import { BimViewerV2 } from "../BimViewerV2/BimViewerV2"
interface IBimPageProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IBimPageViewModel
}

const useClasses = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: "100%",
    overflow: "auto"
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flex: 1,
    paddingTop: '64px',
    overflow: 'hidden'
  },
  viewer: {
    flex: 1,
    overflow: 'hidden'
  },
}));

const useClassesBimPanel = makeStyles(theme => ({
  root: {
    zIndex: 2
  },
}));

export const BimPage = observer((props: IBimPageProps) => {
  const classes = useClasses(props)
  const classesBimPanel = useClassesBimPanel();

  return (
    <div className={classes.root}>
      <BimPanel classes={classesBimPanel} viewModel={props.viewModel.bimPanelViewModel} />
      <div className={classes.viewer}>
        {
          props.viewModel.viewerVersion === 1 ? (
            <BimViewer viewModel={props.viewModel.bimViewerViewModel} />
          ) : props.viewModel.viewerVersion === 2 ? (
            <BimViewerV2 viewModel={props.viewModel.bimViewerV2ViewModel} />
          ) : null
        }
      </div>
    </div>
  )
})