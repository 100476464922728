import { format } from "date-fns";
import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableSet,
  trace,
} from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { numberFormat } from "../../heplpers/formats";
import {
  StatusDictionary,
  TStatusDictionaryProps,
} from "../../models/StatusDictionary";
import { StatusHistory } from "../../models/StatusHistory";
import { TUploadFileProps, UploadFile } from "../../models/UploadFile";
import { EActions } from "../../usecase/Permissions/Permissions";
import { UploadButtonViewModel } from "../Buttons/UploadButtonViewModel";
import { StatusWrapperViewModel } from "../StatusWrapperViewModel/StatusWrapperViewModel";
import { UploadFilesListViewModel } from "../UploadFilesListViewModel/UploadFilesListViewModel";
import {
  EStepStatus,
  IStatusFormViewModel,
  IStatusSelectorViewModel,
  TStepCompleted,
} from "./interface";
export class StatusSelectorViewModel implements IStatusSelectorViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props;

    makeObservable(this);
  }

  @computed
  get warnings() {
    if (
      this.nextStatusDictionary &&
      !this.nextStatusDictionary.isBlockedByRemarks
    )
      return;

    const warnings: string[] = [];
    const forgeElements =
      this.props.layers.usecases.selectedElements.selectedForgeElements;

    if (!this.props.layers.usecases.statuses.canAssignStatusByCount) {
      warnings.push("- выберите элементы");
    } else if (
      !this.props.layers.usecases.statuses.canAssignStatusByConstructionGroups
    ) {
      warnings.push("- выберите элементы с одинаковой группой конструкции");
    } else if (
      !this.props.layers.usecases.statuses.canAssignStatusByMaterials
    ) {
      warnings.push("- выберите элементы с одинаковыми материалами");
    } else if (
      !this.props.layers.usecases.statuses.canAssignStatusByStatusHistory
    ) {
      warnings.push("- выберите элементы с одинаковым статусом работ");
    } else if (!this.props.layers.usecases.remarks.canAssignStatusByRemarks) {
      warnings.push("- необходимо закрыть замечания");
    }

    if (warnings.length > 0) {
      return {
        header: "чтобы назначить следующий статус",
        items: warnings,
      };
    }
  }

  @computed
  private get hasWarnings(): boolean {
    return Boolean(this.warnings);
  }

  @computed
  private get allowedApply(): boolean {
    return this.props.layers.usecases.permissions.actionIsAllowed(
      EActions.applyNextStatus
    );
  }

  statusWrapperViewModel = new StatusWrapperViewModel();

  @computed
  get forgeElement() {
    const forgeElements =
      this.props.layers.usecases.selectedElements.selectedForgeElements;
    return forgeElements[0];
  }

  @computed
  get statusDictionaries() {
    const forgeElement = this.forgeElement;
    if (!forgeElement) return [];

    return this.props.layers.usecases.statuses.getStatusDictionaryForForgeElement(
      forgeElement
    );
  }

  @computed
  get bimElement() {
    return this.props.layers.usecases.elements.getBimElement(this.forgeElement);
  }

  @computed
  get activeStatusHistory() {
    return this.bimElement
      ? this.props.layers.repositories.statusHistoryRepository.getActualByBimElement(
          this.bimElement
        )
      : undefined;
  }

  @computed
  get statusHistories() {
    return this.bimElement
      ? this.props.layers.repositories.statusHistoryRepository
          .getByBimElement(this.bimElement)
          .sort((a, b) => (a.statusIndex || 0) - (b.statusIndex || 0))
      : [];
  }

  @computed
  get currentStatusDictionaryIndex() {
    const activeStatusHistory = this.activeStatusHistory;
    const statusDictionaries = this.statusDictionaries;

    if (!activeStatusHistory) return -1;

    if (activeStatusHistory.statusIndex === null) {
      return statusDictionaries.findIndex(
        (statusDictionary) => statusDictionary.id === activeStatusHistory.status
      );
    }

    const statusDictionaryByIndex =
      statusDictionaries[activeStatusHistory.statusIndex];

    if (statusDictionaryByIndex.id === activeStatusHistory.status) {
      return activeStatusHistory.statusIndex;
    } else {
      this.props.layers.usecases.statuses.statusAndStatusHistoryNotEqualByIndexLog(
        statusDictionaryByIndex,
        activeStatusHistory
      );

      return statusDictionaries.findIndex(
        (statusDictionary) => statusDictionary.id === activeStatusHistory.status
      );
    }
  }

  @computed
  get nextStatusDictionary(): StatusDictionary | undefined {
    const statusDictionaries = this.statusDictionaries;

    return statusDictionaries[this.currentStatusDictionaryIndex + 1];
  }

  @computed
  get steps() {
    const statusDictionaries = this.statusDictionaries;
    const nextStatusDictionary = this.nextStatusDictionary;
    const activeStatusHistory = this.activeStatusHistory;
    const currentStatusDictionaryIndex = this.currentStatusDictionaryIndex;

    return statusDictionaries.map((statusDictionary, index) => {
      if (index < currentStatusDictionaryIndex) {
        const statusHistoryForStep = this.statusHistories.find(
          (statusHistory) =>
            statusHistory.statusIndex === null
              ? statusHistory.status === statusDictionary.id
              : statusHistory.statusIndex === index
        );

        if (statusHistoryForStep) {
          return new CompletedStatusViewModel({
            activeStatusHistory: statusHistoryForStep,
            statusDictionary,
            allowDelete: false,
            ...this.props,
          });
        }
      }

      if (index === currentStatusDictionaryIndex && activeStatusHistory) {
        return new CompletedStatusViewModel({
          activeStatusHistory,
          statusDictionary,
          allowDelete: index === currentStatusDictionaryIndex ? true : false,
          ...this.props,
        });
      }

      if (
        index === currentStatusDictionaryIndex + 1 &&
        nextStatusDictionary &&
        !this.hasWarnings &&
        this.allowedApply
      ) {
        const status: EStepStatus = EStepStatus.editable;

        return {
          status,
          statusFormViewModel: new StatusFormViewModel({
            ...this.props,
            status: statusDictionary.id,
            statusWrapperViewModel: this.statusWrapperViewModel,
            nextStatusIndex: this.currentStatusDictionaryIndex + 1,
          }),
          name: statusDictionary.name,
        };
      }

      const status: EStepStatus = EStepStatus.none;

      return {
        status,
        name: statusDictionary.name,
      };

      // const statusHistoryByIndex = this.statusHistories[index];

      // if (statusHistoryByIndex && statusHistoryByIndex.statusIndex !== null) {
      //   if (index === statusHistoryByIndex.statusIndex) {
      //     return new CompletedStatusViewModel({
      //       activeStatusHistory: statusHistoryByIndex,
      //       statusDictionary,
      //       allowDelete: false,
      //       ...this.props,
      //     });
      //   } else {
      //     this.props.layers.usecases.statuses.statusAndStatusHistoryNotEqualByIndexLog(
      //       statusDictionary,
      //       statusHistoryByIndex
      //     );
      //   }
      // }

      // const statusHistoryForStep = this.statusHistories.find(
      //   (statusHistory) => statusHistory.status === statusDictionary.id
      // );

      // if (statusHistoryForStep) {
      //   return new CompletedStatusViewModel({
      //     activeStatusHistory: statusHistoryForStep,
      //     statusDictionary,
      //     allowDelete: false,
      //     ...this.props,
      //   });
      // }

      // return {
      //   status,
      //   name: statusDictionary.name,
      // };
    });
  }
}

class CompletedStatusViewModel implements TStepCompleted {
  constructor(
    private props: {
      layers: ILayers;
      activeStatusHistory: StatusHistory;
      statusDictionary: StatusDictionary;
      allowDelete?: boolean;
    }
  ) {
    this.props = props;

    this.files = new ObservableSet(this.props.activeStatusHistory.files);

    makeObservable(this);
  }

  private files: ObservableSet<TUploadFileProps["id"]>;

  get status(): EStepStatus.completed {
    return EStepStatus.completed;
  }

  @computed
  get allowDelete() {
    return (
      (this.props.allowDelete === undefined ? true : this.props.allowDelete) &&
      this.props.layers.usecases.permissions.actionIsAllowed(
        EActions.editLastStatusHistory
      )
    );
  }

  @observable
  private showFiles = false;

  get name() {
    return this.props.statusDictionary.name;
  }

  @computed
  get username() {
    return (
      this.props.layers.repositories.usersRepository.getById(
        this.props.activeStatusHistory.owner
      )?.fullName || ""
    );
  }

  get comment() {
    return this.props.activeStatusHistory.comment;
  }

  get date() {
    return format(this.props.activeStatusHistory.date, "dd.MM.yyyy");
  }

  @computed
  get showFilesViewModel() {
    if (this.files.size === 0) return;
    return {
      label: `Посмотреть ${this.files.size} ${numberFormat(this.files.size, [
        "файл",
        "файла",
        "файлов",
      ])}`,
      onClick: () => {
        this.showFiles = true;
      },
    };
  }

  @computed
  get showFilesDialogViewModel() {
    return {
      open: this.showFiles,
      onClose: () => (this.showFiles = false),
    };
  }

  @computed
  get uploadFilesListViewModel() {
    const viewModel = new UploadFilesListViewModel({
      ...this.props,
      ids: this.files,
      disableDelete: !this.allowDelete,
    });

    viewModel.onClose = () => {
      this.showFiles = false;
    };

    viewModel.onDelete = this.deleteFile;

    return viewModel;
  }

  @action
  deleteFile = async (file: UploadFile) => {
    this.files.delete(file.id);
    await this.props.layers.repositories.statusHistoryRepository.edit(
      this.props.activeStatusHistory.id,
      {
        files: Array.from(this.files.values()),
      }
    );
    if (this.files.size === 0) this.showFiles = false;
  };
}

class StatusFormViewModel implements IStatusFormViewModel {
  constructor(
    private props: {
      layers: ILayers;
      status: TStatusDictionaryProps["id"];
      statusWrapperViewModel: StatusWrapperViewModel;
      nextStatusIndex: number;
    }
  ) {
    this.props = props;

    makeObservable(this);
  }

  @observable
  private showFiles = false;

  @observable
  comment = "";

  private files = new ObservableSet<TUploadFileProps["id"]>();

  get statusWrapperViewModel() {
    return this.props.statusWrapperViewModel;
  }

  @action
  onChangeComment(s: string) {
    this.comment = s;
  }

  @action
  async onApply() {
    this.statusWrapperViewModel.load();

    await this.props.layers.usecases.statuses.applyStatus({
      comment: this.comment,
      files: Array.from(this.files.values()),
      status: this.props.status,
      statusIndex: this.props.nextStatusIndex,
    });

    this.statusWrapperViewModel.success();
  }

  @computed
  get uploadButtonViewModel() {
    return new UploadButtonViewModel({
      ...this.props,
      onAdd: (files: UploadFile[]) => {
        for (const file of files) {
          this.files.add(file.id);
        }
      },
    });
  }

  @computed
  get showFilesViewModel() {
    if (this.files.size === 0) return;

    return {
      label: `Посмотреть ${this.files.size} ${numberFormat(this.files.size, [
        "файл",
        "файла",
        "файлов",
      ])}`,
      onClick: () => {
        this.showFiles = true;
      },
    };
  }

  @computed
  get uploadFilesListViewModel() {
    const viewModel = new UploadFilesListViewModel({
      ...this.props,
      ids: this.files,
    });

    viewModel.onClose = () => {
      this.showFiles = false;
    };

    viewModel.onDelete = async (file: UploadFile) => {
      this.files.delete(file.id);
      if (this.files.size === 0) this.showFiles = false;
    };

    return viewModel;
  }

  @computed
  get showFilesDialogViewModel() {
    return {
      open: this.showFiles,
      onClose: () => (this.showFiles = false),
    };
  }
}
