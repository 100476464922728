import { computed } from "mobx"
import { IFloatingWindowViewModel, TPosition } from "./interface"

export class StaticFloatingWindowViewModel implements IFloatingWindowViewModel {
  constructor(private props: { position: TPosition }) {
    this.props = props
  }

  @computed
  get position() {
    return this.props.position
  }

  enableResizable = false
  disableDragging = true
}