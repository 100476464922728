import { action, computed, makeObservable, observable } from "mobx";
import { RequestStatus } from "../../models/RequestStatus";
import { IStatusWrapperViewModel } from "./interface";

export class StatusWrapperViewModel implements IStatusWrapperViewModel {
  constructor(props?: { status?: RequestStatus }) {
    this.props = {
      status: props?.status || new RequestStatus({ loading: false, error: undefined })
    }

    makeObservable(this)
  }

  @observable
  private props: {
    status: RequestStatus
  }

  @computed
  get loading() {
    return this.props.status?.loading || false
  }

  @computed
  get error() {
    return this.props.status?.error
  }

  @action
  load() {
    this.props.status.load()
  }

  @action
  success() {
    this.props.status.success()
  }

  @action
  fail(error: string) {
    this.props.status.fail(error)
  }
}