import { action, computed, makeObservable, observable } from "mobx"
import { IPanelSelectorViewModelOld } from "./interface"

export class PanelSelectorViewModel<T, M extends boolean> implements IPanelSelectorViewModelOld<T, M> {
  @observable
  value: IPanelSelectorViewModelOld<T, M>["value"]

  constructor(private props: {
    value: IPanelSelectorViewModelOld<T, M>["value"]
    options: IPanelSelectorViewModelOld<T, M>["options"]
    multiselect?: M
    disabled?: boolean,
    onChange?(e: any): void
  }) {
    this.props = props
    this.value = props.value

    makeObservable(this)
  }

  @computed
  get options() {
    return this.props.options
  }

  @computed
  get disabled() {
    return this.props.disabled
  }

  @computed
  get multiselect() {
    return this.props.multiselect
  }

  @action
  onChange = (value: any) => {
    if (this.value !== value) {
      this.value = value
      this.props.onChange?.(value)
    }
  }
}