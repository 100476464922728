import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { ICheckboxViewModel } from "../../view-model/CheckboxViewModel/interface"
import clsx from "clsx"

const useClasses = makeStyles(theme => ({
  root: {
    flex: "none",
    width: 50,
    height: 30,
    borderRadius: 15,

    cursor: "pointer",
    userSelect: "none",
    backgroundColor: "#AAAAA9",

    display: "flex",
    alignItems: "center"
  },
  // rootSmall: {
  //     width: 25,
  //     height: 15,
  // },
  rootActive: {
    backgroundColor: "#48A410"
  },
  circle: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: "white",
    transform: "translateX(2px)",
    transition: "transform 0.3s"
  },
  // circleSmall: {
  //     width: 13,
  //     height: 13,
  // },
  circleActive: {
    transform: "translateX(22px)"
  },
}))

interface ISliderCheckboxProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: ICheckboxViewModel
  className?: string
}

export const SliderCheckbox = observer((props: ISliderCheckboxProps) => {
  const classes = useClasses(props)

  return (
    <div className={clsx(classes.root, props.className, {
      [classes.rootActive]: props.viewModel.value,
    })}
      onClick={props.viewModel.disabled ? undefined : props.viewModel.onClick}
    >
      <div className={clsx(classes.circle, {
        [classes.circleActive]: props.viewModel.value
      })} />
    </div>
  )
})