// RestActionsRepository

import axios from "axios"
import { computed } from "mobx"
import { Action, EActionTarget, EActionType, TActionProps } from "../../models/Action"
import { TBaseModel } from "../../models/BaseModel"
import { TBimProps } from "../../models/Bim"
import { TUserProps } from "../../models/User"
import { IAuthRepository } from "../AuthRepository/interface"
import { ReadOnlyRestRepository } from "../Repository/ReadOnlyRestRepository"
import { IActionsRepository } from "./interface"

type TRestProps = {
  id: TBaseModel["id"]
  table_name: "main_bimprescription" | "main_bimremark" | "main_bimstatushistory" | "main_bimelement"
  target_content_type: 17 | 18 | 20 | 15
  target_object_id: string
  succeded: boolean
  timestamp: string
  bim: TBimProps["id"]
  actor: TUserProps
  action_type: 2 | 3 | 4
  data: null
}

export class RestActionsRepository implements IActionsRepository {
  constructor(private props: {
    host: string
    authRepository: IAuthRepository
  }) {
    this.props = props

    this.repository = new ReadOnlyRestRepository({
      authRepository: props.authRepository,
      expirationTime: 5,
      path: this.path,
      Model: Action,
      serialyze: this.serialyze,
    })

    this.repository.loadPropsByParams = async function (params?: { [key: string]: any }) {
      const response = await axios.get<{ results: TRestProps[] }>(this.props.path, {
        headers: await props.authRepository.getAuthHeadersAsync(),
        params
      })

      return response.data.results.map(d => this.serialyze(d))
    }
  }

  repository: ReadOnlyRestRepository<Action, TRestProps>

  @computed
  get path() {
    return `${this.props.host}api/actions/`
  }

  async silentSendEntityAction(props: { entityType: EActionTarget, entityId: number | string }) {
    await fetch(`${this.props.host}api/notifications/open-notification/${props.entityType}/${props.entityId}/`, {
      headers: await this.props.authRepository.getAuthHeadersAsync(),
      method: "POST"
    })
  }

  getForBim(bim) {
    return this.repository.getByParams({ bim }, { limit: 100, bim })
  }

  async getForBimAsync(bim) {
    return this.repository.getByParamsAsync({ bim }, { limit: 100, bim })
  }

  targetContentTypeToTarget = new Map<TRestProps["target_content_type"], EActionTarget>([
    [15, EActionTarget.bimElement],
    [17, EActionTarget.prescription],
    [18, EActionTarget.remark],
    [20, EActionTarget.bimStatusHistory],
  ])

  actionTypeToType = new Map<TRestProps["action_type"], EActionType>([
    [2, EActionType.create],
    [3, EActionType.edit],
    [4, EActionType.delete],
  ])

  serialyze = (props: TRestProps): TActionProps => {
    const target = this.targetContentTypeToTarget.get(props.target_content_type)
    const type = this.actionTypeToType.get(props.action_type)

    return {
      // id: props.id,
      id: props.timestamp,
      createdAt: props.timestamp,
      user: props.actor.id,
      bim: props.bim,
      //@ts-ignore
      data: {
        target: target || EActionTarget.unknown,
        type: type || EActionType.unknown,
        props: {
          id: props.target_object_id
        }
      }
    }
  }
}