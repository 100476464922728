import { computed, makeObservable } from "mobx";
import { RemarkBase, TRemarkBaseProps } from "../../models/RemarkBase";
import { TStatusDictionaryProps } from "../../models/StatusDictionary";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { IRemarkBaseRepository } from "./interface";

type TRestProps = {
  id: number
  // base_status?: number | number[]
  base_statuses: number[]
  is_critical: boolean
  title: string
  requires_normative_document?: boolean
}

export class RestRemarkBaseRepository implements IRemarkBaseRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.offlineRepository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: RemarkBase,
      serialyze: this.serialyze
    })

    makeObservable(this)
  }

  offlineRepository: RestOfflineReadOnlyRepository<RemarkBase, TRestProps>

  @computed
  get path() {
    return `${this.props.host}api/main/bim-element-remark-base/`
  }

  getAll() {
    return this.offlineRepository.getAll()
  }

  getById(id: TRemarkBaseProps["id"]) {
    return this.offlineRepository.getOneByParams({ id }, {})
  }

  getByIdAsync(id) {
    return this.offlineRepository.getOneByParamsAsync({ id }, {})
  }

  getByBaseStatus(status: TStatusDictionaryProps["id"]) {
    const all = this.offlineRepository.getAll()

    return all.filter(remarkBase => remarkBase.baseStatus.includes(status))
  }

  getCommonRemarks() {
    return this.offlineRepository.getByParams({ commonRemark: true }, {})
  }

  serialyze(p: TRestProps): TRemarkBaseProps {
    return {
      id: p.id,
      title: p.title,
      isCritical: p.is_critical,
      requiresNormativeDocument: p.requires_normative_document || false,
      baseStatus: p.base_statuses
    }
  }
}