import { TProjectProps } from "../../models/Project";
import { IAuthRepository } from "../AuthRepository/interface";
import { IStatisticsRepository } from "./interface";

export class StatisticsRepository implements IStatisticsRepository {
  constructor(private props: {
    authRepository: IAuthRepository
    host: string
  }) {
    this.props = props
  }

  async downloadProjectReport(props: { project: TProjectProps["id"] }) {
    const response = await fetch(`${this.props.host}api/statistics/project-report/?project=${props.project}`, {
      headers: this.props.authRepository.getAuthHeaders()
    })

    const arrayBuffer = await response.arrayBuffer()
    const src = URL.createObjectURL(new Blob([arrayBuffer], {
      type: "application/xlsx"
    }))

    const a = document.createElement('a')

    a.setAttribute('href', src)
    a.setAttribute('download', `project${props.project}.xlsx`)
    a.setAttribute('target', '_blank')
    a.click()
    a.remove()
  }
}