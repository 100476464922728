import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EActions } from "../../usecase/Permissions/Permissions";
import { EditStatusFormViewModel } from "../EditStatusFormViewModel/EditStatusFormViewModel";
import { StatusFilterViewModel } from "../StatusFilterViewModel/StatusFilterViewModel";
import { StatusSelectorViewModel } from "../StatusSelectorViewModel/StatusSelectorViewModel";
import { EActiveComponent, IStatusPageViewModel } from "./interface";

export class StatusPageViewModel implements IStatusPageViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  editStatus = false

  @computed
  get statusSelectorViewModel() {
    return new StatusSelectorViewModel(this.props)
  }

  @computed
  get activeComponent() {
    if (this.props.layers.usecases.selectedElements.count > 0) return EActiveComponent.form
    return EActiveComponent.filter
  }

  @computed
  get statusFilterViewModel() {
    return new StatusFilterViewModel(this.props)
  }

  @computed
  get editButtonViewModel() {
    if (this.activeComponent !== EActiveComponent.form) return
    if (!this.props.layers.usecases.permissions.actionIsAllowed(EActions.editStatusHistory)) return

    return {
      onClick: () => this.editStatus = true
    }
  }

  @computed
  get editStatusDialogViewModel() {
    return {
      onClose: () => {
        this.editStatus = false
      }
    }
  }

  @computed
  get editStatusFormViewModel() {
    if (!this.editStatus) return

    return new EditStatusFormViewModel({
      ...this.props,
      onClose: () => {
        this.editStatus = false
      }
    })
  }

  onBack() {
    if (this.activeComponent === EActiveComponent.form) {
      this.props.layers.usecases.selectedElements.clearSelection()
    } else {
      this.props.layers.usecases.viewState.setPanelOpened(false)
    }
  }
}