import React from "react";
import {
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';

import {
  indigo
} from '@material-ui/core/colors';
import '../../../fonts/GothamPro/stylesheet.css';
import { CssBaseline } from "@material-ui/core";

const fontName = "Gotham Pro";

export const theme = createTheme({
  fontFamily: fontName,
  typography: {
    fontFamily: fontName
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": fontName,
        body: {
          fontFamily: fontName,
        }
      },
    },
  },
  breakpoints: {
    values: {
      lg: 1040,
    },
  },
  palette: {
    primary: {
      main: "#fdc50a",
    },
    primary1Color: "#fdc50a",
    primary2Color: "#000",
    accent1Color: "#fdc50a",
    homePageColor: indigo[900],
    forgeMinimapPanelColor: '#797979',
    deleteColor: "#e24646",
    link: '#F5C646',

    primaryTextFieldColor: "rgba(0,0,0,0.87)",
    secondaryTextFieldColor: "rgba(0, 0, 0, 0.12)",
    secondaryTextFieldIconColor: "#828282",

    greenButtonColor: '#5D9D52',
    redButtonColor: '#E25241',

    baseBackgroundColor: '#f5f5f5',

    primaryRedesignColor1: "#414141",
    primaryRedesignColor2: "#F5C646",
    primaryRedesignColor3: "#000000",
    primaryRedesignColor4: "#B1B1B1",
    primaryRedesignColor5: "#DEDEDE",
    primaryRedesignColor6: "#FFFFFF",
    primaryRedesignColor7: "#F1F1F1",
    primaryColor: "#FEC60F",
    primaryColorBgc: "#ECEBE9",

    textColor: "#000",
    secondTextColor: "#0005",

    backgroundColor: "#FFF",
    sectionIconColor: "#C4C4C4",
    greyColor: "#0005",
    greenColor: "#48A410",
    redColor: "#EE2E2E",
  },
  datePicker: {
    headerColor: "#fdc50a"
  },
});

export function ThemeProvider(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  )
}

export default ThemeProvider;