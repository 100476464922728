import React, { memo, useReducer } from "react";
import { makeStyles } from "@material-ui/core";
import ArrowRightIcon from "../Icons/ArrowRightIcon";
import { useMemo } from "react";
import { useState } from "react";
import { useCallback } from "react";
import clsx from "clsx";
import { useRef } from "react";
import { useEffect } from "react";

const useClasses = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: ({ orientation }) => orientation === "right" ? "row" : "column"
    },
    hidden: {
        display: "none"
    },
    selector: {
        flex: 1,
        maxWidth: "100%",
        display: "flex",
        lineHeight: "44px",
        cursor: "pointer",
        userSelect: "none",

        "&:hover": {
            backgroundColor: "#f5f5f5"
        }
    },
    disabledSelector: {
        cursor: "initial",

        "&:hover": {
            backgroundColor: "inherit"
        }
    },
    padding: {
        padding: "0 15px",
    },
    label: {
        fontWeight: "normal",
        fontSize: "12px",
        flex: 1
    },
    value: {
        textAlign: "right",
        fontWeight: "bold",
        fontSize: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "50%",
        marginRight: 15,
        flex: 1
    },
    menuWrapper: {
        zIndex: 2,
        position: "fixed",
        overflow: "hidden",
        pointerEvents: "none",
        transform: ({ orientation }) => orientation === "right" ? "translateY(-10px)" : "none",
    },
    menu: {
        backgroundColor: "white",

        pointerEvents: "auto",
        transition: "transform 0.4s ease",
        boxShadow: ({ orientation }) => orientation === "right" ? "inset 10px 0 20px -10px rgba(0, 0, 0, 0.05)" : "inset 0px 0px 7px 0px rgba(0, 0, 0, 0.05)",
        borderRadius: ({ orientation }) => orientation === "right" ? "0px 10px 10px 0px" : "0px 0px 10px 10px",
        overflow: "hidden",
        transform: ({ orientation }) => orientation === "right" ? "translateX(calc(-100% - 1px))" : "translateY(calc(-100% - 1px))",
    },
    menuOpen: {
        transform: ({ orientation }) => orientation === "right" ? "translateX(0)" : "translateY(0)",
    },
    options: {
        padding: "10px 0",
        userSelect: "none",
        overflow: "auto"
    },
    option: {
        cursor: "pointer",
        padding: "0 30px",
        fontSize: "12px",
        minHeight: 44,
        display: "flex",
        alignItems: "center",

        "&:hover": {
            backgroundColor: "#f5f5f5"
        }
    },
    groupLabel: {
        padding: "0 30px",
        lineHeight: "44px",
        fontWeight: "bold",
        fontSize: "12px",
    },
    emptyOptions: {
        lineHeight: "44px",
        padding: "10px 30px",
        fontSize: "12px",
    },
    selectedOption: {
        fontSize: "12px",
        fontWeight: "bold",
        opacity: 1,
    },
    disabledOption: {
        opacity: 0.3,
        cursor: "not-allowed"
    },
    icon: {
        transform: ({ orientation }) => orientation === "bottom" ? "rotate(90deg)" : null,
    },
    groupOption: {
        paddingLeft: 40
    }
}))

function getValueLabelDefault({ values, options }) {
    if (!values) return null;
    if (!options) return null;

    const selectedOptions = values.map(value => options.find(({ value: _value }) => _value === value)).filter(o => o);

    return selectedOptions.map(({ label }) => label).join(", ");
}

function PanelSelector(props) {
    const {
        label,
        options,
        value,
        disabled,
        className,

        getValueLabel = getValueLabelDefault,

        multiselect,
        group,
        orientation = "auto",

        onChange
    } = props;

    const rootRef = useRef();
    const ignoreClickEvent = useRef()
    const [menuRender, setMenuRender] = useState(false);
    const [menuOpen, setMenuOpen] = useReducer((state, action) => {
        if (disabled) return false
        if (action) {
            setMenuRender(true);
        }
        return action;
    }, false);

    const [calculatedOrientation, setCalculatedOrientation] = useState();

    // pre orientation
    const preparedOrientation = useMemo(() => {
        if (orientation === "auto") {
            if (rootRef.current) {
                const allowedWidth = window.innerWidth - rootRef.current.getBoundingClientRect().right;
                if (allowedWidth < 100) {
                    return "bottom"
                } else {
                    return "right"
                }
            }
            return "right"
        } else {
            return orientation;
        }
    }, [menuOpen]);

    // post orientation
    useEffect(() => {
        if (menuOpen && orientation === "auto") {
            const allowedWidth = window.innerWidth - rootRef.current.getBoundingClientRect().right;
            if (allowedWidth < 100) {
                setCalculatedOrientation("bottom")
            } else {
                setCalculatedOrientation("right")
            }
        }
    }, [value]);

    const classes = useClasses({
        classes: props.classes,
        orientation: calculatedOrientation || preparedOrientation
    });

    const stopPropagation = useCallback(e => e.stopPropagation(), []);

    const switchMenu = useCallback(e => {
        ignoreClickEvent.current = e.nativeEvent
        setMenuOpen(!menuOpen)
    }, [menuOpen]);

    const values = useMemo(() => multiselect ? value : (value ? [value] : []), [value, multiselect]);

    const valueLabel = useMemo(() => getValueLabel({ values, options }), [values, options]);

    const refForClick = useRef({
        values,
        multiselect
    });

    refForClick.current = {
        values,
        multiselect
    }

    const onOptionClickHandler = useCallback((value) => onChange ? () => {
        if (disabled) {
            setMenuOpen(false)
            return;
        };

        const {
            values,
            multiselect
        } = refForClick.current;

        if (multiselect) {
            if (values.includes(value)) {
                onChange([...values.filter(v => v !== value)])
            } else {
                const newValues = [...values];
                newValues.push(value);
                onChange(newValues);
            }
        } else {
            setMenuOpen(false);
            if (!values.includes(value)) {
                onChange(value);
            }
        }
    } : null, [onChange, disabled]);

    const menuWrapperStyle = useMemo(() => {
        if (rootRef.current) {
            const bc = rootRef.current.getBoundingClientRect();

            return {
                left: bc.right,
                maxHeight: window.innerHeight - bc.top
            }
        } else {
            return null;
        }
    }, [menuOpen]);

    const optionsStyle = useMemo(() => {
        if (!menuWrapperStyle) return null;
        return {
            maxHeight: menuWrapperStyle.maxHeight
        }
    }, [menuWrapperStyle]);

    useEffect(() => {
        if (menuOpen) {
            function closeMenuHandler(e) {
                if (ignoreClickEvent.current === e) return
                setMenuOpen(false);
            }
            window.addEventListener("click", closeMenuHandler);

            return () => {
                window.removeEventListener("click", closeMenuHandler);
            }
        }
    }, [menuOpen]);

    const groups = useMemo(() => {
        if (group) {
            if (!options) return null;
            const groupsSet = new Map()

            for (const option of options) {
                if (groupsSet.has(option.group)) {
                    groupsSet.get(option.group).options.push(option)
                } else {
                    groupsSet.set(option.group, {
                        label: option.group,
                        options: [option]
                    })
                }
            }

            return Array.from(groupsSet.values())
        }

        return null;
    }, [group, options]);

    return (
        <div className={clsx(classes.root, className)} ref={rootRef}>
            <div className={clsx(classes.selector, classes.padding, {
                [classes.disabledSelector]: disabled
            })} onClick={switchMenu}>
                <div className={classes.label}>{label}</div>
                <div className={classes.value} title={valueLabel}>{valueLabel}</div>
                <div className={clsx(classes.icon, {
                    [classes.hidden]: disabled
                })}>{ArrowRightIcon}</div>
            </div>
            <div onClick={stopPropagation}>
                <div className={classes.menuWrapper}>
                    <div
                        className={clsx(classes.menu, {
                            [classes.menuOpen]: menuOpen
                        })}
                    >
                        {
                            menuRender ? (
                                options && options.length > 0 ? (
                                    <div className={classes.options} style={optionsStyle}>
                                        {
                                            group ? groups.map(({ options, label }) => (
                                                <div className={classes.group} key={label}>
                                                    <div className={classes.groupLabel}>{label}</div>
                                                    {
                                                        options.map(({ label, value, disabled }) => (
                                                            <div
                                                                key={value}
                                                                className={clsx(classes.option, classes.groupOption, {
                                                                    [classes.selectedOption]: values && values.includes(value),
                                                                    [classes.disabledOption]: disabled,
                                                                })}
                                                                onClick={disabled ? undefined : onOptionClickHandler(value)}
                                                            >
                                                                {label}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )) : (
                                                options.map(({ label, value, disabled }) => (
                                                    <div
                                                        key={value}
                                                        className={clsx(classes.option, {
                                                            [classes.selectedOption]: values && values.includes(value),
                                                            [classes.disabledOption]: disabled,
                                                        })}
                                                        onClick={disabled ? undefined : onOptionClickHandler(value)}
                                                    >
                                                        {label}
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                ) : <div className={classes.emptyOptions}>Выбор недоступен</div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(PanelSelector);