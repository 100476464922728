import { action, makeObservable, observable } from "mobx";
import { IInputViewModel } from "./interface";

export class InputViewModel implements IInputViewModel {
  @observable
  value

  disabled

  constructor(props: { value: string, disabled?: boolean, mask?: RegExp, onMaskPrevented?(): void }) {
    this.value = props.value
    this.disabled = props.disabled
    this.mask = props.mask
    this.onMaskPrevented = props.onMaskPrevented

    makeObservable(this)
  }

  onMaskPrevented?(): void
  mask?: RegExp

  @action
  onChange = (value: string) => {
    if (this.mask) {
      const newValue = value.match(this.mask)?.join("") || ""
      if (this.onMaskPrevented && this.value === newValue) this.onMaskPrevented()
      this.value = newValue
    } else {
      this.value = value
    }
  }
}