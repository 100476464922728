export enum EDocumentMode {
  development = "development",
  bc = "bc"
}
export interface IPrescriptionDocumentViewModel {
  mode: EDocumentMode

  prescriptionCode: string
  workDescription: string
  targetAddress: string

  contractorCompanyName: string
  contractorPureCompanyName: string
  mainCompanyName: string
  developmentCompanyName: string

  remarks: {
    title: string
    deadline: string
    annex: string
  }[]
  images: {
    src: string
  }[]
  notes: string
  specialConditions: string

  ownerFullName: string
  receiverFullName: string

  createdAtDate: string
}