import { IStatusWrapperViewModel } from "../StatusWrapperViewModel/interface"

export enum EPropertyType {
  string = "string",
  object = "object"
}

export type TStringProperty = {
  type: EPropertyType.string
  key: string

  name: string
  label: string
}

export type TObjectProperty = {
  type: EPropertyType.object
  key: string
  expanded: boolean
  name: string
  properties: (TStringProperty | TObjectProperty)[]

  onClick?(): void
}

type TProperty = TStringProperty | TObjectProperty

export interface IPropertiesPanelViewModel {
  header: string
  properties: TProperty[]

  onClose(): void

  statusWrapperViewModel: IStatusWrapperViewModel
}