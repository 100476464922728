import React from "react"
import { Button, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IRemarksTableViewModel } from "../../view-model/RemarksTableViewModel/interface"
import { TableButton } from "../Buttons/TableButton"
import { useTableClasses } from "../../styles/TableClasses"
import clsx from "clsx"
import { StatusWrapper } from "../StatusWrapper/StatusWrapper"

const useClasses = makeStyles(theme => ({
  root: {},
}))

interface IRemarksTableProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IRemarksTableViewModel
}

export const RemarksTable = observer((props: IRemarksTableProps) => {
  const classes = useClasses(props)
  const tableClasses = useTableClasses()

  return (
    <StatusWrapper className={classes.root} viewModel={props.viewModel.statusWrapperViewModel}>
      <TableContainer>
        <Table className={clsx(tableClasses.table, tableClasses.tableFixed)}>
          <TableHead>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.widthCell)}>
              ID
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
              Шифр
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
              Статус
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width5Cell)}>
              Замечание
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
              Инженер СК
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
              Дата создания
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
              Подрядчик
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
              Устранить до
            </TableCell>
            <TableCell className={clsx(tableClasses.headerCell, tableClasses.width2Cell)}>
            </TableCell>
          </TableHead>
          <TableBody>
            {
              props.viewModel.remarks.map(remark => (
                <TableRow key={remark.key} className={tableClasses.bodyRow}>
                  <TableCell className={clsx(tableClasses.bodyCell, tableClasses.boldCell)}>
                    {remark.id}
                  </TableCell>
                  <TableCell className={clsx(tableClasses.bodyCell, tableClasses.boldCell, tableClasses.overflowCell)}>
                    {remark.code}
                  </TableCell>
                  <TableCell
                    className={clsx(tableClasses.bodyCell, tableClasses.boldCell, tableClasses.nowrapCell)}
                    style={{ color: remark.statusColor }}
                  >
                    {remark.status}
                  </TableCell>
                  <TableCell className={tableClasses.bodyCell}>
                    {remark.content}
                  </TableCell>
                  <TableCell className={clsx(tableClasses.bodyCell, tableClasses.overflowCell)}>
                    {remark.owner}
                  </TableCell>
                  <TableCell className={tableClasses.bodyCell}>
                    {remark.createdAt}
                  </TableCell>
                  <TableCell className={clsx(tableClasses.bodyCell, tableClasses.overflowCell)}>
                    {remark.contractor}
                  </TableCell>
                  <TableCell className={tableClasses.bodyCell}>
                    {remark.dealine}
                  </TableCell>
                  <TableCell className={clsx(tableClasses.bodyCell, tableClasses.rightCell)}>
                    {
                      remark.onOpen ? (
                        <TableButton onClick={remark.onOpen}>
                          Открыть
                        </TableButton>
                      ) : null
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </StatusWrapper>
  )
})