import React from 'react'

import {
    makeStyles,
    Typography,
} from '@material-ui/core';

import clsx from 'clsx';
import UploadButton from '../../components/TargetsList/UploadButton';
import { observer } from 'mobx-react-lite';
import { ITargetsListViewModel, TTargetItemViewModel } from '../../view-model/TargetsListViewModel/interface';


const useClasses = makeStyles(theme => ({
    name: {
        lineHeight: "40px",
        fontWeight: "bold",
        fontSize: 20,
        //@ts-ignore
        color: theme.palette.primaryRedesignColor3,
        textTransform: "uppercase",
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
    },
    root: {
        overflowY: "auto"
    },
    activeRow: {
        backgroundColor: theme.palette.primary.main
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        width: '100%',
    },
    titleMarginTop: {
        marginTop: 15
    },
}))
interface ITargetsListProps {
    render: (id: number) => JSX.Element | null
    viewModel: ITargetsListViewModel
}

export const TargetsList = observer((props: ITargetsListProps) => {
    const {
        render,
    } = props;
    const classes = useClasses(props);

    return (
        <div className={classes.root}>
            {
                props.viewModel.targets.map(target => (
                    <TargetItem
                        key={target.key}
                        renderItem={render}
                        viewModel={target.viewModel}
                        classes={classes}
                    />
                ))
            }
        </div>
    )
})

interface ITargetItemProps {
    viewModel: TTargetItemViewModel
    renderItem: (id: number) => JSX.Element | null
    classes: ReturnType<typeof useClasses>
}

const TargetItem = observer((props: ITargetItemProps) => {
    const {
        classes
    } = props

    // const onAdd = useCallback((file) => props.viewModel.onAdd(file), []);
    // const onDrop = useCallback((files) => onAdd(files[0]), []);

    // const { getRootProps, isDragActive } = useDropzone({
    //     onDrop,
    //     disabled: !props.viewModel.allowAdd,
    //     multiple: false,
    //     accept: props.viewModel.accept
    // });

    // const dragProps = useMemo(getRootProps, []);

    return (
        <div
            // {...dragProps}
            tabIndex={undefined}
            className={clsx({
                // [classes.activeRow]: isDragActive,
            })}
        >
            <div className={clsx(classes.title, classes.container)}>
                <Typography className={classes.name}>
                    {props.viewModel.name}
                </Typography>
                {
                    props.viewModel.onAdd ? (
                        <UploadButton onFileLoad={props.viewModel.onAdd} identifier={props.viewModel.id} />
                    ) : null
                }
            </div>
            <div>{props.viewModel.additionalContent}</div>
            <div>
                {
                    props.renderItem(props.viewModel.id)
                }
            </div>
        </div>
    )
})