import { format } from "date-fns";
import { computed } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Bim } from "../../models/Bim";
import { Project } from "../../models/Project";
import { Target } from "../../models/Target";
import { EWorkStatus } from "../StatisticsPageViewModel/interface";
import { ISummaryStatisticViewModel } from "./interface";
import { toSvg } from "dom-to-image";

export class SummaryStatisticForTargetsViewModel implements ISummaryStatisticViewModel {
  constructor(private props: {
    layers: ILayers
    bims: Bim[]
    onClose: () => void
  }) {
    this.props = props
  }

  header = `Сводная статистика на ${format(new Date(), "dd.MM.yyyy")}`

  @computed
  get targets() {
    const set = new Set<Target>()

    for (const bim of this.props.bims) {
      const target = this.props.layers.repositories.targetsRepository.getById(bim.target)
      if (target) {
        set.add(target)
      }
    }

    return Array.from(set.values())
  }

  @computed
  get projects() {
    const set = new Set<Project>()

    for (const target of this.targets) {
      const project = this.props.layers.repositories.projectsRepository.getById(target.project)
      if (project) {
        set.add(project)
      }
    }

    return Array.from(set.values())
  }

  @computed
  get address() {
    return this.projects[0]?.name || ""
  }

  @computed
  get statistics() {
    return this.targets.map(target => {
      const bims = this.props.bims.filter(bim => bim.target === target.id)

      return {
        key: target.name,
        name: target.name,
        data: this.getDataForBims(bims)
      }
    })
  }

  private workStatusDictionary: Map<EWorkStatus, {
    color: string
    label: string
  }> = new Map(
    [
      [EWorkStatus.completed, {
        color: "rgb(72, 164, 16)",
        label: "Выполнено"
      }],
      [EWorkStatus.inProgress, {
        color: "rgb(254, 198, 15)",
        label: "В процессе работы"
      }],
      [EWorkStatus.hasRemarks, {
        color: "rgb(238, 46, 46)",
        label: "Имеют замечания"
      }],
      [EWorkStatus.notStarted, {
        color: "rgba(0, 0, 0, 0.2)",
        label: "Работы не начаты"
      }]
    ]
  )

  private getDataForBims(bims: Bim[]): {
    key: string
    valueLabel: string
    value: number
    label: string
    color: string
  }[] {
    const dataArray = Array.from(this.props.layers.usecases.statistics.getDataForBims(bims).entries())
    const totalValue = this.getTotalData(dataArray)

    return Array.from(this.props.layers.usecases.statistics.getDataForBims(bims).entries()).map(([workStatus, value]) => {
      const dictionary = this.workStatusDictionary.get(workStatus)

      return {
        key: String(workStatus),
        valueLabel: `${(value / totalValue * 100).toFixed(0)}%`,
        value,
        label: dictionary?.label || "",
        color: dictionary?.color || ""
      }
    })
  }

  private getTotalData(data: [EWorkStatus, number][]) {
    return data.reduce((a, [status, value]) => a + value, 0)
  }

  async onSavePDF(node: HTMLElement) {
    const dataUrl = await toSvg(node)
    const svg = dataUrl
      .replace("data:image/svg+xml;charset=utf-8,", "")
      .replace(/<style>.+<\/style>/, "")
      .replace(/<\/foreignObject.+/, "")
      .replace(/.+<foreignObject[^>]+>/, "")

    const html = `<!DOCTYPE html><html><head><meta charset="utf-8"></head><body style='margin: 0;display:flex;justify-content: center;'>${svg}</body></html>`;
    const data = await this.props.layers.repositories.pdfGenerator.getPDF(html)

    const src = URL.createObjectURL(new Blob([data], {
      type: "application/pdf"
    }))

    const a = document.createElement('a')

    a.setAttribute('href', src)
    a.setAttribute('download', `${this.header}.pdf`)
    a.setAttribute('target', '_blank')
    a.click()
  }

  onClose = () => {
    this.props.onClose()
  }
}