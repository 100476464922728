import { action, computed } from "mobx";
import { IMeasurementsPanelViewModel } from "./interface";
import { ILayers } from "../../core/Layers/interface";
import { IMeasurement } from "../../models/Measurement/interface";

export class MeasurementsPanelViewModel implements IMeasurementsPanelViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props
  }

  header = "Измерения"

  onClose = () => {
    this.props.layers.usecases.ui.measurementsDataRepository.patch({
      toolActive: false
    })
  }

  @computed
  get measurements() {
    return this._measurements.map(measurement => ({
      key: measurement.id,
      name: measurement.name,
      onClick: console.log,
      onDelete: () => this.onDelete(measurement)
    }))
  }

  @action
  private onDelete = (measurement: IMeasurement) => {
    const measurements = { ...this.props.layers.usecases.ui.measurementsDataRepository.get()?.measurements }
    delete measurements[measurement.id]

    this.props.layers.usecases.ui.measurementsDataRepository.patch({
      measurements
    })
  }

  @computed
  private get _measurements(): IMeasurement[] {
    return Object.values(this.props.layers.usecases.ui.measurementsDataRepository.get()?.measurements || [])
  }

  @computed
  get addButtonViewModel() {
    return {
      label: "Добавить",
      onClick: () => {
        this.props.layers.usecases.ui.temporaryMeasurementsDataRepository.addMode = true
      }
    }
  }
}