import { BaseModel } from "./BaseModel";
import { Entity } from "./Entity";

export type TMaterialProps = {
  name: string
  code: string
} & BaseModel

export class Material extends Entity<TMaterialProps> {
  get code() {
    return this.props.code
  }
}