import { computed, makeObservable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { Bim } from "../../models/Bim";
import { IModelsSelectorViewModel } from "./interface";

export class ModelsSelectorViewModel implements IModelsSelectorViewModel {
  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  async selectElements(props: [string, number[]][]) {
    const set = await this.props.layers.usecases.forgeElements.getModelElementsFromUrnAndModelID(props)

    this.props.layers.usecases.forgeElements.onSelect(set)
  }

  async switchSelectedElements(props: [string, number[]][]) {
    const set = await this.props.layers.usecases.forgeElements.getModelElementsFromUrnAndModelID(props)

    this.props.layers.usecases.forgeElements.onSwitch(set)
  }

  dropSelectedElements() {
    this.props.layers.usecases.forgeElements.onClear()
  }

  getBimByUrn(urn: string) {
    return this.bims.find(bim => bim.urn === urn)
  }

  @computed
  get bims(): Bim[] {
    return this.props.layers.usecases.bims.selectedBims
  }

  getSelectedElementsByUrn(urn: string): number[] {
    const bim = this.props.layers.usecases.bims.getByUrn(urn)

    if (bim) {
      const forgeElements = this.props.layers.usecases.selectedElements.selectedForgeElements
      return forgeElements.filter(forgeElement => forgeElement.bim === bim.id).map(forgeElement => forgeElement.modelID)
    }

    return []
  }

  @computed
  get urns() {
    return this.bims.map(bim => bim.urn)
  }
}