import { computed } from "mobx";
import { StatusDictionary, TStatusDictionaryProps } from "../../models/StatusDictionary";
import { TWorkTypeProps } from "../../models/WorkType";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { IStatusDictionaryRepository } from "./interface";

type TRestProps = {
  color: string
  comment: string
  id: number
  materials: any[]
  name: string
  order: number
  service_name: string
  work_type: number
  is_blocked_by_remarks: boolean
}

export class RestStatusDictionaryRepository implements IStatusDictionaryRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.offlineRepository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: StatusDictionary,
      serialyze: this.serialyze
    })
  }

  offlineRepository: RestOfflineReadOnlyRepository<StatusDictionary, TRestProps>

  @computed
  get path() {
    return `${this.props.host}api/main/bim-status/`
  }

  getByIdAsync(id) {
    return this.offlineRepository.getOneByParamsAsync({ id }, {})
  }

  getAll() {
    return this.offlineRepository.getAll()
  }

  getAllAsync() {
    return this.offlineRepository.getAllAsync()
  }

  getByWorkType(workType: TWorkTypeProps["id"]) {
    return this.offlineRepository.getByParams({ workType }, {})
  }

  getById(id: TStatusDictionaryProps["id"]) {
    return this.offlineRepository.getOneByParams({ id }, {})
  }

  getByWorkTypeAsync(workType: TWorkTypeProps["id"]) {
    return this.offlineRepository.getByParamsAsync({ workType }, {})
  }

  serialyze(props: TRestProps): StatusDictionary["props"] {
    return {
      id: props.id,
      name: props.name,
      workType: props.work_type,
      color: props.color,
      order: props.order,
      isBlockedByRemarks: props.is_blocked_by_remarks,
      materials: props.materials
    }
  }
}