import { action, computed, makeObservable, ObservableSet } from "mobx"

export class SetViewModel<T> {
  constructor(props?: Set<T> | T[]) {
    if (props) {
      this.replace(props)
    }

    makeObservable(this)
  }

  private value = new ObservableSet<T>()

  @computed
  get array() {
    return Array.from(this.value.values())
  }

  @computed
  get size() {
    return this.value.size
  }

  @computed
  get empty() {
    return this.value.size === 0
  }

  @action
  switch = (value: T) => {
    this.value.has(value) ? this.value.delete(value) : this.value.add(value)
  }

  @action
  replace(values: Set<T> | T[]) {
    this.value.replace(values)
  }

  @action
  add(value: T) {
    this.value.add(value)
  }

  @action
  addBatch(values: T[]) {
    for (const value of values) {
      this.value.add(value)
    }
  }

  @action
  deleteBatch(values: T[]) {
    for (const value of values) {
      this.value.delete(value)
    }
  }

  @action
  delete(value: T) {
    this.value.delete(value)
  }

  @action
  clear() {
    this.value.clear()
  }

  has(value: T) {
    return this.value.has(value)
  }

  clone() {
    return new SetViewModel(this.value)
  }
}