import { computed, makeObservable } from "mobx";
import { BaseModel } from "./BaseModel";
import { TCompanyProps } from "./Company";
import { Entity } from "./Entity";

export type TUserProps = {
  firstName: string
  lastName: string
  email: string
  role: EUserRoles
  company: TCompanyProps["id"]
} & BaseModel

export enum EUserRoles {
  // тест
  test = "test",

  // неизвестно
  none = "none",

  // проектировщик
  designer = "designer",

  // клиент / заказчик (технический заказчик)
  customer = "customer",

  // подрядчик
  contractor = "contractor",

  // подрядчик
  general_contractor = "general_contractor",

  // инженер СК
  engineer = "engineer",

  // руководитель строительства
  supervisor = "supervisor",

  // начальник СК
  head = "head",

  // админ
  admin = "admin",

  // менеджер планирования
  scheduler = "scheduler"
}
export class User extends Entity<TUserProps> {
  constructor(props: TUserProps) {
    super(props)

    makeObservable(this)
  }

  @computed
  get firstName() {
    return this.props.firstName
  }

  @computed
  get lastName() {
    return this.props.lastName
  }

  @computed
  get fullName() {
    return [this.firstName, this.lastName].filter(n => n).join(' ')
  }

  @computed
  get role() {
    return this.props.role
  }
}