import axios from "axios";
import { Bim, EBimType } from "../../models/Bim";
import { TModelWorkType } from "../../models/ModelWorkType";
import { TTargetProps } from "../../models/Target";
import { EUserRoles } from "../../models/User";
import { IAuthRepository } from "../AuthRepository/interface";
import { RestOfflineReadOnlyRepository } from "../Repository/RestOfflineReadOnlyRepository";
import { IBimsRepository } from "./interface";

type TRestProp = {
  id: number
  description: string
  display_name: string
  file: string
  forge_id: string
  forge_location: string
  size: number
  target: number
  uploaded_at: string
  uploader: {
    id: number
    company: number
    email: string
    first_name: string
    last_name: string
    role: EUserRoles
  } | null
  version: string
  model_type: TModelWorkType["id"]
  bundle_id: string | null
}
export class RestBimsRepository implements IBimsRepository {
  constructor(private props: { host: string, authRepository: IAuthRepository }) {
    this.props = props

    this.offlineRepository = new RestOfflineReadOnlyRepository({
      authRepository: props.authRepository,
      path: this.path,
      Model: Bim,
      serialyze(responseProp: TRestProp): Bim["props"] {
        return {
          id: responseProp.id,
          name: responseProp.display_name,
          target: responseProp.target,
          description: responseProp.description,
          size: responseProp.size,
          uploadedAt: responseProp.uploaded_at,
          file: responseProp.file,
          uploader: responseProp.uploader?.id,
          modelWorkType: responseProp.model_type,
          ...responseProp.bundle_id ? {
            type: EBimType.bundle,
            bundle: responseProp.bundle_id
          } : {
            type: EBimType.forge,
            forgeId: responseProp.forge_id,
          }
        }
      }
    })
  }

  offlineRepository: RestOfflineReadOnlyRepository<Bim, TRestProp>

  accept = [".rvt", ".s3d"]

  get path() {
    return `${this.props.host}api/main/bim/`
  }

  async edit(bim: Bim, file: File) {
    const data = new FormData();
    data.append("file", file);
    data.append("target", String(bim.target));
    data.append("id", String(bim.id));

    await axios.put<any>(`${this.path}${bim.id}/`, data, {
      headers: await this.offlineRepository.getAuthHeadersAsync(),
    })

    return this.getByIdAsync(bim.id)
  }

  async add(props: { target: TTargetProps["id"], file: File, modelWorkType: TModelWorkType["id"] }) {
    const data = new FormData();
    data.append("file", props.file);
    data.append("target", String(props.target));
    data.append("model_type", String(props.modelWorkType));

    const response = await axios.post<{
      id: number
      description: string
      display_name: string
      file: string
      target: number
      version: string
    }>(this.path, data, {
      headers: await this.offlineRepository.getAuthHeadersAsync(),
    })

    return this.getByIdAsync(response.data.id)
  }

  getAll() {
    return this.offlineRepository.getAll()
  }

  getById(props) {
    return this.offlineRepository.getById(props)
  }

  getByIdAsync(props) {
    return this.offlineRepository.getByIdAsync(props)
  }

  getByTargetId(id: TTargetProps["id"]) {
    return this.offlineRepository.getByParams({
      target: id
    })
  }

  async getByTargetIdAsync(id: TTargetProps["id"]) {
    return this.offlineRepository.getByParamsAsync({
      target: id
    })
  }

  // get from cache! not load
  async getByUrnAsync(urn: string) {
    return this.getByUrn(urn)
  }

  // get from cache! not load
  getByUrn(urn: string) {
    return this.offlineRepository.modelsArray.find(bim => bim.urn === urn)
  }
}