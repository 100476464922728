import { TBaseModel } from "./BaseModel";
import { Entity } from "./Entity";
import { TWorkTypeProps } from "./WorkType";

export type TConstructionGroupProps = {
  name: string
  workType: TWorkTypeProps["id"]
} & TBaseModel

export class ConstructionGroup extends Entity<TConstructionGroupProps> {
  get name() {
    return this.props.name
  }
  get workType() {
    return this.props.workType
  }
}