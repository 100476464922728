import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { memo } from "react";
import SimpleButton from "../Buttons/SimpleButton";


const useClasses = makeStyles(theme => ({
    root: {

    },
    crumb: {
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: "#FFF",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
        borderRadius: 14,
        padding: 10,
        margin: 10
    },
    label: {
        lineHeight: "34px",
        fontSize: "12px",
        fontWeight: "bold",
        userSelect: "none"
    },
    button: {
        marginLeft: 10,
        width: 34,
        height: 34,
        backgroundColor: "#F5F5F5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }
}));

function ViewerCrumbs(props) {
    const classes = useClasses(props);
    const {
        crumbs
    } = props;

    return (
        <div className={classes.root}>
            {
                crumbs ? crumbs.map(({ label, onClose }) => (
                    <div className={classes.crumb} key={label}>
                        <div className={classes.label}>{label}</div>
                        {
                            onClose ? (
                                <SimpleButton className={classes.button} onClick={onClose}>{Icon}</SimpleButton>
                            ) : null
                        }
                    </div>
                )) : null
            }
        </div>
    );
}

const Icon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1348 6.46582L6.82106 17.7795" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.1348 17.7795L6.82106 6.46583" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default memo(ViewerCrumbs);