import { action, computed, makeObservable, observable } from "mobx";
import { CSSProperties, MouseEvent } from "react";
import { EOrientations, IPanelDropdownMenuViewModel, TGroup, TOption } from "./interface";

export class PanelDropdownMenuViewModel implements IPanelDropdownMenuViewModel {
  constructor(private props: {
    opened?: boolean
    orientation?: EOrientations
    getOptions?: () => TOption[]
    getGroups?: () => TGroup[]
  }) {
    this.props = props

    this.opened = props.opened || false

    makeObservable(this)
  }

  @observable
  opened = false

  @observable
  menuRendered = false

  @computed
  get orientation() {
    return this.props.orientation || EOrientations.bottom
  }

  @computed
  get options() {
    return this.props.getOptions?.() || []
  }

  @computed
  get groups() {
    return this.props.getGroups?.() || []
  }

  @computed
  get isEmpty() {
    return (this.options.length === 0) && (this.groups.length === 0)
  }

  onClick(e: MouseEvent) {
    e.stopPropagation()
  }

  @action
  onRootMount(el: HTMLDivElement) {
    this.root = el
    const bc = el.getBoundingClientRect();

    this.optionsStyle = {
      left: bc.right,
      maxHeight: window.innerHeight - bc.top
    }
  }

  root?: HTMLDivElement
  optionsStyle?: CSSProperties

  @computed
  get menuWrapperStyle() {
    return {
      transform: `translateY(${this.transformY}px)`
    }
  }

  @action
  switch = () => {
    if (this.opened) {
      this.close()
    } else {
      this.open()
    }
  }

  skipEvent?: globalThis.MouseEvent

  @action
  open = () => {
    if (this.opened) return
    this.updatePosition()

    this.opened = true
    this.menuRendered = true

    window.addEventListener("click", this.onClose)

    requestAnimationFrame(this.updatePosition)
  }

  @observable
  private transformY = 0

  @computed
  get verticalOrientation() {
    return (this.orientation === EOrientations.right) || (this.orientation === EOrientations.left)
  }

  @action
  private updatePosition = () => {
    if (this.root && this.root.parentElement && this.root.firstElementChild) {
      const bcRoot = this.root.firstElementChild.getBoundingClientRect()
      const bcParent = this.root.parentElement.getBoundingClientRect()
      const diffY = this.verticalOrientation ? bcParent.top - bcRoot.top : bcParent.bottom - bcRoot.top
      this.transformY += diffY + (this.verticalOrientation ? -10 : 0)
    }

    if (this.opened) {
      requestAnimationFrame(this.updatePosition)
    }
  }

  onClose = (e: globalThis.MouseEvent) => {
    if (this.skipEvent === e) {
      this.skipEvent = undefined
      return
    }

    this.close()
  }

  @action
  close = () => {
    if (!this.opened) return

    this.opened = false
    this.unlisten()
  }

  unlisten() {
    this.opened = false
    window.removeEventListener("click", this.onClose)
  }

  unmount() {
    this.unlisten()
  }
}