import { computed, makeObservable, reaction } from "mobx";
import { IPaintElementsViewModel, IPaintModelElementsViewModel } from "../../view-model/PaintElementsViewModel/interface";
import { ModelLoader } from "./ModelLoader";
import { Viewer } from "./Viewer";

export class PaintElements {
  constructor(private props: { viewModel: IPaintElementsViewModel, bimViewer: Viewer, modelLoader: ModelLoader }) {
    this.props = props

    makeObservable(this)
    this.mount()
  }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  private mount() {
    const clearReaction1 = reaction(() => this.props.viewModel.paintModelElementsViewModels, (viewModels) => {
      for (const viewModel of viewModels) {
        new PaintModelElements({
          ...this.props,
          viewModel
        })
      }
    }, {
      fireImmediately: true
    })

    this.unmount = () => {
      clearReaction1()
    }
  }

  unmount() { }
}

class PaintModelElements {
  constructor(private props: { viewModel: IPaintModelElementsViewModel, bimViewer: Viewer, modelLoader: ModelLoader }) {
    this.props = props

    makeObservable(this)

    this.mount()
  }

  @computed
  get viewer() {
    return this.props.bimViewer.viewer
  }

  @computed
  get model() {
    return this.props.modelLoader.getModelByUrn(this.props.viewModel.urn)
  }

  mount() {
    reaction(() => {
      if (!this.viewer || !this.model) return []
      return this.props.viewModel.elementsColor
    }, (elements, oldElements) => {
      if (this.viewer && this.model) {
        for (const element of elements) {
          // this.viewer.setThemingColor(element.dbId, element.color, this.model)
          //@ts-ignore
          this.model.setThemingColor(element.dbId, element.color);
          this.viewer.impl.invalidate(true);
        }
      }
    }, {
      fireImmediately: true
    })
  }

  unmount() { }
}