import React from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IBimTableWithModelWorkTypeViewModel } from "../../view-model/BimTableWithModelWorkTypeViewModel/interface"
import { BimTable } from "../BimTable/BimTable"
import { PlusButton, UploadFile } from "../UploadFile/UploadFile"

const useClasses = makeStyles(theme => ({
  root: {
    "& >:not(:first-child)": {
      marginTop: 15
    }
  },
  header: {
    marginBottom: 5,
    display: "flex",
    alignItems: "center"
  },
  headerLabel: {
    fontWeight: "bold",
    marginRight: 10
  }
}))

interface IBimTableWithModelWorkTypeProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IBimTableWithModelWorkTypeViewModel
}

export const BimTableWithModelWorkType = observer((props: IBimTableWithModelWorkTypeProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      {
        props.viewModel.bimTableViewModels.map(row =>
          <div key={row.key}>
            <div className={classes.header}>
              <div className={classes.headerLabel}>{row.header}</div>
              {
                row.uploadFileViewModel ? (
                  <UploadFile viewModel={row.uploadFileViewModel}>
                    <PlusButton />
                  </UploadFile>
                ) : null
              }
            </div>

            <BimTable viewModel={row.viewModel} />
          </div>
        )
      }
    </div>
  )
})