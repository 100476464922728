import { computed, makeObservable, observable } from "mobx";
import { ILayers } from "../../core/Layers/interface";
import { EPages } from "../../models/Pages";
import { AuthFormViewModel } from "../AuthFormViewModel/AuthFormViewModel";
import { BimPageViewModel } from "../BimPageViewModel/BimPageViewModel";
import { HomePageViewModel } from "../HomePageViewModel/HomePageViewModel";
import { IAppViewModel } from "./interface";

export class AppViewModel implements IAppViewModel {
  @observable.ref
  result = "result 1"

  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @computed
  get activePage() {
    if (!this.props.layers.repositories.authRepository.getIsAuthorized()) return "auth"
    if (this.props.layers.usecases.viewState.props.selectedPage === EPages.viewer) return "bim"

    return "home"
  }

  @computed
  get authFormViewModel() {
    return new AuthFormViewModel(this.props)
  }

  @computed
  get homePageViewModel() {
    return new HomePageViewModel(this.props)
  }

  @computed
  get bimPageViewModel() {
    return new BimPageViewModel(this.props)
  }
}