import { difference } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { BimElement } from "../../models/BimElement";
import { Prescription } from "../../models/Prescription";
import { ERemarkStatus, Remark } from "../../models/Remark";
import { TTargetProps } from "../../models/Target";
import { IBimsRepository } from "../../repositories/BimsRepository/interface";
import { IPrescriptionRepository } from "../../repositories/PrescriptionRepository/interface";
import { IRemarksRepository } from "../../repositories/RemarksRepository/interface";
import { Bims } from "../Bims/Bims";
import { Remarks } from "../Remarks/Remarks";
import { ViewState } from "../ViewState/ViewState";

export class Prescriptions {
  constructor(private props: {
    remarks: Remarks
    bims: Bims
    bimsRepository: IBimsRepository
    remarksRepository: IRemarksRepository
    prescriptionsRepository: IPrescriptionRepository
    viewState: ViewState
  }) {
    this.props = props

    makeObservable(this)
  }

  @observable
  addMode = false

  @computed
  get selectedPrescriptions() {
    const prescriptions: Prescription[] = []

    for (const id of this.props.viewState.props.selectedPrescriptions || []) {
      const prescription = this.props.prescriptionsRepository.getById(id)
      if (prescription) {
        prescriptions.push(prescription)
      }
    }

    return prescriptions
  }

  @computed
  get selectedPrescription() {
    return this.selectedPrescriptions[0]
  }

  @computed
  get prescriptionsForSelectedBims(): Prescription[] {
    const set = new Set<Prescription>()

    for (const bim of this.props.bims.selectedBims) {
      const prescriptions = this.props.prescriptionsRepository.getByBimId(bim.id)
      for (const prescription of prescriptions) {
        set.add(prescription)
      }
    }

    return Array.from(set)
  }

  @computed
  get busyRemarks() {
    const remarks = new Set<Remark>()

    for (const prescription of this.prescriptionsForSelectedBims) {
      for (const remarkId of prescription.remarks) {
        const remark = this.props.remarksRepository.getById(remarkId)

        if (remark) {
          remarks.add(remark)
        }
      }
    }

    return Array.from(remarks)
  }

  @computed
  get freeRemarks() {
    const allRemarks = this.props.remarks.selectedBimsRemarks
    const busyRemarks = this.busyRemarks

    return difference(allRemarks, busyRemarks)
  }

  getFreeRemarksForPrescription(prescription: Prescription) {
    const freeRemarks = new Set(this.freeRemarks)

    for (const id of prescription.remarks) {
      const remark = this.props.remarksRepository.getById(id)

      if (remark) {
        freeRemarks.add(remark)
      }
    }

    return Array.from(freeRemarks)
  }

  getPrescriptionProject(prescription: Prescription) {
    return this.props.bims.getProjectByBimId(prescription.bim)
  }

  getPrescriptionProjectAsync(prescription: Prescription) {
    return this.props.bims.getProjectByBimIdAsync(prescription.bim)
  }

  getPrescriptionCode(prescription: Prescription) {
    const project = this.getPrescriptionProject(prescription)
    return `${project?.code || ""}_П_${prescription.projectNumber}${prescription.customCode ? `.${prescription.customCode}` : ""}`
  }

  async getPrescriptionCodeAsync(prescription: Prescription) {
    const project = await this.getPrescriptionProjectAsync(prescription)
    return `${project?.code || ""}_П_${prescription.projectNumber}`
  }

  getPrescriptionBim(prescription: Prescription) {
    const project = this.getPrescriptionProject(prescription)
    return `${project?.code || ""}_П_${prescription.projectNumber}`
  }

  getPrescriptionTarget(prescription: Prescription) {
    const bim = this.props.bimsRepository.getById(prescription.bim)
    if (!bim) return
    return this.props.bims.getTarget(bim)
  }

  getPrescriptionRemarks(prescription: Prescription) {
    const result: (Remark | undefined)[] = []

    for (const remark of prescription.remarks) {
      result.push(
        this.props.remarksRepository.getById(remark)
      )
    }

    return result
  }

  getPrescriptionStatus(prescription: Prescription) {
    const remarks = this.getPrescriptionRemarks(prescription)

    const statuses = new Set(remarks.map(remark => remark?.status))

    if (statuses.has(ERemarkStatus.opened)) return ERemarkStatus.opened
    if (statuses.has(ERemarkStatus.pending)) return ERemarkStatus.pending
    if (statuses.has(undefined)) return undefined

    return ERemarkStatus.closed
  }

  getPrescriptionContractor(prescription: Prescription) {
    const firstRemarkId = prescription.remarks[0]
    if (!firstRemarkId) return

    return this.props.remarks.getContractorByRemarkId(firstRemarkId)
  }

  getPrescriptionsByTargetId(id: TTargetProps["id"]) {
    const set = new Set<Prescription>()
    const bims = this.props.bimsRepository.getByTargetId(id)

    for (const bim of bims) {
      const prescriptions = this.props.prescriptionsRepository.getByBimId(bim.id)
      for (const prescription of prescriptions) {
        set.add(prescription)
      }
    }

    return Array.from(set)
  }

  async getPrescriptionsByTargetIdAsync(id: TTargetProps["id"]) {
    const set = new Set<Prescription>()
    const bims = await this.props.bimsRepository.getByTargetIdAsync(id)

    for (const bim of bims) {
      const prescriptions = await this.props.prescriptionsRepository.getByBimIdAsync(bim.id)
      for (const prescription of prescriptions) {
        set.add(prescription)
      }
    }

    return Array.from(set)
  }

  async getNotClosedPrescriptionsByBimElementsAsync(elements: BimElement[]) {
    const remarks = await this.props.remarks.getRemarksForBimElementsAsync(elements)
    const notClosedRemarks = this.props.remarks.getNotClosedRemarks(remarks)
    const prescriptionsSet = new Set<Prescription>()

    for (const remark of notClosedRemarks) {
      const prescriptions = await this.props.prescriptionsRepository.getByRemarkAsync(remark)
      for (const prescription of prescriptions) {
        prescriptionsSet.add(prescription)
      }
    }

    return Array.from(prescriptionsSet.values())
  }

  getNotClosedPrescriptionsByBimElements(elements: BimElement[]) {
    const remarks = this.props.remarks.getRemarksForBimElements(elements)
    const notClosedRemarks = this.props.remarks.getNotClosedRemarks(remarks)
    const prescriptionsSet = new Set<Prescription>()

    for (const remark of notClosedRemarks) {
      const prescriptions = this.props.prescriptionsRepository.getByRemark(remark)
      for (const prescription of prescriptions) {
        prescriptionsSet.add(prescription)
      }
    }

    return Array.from(prescriptionsSet.values())
  }

  getNotClosedPrescriptions(prescriptions: Prescription[]) {
    const prescriptionsSet = new Set<Prescription>()

    for (const prescription of prescriptions) {
      const remarks = this.props.remarksRepository.getByBimId(prescription.bim)
      const notClosedRemarks = this.props.remarks.getNotClosedRemarks(remarks)

      if (notClosedRemarks.some(remark => prescription.remarks.includes(remark.id))) {
        prescriptionsSet.add(prescription)
      }
    }

    return Array.from(prescriptionsSet.values())
  }

  getPrescriptionDeadline(prescription: Prescription): Date | undefined {
    const remarks = this.getPrescriptionRemarks(prescription)
    if (remarks.length === 0) return
    if (remarks.some(remark => remark === undefined)) return;

    return new Date(
      Math.min(
        ...remarks.map(remark => remark?.deadline.getTime() || 0)
      )
    )
  }

  @action
  setAddMode(value: boolean) {
    this.addMode = value
  }
}