import { format } from "date-fns";
import { action, computed, makeObservable, observable, ObservableSet } from "mobx";
import { computedFn } from "mobx-utils";
import { ILayers } from "../../core/Layers/interface";
import { ERemarkStatus, Remark } from "../../models/Remark";
import { IRemarksListViewModel } from "./interface";

export class RemarksListViewModel implements IRemarksListViewModel {
  @observable
  search = ""

  constructor(private props: { layers: ILayers }) {
    this.props = props

    makeObservable(this)
  }

  @action
  onChangeSearch(search: string) {
    this.search = search
  }

  @computed
  private get allRemarks(): Remark[] {
    return this.props.layers.usecases.remarks.selectedBimsRemarks
  }

  @computed
  private get selectedElementsRemarksSet(): Set<Remark> {
    return new Set(this.props.layers.usecases.remarks.selectedElementsRemarks.values())
  }

  private selectedStatuses = new ObservableSet<ERemarkStatus>()

  @observable
  private selectedExpiredStatus = false

  @computed
  get statuses() {
    return [
      {
        label: "Открыто",
        color: "#EE2E2E",
        checked: this.selectedStatuses.has(ERemarkStatus.opened),
        onClick: () => {
          if (this.selectedStatuses.has(ERemarkStatus.opened)) {
            this.selectedStatuses.delete(ERemarkStatus.opened)
          } else {
            this.selectedStatuses.add(ERemarkStatus.opened)
          }
        }
      },
      {
        label: "!Просрочено",
        color: "#EB5757",
        checked: this.selectedExpiredStatus,
        onClick: () => {
          if (this.selectedExpiredStatus) {
            this.selectedExpiredStatus = false
          } else {
            this.selectedExpiredStatus = true
          }
        }
      },
      {
        label: "Ожидает",
        color: "#FEC60F",
        checked: this.selectedStatuses.has(ERemarkStatus.pending),
        onClick: () => {
          if (this.selectedStatuses.has(ERemarkStatus.pending)) {
            this.selectedStatuses.delete(ERemarkStatus.pending)
          } else {
            this.selectedStatuses.add(ERemarkStatus.pending)
          }
        }
      },
      {
        label: "Закрыто",
        color: "#48A410",
        checked: this.selectedStatuses.has(ERemarkStatus.closed),
        onClick: () => {
          if (this.selectedStatuses.has(ERemarkStatus.closed)) {
            this.selectedStatuses.delete(ERemarkStatus.closed)
          } else {
            this.selectedStatuses.add(ERemarkStatus.closed)
          }
        }
      },

    ]
  }

  private getRemarkDescription(remark: Remark) {
    const base = this.props.layers.repositories.remarksBaseRepository.getById(remark.remarkBase)

    return `${base?.title || "-"} ${remark.normativeDocument}`
  }

  private prepareRemark = computedFn((remark: Remark) => {
    const viewModel = {
      code: this.props.layers.usecases.remarks.getRemarkCode(remark),
      time: format(remark.createdAt, "dd.MM.yyyy в hh.mm"),
      description: this.getRemarkDescription(remark),
      statusColor: remark.statusColor,
      comment: remark.body,
      storey: remark.location,
      darken: this.selectedElementsRemarksSet.size > 0 ? !this.selectedElementsRemarksSet.has(remark) : false,
      warning: remark.expired ? "вышел срок закрытия замечания" : "",
      onClick: () => this.props.layers.usecases.remarks.setSelectedRemark(remark.id)
    }

    const search = `
      ${viewModel.code}
      ${viewModel.time}
      ${viewModel.description}
      ${viewModel.statusColor}
      ${viewModel.comment}
      ${viewModel.warning}
      ${viewModel.storey}
    `.toLowerCase()

    return {
      key: remark.id,
      viewModel,
      remark,
      search
    }
  })

  @computed
  private get allPreparedRemarks() {
    return this.allRemarks.map(remark => this.prepareRemark(remark))
  }

  @computed
  get remarksAfterSearch() {
    const search = this.search.toLowerCase()

    return this.allPreparedRemarks.filter(remark => remark.search.includes(search))
  }

  @computed
  get filterByStatus() {
    return this.selectedStatuses.size > 0
  }

  @computed
  get remarks(): IRemarksListViewModel["remarks"] {
    return this.remarksAfterSearch.filter(({ remark }) => (
      (this.filterByStatus ? this.selectedStatuses.has(remark.status) : true) &&
      (this.selectedExpiredStatus ? remark.expired : true)
    )).sort((a, b) => {
      if (a.viewModel.darken && !b.viewModel.darken) return 1
      if (b.viewModel.darken && !a.viewModel.darken) return -1

      return b.remark.createdAt.getTime() - a.remark.createdAt.getTime()
    })
  }
}