import { BaseModel } from "../BaseModel";

export class FrozenEntity<T extends BaseModel> {
  constructor(props: T) {
    this.props = props
  }

  props: T

  get id() {
    return this.props.id
  }

  patch() { }
}