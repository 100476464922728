import React from "react"
import { Button, makeStyles, TextField, Typography } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IAuthFormViewModel } from "../../view-model/AuthFormViewModel/interface"
import { StatusWrapper } from "../StatusWrapper/StatusWrapper"

const useClasses = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white"
  },
  circularProgress: {
    color: "white"
  },
  info: {
    textAlign: "center"
  }
}))

interface IAuthFormProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IAuthFormViewModel
}

export const AuthForm = observer((props: IAuthFormProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root} onKeyPress={e => props.viewModel.onKeyPress(e.nativeEvent.code)}>
      <StatusWrapper viewModel={props.viewModel.statusWrapperViewModel}>
        <Typography component="h1" variant="h5">
          Вход
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Логин"
          name="email"
          autoComplete="email"
          autoFocus

          value={props.viewModel.login}
          onChange={e => props.viewModel.onChangeLogin(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Пароль"
          type="password"
          id="password"
          autoComplete="current-password"

          value={props.viewModel.password}
          onChange={e => props.viewModel.onChangePassword(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={props.viewModel.disableLoginButton}
          onClick={() => props.viewModel.onLogin()}
        >
          Войти
        </Button>
        <Typography variant="body2" color="textSecondary" className={classes.info}>
          Для восстановления пароля обратитесь к администратору
        </Typography>
      </StatusWrapper>
    </div>
  )
})