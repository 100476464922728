import React, { useRef } from "react"
import { makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { ISummaryStatisticViewModel } from "../../view-model/SummaryStatisticViewModel/interface"
import PieChart from "../../components/Charts/PieChart";
import SimpleButton from "../../components/Buttons/SimpleButton";

const useClasses = makeStyles(theme => ({
  root: {
    overflow: "auto"
  },
  content: {
    margin: "0 auto 0 auto",
    display: "flex",
    flexDirection: "column"
  },
  header: {
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "44px"
  },
  address: {
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "30px"
  },
  circle: {
    display: "inline-block",
    width: 12,
    height: 12,
    borderRadius: 6,
    marginTop: 2
  },
  valueLabel: {
    whiteSpace: "nowrap",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "15px"
  },
  label: {
    whiteSpace: "nowrap",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "15px"
  },
  legendRow: {
    "&:not(:last-child)": {
      marginBottom: 20
    },
    display: "flex"
  },
  valueInformation: {
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: 8
  },
  saveButton: {
    width: 150,
    backgroundColor: theme.palette.primary.main,
  },
  closeButton: {
    marginRight: 20,

    width: 150,
    backgroundColor: "#F5F5F5",
    color: "#0005",

    "&:hover": {
      backgroundColor: "#E25241",
      color: "#FFF",
    }
  },
  buttons: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center"
  },
  chartContainer: {
    margin: "10px 10px",
  },
  charts: {
    margin: "20px auto 20px auto",
    display: "grid",
    gridTemplateColumns: "auto auto",
  },
  chart: {
    display: "inline-block",

    width: 200,
    height: 200,
  },
  name: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: 20,
  },
  legend: {
    verticalAlign: "top",
    display: "inline-block",
    margin: "15px 0 5px 30px",
  },
  statisticData: {
    display: "flex"
  }
}))

interface ISummaryStatisticProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: ISummaryStatisticViewModel
}

export const SummaryStatistic = observer((props: ISummaryStatisticProps) => {
  const classes = useClasses(props)
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classes.root}>
      <div className={classes.content} ref={contentRef}>
        <div className={classes.header}>{props.viewModel.header}</div>
        <div className={classes.address}>{props.viewModel.address}</div>
        <div className={classes.charts}>
          {
            props.viewModel.statistics.map(statistic => (
              <div className={classes.chartContainer} key={statistic.key}>
                <div className={classes.name}>{statistic.name}</div>
                <div className={classes.statisticData}>
                  <div className={classes.chart}>
                    <PieChart data={statistic.data} width={200} height={200} />
                  </div>
                  <div className={classes.legend}>
                    {
                      statistic.data.map(row => (
                        <div className={classes.legendRow} key={row.key}>
                          <div className={classes.circle} style={{ backgroundColor: row.color }}></div>
                          <div className={classes.valueInformation}>
                            <div className={classes.valueLabel}>{row.valueLabel}</div>
                            <div className={classes.label}>{row.label}</div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>

              </div>
            ))
          }
        </div>
      </div>
      <div className={classes.buttons}>
        <SimpleButton className={classes.closeButton} onClick={props.viewModel.onClose}>Закрыть</SimpleButton>
        <SimpleButton className={classes.saveButton} onClick={() => contentRef.current ? props.viewModel.onSavePDF(contentRef.current) : null}>Сохранить в PDF</SimpleButton>
      </div>
    </div>
  );
})