import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { IStatusFilterViewModel } from "../../view-model/StatusFilterViewModel/interface"
import SimpleCheckbox from "../../components/Checkboxes/SimpleCheckbox"

const useClasses = makeStyles(theme => ({
  root: {
    userSelect: "none",
    overflow: "auto"
  },
  group: {
    paddingBottom: 30
  },
  listItem: {
    fontSize: "12px",
    lineHeight: "16px",
    padding: "10px 0",
    //@ts-ignore
    color: theme.palette.textColor,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",

    "&:hover": {
      //@ts-ignore
      backgroundColor: theme.palette.baseBackgroundColor
    }
  },
  title: {
    fontSize: "12px",
    lineHeight: "16px",
    paddingBottom: 10,
    //@ts-ignore
    color: theme.palette.textColor,
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
  }
}))

interface IStatusFilterProps {
  classes?: Partial<ReturnType<typeof useClasses>>
  viewModel: IStatusFilterViewModel
}

export const StatusFilter = observer((props: IStatusFilterProps) => {
  const classes = useClasses(props)

  return (
    <div className={classes.root}>
      {
        props.viewModel.groups.map(group => (
          <div className={classes.group}>
            {
              group.name ? (
                <Typography className={classes.title}>{group.name}</Typography>
              ) : null
            }
            {
              group.options.map(option => (
                <div
                  className={classes.listItem}
                  onClick={option.onClick}
                >
                  <span>{option.name}</span>
                  <SimpleCheckbox checked={option.selected} />
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  )
})